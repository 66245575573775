import EntryLayout from "../components/layouts/EntryLayout";
import Login from "../components/login/Login";
import SEO from "../components/seo";
import PropTypes from "prop-types";

const LoginPage = ({ location }) => {
  return (
    <EntryLayout>
      <SEO title="Entrar" />
      <Login isNewUser={location?.state?.isNewUser} />
    </EntryLayout>
  );
};

LoginPage.propTypes = {
  location: PropTypes.object,
};

export default LoginPage;
