import { useState } from "react";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./HistoricalReturnsContainer.module.css";
import { useAssets } from "../../hooks/useAssets";
import AssetsPanel from "../valueAtRisk/AssetsPanel";
import { useIsClient } from "../../hooks/useIsClient";
import ReturnsFilter from "./ReturnsFilter";
import ReturnsResult from "./ReturnsResult";
import ReturnsDateFilter from "./ReturnsDateFilter";
import { DateTime } from "luxon";
import { MAX_DATE_RANGE } from "../../hooks/useBacktestFilter";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";

const HistoricalReturnsContainer = () => {
  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn } = useAuth();

  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const [securities, setSecurities] = useState([]);
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ days: MAX_DATE_RANGE }).toJSDate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toJSDate());

  const onReturnsExecuted = () => {
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.HISTORICAL_RETURN_EXECUTED);
    incrementProperty("historicalReturnsExecuted");
  };

  const { grouped: allAssets } = useAssets({ requirePremium: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/metrics/historical_return`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start_date: DateTime.fromJSDate(startDate).toISODate(),
          end_date: DateTime.fromJSDate(endDate).toISODate(),
          assets_portfolio: securities
            .filter(({ type }) => type === "asset")
            .map(asset => ({
              ticker: asset.value,
              weight: asset.weight / 100,
            })),
          funds_portfolio: securities
            .filter(({ type }) => type === "fund")
            .map(fund => ({
              fund_id: fund.value,
              weight: fund.weight / 100,
            })),
        }),
      }),
    {
      onError: showError,
    }
  );

  const title = "Retorno Histórico";

  return (
    <AppLayout
      ctaMessage="Crie sua conta para calcular o desempenho histórico de um portfólio"
      premiumMessage="Seja Premium para desbloquear todos os ativos, fundos e períodos de tempo"
      seoProps={{
        title,
        description:
          "Calcule o desempenho histórico de um portfólio. Escolha o período de análise, os ativos (incluindo fundos) e o peso de cada investimento no seu portfólio.",
      }}>
      <div>
        <h1 className={css["header"]}>{title}</h1>
        <p style={{ marginBottom: "20px" }}>
          Utilize a ferramenta abaixo para calcular o desempenho histórico de um
          portfólio. Selecione o <em>período</em>, os <em>ativos</em> em questão
          (ações, BDRs, ETFs, futuros, criptos e fundos), e seus respectivos{" "}
          <em>pesos</em> no portfólio.
        </p>
        {!isLoggedIn && isClient && (
          <div className={css["welcomeContainer"]} key={key}>
            <div className={css["ctaContainer"]}>
              <RegisterButton copy="Crie sua conta para calcular o desempenho histórico de um portfólio, simular backtests, testar estratégias de Long & Short, e muito mais." />
            </div>
          </div>
        )}
        {isLoggedIn && isClient && (
          <div>
            <ReturnsFilter
              assetsOptions={allAssets}
              securities={securities}
              setSecurities={setSecurities}
              dateFilter={
                <ReturnsDateFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              }
            />
            <div className={css["panel"]}>
              <AssetsPanel
                assets={securities}
                setAssets={setSecurities}
                isLoading={mutation.isLoading}
                onSubmit={onReturnsExecuted}
                buttonText="Calcular Desempenho"
              />
              <div className={css["results"]}>
                {mutation.isLoading && (
                  <Spinner type="Rings" text="Robôs trabalhando..." />
                )}
                {mutation.data && mutation.isSuccess && (
                  <ReturnsResult
                    weightedData={mutation.data.weighted_daily_returns}
                    dailyReturns={mutation.data.daily_returns}
                    walletDrawdown={mutation.data.wallet_drawdown}
                    walletStd={mutation.data.wallet_std}
                    sharpeRatio={mutation.data.sharpe_ratio}
                    ibovData={mutation.data.compounded_ibov}
                    cdiData={mutation.data.compounded_cdi}
                    ipcaData={mutation.data.compounded_ipca}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default HistoricalReturnsContainer;
