// extracted by mini-css-extract-plugin
export var alreadyMember = "PlansContainer-module--alreadyMember--088fa";
export var anchors = "PlansContainer-module--anchors--5a79b";
export var backTop = "PlansContainer-module--backTop--8fe38";
export var benefits = "PlansContainer-module--benefits--a7ac9";
export var button = "PlansContainer-module--button--97dae";
export var buttonOutline = "PlansContainer-module--buttonOutline--3e4f4";
export var content = "PlansContainer-module--content--32a71";
export var coupon = "PlansContainer-module--coupon--adaef";
export var cta = "PlansContainer-module--cta--34fa1";
export var header = "PlansContainer-module--header--6b7c8";
export var logo = "PlansContainer-module--logo--e068d";
export var main = "PlansContainer-module--main--1d4d5";
export var period = "PlansContainer-module--period--3ade5";
export var plan = "PlansContainer-module--plan--83811";
export var plans = "PlansContainer-module--plans--dcc6e";
export var price = "PlansContainer-module--price--83d2b";
export var rest = "PlansContainer-module--rest--78f14";
export var savings = "PlansContainer-module--savings--92447";
export var toggle = "PlansContainer-module--toggle--96ce2";
export var waContainer = "PlansContainer-module--waContainer--dd9ff";
export var whatsContainer = "PlansContainer-module--whatsContainer--3bc12";