import { graphql } from "gatsby";
import PropTypes from "prop-types";
import PublicLayout from "../components/layouts/PublicLayout";
import Ranking from "../components/ranking/Ranking";
import { useEffect } from "react";
import { trackScreeningViewed } from "../utils/amplitude";

const RankingPage = ({ data, pageContext: { isB3 } }) => {
  useEffect(() => {
    trackScreeningViewed({ key: "ranking" + isB3 ? "B3" : "S&P500" });
  }, [isB3]);

  return (
    <PublicLayout
      seoProps={{
        title: isB3 ? "Ranking" : "Ranking S&P 500",
        description: isB3
          ? "Acompanhe os melhores e piores retornos dos ativos mais líquidos da bolsa brasileira."
          : "Acompanhe os melhores e piores retornos dos ativos que compõe o S&P 500.",
      }}
      title={
        isB3
          ? "Ranking dos Ativos da Bolsa Brasileira"
          : "Ranking dos Ativos do S&P 500"
      }
      ctaMessage="Crie sua conta para desbloquear todos os períodos.">
      <Ranking
        data={data}
        isB3={isB3}
        descriptionParagraph={
          isB3 ? (
            <p>
              A lista abaixo contém os ativos mais líquidos da bolsa brasileira
              ordenados pelos seus retornos em diferentes janelas de tempo. O
              ranking é atualizado diariamente.
            </p>
          ) : (
            <p>
              A lista abaixo contém os ativos que compõe o S&P 500 ordenados
              pelos seus retornos em diferentes janelas de tempo. O ranking é
              atualizado diariamente.{" "}
            </p>
          )
        }
      />
    </PublicLayout>
  );
};

RankingPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default RankingPage;

export const query = graphql`
  query($portfolioId: Int!) {
    _1mo: allVariation(
      filter: { window: { eq: "DAYS_30" }, portfolio_id: { eq: $portfolioId } }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _3mo: allVariation(
      filter: { window: { eq: "DAYS_90" }, portfolio_id: { eq: $portfolioId } }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _6mo: allVariation(
      filter: { window: { eq: "DAYS_180" }, portfolio_id: { eq: $portfolioId } }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _12mo: allVariation(
      filter: { window: { eq: "DAYS_365" }, portfolio_id: { eq: $portfolioId } }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _currentYear: allVariation(
      filter: {
        window: { eq: "CURRENT_YEAR" }
        portfolio_id: { eq: $portfolioId }
      }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _currentMonth: allVariation(
      filter: {
        window: { eq: "CURRENT_MONTH" }
        portfolio_id: { eq: $portfolioId }
      }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
    _currentWeek: allVariation(
      filter: {
        window: { eq: "CURRENT_WEEK" }
        portfolio_id: { eq: $portfolioId }
      }
      sort: { variation: DESC }
    ) {
      nodes {
        ticker
        variation
        window
        initial_price
        current_price
        last_updated
        buildTime
        ranking
      }
    }
  }
`;
