import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import * as css from "./estrategias.module.css";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import Footer from "../components/Footer";
import StrategyTags from "../components/common/StrategyTags";
import { useEffect, useState } from "react";
import Select from "react-select";
import { SELECT_MEDIUM } from "../utils/select";
import UpgradeSection from "../components/common/UpgradeSection";
import Breadcrumb from "../components/common/Breadcrumb";
import { EVENT_MAP, logAmplitudeEvent } from "../utils/amplitude";
import { StringParam, useQueryParams } from "use-query-params";

const DIRECTION_TAGS = ["Compra", "Venda"];
const TYPE_TAGS = ["Tendência", "Volatilidade"];

const StrategiesPage = ({
  data: {
    allMarkdownRemark: { nodes },
  },
}) => {
  const [{ direction, type }] = useQueryParams({
    direction: StringParam,
    type: StringParam,
  });

  const [selectedDirection, setSelectedDirection] = useState(
    direction ? { value: direction, label: direction } : null
  );
  const [selectedType, setSelectedType] = useState(
    type ? { value: type, label: type } : null
  );

  const ALL_TAGS = [
    ...new Set(
      nodes
        .map(node => node.frontmatter.tags)
        .reduce((acc, tags) => [...acc, ...tags], [])
    ),
  ];

  const selectedTags = ALL_TAGS.filter(tag =>
    [selectedDirection?.value, selectedType?.value].includes(tag)
  );

  const filteredNodes = nodes.filter(node =>
    selectedTags.every(tag => node.frontmatter.tags.includes(tag))
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.STRATEGIES_LANDING_PAGE_VIEWED);
  }, []);

  return (
    <div>
      <SEO
        title="Estratégias Quantitativas"
        description="Lista de todas as estratégias quantitativas disponíveis para backtest no QuantBrasil."
      />
      <Navbar />
      <div className={css["content"]}>
        <div className={css["breadcrumbContainer"]}>
          <Breadcrumb
            name="Estratégias"
            parents={[{ path: "/backtests", name: "Backtests" }]}
          />
        </div>
        <h1>Estratégias Quantitativas</h1>
        <p>
          Lista de todas as estratégias quantitativas disponíveis no{" "}
          <Link to="/backtests">Simulador de Estratégias</Link> do QuantBrasil.
          As estratégias são baseadas em variáveis quantitativas e{" "}
          <em>price action</em> e podem ser customizadas utilizando parâmetros
          específicos.
        </p>
        <p>
          Cada estratégia é dividida entre{" "}
          <span className={css["tag"] + " " + css["buy"]}>Compra</span> e{" "}
          <span className={css["tag"] + " " + css["sell"]}>Venda</span> e podem
          ser de <span className={css["tag"]}>Tendência</span> ou{" "}
          <span className={css["tag"]}>Volatilidade</span>. Utilize os filtros
          abaixo para encontrar a estratégia ideal para o seu objetivo.
        </p>
        <div className={css["filter"]}>
          <div>
            <label htmlFor="mf-order">Filtrar por direção:</label>
            <Select
              id="direction-select"
              name="direction-select"
              options={DIRECTION_TAGS.map(tag => ({ value: tag, label: tag }))}
              value={selectedDirection}
              onChange={setSelectedDirection}
              styles={SELECT_MEDIUM}
              isClearable
              placeholder="Todas"
            />
          </div>
          <div>
            <label htmlFor="mf-order">Filtrar por tipo:</label>
            <Select
              id="type-select"
              name="type-select"
              options={TYPE_TAGS.map(tag => ({ value: tag, label: tag }))}
              value={selectedType}
              onChange={setSelectedType}
              styles={SELECT_MEDIUM}
              isClearable
              placeholder="Todos"
            />
          </div>
        </div>
        <div className={css["strategies"]}>
          {filteredNodes.map(node => (
            <Link
              key={node.fields.slug}
              to={node.fields.slug}
              className={css["strategy"]}>
              <h2 className={css["title"]}>{node.frontmatter.name}</h2>
              <div className={css["excerpt"]}>{node.excerpt}</div>
              <StrategyTags tags={node.frontmatter.tags} />
            </Link>
          ))}
        </div>
        <div className={css["upgrade"]}>
          <UpgradeSection copy="Assine o plano Premium para desbloquear o backtest de todas as estratégias do QuantBrasil!" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

StrategiesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default StrategiesPage;

export const query = graphql`
  query AllStrategies {
    allMarkdownRemark(
      sort: { frontmatter: { name: ASC } }
      filter: { fields: { type: { eq: "strategies" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          name
          tags
        }
        excerpt
      }
    }
  }
`;
