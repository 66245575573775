import PropTypes from "prop-types";
import { createContext, useContext } from "react";

// Hardcode dev environments for now. They will be used to tell if we are in
// dev or not
const DEV_URLS = [
  "https://dev-quantbrasil-backend.onrender.com",
  "https://dev-backend.quantbrasil.com.br",
];

const PROD_URLS = [
  "https://quantbrasil-backend.onrender.com",
  "https://backend.quantbrasil.com.br",
];

const LOCAL_URLS = ["http://localhost:5000"];

const initialState = {
  BASE_URL: process.env.GATSBY_BACKEND_URL,
  AMPLITUDE_API_KEY: process.env.GATSBY_AMPLITUDE_API_KEY,
  GATSBY_QB_PREMIUM_MONTHLY_PRICE_ID:
    process.env.GATSBY_QB_PREMIUM_MONTHLY_PRICE_ID,
  GATSBY_QB_PREMIUM_YEARLY_PRICE_ID:
    process.env.GATSBY_QB_PREMIUM_YEARLY_PRICE_ID,
  TRIAL_DAYS: isNaN(parseInt(process.env.GATSBY_QB_PREMIUM_TRIAL_PERIOD))
    ? 0
    : parseInt(process.env.GATSBY_QB_PREMIUM_TRIAL_PERIOD),
  IS_BLACKFRIDAY: process.env.GATSBY_IS_BLACKFRIDAY == "true",
  IS_CHRISTMAS: process.env.GATSBY_IS_CHRISTMAS == "true",
  IS_PROMO: process.env.GATSBY_IS_PROMO == "true",
  PREMIUM_BANNER_MESSAGE: process.env.GATSBY_PREMIUM_BANNER_MESSAGE || null,
  YEARLY_COUPON: process.env.GATSBY_YEARLY_COUPON
    ? parseInt(process.env.GATSBY_YEARLY_COUPON)
    : 0,
  MONTHLY_COUPON: process.env.GATSBY_MONTHLY_COUPON
    ? parseInt(process.env.GATSBY_MONTHLY_COUPON)
    : 0,
  COUPON_CODE: process.env.GATSBY_FEATURED_COUPON_CODE || "",
  PROMOTION_CODE_ID: process.env.GATSBY_PROMOTION_CODE_ID || "",
};

const configContext = createContext(initialState);

export function ProvideConfig({ children }) {
  const config = useProvideConfig();
  return (
    <configContext.Provider value={config}>{children}</configContext.Provider>
  );
}

ProvideConfig.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useConfig = () => {
  return useContext(configContext);
};

function useProvideConfig() {
  const config = useContext(configContext);
  const isDev = DEV_URLS.includes(config.BASE_URL);
  const isProd = PROD_URLS.includes(config.BASE_URL);
  const isLocal = LOCAL_URLS.includes(config.BASE_URL);
  const plans = {
    premium: {
      monthly: config.GATSBY_QB_PREMIUM_MONTHLY_PRICE_ID,
      yearly: config.GATSBY_QB_PREMIUM_YEARLY_PRICE_ID,
    },
  };
  return { ...config, isDev, isProd, isLocal, plans };
}
