import { useState } from "react";
import TrashIcon from "../../icons/trash.svg";
import * as css from "./DeleteAlertButton.module.css";
import { useAPI } from "../../hooks/useAPI";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { mapStrategy, showError } from "../../utils/utils";
import { toast } from "react-toastify";
import Modal from "../common/Modal";

const DeleteAlertButton = ({ alert, refetch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const callAPI = useAPI({ withCredentials: true });

  const { mutate, isLoading } = useMutation(
    () =>
      callAPI(`/api/alert/user/${alert.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("Alerta deletado com sucesso!");
        setIsModalOpen(false);
      },
      onError: showError,
    }
  );

  return (
    <>
      <button
        className={css["toggle"]}
        data-tooltip-id="alert-toggle-tp"
        data-tooltip-content="Deletar alerta"
        onClick={() => setIsModalOpen(true)}>
        <TrashIcon />
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalClassName={css["modal"]}>
        <div className={css["content"]}>
          <h3>Deletar Alerta</h3>
          <p>
            Tem certeza que deseja deletar o alerta de{" "}
            <span>
              {mapStrategy(alert.strategy_id)} em {alert.symbol} no{" "}
              {alert.timeframe_id}
            </span>
            ? Essa ação é <b>irreversível</b>.
          </p>
          <div className={css["buttons"]}>
            <button
              className={css["cancel"]}
              onClick={() => setIsModalOpen(false)}>
              Cancelar
            </button>
            <button
              disabled={isLoading}
              className={css["deleteButton"]}
              onClick={() => mutate()}>
              Deletar
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

DeleteAlertButton.propTypes = {
  alert: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default DeleteAlertButton;
