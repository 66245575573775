import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as css from "./CapitalTooltip.module.css";

export const getColor = (value, threshold = 0) => ({
  color:
    value > threshold ? "#49ce8b" : value < threshold ? "#d64242" : undefined,
  fontWeight: 600,
});

// Parse to AT LEAST 2 decimal places
// Source: https://stackoverflow.com/a/20474063
const formatPrice = num =>
  num.toFixed(Math.max(2, (num.toString().split(".")[1] || []).length));

const CapitalTooltip = ({ data }) => {
  if (data.first)
    return (
      <div className={css["container"]}>
        <div>
          Início em{" "}
          {DateTime.fromISO(data.xFormatted)
            .setLocale("pt-BR")
            .toFormat("dd LLL yy")}
        </div>
        <div>
          <div>
            <b>Capital:</b>{" "}
            {
              data.y
                .toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })
                .split(",")[0]
            }
          </div>
        </div>
      </div>
    );
  return (
    <div className={css["container"]}>
      <div>
        <em>
          Em{" "}
          {DateTime.fromISO(data.day).setLocale("pt-BR").toFormat("dd LLL yy")}
        </em>
      </div>
      <div>
        <span style={getColor(data.profit)}>
          {data.profit.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}
        </span>{" "}
        {data.numberTrades > 1 && <em>({data.numberTrades} trades)</em>}
      </div>
      <div className={css["tradeContainers"]}>
        {data.trades.map((d, i) => (
          <>
            <div key={`${d.exit_date}-${i}`} className={css["trade"]}>
              {d.entry_date !== d.exit_date && (
                <div>
                  <em>
                    Início em{" "}
                    {DateTime.fromISO(d.entry_date)
                      .setLocale("pt-BR")
                      .toFormat("dd LLL yy")}
                  </em>
                </div>
              )}
              <div>
                <b>Entrada:</b> {formatPrice(d.entry_price)}
              </div>
              <div>
                <b>Saída:</b> {formatPrice(d.exit_price)}
              </div>
              <div>
                <b>Quantidade:</b> {d.shares}
              </div>
              <div className={css[["tradeResult"]]}>
                <span style={getColor(d.profit)}>
                  {
                    d.profit
                      .toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })
                      .split(",")[0]
                  }
                </span>
              </div>
            </div>
            {(i + 1) % 2 === 0 && <hr />}
          </>
        ))}
      </div>
    </div>
  );
};

CapitalTooltip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CapitalTooltip;
