import { navigate } from "gatsby";
import { useEffect } from "react";
import useTimeout from "use-timeout";
import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import { useLocalStorage } from "react-use";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import {
  logAmplitudeEvent,
  trackCheckoutSessionStarted,
  trackLogin,
  EVENT_MAP,
} from "../../utils/amplitude";

const RedirectMessage = ({ isNewUser = false }) => {
  const [productId, , removeProductId] = useLocalStorage("qb.selectedProduct");

  const hasSelectedProduct = !!productId;

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    priceId =>
      callAPI(`/api/payment/checkout?price_id=${priceId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: data => {
        removeProductId();
        trackCheckoutSessionStarted({ fromValidation: true });
        window.location.href = data.url;
      },
      onError: () => navigate("/app/feed"),
    }
  );

  useTimeout(
    () => {
      if (hasSelectedProduct) mutation.mutate(productId);
      else navigate("/app/feed");
    },
    hasSelectedProduct ? 1000 : 3000
  );

  // Log event here so we have time to init Amplitude
  const { user } = useAuth();
  useEffect(() => {
    if (user && isNewUser) {
      logAmplitudeEvent(EVENT_MAP.ACCOUNT_VERIFIED);
      trackLogin(user);
    }
  }, [isNewUser, user]);

  return (
    <div>
      <span>Sucesso!</span> Você será redirecionado para{" "}
      {hasSelectedProduct ? "o checkout" : "a plataforma"} em alguns segundos...
    </div>
  );
};

RedirectMessage.propTypes = {
  isNewUser: PropTypes.bool,
};

export default RedirectMessage;
