import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import * as css from "./sobre.module.css";
import Footer from "../components/Footer";
import { InlineMath, BlockMath } from "react-katex";
import "katex/dist/katex.min.css";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../utils/amplitude";

const About = () => {
  const {
    allMarkdownRemark: { nodes },
  } = useStaticQuery(graphql`
    query AboutQuery {
      allMarkdownRemark(
        filter: { fields: { type: { eq: "authors" } } }
        sort: { frontmatter: { name: ASC } }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            name
            avatar {
              childImageSharp {
                gatsbyImageData(width: 256, height: 256, quality: 100)
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ABOUT_VIEWED);
  }, []);

  const seoDescription =
    "Descubra o QuantBrasil, sua plataforma de referência para análise de investimentos quantitativos voltada para o investidor brasileiro. Com foco em rentabilidade, relevância e compartilhamento de informações, oferecemos um serviço artesanal, independente e transparente para potencializar suas decisões financeiras.";

  return (
    <div>
      <SEO title="Sobre" description={seoDescription} />
      <Navbar />
      <div className={css["main"]}>
        <div className={css["manifest"]}>
          <div className={css["upper"]}>Sobre o QuantBrasil</div>
          <h1>Manifesto</h1>
          <div>
            <p>
              O QuantBrasil é uma plataforma de auxílio ao investidor brasileiro
              focada em análise quantitativa.
            </p>
            <p>
              Expressamos nesse manifesto nossa visão para a empresa, de modo a
              alinhar as expectativas da forma mais transparente possível com
              nossos usuários e assinantes.
            </p>
            <h2 id="rent-vel">Rentável</h2>
            <p>
              O QuantBrasil <strong>precisa</strong> trazer valor para o
              usuário. Nós acreditamos firmemente que:
            </p>
            <BlockMath>{`R(I + QB) > R(I)`}</BlockMath>
            <p>
              Onde <InlineMath>R(I)</InlineMath> é a função retorno de um
              investidor <InlineMath>I</InlineMath>, e{" "}
              <InlineMath>R(I + QB)</InlineMath> é a função retorno do
              investidor com o auxílio do QuantBrasil.
            </p>
            <h2 id="relevante">Relevante</h2>
            <p>
              Existem duas formas de aumentar o seu patrimônio:{" "}
              <em>ganhando dinheiro</em> ou <em>deixando de perdê-lo</em>.
              Portanto, consideramos um sucesso quando provamos que uma
              abordagem quantitativa ou determinado investimento{" "}
              <strong>não</strong> funciona, pois isso significa uma forma a
              menos de se perder dinheiro.
            </p>
            <h2 id="informativo">Informativo</h2>
            <p>
              Queremos compartilhar o máximo de conhecimento possível. Primeiro,
              como missão geral. O brasileiro investe pouco, e mal. Segundo,
              como treinamento específico. O investidor ciente do que está
              fazendo tem muito mais chances de tirar maior proveito da
              plataforma.
            </p>
            <h2 id="artesanal">Artesanal</h2>
            <p>
              O QuantBrasil é uma empresa familiar, gerido essencialmente por{" "}
              <em>marido e mulher</em>. Portanto, consideramos nosso produto
              como <strong>artesanal</strong>.
            </p>
            <p>Isso traz algumas implicações importantes:</p>
            <div className={css["implications"]}>
              <h3 id="independente">Independente</h3>
              <p>
                Somos <strong>independentes</strong>. Não há dinheiro de
                investidor externo, nem outros interesses para o produto que não
                sejam alinhados com os seus criadores.{" "}
              </p>
              <p>
                Somos extremamente <strong>cuidadosos</strong> com o produto.
                Seu sucesso ou falha é responsabilidade única e exclusivamente
                nossa.
              </p>
              <p>
                Somos <strong>focados</strong> no que precisamos fazer.
                Priorizamos <em>depth over width</em>, pois nossa capacidade de
                entrega é naturalmente limitada.{" "}
              </p>
              <blockquote>
                <p>
                  Uma corrente comprimida por margens estreitas chegará mais
                  longe. — Pe. Antonin Sertillanges
                </p>
              </blockquote>
              <h3 id="pessoal">Pessoal</h3>
              <p>
                Nossa relação com os usuários deve se aproximar mais da sua
                relação com o padeiro do bairro do que com o gerente do seu
                banco. Sendo pequenos, sabemos que teremos uma base menor de
                assinantes mas que seremos capazes de atendê-los diretamente,
                sem robôs, telemarketing, <em>help desks</em>, etc.{" "}
              </p>
              <p>
                Na medida do possível e respeitando a disponibilidade e
                privacidade de cada um, gostamos de ter um contato mais pessoal
                para tirar dúvidas, coletar feedback, e anunciar novas
                funcionalidades.
              </p>
              <h3 id="transparente">Transparente</h3>
              <p>
                Ser artesanal e independente faz com que o QuantBrasil não
                preste contas a ninguém que não sejam seus assinantes. O site é
                mantido por aqueles que acreditam no projeto e se beneficiam do
                valor que lhes é oferecido.
              </p>
              <p>
                Nós queremos{" "}
                <a
                  href="https://notes.andymatuschak.org/About_these_notes?stackedNotes=zCMhncA1iSE74MKKYQS5PBZ"
                  rel="noopener noreferrer"
                  target="_blank">
                  trabalhar com o portão da garagem aberto
                </a>
                , ou seja, ser transparentes sobre o que estamos trabalhando, as
                dificuldades encontradas, e as soluções escolhidas.
              </p>
            </div>
          </div>
        </div>
        <div className={css["align"]}>
          <h2>Nosso Time</h2>
          <p>Os responsáveis por tudo que você vê por aqui:</p>
        </div>
        <div className={css["team"]}>
          {nodes.map(node => (
            <Link key={node.frontmatter.name} to={`/sobre/${node.fields.slug}`}>
              <GatsbyImage
                image={node.frontmatter.avatar.childImageSharp.gatsbyImageData}
                alt={`Foto de ${node.frontmatter.name}`}
              />
              <div>{node.frontmatter.name}</div>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
