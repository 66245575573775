import * as css from "./score.module.css";
import Navbar from "../../components/Navbar";
import SEO from "../../components/seo";
import Footer from "../../components/Footer";
import Breadcrumb from "../../components/common/Breadcrumb";
import ScoreIndicator from "../../components/backtests/ScoreIndicator";
import { Link } from "gatsby";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const faq = [
  {
    question: "O que é o Score do QuantBrasil?",
    answer:
      "É uma nota atribuída a cada backtest para comparar objetivamente duas estratégias, considerando métricas como Retorno Percentual, Porcentagem de Acerto, Trades por Dia, EV e Drawdown.",
  },
  {
    question: "Por que algumas estratégias são excluídas do cálculo do Score?",
    answer:
      "Estratégias com menos de 5 operações ou com preço do ativo menor que 3,00 são excluídas para evitar distorções nos resultados, como EV irreal e drawdown zero.",
  },
  {
    question: "Como o Score ajuda a decidir entre dois backtests?",
    answer:
      "O Score permite comparar dois backtests objetivamente, ajudando a identificar qual estratégia é superior com base no algoritmo do QuantBrasil.",
  },
  {
    question: "O que significa estar no 95º percentil do Score?",
    answer:
      "Estar no 95º percentil significa que o backtest está entre os top 5% dos últimos 100.000 backtests para aquele timeframe, sendo classificado como 'Excelente'.",
  },
  {
    question:
      "É possível comparar estratégias de timeframes diferentes com o Score?",
    answer:
      "Não, o Score é relativo ao mesmo timeframe. Variações significativas nas métricas de diferentes timeframes impedem comparações objetivas entre eles.",
  },
];

const ScorePage = () => {
  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.BACKTEST_SCORE_PAGE_VIEWED);
  }, []);

  return (
    <div>
      <SEO
        title="Backtest Score"
        description="Descubra como o QuantBrasil utiliza o Score para avaliar objetivamente backtests de estratégias de trading, incluindo métricas, exclusões, e como usar o Score para comparar e classificar backtests de forma eficaz."
      />
      <Navbar />
      <div className={css["content"]}>
        <div className={css["breadcrumbContainer"]}>
          <Breadcrumb
            name="Score"
            parents={[{ path: "/backtests", name: "Backtests" }]}
          />
        </div>
        <h1>Backtest Score</h1>
        <p>
          O backtest é uma técnica excelente para se analisar como uma
          determinada estratégia teria se comportado no <em>passado</em>. Porém,
          para um trader, muitas vezes isso não é o suficiente. Além de
          verificarmos as estatísticas da estratégia, queremos também saber se
          ela é a <strong>melhor disponível</strong>.
        </p>
        <p>
          O problema é que nem sempre é fácil decidir qual o melhor conjunto de
          parâmetros para uma estratégia. No QuantBrasil, nós facilitamos essa
          análise gerando um{" "}
          <Link to="/app/melhores-backtests">Ranking de Backtests</Link>, e
          permitindo que cada usuário gere seu próprio ranking baseado em
          parâmetros customizados.
        </p>
        <p>
          Ainda assim, muitas vezes não é <em>claro</em> qual backtest é o
          melhor. Considere o exemplo simplificado abaixo:
        </p>
        <table>
          <thead>
            <tr>
              <th>Backtest</th>
              <th>EV</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#1</td>
              <td>0,50%</td>
              <td>5,67%</td>
            </tr>
            <tr>
              <td>#2</td>
              <td>0,40%</td>
              <td>4,19%</td>
            </tr>
          </tbody>
        </table>

        <p>
          Embora o <em>Backtest 1</em> possua um maior EV, ou seja, um maior
          valor esperado por trade, ele possui um <em>drawdown</em> maior, ou
          seja, um maior risco associado. Não é claro, pelos números, qual
          backtest é o melhor. No final, existe um grau de subjetividade onde
          cada trader valorizará aquilo que lhe é mais importante.
        </p>
        <p>
          Por exemplo, nem sempre a estratégia que tem o melhor retorno
          percentual é preferível. Imagine esse outro exemplo:
        </p>
        <table>
          <thead>
            <tr>
              <th>Backtest</th>
              <th>Retorno</th>
              <th>Drawdown</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#3</td>
              <td>50%</td>
              <td>22,34%</td>
            </tr>
            <tr>
              <td>#4</td>
              <td>44%</td>
              <td>7,90%</td>
            </tr>
          </tbody>
        </table>

        <p>
          Mesmo que o <em>Backtest 3</em> tenha o maior retorno de todos, o
          risco que ele corre é 3x maior que o <em>Backtest 4</em>. Assim,
          dependendo da volatilidade aceita no portfólio, é perfeitamente
          natural que se opte pela estratégia mais estável.
        </p>
        <p>
          Para mitigar esse problema nós desenvolvemos o nosso próprio{" "}
          <strong>score</strong>. O score é uma nota que atribuímos a cada
          backtest de modo que possamos comparar <em>objetivamente</em> duas ou
          mais estratégias.
        </p>
        <p>
          O <strong>score</strong> do QuantBrasil está em constante
          aprimoramento, mas é uma função que leva em consideração as seguintes
          métricas e seus respectivos pesos:
        </p>
        <table>
          <thead>
            <tr>
              <th>Métrica</th>
              <th>Peso</th>
              <th>Preferência</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>EV (Valor Esperado)</td>
              <td>3</td>
              <td>Maior é melhor</td>
            </tr>
            <tr>
              <td>Drawdown</td>
              <td>2</td>
              <td>Menor é melhor</td>
            </tr>
            <tr>
              <td>Retorno Percentual</td>
              <td>2</td>
              <td>Maior é melhor</td>
            </tr>
            <tr>
              <td>Operações por Dia</td>
              <td>2</td>
              <td>Maior é melhor</td>
            </tr>
            <tr>
              <td>Porcentagem de Acerto</td>
              <td>1</td>
              <td>Maior é melhor</td>
            </tr>
          </tbody>
        </table>

        <p>Além disso, o score é indefinido se:</p>
        <ol>
          <li>
            O backtest tiver <strong>menos que</strong> 5 operações;
          </li>
          <li>
            O preço do ativo for <strong>menor que</strong> 3,00.
          </li>
        </ol>
        <p>
          Excluímos estratégias com poucas operações para evitar distorções. Por
          exemplo, um backtest com 1 trade que resultou 5% de lucro vai ter 100%
          de acerto, um EV irreal, e drawdown zero. Retiramos ativos com valor
          de face muito pequeno pois o efeito do <em>slippage</em> é muito
          pronunciado, uma vez que 1 tick para cima ou para baixo já afeta o
          resultado do trade significativamente.
        </p>
        <div className={css["warning"]}>
          <span>Importante:</span> o cálculo do score pode mudar a qualquer
          momento, considerando que o algoritmo está em constante evolução.
        </div>
        <h2 id="classificando-um-backtest">Classificando um Backtest</h2>
        <p>
          O score nos ajuda a comparar dois backtests – podemos dizer que,
          baseado no algoritmo do QuantBrasil, o backtest X é superior ao
          backtest Y. No entanto, isso ainda não resolve o problema de não
          sabermos se o backtest X é de fato um <em>bom backtest</em>, ou se ele
          é somente melhor que a concorrência. Em outras palavras, não é porque
          uma estratégia é melhor que outra, que ela é{" "}
          <em>instrinsicamente boa</em>.
        </p>
        <p>
          Para ajudar nesse problema nós calculamos, periodicamente, o score dos
          últimos 100.000 backtests <em>para cada timeframe</em>. Depois,
          calculamos o <em>percentil</em> das notas, gerando a seguinte
          classificação:
        </p>
        <table>
          <thead>
            <tr>
              <th>Score Percentil</th>
              <th>Classificação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>&lt; 5%</td>
              <td>Péssimo</td>
            </tr>
            <tr>
              <td>5% - 25%</td>
              <td>Muito Ruim</td>
            </tr>
            <tr>
              <td>25% - 50%</td>
              <td>Ruim</td>
            </tr>
            <tr>
              <td>50% - 75%</td>
              <td>Bom</td>
            </tr>
            <tr>
              <td>75% - 95%</td>
              <td>Muito Bom</td>
            </tr>
            <tr>
              <td>&gt; 95%</td>
              <td>Excelente</td>
            </tr>
          </tbody>
        </table>
        <p>
          Ou seja, sempre que o score de um backtest estiver no 95º percentil
          (significando que ele está no top 5% dos últimos 100.000 backtests
          computados para aquele timeframe) ele é classificado como{" "}
          <strong
            style={{
              color: "#49ce8b",
            }}>
            Excelente
          </strong>
          .
        </p>
        <p>
          Assim, temos uma abordagem <em>qualitativa</em> de um determinado
          backtest, e podemos compará-lo com <em>todos</em> seus pares para um
          determinado timeframe.
        </p>
        <div className={css["warning"]}>
          <span>Importante:</span> como o valor das métricas utilizadas varia
          significativamente de um timeframe para o outro, a nota é dada sempre
          relativa a um mesmo timeframe. Não é possível comparar objetivamente
          uma estratégia no 15 minutos com uma estratégia no semanal. Enquanto a
          primeira terá muito mais trades e um EV bem menor, a segunda terá
          pouquíssimos trades e potencialmente um EV muito mais alto.
        </div>
        <h2 id="visualizando-o-score">Visualizando o Score</h2>
        <p>
          Para uma forma visual de comparação, o{" "}
          <strong>indicador de score</strong> abaixo está presente em todos os
          backtests que satisfaçam os requisitos mínimos.
        </p>
        <div className={css["scoreIndicatorContainer"]}>
          <ScoreIndicator
            score={73.11}
            percentiles={{
              percentile_5: 20.88,
              percentile_25: 45,
              percentile_50: 50,
              percentile_75: 55,
              percentile_95: 78.12,
            }}
          />
        </div>
        <p>
          Com ele, fica fácil visualizar o score do backtest, sua posição
          relativa aos outros, e sua classificação qualitativa. Note que o 5º e
          o 95º percentis, que marcam os extremos da classificação, estão sempre
          disponíveis.
        </p>
        <h2 id="faq">FAQ</h2>
        <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>
          {faq.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>{item.question}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  <p>{item.answer}</p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
      <Footer />
    </div>
  );
};

export default ScorePage;
