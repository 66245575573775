import * as css from "./HowButton.module.css";
import Modal from "../common/Modal";
import { useState } from "react";
import { Link } from "gatsby";

const HowButton = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button onClick={() => setOpen(true)} className={css["hint"]}>
        Como funciona?
      </button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["help"]}>
          <div>Sobre os robôs</div>
          <div>
            <p>
              Os robôs quantitativos são estratégias de investimento
              automatizadas que executam operações de compra e venda de acordo
              com parâmetros pré-definidos (<span>setups</span>).
            </p>
            <p>
              Todo o processo de coleta de dados e análise{" "}
              <em>em tempo real</em> são feitos pelo <span>QuantBrasil</span>,
              de modo a tornar o resultado o mais próximo possível da realidade.
              Possíveis diferenças:
            </p>
            <ul>
              <li>
                Preços de entrada e saída podem diferir do preço teórico
                presente no backtest, assim como o horário de execução das
                ordens.{" "}
                <em>
                  Toda ordem executada é notificada em tempo real no{" "}
                  <span>
                    <Link to="/app/feed">Feed</Link>
                  </span>
                </em>
                .
              </li>
              <li>
                Trades que seriam <em>descartados</em> no backtest (por exemplo,
                quando não é possível discernir se o ativo atingiu o alvo ou o
                stop primeiro), <span>constam</span> no robô.
              </li>
              <li>
                Trades que seriam <em>descartados</em> no backtest, por já haver
                um em andamento, podem <span>constar</span> no robô.
              </li>
            </ul>
            <p>
              Os backtests são recalculados <em>diariamente</em>, de modo que o
              desempenho real do robô possa ser comparado com o esperado.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HowButton;
