// extracted by mini-css-extract-plugin
export var actionsContainer = "CointegrationsList-module--actionsContainer--50d10";
export var arrowDown = "CointegrationsList-module--arrowDown--d0aac";
export var arrowUp = "CointegrationsList-module--arrowUp--e4700";
export var buttonContainer = "CointegrationsList-module--buttonContainer--964c5";
export var checkbox = "CointegrationsList-module--checkbox--73208";
export var checkboxFilter = "CointegrationsList-module--checkboxFilter--d86eb";
export var filter = "CointegrationsList-module--filter--c806a";
export var headerWithIcon = "CointegrationsList-module--headerWithIcon--47b40";
export var input = "CointegrationsList-module--input--8de2d";
export var refreshButton = "CointegrationsList-module--refreshButton--f16ce";
export var tableContainer = "CointegrationsList-module--tableContainer--12750";
export var zscore = "CointegrationsList-module--zscore--ad1d3";