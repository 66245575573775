import * as css from "./ReturnsFilter.module.css";
import Select, { components } from "react-select";
import { PREMIUM_OPTION, SELECT_MULTI } from "../../utils/select";
import PropTypes from "prop-types";
import FUNDS from "../../data/funds.json";
import { useState } from "react";
import { uniqBy, differenceBy } from "lodash";
import { useAuth } from "../../hooks/useAuth";

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <div className={css["premiumBannerMenu"]}>
        Seja <b>Premium</b> para desbloquear <b>+3000</b> fundos!
      </div>
      {props.children}
    </components.MenuList>
  );
};

MenuList.propTypes = {
  children: PropTypes.node.isRequired,
};

const maxResults = 100;

// Min number of chars before we trigger the filter for a big list
export const SEARCH_MIN_CHARS = 3;

// Min number of options when an array is considered a big list
export const BIG_LIST_LIMIT = 300;

export const isBigList = options => options && options.length > BIG_LIST_LIMIT;

const filterAndLimitOptions = ({ options, inputValue, maxResults }) =>
  options && (inputValue.length >= SEARCH_MIN_CHARS || !isBigList(options))
    ? options
        .filter(value => {
          const option = value;
          return typeof option.value === "string"
            ? option.value.toLowerCase().includes(inputValue.toLowerCase())
            : option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
                option?.alias?.toLowerCase().includes(inputValue.toLowerCase());
        })
        .slice(0, maxResults)
    : [];

export const getNoOptionsMessage = (inputValue, isBigList) =>
  isBigList && inputValue.length < SEARCH_MIN_CHARS
    ? "Digite pelo menos 3 caracteres"
    : "Nenhum resultado encontrado";

const OPEN_FUNDS = [1634, 2653, 2244, 664, 719, 733];

const ReturnsFilter = ({
  securities,
  setSecurities,
  assetsOptions,
  dateFilter,
}) => {
  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const needMinChars = isBigList(FUNDS);
  const [inputValue, setInputValue] = useState("");

  const filteredOptions = filterAndLimitOptions({
    options: isPremium
      ? FUNDS
      : FUNDS.filter(({ value }) => OPEN_FUNDS.includes(value)),
    inputValue,
    maxResults,
  });

  const onChange = (option, securityType) => {
    const excluded = differenceBy(
      securities.filter(({ type }) => type === securityType),
      option,
      "value"
    );

    return setSecurities(
      differenceBy(
        uniqBy(
          [...securities, ...option.map(o => ({ ...o, type: securityType }))],
          "label"
        ),
        excluded,
        "label"
      )
    );
  };

  return (
    <div className={css["filter"]}>
      {dateFilter}
      <div className={css["assets"]}>
        <label htmlFor="assets-select">Ativos</label>
        <Select
          id="assets-select"
          options={assetsOptions}
          value={securities.filter(({ type }) => type === "asset")}
          onChange={option => onChange(option, "asset")}
          styles={{ ...SELECT_MULTI, ...PREMIUM_OPTION }}
          isMulti
          placeholder="Selecione os ativos"
        />
      </div>
      <div className={css["assets"]}>
        <label htmlFor="funds-select">Fundos</label>
        <Select
          id="funds-select"
          options={filteredOptions}
          value={securities.filter(({ type }) => type === "fund")}
          onChange={option => onChange(option, "fund")}
          styles={{ ...SELECT_MULTI, ...PREMIUM_OPTION }}
          inputValue={inputValue}
          onInputChange={value => setInputValue(value)}
          isMulti
          placeholder="Selecione os fundos"
          filterOption={() => true}
          noOptionsMessage={({ inputValue }) =>
            getNoOptionsMessage(inputValue, needMinChars)
          }
          components={isPremium ? undefined : { MenuList }}
        />
      </div>
    </div>
  );
};

ReturnsFilter.propTypes = {
  securities: PropTypes.array.isRequired,
  setSecurities: PropTypes.func.isRequired,
  assetsOptions: PropTypes.array.isRequired,
  dateFilter: PropTypes.node.isRequired,
};

export default ReturnsFilter;
