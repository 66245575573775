import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import YouTubePlayer from "../components/common/YouTubePlayer";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./beta.module.css";

const SORT_OPTIONS = [
  { value: "beta asc", label: "Menor Beta" },
  { value: "beta desc", label: "Maior Beta" },
  { value: "std_asset asc", label: "Menor Volatilidade" },
  { value: "std_asset desc", label: "Maior Volatilidade" },
  { value: "corr asc", label: "Menor Correlação" },
  { value: "corr desc", label: "Maior Correlação" },
];

const PERIOD_OPTIONS = [
  { value: "_1y", label: "1 ano" },
  { value: "_3y", label: "3 anos" },
  { value: "_5y", label: "5 anos" },
];

const Beta = () => {
  const [minBeta, setMinBeta] = useState(0);
  const [maxBeta, setMaxBeta] = useState(3);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [period, setPeriod] = useState(PERIOD_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allBeta: { nodes },
  } = useStaticQuery(graphql`
    query BetaQuery {
      allBeta {
        nodes {
          ticker
          buildTime
          _1y {
            beta
            corr
            std_asset
            std_bench
            last_updated
          }
          _3y {
            beta
            corr
            std_asset
            std_bench
            last_updated
          }
          _5y {
            beta
            corr
            std_asset
            std_bench
            last_updated
          }
        }
      }
    }
  `);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn } = useAuth();

  const filtered = orderBy(
    nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(
        ({ [period.value]: node }) =>
          node?.beta <= maxBeta && node?.beta >= minBeta
      )
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    item => item[period.value][fieldToSort],
    directionToSort
  );

  const lastUpdated =
    nodes[0] &&
    nodes[0][period.value] &&
    nodes[0][period.value].last_updated.replace("UTC", "Z");

  return (
    <PublicLayout
      seoProps={{
        title: "Beta",
        description: "Lista com o beta dos ativos da bolsa brasileira.",
      }}
      title="Beta dos Ativos da Bolsa"
      ctaMessage="Crie seus próprios portfólios e calcule o beta da sua carteira.">
      <div>
        <div className={css["youtubeContainer"]}>
          <p>
            A lista abaixo contém os valores do beta e a volatilidade para os
            ativos mais líquidos da bolsa brasileira. Os valores são calculados
            semanalmente utilizando os retornos diários.{" "}
            <Link to="/entenda-o-que-e-o-beta-e-aprenda-a-calcula-lo-utilizando-python">
              Entenda o que é o Beta e Aprenda a Calculá-lo Utilizando Python
            </Link>
            .
          </p>
          <YouTubePlayer
            videoId="xm7jYLnCGjk"
            width={500}
            height={315}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <LastUpdated date={lastUpdated} isISO />
        <div className={css["periodSelector"]}>
          <label htmlFor="beta-period">Período</label>
          <Select
            id="beta-period"
            options={PERIOD_OPTIONS}
            value={period}
            onChange={option => setPeriod(option)}
            styles={{
              ...SELECT_SMALL,
              container: provided => ({
                ...provided,
                width: "205px",
              }),
            }}
            isSearchable={false}
          />
        </div>
        <p>
          <b>Volatilidade IBOV (Diária / Anualizada) em {period.label}</b>:{" "}
          <span>
            {(
              nodes[0] &&
              nodes[0][period.value] &&
              nodes[0][period.value].std_bench * 100
            )?.toFixed(2)}
            %
          </span>{" "}
          /{" "}
          <span>
            {(
              nodes[0] &&
              nodes[0][period.value] &&
              nodes[0][period.value].std_bench * 100 * Math.sqrt(252)
            )?.toFixed(2)}
            %
          </span>
        </p>
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-beta">{`Min Beta`}</label>
            <input
              id="min-beta"
              type="number"
              min={0}
              value={minBeta}
              onChange={e => setMinBeta(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-beta">{`Max Beta`}</label>
            <input
              id="max-beta"
              type="number"
              min={0}
              value={maxBeta}
              onChange={e => setMaxBeta(e.target.value)}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="beta-asset">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="beta-order">Ordenar por</label>
            <Select
              id="beta-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="beta-tip"
                  data-tooltip-content={`Valor do beta em ${period.label}.`}>
                  Beta<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="beta-tip"
                  data-tooltip-content={`Correlação com o IBOV em ${period.label}.`}>
                  Correlação<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="beta-tip"
                  data-tooltip-content={`Desvio padrão dos retornos em ${period.label}.`}>
                  Vol. Diária<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="beta-tip"
                  data-tooltip-content="Vol. diária multiplicada por √252.">
                  Vol. Anualizada<sup>4</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr
                  key={node.ticker}
                  style={{
                    boxShadow:
                      node.ticker === "IBOV"
                        ? "0 0 0 2px #033660 inset"
                        : undefined,
                    fontWeight: node.ticker === "IBOV" ? "600" : undefined,
                  }}>
                  <td>{node.ticker}</td>
                  <td>{node[period.value].beta.toFixed(2)}</td>
                  <td>{node[period.value].corr.toFixed(2)}</td>
                  <td>{(node[period.value].std_asset * 100).toFixed(2)}%</td>
                  <td>
                    {(
                      node[period.value].std_asset *
                      Math.sqrt(252) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Valor do beta em {period.label}.
          </div>
          <div>
            <sup>2</sup> Correlação com o benchmark (Ibovespa).
          </div>
          <div>
            <sup>3</sup> Desvio padrão dos retornos em {period.label}.
          </div>
          <div>
            <sup>4</sup> Vol. diária multiplicada por √252.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="beta-tip" />
      </div>
    </PublicLayout>
  );
};

export default Beta;
