// extracted by mini-css-extract-plugin
export var arrow = "OnboardingStep-module--arrow--237d0";
export var buttonsContainer = "OnboardingStep-module--buttonsContainer--4bc85";
export var close = "OnboardingStep-module--close--16dcc";
export var content = "OnboardingStep-module--content--875a2";
export var dismiss = "OnboardingStep-module--dismiss--c3584";
export var popoverContent = "OnboardingStep-module--popoverContent--2b7c0";
export var popoverTitle = "OnboardingStep-module--popoverTitle--ba0b9";
export var primary = "OnboardingStep-module--primary--53177";
export var pulse = "OnboardingStep-module--pulse--56011";
export var slideDownAndFade = "OnboardingStep-module--slideDownAndFade--9d52b";
export var slideLeftAndFade = "OnboardingStep-module--slideLeftAndFade--a2103";
export var slideRightAndFade = "OnboardingStep-module--slideRightAndFade--bb3d8";
export var slideUpAndFade = "OnboardingStep-module--slideUpAndFade--9d5d9";