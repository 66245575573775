import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import Particles from "react-tsparticles";
import scrollToElement from "scroll-to-element";
import Typewriter from "typewriter-effect";
import LandingPageLayout from "../components/layouts/LandingPageLayout";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import AlertIcon from "../icons/alert.svg";
import StarIcon from "../icons/star.svg";
import PerformanceIcon from "../icons/chart.svg";
import TeacherIcon from "../icons/teacher.svg";
import Logo from "../images/logo-horizontal-white.svg";
import * as css from "./index.module.css";
import WhatsAppLink from "../components/checkout/WhatsAppLink";
import WhatsAppFloatingButton from "../components/checkout/WhatsAppFloatingButton";
import YouTubePlayer from "../components/common/YouTubePlayer";
import { useCallback } from "react";
import { loadLinksPreset } from "tsparticles-preset-links";
import YouTubeLink from "../components/common/YouTubeLink";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackPlansButtonClicked,
} from "../utils/amplitude";
import { useConfig } from "../hooks/useConfig";

const IndexPage = ({ location }) => {
  const {
    allJupyterNotebook: { nodes: posts },
    allYoutubeVideo: { nodes: videos },
  } = useStaticQuery(graphql`
    {
      allJupyterNotebook(
        sort: { fields: { date: DESC } }
        filter: { fields: { published: { eq: true } } }
        limit: 3
      ) {
        nodes {
          fields {
            slug
            title
            author
            date(formatString: "DD/MM/YYYY")
            spoiler
          }
        }
      }
      allYoutubeVideo(sort: { publishedAt: DESC }, limit: 4) {
        nodes {
          title
          videoId
          publishedAt(fromNow: true, locale: "pt-BR")
          thumbnail {
            url
            height
            width
          }
        }
      }
    }
  `);

  const renderPost = node => (
    <Link key={node.fields.slug} to={`/${node.fields.slug}`}>
      <h2>{node.fields.title}</h2>
      <div className={css["spoiler"]}>{node.fields.spoiler}</div>
      <div className={css["author"]}>
        Por {node.fields.author} — {node.fields.date}
      </div>
    </Link>
  );

  const renderVideo = node => (
    <div key={node.videoId}>
      <div className={css["ytTitle"]}>{node.title}</div>
      <div className={css["ytPublished"]}>Publicado {node.publishedAt}</div>
      <YouTubePlayer
        videoId={node.videoId}
        width={node.thumbnail.width}
        height={250}
      />
    </div>
  );

  const particlesInit = useCallback(async engine => {
    await loadLinksPreset(engine);
  }, []);

  const { IS_BLACKFRIDAY } = useConfig();
  const theme = IS_BLACKFRIDAY ? "#100826" : "#033660";

  return (
    <LandingPageLayout location={location} theme={theme}>
      <SEO title="Início" />
      <Navbar theme={theme} />
      <div className={css["content"]}>
        <div className={css["main"]}>
          <img src={Logo} alt="Logo QuantBrasil" />
          <div className={css["copy"]}>
            Para <b>investidores</b>. Em todos os <b>momentos</b>, em todos os{" "}
            <b>mercados</b>.
          </div>
          <div>
            <Typewriter
              options={{
                strings: [
                  "Backtests.",
                  "Long & Short.",
                  "Alertas.",
                  "Controle de Risco.",
                ],
                autoStart: true,
                loop: true,
                delay: 50,
                deleteSpeed: 20,
              }}
            />
          </div>
          <div className={css["buttonContainer"]}>
            <Link
              onClick={() => {
                logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                  ctaText: "Cadastrar",
                  page: window.location.pathname,
                });
              }}
              to="/cadastro">
              Cadastrar
            </Link>
          </div>
          <Link className={css["signin"]} to="/login">
            Entrar
          </Link>
          <div className={css["anchors"]}>
            <button
              onClick={() =>
                scrollToElement("#home-backtests", { offset: -150 })
              }>
              Backtests
            </button>
            <button
              onClick={() => scrollToElement("#home-alerts", { offset: -150 })}>
              Alertas
            </button>
            <button
              onClick={() => scrollToElement("#home-plans", { offset: -150 })}>
              <span>Seja </span>Premium
            </button>
          </div>
          <Particles
            canvasClassName={css["canvas"]}
            options={{
              preset: "links",
              fullScreen: {
                enable: false,
              },
              background: {
                color: theme,
              },
              particles: {
                number: {
                  value: 50,
                },
              },
            }}
            init={particlesInit}
          />
        </div>
        <div className={css["secondary"]}>
          <div id="home-tutorials">
            <div>
              <div className={css["title"]}>Educacional</div>
              <TeacherIcon />
            </div>
            <div>
              <p>
                Uma coleção de tutoriais ensinando você todo o necessário para
                desenvolver suas próprias análises e backtests.
              </p>
              <p>
                Aprenda <b>Python</b>, <b>Banco de Dados</b>, <b>Pandas</b>,{" "}
                <b>Matplotlib</b> e diversas outras ferramentas.
              </p>
            </div>
          </div>
          <div className={css["blog"]}>
            <div>Últimas do Blog</div>
            <div className={css["posts"]}>{posts.map(renderPost)}</div>
            <Link to="/blog">Ver todos ➞</Link>
          </div>
          <div className={css["whatsappContainer"]}>
            <WhatsAppLink />
          </div>
          <div id="home-backtests">
            <div>
              <div className={css["title"]}>Backtests</div>
              <PerformanceIcon />
            </div>
            <div>
              <p>
                Realize o backtest de diversas estratégias, no ativo e timeframe
                que você desejar.
              </p>
              <p>
                Customize parâmetros e descubra estratégias vencedoras antes
                escondidas!
              </p>
              <div className={css["buttonContainer"]}>
                <Link
                  onClick={() => {
                    logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                      ctaText: "Testar Backtests de Graça",
                      page: window.location.pathname,
                    });
                  }}
                  style={{ backgroundColor: theme }}
                  to="/cadastro">
                  Testar Backtests de Graça
                </Link>
              </div>
            </div>
          </div>
          <div id="home-alerts">
            <div>
              <div className={css["title"]}>Alertas</div>
              <AlertIcon />
            </div>
            <div>
              <p>
                Monitore sinais de setups vencedores diretamente na plataforma.
              </p>
              <p>
                Seja avisado quando o seu sinal favorito estiver se formando.{" "}
                <em>Receba notificações direto no WhatsApp!</em>
              </p>
              <div className={css["buttonContainer"]}>
                <Link
                  onClick={() => {
                    logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                      ctaText: "Conhecer Alertas",
                      page: window.location.pathname,
                    });
                  }}
                  to="/cadastro">
                  Conhecer Alertas
                </Link>
              </div>
            </div>
          </div>
          <div id="home-plans">
            <div>
              <div className={css["title"]}>Seja Premium</div>
              <StarIcon className={css["star"]} />
            </div>
            <div>
              <p>
                Seja um usuário <b>Premium</b> para aproveitar o QuantBrasil ao
                máximo.
              </p>
              <p>
                Desbloqueie todas as funcionalidades, incluindo{" "}
                <em>backtests em contratos futuros, criptos, BDRs e ETFs</em>,{" "}
                <em>cointegração em todos os ativos</em>,{" "}
                <em>alertas e rankings exclusivos</em>, e muito mais!
              </p>
              <div className={css["buttonContainer"]}>
                <Link
                  onClick={() =>
                    trackPlansButtonClicked({ ctaText: "Ver Planos" })
                  }
                  style={{ backgroundColor: "#eab838" }}
                  to="/planos">
                  Ver Planos
                </Link>
              </div>
            </div>
          </div>
          <div className={css["blog"]}>
            <div>Últimas do YouTube</div>
            <div className={css["videos"]}>{videos.map(renderVideo)}</div>
            <Link to="/youtube">Ver todos ➞</Link>
            <div className={css["shareLink"]}>
              <YouTubeLink />
            </div>
          </div>
        </div>
      </div>
      <WhatsAppFloatingButton />
    </LandingPageLayout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
