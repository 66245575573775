import PropTypes from "prop-types";
import * as css from "./PlansPopup.module.css";
import Modal from "react-modal";
import CloseIcon from "../../icons/close.svg";
import { useAuth } from "../../hooks/useAuth";
import Countdown from "react-countdown";
import { useEffect } from "react";
import noScroll from "no-scroll";

export const PROMO_DEADLINE = "2023-11-24T23:59:59";

export const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  formatted,
}) => {
  if (completed) return <div>{`It's time!`}</div>;

  return (
    <div className={css["container"]}>
      <div className={css["part"]}>
        <div>{formatted.days}</div>
        <div>{days === 1 ? "Dia" : "Dias"}</div>
      </div>
      <div className={css["part"]}>
        <div>{formatted.hours}</div>
        <div>{hours === 1 ? "Hora" : "Horas"}</div>
      </div>
      <div className={css["part"]}>
        <div>{formatted.minutes}</div>
        <div>{minutes === 1 ? "Minuto" : "Minutos"}</div>
      </div>
      <div className={css["part"]}>
        <div>{formatted.seconds}</div>
        <div>{seconds === 1 ? "Segundo" : "Segundos"}</div>
      </div>
    </div>
  );
};

const PlansPopup = ({ isOpen, onClose, onClick, discount }) => {
  const { user } = useAuth();

  const firstName = !user
    ? null
    : user?.userName?.includes(" ")
    ? user?.userName?.split(" ")[0]
    : user?.userName;

  // Prevent scrolling parent component when modal is open
  useEffect(() => {
    if (isOpen) noScroll.on();
    return () => noScroll.off();
  });

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={{
        base: css["modal"],
        afterOpen: css["modalAfter"],
        beforeClose: css["modalBefore"],
      }}
      overlayClassName={css["overlay"]}
      onRequestClose={onClose}
      contentLabel="Modal">
      <div>
        <button className={css["close"]} onClick={onClose}>
          <CloseIcon aria-hidden />
        </button>
      </div>
      <div className={css["content"]}>
        <div className={css["title"]}>É por tempo limitado!</div>
        <p>
          {firstName ? `${firstName}, essa` : "Essa"} é a sua oportunidade de
          aproveitar <b>todas as funcionalidades</b> do QuantBrasil com um
          desconto que <b>não costuma se repetir</b>.
        </p>
        <p>A promoção terminará em:</p>
        <div className={css["countdownContainer"]}>
          <Countdown
            date={PROMO_DEADLINE}
            renderer={renderer}
            zeroPadDays={2}
          />
        </div>
        <p>
          Garanta a assinatura do QuantBrasil por um preço que cabe no seu bolso
          e melhore a eficiência dos seus investimentos.
        </p>
        <div className={css["buttons"]}>
          <button className={css["button"]} onClick={onClick}>
            Assinar com <b>{discount}% OFF</b>
          </button>
          <button className={css["notNow"]} onClick={onClose}>
            Prefiro pagar caro
          </button>
        </div>
      </div>
    </Modal>
  );
};

PlansPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  discount: PropTypes.number.isRequired,
};

export default PlansPopup;
