import { Link } from "gatsby";
import PropTypes from "prop-types";
import Particles from "react-tsparticles";
import Typewriter from "typewriter-effect";
import Logo from "../../images/logo-horizontal-white.svg";
import * as css from "./EntryLayout.module.css";
import { loadLinksPreset } from "tsparticles-preset-links";
import { useCallback } from "react";
import { trackPlansButtonClicked } from "../../utils/amplitude";

const EntryLayout = ({ children, themeColor = "#033660" }) => {
  const particlesInit = useCallback(async engine => {
    await loadLinksPreset(engine);
  }, []);

  return (
    <div className={css["container"]}>
      <div
        className={css["left"]}
        style={{
          backgroundColor: themeColor,
        }}>
        <Link to="/">
          <img src={Logo} alt="Logo QuantBrasil" />
        </Link>
        <div className={css["copy"]}>
          Para <b>investidores</b>. Em todos os <b>momentos</b>, em todos os{" "}
          <b>mercados</b>.
        </div>
        <div className={css["typeContainer"]}>
          <Typewriter
            options={{
              strings: [
                "Backtests.",
                "Long & Short.",
                "Alertas.",
                "Controle de Risco.",
              ],
              autoStart: true,
              loop: true,
              delay: 50,
              deleteSpeed: 20,
              wrapperClassName: css["typewriterWrapper"],
            }}
          />
        </div>
        <div className={css["links"]}>
          <Link to="/">Home</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/sobre">Sobre</Link>
          <Link
            onClick={() => trackPlansButtonClicked({ ctaText: "Planos" })}
            to="/planos">
            Planos
          </Link>
        </div>
        <Particles
          canvasClassName={css["canvas"]}
          options={{
            preset: "links",
            fullScreen: {
              enable: false,
            },
            background: {
              color: themeColor,
            },
            particles: {
              number: {
                value: 30,
              },
            },
          }}
          init={particlesInit}
        />
      </div>
      <div className={css["right"]}>{children}</div>
    </div>
  );
};

EntryLayout.propTypes = {
  children: PropTypes.node.isRequired,
  themeColor: PropTypes.string,
};

export default EntryLayout;
