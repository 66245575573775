// extracted by mini-css-extract-plugin
export var asset = "RegisterCointegration-module--asset--3d798";
export var button = "RegisterCointegration-module--button--32fba";
export var favorite = "RegisterCointegration-module--favorite--6ed33";
export var form = "RegisterCointegration-module--form--18fad";
export var header = "RegisterCointegration-module--header--09e1a";
export var info = "RegisterCointegration-module--info--f2fa6";
export var inputContainer = "RegisterCointegration-module--inputContainer--d1698";
export var modal = "RegisterCointegration-module--modal--6b0b9";
export var registerButton = "RegisterCointegration-module--registerButton--662c0";
export var success = "RegisterCointegration-module--success--77c46";
export var tips = "RegisterCointegration-module--tips--9bde2";
export var warning = "RegisterCointegration-module--warning--3e52f";