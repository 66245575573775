import { Link } from "gatsby";
import { useState } from "react";
import { useMutation } from "react-query";
import { StringParam,useQueryParams } from "use-query-params";

import { useAuth } from "../../hooks/useAuth";
import { isValidPassword, showError } from "../../utils/utils";
import SliderDiv from "../common/SliderDiv";
import * as css from "./Login.module.css";
import RedirectMessage from "./RedirectMessage";

const ResetPassword = () => {
  const [{ token, to }] = useQueryParams({
    token: StringParam,
    to: StringParam,
  });

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [hasReset, setReset] = useState(false);

  const { resetPassword } = useAuth();

  const mutation = useMutation(() => resetPassword(token, to, password), {
    onSuccess: () => {
      setReset(true);
    },
    onError: showError,
  });

  const onClick = e => {
    e.preventDefault();
    if (!canSubmit) return;
    mutation.mutate();
  };

  const canSubmit =
    !mutation.isLoading && isValidPassword(password, confirmPassword);

  if (hasReset) {
    return (
      <SliderDiv>
        <div className={css["confirmation"]}>
          <RedirectMessage />
        </div>
      </SliderDiv>
    );
  }

  return (
    <div className={css["box"]}>
      <h2>Redefinir Senha</h2>
      <form>
        <div className={css["input"]}>
          <label>Nova Senha</label>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <div className={css["input"]}>
          <label>Confirmar Senha</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />
        </div>
        <button
          disabled={!canSubmit}
          className={css["button"]}
          onClick={onClick}>
          {mutation.isLoading ? "Enviando..." : "Enviar"}
        </button>
        <div className={css["createAccount"]}>
          <Link to="/login">Voltar</Link>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
