export const TIMEFRAME_OPTIONS = [
  { value: "W1", label: "Semanal" },
  { value: "D1", label: "Diário" },
  { value: "H4", label: "240 Minutos" },
  { value: "H2", label: "120 Minutos" },
  { value: "H1", label: "60 Minutos" },
  { value: "M30", label: "30 Minutos" },
  { value: "M15", label: "15 Minutos" },
  { value: "M5", label: "5 Minutos" },
];

export const INTRADAY_TIMEFRAMES = ["H4", "H2", "H1", "M30", "M15", "M5"];
const ALL_TIMEFRAMES = TIMEFRAME_OPTIONS.map(({ value }) => value);

const TARGET_BANDS = [
  { value: "middle", label: "Banda do Meio" },
  { value: "opposite", label: "Banda Oposta" },
];

export const STRATEGY_OPTIONS = [
  {
    value: "ifr2",
    label: "IFR2",
    order: 1,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "rsi",
      "stopInDays",
      "window",
      "mm50IsUp",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    isPublic: true,
  },
  {
    value: "long_rsi",
    label: "IFR de Compra",
    order: 2,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    parameters: [
      "rsiWindow",
      "stopInDays",
      "stopInPct",
      "exitWindow",
      "exitRsi",
      "entryRsi",
      "target",
      "entry",
      "rollingAvgType",
      "rollingAvgWindow",
      "mme80IsUp",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    entry: [
      {
        value: "rsi",
        label: "Valor de IFR",
      },
      {
        value: "rolling_avg",
        label: "Cruzamento Média Móvel",
      },
    ],
    target: [
      {
        value: "window",
        label: "Máxima da Janela",
      },
      {
        value: "rsi",
        label: "Valor de IFR",
      },
    ],
    rollingAvgType: [
      {
        label: "Aritmética",
        value: "simple",
      },
      {
        label: "Exponencial",
        value: "ewm",
      },
    ],
  },
  {
    value: "long_stochastic",
    label: "Estocástico Lento de Compra",
    order: 3,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    parameters: [
      "kWindow",
      "mmaWindow",
      "stopInDays",
      "stopInPct",
      "exitWindow",
      "exitStochastic",
      "entryStochastic",
      "target",
      "entry",
      "rollingAvgType",
      "rollingAvgWindow",
      "mme80IsUp",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    entry: [
      {
        value: "stochastic",
        label: "Valor de Estocástico",
      },
      {
        value: "rolling_avg",
        label: "Cruzamento Média Móvel",
      },
    ],
    target: [
      {
        value: "window",
        label: "Máxima da Janela",
      },
      {
        value: "stochastic",
        label: "Valor de Estocástico",
      },
    ],
    rollingAvgType: [
      {
        label: "Aritmética",
        value: "simple",
      },
      {
        label: "Exponencial",
        value: "ewm",
      },
    ],
  },
  {
    value: "long_turnaround",
    label: "Turnaround",
    order: 6,
    allowedTimeframes: ["D1", "W1"],
    parameters: ["exhaustionPct", "window", "negativeSequence"],
    isPublic: true,
  },
  {
    value: "maxmin",
    label: "Máximas e Mínimas de Compra",
    order: 3,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "window",
      "stopInDays",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
      "applyKeltnerFilter",
      "keltnerWindow",
      "keltnerMultiplier",
    ],
  },
  {
    value: "long_123",
    label: "123 de Compra",
    order: 4,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "tradersEden",
      "insideBar",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    stop: [
      {
        value: "candle2",
        label: "Mínima do Candle 2",
      },
      {
        value: "candle3",
        label: "Mínima do Candle 3",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "long_pfr",
    label: "PFR de Compra",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "window",
      "hammer",
      "tradersEden",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },
  {
    value: "long_intraday_variation",
    label: "Variação Intradiária de Compra",
    order: 6,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    isPremium: true,
    isDayTradeOnly: true,
    ignoreTimeframe: true,
    parameters: [
      "entryVariation",
      "exitVariation",
      "stopInPct",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    isNew: true,
  },
  {
    value: "long_atypical_variation",
    label: "Variação Atípica de Compra",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "zscoreThreshold",
      "target",
      "stopInStd",
      "rollingWindow",
      "exitWindow",
      "stdFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    target: [
      {
        value: "std",
        label: "Desvio-Padrão",
      },
      {
        value: "window",
        label: "Janela",
      },
    ],
    isNew: true,
  },
  {
    value: "long_hilo_activator",
    label: "HiLo Activator de Compra",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: ["window", "isDayTrade", "closeTimeLimit", "minOpenTime"],
    isNew: true,
  },
  {
    value: "long_three_bar",
    label: "Três Médias de Compra",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "mmaWindow",
      "channelWindow",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    isNew: true,
  },
  {
    value: "long_reversal_bb",
    label: "Reversão de Banda de Compra",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "numStd",
      "mmaWindow",
      "rsiWindow",
      "rsi",
      "window",
      "stopInPct",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
      "applyRsiFilter",
    ],
  },

  {
    value: "long_open_bb",
    label: "Abertura de Banda de Compra",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "numStd",
      "mmaWindow",
      "rsiWindow",
      "rsi",
      "window",
      "stopInPct",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
      "applyRsiFilter",
    ],
  },
  {
    value: "long_candle_trap",
    label: "Trap de Compra no Candle",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "hammer",
      "tradersEden",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },
  {
    value: "long_trap",
    label: "Trap de Compra na Média",
    order: 9,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "rollingAvgType",
      "rollingAvgWindow",
      "tradersEden",
      "riskFactor",
      "hammer",
      "candlesAbove",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    rollingAvgType: [
      {
        label: "Aritmética",
        value: "simple",
      },
      {
        label: "Exponencial",
        value: "ewm",
      },
    ],
  },
  {
    value: "long_shark",
    label: "Shark de Compra",
    order: 8,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "tradersEden",
      "entry",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    entry: [
      {
        value: "candle1",
        label: "Máxima do Candle 1",
      },
      {
        value: "candle2",
        label: "Máxima do Candle 2",
      },
      {
        value: "candle3",
        label: "Máxima do Candle 3",
      },
    ],
    stop: [
      {
        value: "candle1",
        label: "Mínima do Candle 1",
      },
      {
        value: "candle2",
        label: "Mínima do Candle 2",
      },
      {
        value: "candle3",
        label: "Mínima do Candle 3",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "long_insidebar",
    label: "Insidebar de Compra",
    order: 7,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    fixedRisk: true,
    parameters: [
      "tradersEden",
      "entry",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    entry: [
      {
        value: "candle1",
        label: "Máxima do Candle 1",
      },
      {
        value: "candle2",
        label: "Máxima do Candle 2",
      },
    ],
    stop: [
      {
        value: "candle1",
        label: "Mínima do Candle 1",
      },
      {
        value: "candle2",
        label: "Mínima do Candle 2",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "long_breakout",
    label: "Breakout de Compra",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    parameters: [
      "tradersEden",
      "hammer",
      "target",
      "fiboRetracement",
      "fiboExtension",
      "targetInPct",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    target: [
      {
        value: "percentage",
        label: "Porcentagem",
      },
      {
        value: "fibo",
        label: "Extensão de Fibonacci",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "long_wick_candle",
    label: "Candle Pavio de Compra",
    order: 10,
    allowedTimeframes: ["D1", "W1"],
    parameters: ["gapPct", "bodyPct", "tradersEden", "stopInPct"],
    blockedInCrypto: true,
  },
  {
    value: "long_gaptrap",
    label: "Gap Trap de Compra",
    order: 11,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    blockedInCrypto: true,
    isPremium: true,
    parameters: [
      "target",
      "fiboRetracement",
      "fiboExtension",
      "targetInPct",
      "riskFactor",
      "isDayTrade",
    ],
    target: [
      {
        value: "percentage",
        label: "Porcentagem",
      },
      {
        value: "fibo",
        label: "Extensão de Fibonacci",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "long_by_time",
    label: "Compra por Horário",
    order: 12,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    isPremium: true,
    isNew: true,
    isDayTradeOnly: true,
    parameters: [
      "tradersEden",
      "targetInPct",
      "entryTime",
      "exitTime",
      "isDayTrade",
      "stopInPct",
      "hammer",
    ],
  },
  {
    value: "long_sdc",
    label: "Saudade de Casa de Compra",
    order: 12,
    fixedRisk: true,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    parameters: ["targetBand", "isDayTrade"],
    targetBands: TARGET_BANDS,
    blockedInCrypto: true,
    isPremium: true,
  },
  {
    value: "short_123",
    label: "123 de Venda",
    order: 1,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "tradersEden",
      "insideBar",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    stop: [
      {
        value: "candle2",
        label: "Máxima do Candle 2",
      },
      {
        value: "candle3",
        label: "Máxima do Candle 3",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "short_pfr",
    label: "PFR de Venda",
    order: 2,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "window",
      "hammer",
      "tradersEden",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },
  {
    value: "short_maxmin",
    label: "Máximas e Mínimas de Venda",
    order: 3,
    isPremium: true,
    isShort: true,
    allowedTimeframes: ALL_TIMEFRAMES,
    blockIntradayIfNotPremium: true,
    parameters: [
      "window",
      "stopInDays",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
      "applyKeltnerFilter",
      "keltnerWindow",
      "keltnerMultiplier",
    ],
  },
  {
    value: "short_candle_trap",
    label: "Trap de Venda no Candle",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "hammer",
      "tradersEden",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },
  {
    value: "short_intraday_variation",
    label: "Variação Intradiária de Venda",
    order: 6,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    isDayTradeOnly: true,
    ignoreTimeframe: true,
    parameters: [
      "entryVariation",
      "exitVariation",
      "stopInPct",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    isNew: true,
  },
  {
    value: "short_atypical_variation",
    label: "Variação Atípica de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: [
      "zscoreThreshold",
      "target",
      "stopInStd",
      "rollingWindow",
      "exitWindow",
      "stdFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    target: [
      {
        value: "std",
        label: "Desvio-Padrão",
      },
      {
        value: "window",
        label: "Janela",
      },
    ],
    isNew: true,
  },
  {
    value: "short_hilo_activator",
    label: "HiLo Activator de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: ["window", "isDayTrade", "closeTimeLimit", "minOpenTime"],
    isNew: true,
  },
  {
    value: "short_three_bar",
    label: "Três Médias de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: [
      "mmaWindow",
      "channelWindow",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    isNew: true,
  },
  {
    value: "short_reversal_bb",
    label: "Reversão de Banda de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: [
      "numStd",
      "mmaWindow",
      "rsiWindow",
      "rsi",
      "window",
      "stopInPct",
      "applyRsiFilter",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },

  {
    value: "short_open_bb",
    label: "Abertura de Banda de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: [
      "numStd",
      "mmaWindow",
      "rsiWindow",
      "rsi",
      "window",
      "stopInPct",
      "applyRsiFilter",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
  },
  {
    value: "short_stochastic",
    label: "Estocástico Lento de Venda",
    order: 3,
    allowedTimeframes: ALL_TIMEFRAMES,
    isPremium: true,
    isShort: true,
    parameters: [
      "kWindow",
      "mmaWindow",
      "stopInDays",
      "stopInPct",
      "exitWindow",
      "exitStochastic",
      "entryStochastic",
      "target",
      "entry",
      "rollingAvgType",
      "rollingAvgWindow",
      "mme80IsUp",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    entry: [
      {
        value: "stochastic",
        label: "Valor de Estocástico",
      },
      {
        value: "rolling_avg",
        label: "Cruzamento Média Móvel",
      },
    ],
    target: [
      {
        value: "window",
        label: "Mínima da Janela",
      },
      {
        value: "stochastic",
        label: "Valor de Estocástico",
      },
    ],
    rollingAvgType: [
      {
        label: "Aritmética",
        value: "simple",
      },
      {
        label: "Exponencial",
        value: "ewm",
      },
    ],
  },
  {
    value: "short_shark",
    label: "Shark de Venda",
    order: 6,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "tradersEden",
      "entry",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    stop: [
      {
        value: "candle1",
        label: "Máxima do Candle 1",
      },
      {
        value: "candle2",
        label: "Máxima do Candle 2",
      },
      {
        value: "candle3",
        label: "Máxima do Candle 3",
      },
    ],
    entry: [
      {
        value: "candle1",
        label: "Mínima do Candle 1",
      },
      {
        value: "candle2",
        label: "Mínima do Candle 2",
      },
      {
        value: "candle3",
        label: "Mínima do Candle 3",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "short_insidebar",
    label: "Insidebar de Venda",
    order: 5,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "tradersEden",
      "entry",
      "stop",
      "target",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    stop: [
      {
        value: "candle1",
        label: "Máxima do Candle 1",
      },
      {
        value: "candle2",
        label: "Máxima do Candle 2",
      },
    ],
    entry: [
      {
        value: "candle1",
        label: "Mínima do Candle 1",
      },
      {
        value: "candle2",
        label: "Mínima do Candle 2",
      },
    ],
    target: [
      {
        value: "amplitude",
        label: "Amplitude",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "short_breakout",
    label: "Breakout de Venda",
    order: 4,
    allowedTimeframes: ALL_TIMEFRAMES,
    fixedRisk: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "tradersEden",
      "hammer",
      "target",
      "fiboRetracement",
      "fiboExtension",
      "targetInPct",
      "riskFactor",
      "isDayTrade",
      "closeTimeLimit",
      "minOpenTime",
    ],
    target: [
      {
        value: "percentage",
        label: "Porcentagem",
      },
      {
        value: "fibo",
        label: "Extensão de Fibonacci",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "short_wick_candle",
    label: "Candle Pavio de Venda",
    order: 7,
    allowedTimeframes: ["D1", "W1"],
    parameters: ["gapPct", "bodyPct", "tradersEden", "stopInPct"],
    isShort: true,
    blockedInCrypto: true,
    isPremium: true,
  },
  {
    value: "short_gaptrap",
    label: "Gap Trap de Venda",
    order: 8,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    blockedInCrypto: true,
    isPremium: true,
    isShort: true,
    parameters: [
      "target",
      "fiboRetracement",
      "fiboExtension",
      "targetInPct",
      "riskFactor",
      "isDayTrade",
    ],
    target: [
      {
        value: "percentage",
        label: "Porcentagem",
      },
      {
        value: "fibo",
        label: "Extensão de Fibonacci",
      },
      {
        value: "risk",
        label: "Risco Fixo",
      },
    ],
  },
  {
    value: "short_sdc",
    label: "Saudade de Casa de Venda",
    order: 9,
    fixedRisk: true,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    parameters: ["targetBand", "isDayTrade"],
    targetBands: TARGET_BANDS,
    isShort: true,
    blockedInCrypto: true,
    isPremium: true,
  },
  {
    value: "short_by_time",
    label: "Venda por Horário",
    order: 10,
    allowedTimeframes: INTRADAY_TIMEFRAMES,
    isPremium: true,
    isNew: true,
    isShort: true,
    isDayTradeOnly: true,
    parameters: [
      "tradersEden",
      "targetInPct",
      "entryTime",
      "exitTime",
      "isDayTrade",
      "stopInPct",
      "hammer",
    ],
  },
];
