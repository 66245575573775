import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { toPercent } from "../../utils/utils";
import * as css from "../backtests/CapitalTooltip.module.css";

const computeReturn = data => {
  const initialValue = 1;
  const finalValue = data.y;
  const adjust = initialValue === 0 ? 1 : 0;
  const sign = initialValue >= 0 ? 1 : -1;
  return sign * ((finalValue + adjust) / (initialValue + adjust) - 1);
};

const RobotsReturnsTooltip = ({ data }) => {
  return (
    <div className={css["container"]}>
      <div>
        <b style={{ color: data.color }}>{data.name}</b>
      </div>
      <div>
        <em>
          Em{" "}
          {DateTime.fromISO(data.xFormatted)
            .setLocale("pt-BR")
            .toFormat("dd LLL yy")}
        </em>
      </div>
      <div>
        <b>Variação no dia</b>: {toPercent(data.variation)}
      </div>
      <div>
        <b>Retorno acumulado:</b> {toPercent(computeReturn(data))}
      </div>
    </div>
  );
};

RobotsReturnsTooltip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RobotsReturnsTooltip;
