import { Link } from "gatsby";
import * as css from "./CointegrationWidget.module.css";
import Icon from "../../icons/volatility.svg";
import FeedWidget from "./FeedWidget";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const CointegrationWidget = () => {
  return (
    <FeedWidget>
      <div className={css["icon"]}>
        <Icon />
      </div>
      <p>
        Coloque a matemática ao seu favor no <b>Long & Short</b> por
        cointegração.
      </p>
      <Link
        className={css["button"]}
        to="/cointegracao"
        onClick={() =>
          logAmplitudeEvent(EVENT_MAP.FEED_WIDGET_BUTTON_CLICKED, {
            widget: "cointegration",
          })
        }>
        Buscar Cointegrações
      </Link>
    </FeedWidget>
  );
};

export default CointegrationWidget;
