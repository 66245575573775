import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import { SELECT_MEDIUM } from "../../utils/select";
import { useAuth } from "../../hooks/useAuth";
import Select from "react-select";
import PropTypes from "prop-types";
import { groupBy } from "lodash";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

export const parseStatus = status => {
  switch (status) {
    case "ongoing":
      return "Em execução";
    case "completed":
      return "Finalizados";
    case "canceled":
      return "Cancelados";
    default:
      return status;
  }
};

const parseOptions = options => {
  const grouped = groupBy(options, "status");
  return Object.keys(grouped).map(status => ({
    label: parseStatus(status),
    options: grouped[status].map(o => ({
      label: o.label,
      value: o.id,
      isDisabled: status !== "completed",
    })),
  }));
};

const UserBatchesFilter = ({ selectedId, setSelectedId }) => {
  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const { data, isLoading } = useQuery(
    ["userBatches", user.userId],
    () => callAPI(`/api/batch/me`),
    {
      onError: error => showError(error),
    }
  );

  const getOptions = () => {
    if (!data) {
      return [];
    }

    return parseOptions(data);
  };

  const {
    step: changeRankingStep,
    markStepAsCompleted: markChangeRankingAsCompleted,
    showStep: showChangeRankingStep,
  } = useOnboardingStep("CHANGE_USER_BATCH");

  return (
    <Popover.Root open={showChangeRankingStep}>
      <Popover.Trigger asChild>
        <div style={{ width: "100%" }}>
          <label htmlFor="batch-select">Nome</label>
          <Select
            id="batch-select"
            isLoading={isLoading}
            options={getOptions()}
            placeholder="Selecione um ranking"
            value={data?.find(d => d.id === selectedId)}
            onChange={option => {
              setSelectedId(option.value);
            }}
            styles={{
              ...SELECT_MEDIUM,
            }}
          />
        </div>
      </Popover.Trigger>
      {showChangeRankingStep && (
        <OnboardingStep
          step={changeRankingStep}
          onConfirm={markChangeRankingAsCompleted}
          onDismiss={markChangeRankingAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

UserBatchesFilter.propTypes = {
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
};

export default UserBatchesFilter;
