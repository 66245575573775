// extracted by mini-css-extract-plugin
export var cancel = "ReportBacktest-module--cancel--5ef71";
export var formLabel = "ReportBacktest-module--formLabel--5516c";
export var hint = "ReportBacktest-module--hint--65adf";
export var modalButtonsContainer = "ReportBacktest-module--modalButtonsContainer--2c490";
export var modalContainer = "ReportBacktest-module--modalContainer--88b28";
export var radioContainer = "ReportBacktest-module--radioContainer--3a180";
export var reportButton = "ReportBacktest-module--reportButton--c7e49";
export var send = "ReportBacktest-module--send--104a7";
export var spinner = "ReportBacktest-module--spinner--7039f";
export var textContainer = "ReportBacktest-module--textContainer--d91b0";
export var warning = "ReportBacktest-module--warning--b57ed";