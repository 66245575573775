import EntryLayout from "../components/layouts/EntryLayout";
import ResetPassword from "../components/login/ResetPassword";
import SEO from "../components/seo";

const ResetPasswordPage = () => {
  return (
    <EntryLayout>
      <SEO title="Redefinir Senha" />
      <ResetPassword />
    </EntryLayout>
  );
};

export default ResetPasswordPage;
