import PropTypes from "prop-types";
import * as css from "./FeedWidget.module.css";

const FeedWidget = ({ children }) => {
  return <div className={css["box"]}>{children}</div>;
};

FeedWidget.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedWidget;
