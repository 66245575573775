import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../common/InstagramLink";
import LastUpdated from "../common/LastUpdated";
import { Tooltip } from "react-tooltip";
import ShowPortfolios from "../portfolios/ShowPortfolios";
import { useAuth } from "../../hooks/useAuth";
import { usePortfolios } from "../../hooks/usePortfolios";
import { getRankedNodes } from "../../hooks/useSortedLowRisk";
import { SELECT_SMALL } from "../../utils/select";
import * as css from "./LowRisk.module.css";
import PropTypes from "prop-types";

const SORT_OPTIONS = [
  { value: "overallRanking asc", label: "Melhor Posição" },
  { value: "dividendRanking asc", label: "Maior Dividendo" },
  { value: "betaRanking asc", label: "Menor Beta" },
  { value: "variationRanking asc", label: "Maior Variação" },
];

const LowRisk = ({ nodes, topAssets, isB3, descriptionParagraph }) => {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn } = useAuth();

  const filtered = orderBy(
    getRankedNodes(nodes)
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    item => item[fieldToSort],
    directionToSort
  );

  const lastUpdated = nodes[0]?.buildTime.replace("UTC", "Z");

  return (
    <>
      <div>
        {descriptionParagraph}
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="lr-asset">Ativo</label>
            <input
              id="lr-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder={`Ex: ${isB3 ? "PETR4" : "AAPL"}`}
            />
          </div>
          <div>
            <label htmlFor="lr-order">Ordenar por</label>
            <Select
              id="lr-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "265px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && isB3 && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>
                  <span
                    data-tooltip-id="lr-tip"
                    data-tooltip-content={`Ranking final considerando as posições em Dividendos, Beta e Variação.`}>
                    Posição<sup>1</sup>
                  </span>
                </th>
                <th>Ativo</th>
                <th>
                  <span
                    data-tooltip-id="lr-tip"
                    data-tooltip-content={`Dividend Yield anual.`}>
                    Dividendos<sup>2</sup>
                  </span>
                </th>
                <th>
                  <span
                    data-tooltip-id="lr-tip"
                    data-tooltip-content={`Beta em ${isB3 ? "2" : "5"} anos.`}>
                    Beta<sup>3</sup>
                  </span>
                </th>
                <th>
                  <span
                    data-tooltip-id="lr-tip"
                    data-tooltip-content={`Variação de preço nos últimos 365 dias.`}>
                    Variação<sup>4</sup>
                  </span>
                </th>
                <th>
                  <span
                    data-tooltip-id="lr-tip"
                    data-tooltip-content={`Soma da posição do ativo em cada um dos fatores.`}>
                    Pontos<sup>5</sup>
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td
                    style={{
                      color:
                        node.overallRanking <= topAssets
                          ? "#49ce8b"
                          : undefined,
                      fontWeight:
                        node.overallRanking <= topAssets ? 600 : undefined,
                    }}>
                    {node.overallRanking}
                  </td>
                  <td>{node.ticker}</td>
                  <td>
                    <span className={css["rankValue"]}>
                      {(node.dividend_yield * 100).toFixed(2)}%
                    </span>{" "}
                    <span className={css["rankPosition"]}>
                      {node.dividendRanking}º
                    </span>
                  </td>
                  <td>
                    <span className={css["rankValue"]}>
                      {node.beta.toFixed(2)}
                    </span>
                    <span className={css["rankPosition"]}>
                      {node.betaRanking}º
                    </span>
                  </td>
                  <td>
                    <span className={css["rankValue"]}>
                      {(node.variation * 100).toFixed(2)}%
                    </span>
                    <span className={css["rankPosition"]}>
                      {node.variationRanking}º
                    </span>
                  </td>
                  <td>{node.overallPoints}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Ranking final considerando as posições em Dividendos,
            Beta e Variação.
          </div>
          <div>
            <sup>2</sup> Dividend Yield anual.
          </div>
          <div>
            <sup>3</sup> Beta em {isB3 ? "2" : "5"} anos.
          </div>
          <div>
            <sup>4</sup> Variação de preço nos últimos 365 dias.
          </div>
          <div>
            <sup>5</sup> Soma da posição do ativo em cada um dos fatores.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="lr-tip" />
      </div>
    </>
  );
};

LowRisk.propTypes = {
  nodes: PropTypes.array.isRequired,
  isB3: PropTypes.bool.isRequired,
  descriptionParagraph: PropTypes.node.isRequired,
  topAssets: PropTypes.number.isRequired,
};

export default LowRisk;
