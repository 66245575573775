import { graphql, useStaticQuery } from "gatsby";
import YouTubeLink from "../components/common/YouTubeLink";
import YouTubePlayer from "../components/common/YouTubePlayer";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import * as css from "./youtube.module.css";
import Linkify from "linkify-react";
import "linkify-plugin-hashtag";

const POSTS_ON_TOP = 1;

const YouTubePage = () => {
  const {
    allYoutubeVideo: { nodes: videos },
  } = useStaticQuery(graphql`{
  allYoutubeVideo(sort: {publishedAt: DESC}) {
    nodes {
      title
      videoId
      description
      publishedAt(fromNow: true, locale: "pt-BR")
      thumbnail {
        url
        height
        width
      }
    }
  }
}`);

  const renderVideo = node => (
    <div key={node.videoId}>
      <h2 className={css["ytTitle"]}>{node.title}</h2>
      <div className={css["ytPublished"]}>Publicado {node.publishedAt}.</div>
      <div className={css["videoContainer"]}>
        <YouTubePlayer
          videoId={node.videoId}
          className={css["iframeContainer"]}
          width={600}
          height={400}
        />
        <Linkify
          tagName="div"
          className={css["ytDesc"]}
          options={{
            target: "_blank",
            rel: "noreferrer",
            formatHref: {
              hashtag: href => "https://youtube.com/hashtag/" + href.substr(1),
            },
          }}>
          {node.description}
        </Linkify>
      </div>
    </div>
  );

  const top = videos.slice(0, POSTS_ON_TOP);
  const rest = videos.slice(POSTS_ON_TOP);

  return (
    <div>
      <SEO
        title="Vídeos do YouTube"
        description="Assista aos conteúdos do QuantBrasil no YouTube. Vídeos sobre análise quantitativa, day trade, swing trade, gerenciamento de risco, e muito mais!"
      />
      <Navbar />
      <div className={css["main"]}>
        <h1>Vídeos do YouTube</h1>
        <p>
          Acompanhe os últimos vídeos do QuantBrasil no nosso{" "}
          <a
            href="https://www.youtube.com/c/QuantBrasil"
            target="_blank"
            rel="noreferrer">
            canal do YouTube
          </a>
          . Não deixe de se inscrever e ativar o sino das notificações para não
          perder nenhum conteúdo!
        </p>
        <div className={css["top"]}>{top.map(renderVideo)}</div>
        <div className={css["banner"]}>
          <YouTubeLink />
        </div>
        <div className={css["allVideos"]}>{rest.map(renderVideo)}</div>
      </div>
      <Footer />
    </div>
  );
};

export default YouTubePage;
