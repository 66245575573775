import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../common/InstagramLink";
import LastUpdated from "../common/LastUpdated";
import { Tooltip } from "react-tooltip";
import ShowPortfolios from "../portfolios/ShowPortfolios";
import { useAuth } from "../../hooks/useAuth";
import { usePortfolios } from "../../hooks/usePortfolios";
import { SELECT_SMALL } from "../../utils/select";
import * as css from "./ranking.module.css";
import PropTypes from "prop-types";

const SORT_OPTIONS = [
  { value: "variation desc", label: "Melhor Retorno" },
  { value: "variation asc", label: "Pior Retorno" },
];

const PERIOD_OPTIONS = [
  { value: "_currentYear", label: new Date().getFullYear() + " (YTD)" },
  { value: "_currentMonth", label: "Esse mês (MTD)" },
  { value: "_currentWeek", label: "Essa semana (WTD)" },
  { value: "_12mo", label: "365 dias" },
  { value: "_6mo", label: "180 dias" },
  { value: "_3mo", label: "90 dias" },
  { value: "_1mo", label: "30 dias" },
];

const FILTER_OPTIONS = [
  { value: "all", label: "Todos" },
  { value: "better", label: "Melhores" },
  { value: "worse", label: "Piores" },
];

const getBenchmarkName = benchmark => {
  switch (benchmark) {
    case "IBOV":
      return "Ibovespa";
    case "$SPX.X":
      return "S&P 500";
    default:
      return "";
  }
};

const Ranking = ({ data, isB3, descriptionParagraph }) => {
  const benchmark = isB3 ? "IBOV" : "$SPX.X";

  const { isLoggedIn } = useAuth();

  const PROTECTED_PERIOD_OPTIONS = PERIOD_OPTIONS.map(option => {
    if (!isLoggedIn && !option.value.startsWith("_current"))
      return { ...option, isDisabled: true };
    return option;
  });

  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [period, setPeriod] = useState(PROTECTED_PERIOD_OPTIONS[0]);
  const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const lastUpdated = data[period.value].nodes[0]?.last_updated.replace(
    "UTC",
    "Z"
  );

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data[period.value].nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      })
      .filter(node => {
        if (filter.value === "all") return true;

        const benchmarkVariation = data[period.value].nodes.find(
          ({ ticker }) => ticker === benchmark
        )?.variation;

        if (filter.value === "better")
          return node.variation >= benchmarkVariation;
        if (filter.value === "worse")
          return node.variation <= benchmarkVariation;

        // Shouldn't happen
        return true;
      }),
    fieldToSort,
    directionToSort
  );

  return (
    <>
      <div>
        {descriptionParagraph}
        <LastUpdated date={lastUpdated} isISO />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="ranking-period">Período</label>
            <Select
              id="ranking-period"
              options={PROTECTED_PERIOD_OPTIONS}
              value={period}
              onChange={option => setPeriod(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "210px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable
            />
          </div>
          <div>
            <label htmlFor="ranking-filter">
              Comparação com {getBenchmarkName(benchmark)}
            </label>
            <Select
              id="ranking-filter"
              options={FILTER_OPTIONS}
              value={filter}
              onChange={option => setFilter(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "220px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="ranking-ticker">Ativo</label>
            <input
              id="ranking-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder={`Ex: ${isB3 ? "PETR4" : "AAPL"}`}
            />
          </div>
          <div>
            <label htmlFor="ranking-order">Ordenar por</label>
            <Select
              id="ranking-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "190px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && isB3 && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Posição</th>
                <th>Ativo</th>
                <th
                  data-tooltip-id="ranking-tip"
                  data-tooltip-content={`Variação no período. Não leva em consideração splits, dividendos e bonificações.`}>
                  Retorno<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="ranking-tip"
                  data-tooltip-content={`Preço não-ajustado no início do período.`}>
                  Preço Inicial<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="ranking-tip"
                  data-tooltip-content={`Preço não-ajustado no final do período.`}>
                  Preço Final<sup>3</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr
                  key={node.ticker}
                  style={{
                    boxShadow:
                      node.ticker === benchmark
                        ? "0 0 0 2px #033660 inset"
                        : undefined,
                    fontWeight: node.ticker === benchmark ? "600" : undefined,
                  }}>
                  <td>{node.ranking}</td>
                  <td>
                    {node.ticker === benchmark
                      ? getBenchmarkName(benchmark)
                      : node.ticker}
                  </td>
                  <td
                    style={{
                      color: node.variation >= 0 ? "#49ce8b" : "#d64242",
                      fontWeight: "600",
                    }}>
                    {(node.variation * 100).toFixed(2)}%
                  </td>
                  <td>{node.initial_price.toFixed(2)}</td>
                  <td>{node.current_price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Variação no período. Não leva em consideração splits,
            dividendos e bonificações.
          </div>
          <div>
            <sup>2</sup> Preço não-ajustado no início do período.
          </div>
          <div>
            <sup>3</sup> Preço não-ajustado no final do período.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="ranking-tip" />
      </div>
    </>
  );
};

Ranking.propTypes = {
  data: PropTypes.object.isRequired,
  isB3: PropTypes.bool.isRequired,
  descriptionParagraph: PropTypes.node.isRequired,
};

export default Ranking;
