import EntryLayout from "../components/layouts/EntryLayout";
import ConfirmEmail from "../components/login/ConfirmEmail";
import SEO from "../components/seo";

const ConfirmEmailPage = () => {
  return (
    <EntryLayout>
      <SEO title="Confirmar Email" />
      <ConfirmEmail />
    </EntryLayout>
  );
};

export default ConfirmEmailPage;
