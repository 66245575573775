import { useEffect,useState } from "react";

// We need to resort to this technique in order to only render content after
// the page is first rendered (i.e. we are in the client side). This is necessary
// to avoid hydration issues (see https://github.com/gatsbyjs/gatsby/issues/14601#issuecomment-499922794)
// and also to stop the flickering that happens when we load the page before local
// storage is available.

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
};
