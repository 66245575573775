import PropTypes from "prop-types";
import * as css from "./FeedBacktest.module.css";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import BacktestResult from "../backtests/BacktestResult";
import Spinner from "../common/Spinner";
import { DateTime } from "luxon";

const FeedBacktest = ({ backtestId, showParameters = true }) => {
  const callAPI = useAPI({ withCredentials: true });

  const backtestResponse = useQuery(
    ["backtest", backtestId],
    () => callAPI(`/api/backtest/${backtestId}`),
    {
      enabled: !!backtestId,
    }
  );

  return (
    <div className={css["container"]}>
      {backtestResponse.isSuccess && (
        <BacktestResult
          id={`backtest-${backtestResponse.data.id}`}
          showParameters={showParameters}
          isFavorite={false}
          result={{
            ...backtestResponse.data,
            start_date: DateTime.fromJSDate(
              new Date(backtestResponse.data.start_date)
            ).toISODate(),
            end_date: DateTime.fromJSDate(
              new Date(backtestResponse.data.end_date)
            ).toISODate(),
          }}
        />
      )}
      {backtestResponse.isLoading && (
        <Spinner type="Rings" text="Carregando backtest..." />
      )}
    </div>
  );
};

FeedBacktest.propTypes = {
  backtestId: PropTypes.number.isRequired,
  showParameters: PropTypes.bool,
};

export default FeedBacktest;
