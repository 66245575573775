import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import { useIsClient } from "../../hooks/useIsClient";
import { usePremiumPopup } from "../../hooks/usePremiumPopup";
import PremiumBanner from "../common/PremiumBanner";
import PublicPopup from "../common/PublicPopup";
import RegisterBanner from "../common/RegisterBanner";
import Footer from "../Footer";
import Navbar from "../Navbar";
import SEO from "../seo";
import * as css from "./PublicLayout.module.css";

const PublicLayout = ({
  children,
  seoProps,
  ctaMessage,
  title,
  premiumMessage,
}) => {
  const { isLoggedIn, user } = useAuth();
  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const showCta = !isLoggedIn && isClient;

  const showPremiumCta =
    premiumMessage && isLoggedIn && isClient && !user.isPremium;

  const [showModal, setShowModal] = usePremiumPopup(false);

  return (
    <div>
      <SEO {...seoProps} />
      <Navbar />
      {showPremiumCta && (
        <PremiumBanner key={key} ctaMessage={premiumMessage} />
      )}
      {showCta && <RegisterBanner key={key} ctaMessage={ctaMessage} />}
      <div className={css["main"]}>
        <h1>{title}</h1>
        {children}
      </div>
      <Footer />
      {showModal && (
        <PublicPopup isOpen={showModal} onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoProps: PropTypes.object.isRequired,
  ctaMessage: PropTypes.string,
  title: PropTypes.string.isRequired,
  premiumMessage: PropTypes.string,
};

export default PublicLayout;
