import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import * as css from "./RobotTrades.module.css";

import ReturnsDateFilter from "../historicalReturns/ReturnsDateFilter";
import { getColor } from "../backtests/CapitalTooltip";
import TradeSquare from "./TradeSquare";

const MAX_DATE_RANGE = 365 * 5;

const RobotTrades = ({ id }) => {
  const callAPI = useAPI({ withCredentials: true });

  const [startDate, setStartDate] = useState(
    DateTime.now()
      .minus({ days: MAX_DATE_RANGE / 5 })
      .toJSDate()
  );
  const [endDate, setEndDate] = useState(DateTime.now().toJSDate());

  const entryDate = DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
  const exitDate = DateTime.fromJSDate(endDate)
    .plus({ days: 1 }) // Add 1 day to include current day
    .toFormat("yyyy-MM-dd");

  const { data: trades, isLoading, isSuccess } = useQuery(
    ["automatedRobotsTrades", id, entryDate, exitDate],
    () =>
      callAPI(
        `/api/automated/robot/${id}/trades?entry_date=${entryDate}&exit_date=${exitDate}`
      ),
    { onError: showError }
  );

  // Check that at least one trade has target price
  const hasTarget =
    isSuccess && trades.some(trade => trade.target_price !== null);

  const hasStop = isSuccess && trades.some(trade => trade.stop_price !== null);

  return (
    <div>
      <div className={css["tradesLabel"]}>Histórico de Trades</div>
      <ReturnsDateFilter
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        restrictToYesterday={false}
      />
      {isLoading && <Spinner type="Rings" text="Carregando trades..." />}
      {isSuccess && (
        <div className={css["wrapper"]}>
          <table>
            <thead>
              <tr>
                <th>Data de entrada</th>
                <th>Preço de entrada</th>
                {hasTarget && <th>Alvo</th>}
                {hasStop && <th>Stop</th>}
                <th>Data de saída</th>
                <th>Preço de saída</th>
                <th>Resultado</th>
              </tr>
            </thead>
            <tbody>
              {trades.map(trade => {
                const resultInPct =
                  ((trade.exit_price - trade.entry_price) / trade.entry_price) *
                  100;
                return (
                  <tr key={trade.entry_date}>
                    <td>
                      {DateTime.fromISO(trade.entry_date + "Z")
                        .setZone("America/Sao_Paulo")
                        .setLocale("pt-BR")
                        .toFormat("dd/LL 'às' HH:mm")}
                    </td>
                    <td>{trade.entry_price.toFixed(2)}</td>
                    {hasTarget && <td>{trade.target_price?.toFixed(2)}</td>}
                    {hasStop && <td>{trade.stop_price?.toFixed(2)}</td>}
                    <td>
                      {DateTime.fromISO(trade.exit_date + "Z")
                        .setZone("America/Sao_Paulo")
                        .setLocale("pt-BR")
                        .toFormat("dd/LL 'às' HH:mm")}
                    </td>
                    <td>{trade.exit_price.toFixed(2)}</td>
                    <td style={getColor(resultInPct)}>
                      <div className={css["result"]}>
                        <TradeSquare
                          trade={{
                            start_date: trade.entry_date,
                            end_date: trade.exit_date,
                            pct_profit: resultInPct / 100,
                          }}
                        />
                        <span>
                          {resultInPct > 0 ? "+" : ""}
                          {resultInPct.toFixed(2)}%
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

RobotTrades.propTypes = {
  id: PropTypes.number.isRequired,
};

export default RobotTrades;
