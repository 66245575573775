import { useEffect } from "react";
import AppLayout from "../layouts/AppLayout";
import { StringParam, useQueryParams } from "use-query-params";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import * as css from "./CheckoutSuccess.module.css";
import { Link, navigate } from "gatsby";
import Spinner from "../common/Spinner";
import LogoIcon from "../../images/logo.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const CheckoutSuccess = () => {
  const [{ session_id: sessionId }] = useQueryParams({
    session_id: StringParam,
  });

  const callAPI = useAPI({ withCredentials: true });

  const {
    user: { isPremium, email },
    setIsPremium,
  } = useAuth();

  const { data, isLoading, isSuccess } = useQuery(
    ["userSession", sessionId],
    () => callAPI(`/api/payment/session?session_id=${sessionId}`),
    {
      enabled: !!sessionId,
      onSuccess: data => {
        if (data.is_premium !== isPremium) setIsPremium(data.is_premium);
      },
      onError: () => navigate("/app/settings"),
    }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.CHECKOUT_COMPLETED, {
      email,
    });
  }, [email]);

  return (
    <AppLayout
      seoProps={{
        title: "Pagamento Confirmado",
      }}>
      <div className={css["container"]}>
        <h1>Pagamento Confirmado!</h1>
        {isLoading && <Spinner text="Buscando informações atualizadas..." />}
        {isSuccess && (
          <div>
            <div>Obrigado pela sua compra.</div>
            <br />
            <img className={css["logo"]} src={LogoIcon} />
            {data.session.payment_status === "paid" ? (
              <div>
                <div>
                  Já confirmamos a sua assinatura! Você já está pronto para
                  aproveitar o QuantBrasil ao máximo.
                </div>
              </div>
            ) : (
              <div>
                Assim que seu pagamento for aprovado, você terá acesso
                irrestrito à plataforma do QuantBrasil.
              </div>
            )}
            <div className={css["buttons"]}>
              <Link to="/app/settings">Minha Conta</Link>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default CheckoutSuccess;
