// extracted by mini-css-extract-plugin
export var backtest = "SavedBacktests-module--backtest--7c145";
export var container = "SavedBacktests-module--container--79cbf";
export var details = "SavedBacktests-module--details--2cbb6";
export var info = "SavedBacktests-module--info--0f3f6";
export var maxRisk = "SavedBacktests-module--maxRisk--0f6b9";
export var relativeDate = "SavedBacktests-module--relativeDate--f21c8";
export var result = "SavedBacktests-module--result--40bbf";
export var resultRow = "SavedBacktests-module--resultRow--39d90";
export var results = "SavedBacktests-module--results--b1d70";
export var row = "SavedBacktests-module--row--5e5f6";
export var score = "SavedBacktests-module--score--43e57";
export var strategy = "SavedBacktests-module--strategy--9418c";
export var time = "SavedBacktests-module--time--44be3";
export var warning = "SavedBacktests-module--warning--bd7e8";