import { Link } from "gatsby";
import { useState, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "../valueAtRisk/VaRContainer.module.css";
import { useAssets } from "../../hooks/useAssets";
import { usePortfolios } from "../../hooks/usePortfolios";
import ShowPortfolios from "../portfolios/ShowPortfolios";
import { xor } from "lodash";
import AssetsPanel from "../valueAtRisk/AssetsPanel";
import HelpButton from "../valueAtRisk/HelpModal";
import BetaFilter from "./BetaFilter";
import BetaResult from "./BetaResult";
import { useIsClient } from "../../hooks/useIsClient";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";

const YEARS_OPTIONS = [
  { value: 1, label: "1 ano" },
  { value: 3, label: "3 anos" },
  { value: 5, label: "5 anos" },
];

const PortfolioBetaContainer = () => {
  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn, user } = useAuth();

  const isPremium = user?.isPremium;

  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const PROTECTED_YEARS_OPTIONS = YEARS_OPTIONS.map(option => {
    if (!isPremium && option.value > 1) return { ...option, isDisabled: true };
    return option;
  });

  const [years, setYears] = useState(
    PROTECTED_YEARS_OPTIONS.find(({ value }) => value === 1)
  );
  const [assets, setAssets] = useState([]);
  const [showPortfolios, setShowPortfolios] = useState(false);

  const onBetaExecuted = () => {
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.BETA_EXECUTED);
    incrementProperty("betaExecuted");
  };

  const { grouped: allAssets } = useAssets({ requirePremium: true });

  const availableAssets = useMemo(
    () => allAssets.filter(group => group.label === "Ações"),
    [allAssets]
  );

  const availableTickers = useMemo(() => {
    let allTickers = [];
    availableAssets.forEach(({ options }) => {
      allTickers = [...allTickers, ...options];
    });
    return allTickers;
  }, [availableAssets]);

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const mutation = useMutation(
    () =>
      callAPI(`/api/metrics/beta`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          years: years.value,
          portfolio: assets.map(asset => ({
            ticker: asset.value,
            weight: asset.weight / 100,
          })),
        }),
      }),
    {
      onError: showError,
    }
  );

  useEffect(() => {
    if (showPortfolios && selectedAssets.length > 0) {
      const toBeAdded = availableTickers.filter(a =>
        selectedAssets.includes(a.value)
      );
      if (
        xor(
          assets.map(a => a.value),
          toBeAdded.map(a => a.value)
        ).length > 0
      ) {
        setAssets(toBeAdded);
      }
    }
  }, [availableTickers, assets, selectedAssets, showPortfolios]);

  const title = "Beta da Carteira";

  return (
    <AppLayout
      ctaMessage="Crie sua conta para calcular o beta de um portfólio"
      premiumMessage="Seja Premium para desbloquear todos os filtros e ativos"
      seoProps={{
        title,
        description:
          "Calcule o beta de um portfólio utilizando a calculadora do QuantBrasil.",
      }}>
      <div>
        <h1 className={css["header"]}>
          {title} <HelpButton />
        </h1>
        <p style={{ marginBottom: "20px" }}>
          Utilize a ferramenta abaixo para calcular o beta de um portfólio.
          Dúvidas?{" "}
          <Link to="/entenda-o-que-e-o-beta-e-aprenda-a-calcula-lo-utilizando-python">
            Entenda o que é o Beta e Aprenda a Calculá-lo Utilizando Python
          </Link>
          .
        </p>
        {!isLoggedIn && isClient && (
          <div className={css["welcomeContainer"]} key={key}>
            <div className={css["ctaContainer"]}>
              <RegisterButton copy="Crie sua conta para calcular o beta da sua carteira, simular backtests, testar estratégias de Long & Short, e muito mais." />
            </div>
          </div>
        )}
        {isLoggedIn && isClient && (
          <div>
            <BetaFilter
              years={years}
              yearsOptions={PROTECTED_YEARS_OPTIONS}
              setYears={setYears}
              assetsOptions={availableAssets}
              assets={assets}
              setAssets={setAssets}
              showPortfolios={showPortfolios}
              setShowPortfolios={setShowPortfolios}
            />
            <ShowPortfolios
              showPortfolios={showPortfolios}
              setShowPortfolios={setShowPortfolios}
              portfolioOptions={portfolioOptions}
              setSelectedId={setSelectedId}
              selectedPortfolio={selectedPortfolio}
              requirePremium
            />
            <div className={css["panel"]}>
              <AssetsPanel
                assets={assets}
                setAssets={setAssets}
                isLoading={mutation.isLoading}
                onSubmit={onBetaExecuted}
                buttonText="Calcular Beta"
              />
              <div className={css["results"]}>
                {mutation.isLoading && (
                  <Spinner type="Rings" text="Robôs trabalhando..." />
                )}
                {mutation.data && mutation.isSuccess && (
                  <BetaResult data={mutation.data} />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default PortfolioBetaContainer;
