import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useReducer, useRef } from "react";
import { useSessionStorage } from "react-use";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from "use-query-params";
import { STRATEGY_OPTIONS, TIMEFRAME_OPTIONS } from "../utils/constants";
import { useAssets } from "./useAssets";
import { useAuth } from "./useAuth";

export const MAX_DATE_RANGE = 365;

const roundNumeric = number => Math.round(number * 1000) / 1000;

const getInitialState = isPremium => ({
  // Common values
  strategy: null,
  ticker: null,
  timeframeId: null,
  startDate: DateTime.now()
    .minus(isPremium ? { years: 2, days: 1 } : { days: MAX_DATE_RANGE + 1 })
    .toISO(),
  endDate: DateTime.now().minus({ days: 1 }).toISO(),

  // Position sizing parameters
  capital: 100000,
  maxRisk: 1000,
  contracts: 5,

  // Strategy parameters
  stopInDays: null,
  stopInPct: null,
  stopInStd: null,
  rsi: null,
  entryRsi: null,
  exitRsi: null,
  entryStochastic: null,
  exitStochastic: null,
  targetBand: null,
  window: null,
  exitWindow: null,
  kWindow: null,
  mmaWindow: null,
  channelWindow: 3,
  tradersEden: false,
  insideBar: false,
  entry: null,
  stop: null,
  target: null,
  targetInPct: null,
  riskFactor: null,
  bodyPct: 20,
  gapPct: 0.5,
  exhaustionPct: 0.5,
  negativeSequence: 3,
  mm50IsUp: false,
  mme80IsUp: false,
  rollingAvgType: null,
  rollingAvgWindow: null,
  candlesAbove: 2,
  hammer: false,
  isDayTrade: false,
  minOpenTime: null,
  closeTimeLimit: null,
  entryTime: null,
  exitTime: null,
  numStd: null,
  stdFactor: null,
  rollingWindow: 20,
  zscoreThreshold: null,
  applyRsiFilter: false,
  rsiWindow: null,
  applyKeltnerFilter: false,
  keltnerWindow: null,
  keltnerMultiplier: 1,
  entryVariation: null,
  exitVariation: null,
});

const parseTime = date => {
  if (date === null || date == undefined) return undefined;

  // Check first if ISO date
  let parsed = DateTime.fromISO(date);
  if (parsed.isValid) return parsed.toFormat("HH:mm");

  // Then check if JS date
  parsed = DateTime.fromJSDate(date);
  if (parsed.isValid) return parsed.toFormat("HH:mm");

  // Shouldn't happen
  return undefined;
};

export const positionSizingVisibility = state => {
  const showMaxRisk = !!state.strategy?.fixedRisk;

  const showContracts =
    state.strategy && !!state.ticker?.useContracts && !showMaxRisk;

  const showCapital =
    state.strategy &&
    state.ticker &&
    !showContracts &&
    !state.ticker.useContracts;

  return { showMaxRisk, showCapital, showContracts };
};

export const mapValues = state => {
  const strategy = STRATEGY_OPTIONS.find(
    ({ value }) => value === state.strategy.value
  );
  const { showCapital, showContracts, showMaxRisk } = positionSizingVisibility(
    state
  );

  const ignoreTimeframe = Boolean(state.strategy?.ignoreTimeframe);

  return {
    // Required values
    ticker: state.ticker.value,
    start_date: state.startDate.slice(0, 10),
    end_date: state.endDate.slice(0, 10),

    // Optional values
    ...(ignoreTimeframe ? {} : { timeframe_id: state.timeframeId.value }),

    // Position sizing parameters
    ...(showCapital ? { capital: state.capital } : {}),
    ...(showContracts ? { num_contracts: state.contracts } : {}),
    ...(showMaxRisk ? { max_risk: state.maxRisk } : {}),

    // Strategy parameters
    parameters: {
      ...(strategy.parameters.includes("stopInDays")
        ? {
            stop_in_days: state.stopInDays === null ? 0 : state.stopInDays,
          }
        : {}),
      ...(strategy.parameters.includes("stopInPct")
        ? {
            stop_in_pct: state.stopInPct === null ? 0 : state.stopInPct / 100,
          }
        : {}),
      ...(strategy.parameters.includes("stopInStd")
        ? {
            stop_in_std: state.stopInStd === null ? 0 : state.stopInStd,
          }
        : {}),
      ...(strategy.parameters.includes("targetInPct") &&
      (!strategy?.parameters.includes("target") ||
        state.target?.value === "percentage")
        ? {
            target_in_pct:
              state.targetInPct === null ? 0 : state.targetInPct / 100,
          }
        : {}),
      ...(strategy.parameters.includes("fiboExtension") &&
      state.target?.value === "fibo"
        ? {
            fibo_extension: roundNumeric(state.fiboExtension / 100),
          }
        : {}),
      ...(strategy.parameters.includes("fiboRetracement")
        ? {
            fibo_retracement: roundNumeric(state.fiboRetracement / 100),
          }
        : {}),
      ...(strategy.parameters.includes("negativeSequence")
        ? {
            negative_sequence: state.negativeSequence,
          }
        : {}),
      ...(strategy.parameters.includes("window")
        ? {
            window: state.window,
          }
        : {}),
      ...(strategy.parameters.includes("exitWindow") &&
      [
        "long_rsi",
        "long_stochastic",
        "short_stochastic",
        "long_atypical_variation",
        "short_atypical_variation",
      ].includes(strategy.value) &&
      state.target?.value === "window"
        ? {
            exit_window: state.exitWindow,
          }
        : {}),
      ...(strategy.parameters.includes("rsiWindow") &&
      (!strategy.parameters.includes("applyRsiFilter") || state.applyRsiFilter)
        ? {
            rsi_window: state.rsiWindow,
          }
        : {}),
      ...(strategy.parameters.includes("kWindow")
        ? {
            k_window: state.kWindow,
          }
        : {}),
      ...(strategy.parameters.includes("mmaWindow")
        ? {
            mma_window: state.mmaWindow,
          }
        : {}),
      ...(strategy.parameters.includes("channelWindow")
        ? {
            channel_window: state.channelWindow,
          }
        : {}),
      ...(strategy.parameters.includes("rollingWindow")
        ? {
            rolling_window: state.rollingWindow,
          }
        : {}),
      ...(strategy.parameters.includes("keltnerWindow") &&
      state.applyKeltnerFilter
        ? {
            keltner_window: state.keltnerWindow,
          }
        : {}),
      ...(strategy.parameters.includes("keltnerMultiplier") &&
      state.applyKeltnerFilter
        ? {
            keltner_multiplier: state.keltnerMultiplier,
          }
        : {}),
      ...(strategy.parameters.includes("rsi") &&
      (!strategy.parameters.includes("applyRsiFilter") || state.applyRsiFilter)
        ? {
            rsi: state.rsi,
          }
        : {}),
      ...(strategy.parameters.includes("entryRsi")
        ? {
            entry_rsi: state.entryRsi,
          }
        : {}),
      ...(strategy.parameters.includes("exitRsi") &&
      strategy.value === "long_rsi" &&
      state.target?.value === "rsi"
        ? {
            exit_rsi: state.exitRsi,
          }
        : {}),
      ...(strategy.parameters.includes("entryStochastic")
        ? {
            entry_stochastic: state.entryStochastic,
          }
        : {}),
      ...(strategy.parameters.includes("exitStochastic")
        ? {
            exit_stochastic: state.exitStochastic,
          }
        : {}),
      ...(strategy.parameters.includes("targetBand")
        ? {
            target_band: state.targetBand?.value,
          }
        : {}),
      ...(strategy.parameters.includes("tradersEden")
        ? {
            traders_eden: state.tradersEden,
          }
        : {}),
      ...(strategy.parameters.includes("insideBar")
        ? {
            inside_bar: state.insideBar,
          }
        : {}),
      ...(strategy.parameters.includes("hammer")
        ? {
            hammer: state.hammer,
          }
        : {}),
      ...(strategy.parameters.includes("target")
        ? {
            target: state.target?.value,
          }
        : {}),
      ...(strategy.parameters.includes("entry")
        ? {
            entry: state.entry?.value,
          }
        : {}),
      ...(strategy.parameters.includes("stop")
        ? {
            stop: state.stop?.value,
          }
        : {}),
      ...(strategy.parameters.includes("riskFactor") &&
      (!strategy?.parameters.includes("target") ||
        state.target?.value === "risk")
        ? {
            risk_factor: state.riskFactor,
          }
        : {}),
      ...(strategy.parameters.includes("bodyPct")
        ? {
            body_pct: state.bodyPct / 100,
          }
        : {}),
      ...(strategy.parameters.includes("gapPct")
        ? {
            gap_pct: state.gapPct / 100,
          }
        : {}),
      ...(strategy.parameters.includes("exhaustionPct")
        ? {
            exhaustion_pct: state.exhaustionPct / 100,
          }
        : {}),
      ...(strategy.parameters.includes("entryVariation")
        ? {
            entry_variation: state.entryVariation / 100,
          }
        : {}),
      ...(strategy.parameters.includes("exitVariation")
        ? {
            exit_variation: state.exitVariation / 100,
          }
        : {}),
      ...(strategy.parameters.includes("mm50IsUp")
        ? {
            mm50_is_up: state.mm50IsUp,
          }
        : {}),
      ...(strategy.parameters.includes("mme80IsUp")
        ? {
            mme80_is_up: state.mme80IsUp,
          }
        : {}),
      ...(strategy.parameters.includes("isDayTrade")
        ? {
            is_day_trade: Boolean(strategy.isDayTradeOnly) || state.isDayTrade,
          }
        : {}),
      ...(strategy.parameters.includes("minOpenTime")
        ? {
            min_open_time: parseTime(state.minOpenTime),
          }
        : {}),
      ...(strategy.parameters.includes("closeTimeLimit")
        ? {
            close_time_limit: parseTime(state.closeTimeLimit),
          }
        : {}),
      ...(strategy.parameters.includes("entryTime")
        ? {
            entry_time: parseTime(state.entryTime),
          }
        : {}),
      ...(strategy.parameters.includes("exitTime")
        ? {
            exit_time: parseTime(state.exitTime),
          }
        : {}),
      ...(strategy.parameters.includes("rollingAvgType")
        ? {
            rolling_avg_type: state.rollingAvgType?.value,
          }
        : {}),
      ...(strategy.parameters.includes("rollingAvgWindow") &&
      ["long_rsi", "long_stochastic", "short_stochastic"].includes(
        strategy.value
      )
        ? {
            rolling_avg_window: state.rollingAvgWindow,
          }
        : {}),
      ...(strategy.parameters.includes("rollingAvgWindow") &&
      !["long_rsi", "long_stochastic", "short_stochastic"].includes(
        strategy.value
      )
        ? {
            window: state.rollingAvgWindow,
          }
        : {}),
      ...(strategy.parameters.includes("candlesAbove")
        ? {
            candles_above: state.candlesAbove,
          }
        : {}),
      ...(strategy.parameters.includes("numStd")
        ? {
            num_std: state.numStd,
          }
        : {}),
      ...(strategy.parameters.includes("stdFactor")
        ? {
            std_factor: state.stdFactor || undefined,
          }
        : {}),
      ...(strategy.parameters.includes("zscoreThreshold")
        ? {
            zscore_threshold: state.zscoreThreshold,
          }
        : {}),
    },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, [action.key]: action.value };
    case "PATCH_STATE":
      return { ...state, ...action.prefilledState };
    case "RESET":
      return action.initialState;
    default:
      throw new Error();
  }
};

const isDateField = key => ["startDate", "endDate"].includes(key);

const getPrefilledState = (
  {
    strategy,
    ticker,
    timeframeId,
    rsi,
    tradersEden,
    mm50IsUp,
    insideBar,
    negativeSequence,
    exhaustionPct,
    hammer,
    rollingAvgType,
    rollingAvgWindow,
  },
  tickers,
  isLoggedIn
) => {
  const validStrategies = [
    "ifr2",
    "long_123",
    "long_wick_candle",
    "short_wick_candle",
    "long_turnaround",
    "long_trap",
    "long_pfr",
    "long_insidebar",
    "short_insidebar",
    "long_shark",
    "short_shark",
  ];

  const validTimeframes = ["D1"];
  const validAvgTypes = ["ewm"];

  if (isLoggedIn) {
    validTimeframes.push("W1", "H2", "H1");
    validAvgTypes.push("simple");
  }
  const validTickers = tickers.map(node => node.symbol);

  const isValidStrategy = validStrategies.includes(strategy);
  const isValidTicker = validTickers.includes(ticker);

  const defaultRsi =
    strategy === "ifr2" && isValidTicker && typeof rsi !== "number"
      ? tickers.find(node => node.symbol === ticker)?.rsi?.rsi
      : null;

  const defaultAvgWindow = isLoggedIn ? rollingAvgWindow : 8;

  return {
    ...(isValidStrategy
      ? { strategy: STRATEGY_OPTIONS.find(({ value }) => value === strategy) }
      : {}),
    ...(isValidTicker ? { ticker: { label: ticker, value: ticker } } : {}),
    ...(validTimeframes.includes(timeframeId)
      ? {
          timeframeId: TIMEFRAME_OPTIONS.find(
            ({ value }) => value === timeframeId
          ),
        }
      : {}),
    ...(validAvgTypes.includes(rollingAvgType)
      ? {
          rollingAvgType: STRATEGY_OPTIONS.find(
            ({ value }) => value === strategy
          ).rollingAvgType.find(({ value }) => value === rollingAvgType),
        }
      : {}),
    ...(typeof rsi === "number"
      ? { rsi }
      : defaultRsi
      ? { rsi: defaultRsi }
      : {}),
    ...(typeof tradersEden === "boolean" ? { tradersEden } : {}),
    ...(typeof insideBar === "boolean" ? { insideBar } : {}),
    ...(typeof hammer === "boolean" ? { hammer } : {}),
    ...(typeof mm50IsUp === "boolean" ? { mm50IsUp } : {}),
    ...(typeof negativeSequence === "number" && isLoggedIn
      ? { negativeSequence }
      : {}),
    ...(typeof exhaustionPct === "number" && isLoggedIn
      ? { exhaustionPct }
      : {}),
    ...(typeof rollingAvgWindow === "number"
      ? { rollingAvgWindow: defaultAvgWindow }
      : {}),
  };
};

export const useBacktestFilter = () => {
  const { isLoggedIn, user } = useAuth();

  const isPremium = user?.isPremium;
  const initialState = getInitialState(isPremium);

  const [filter, saveFilter] = useSessionStorage(
    "qb.backtestFilter",
    initialState
  );
  const [state, dispatch] = useReducer(reducer, filter);

  const [paramState] = useQueryParams({
    strategy: StringParam,
    ticker: StringParam,
    timeframeId: StringParam,
    rsi: NumberParam,
    tradersEden: BooleanParam,
    insideBar: BooleanParam,
    mm50IsUp: BooleanParam,
    negativeSequence: NumberParam,
    exhaustionPct: NumberParam,
    hammer: BooleanParam,
    rollingAvgWindow: NumberParam,
    rollingAvgType: StringParam,
  });

  const { raw: tickers } = useAssets({ requirePremium: true });

  const prefilledState = getPrefilledState(paramState, tickers, isLoggedIn);

  const hasPatchedRef = useRef(false);

  if (!isEmpty(prefilledState) && !hasPatchedRef.current) {
    dispatch({ type: "PATCH_STATE", prefilledState });
    hasPatchedRef.current = true;
  }

  const setValue = (key, value) =>
    dispatch({
      type: "SET_VALUE",
      key,
      value: isDateField(key)
        ? DateTime.fromJSDate(value).toISO()
        : value === ""
        ? null
        : value,
    });

  const reset = () => {
    saveFilter(initialState);
    dispatch({ type: "RESET", initialState });
  };

  const getValue = key => {
    if (!isDateField(key)) return state[key] === undefined ? "" : state[key];
    return state[key] ? DateTime.fromISO(state[key]).toJSDate() : "";
  };

  const dateRangeInDays = Math.floor(
    DateTime.fromJSDate(getValue("endDate"))
      .diff(DateTime.fromJSDate(getValue("startDate")), "days")
      .toObject().days
  );

  const checkForErrors = state => {
    const errors = {};
    Object.keys(state).forEach(key => {
      let errorMessage = "";
      switch (key) {
        case "endDate":
        case "startDate":
          errorMessage =
            !isPremium && dateRangeInDays > MAX_DATE_RANGE
              ? `Limite máximo de ${MAX_DATE_RANGE} dias`
              : "";
          break;
        case "capital":
          errorMessage =
            state.capital >= 1000 && state.capital <= 1000000
              ? ""
              : "Entre R$ 1.000,00 e R$ 1.000.000,00";
          break;
        case "contracts":
          errorMessage =
            state.contracts >= 1 && state.contracts <= 100
              ? ""
              : "Entre 1 e 100";
          break;
        default:
          break;
      }
      errors[key] = errorMessage;
    });
    return errors;
  };

  const validateStrategy = strategy =>
    strategy.parameters.every(param => {
      const value = state[param];

      const shouldApplyRiskFactor =
        strategy.parameters.includes("riskFactor") &&
        (!strategy.parameters.includes("target") ||
          state["target"]?.value === "risk");

      const shouldApplyTargetInPct =
        strategy.parameters.includes("targetInPct") &&
        (!strategy.parameters.includes("target") ||
          state["target"]?.value === "percentage");

      switch (param) {
        case "stopInDays":
        case "minOpenTime":
        case "closeTimeLimit":
        case "stopInStd":
          return true;
        case "stopInPct":
          return [
            "long_rsi",
            "long_stochastic",
            "short_stochastic",
            "long_open_bb",
            "short_open_bb",
            "long_reversal_bb",
            "short_reversal_bb",
            "long_by_time",
            "short_by_time",
            "long_intraday_variation",
            "short_intraday_variation",
          ].includes(strategy.value)
            ? value === null || (value > 0 && value <= 100)
            : value === parseInt(value, 10) && value > 0 && value <= 100;
        case "targetInPct":
          return shouldApplyTargetInPct
            ? ["long_by_time", "short_by_time"].includes(strategy.value)
              ? value === null || (value > 0 && value <= 100)
              : value === parseFloat(value, 10) && value > 0 && value <= 100
            : true;
        case "window":
          return value === parseInt(value, 10) && value > 0 && value <= 200;
        case "exitWindow":
          return state["target"]?.value === "window"
            ? value === parseInt(value, 10) && value > 0 && value <= 200
            : true;
        case "rsiWindow":
          return [
            "long_open_bb",
            "short_open_bb",
            "long_reversal_bb",
            "short_reversal_bb",
          ].includes(strategy.value) && !state["applyRsiFilter"]
            ? true
            : value === parseInt(value, 10) && value >= 2 && value <= 50;
        case "kWindow":
          return value === parseInt(value, 10) && value >= 2 && value <= 50;
        case "numStd":
        case "zscoreThreshold":
          return value === parseFloat(value, 10) && value >= 1 && value <= 5;
        case "keltnerMultiplier":
          return state["applyKeltnerFilter"]
            ? value === parseFloat(value, 10) && value > 0 && value <= 5
            : true;
        case "targetBand":
          return ["opposite", "middle"].includes(value?.value);
        case "rsi":
          return [
            "long_open_bb",
            "short_open_bb",
            "long_reversal_bb",
            "short_reversal_bb",
          ].includes(strategy.value) && !state["applyRsiFilter"]
            ? true
            : value === parseInt(value, 10) && value >= 0 && value <= 100;
        case "entryRsi":
          return state["entry"]?.value === "rsi"
            ? value === parseInt(value, 10) && value >= 0 && value <= 100
            : true;
        case "exitRsi":
          return state["target"]?.value === "rsi"
            ? value === parseInt(value, 10) && value >= 0 && value <= 100
            : true;
        case "entryStochastic":
          return state["entry"]?.value === "stochastic"
            ? value === parseInt(value, 10) && value >= 0 && value <= 100
            : true;
        case "exitStochastic":
          return state["target"]?.value === "stochastic"
            ? value === parseInt(value, 10) && value >= 0 && value <= 100
            : true;
        case "stop":
        case "entry":
          return [
            "candle1",
            "candle2",
            "candle3",
            "rsi",
            "stochastic",
            "rolling_avg",
          ].includes(value?.value);
        case "target":
          return [
            "amplitude",
            "risk",
            "window",
            "rsi",
            "stochastic",
            "percentage",
            "fibo",
            "std",
          ].includes(value?.value);
        case "rollingAvgType":
          return ["long_rsi", "long_stochastic", "short_stochastic"].includes(
            strategy.value
          ) && state["entry"]?.value !== "rolling_avg"
            ? true
            : ["ewm", "simple"].includes(value?.value);
        case "tradersEden":
        case "insideBar":
        case "mm50IsUp":
        case "mme80IsUp":
        case "hammer":
        case "isDayTrade":
        case "applyRsiFilter":
        case "applyKeltnerFilter":
          return typeof value === "boolean";
        case "riskFactor":
          return shouldApplyRiskFactor
            ? value === parseFloat(value, 10) && value >= 0 && value <= 5
            : true;
        case "bodyPct":
          return value === parseFloat(value, 10) && value > 0 && value <= 25;
        case "gapPct":
        case "exhaustionPct":
          return value === parseFloat(value, 10) && value >= 0 && value <= 5;
        case "negativeSequence":
          return value === parseInt(value, 10) && value >= 1 && value <= 10;
        case "candlesAbove":
          return value === parseInt(value, 10) && value >= 1 && value <= 20;
        case "rollingAvgWindow":
          return ["long_rsi", "long_stochastic", "short_stochastic"].includes(
            strategy.value
          ) && state["entry"]?.value !== "rolling_avg"
            ? true
            : value === parseInt(value, 10) && value >= 1 && value <= 80;
        case "mmaWindow":
        case "rollingWindow":
        case "channelWindow":
          return value === parseInt(value, 10) && value >= 1 && value <= 80;
        case "keltnerWindow":
          return state.applyKeltnerFilter
            ? value === parseInt(value, 10) && value >= 1 && value <= 50
            : true;
        case "fiboRetracement":
          return (
            value === parseFloat(value, 10) && value >= 100 && value <= 261.8
          );
        case "fiboExtension":
          return state["target"]?.value !== "fibo"
            ? true
            : value === parseFloat(value, 10) &&
                value >= 11.8 &&
                value <= 261.8;
        case "stdFactor":
          return state["target"]?.value !== "std"
            ? true
            : value === parseFloat(value, 10) && value >= 0.5 && value <= 5;
        case "entryTime":
        case "exitTime":
          return value !== null && value !== undefined && value !== "";
        case "entryVariation":
          return (
            value === parseFloat(value, 10) &&
            value !== 0 &&
            value <= 5 &&
            value >= -5
          );
        case "exitVariation":
          return (
            value === parseFloat(value, 10) &&
            value !== 0 &&
            (strategy.value.includes("long_")
              ? value > 0 && value <= 5
              : value < 0 && value >= -5)
          );
      }
    });

  const { showCapital, showContracts } = positionSizingVisibility(state);

  const ignoreTimeframe = Boolean(state.strategy?.ignoreTimeframe);

  const isValid =
    !isEmpty(state.strategy) &&
    (!isEmpty(state.timeframeId) || ignoreTimeframe) &&
    !isEmpty(state.ticker) &&
    (!showCapital || (state.capital >= 1000 && state.capital <= 1000000)) &&
    (!showContracts || state.contracts > 0) &&
    (isPremium ? true : dateRangeInDays <= MAX_DATE_RANGE) &&
    validateStrategy(state.strategy);

  const errors = checkForErrors(state);

  useEffect(() => {
    saveFilter(state);
  }, [saveFilter, state]);

  return {
    state: filter,
    setValue,
    getValue,
    isValid,
    reset,
    errors,
  };
};
