import PropTypes from "prop-types";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import { animated,useSpring } from "react-spring";

import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { useIsClient } from "../../hooks/useIsClient";
import CloseIcon from "../../icons/close.svg";
import LogoIcon from "../../images/logo.svg";
import { showError } from "../../utils/utils";
import * as css from "./FeedbackContainer.module.css";

const FeedbackContainer = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const isClient = useIsClient();
  const [showPopup, setShowPopup] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);

  const popupProps = useSpring({
    opacity: showPopup ? 1 : 0,
    right: showPopup ? 0 : -340,
    config: { duration: 200 },
    onRest: () => {
      if (!showPopup) setShowSuccess(false);
    },
  });

  const buttonProps = useSpring({
    opacity: showPopup ? 0 : 1,
    right: showPopup ? -100 : -45,
  });

  const successProps = useSpring({
    opacity: showSuccess ? 1 : 0,
    config: { duration: 1000 },
  });

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/contact/suggestion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ text: feedback }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        setShowSuccess(true);
        setFeedback("");
        setTimeout(() => {
          setShowPopup(false);
        }, 4000);
      },
    }
  );

  const getContent = () => {
    if (showSuccess)
      return (
        <animated.div style={successProps} className={css["success"]}>
          <img src={LogoIcon} />
          <div>Obrigado!</div>
          <div>Seu feedback torna o QuantBrasil cada vez melhor.</div>
        </animated.div>
      );

    return (
      <div className={css["content"]}>
        <div className={css["title"]}>Queremos te ouvir!</div>
        <div>
          O QuantBrasil está em <b>beta</b>. Todo feedback é importante.
        </div>
        <textarea
          value={feedback}
          onChange={e => setFeedback(e.target.value)}
          placeholder="Sugestões"
        />
        <button
          disabled={mutation.isLoading || feedback === ""}
          onClick={() => mutation.mutate()}
          className={css["send"]}>
          {mutation.isLoading ? (
            <Loader type="TailSpin" color="#fff" height={18} width={18} />
          ) : (
            "Enviar"
          )}
        </button>
      </div>
    );
  };

  return (
    <>
      {children}
      {isLoggedIn && isClient && (
        <>
          <animated.button
            style={buttonProps}
            onClick={() => setShowPopup(true)}
            className={css["feedback"]}>
            Feedback
          </animated.button>
          <animated.div style={popupProps} className={css["container"]}>
            <div>
              <button
                className={css["close"]}
                onClick={() => setShowPopup(false)}>
                <CloseIcon />
              </button>
              {getContent()}
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};

FeedbackContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FeedbackContainer;
