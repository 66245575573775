import { Router } from "@reach/router";
import AlertsContainer from "../components/alerts/AlertsContainer";
import AlertsMonitorContainer from "../components/alerts/AlertsMonitorContainer";
import CheckoutSuccess from "../components/checkout/CheckoutSuccess";
import CointegrationRadarContainer from "../components/cointegration/CointegrationRadarContainer";
import UserCointegrationsContainer from "../components/cointegration/UserCointegrationsContainer";
import PrivateRoute from "../components/common/PrivateRoute";
import BacktestTabs from "../components/favorites/BacktestTabs";
import PortfoliosContainer from "../components/portfolios/PortfoliosContainer";
import Settings from "../components/settings/Settings";
import TopBacktestsContainer from "../components/topBacktests/TopBacktestsContainer";
import NotFoundPage from "./404";
import SupplyDemandScreeningContainer from "../components/supplyDemand/SupplyDemandScreeningContainer";
import FeedContainer from "../components/feed/FeedContainer";
import RobotsContainer from "../components/robots/RobotsContainer";
import CointegrationTradeTabs from "../components/cointegration/CointegrationTradeTabs";
import RobotContainer from "../components/robots/RobotContainer";
import ScoreRankingContainer from "../components/scoreRanking/ScoreRankingContainer";

const App = () => (
  <Router>
    <PrivateRoute path="/app/feed" component={FeedContainer} />
    <PrivateRoute path="/app/robots" component={RobotsContainer} />
    <PrivateRoute path="/app/robots/:id" component={RobotContainer} />
    <PrivateRoute path="/app/settings" component={Settings} />
    <PrivateRoute path="/app/alertas" component={AlertsContainer} />
    <PrivateRoute
      path="/app/alertas/monitor"
      component={AlertsMonitorContainer}
    />
    <PrivateRoute path="/app/portfolios" component={PortfoliosContainer} />
    <PrivateRoute
      path="/app/cointegracao/radar"
      component={CointegrationRadarContainer}
    />
    <PrivateRoute
      path="/app/cointegracao/favoritos"
      component={UserCointegrationsContainer}
    />
    <PrivateRoute
      path="/app/cointegracao/trades/:tab"
      component={CointegrationTradeTabs}
    />
    <PrivateRoute
      path="/app/melhores-backtests"
      component={TopBacktestsContainer}
    />
    <PrivateRoute
      path="/app/backtests/ranking/score"
      component={ScoreRankingContainer}
    />
    <PrivateRoute
      path="/app/screening/supply-and-demand"
      component={SupplyDemandScreeningContainer}
    />
    <PrivateRoute path="/app/backtests/:tab" component={BacktestTabs} />
    <PrivateRoute path="/app/checkout/success" component={CheckoutSuccess} />
    <NotFoundPage default />
  </Router>
);

export default App;
