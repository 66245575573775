import { DateTime } from "luxon";
import * as css from "./UserAlerts.module.css";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { showError } from "../../utils/utils";
import { toast } from "react-toastify";
import { useAPI } from "../../hooks/useAPI";
import AlertIcon from "../../icons/alert.svg";
import AlertMuteIcon from "../../icons/alert-mute.svg";
import Spinner from "../common/Spinner";
import UpgradeSection from "../common/UpgradeSection";
import { useAuth } from "../../hooks/useAuth";
import { FREE_ALERTS, PREMIUM_ALERTS } from "./AlertsMonitorContainer";
import orderBy from "lodash.orderby";
import { useState } from "react";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import StrategyDetails from "../common/StrategyDetails";
import DeleteAlertButton from "./DeleteAlertButton";

const UserAlerts = ({ alerts, refetch }) => {
  const [onlyActive, setOnlyActive] = useState(false);

  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const callAPI = useAPI({ withCredentials: true });

  const activeAlerts = alerts.filter(({ status }) => status === "active")
    .length;

  const remainingAlerts = isPremium
    ? PREMIUM_ALERTS - activeAlerts
    : FREE_ALERTS - activeAlerts;

  const mutation = useMutation(
    ({ id, newStatus }) =>
      callAPI(`/api/alert/user`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_alert_id: id,
          status: newStatus,
        }),
      }),
    {
      onSuccess: (newAlert, { newStatus }) => {
        toast.success(
          `Alerta ${newStatus === "active" ? "ativado" : "desativado"}!`
        );
        refetch();
        logAmplitudeEvent(EVENT_MAP.ALERT_STATUS_CHANGED, { newStatus });
      },
      onError: err => {
        showError(err);
        logAmplitudeEvent(EVENT_MAP.SAVE_ALERT_ERROR, { message: err.message });
      },
    }
  );

  const ordered = orderBy(
    alerts
      .filter(({ status }) => !onlyActive || status === "active")
      .map(a => ({
        ...a,
        timestamp: DateTime.fromHTTP(a.last_updated).toSeconds(),
      })),
    ["status", "timestamp"],
    ["asc", "desc"]
  );

  return (
    <div>
      <div className={css["filter"]}>
        <div>
          <div className={css["checkbox"]}>
            <input
              id="checkbox-only-active"
              type="checkbox"
              checked={onlyActive}
              onChange={e => {
                setOnlyActive(e.target.checked);
              }}
            />
            <label htmlFor="checkbox-only-active">Apenas alertas ativos</label>
          </div>
        </div>
      </div>
      <div className={css["tableContainer"]}>
        <table>
          <thead>
            <tr>
              <th>Estratégia</th>
              <th>Ativo</th>
              <th>Timeframe</th>
              <th>Criado em</th>
              <th>Atualizado em</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {ordered.map(a => {
              return (
                <tr key={a.id}>
                  <td>
                    <StrategyDetails
                      strategyId={a.strategy_id}
                      parameters={a.parameters}
                    />
                  </td>
                  <td>{a.symbol}</td>
                  <td>{a.timeframe_id}</td>
                  <td>
                    {DateTime.fromJSDate(new Date(a.created_at))
                      .setLocale("pt-BR")
                      .toFormat("dd/LL 'às' HH:mm")}
                  </td>
                  <td>
                    {DateTime.fromJSDate(new Date(a.last_updated))
                      .setLocale("pt-BR")
                      .toFormat("dd/LL 'às' HH:mm")}
                  </td>
                  <td
                    style={{
                      color: a.status === "active" ? "#49ce8b" : "#888",
                      fontWeight: 500,
                    }}>
                    {a.status === "active" ? "Ativo" : "Inativo"}
                  </td>
                  <td>
                    <button
                      className={css["toggle"]}
                      data-tooltip-id="alert-toggle-tp"
                      data-tooltip-content={
                        a.status === "active"
                          ? "Desativar alerta"
                          : "Ativar alerta"
                      }
                      onClick={() =>
                        mutation.mutate({
                          id: a.id,
                          newStatus:
                            a.status === "active" ? "inactive" : "active",
                        })
                      }>
                      {mutation.isLoading && mutation.variables.id === a.id ? (
                        <div className={css["spinner"]}>
                          <Spinner type="Rings" size={24} />
                        </div>
                      ) : a.status === "active" ? (
                        <AlertMuteIcon className={css["mute"]} />
                      ) : (
                        <AlertIcon />
                      )}
                    </button>
                    {a.status === "inactive" && (
                      <DeleteAlertButton alert={a} refetch={refetch} />
                    )}
                    <Tooltip id="alert-toggle-tp" clickable />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className={css["extra"]}>
        <p>
          <span>
            {remainingAlerts === 0
              ? "Você atingiu o limite de alertas."
              : `Você pode monitorar mais ${remainingAlerts} estratégia${
                  remainingAlerts === 1 ? "" : "s"
                }.`}
          </span>
        </p>
        {!isPremium && (
          <UpgradeSection
            copy={`Assine o plano Premium para monitorar até ${PREMIUM_ALERTS} sinais simultâneos!`}
          />
        )}
      </div>
    </div>
  );
};

UserAlerts.propTypes = {
  alerts: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default UserAlerts;
