import { useState } from "react";
import Modal from "../common/Modal";
import * as css from "./HelpModal.module.css";

const HelpButton = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button className={css["button"]} onClick={() => setOpen(true)}>
        Ajuda
      </button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["help"]}>
          <div>Ajuda</div>
          <div>
            <p>
              Todos os backtests são realizados de acordo com as seguintes
              regras:
            </p>
            <ul>
              <li>
                <b>Apenas uma operação é realizada por vez</b>. Qualquer sinal
                será descartado enquanto houver uma operação em andamento.
              </li>
              <li>
                <b>O capital disponível é fixo</b>. Isso significa que lucros ou
                prejuízos de uma operação não impactam o capital disponível nas
                operações subsequentes. Em outras palavras,{" "}
                <em>
                  lucros não são reinvestidos e prejuízos não são abatidos
                </em>
                .
              </li>
              <li>
                Caso um candle de entrada tenha a máxima maior e a mínima menor
                que o candle sinal, <b>o trade é desconsiderado</b> uma vez que
                não é possível determinar o que ocorreu primeiro.
              </li>
              <li>
                Serão comprados{" "}
                <b>
                  o maior número possível de lotes cheios com o capital
                  disponível.
                </b>{" "}
                Por exemplo, se um sinal de entrada for dado a R$ 30,00 e o
                capital disponível for R$ 100.000,00, serão compradas 3000
                ações.
              </li>
              <ul>
                <li>
                  A exceção ocorre nas estratégias que permitem definir um risco
                  máximo. Nesse caso, serão comprados{" "}
                  <b>apenas os lotes permitidos para o risco definido</b>. No
                  exemplo anterior, se a entrada é a 30,00 e o stop a 29,00,
                  então o risco para 3000 ações é R$ 3.000,00. Caso o risco
                  máximo seja de R$ 1.000,00, <em>somente 1000 ações</em> seriam
                  compradas.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HelpButton;
