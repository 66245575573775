import * as css from "./AddPhoneBanner.module.css";
import { WhatsappIcon } from "react-share";
import { Link } from "gatsby";

const AddAlertBanner = () => {
  return (
    <div className={css["container"]}>
      <div>
        <WhatsappIcon size={48} round={true} />
      </div>
      <div>
        <div className={css["text"]}>
          <div>Não perca mais nenhum trade!</div>
          <div>
            Seja avisado no <span>WhatsApp</span> toda vez que seu sinal
            favorito for identificado. Utilize nosso simulador para buscar os
            melhores trades do mercado.
          </div>
        </div>
        <Link className={css["button"]} to="/backtests">
          Buscar Estratégias
        </Link>
      </div>
    </div>
  );
};

export default AddAlertBanner;
