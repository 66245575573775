import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import * as css from "./BatchBanner.module.css";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "gatsby";
import BatchIcon from "../../icons/batch.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import Countdown from "react-countdown";
import CloseIcon from "../../icons/close.svg";
import { useSessionStorage } from "react-use";
import { useState } from "react";
import BatchModal from "./BatchModal";
import { trackPlansButtonClicked } from "../../utils/amplitude";

const BatchBanner = ({
  parameters,
  strategyId,
  timeframeId,
  ticker,
  setupId,
  displayOnly = false,
}) => {
  const [hasDismissed, setHasDismissed] = useSessionStorage(
    "dismissedBatchBanner",
    false
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const { data, refetch } = useQuery(
    ["batchExpiration", user.userId],
    () => callAPI(`/api/batch/expiration`),
    {
      enabled: user.isPremium,
    }
  );

  if (!user.isPremium)
    return (
      <div className={css["container"]}>
        <div>
          <BatchIcon />
        </div>
        <div>
          <div className={css["text"]}>
            <div>Acelere a sua análise sendo Premium!</div>
            <div>
              Execute a mesma estratégia em múltiplos ativos ao mesmo tempo.
              Descubra os melhores sem a necessidade de executar backtests um a
              um.
            </div>
          </div>
          <Link
            onClick={() =>
              trackPlansButtonClicked({
                ctaText: "Upgrade",
                isBatchBanner: true,
              })
            }
            to="/planos"
            className={css["button"]}>
            Upgrade
          </Link>
        </div>
      </div>
    );

  if (!data || (hasDismissed && data.expiration > 0)) return null;

  const renderBody = ({ formatted, completed }) => {
    if (!completed) {
      return (
        <div>
          <div className={css["text"]}>
            <div>Você será liberado em breve!</div>
            <div>
              Faltam{" "}
              <span>
                {formatted.minutes} minutos e {formatted.seconds} segundos
              </span>{" "}
              para você poder executar estratégias em múltiplos ativos
              novamente. <em>Hold tight!</em>
            </div>
          </div>
          <button
            className={css["close"]}
            onClick={() => setHasDismissed(true)}>
            <CloseIcon />
          </button>
        </div>
      );
    }

    if (displayOnly) {
      return (
        <div>
          <div className={css["text"]}>
            <div>Acelere a sua análise!</div>
            <div>
              Execute a mesma estratégia em múltiplos ativos ao mesmo tempo e
              descubra quais são os mais promissores;
            </div>
          </div>
          <Link to="/backtests" className={css["button"]}>
            Simulador
          </Link>
        </div>
      );
    }

    return (
      <div>
        <div className={css["text"]}>
          <div>Acelere a sua análise!</div>
          <div>
            Execute a mesma estratégia em múltiplos ativos ao mesmo tempo e
            descubra quais são os mais promissores.
          </div>
        </div>
        <button
          onClick={() => {
            logAmplitudeEvent(EVENT_MAP.BATCH_BUTTON_CLICKED, {
              strategyId,
              ticker,
              setupId,
              timeframeId,
            });
            setIsModalOpen(true);
          }}
          className={css["button"]}>
          Acelerar
        </button>
      </div>
    );
  };

  return (
    <div className={css["container"]}>
      <div>
        <BatchIcon />
      </div>
      <Countdown
        date={data.expiration * 1000}
        renderer={renderBody}
        zeroPadTime={2}
      />
      {!displayOnly && (
        <BatchModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          parameters={parameters}
          strategyId={strategyId}
          timeframeId={timeframeId}
          ticker={ticker}
          setupId={setupId}
          refetch={refetch}
        />
      )}
    </div>
  );
};

BatchBanner.propTypes = {
  setupId: PropTypes.number,
  timeframeId: PropTypes.string,
  ticker: PropTypes.string,
  parameters: PropTypes.object,
  strategyId: PropTypes.string,
  displayOnly: PropTypes.bool,
};

export default BatchBanner;
