import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import * as css from "./CointegrationBatchBanner.module.css";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "gatsby";
import BatchIcon from "../../icons/batch.svg";
import Countdown from "react-countdown";
import CloseIcon from "../../icons/close.svg";
import { useSessionStorage } from "react-use";
import { trackPlansButtonClicked } from "../../utils/amplitude";

const CointegrationBatchBanner = () => {
  const [hasDismissed, setHasDismissed] = useSessionStorage(
    "dismissedCointegrationBatchBanner",
    false
  );

  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const { data } = useQuery(
    ["cointegrationBatchExpiration", user.userId],
    () => callAPI(`/api/cointegration/expiration`),
    {
      enabled: user.isPremium,
    }
  );

  if (!user.isPremium)
    return (
      <div className={css["container"]}>
        <div>
          <BatchIcon />
        </div>
        <div>
          <div className={css["text"]}>
            <div>Acelere a sua análise sendo Premium!</div>
            <div>
              Faça testes de cointegração em múltiplos ativos ao mesmo tempo.
              Descubra os melhores pares sem a necessidade de testar um a um.
            </div>
          </div>
          <Link
            onClick={() =>
              trackPlansButtonClicked({
                ctaText: "Upgrade",
                isCointegrationBatchBanner: true,
              })
            }
            to="/planos"
            className={css["button"]}>
            Upgrade
          </Link>
        </div>
      </div>
    );

  if (!data || (hasDismissed && data.expiration > 0)) return null;

  const renderBody = ({ formatted, completed }) => {
    if (!completed) {
      return (
        <div>
          <div className={css["text"]}>
            <div>Você será liberado em breve!</div>
            <div>
              Faltam{" "}
              <span>
                {formatted.minutes} minutos e {formatted.seconds} segundos
              </span>{" "}
              para você poder testar cointegrações em múltiplos ativos
              novamente. <em>Hold tight!</em>
            </div>
          </div>
          <button
            className={css["close"]}
            onClick={() => setHasDismissed(true)}>
            <CloseIcon />
          </button>
        </div>
      );
    }

    return (
      <div>
        <div className={css["text"]}>
          <div>Acelere a sua análise!</div>
          <div>
            Teste a cointegração em múltiplos ativos ao mesmo tempo e aumente a
            sua chance de encontrar oportunidades.
          </div>
        </div>
        <Link to="/cointegracao" className={css["button"]}>
          Cointegração
        </Link>
      </div>
    );
  };

  return (
    <div className={css["container"]}>
      <div>
        <BatchIcon />
      </div>
      <Countdown
        date={data.expiration * 1000}
        renderer={renderBody}
        zeroPadTime={2}
      />
    </div>
  );
};

export default CointegrationBatchBanner;
