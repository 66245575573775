import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import Spinner from "../components/common/Spinner";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import { showError } from "../utils/utils";
import * as css from "./bandas-de-bollinger.module.css";
import ArrowDownIcon from "../icons/arrow-down.svg";
import InfoIcon from "../icons/info-question-circle.svg";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import YouTubePlayer from "../components/common/YouTubePlayer";
import { trackScreeningViewed } from "../utils/amplitude";

const PORTFOLIO_ID = process.env.GATSBY_TOP_200_PORTFOLIO_ID;

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "H2", label: "120 Minutos" },
  { value: "H1", label: "60 Minutos" },
];

const BollingerBands = () => {
  const { isLoggedIn } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["H1", "H2"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [ticker, setTicker] = useState("");
  const [isFFFD, setFFFD] = useState(false);
  const [isFDFF, setFDFF] = useState(false);
  const [avgWindow, setWindow] = useState(20);
  const [numberOfStd, setNumberOfStd] = useState(2);
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [timeframe, setTimeframe] = useState(
    PROTECTED_TIMEFRAME_OPTIONS.find(({ value }) => value === "D1")
  );

  const [debouncedWindow] = useDebounce(avgWindow, 800);
  const [debouncedStd] = useDebounce(numberOfStd, 800);

  const staticData = useStaticQuery(graphql`
    query {
      allBb {
        nodes {
          current_close
          current_lower_band
          current_middle_band
          current_upper_band
          previous_lower_band
          previous_middle_band
          previous_upper_band
          ticker
          previous_close
          buildTime
          last_updated
        }
      }
    }
  `);

  const [data, setData] = useState(staticData);

  const callAPI = useAPI({ withCredentials: true });

  const { data: bbData, isLoading } = useQuery(
    ["bbScreening", timeframe, debouncedStd, debouncedWindow],
    ({ queryKey }) => {
      const timeframeId = queryKey[1].value;
      if (
        timeframeId === "D1" &&
        debouncedWindow === 20 &&
        debouncedStd === 2
      ) {
        setData(staticData);
        return;
      }
      if (!debouncedWindow || !debouncedStd || debouncedWindow < 2) return;
      return callAPI(
        `/api/portfolio/${PORTFOLIO_ID}/bb?timeframe=${timeframeId}&sd_factor=${debouncedStd}&window=${debouncedWindow}`
      );
    },
    {
      onError: showError,
      retry: (count, error) => {
        if (
          error.message === "Parâmetro inválido para usuários não cadastrados."
        )
          return false;
      },
    }
  );

  useEffect(() => {
    if (bbData) {
      const newData = { allBb: { nodes: [] } };
      Object.keys(bbData).map(symbol => {
        const d = bbData[symbol];
        newData.allBb.nodes.push({
          ...d,
          ticker: symbol,
          buildTime: d.last_updated.replace("UTC", "Z"),
        });
      });
      setData(newData);
    }
  }, [bbData]);

  const lastUpdated = data.allBb.nodes[0]?.buildTime;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data.allBb.nodes
      .map(node => ({
        ...node,
        currentPosition:
          node.current_close < node.current_lower_band
            ? "Abaixo"
            : node.current_close > node.current_upper_band
            ? "Acima"
            : "Dentro",
        previousPosition:
          node.previous_close < node.previous_lower_band
            ? "Abaixo"
            : node.previous_close > node.previous_upper_band
            ? "Acima"
            : "Dentro",
        currentWidth:
          Math.round(
            (100 * (node.current_upper_band - node.current_lower_band)) /
              node.current_middle_band
          ) / 100,
        previousWidth:
          Math.round(
            (100 * (node.previous_upper_band - node.previous_lower_band)) /
              node.previous_middle_band
          ) / 100,
      }))
      .filter(node => {
        if (isFFFD)
          return (
            node.currentPosition === "Dentro" &&
            (node.previousPosition === "Abaixo" ||
              node.previousPosition === "Acima")
          );
        return true;
      })
      .filter(node => {
        if (isFDFF)
          return (
            (node.currentPosition === "Abaixo" ||
              node.currentPosition === "Acima") &&
            node.previousPosition === "Dentro"
          );
        return true;
      })
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    "currentWidth",
    "desc"
  );

  useEffect(() => {
    trackScreeningViewed({ key: "bollingerBands" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Bandas de Bollinger",
        description:
          "Screening com a posição dos ativos mais líquidos do Ibovespa em relação à suas bandas de bollinger, em diversos timeframes.",
      }}
      title="Bandas de Bollinger"
      ctaMessage="Visualize a posição dos ativos em relação às bandas em diferentes timeframes.">
      <div>
        <div className={css["youtubeContainer"]}>
          <p>
            As <em>Bandas de Bollinger</em> podem ser entendidas como bandas de
            volatilidade que se encontram acima e abaixo de uma média móvel.{" "}
            <Link to="/como-calcular-as-bandas-de-bollinger-em-python">
              Aprenda o que são, pra que servem, e como calcular as Bandas de
              Bollinger de forma rápida e prática.
            </Link>
          </p>
          <YouTubePlayer
            videoId="duLi_pxbsfE"
            width={500}
            height={315}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <p>
          A lista abaixo contém a posição dos ativos em relação às bandas. É
          possível alterar a janela da média móvel aritmética (máximo de 50
          períodos), a quantidade de desvios-padrão (máximo de 5), além de
          filtrar pelos ativos formando um <em>FFFD</em> (Fechou Fora Fechou
          Dentro) ou <em>FDFF</em> (Fechou Dentro Fechou Fora).
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="bb-tf">Timeframe</label>
            <Select
              id="bb-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="rolling-window">Período da Média</label>
            <input
              id="rolling-window"
              type="number"
              min={2}
              max={50}
              value={avgWindow}
              onChange={e =>
                setWindow(e.target.value ? parseInt(e.target.value) : "")
              }
              disabled={!isLoggedIn}
              title={
                isLoggedIn
                  ? undefined
                  : "Crie sua conta para desbloquear esse filtro"
              }
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="std-factor">Desvios-Padrão</label>
            <input
              id="std-factor"
              type="number"
              min={1}
              max={5}
              value={numberOfStd}
              onChange={e =>
                setNumberOfStd(e.target.value ? parseFloat(e.target.value) : "")
              }
              disabled={!isLoggedIn}
              title={
                isLoggedIn
                  ? undefined
                  : "Crie sua conta para desbloquear esse filtro"
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="bb-asset">Ativo</label>
            <input
              id="bb-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="is-fffd"
                type="checkbox"
                checked={isFFFD}
                onChange={e => {
                  setFFFD(e.target.checked);
                }}
              />
              <label htmlFor="is-fffd">
                FFFD{" "}
                <InfoIcon
                  data-tooltip-id="bb-tip"
                  data-tooltip-content="Fechou Fora Fechou Dentro, quando um ativo fecha dentro das bandas tendo fechado fora no candle anterior."
                />
              </label>
            </div>
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="is-fdff"
                type="checkbox"
                checked={isFDFF}
                onChange={e => {
                  setFDFF(e.target.checked);
                }}
              />
              <label htmlFor="is-fdff">
                FDFF{" "}
                <InfoIcon
                  data-tooltip-id="bb-tip"
                  data-tooltip-content="Fechou Dentro Fechou Fora, quando um ativo fecha fora das bandas tendo fechado dentro no candle anterior."
                />
              </label>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        {isLoading && isLoggedIn ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Robôs trabalhando..." />
          </div>
        ) : (
          <>
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th
                      data-tooltip-id="bb-tip"
                      data-tooltip-content={`Preço aferido na última atualização.`}>
                      Preço<sup>1</sup>
                    </th>
                    <th
                      data-tooltip-id="bb-tip"
                      data-tooltip-content={`Valor da banda inferior / central / superior.`}>
                      Bandas<sup>2</sup>
                    </th>
                    <th
                      data-tooltip-id="bb-tip"
                      data-tooltip-content={`Diferença entre as bandas dividido pela média móvel.`}>
                      Largura<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="bb-tip"
                      data-tooltip-content={`Posição anterior do preço em relação às bandas.`}>
                      Posição Anterior<sup>4</sup>
                    </th>
                    <th
                      data-tooltip-id="bb-tip"
                      data-tooltip-content={`Posição atual do preço em relação às bandas.`}>
                      Posição Atual<sup>5</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>{node.ticker}</td>
                      <td>{node.current_close.toFixed(2)}</td>
                      <td>
                        <div className={css["bandsCell"]}>
                          <span className={css["arrowContainer"]}>
                            {node.current_lower_band.toFixed(2)}{" "}
                            {node.current_lower_band <
                            node.previous_lower_band ? (
                              <ArrowDownIcon
                                className={css["arrowDown"]}
                                data-tooltip-id={`lower-arrow-${node.ticker}`}
                              />
                            ) : node.current_lower_band <
                              node.previous_lower_band ? (
                              <ArrowDownIcon
                                className={css["arrowUp"]}
                                data-tooltip-id={`lower-arrow-${node.ticker}`}
                              />
                            ) : null}
                          </span>{" "}
                          / {node.current_middle_band.toFixed(2)} /{" "}
                          <span className={css["arrowContainer"]}>
                            {node.current_upper_band.toFixed(2)}
                            {node.current_upper_band <
                            node.previous_upper_band ? (
                              <ArrowDownIcon
                                className={css["arrowDown"]}
                                data-tooltip-id={`upper-arrow-${node.ticker}`}
                              />
                            ) : node.current_upper_band <
                              node.previous_upper_band ? (
                              <ArrowDownIcon
                                className={css["arrowUp"]}
                                data-tooltip-id={`upper-arrow-${node.ticker}`}
                              />
                            ) : null}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className={css["arrowContainer"]}>
                          {node.currentWidth.toFixed(2)}
                          {node.currentWidth < node.previousWidth ? (
                            <ArrowDownIcon
                              className={css["arrowDown"]}
                              data-tooltip-id={`width-arrow-${node.ticker}`}
                            />
                          ) : node.currentWidth > node.previousWidth ? (
                            <ArrowDownIcon
                              className={css["arrowUp"]}
                              data-tooltip-id={`width-arrow-${node.ticker}`}
                            />
                          ) : null}
                        </span>
                      </td>
                      <td>{node.previousPosition}</td>
                      <td>{node.currentPosition}</td>
                      <Tooltip id={`lower-arrow-${node.ticker}`} clickable>
                        {node.current_lower_band < node.previous_lower_band
                          ? `Diminuiu de ${node.previous_lower_band.toFixed(
                              2
                            )} para ${node.current_lower_band.toFixed(2)}`
                          : `Aumentou de ${node.previous_lower_band.toFixed(
                              2
                            )} para ${node.current_lower_band.toFixed(2)}`}
                      </Tooltip>
                      <Tooltip id={`upper-arrow-${node.ticker}`} clickable>
                        {node.current_upper_band < node.previous_upper_band
                          ? `Diminuiu de ${node.previous_upper_band.toFixed(
                              2
                            )} para ${node.current_upper_band.toFixed(2)}`
                          : `Aumentou de ${node.previous_upper_band.toFixed(
                              2
                            )} para ${node.current_upper_band.toFixed(2)}`}
                      </Tooltip>
                      <Tooltip id={`width-arrow-${node.ticker}`} clickable>
                        {node.currentWidth < node.previousWidth
                          ? `Diminuiu de ${node.previousWidth.toFixed(
                              2
                            )} para ${node.currentWidth.toFixed(2)}`
                          : `Aumentou de ${node.previousWidth.toFixed(
                              2
                            )} para ${node.currentWidth.toFixed(2)}`}
                      </Tooltip>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço aferido na última atualização.
              </div>
              <div>
                <sup>2</sup> Valor da banda inferior / central / superior.
              </div>
              <div>
                <sup>3</sup> Diferença entre as bandas dividido pela média
                móvel.
              </div>
              <div>
                <sup>4</sup> Posição anterior do preço em relação às bandas.
              </div>
              <div>
                <sup>5</sup> Posição atual do preço em relação às bandas.
              </div>
            </div>
          </>
        )}
        <Tooltip id="bb-tip" />
        <InstagramLink />
      </div>
    </PublicLayout>
  );
};

export default BollingerBands;
