import noScroll from "no-scroll";
import PropTypes from "prop-types";
import { useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "../../icons/close.svg";
import * as css from "./Modal.module.css";

const CommonModal = ({ isOpen, onClose, children, modalClassName = "" }) => {
  // Prevent scrolling parent component when modal is open
  useEffect(() => {
    if (isOpen) noScroll.on();
    return () => noScroll.off();
  });

  // Combine classes if any
  const modalClasses = [css["modal"], modalClassName]
    .filter(className => !!className)
    .join(" ");

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      overlayClassName={css["overlay"]}
      className={modalClasses}
      onRequestClose={onClose}
      contentLabel="Modal">
      <div>
        <button className={css["close"]} onClick={onClose}>
          <CloseIcon aria-hidden />
        </button>
      </div>
      <div className={css["content"]}>{children}</div>
    </Modal>
  );
};

CommonModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  modalClassName: PropTypes.string,
};

export default CommonModal;
