import { Link } from "gatsby";
import { isEmpty, uniq } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import useSortedLowRisk from "../hooks/useSortedLowRisk";
import useSortedMagicFormula from "../hooks/useSortedMagicFormula";
import useSortedMomentum from "../hooks/useSortedMomentum";
import { SELECT_MULTI } from "../utils/select";
import * as css from "./magic-formula.module.css";
import { useDebounce } from "use-debounce";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import { trackScreeningViewed } from "../utils/amplitude";

const TOP_ASSETS = 30;

const TOP_200_PORTFOLIO_ID = Number(process.env.GATSBY_TOP_200_PORTFOLIO_ID);
const TWO_YEARS_PORTFOLIO_ID = Number(
  process.env.GATSBY_TWO_YEARS_DATA_PORTFOLIO
);

const SORT_OPTIONS = [
  { value: "score asc", label: "Melhor Posição" },
  { value: "momentumPosition asc", label: "Melhor Momentum" },
  { value: "lowRiskPosition asc", label: "Melhor Low Risk" },
  { value: "magicFormulaPosition asc", label: "Melhor Magic Formula" },
];

const sortAll = (
  momentum,
  lowRisk,
  magicFormula,
  momentumWeight,
  lowRiskWeight,
  magicFormulaWeight
) => {
  const allAssets = uniq(
    momentum.map(({ ticker }) => ticker),
    lowRisk.map(({ ticker }) => ticker),
    magicFormula.map(({ ticker }) => ticker)
  );

  const buildTime = momentum[0].buildTime;

  const ranking = [];
  allAssets.forEach(ticker => {
    const momentumPosition =
      momentum.findIndex(obj => obj.ticker === ticker) + 1;

    const lowRiskPosition =
      lowRisk.find(obj => obj.ticker === ticker)?.overallRanking || 100;

    const magicFormulaPosition =
      magicFormula.find(obj => obj.ticker === ticker)?.overallRanking || 100;

    const score = Math.abs(
      -momentumWeight * momentumPosition +
        -lowRiskWeight * lowRiskPosition +
        -magicFormulaWeight * magicFormulaPosition
    );

    ranking.push({
      ticker,
      momentumPosition,
      lowRiskPosition,
      magicFormulaPosition,
      score,
      buildTime,
    });
  });

  return orderBy(ranking, "score", "asc");
};

const FullFactor = () => {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [momentumWeight, setMomentumWeight] = useState(2);
  const [lowRiskWeight, setLowRiskWeight] = useState(1);
  const [magicFormulaWeight, setMagicFormulaWeight] = useState(1);
  const [showPortfolios, setShowPortfolios] = useState(false);

  const [debouncedMomentumWeight] = useDebounce(momentumWeight, 800);
  const [debouncedLowRiskWeight] = useDebounce(lowRiskWeight, 800);
  const [debouncedMagicFormulaWeight] = useDebounce(magicFormulaWeight, 800);

  const sortedMomentum = useSortedMomentum({
    fieldToSort: "momentum",
    directionToSort: "desc",
    portfolioId: TOP_200_PORTFOLIO_ID,
  });

  const sortedLowRisk = useSortedLowRisk({
    fieldToSort: "overallRanking",
    directionToSort: "asc",
    portfolioId: TWO_YEARS_PORTFOLIO_ID,
  });

  const sortedMagicFormula = useSortedMagicFormula({
    fieldToSort: "score",
    directionToSort: "asc",
  });

  const sorted = sortAll(
    sortedMomentum,
    sortedLowRisk,
    sortedMagicFormula,
    debouncedMomentumWeight,
    debouncedLowRiskWeight,
    debouncedMagicFormulaWeight
  );

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn } = useAuth();

  const filtered = orderBy(
    sorted
      .map((node, i) => ({ ...node, overallRanking: i + 1 }))
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    item => item[fieldToSort],
    directionToSort
  );

  const lastUpdated = filtered[0]?.buildTime.replace("UTC", "Z");

  useEffect(() => {
    trackScreeningViewed({ key: "fullFactor" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Ranking Full Factor",
        description:
          "Ranking de Factor Investing combinando três fatores: Momentum, Low Risk e Magic Formula.",
      }}
      title="Ranking Full Factor"
      premiumMessage="Utilize o cupom LANCAMENTO10 para 10% off no plano anual"
      ctaMessage="Crie sua conta para alterar os pesos de cada fator.">
      <div>
        <p>
          A lista abaixo é um ranking de <b>Factor Investing</b> considerando
          três fatores fundamentais: <Link to="/momentum">Momentum</Link>,{" "}
          <Link to="/low-risk">Low Risk</Link> e{" "}
          <Link to="/magic-formula">Magic Formula</Link>. Os fatores são
          ponderados de acordo com seus pesos e quanto menor a pontuação,
          melhor.
        </p>
        <p>
          O objetivo do <b>Ranking Full Factor</b> é listar os ativos que
          pontuam bem em todos os fatores, de acordo com a importância dada a
          cada fator.{" "}
          <em>
            Nota: ativos que não estejam presentes em um fator recebem uma
            posição média por padrão.
          </em>
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="momentum-weight">Peso Momentum</label>
            <input
              id="momentum-weight"
              type="number"
              min={0}
              max={10}
              value={momentumWeight}
              onChange={e => setMomentumWeight(e.target.value)}
              disabled={!isLoggedIn}
              title={
                isLoggedIn ? undefined : "Crie sua conta para alterar o peso"
              }
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="low-risk-weight">Peso Low Risk</label>
            <input
              id="low-risk-weight"
              type="number"
              min={0}
              max={10}
              value={lowRiskWeight}
              onChange={e => setLowRiskWeight(e.target.value)}
              disabled={!isLoggedIn}
              title={
                isLoggedIn ? undefined : "Crie sua conta para alterar o peso"
              }
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "170px" }}>
            <label htmlFor="mf-weight">Peso Magic Formula</label>
            <input
              id="mf-weight"
              type="number"
              min={0}
              max={10}
              value={magicFormulaWeight}
              onChange={e => setMagicFormulaWeight(e.target.value)}
              disabled={!isLoggedIn}
              title={
                isLoggedIn ? undefined : "Crie sua conta para alterar o peso"
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="mf-asset">Ativo</label>
            <input
              id="mf-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="mf-order">Ordenar por</label>
            <Select
              id="mf-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_MULTI,
                container: provided => ({
                  ...provided,
                  width: "265px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th
                  data-tooltip-id="ff-tip"
                  data-tooltip-content={`Posição final considerando os 3 fatores e seus respectivos pesos.`}>
                  Posição<sup>1</sup>
                </th>
                <th>Ativo</th>
                <th
                  data-tooltip-id="ff-tip"
                  data-tooltip-content={`Posição no ranking de Momentum.`}>
                  Momentum<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="ff-tip"
                  data-tooltip-content={`Posição no ranking de Low Risk.`}>
                  Low Risk<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="ff-tip"
                  data-tooltip-content={`Posição no ranking de Magic Formula.`}>
                  Magic Formula<sup>4</sup>
                </th>
                <th
                  data-tooltip-id="ff-tip"
                  data-tooltip-content={`Pontuação total, quanto menor melhor.`}>
                  Pontos<sup>5</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td
                    style={{
                      color:
                        node.overallRanking <= TOP_ASSETS
                          ? "#49ce8b"
                          : undefined,
                      fontWeight:
                        node.overallRanking <= TOP_ASSETS ? 600 : undefined,
                    }}>
                    {node.overallRanking}
                  </td>
                  <td>{node.ticker}</td>
                  <td>{node.momentumPosition}º</td>
                  <td>{node.lowRiskPosition}º</td>
                  <td>{node.magicFormulaPosition}º</td>
                  <td>{node.score}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Posição final considerando os 3 fatores e seus
            respectivos pesos.
          </div>
          <div>
            <sup>2</sup> Posição no ranking de Momentum.
          </div>
          <div>
            <sup>3</sup> Posição no ranking de Low Risk.
          </div>
          <div>
            <sup>4</sup> Posição no ranking de Magic Formula.
          </div>
          <div>
            <sup>5</sup> Pontuação total, quanto menor melhor.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="ff-tip" />
      </div>
    </PublicLayout>
  );
};

export default FullFactor;
