import { graphql } from "gatsby";
import PropTypes from "prop-types";
import LeadPageContainer from "../components/leadPages/LeadPageContainer";

const LeadPage = ({ location, data }) => {
  return <LeadPageContainer location={location} data={data.leadsPagesJson} />;
};

LeadPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LeadPage;

export const query = graphql`
  query($slug: String!) {
    leadsPagesJson(slug: { eq: $slug }) {
      slug
      title
      loadingText
      successTitle
      successText
      theme
    }
  }
`;
