import { graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { DateTime } from "luxon";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./ipos.module.css";

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const initialYear = 2020;
  const options = [{ value: "all", label: `Desde ${initialYear}` }];

  for (let i = initialYear; i <= currentYear; i++) {
    options.push({
      value: i.toString(),
      label: `Apenas ${i}`,
    });
  }

  return options;
};

const YEARS_OPTIONS = getYearOptions();

const SORT_OPTIONS = [
  { value: "variation desc", label: "Melhor Retorno" },
  { value: "variation asc", label: "Pior Retorno" },
  { value: "diff desc", label: "Melhor Retorno vs IBOV" },
  { value: "diff asc", label: "Pior Retorno vs IBOV" },
  { value: "IPO_date desc", label: "Mais Recentes" },
  { value: "IPO_date asc", label: "Mais Antigos" },
];

const IPO = () => {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [year, setYear] = useState(YEARS_OPTIONS[0]);
  const [ticker, setTicker] = useState("");

  const data = useStaticQuery(graphql`
    query {
      tickers: allIpo {
        nodes {
          IPO_date
          IPO_opening_price
          buildTime
          current_price
          ibov_variation
          last_date
          ticker
          variation
        }
      }
    }
  `);

  const lastUpdated = data.tickers.nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const filtered = orderBy(
    data.tickers.nodes
      .map(node => ({
        ...node,
        diff: node.variation - node.ibov_variation,
      }))
      .filter(node => {
        if (year.value === "all") return true;
        return node.IPO_date.slice(0, 4) === year.value;
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    fieldToSort,
    directionToSort
  );

  return (
    <PublicLayout
      seoProps={{
        title: "IPOs Recentes",
        description:
          "Acompanhe o desempenho dos ativos que estrearam na bolsa brasileira a partir de 2020.",
      }}
      title="IPOs Recentes"
      ctaMessage="Crie sua conta para aproveitar o máximo do QuantBrasil.">
      <div>
        <p>
          A lista abaixo contém todos os ativos que abriram capital na bolsa
          brasileira desde 2020. Criamos um ranking com seu retorno total desde
          a estreia na bolsa, além do comparativo frente ao IBOV no mesmo
          período.{" "}
          <em>Nota: os valores podem diferir da sua plataforma de trading.</em>
        </p>
        <p>
          Dúvidas ou sugestões em relação à lista?{" "}
          <a href="https://t.me/quantbrasil" target="_blank" rel="noreferrer">
            Discuta-as na nossa comunidade no Telegram
          </a>
          !
        </p>
        <LastUpdated date={lastUpdated} isISO />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="ipo-year">Ano de IPO</label>
            <Select
              id="ipo-year"
              options={YEARS_OPTIONS}
              value={year}
              onChange={option => setYear(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "170px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="beta-period">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: LWSA3"
            />
          </div>
          <div>
            <label htmlFor="ipo-order">Ordenar por</label>
            <Select
              id="ipo-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "250px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th>Estreia</th>
                <th>Retorno</th>
                <th
                  data-tooltip-id="ipos-tip"
                  data-tooltip-content={`Preço de abertura no primeiro dia de negociação.`}>
                  Abertura IPO<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="ipos-tip"
                  data-tooltip-content={`Preço aferido na última atualização.`}>
                  Preço Atual<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="ipos-tip"
                  data-tooltip-content={`Considera o preço de abertura na data do IPO.`}>
                  Variação IBOV<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="ipos-tip"
                  data-tooltip-content={`Variação em pontos percentuais.`}>
                  Retorno vs IBOV<sup>4</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td>{node.ticker}</td>
                  <td>
                    {DateTime.fromISO(node.IPO_date)
                      .setLocale("pt-br")
                      .toLocaleString()}
                  </td>
                  <td
                    style={{
                      color: node.variation >= 0 ? "#49ce8b" : "#d64242",
                      fontWeight: "600",
                    }}>
                    {(node.variation * 100).toFixed(2)}%
                  </td>
                  <td>{node.IPO_opening_price.toFixed(2)}</td>
                  <td>{node.current_price.toFixed(2)}</td>
                  <td>{(node.ibov_variation * 100).toFixed(2)}%</td>
                  <td>{(node.diff * 100).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Preço de abertura no primeiro dia de negociação.
          </div>
          <div>
            <sup>2</sup> Preço aferido na última atualização.
          </div>
          <div>
            <sup>3</sup> Considera o preço de abertura na data do IPO.
          </div>
          <div>
            <sup>4</sup> Variação em pontos percentuais.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="ipos-tip" />
      </div>
    </PublicLayout>
  );
};

export default IPO;
