import { Link } from "gatsby";
import * as css from "./PremiumWidget.module.css";
import StarIcon from "../../icons/star.svg";
import FeedWidget from "./FeedWidget";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const PremiumWidget = () => {
  return (
    <FeedWidget>
      <div className={css["icon"]}>
        <StarIcon />
      </div>
      <p>
        Desbloqueie <b>todas</b> as funcionalidades do QuantBrasil.
      </p>
      <Link
        className={css["button"]}
        to="/planos"
        onClick={() =>
          logAmplitudeEvent(EVENT_MAP.FEED_WIDGET_BUTTON_CLICKED, {
            widget: "premium",
          })
        }>
        Seja Premium
      </Link>
    </FeedWidget>
  );
};

export default PremiumWidget;
