import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import UpgradeSection from "../components/common/UpgradeSection";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import { getRSIStyle } from "../utils/utils";
import * as css from "./ifr2.module.css";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const SORT_OPTIONS = [
  { value: "rsi.rsi asc", label: "Menor IFR" },
  { value: "rsi.rsi desc", label: "Maior IFR" },
  { value: "rsi.upside desc", label: "Maior Upside" },
];

const MM50_DIRECTION = [
  { value: "Qualquer", label: "Qualquer" },
  { value: "Cima", label: "Cima" },
  { value: "Baixo", label: "Baixo" },
];

const IFR2 = () => {
  const [minRSI, setMinRSI] = useState(0);
  const [maxRSI, setMaxRSI] = useState(100);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [averageDirection, setAverageDirection] = useState(MM50_DIRECTION[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allTicker: { nodes },
  } = useStaticQuery(graphql`
    query TickerQuery {
      allTicker(sort: { rsi: { rsi: ASC } }) {
        nodes {
          price
          rsi {
            rsi
            target
            upside
          }
          mm50_is_up
          ticker
          buildTime
        }
      }
    }
  `);

  const buildTime = nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn, user } = useAuth();

  const filtered = orderBy(
    nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(({ rsi: { rsi } }) => rsi <= maxRSI && rsi >= minRSI)
      .filter(({ mm50_is_up }) => {
        if (averageDirection.value === "Cima") return mm50_is_up;
        if (averageDirection.value === "Baixo") return !mm50_is_up;
        return true;
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "rsi" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "IFR2",
        description:
          "Screening com o IFR2 dos ativos da bolsa brasileira. Rode os backtests de forma gratuita.",
      }}
      title="IFR2"
      ctaMessage="Crie sua conta para customizar os backtests da estratégia IFR2.">
      <div>
        <p>
          A lista abaixo contém os valores do IFR2 para os ativos mais líquidos
          da B3. Os valores não são em tempo real e podem diferir de sua
          plataforma de trading.{" "}
          <Link to="/aprenda-a-calcular-o-ifr-indice-de-forca-relativa">
            Aprenda a calcular o IFR utilizando Python.
          </Link>{" "}
          <strong>Clique no ativo para rodar seu backtest.</strong>
        </p>
        <LastUpdated date={buildTime} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-ifr2">{`Min IFR2`}</label>
            <input
              id="min-ifr2"
              type="number"
              min={0}
              max={100}
              value={minRSI}
              onChange={e => setMinRSI(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-ifr2">{`Max IFR2`}</label>
            <input
              id="max-ifr2"
              type="number"
              min={0}
              max={100}
              value={maxRSI}
              onChange={e => setMaxRSI(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="ifr2-mm50">Direção MM50</label>
            <Select
              id="ifr2-mm50"
              options={MM50_DIRECTION}
              value={averageDirection}
              onChange={option => setAverageDirection(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "160px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="ifr2-ticker">Ativo</label>
            <input
              id="ifr2-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="ifr2-order">Ordenar por</label>
            <Select
              id="ifr2-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "160px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th>IFR2</th>
                <th
                  data-tooltip-id="ifr2-tip"
                  data-tooltip-content="Preços tem um delay de 15 minutos a partir da última atualização.">
                  Preço<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="ifr2-tip"
                  data-tooltip-content="Máxima dos últimos dois dias incluindo o dia atual.">
                  Alvo<sup>2</sup>
                </th>
                <th>Upside</th>
                <th
                  data-tooltip-id="ifr2-tip"
                  data-tooltip-content="Direção da média móvel aritmética de 50 períodos.">
                  MM50<sup>3</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td style={getRSIStyle(node.rsi.rsi)}>
                    <Link
                      onClick={() =>
                        logAmplitudeEvent(EVENT_MAP.RSI_CLICKED, {
                          ticker: node.ticker,
                          rsi: node.rsi.rsi,
                        })
                      }
                      to={`/backtests?strategy=ifr2&timeframeId=D1&ticker=${
                        node.ticker
                      }&rsi=${node.rsi.rsi}${
                        node.mm50_is_up ? `&mm50IsUp=1` : ""
                      }`}
                      style={{ color: "inherit" }}>
                      {node.ticker}
                    </Link>
                  </td>
                  <td style={getRSIStyle(node.rsi.rsi)}>{node.rsi.rsi}</td>
                  <td>{node.price.toFixed(2)}</td>
                  <td>{node.rsi.target.toFixed(2)}</td>
                  <td>{node.rsi.upside.toFixed(2)}%</td>
                  <td>{node.mm50_is_up ? "Cima" : "Baixo"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Preços tem um delay de 15 minutos a partir da última
            atualização.
          </div>
          <div>
            <sup>2</sup> Máxima dos últimos dois dias incluindo o dia atual.
          </div>
          <div>
            <sup>3</sup> Direção da média móvel aritmética de 50 períodos.
          </div>
        </div>
        <InstagramLink />
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear alertas de IFR2 e o ranking com os ativos que melhor performaram nessa estratégia!" />
        )}
      </div>
      <Tooltip id="ifr2-tip" />
    </PublicLayout>
  );
};

export default IFR2;
