export const SORT_OPTIONS = [
  { value: "momentum desc", label: "Maior Momentum" },
  { value: "momentum asc", label: "Menor Momentum" },
  { value: "r_squared desc", label: "Maior Coef. Determinação" },
  { value: "r_squared asc", label: "Menor Coef. Determinação" },
];

export const PERIOD_OPTIONS = [
  { value: "DAYS_30", label: "30 dias" },
  { value: "DAYS_90", label: "90 dias" },
  { value: "DAYS_180", label: "180 dias" },
];

export const REGRESSION_OPTIONS = [
  { value: "LINEAR", label: "Linear" },
  { value: "EXPONENTIAL", label: "Exponencial" },
];

export const LAG_OPTIONS = [
  { value: "momentum_lag1", label: "1 Pregão" },
  { value: "momentum_lag5", label: "5 Pregões" },
  { value: "momentum_lag20", label: "20 Pregões" },
];

export const parseSlope = node => {
  if (node.regression === "LINEAR") return node.slope.toFixed(4);
  if (node.regression === "EXPONENTIAL")
    return ((1 + node.slope - 1) * 100).toFixed(4) + "%";
};

export const parseMomentum = (momentum, regression) => {
  if (regression === "LINEAR") return momentum.toFixed(2);
  if (regression === "EXPONENTIAL") return (100 * momentum).toFixed(2) + "%";
};

export const hasPreviousMomentum = (node, lag, isPremium) =>
  isPremium && lag && node[lag.value] !== null;

export const getMomentumStatus = (node, lag) => {
  const prevMomentum = node[lag.value];
  return node.momentum > prevMomentum
    ? "INCREASING"
    : node.momentum < prevMomentum
    ? "DECREASING"
    : "EQUAL";
};
