import PropTypes from "prop-types";
import * as css from "./ScoreIndicator.module.css";
import { Tooltip } from "react-tooltip";

const OUTER_BAR_WIDTH = 240;
const colorStart = "e34f62";
const colorEnd = "49ce8b";

function interpolateColor(colorStart, colorEnd, percentage) {
  if (percentage < 0) return `#${colorStart}`;
  if (percentage > 1) return `#${colorEnd}`;

  // Convert hex to RGB
  const start = colorStart.match(/.{1,2}/g).map(hex => parseInt(hex, 16));
  const end = colorEnd.match(/.{1,2}/g).map(hex => parseInt(hex, 16));

  // Calculate color at the given percentage
  const result = start.map((startComponent, index) => {
    const endComponent = end[index];
    return Math.round(
      startComponent + percentage * (endComponent - startComponent)
    );
  });

  // Convert RGB back to hex
  return `#${result.map(val => val.toString(16).padStart(2, "0")).join("")}`;
}

const classifyScore = (score, percentiles) => {
  if (score < percentiles.percentile_5)
    return {
      classification: "Péssimo",
      tooltip:
        "Está no 5º inferior dos últimos backtests computados o timeframe.",
    };
  if (score < percentiles.percentile_25)
    return {
      classification: "Muito Ruim",
      tooltip:
        "Está entre o 5º e o 25º percentil dos últimos backtests computados o timeframe.",
    };
  if (score < percentiles.percentile_50)
    return {
      classification: "Ruim",
      tooltip:
        "Está entre o 25º e o 50º percentil dos últimos backtests computados o timeframe.",
    };
  if (score < percentiles.percentile_75)
    return {
      classification: "Bom",
      tooltip:
        "Está entre o 50º e o 75º percentil dos últimos backtests computados o timeframe.",
    };
  if (score < percentiles.percentile_95)
    return {
      classification: "Muito Bom",
      tooltip:
        "Está entre o 75º e o 95º percentil dos últimos backtests computados o timeframe.",
    };
  return {
    classification: "Excelente",
    tooltip:
      "Está no 5% superior dos últimos backtests computados o timeframe.",
  };
};

const ScoreIndicator = ({ score, percentiles }) => {
  // Function to determine the score's position within the percentiles
  const calculateScorePosition = () => {
    const {
      percentile_5,
      percentile_25,
      percentile_50,
      percentile_75,
      percentile_95,
    } = percentiles;
    const ranges = [
      percentile_5,
      percentile_25,
      percentile_50,
      percentile_75,
      percentile_95,
    ];
    const maxScore = Math.max(...ranges);
    const minScore = Math.min(...ranges);
    // Normalize score to be within 0-100%
    return ((score - minScore) / (maxScore - minScore)) * 100;
  };

  // Let's get the bar width by computing the percentage of the range that is relative to the outer bar width
  const barWidth =
    ((percentiles.percentile_95 - percentiles.percentile_5) / 100) *
    OUTER_BAR_WIDTH;

  const scorePosition = calculateScorePosition();

  const color = interpolateColor(colorStart, colorEnd, scorePosition / 100);

  const { classification, tooltip } = classifyScore(score, percentiles);

  return (
    <div className={css["container"]}>
      <div
        style={{
          width: OUTER_BAR_WIDTH,
        }}
        className={css["outerBar"]}>
        <div
          style={{
            left: `calc(${percentiles.percentile_5}%)`,
          }}
          className={css["innerBar"]}>
          <div
            style={{
              width: barWidth,
            }}
            className={css["bar"]}>
            <div
              style={{ left: -18, color: `#${colorStart}` }}
              className={css["percentile"]}>
              {percentiles.percentile_5.toFixed(2)}
            </div>
            <div
              className={css["caret"]}
              style={{
                left: `calc(${scorePosition}% - 18px)`,
                color,
              }}>
              <div>{score.toFixed(2)}</div>
              <div style={{ width: "12px" }}>▼</div>
            </div>
            <div
              style={{ right: -18, color: `#${colorEnd}` }}
              className={css["percentile"]}>
              {percentiles.percentile_95.toFixed(2)}
            </div>
          </div>
        </div>
      </div>
      <div className={css["context"]}>
        O score de <span style={{ color }}>{score.toFixed(2)}</span> é
        classificado como{" "}
        <span
          style={{ color }}
          className={css["hint"]}
          data-tooltip-content={tooltip}
          data-tooltip-id="score-indicator-classification">
          {classification}
        </span>
        .
        <Tooltip
          id="score-indicator-classification"
          clickable
          delayHide={100}
        />
      </div>
    </div>
  );
};

ScoreIndicator.propTypes = {
  score: PropTypes.number.isRequired,
  percentiles: PropTypes.shape({
    percentile_5: PropTypes.number,
    percentile_25: PropTypes.number,
    percentile_50: PropTypes.number,
    percentile_75: PropTypes.number,
    percentile_95: PropTypes.number,
  }).isRequired,
};

export default ScoreIndicator;
