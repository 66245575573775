import PropTypes from "prop-types";
import { toPercent } from "../../utils/utils";
import * as css from "./HistogramTooltip.module.css";

const HistogramTooltip = ({ data }) => {
  return (
    <div className={css["container"]}>
      <div>
        <em>
          Entre {toPercent(data.start_interval)} e{" "}
          {toPercent(data.end_interval)}
        </em>
      </div>
      <div>
        <b>Frequência:</b> {data.frequency}
      </div>
    </div>
  );
};

HistogramTooltip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HistogramTooltip;
