import { graphql, useStaticQuery } from "gatsby";

const useStaticStrategies = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { frontmatter: { name: ASC } }
        filter: { fields: { type: { eq: "strategies" } } }
      ) {
        nodes {
          fields {
            slug
          }
          frontmatter {
            name
            tags
            strategyId
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.nodes.map(node => ({
    slug: node.fields.slug,
    name: node.frontmatter.name,
    tags: node.frontmatter.tags,
    strategyId: node.frontmatter.strategyId,
  }));
};

export default useStaticStrategies;
