import "katex/dist/katex.min.css";

import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIsClient } from "../hooks/useIsClient";
import RegisterButton from "../components/common/RegisterButton";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import Share from "../components/Share";
import { useAuth } from "../hooks/useAuth";
import * as css from "./BlogPost.module.css";
import RegisterBanner from "../components/common/RegisterBanner";
import UpgradeSection from "../components/common/UpgradeSection";
import InstagramLink from "../components/common/InstagramLink";
import YouTubePlayer from "../components/common/YouTubePlayer";
import { usePremiumPopup } from "../hooks/usePremiumPopup";
import PublicPopup from "../components/common/PublicPopup";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../utils/amplitude";

const BlogPost = ({
  data: {
    jupyterNotebook: { fields, html },
    markdownRemark: {
      frontmatter: { avatar, name },
    },
  },
  pageContext: { next, previous, authorSlug },
}) => {
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src =
      "https://cdnjs.cloudflare.com/ajax/libs/require.js/2.3.6/require.min.js";
    s.onload = () => {
      const scripts = document.querySelectorAll("#___gatsby script");
      scripts.forEach(script => {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.innerHTML = script.innerHTML;
        document.body.appendChild(s);
      });
    };

    document.body.appendChild(s);
  }, []);

  const { isLoggedIn, user } = useAuth();

  const isClient = useIsClient();

  const key = isClient ? `client` : `server`;

  const showRegisterCta = !isLoggedIn && isClient && fields.registerCta !== "";

  const [showModal, setShowModal] = usePremiumPopup(false);

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.POST_VIEWED, {
      title: fields.title,
      slug: fields.slug,
      author: fields.author,
    });
    incrementProperty("postsViewed");
  }, [fields.author, fields.slug, fields.title]);

  return (
    <div>
      <SEO
        title={fields.title}
        slug={fields.slug}
        type="article"
        description={fields.spoiler}
      />
      <Navbar />
      {showRegisterCta && (
        <RegisterBanner key={key} ctaMessage={fields.registerCta} />
      )}
      <div className={css["content"]}>
        <div className={css["header"]}>
          <h1>{fields.title}</h1>
          <Link className={css["author"]} to={`/sobre/${authorSlug}`}>
            <GatsbyImage
              image={avatar.childImageSharp.gatsbyImageData}
              style={{ marginRight: "16px", borderRadius: "50%" }}
              imgStyle={{ borderRadius: "50%" }}
              alt={name}
            />
            <div className={css["authorDetails"]}>
              <div>
                Por <span>{fields.author}</span>
              </div>
              <div>
                Em <span>{fields.date}</span>
              </div>
            </div>
          </Link>
          <Share slug={fields.slug} title={fields.title} />
        </div>
        {fields.youtubeId && (
          <div>
            <div className={css["youtubeSubtitle"]}>
              Veja também no YouTube:
            </div>
            <YouTubePlayer
              videoId={fields.youtubeId}
              width={540}
              height={315}
            />
          </div>
        )}
        <div
          className={css["post"]}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        {!isLoggedIn && (
          <>
            <hr />
            <div>
              <RegisterButton
                copy="Ainda não é cadastrado? Crie sua conta e leia com conforto habilitando o modo noturno."
                buttonText="Criar conta"
              />
            </div>
          </>
        )}
        <hr />
        <InstagramLink />
        <ul
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            listStyle: "none",
            padding: 0,
            marginLeft: 0,
            marginTop: "40px",
          }}>
          <li>
            {previous && (
              <Link to={`/${previous.fields.slug}`} rel="prev">
                ← {previous.fields.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/${next.fields.slug}`} rel="next">
                {next.fields.title} →
              </Link>
            )}
          </li>
        </ul>
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear todas as funcionalidades do QuantBrasil!" />
        )}
      </div>
      {showModal && (
        <PublicPopup isOpen={showModal} onClose={() => setShowModal(false)} />
      )}
      <Footer />
    </div>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPost;

export const query = graphql`
  query BlogPostBySlug($slug: String!, $authorSlug: String!) {
    jupyterNotebook(fields: { slug: { eq: $slug } }) {
      fields {
        title
        author
        date(formatString: "DD/MM/YYYY")
        slug
        spoiler
        registerCta
        youtubeId
      }
      html
    }
    markdownRemark(fields: { slug: { eq: $authorSlug } }) {
      frontmatter {
        name
        avatar {
          childImageSharp {
            gatsbyImageData(width: 40, height: 40, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
