import PropTypes from "prop-types";
import * as css from "./AssetsPanel.module.css";
import Input from "../common/Input";

const formatNumber = num => Math.round(num * 100) / 100;

const AssetsPanel = ({
  assets,
  onSubmit,
  isLoading,
  setAssets,
  buttonText,
}) => {
  const updateWeight = (value, weight, type) => {
    const clone = assets.concat([]);
    const index = clone.findIndex(
      asset => asset.value === value && (type ? asset.type === type : true)
    );
    const element = { ...clone[index], weight };
    clone[index] = element;
    setAssets(clone);
  };

  const totalWeight = assets
    .map(({ weight }) => weight || 0)
    .reduce((a, b) => a + b, 0);

  const totalLong = assets
    .filter(({ weight }) => weight > 0)
    .map(({ weight }) => weight)
    .reduce((a, b) => a + b, 0);

  const totalShort = assets
    .filter(({ weight }) => weight < 0)
    .map(({ weight }) => weight)
    .reduce((a, b) => a + b, 0);

  const isValid =
    assets.length > 0 &&
    assets.every(asset => asset.weight >= -200 || asset.weight <= 200);

  const canSubmit = isValid && !isLoading;

  return (
    <div>
      <div className={css["box"]}>
        <div className={css["title"]}>Portfólio</div>
        {assets.length > 0 ? (
          <div className={css["assetsList"]}>
            {assets.map(asset => (
              <div key={asset.value} className={css["item"]}>
                <div>{asset.label}</div>
                <Input
                  placeholder="Peso (%)"
                  size="small"
                  isNumber
                  onValueChange={({ floatValue }) =>
                    updateWeight(asset.value, floatValue, asset.type)
                  }
                  allowNegative={true}
                  decimalScale={2}
                  decimalSeparator=","
                  value={asset.weight || ""}
                  suffix="%"
                  isAllowed={({ floatValue }) =>
                    floatValue === undefined || floatValue <= 100
                  }
                />
              </div>
            ))}
          </div>
        ) : (
          <div className={css["assetsEmpty"]}>
            Adicione ativos ao portfólio no filtro acima.
          </div>
        )}
        {assets.length > 0 && (
          <div className={css["stats"]}>
            <div className={css["remaining"]}>
              Long{" "}
              <span style={{ color: "#49ce8b" }}>
                {formatNumber(totalLong)}%
              </span>
            </div>
            <div className={css["remaining"]}>
              Short{" "}
              <span style={{ color: "#d64242" }}>
                {formatNumber(totalShort)}%
              </span>
            </div>
            <div className={css["remaining"]}>
              Total <span>{formatNumber(totalWeight)}%</span>
            </div>
          </div>
        )}
        <div className={css["buttonContainer"]}>
          <button
            disabled={!canSubmit}
            className={css["button"]}
            onClick={onSubmit}>
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

AssetsPanel.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setAssets: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default AssetsPanel;
