import { Link } from "gatsby";
import { useState } from "react";

import Modal from "../common/Modal";
import * as css from "./HelpModal.module.css";

const HelpButton = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button className={css["button"]} onClick={() => setOpen(true)}>
        Ajuda
      </button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["help"]}>
          <div>Ajuda</div>
          <div>
            <p>
              O Long & Short por cointegração pode ser entendido da seguinte
              forma:
            </p>
            <ul>
              <li>
                Um par pode ser considerado cointegrado caso sua{" "}
                <b>confiança seja superior a 95%</b>.
              </li>
              <li>
                O tempo estimado para que o par cointegrado retorne à média é o{" "}
                <b>tempo de meia-vida</b>.
              </li>
              <li>
                Ao atingir a linha superior, devemos{" "}
                <b>vender o ativo base e comprar o ativo de comparação</b>. Já
                na linha inferior, devemos{" "}
                <b>vender o ativo de comparação e comprar o ativo base</b>. A
                proporção de compra e venda será dado pelo <b>beta</b>.
              </li>
              <li>
                O ativo base também é conhecido como{" "}
                <em>variável dependente</em>, enquanto o ativo de comparação
                pode ser chamado de <em>variável independente</em>.
              </li>
              <ul>
                <li>
                  Por exemplo, se o <em>beta</em> é 0.20 e o <em>Z-Score</em>{" "}
                  está no limite superior, isso significa que para cada 100
                  ações vendidas do ativo base (variável dependente) devemos
                  comprar 20 ações do ativo de comparação (variável
                  independente).
                </li>
                <li>
                  Caso o <em>beta</em> seja 1.10 e o <em>Z-Score</em> esteja no
                  limite inferior, então vendemos 11 ações do ativo de
                  comparação para cada 10 ações compradas do ativo base.
                </li>
              </ul>
            </ul>
            <p>
              No caso de <b>beta negativo</b>, os dois ativos são operados na
              mesma ponta:
            </p>
            <ul>
              <li>
                Compra-se tanto o ativo base quanto o comparação se o{" "}
                <em>Z-Score</em> estiver na banda inferior.
              </li>
              <li>
                Vende-se tanto o ativo base quanto o comparação se o{" "}
                <em>Z-Score</em> estiver na banda superior.
              </li>
              <li>
                Em ambos os casos a proporção de compra ou venda é dada pelo{" "}
                <em>módulo do beta</em>.
              </li>
              <ul>
                <li>
                  Por exemplo, se o <em>beta</em> é -5 e o <em>Z-Score</em> está
                  no limite superior, isso significa que para cada 100 ações
                  vendidas do ativo base (variável dependente) devemos vender
                  500 ações do ativo de comparação (variável independente).
                </li>
                <li>
                  Caso o <em>beta</em> seja -0.5 e o <em>Z-Score</em> esteja no
                  limite inferior, então compramos 50 ações do ativo de
                  comparação para cada 100 ações compradas do ativo base.
                </li>
              </ul>
            </ul>
            <div>
              <p>
                Dúvidas?{" "}
                <Link to="/aprenda-a-estrategia-de-long-and-short-por-cointegracao">
                  Aprenda a Estratégia de Long & Short por Cointegração
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HelpButton;
