import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { PRICES } from "./PlansContainer";
import PremiumBenefits from "./PremiumBenefits";

const PremiumFAQ = () => {
  return (
    <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Quais os benefícios do plano Premium?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div>
            O plano Premium <b>desbloqueia</b> todas as funcionalidades do
            QuantBrasil, dentre elas:
            <PremiumBenefits />
          </div>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Assinando agora terei acesso a futuras funcionalidades?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Sim! Todos os membros Premium do QuantBrasil terão acesso a novas
          funcionalidades quando lançadas.{" "}
          <b>
            Garantimos que os assinantes do plano anual, ao final do período de
            1 ano, terão um pacote de funcionalidades muito maior do que quando
            assinaram.
          </b>
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Já sou membro e não quero assinar, posso continuar usando o
            QuantBrasil?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Sim! O QuantBrasil nasceu como um app gratuito e{" "}
          <em>assim o será para sempre.</em> No entanto, nem todas as
          funcionalidades do site estarão disponíveis no plano Básico.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>A renovação é automática?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Sim, no entanto você pode cancelar sua assinatura a qualquer momento
          em <Link to="/app/settings">sua conta</Link>.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Como faço para cancelar minha assinatura?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Basta ir em <Link to="/app/settings">Minha Conta</Link> e clicar em{" "}
          <em>Gerenciar assinatura</em>.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Ao cancelar minha assinatura deixo de ser Premium imediatamente?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Não. Você continuará sendo Premium até a expiração da sua assinatura
          mesmo que cancele antes.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Como faço para reativar minha assinatura?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Basta ir em <Link to="/app/settings">Minha Conta</Link> e clicar em{" "}
          <em>Reativar assinatura</em>.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Assinei o plano mensal, posso fazer o upgrade para o anual?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Sim! Basta ir em <Link to="/app/settings">Minha Conta</Link> e clicar
          em <em>Gerenciar assinatura</em>. Você pagará apenas o valor
          proporcional.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>Por que um plano Premium?</AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          O QuantBrasil vem crescendo bastante e agradando os seus usuários.
          Para que possamos manter o ritmo de entregas e adicionar cada vez mais
          funcionalidades, é preciso escalar o app, e portanto, gerar caixa que
          custeie os servidores e os desenvolvedores.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Qual o valor do plano Premium do QuantBrasil?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          O plano Premium custa{" "}
          <b>
            R${" "}
            {(PRICES.yearly / 12).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </b>{" "}
          por mês no plano anual ou{" "}
          <b>
            R${" "}
            {PRICES.monthly.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </b>{" "}
          no plano mensal.
        </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            Tenho uma dúvida específica. Como posso entrar em contato?
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          Envie um email para{" "}
          <a href="mailto:contato@quantbrasil.com.br">
            contato@quantbrasil.com.br
          </a>{" "}
          ou utilize o botão lateral de <em>Feedback</em>.
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default PremiumFAQ;
