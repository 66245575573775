import EntryLayout from "../components/layouts/EntryLayout";
import ForgotPassword from "../components/login/ForgotPassword";
import SEO from "../components/seo";

const LoginPage = () => {
  return (
    <EntryLayout>
      <SEO title="Esqueci minha senha" />
      <ForgotPassword />
    </EntryLayout>
  );
};

export default LoginPage;
