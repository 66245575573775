import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import ManageSubscriptionButton from "./ManageSubscriptionButton";
import * as css from "./UserSubscription.module.css";
import UpgradeButton from "../common/UpgradeButton";

const UserSubscription = () => {
  const callAPI = useAPI({ withCredentials: true });

  const {
    user: { isPremium, email },
    setIsPremium,
  } = useAuth();

  const { data, isSuccess } = useQuery(
    ["userSubscriptions", email],
    () => callAPI(`/api/payment/subscription`),
    {
      onSuccess: data => {
        if (data.is_premium !== isPremium) setIsPremium(data.is_premium);
      },
    }
  );

  return (
    <div className={css["container"]}>
      {isPremium ? (
        <ManageSubscriptionButton
          subscription={isSuccess ? data.subscription : null}
        />
      ) : (
        <div className={css["mktContainer"]}>
          <div>
            Seja <b>Premium</b> para aproveitar todas as funcionalidades do
            QuantBrasil, sem restrições.
          </div>
          <UpgradeButton />
        </div>
      )}
    </div>
  );
};

export default UserSubscription;
