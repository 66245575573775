import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { useMedia } from "react-use";
import { useAuth } from "../../hooks/useAuth";
import { groupTrades } from "./CalendarChart";
import CapitalTooltip from "./CapitalTooltip";

const formatHistory = (tradeHistory, initialCapital, startDate) => {
  let capital = [{ x: startDate, y: initialCapital, first: true }];
  const grouped = groupTrades(tradeHistory);
  let currentCapital = initialCapital;
  for (let i = 0; i < grouped.length; i++) {
    const obj = grouped[i];
    currentCapital += obj.profit;
    capital.push({ x: obj.day, y: currentCapital, ...obj });
  }
  return capital;
};

const CapitalChart = ({ tradeHistory, initialCapital, startDate }) => {
  const isMobile = useMedia("(max-width: 700px)");
  const { isLoggedIn } = useAuth();

  const delta = parseFloat(initialCapital) / 50;

  const points = useMemo(
    () => formatHistory(tradeHistory, initialCapital, startDate),
    [initialCapital, startDate, tradeHistory]
  );
  const data = [
    {
      id: "Curva de Capital",
      color: "#033660",
      data: points,
    },
  ];
  const minValue = Math.min(...points.map(({ y }) => y)) - delta;
  const maxValue = Math.max(...points.map(({ y }) => y)) + delta;

  const getPointSize = () =>
    isMobile
      ? 0
      : tradeHistory.length < 60
      ? 10
      : tradeHistory.length < 150
      ? 4
      : 0;

  return (
    <div
      style={{
        height: "500px",
        backgroundColor: "#fff",
        filter: `blur(${isLoggedIn ? 0 : 4}px)`,
      }}>
      <ResponsiveLine
        data={data}
        margin={{
          top: 20,
          right: isMobile ? 10 : 20,
          bottom: 50,
          left: isMobile ? 50 : 70,
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        tooltip={input => <CapitalTooltip data={input.point.data} />}
        yScale={{
          type: "linear",
          min: minValue,
          max: maxValue,
        }}
        axisLeft={{
          tickPadding: 8,
          format: value =>
            isMobile
              ? value
              : value === 0
              ? ""
              : value
                  .toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                  .split(",")[0],
        }}
        axisBottom={{
          format: "%b %y",
          tickRotation: isMobile ? 60 : 0,
        }}
        enableArea={true}
        areaBaselineValue={initialCapital}
        areaOpacity={0.07}
        enableSlices={false}
        colors={{ datum: "color" }}
        pointSize={getPointSize()}
        pointColor={{ theme: "background" }}
        pointBorderWidth={isMobile ? 1 : 2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        useMesh={true}
        motionConfig="gentle"
      />
    </div>
  );
};

CapitalChart.propTypes = {
  tradeHistory: PropTypes.array.isRequired,
  initialCapital: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
};

export default memo(CapitalChart);
