import PropTypes from "prop-types";
import * as css from "./BacktestPopup.module.css";
import Modal from "react-modal";
import CloseIcon from "../../icons/close.svg";
import { useAuth } from "../../hooks/useAuth";
import { formatPaymentLinkUrl } from "../landingPages/LandingPageContainer";
import { useConfig } from "../../hooks/useConfig";
import { STRATEGY_OPTIONS } from "../../utils/constants";
import landingPagesData from "../../data/landingPages.json";
import { trackCheckoutSessionStarted } from "../../utils/amplitude";

const BacktestPopup = ({ isOpen, onClose, sequence }) => {
  const { user } = useAuth();

  const { isProd } = useConfig();

  const firstName = !user
    ? null
    : user?.userName?.includes(" ")
    ? user?.userName?.split(" ")[0]
    : user?.userName;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={{
        base: css["modal"],
        afterOpen: css["modalAfter"],
        beforeClose: css["modalBefore"],
      }}
      overlayClassName={css["overlay"]}
      onRequestClose={onClose}
      contentLabel="Modal">
      <div>
        <button className={css["close"]} onClick={onClose}>
          <CloseIcon aria-hidden />
        </button>
      </div>
      <div className={css["content"]}>
        <div className={css["title"]}>Seja Premium de Graça! 🤑</div>
        <p>
          Que tal se tornar Premium agora <b>sem pagar nada</b> e ter acesso a{" "}
          <b>{STRATEGY_OPTIONS.filter(({ isPremium }) => isPremium).length}</b>{" "}
          novas estratégias{firstName ? `, ${firstName}` : ""}?
        </p>
        <p>
          Você vai desbloquear <span>todos</span> os ativos da plataforma{" "}
          <span>sem restrições</span> de data. Isso inclui criptos, mini-índice,
          mini-dólar, BDRs e ETFs.
        </p>
        <p>
          Teste <b>7 dias grátis</b> agora mesmo.{" "}
          <em>Você pode cancelar a assinatura a qualquer momento.</em>
        </p>
        <div className={css["buttons"]}>
          <a
            href={formatPaymentLinkUrl({
              email: user?.email,
              couponCode: "",
              isProd,
              isYearly: false,
              yearlyPaymentLink: null,
              monthlyPaymentLink: landingPagesData[0].monthlyPaymentLink,
            })}
            className={css["button"]}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              trackCheckoutSessionStarted({
                popup: true,
                sequence,
                ctaText: "Testar 7 Dias Grátis",
              })
            }>
            Testar 7 Dias Grátis
          </a>
          <button className={css["notNow"]} onClick={onClose}>
            Agora não
          </button>
        </div>
      </div>
    </Modal>
  );
};

BacktestPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sequence: PropTypes.number.isRequired,
};

export default BacktestPopup;
