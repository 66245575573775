import * as css from "./StrategyTags.module.css";
import TrendingIcon from "../../icons/trending.svg";
import VolatilityIcon from "../../icons/volatility.svg";
import StarIcon from "../../icons/star.svg";
import PropTypes from "prop-types";

const StrategyTags = ({ tags }) => {
  const isShort = tags.includes("Venda");
  return (
    <div className={css["tags"]}>
      {tags.map(tag => (
        <div
          key={tag}
          className={
            tag === "Compra"
              ? css["buy"]
              : tag === "Venda"
              ? css["sell"]
              : tag === "Premium"
              ? css["premium"]
              : undefined
          }>
          {tag === "Tendência" && (
            <TrendingIcon
              style={{ transform: isShort ? "scaleY(-1)" : undefined }}
            />
          )}
          {tag === "Volatilidade" && <VolatilityIcon />}
          {tag === "Premium" && <StarIcon />}
          {tag}
        </div>
      ))}
    </div>
  );
};

StrategyTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StrategyTags;
