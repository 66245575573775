import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../utils/amplitude";
import ReleasesLayout from "../components/layouts/ReleasesLayout";

const ReleasesPage = ({
  data: {
    allGhostPost: { nodes },
  },
}) => {
  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.RELEASES_VIEWED);
  }, []);

  return <ReleasesLayout data={nodes} />;
};

ReleasesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReleasesPage;

export const query = graphql`
  query AllReleases {
    allGhostPost(
      sort: { published_at: DESC }
      filter: { tags: { elemMatch: { slug: { eq: "hash-release" } } } }
    ) {
      nodes {
        title
        slug
        tags {
          slug
          name
        }
        excerpt
        published_at
        uuid
        html
        feature_image
      }
    }
  }
`;
