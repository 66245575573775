export const SELECT_SMALL = {
  container: provided => ({
    ...provided,
    width: "auto",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "32px",
    height: "32px",
    border: state.isFocused ? "1px solid hsl(0, 0%, 80%)" : undefined,
    boxShadow: state.isFocused ? "0 0 0 2px black" : undefined,
    "&:hover": {
      border: state.isFocused ? "1px solid hsl(0, 0%, 80%)" : undefined,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    height: "32px",
    padding: "0 6px 0 12px",
  }),
  singleValue: provided => ({
    ...provided,
    lineHeight: "32px",
  }),
  input: provided => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "32px",
    "> div": {
      padding: "8px 8px 8px 4px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
  }),
};

export const SELECT_MEDIUM = {
  container: provided => ({
    ...provided,
    width: "auto",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "40px",
    height: "40px",
    border: state.isFocused ? "1px solid hsl(0, 0%, 80%)" : undefined,
    boxShadow: state.isFocused ? "0 0 0 2px black" : undefined,
    "&:hover": {
      border: state.isFocused ? "1px solid hsl(0, 0%, 80%)" : undefined,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    height: "40px",
    padding: "0 6px 0 12px",
  }),
  singleValue: provided => ({
    ...provided,
    lineHeight: "40px",
  }),
  input: provided => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "40px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
  }),
};

export const SELECT_MULTI = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: "#49ce8b",
    maxWidth: "100px",
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: "#fff",
    fontWeight: 500,
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: "#fff",
    ":hover": {
      backgroundColor: "#328a5e",
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "hsl(0, 0%, 100%)" : "rgb(51, 51, 51)",
  }),
};

export const PREMIUM_OPTION = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
    ...(state.isDisabled
      ? {
          display: "flex",
          alignItems: "center",
          ":after": {
            backgroundColor: "#49ce8b",
            color: "#fff",
            borderRadius: 10,
            content: "'Premium'",
            display: "block",
            marginLeft: "auto",
            fontSize: 10,
            padding: 4,
            fontWeight: 500,
          },
        }
      : {}),
    ...(!state.isDisabled && state.data.isNew
      ? {
          display: "flex",
          alignItems: "center",
          ":after": {
            backgroundColor: "#eab838",
            color: "#fff",
            borderRadius: 10,
            content: "'Novo'",
            display: "block",
            marginLeft: "auto",
            fontSize: 10,
            padding: 4,
            fontWeight: 600,
          },
        }
      : {}),
  }),
};

export const WITH_DETAILS = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
    ...(state.data.value === "M5"
      ? {
          display: "flex",
          alignItems: "center",
          ":after": {
            backgroundColor: "#eab838",
            color: "#fff",
            borderRadius: 10,
            content: "'Apenas Futuros'",
            display: "block",
            marginLeft: "auto",
            fontSize: 10,
            padding: 4,
            fontWeight: 600,
          },
        }
      : {}),
  }),
};

export const WITH_NEW_TAG = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
    ...(state.data.isNew
      ? {
          display: "flex",
          alignItems: "center",
          ":after": {
            backgroundColor: "#eab838",
            color: "#fff",
            borderRadius: 10,
            content: "'Novo'",
            display: "block",
            marginLeft: "auto",
            fontSize: 10,
            padding: 4,
            fontWeight: 600,
          },
        }
      : {}),
  }),
};

export const PREMIUM_DISABLED = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
  }),
};

export const MEMBERS_ONLY = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "hsl(0, 0%, 100%)"
      : state.isDisabled
      ? "hsl(0, 0%, 80%)"
      : "rgb(51, 51, 51)",
    ...(state.isDisabled
      ? {
          display: "flex",
          alignItems: "center",
          ":before": {
            backgroundColor: "#49ce8b",
            color: "#fff",
            borderRadius: 10,
            content: "'Membros'",
            display: "block",
            marginRight: 8,
            fontSize: 10,
            padding: 4,
            fontWeight: 500,
          },
        }
      : {}),
  }),
};
