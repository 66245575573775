import { useState } from "react";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import HelpButton from "../backtests/HelpModal";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import BacktestsList from "./BacktestsList";
import * as css from "./TopBacktestsContainer.module.css";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";
import SelectedSetupFilter from "./SelectedSetupFilter";
import Select from "react-select";
import UserBatchesFilter from "./UserBatchesFilter";
import { useAuth } from "../../hooks/useAuth";
import { PREMIUM_OPTION } from "../../utils/select";
import { Link } from "gatsby";
import BatchBanner from "../backtests/BatchBanner";
import { StringParam, useQueryParams } from "use-query-params";

const TYPE_OPTIONS = [
  { value: "public", label: "Públicos" },
  { value: "user", label: "Meus Rankings", isPremium: true },
];

export const STRATEGY_OPTIONS = [
  {
    selectedSetup: "CLASSIC_IFR2",
    timeframe: "D1",
    group: "IFR",
    value: "CLASSIC_IFR2-D1",
    label: "IFR2 (Diário)",
    description:
      "Entrada com IFR2 abaixo de 30, alvo na máxima dos últimos 2 dias, stop em 7 dias.",
  },
  {
    selectedSetup: "SNIPER_IFR2",
    label: "IFR2 Sniper (Diário)",
    timeframe: "D1",
    group: "IFR",
    value: "SNIPER_IFR2-D1",
    description:
      "Entrada com IFR2 abaixo de 5, alvo na máxima dos últimos 2 dias, stop em 7 dias.",
    isPremium: true,
  },
  {
    selectedSetup: "HAWK_RSI",
    timeframe: "H2",
    group: "IFR",
    value: "HAWK_RSI-H2",
    label: "IFR Águia (120 Minutos)",
    description:
      "Entrada quando a média exponencial de 9 períodos do IFR14 cruza acima do indicador, saída quando IFR atingir 65, stop de 3% ou em 8 candles.",
    isPremium: true,
  },
  {
    selectedSetup: "HAWK_RSI",
    timeframe: "H1",
    group: "IFR",
    value: "HAWK_RSI-H1",
    label: "IFR Águia (60 Minutos)",
    description:
      "Entrada quando a média exponencial de 9 períodos do IFR14 cruza acima do indicador, saída quando IFR atingir 65, stop de 3% ou em 8 candles.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_STOCHASTIC",
    timeframe: "D1",
    group: "Estocástico",
    value: "CLASSIC_LONG_STOCHASTIC-D1",
    label: "Estocástico Lento de Compra",
    description:
      "Entrada com estocástico lento de 8 períodos abaixo de 20, saída quando for maior que 80, sem stop.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_STOCHASTIC",
    timeframe: "D1",
    group: "Estocástico",
    value: "CLASSIC_SHORT_STOCHASTIC-D1",
    label: "Estocástico Lento de Venda",
    description:
      "Entrada com estocástico lento de 8 períodos acima de 80, saída quando for menor que 20, sem stop.",
    isPremium: true,
  },
  {
    selectedSetup: "TARGET_WINDOW_LONG_STOCHASTIC",
    timeframe: "D1",
    group: "Estocástico",
    value: "TARGET_WINDOW_LONG_STOCHASTIC-D1",
    label: "Estocástico Lento de Compra (Alvo Janela)",
    description:
      "Entrada com estocástico lento de 8 períodos abaixo de 25, saída na máxima dos últimos 3 dias, stop em 7 dias.",
    isPremium: true,
  },
  {
    selectedSetup: "TARGET_WINDOW_SHORT_STOCHASTIC",
    timeframe: "D1",
    group: "Estocástico",
    value: "TARGET_WINDOW_SHORT_STOCHASTIC-D1",
    label: "Estocástico Lento de Venda (Alvo Janela)",
    description:
      "Entrada com estocástico lento de 8 períodos acima de 75, saída na mínima dos últimos 3 dias, stop em 7 dias.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_TURNAROUND",
    timeframe: "D1",
    group: "Turnaround",
    value: "CLASSIC_LONG_TURNAROUND-D1",
    label: "Turnaround",
    description:
      "Entrada no fechamento após 3 dias de queda onde o 3º dia é uma queda de pelo menos 0.5%, alvo no fechamento de 2 dias depois, stop de 2% do capital.",
  },
  {
    selectedSetup: "CLASSIC_LONG_MAXMIN",
    timeframe: "D1",
    group: "Máximas e Mínimas",
    value: "CLASSIC_LONG_MAXMIN-D1",
    label: "Máximas e Mínimas de Compra (Diário)",
    description:
      "Entrada na mínima dos últimos 2 dias, alvo na máxima dos últimos 2 dias, stop em 7 dias.",
  },
  {
    selectedSetup: "CLASSIC_LONG_MAXMIN",
    timeframe: "H2",
    group: "Máximas e Mínimas",
    value: "CLASSIC_LONG_MAXMIN-H2",
    label: "Máximas e Mínimas de Compra (120 Minutos)",
    description:
      "Entrada na mínima das últimas 4 horas, alvo na máxima das últimas 4 horas, stop em 7 candles de 120 minutos.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_MAXMIN",
    timeframe: "H1",
    group: "Máximas e Mínimas",
    value: "CLASSIC_LONG_MAXMIN-H1",
    label: "Máximas e Mínimas de Compra (60 Minutos)",
    description:
      "Entrada na mínima dos últimos 120 minutos, alvo na máxima dos últimos 120 minutos, stop em 7 candles de 60 minutos.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_MAXMIN",
    timeframe: "M30",
    group: "Máximas e Mínimas",
    value: "CLASSIC_LONG_MAXMIN-M30",
    label: "Máximas e Mínimas de Compra (30 Minutos)",
    description:
      "Entrada na mínima dos últimos 60 minutos, alvo na máxima dos últimos 60 minutos, stop em 7 candles de 30 minutos.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_MAXMIN",
    timeframe: "M15",
    group: "Máximas e Mínimas",
    value: "CLASSIC_LONG_MAXMIN-M15",
    label: "Máximas e Mínimas de Compra (15 Minutos)",
    description:
      "Entrada na mínima dos últimos 30 minutos, alvo na máxima dos últimos 30 minutos, stop em 7 candles de 15 minutos.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_MAXMIN",
    timeframe: "D1",
    group: "Máximas e Mínimas",
    value: "CLASSIC_SHORT_MAXMIN-D1",
    label: "Máximas e Mínimas de Venda (Diário)",
    description:
      "Entrada na máxima dos últimos 2 dias, alvo na mínima dos últimos 2 dias, stop em 7 dias.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_MAXMIN",
    timeframe: "H2",
    group: "Máximas e Mínimas",
    value: "CLASSIC_SHORT_MAXMIN-H2",
    label: "Máximas e Mínimas de Venda (120 Minutos)",
    description:
      "Entrada na máxima das últimas 4 horas, alvo na mínima das últimas 4 horas, stop em 7 candles de 120 minutos.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_123",
    timeframe: "D1",
    group: "Setup 123",
    value: "CLASSIC_LONG_123-D1",
    label: "123 de Compra (Diário)",
    description:
      "Padrão com 3 candles no diário, onde o 2º tem a menor mínima. Entrada na ruptura da máxima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick abaixo da mínima do 2º candle, risco de 2% do capital inicial.",
  },
  {
    selectedSetup: "CLASSIC_LONG_123",
    timeframe: "H2",
    group: "Setup 123",
    label: "123 de Compra (120 Minutos)",
    value: "CLASSIC_LONG_123-H2",
    description:
      "Padrão com 3 candles no 120 Minutos, onde o 2º tem a menor mínima. Entrada na ruptura da máxima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick abaixo da mínima do 2º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_123",
    timeframe: "D1",
    group: "Setup 123",
    value: "CLASSIC_SHORT_123-D1",
    label: "123 de Venda (Diário)",
    isPremium: true,
    description:
      "Padrão com 3 candles no diário, onde o 2º tem a maior máxima. Entrada na perda da mínima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick acima da máxima do 2º candle, risco de 2% do capital inicial.",
  },
  {
    selectedSetup: "CLASSIC_LONG_INSIDEBAR",
    timeframe: "D1",
    group: "Inside Bar",
    value: "CLASSIC_LONG_INSIDEBAR-D1",
    label: "Inside Bar de Compra (Diário)",
    description:
      "Padrão com 2 candles no diário, onde o 2º está contido no 1º. Entrada na ruptura da máxima do 2º candle, alvo igual à amplitude dos 2 candles a partir do candle sinal, stop 1 tick abaixo da mínima do 1º candle, risco de 2% do capital inicial.",
  },
  {
    selectedSetup: "CLASSIC_LONG_INSIDEBAR",
    timeframe: "H2",
    group: "Inside Bar",
    label: "Inside Bar de Compra (120 Minutos)",
    value: "CLASSIC_LONG_INSIDEBAR-H2",
    description:
      "Padrão com 2 candles no 120 Minutos, onde o 2º está contido no 1º. Entrada na ruptura da máxima do 2º candle, alvo igual à amplitude dos 2 candles a partir do candle sinal, stop 1 tick abaixo da mínima do 1º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_INSIDEBAR",
    timeframe: "D1",
    group: "Inside Bar",
    value: "CLASSIC_SHORT_INSIDEBAR-D1",
    label: "Inside Bar de Venda (Diário)",
    isPremium: true,
    description:
      "Padrão com 2 candles no diário, onde o 2º está contido no 1º. Entrada na perda da mínima do 2º candle, alvo igual à amplitude dos 2 candles a partir do candle sinal, stop 1 tick acima da máxima do 1º candle, risco de 2% do capital inicial.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_INSIDEBAR",
    timeframe: "H2",
    group: "Inside Bar",
    label: "Inside Bar de Venda (120 Minutos)",
    value: "CLASSIC_SHORT_INSIDEBAR-H2",
    description:
      "Padrão com 2 candles no 120 Minutos, onde o 2º está contido no 1º. Entrada na perda da mínima do 2º candle, alvo igual à amplitude dos 2 candles a partir do candle sinal, stop 1 tick acima da máxima do 1º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_SHARK",
    timeframe: "H2",
    group: "Shark",
    label: "Shark de Compra (120 Minutos)",
    value: "CLASSIC_LONG_SHARK-H2",
    description:
      "Padrão com 3 candles no 120 Minutos, onde o 2º e o 3º são um inside bar. Entrada na ruptura da máxima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick abaixo da mínima do 2º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_SHARK",
    timeframe: "H2",
    group: "Shark",
    label: "Shark de Venda (120 Minutos)",
    value: "CLASSIC_SHORT_SHARK-H2",
    description:
      "Padrão com 3 candles no 120 Minutos, onde o 2º e o 3º são um inside bar. Entrada na perda da mínima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick acima da máxima do 2º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_123",
    timeframe: "H2",
    group: "Setup 123",
    label: "123 de Venda (120 Minutos)",
    value: "CLASSIC_SHORT_123-H2",
    description:
      "Padrão com 3 candles no 120 Minutos, onde o 2º tem a maior máxima. Entrada na perda da mínima do 3º candle, alvo igual à amplitude dos 3 candles a partir do candle sinal, stop 1 tick acima da máxima do 2º candle, risco de 2% do capital inicial.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_TRAP",
    timeframe: "D1",
    group: "Trap",
    value: "CLASSIC_LONG_TRAP-D1",
    label: "Trap de Compra (Diário)",
    description:
      "Padrão onde um ativo trabalha com pelo menos 2 candles sem tocar a MME8 até que um candle tem a mínima menor que a média. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 2x o risco.",
  },
  {
    selectedSetup: "CLASSIC_LONG_TRAP",
    timeframe: "H2",
    group: "Trap",
    value: "CLASSIC_LONG_TRAP-H2",
    label: "Trap de Compra (120 Minutos)",
    description:
      "Padrão onde um ativo trabalha com pelo menos 2 candles sem tocar a MME8 até que um candle tem a mínima menor que a média. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 2x o risco.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_PFR",
    timeframe: "D1",
    group: "Preço de Fechamento de Reversão",
    value: "CLASSIC_LONG_PFR-D1",
    label: "PFR de Compra (Diário)",
    description:
      "Padrão onde um candle tem a menor mínima que os dois candles anteriores porém fecha acima do candle anterior. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
  },
  {
    selectedSetup: "CLASSIC_LONG_PFR",
    timeframe: "H2",
    group: "Preço de Fechamento de Reversão",
    value: "CLASSIC_LONG_PFR-H2",
    label: "PFR de Compra (120 Minutos)",
    description:
      "Padrão onde um candle tem a menor mínima que os dois candles anteriores porém fecha acima do candle anterior. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_PFR",
    timeframe: "D1",
    group: "Preço de Fechamento de Reversão",
    value: "CLASSIC_SHORT_PFR-D1",
    label: "PFR de Venda (Diário)",
    isPremium: true,
    description:
      "Padrão onde um candle tem a maior máxima que os dois candles anteriores porém fecha abaixo do candle anterior. Entrada na perda da mínima do candle sinal, stop 1 tick acima da máxima, alvo 1.6x o risco.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_PFR",
    timeframe: "H2",
    group: "Preço de Fechamento de Reversão",
    value: "CLASSIC_SHORT_PFR-H2",
    label: "PFR de Venda (120 Minutos)",
    description:
      "Padrão onde um candle tem a menor mínima que os dois candles anteriores porém fecha acima do candle anterior. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_BREAKOUT",
    timeframe: "D1",
    group: "Breakout",
    value: "CLASSIC_LONG_BREAKOUT-D1",
    label: "Breakout de Compra (Diário)",
    isPremium: true,
    description:
      "Entrada na ruptura da máxima do candle anterior, stop na retração de 100% de Fibonacci (mínima do candle sinal), alvo de 5% a partir da entrada.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_BREAKOUT",
    timeframe: "D1",
    group: "Breakout",
    value: "CLASSIC_SHORT_BREAKOUT-D1",
    label: "Breakout de Venda (Diário)",
    isPremium: true,
    description:
      "Entrada na perda da mínima do candle anterior, stop na retração de 100% de Fibonacci (máxima do candle sinal), alvo de 5% a partir da entrada.",
  },
  {
    selectedSetup: "CLASSIC_LONG_ATYPICAL_VARIATION",
    timeframe: "D1",
    group: "Variação Atípica",
    value: "CLASSIC_LONG_ATYPICAL_VARIATION-D1",
    label: "Variação Atípica de Compra (Diário)",
    isPremium: true,
    description:
      "Entrada quando o z-score da variação for acima de 1.5, alvo 5 candles depois, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_LONG_ATYPICAL_VARIATION",
    timeframe: "H2",
    group: "Variação Atípica",
    value: "CLASSIC_LONG_ATYPICAL_VARIATION-H2",
    label: "Variação Atípica de Compra (120 Minutos)",
    isPremium: true,
    description:
      "Entrada quando o z-score da variação for acima de 1.5, alvo 5 candles depois, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_ATYPICAL_VARIATION",
    timeframe: "D1",
    group: "Variação Atípica",
    value: "CLASSIC_SHORT_ATYPICAL_VARIATION-D1",
    label: "Variação Atípica de Venda (Diário)",
    isPremium: true,
    description:
      "Entrada quando o z-score da variação for abaixo de -1.5, alvo 5 candles depois, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_ATYPICAL_VARIATION",
    timeframe: "H2",
    group: "Variação Atípica",
    value: "CLASSIC_SHORT_ATYPICAL_VARIATION-H2",
    label: "Variação Atípica de Venda (120 Minutos)",
    isPremium: true,
    description:
      "Entrada quando o z-score da variação for abaixo de -1.5, alvo 5 candles depois, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_LONG_INTRADAY_VARIATION",
    timeframe: "M15",
    group: "Variação Intradiária",
    value: "CLASSIC_LONG_INTRADAY_VARIATION-M15",
    label: "Variação Intradiária de Compra",
    isPremium: true,
    description:
      "Entrada quando o ativo estiver caindo 1.5%, alvo em 0.5%, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_INTRADAY_VARIATION",
    timeframe: "M15",
    group: "Variação Intradiária",
    value: "CLASSIC_SHORT_INTRADAY_VARIATION-M15",
    label: "Variação Intradiária de Venda",
    isPremium: true,
    description:
      "Entrada quando o ativo estiver subindo 1.5%, alvo em 0.5%, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_LONG_HILO_ACTIVATOR",
    timeframe: "D1",
    group: "HiLo Activator",
    value: "CLASSIC_LONG_HILO_ACTIVATOR-D1",
    label: "HiLo Activator de Compra (Diário)",
    isPremium: true,
    description:
      "Entrada quando o fechamento do candle estiver acima da média móvel de 8 períodos das máximas, saída quando o fechamento estiver abaixo da média móvel de 8 períodos das mínimas.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_HILO_ACTIVATOR",
    timeframe: "D1",
    group: "HiLo Activator",
    value: "CLASSIC_SHORT_HILO_ACTIVATOR-D1",
    label: "HiLo Activator de Venda (Diário)",
    isPremium: true,
    description:
      "Entrada quando o fechamento do candle estiver abaixo da média móvel de 8 períodos das mínimas, saída quando o fechamento estiver acima da média móvel de 8 períodos das máximas.",
  },
  {
    selectedSetup: "CLASSIC_LONG_THREE_BAR",
    timeframe: "D1",
    group: "Três Médias",
    value: "CLASSIC_LONG_THREE_BAR-D1",
    label: "Três Médias de Compra (Diário)",
    isPremium: true,
    description:
      "Entrada quando o fechamento do candle estiver abaixo da média móvel de 3 períodos das mínimas, saída quando o fechamento estiver acima da média móvel de 3 períodos das máximas, somente quando a média móvel de 30 períodos do fechamento for ascendente.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_THREE_BAR",
    timeframe: "D1",
    group: "Três Médias",
    value: "CLASSIC_SHORT_THREE_BAR-D1",
    label: "Três Médias de Venda (Diário)",
    isPremium: true,
    description:
      "Entrada quando o fechamento do candle estiver acima da média móvel de 3 períodos das máximas, saída quando o fechamento estiver abaixo da média móvel de 3 períodos das mínimas, somente quando a média móvel de 30 períodos do fechamento for descendente.",
  },
  {
    selectedSetup: "CLASSIC_LONG_CANDLE_TRAP",
    timeframe: "D1",
    group: "Trap no Candle",
    value: "CLASSIC_LONG_CANDLE_TRAP-D1",
    label: "Trap de Compra no Candle (Diário)",
    description:
      "Padrão onde um candle perde a mínima do anterior no Éden dos Traders de compra. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
  },
  {
    selectedSetup: "CLASSIC_LONG_CANDLE_TRAP",
    timeframe: "H2",
    group: "Trap no Candle",
    value: "CLASSIC_LONG_CANDLE_TRAP-H2",
    label: "Trap de Compra no Candle (120 Minutos)",
    description:
      "Padrão onde um candle perde a mínima do anterior no Éden dos Traders de compra. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_CANDLE_TRAP",
    timeframe: "D1",
    group: "Trap no Candle",
    value: "CLASSIC_SHORT_CANDLE_TRAP-D1",
    label: "Trap de Venda no Candle (Diário)",
    isPremium: true,
    description:
      "Padrão onde um candle rompe a máxima do anterior no Éden dos Traders de venda. Entrada na perda da mínima do candle sinal, stop 1 tick acima da máxima, alvo 1.6x o risco.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_CANDLE_TRAP",
    timeframe: "H2",
    group: "Trap no Candle",
    value: "CLASSIC_SHORT_CANDLE_TRAP-H2",
    label: "Trap de Venda no Candle (120 Minutos)",
    description:
      "Padrão onde um candle rompe a máxima do anterior no Éden dos Traders de venda. Entrada na ruptura da máxima do candle sinal, stop 1 tick abaixo da mínima, alvo 1.6x o risco.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_REVERSAL_BB",
    timeframe: "D1",
    group: "Reversão de Bandas",
    value: "CLASSIC_LONG_REVERSAL_BB-D1",
    label: "Reversão de Bandas de Compra (Diário)",
    description:
      "Padrão onde um candle fecha abaixo da banda inferior. Alvo 2 candles depois, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_LONG_REVERSAL_BB",
    timeframe: "H2",
    group: "Reversão de Bandas",
    value: "CLASSIC_LONG_REVERSAL_BB-H2",
    label: "Reversão de Bandas de Compra (120 Minutos)",
    description:
      "Padrão onde um candle fecha abaixo da banda inferior. Alvo 2 candles depois, sem stop.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_REVERSAL_BB",
    timeframe: "D1",
    group: "Reversão de Bandas",
    value: "CLASSIC_SHORT_REVERSAL_BB-D1",
    label: "Reversão de Bandas de Venda (Diário)",
    description:
      "Padrão onde um candle fecha acima da banda superior. Alvo 2 candles depois, sem stop.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_REVERSAL_BB",
    timeframe: "H2",
    group: "Reversão de Bandas",
    value: "CLASSIC_SHORT_REVERSAL_BB-H2",
    label: "Reversão de Bandas de Venda (120 Minutos)",
    description:
      "Padrão onde um candle fecha acima da banda superior. Alvo 2 candles depois, sem stop.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_OPEN_BB",
    timeframe: "D1",
    group: "Abertura de Bandas",
    value: "CLASSIC_LONG_OPEN_BB-D1",
    label: "Abertura de Bandas de Compra (Diário)",
    description:
      "Padrão onde um candle fecha acima da banda superior. Alvo 3 candles depois. Stop 3,5%.",
  },
  {
    selectedSetup: "CLASSIC_LONG_OPEN_BB",
    timeframe: "H2",
    group: "Abertura de Bandas",
    value: "CLASSIC_LONG_OPEN_BB-H2",
    label: "Abertura de Bandas de Compra (120 Minutos)",
    description:
      "Padrão onde um candle fecha acima da banda superior. Alvo 3 candles depois. Stop 3,5%.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_SHORT_OPEN_BB",
    timeframe: "D1",
    group: "Abertura de Bandas",
    value: "CLASSIC_SHORT_OPEN_BB-D1",
    label: "Abertura de Bandas de Venda (Diário)",
    isPremium: true,
    description:
      "Padrão onde um candle fecha abaixo da banda inferior. Alvo 3 candles depois. Stop 3,5%.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_OPEN_BB",
    timeframe: "H2",
    group: "Abertura de Bandas",
    value: "CLASSIC_SHORT_OPEN_BB-H2",
    label: "Abertura de Bandas de Venda (120 Minutos)",
    description:
      "Padrão onde um candle fecha abaixo da banda inferior. Alvo 3 candles depois. Stop 3,5%.",
    isPremium: true,
  },
  {
    selectedSetup: "CLASSIC_LONG_WICK_CANDLE",
    timeframe: "D1",
    group: "Candle Pavio",
    value: "CLASSIC_LONG_WICK_CANDLE-D1",
    label: "Candle Pavio de Compra",
    isPremium: true,
    description:
      "Compra na abertura após um candle pavio se for um gap de alta de pelo menos 0.5%, alvo no fechamento, stop de 2% do capital.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_WICK_CANDLE",
    timeframe: "D1",
    group: "Candle Pavio",
    value: "CLASSIC_SHORT_WICK_CANDLE-D1",
    label: "Candle Pavio de Venda",
    isPremium: true,
    description:
      "Venda na abertura após um candle pavio se for um gap de baixa de pelo menos 0.5%, alvo no fechamento, stop de 2% do capital.",
  },
  {
    selectedSetup: "NEW_LONG_GAPTRAP",
    timeframe: "M15",
    group: "Gap Trap",
    value: "NEW_LONG_GAPTRAP-M15",
    label: "Gap Trap de Compra",
    isPremium: true,
    description:
      "Entrada na superação da máxima dos primeiros 15 minutos caso abra abaixo da mínima do dia anterior e feche positivo, alvo 1.6x do risco, stop na retração de 111,8% do candle sinal ou no fechamento do dia.",
  },
  {
    selectedSetup: "NEW_SHORT_GAPTRAP",
    label: "Gap Trap de Venda",
    group: "Gap Trap",
    value: "NEW_SHORT_GAPTRAP-M15",
    isPremium: true,
    timeframe: "M15",
    description:
      "Entrada na perda da mínima dos primeiros 15 minutos caso abra acima da máxima do dia anterior e feche negativo, alvo 1.6x do risco, stop na retração de 111,8% do candle sinal ou no fechamento do dia.",
  },
  {
    selectedSetup: "CLASSIC_LONG_SDC",
    label: "Saudade de Casa de Compra",
    value: "CLASSIC_LONG_SDC-M30",
    timeframe: "M30",
    isPremium: true,
    group: "Saudade de Casa",
    description:
      "Entrada na superação da máxima dos primeiros 30 minutos caso abra abaixo das Bandas de Bollinger, alvo na banda do meio, sem stop.",
  },
  {
    selectedSetup: "CLASSIC_SHORT_SDC",
    label: "Saudade de Casa de Venda",
    group: "Saudade de Casa",
    value: "CLASSIC_SHORT_SDC-M30",
    timeframe: "M30",
    isPremium: true,
    description:
      "Entrada na perda da mínima dos primeiros 30 minutos caso abra acima das Bandas de Bollinger, alvo na banda do meio, sem stop.",
  },
];

const TopBacktestsContainer = () => {
  const { user } = useAuth();
  const isPremium = user?.isPremium;

  const [{ filter: initialFilter }] = useQueryParams({ filter: StringParam });

  const [setup, setSetup] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(
    initialFilter
      ? TYPE_OPTIONS.find(option => option.value === initialFilter) ||
          TYPE_OPTIONS[0]
      : TYPE_OPTIONS[0]
  );
  const [selectedBatchId, setSelectedBatchId] = useState(null);
  const callAPI = useAPI({ withCredentials: true });

  const {
    isLoading: isLoadingSelectedSetup,
    isSuccess: isSuccessSelectedSetup,
    data: selectedSetupData,
    refetch: fetchSelectedSetup,
  } = useQuery(
    ["topBacktests", setup],
    () =>
      callAPI(
        `/api/batch/?selected_setup_id=${setup.selectedSetup}&timeframe_id=${setup.timeframe}&last=True`
      ),
    {
      retry: (count, error) => {
        if (error.message === "Usuário não autorizado.") return false;
      },
      onError: error => showError(error),
      enabled: false,
    }
  );

  const {
    data: batchData,
    isLoading: isLoadingBatch,
    refetch: fetchBatch,
    isSuccess: isSuccessBatch,
  } = useQuery(
    ["batch", selectedBatchId],
    () => callAPI(`/api/batch/${selectedBatchId}`),
    {
      onError: error => showError(error),
      enabled: false,
    }
  );

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear todos os rankings"
      seoProps={{
        title: "Melhores Backtests",
      }}>
      <div>
        <h1 className={css["header"]}>
          Rankings de Backtests <HelpButton />
        </h1>
        <p>
          Acompanhe os ativos que melhor performam em determinadas estratégias.
          Os rankings públicos são atualizados semanalmente. Crie seus próprios
          rankings no{" "}
          <b>
            <Link to="/backtests">Simulador de Estratégias</Link>
          </b>
          .
        </p>
        <div className={css["filter"]}>
          <div>
            <label htmlFor="ranking-type">Tipo</label>
            <Select
              id="ranking-type"
              options={TYPE_OPTIONS.map(option => ({
                ...option,
                isDisabled: !isPremium && option.isPremium,
              }))}
              value={selectedFilter}
              onChange={option => {
                setSelectedFilter(option);
                setSetup(null);
                setSelectedBatchId(null);
              }}
              styles={{
                container: provided => ({
                  ...provided,
                  width: "215px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                ...PREMIUM_OPTION,
              }}
              isSearchable={false}
            />
          </div>
          {selectedFilter.value === "public" && (
            <SelectedSetupFilter setup={setup} setSetup={setSetup} />
          )}
          {selectedFilter.value === "user" && (
            <UserBatchesFilter
              selectedId={selectedBatchId}
              setSelectedId={setSelectedBatchId}
            />
          )}
          <div className={css["buttonWrapper"]}>
            <button
              title="Buscar"
              disabled={isLoadingSelectedSetup || isLoadingBatch}
              className={css["button"]}
              onClick={() => {
                if (selectedFilter.value === "public") fetchSelectedSetup();
                if (selectedFilter.value === "user") fetchBatch();
                logAmplitudeEvent(EVENT_MAP.TOP_BACKTESTS_VIEWED, {
                  selectedFilter: selectedFilter.value,
                  setup,
                  selectedBatchId,
                });
                incrementProperty("backtestsRankingViewed");
              }}>
              Buscar
            </button>
          </div>
        </div>
        {(isLoadingSelectedSetup || isLoadingBatch) && (
          <Spinner type="Rings" text="Carregando..." />
        )}
        {isSuccessSelectedSetup && selectedFilter.value === "public" && (
          <BacktestsList
            backtests={selectedSetupData?.all_backtests}
            info={selectedSetupData?.batch_info}
            description={setup.description}
          />
        )}
        {isSuccessBatch && selectedFilter.value === "user" && (
          <BacktestsList
            backtests={batchData?.all_backtests}
            info={batchData?.batch_info}
          />
        )}
        <div className={css["displayContainer"]}>
          <BatchBanner displayOnly />
        </div>
      </div>
    </AppLayout>
  );
};

export default TopBacktestsContainer;
