// extracted by mini-css-extract-plugin
export var button = "PublicPopup-module--button--6cf3c";
export var buttonOutline = "PublicPopup-module--buttonOutline--12105";
export var buttons = "PublicPopup-module--buttons--ba23a";
export var close = "PublicPopup-module--close--e81bc";
export var container = "PublicPopup-module--container--6bdf1";
export var content = "PublicPopup-module--content--0c0cf";
export var countdownContainer = "PublicPopup-module--countdownContainer--6ed51";
export var modal = "PublicPopup-module--modal--e812c";
export var modalAfter = "PublicPopup-module--modalAfter--0f7f6";
export var modalBefore = "PublicPopup-module--modalBefore--70204";
export var notNow = "PublicPopup-module--notNow--e142e";
export var overlay = "PublicPopup-module--overlay--dfffd";
export var part = "PublicPopup-module--part--42a14";
export var title = "PublicPopup-module--title--97632";