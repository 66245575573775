import "typeface-montserrat";
import "react-toastify/dist/ReactToastify.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-datepicker/dist/react-datepicker.css";
import "./src/components/settings/toggle.css";
import "react-tooltip/dist/react-tooltip.css";
import pt from "date-fns/locale/pt";
import "./src/components/layout.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { initAmplitude } from "./src/utils/amplitude";
import RootElement from "./wrapRootElement";

registerLocale("pt", pt);
setDefaultLocale(pt);

// Init Amplitude if configured
initAmplitude();

export const wrapRootElement = RootElement;
