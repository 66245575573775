import PropTypes from "prop-types";
import Toggle from "react-toggle";
import * as css from "./ChartControls.module.css";
import { BENCHMARKS } from "./ReturnsResult";

const ChartControls = ({ state, setState }) => {
  const onChange = (key, value) => {
    const copy = state.concat([]);
    const i = copy.findIndex(({ ticker }) => ticker === key);
    copy[i] = {
      ticker: key,
      enabled: value,
      isBenchmark: BENCHMARKS.includes(key),
    };
    setState(copy);
  };

  return (
    <>
      <div className={css["chartContainer"]}>
        <div>Controles</div>
        <div className={css["controls"]}>
          {state
            .filter(({ isBenchmark }) => !isBenchmark)
            .map(({ ticker, enabled }) => (
              <label key={ticker}>
                <Toggle
                  defaultChecked={enabled}
                  onChange={e => onChange(ticker, e.target.checked)}
                />
                <span>{ticker}</span>
              </label>
            ))}
        </div>
      </div>
      <div className={css["chartContainer"]}>
        <div>Benchmarks</div>
        <div className={css["controls"]}>
          {state
            .filter(({ isBenchmark }) => isBenchmark)
            .map(({ ticker, enabled }) => (
              <label key={ticker}>
                <Toggle
                  defaultChecked={enabled}
                  onChange={e => onChange(ticker, e.target.checked)}
                />
                <span>{ticker}</span>
              </label>
            ))}
        </div>
      </div>
    </>
  );
};

ChartControls.propTypes = {
  state: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
};

export default ChartControls;
