// extracted by mini-css-extract-plugin
export var allVideos = "youtube-module--allVideos--6a003";
export var banner = "youtube-module--banner--e4673";
export var iframeContainer = "youtube-module--iframeContainer--27f41";
export var main = "youtube-module--main--cb3cb";
export var top = "youtube-module--top--36f60";
export var videoContainer = "youtube-module--videoContainer--ad8dd";
export var videos = "youtube-module--videos--6698c";
export var ytDesc = "youtube-module--ytDesc--1164c";
export var ytPublished = "youtube-module--ytPublished--cec38";
export var ytTitle = "youtube-module--ytTitle--1222a";