import { ResponsivePie } from "@nivo/pie";
import PropTypes from "prop-types";
import { memo } from "react";
import PieTooltip from "./PieTooltip";
import { useMedia } from "react-use";

const formatData = (data, portfolioBeta) =>
  Object.keys(data).map(el => ({
    label: el,
    value: data[el].weighted_beta,
    props: data[el],
    total: portfolioBeta,
    id: el,
  }));

const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
  const total = dataWithArc.length > 0 ? dataWithArc[0].data.total : 0;
  const isMobile = useMedia("(max-width: 700px)");

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: isMobile ? "28px" : "52px",
        fontWeight: 600,
      }}>
      {total.toFixed(2)}
    </text>
  );
};

CenteredMetric.propTypes = {
  dataWithArc: PropTypes.array,
  centerX: PropTypes.number,
  centerY: PropTypes.number,
};

const BetaChart = ({ data, portfolioBeta }) => {
  const isMobile = useMedia("(max-width: 700px)");

  return (
    <div>
      <div
        style={{
          height: isMobile ? "250px" : "400px",
          backgroundColor: "#fff",
        }}>
        <ResponsivePie
          margin={{
            top: 30,
            right: isMobile ? 110 : 0,
            bottom: 30,
            left: isMobile ? 110 : 0,
          }}
          colors={{ scheme: "paired" }}
          animate={true}
          activeOuterRadiusOffset={8}
          tooltip={input => <PieTooltip data={input} total={portfolioBeta} />}
          innerRadius={isMobile ? 0.7 : 0.6}
          padAngle={0.5}
          cornerRadius={5}
          enableArcLabels={false}
          arcLinkLabelsStraightLength={isMobile ? 1 : 12}
          arcLinkLabel={d => `${d.id} (${d.formattedValue})`}
          layers={[
            "arcs",
            "arcLabels",
            "arcLinkLabels",
            "legends",
            CenteredMetric,
          ]}
          data={formatData(data, portfolioBeta)}
          theme={{
            labels: {
              text: {
                fontSize: "14px",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

BetaChart.propTypes = {
  data: PropTypes.object.isRequired,
  portfolioBeta: PropTypes.number.isRequired,
};

export default memo(BetaChart);
