import { useAuth } from "../../hooks/useAuth";
import { toPercent } from "../../utils/utils";
import * as css from "./VaRResult.module.css";
import VaRHistogram from "./VaRHistogram";
import UpgradeSection from "../common/UpgradeSection";
import PropTypes from "prop-types";

const VaRResult = ({ valueAtRisk, data, confidence }) => {
  const { isLoggedIn, user } = useAuth();

  const showUpgrade = isLoggedIn && !user.isPremium;

  return (
    <div>
      <div className={css["varContainer"]}>
        Value-at-Risk: <span>{toPercent(valueAtRisk)}</span>
      </div>
      <p className={css["hint"]}>
        Em <span>{confidence}%</span> dos casos o portfólio não cairá mais que{" "}
        <span>{toPercent(valueAtRisk)}</span> em um dia.
      </p>
      <VaRHistogram data={data} />
      {showUpgrade && (
        <UpgradeSection copy="Faça o upgrade de sua conta para calcular o VaR em portfólios com ações, criptos, ETFs, e BDRs sem restrição de período e confiança!" />
      )}
    </div>
  );
};

VaRResult.propTypes = {
  valueAtRisk: PropTypes.number.isRequired,
  confidence: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
};

export default VaRResult;
