import PropTypes from "prop-types";
import Select from "react-select";
import { PREMIUM_OPTION, SELECT_SMALL } from "../../utils/select";
import Input from "../common/Input";
import * as css from "./SupplyDemandScreeningFilter.module.css";
import InfoIcon from "../../icons/info-question-circle.svg";
import GroupLabel from "../common/GroupLabel";
import { Tooltip } from "react-tooltip";
import { getProjectionPeriods } from "./SupplyDemandFilter";
import { usePortfolios } from "../../hooks/usePortfolios";
import { isEmpty } from "lodash";
import DEFAULT_PORTFOLIOS from "../../data/portfolios.json";

const PROJECTION_PERIODS_OPTIONS = getProjectionPeriods();

const SupplyDemandScreeningFilter = ({
  onClick,
  isLoading,
  portfolio,
  setPortfolio,
  frequency,
  setFrequency,
  isPremium,
  minLevel,
  setMinLevel,
  maxLevel,
  setMaxLevel,
}) => {
  const isValid =
    !isEmpty(portfolio) &&
    minLevel >= -3 &&
    minLevel <= 3 &&
    maxLevel >= -3 &&
    maxLevel <= 3 &&
    minLevel < maxLevel;

  const canSubmit = !isLoading && isValid;

  const PROTECTED_PROJECTION_PERIODS_OPTIONS = PROJECTION_PERIODS_OPTIONS.map(
    option => {
      if (!isPremium && option.value !== "yearly")
        return { ...option, isDisabled: true };
      return option;
    }
  );

  const PROTECTED_DEFAULT_PORTFOLIOS = DEFAULT_PORTFOLIOS.map(option => {
    if (!isPremium && option.type !== "B3")
      return { ...option, isDisabled: true };
    return option;
  });

  const { asOptions: portfolioOptions } = usePortfolios();

  const PROTECTED_USER_PORTFOLIOS = portfolioOptions.map(option => {
    if (!isPremium) return { ...option, isDisabled: true };
    return option;
  });

  return (
    <form
      className={css["filter"]}
      onSubmit={e => {
        e.preventDefault();
        onClick();
      }}>
      <div>
        <label>Ativos</label>
        <Select
          id="base-asset"
          options={[
            {
              label: "Gerais",
              options: PROTECTED_DEFAULT_PORTFOLIOS,
            },
            {
              label: "Seus Portfólios",
              options: PROTECTED_USER_PORTFOLIOS,
            },
          ]}
          value={portfolio}
          onChange={option => setPortfolio(option)}
          styles={{
            ...SELECT_SMALL,
            ...PREMIUM_OPTION,
          }}
          formatGroupLabel={data => <GroupLabel {...data} />}
          placeholder="Selecionar portfólio..."
        />
      </div>
      <div>
        <label htmlFor="sd-proj-period">Período de Projeção</label>
        <Select
          id="sd-proj-period"
          options={PROTECTED_PROJECTION_PERIODS_OPTIONS}
          value={frequency}
          onChange={option => setFrequency(option)}
          styles={{
            ...SELECT_SMALL,
            ...PREMIUM_OPTION,
          }}
          isSearchable={false}
          placeholder="Selecionar período"
        />
      </div>
      <div className={css["zones"]}>
        <div>
          <label htmlFor="window-filter">
            Nível Superior
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-content="O nível de preço mais alto desejado para o cálculo da distância."
              data-tooltip-id="window-tip"
            />
            <Tooltip id="window-tip" delayHide={100} clickable />
          </label>
          <Input
            id="maxLevel-filter"
            value={maxLevel}
            onValueChange={({ floatValue }) =>
              setMaxLevel(floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative
            decimalScale={0}
            placeholder="Ex: 2"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 3 || floatValue >= -3
            }
            disabled={!isPremium}
            title={isPremium ? undefined : "Disponível apenas para Premium"}
          />
        </div>
        <div>
          <label htmlFor="window-filter">
            Nível Inferior
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-content="O nível de preço mais baixo desejado para o cálculo da distância."
              data-tooltip-id="window-tip"
            />
            <Tooltip id="window-tip" delayHide={100} clickable />
          </label>
          <Input
            id="minLevel-filter"
            value={minLevel}
            onValueChange={({ floatValue }) =>
              setMinLevel(floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative
            decimalScale={0}
            placeholder="Ex: -2"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 3 || floatValue >= -3
            }
            disabled={!isPremium}
            title={isPremium ? undefined : "Disponível apenas para Premium"}
          />
        </div>
      </div>
      <div>
        <button disabled={!canSubmit} className={css["button"]} type="submit">
          Buscar
        </button>
      </div>
    </form>
  );
};

SupplyDemandScreeningFilter.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  frequency: PropTypes.object,
  setFrequency: PropTypes.func.isRequired,
  minLevel: PropTypes.number,
  setMinLevel: PropTypes.func.isRequired,
  maxLevel: PropTypes.number,
  setMaxLevel: PropTypes.func.isRequired,
  portfolio: PropTypes.object,
  setPortfolio: PropTypes.func.isRequired,
};

export default SupplyDemandScreeningFilter;
