import * as css from "./AddPhoneBanner.module.css";
import { WhatsappIcon } from "react-share";
import { Link } from "gatsby";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const AddPhoneBanner = () => {
  return (
    <div className={css["container"]}>
      <div>
        <WhatsappIcon size={48} round={true} />
      </div>
      <div>
        <div className={css["text"]}>
          <div>Monitorar estratégias</div>
          <div>
            Seja avisado no <span>WhatsApp</span> toda vez que seu sinal
            favorito for identificado.
          </div>
        </div>
        <Link
          className={css["button"]}
          to="/app/settings"
          onClick={() =>
            logAmplitudeEvent(EVENT_MAP.ADD_PHONE_BUTTON_CLICKED, {
              ctaText: "Salvar WhatsApp",
            })
          }>
          Salvar WhatsApp
        </Link>
      </div>
    </div>
  );
};

export default AddPhoneBanner;
