import { graphql, useStaticQuery } from "gatsby";
import { uniq } from "lodash";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { getRankedNodes } from "../hooks/useSortedMagicFormula";
import { SELECT_MULTI } from "../utils/select";
import * as css from "./magic-formula.module.css";
import { trackScreeningViewed } from "../utils/amplitude";
import { DateTime } from "luxon";

const TOP_ASSETS = 30;

const SORT_OPTIONS = [
  { value: "position asc", label: "Melhor Posição" },
  { value: "ev_ebit_ranking asc", label: "Menor EV/EBIT" },
  { value: "roic_ranking asc", label: "Maior ROIC" },
];

const MagicFormula = () => {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [segmentsToExclude, setSegmentsToExclude] = useState([]);
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allMagicFormula: { nodes },
  } = useStaticQuery(graphql`
    query {
      allMagicFormula {
        nodes {
          ticker
          roic
          roic_ranking
          ev_ebit
          ev_ebit_ranking
          position
          segment
          buildTime
          last_updated
        }
      }
    }
  `);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn } = useAuth();

  const filtered = orderBy(
    getRankedNodes(nodes, segmentsToExclude)
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    item => item[fieldToSort],
    directionToSort
  );

  const lastUpdated = nodes[0]?.buildTime.replace("UTC", "Z");

  useEffect(() => {
    trackScreeningViewed({ key: "magicFormula" });
  }, []);

  const SEGMENTS = uniq(nodes.map(({ segment }) => segment))
    .sort()
    .map(segment => ({
      label: segment,
      value: segment,
    }));

  return (
    <PublicLayout
      seoProps={{
        title: "Magic Formula",
        description:
          "Screening da Magic Formula de Joel Greenblatt adaptada para os ativos brasileiros.",
      }}
      title="Magic Formula"
      ctaMessage="Crie sua conta para aplicar a Magic Formula no seu portfólio.">
      <div>
        <p>
          A lista abaixo utiliza uma técnica de <b>Factor Investing</b> chamada{" "}
          <em>Magic Formula</em> e introduzida por Joel Greenblatt . A partir
          dela, geramos um screening dos ativos da bolsa brasileira baseados em
          2 fatores: <b>EV/EBIT</b> e <b>ROIC</b>.
        </p>
        <p>
          O ranking final é a soma de suas posições em cada fator. Além disso,
          filtramos ativos dos segmentos: <em>Bancos</em>,{" "}
          <em>Energia Elétrica</em> e <em>Água e Saneamento</em>, para evitar
          distorções.{" "}
          <em>
            OBS: É comum excluir Seguradoras da lista. Remova mais segmentos no
            filtro abaixo.
          </em>
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="mf-asset">Ativo</label>
            <input
              id="mf-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div style={{ width: "100%" }}>
            <label htmlFor="assets-select">Excluir Segmentos</label>
            <Select
              id="assets-select"
              options={SEGMENTS}
              value={segmentsToExclude}
              onChange={option => setSegmentsToExclude(option)}
              styles={SELECT_MULTI}
              isMulti
              placeholder="Selecione"
            />
          </div>
          <div>
            <label htmlFor="mf-order">Ordenar por</label>
            <Select
              id="mf-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_MULTI,
                container: provided => ({
                  ...provided,
                  width: "265px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th
                  data-tooltip-id="mf-tip"
                  data-tooltip-content={`Ranking final considerando EV/EBIT e ROIC.`}>
                  Posição<sup>1</sup>
                </th>
                <th>Ativo</th>
                <th
                  data-tooltip-id="mf-tip"
                  data-tooltip-content={`Enterprise Value dividido por Earnings Before Interest and Taxes.`}>
                  EV/EBIT<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="mf-tip"
                  data-tooltip-content={`Return Over Invested Capital.`}>
                  ROIC<sup>3</sup>
                </th>
                <th>Segmento</th>
                <th
                  data-tooltip-id="mf-tip"
                  data-tooltip-content={`Soma da posição do ativo em cada um dos fatores.`}>
                  Pontos<sup>4</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => {
                const nodeUpdated =
                  "Atualizado " +
                  DateTime.fromISO(node.last_updated).toRelative({
                    locale: "pt-br",
                  });
                return (
                  <tr key={node.ticker}>
                    <td
                      style={{
                        color:
                          node.overallRanking <= TOP_ASSETS
                            ? "#49ce8b"
                            : undefined,
                        fontWeight:
                          node.overallRanking <= TOP_ASSETS ? 600 : undefined,
                      }}>
                      {node.overallRanking}
                    </td>
                    <td>{node.ticker}</td>
                    <td>
                      <span
                        className={css["rankValue"]}
                        data-tooltip-id="mf-tip"
                        data-tooltip-content={nodeUpdated}>
                        {node.ev_ebit.toFixed(2)}
                      </span>
                      <span className={css["rankPosition"]}>
                        {node.ev_ebit_ranking}º
                      </span>
                    </td>
                    <td>
                      <span
                        className={css["rankValue"]}
                        data-tooltip-id="mf-tip"
                        data-tooltip-content={nodeUpdated}>
                        {(node.roic * 100).toFixed(2)}%
                      </span>{" "}
                      <span className={css["rankPosition"]}>
                        {node.roic_ranking}º
                      </span>
                    </td>
                    <td>{node.segment}</td>
                    <td>{node.position}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Ranking final considerando EV/EBIT e ROIC.
          </div>
          <div>
            <sup>2</sup> Enterprise Value dividido por Earnings Before Interest
            and Taxes.
          </div>
          <div>
            <sup>3</sup> Return Over Invested Capital.
          </div>
          <div>
            <sup>4</sup> Soma da posição do ativo em cada um dos fatores.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="mf-tip" />
      </div>
    </PublicLayout>
  );
};

export default MagicFormula;
