// extracted by mini-css-extract-plugin
export var arrowDown = "hurst-module--arrowDown--d29cb";
export var arrowUp = "hurst-module--arrowUp--85237";
export var exponentContainer = "hurst-module--exponentContainer--8bbbf";
export var filter = "hurst-module--filter--956a3";
export var input = "hurst-module--input--888fe";
export var legend = "hurst-module--legend--b3ed7";
export var lightGreen = "hurst-module--lightGreen--1c67d";
export var lightRed = "hurst-module--lightRed--3dab0";
export var mediumGreen = "hurst-module--mediumGreen--48d27";
export var mediumRed = "hurst-module--mediumRed--f6530";
export var pause = "hurst-module--pause--f3938";
export var register = "hurst-module--register--cdcb6";
export var state = "hurst-module--state--6aef0";
export var strategyLink = "hurst-module--strategyLink--478e7";
export var strongGreen = "hurst-module--strongGreen--44260";
export var strongRed = "hurst-module--strong-red--fb6ed";
export var tableContainer = "hurst-module--tableContainer--4dec0";