import { Link } from "gatsby";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./AlertsMonitorContainer.module.css";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import UserAlerts from "./UserAlerts";
import AddPhoneBanner from "./AddPhoneBanner";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

export const FREE_ALERTS = 3;
export const PREMIUM_ALERTS = 50;

const AlertsMonitorContainer = () => {
  const { user } = useAuth();

  const hasPhone = !!user?.phone;

  const callAPI = useAPI({ withCredentials: true });

  const { isLoading, isSuccess, data, refetch } = useQuery(
    ["userAlerts"],
    () => callAPI(`/api/alert/user`),
    { onError: showError, enabled: hasPhone }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ALERT_MONITOR_VIEWED);
  }, []);

  return (
    <AppLayout
      premiumMessage={`Seja Premium para monitorar até ${PREMIUM_ALERTS} sinais simultâneos`}
      seoProps={{
        title: "Monitor de Alertas",
      }}>
      <div className={css["content"]}>
        <h1>Monitor de Alertas</h1>
        <p>
          Monitore estratégias encontradas no{" "}
          <b>
            <Link to="/backtests">Simulador de Backtests</Link>
          </b>
          . Notificações serão enviadas para o seu WhatsApp cadastrado em
          horários específicos toda vez que o sinal for identificado.
        </p>
        <p className={css["disclaimer"]}>
          Disclaimer: não é recomendação de compra ou venda.
        </p>
        {!hasPhone && <AddPhoneBanner />}
        {isLoading && <Spinner type="Rings" text="Buscando..." />}
        {isSuccess && data.length === 0 && (
          <div className={css["none"]}>
            Nenhuma estratégia sendo monitorada.{" "}
            <Link to="/backtests">Simular estratégias.</Link>
          </div>
        )}
        {isSuccess && data.length > 0 && (
          <UserAlerts alerts={data} refetch={refetch} />
        )}
      </div>
    </AppLayout>
  );
};

export default AlertsMonitorContainer;
