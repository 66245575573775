import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./drawdown.module.css";

const SORT_OPTIONS = [
  { value: "current_drawdown asc", label: "Menor Drawdown" },
  { value: "current_drawdown desc", label: "Maior Drawdown" },
  { value: "max_drawdown asc", label: "Menor Drawdown Máximo" },
  { value: "max_drawdown desc", label: "Maior Drawdown Máximo" },
];

const Drawdown = () => {
  const [minDrawdown, setMinDrawdown] = useState(0);
  const [maxDrawdown, setMaxDrawdown] = useState(100);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allDrawdown: { nodes },
  } = useStaticQuery(graphql`
    query DrawdownQuery {
      allDrawdown {
        nodes {
          max_drawdown
          current_drawdown
          ticker
          current_close
          last_bottom_close
          last_bottom_day(formatString: "DD/MM")
          last_top_close
          last_top_day(formatString: "DD/MM")
          buildTime
        }
      }
    }
  `);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const { isLoggedIn } = useAuth();

  const filtered = orderBy(
    nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(
        ({ current_drawdown }) =>
          current_drawdown * 100 <= maxDrawdown &&
          current_drawdown * 100 >= minDrawdown
      )
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    item => item[fieldToSort],
    directionToSort
  );

  const lastUpdated = nodes[0]?.buildTime.replace("UTC", "Z");

  return (
    <PublicLayout
      seoProps={{
        title: "Drawdown",
        description:
          "Lista com o drawdown atual e máximo dos ativos da bolsa brasileira.",
      }}
      title="Drawdown dos Ativos da Bolsa"
      ctaMessage="Crie sua conta para aproveitar o máximo do QuantBrasil.">
      <div>
        <p>
          A lista abaixo contém os valores do drawdown atual e do drawdown
          máximo dos ativos mais líquidos da bolsa brasileira.{" "}
          <b>
            Os valores são calculados em relação ao ano de{" "}
            {new Date().getFullYear()}
          </b>
          . Ativos em{" "}
          <span style={{ fontWeight: 600, color: "#49ce8b" }}>verde</span> estão
          na máxima do ano.{" "}
          <Link to="/entenda-o-drawdown-e-calcule-essa-medida-de-volatilidade-para-qualquer-ativo">
            Entenda o Drawdown e Calcule essa Medida de Volatilidade para
            Qualquer Ativo
          </Link>
          .
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-drawdown">{`Min Drawdown (%)`}</label>
            <input
              id="min-drawdown"
              type="number"
              min={0}
              value={minDrawdown}
              onChange={e => setMinDrawdown(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-drawdown">{`Max Drawdown (%)`}</label>
            <input
              id="max-drawdown"
              type="number"
              min={0}
              value={maxDrawdown}
              onChange={e => setMaxDrawdown(e.target.value)}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="drawdown-asset">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="drawdown-order">Ordenar por</label>
            <Select
              id="drawdown-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "265px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="dd-tip"
                  data-tooltip-content={`Drawdown atual`}>
                  Drawdown<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="dd-tip"
                  data-tooltip-content={`Maior drawdown no ano`}>
                  Máximo<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="dd-tip"
                  data-tooltip-content={`Data do maior fechamento do ano`}>
                  Topo<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="dd-tip"
                  data-tooltip-content={`Data do menor fechamento desde o último topo`}>
                  Fundo<sup>4</sup>
                </th>
                <th>Preço Topo</th>
                <th>Preço Fundo</th>
                <th>Preço Atual</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr
                  key={node.ticker}
                  style={{
                    boxShadow:
                      node.ticker === "IBOV"
                        ? "0 0 0 2px #033660 inset"
                        : undefined,
                    fontWeight:
                      node.ticker === "IBOV" || node.current_drawdown === 0
                        ? "600"
                        : undefined,
                    color: node.current_drawdown === 0 ? "#49ce8b" : undefined,
                  }}>
                  <td>{node.ticker}</td>
                  <td>{(node.current_drawdown * 100).toFixed(2)}%</td>
                  <td>{(node.max_drawdown * 100).toFixed(2)}%</td>
                  <td>{node.last_top_day}</td>
                  <td>
                    {node.last_bottom_day === node.last_top_day
                      ? "-"
                      : node.last_bottom_day}
                  </td>
                  <td>{node.last_top_close.toFixed(2)}</td>
                  <td>
                    {node.last_bottom_day === node.last_top_day
                      ? "-"
                      : node.last_bottom_close.toFixed(2)}
                  </td>
                  <td>{node.current_close.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Drawdown atual.
          </div>
          <div>
            <sup>2</sup> Maior drawdown no ano.
          </div>
          <div>
            <sup>3</sup> Data do maior fechamento do ano.
          </div>
          <div>
            <sup>4</sup> Data do menor fechamento desde o último topo.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="dd-tip" />
      </div>
    </PublicLayout>
  );
};

export default Drawdown;
