import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { cloneElement,forwardRef } from "react";
import NumberFormat from "react-number-format";

import * as css from "./Input.module.css";

const Input = forwardRef(
  (
    {
      size = "medium",
      fluid = false,
      disabled = false,
      isNumber = false,
      icon,
      hint,
      error,
      className,
      iconClassName,
      ...rest
    },
    ref
  ) => {
    const hasError = !isEmpty(error);

    const Component = isNumber ? NumberFormat : "input";

    const inputClasses = [css["input"], css[size]];
    if (fluid) inputClasses.push(css["fluid"]);
    if (hasError) inputClasses.push(css["error"]);
    if (className) inputClasses.push(className);

    const iconClasses = [css["icon"], css[size]];
    if (iconClassName) iconClasses.push(iconClassName);
    return (
      <div className={css["container"]}>
        {icon && cloneElement(icon, { className: iconClasses.join(" ") })}
        <Component
          ref={ref}
          className={inputClasses.join(" ")}
          disabled={disabled}
          {...rest}
        />
        {hint && !hasError && <div className={css["hint"]}>{hint}</div>}
        {error && <div className={css["errorMessage"]}>{error}</div>}
      </div>
    );
  }
);

Input.propTypes = {
  size: PropTypes.oneOf(["medium", "small", "large"]),
  fluid: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  isNumber: PropTypes.bool,
};

Input.displayName = "Input";

export default Input;
