import { STRATEGY_OPTIONS } from "../../utils/constants";
import { PREMIUM_ALERTS } from "../alerts/AlertsMonitorContainer";
import * as css from "./FreeBenefits.module.css";

const PremiumBenefits = () => {
  return (
    <ul className={css["benefits"]}>
      <li>
        Backtests de <b>{STRATEGY_OPTIONS.length}</b> estratégias em{" "}
        <b>
          todos ativos do Ibovespa, em criptos, ETFs, BDRs, no mini-índice e
          mini-dólar.
        </b>
      </li>
      <li>
        Backtests em timeframes diários, intradiários e semanal{" "}
        <b>sem limite de data</b>.
      </li>
      <li>
        Backtests simultâneos em até <b>100 ativos</b> (crie seus próprios
        rankings!).
      </li>
      <li>
        Cálculo de cointegração em{" "}
        <b>todos os ativos do Ibovespa e em qualquer janela</b>, além de acesso
        a novos pares encontrados <b>automaticamente</b>.
      </li>
      <li>
        Todas as notificações do Feed Quantitativo, incluindo <b>sugestões</b>{" "}
        de backtests e Long & Short, além de operações realizadas por robôs em{" "}
        <b>tempo-real</b>.
      </li>
      <li>
        Ranking com os ativos que melhor performaram em{" "}
        <b>estratégias exclusivas</b> <em>atualizados toda semana</em>.
      </li>
      <li>
        Alertas de sinais de <em>diversos setups</em>, além de{" "}
        <b>setups exclusivos</b>, tanto para <em>day trade</em> quanto para{" "}
        <em>swing trade</em>.
      </li>
      <li>
        Até <b>{PREMIUM_ALERTS}</b> alertas simultâneos de estratégias no{" "}
        <b>WhatsApp</b>.
      </li>
      <li>
        Acesso às zonas de Suportes e Resistências na peridiocidade{" "}
        <b>anual, mensal e semanal</b>.
      </li>
      <li>
        Ferramenta de cálculo do VaR e Beta de um portfólio{" "}
        <b>
          em até 5 anos, incluindo hedge da carteira com índice, dólar, criptos,
          ETFs e BDRs
        </b>
        .
      </li>
      <li>
        Ferramenta de <em>desempenho histórico</em> de um portfólio{" "}
        <b>sem restrição de data</b> e incluindo <b>fundos de investimento</b>.
      </li>
      <li>
        <b>Novas funcionalidades exclusivas</b> <em>todos os meses</em>!
      </li>
    </ul>
  );
};

export default PremiumBenefits;
