import * as Sentry from "@sentry/gatsby";

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,

  tracesSampleRate: 0.5,

  environment: "frontend",
  enabled: process.env.GATSBY_SENTRY_ENABLED === "true",

  // Source: https://github.com/getsentry/sentry-javascript/issues/6295#issuecomment-1352958925
  ignoreErrors: [
    "https://reactjs.org/docs/error-decoder.html?invariant=422", // There was an error while hydrating this Suspense boundary. Switched to client rendering.
    "https://reactjs.org/docs/error-decoder.html?invariant=423", // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
    "https://reactjs.org/docs/error-decoder.html?invariant=425", // Text content does not match server-rendered HTML...
  ],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
    }),
  ],
});
