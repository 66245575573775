import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import CloseIcon from "../icons/close.svg";
import { SELECT_SMALL } from "../utils/select";
import { getRSIStyle } from "../utils/utils";
import * as css from "./estocastico-lento.module.css";
import { trackScreeningViewed } from "../utils/amplitude";

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "W1", label: "Semanal" },
];

const SORT_OPTIONS = [
  { value: "k asc", label: "Menor %K" },
  { value: "k desc", label: "Maior %K" },
];

const DIRECTION = [
  { value: "Qualquer", label: "Qualquer" },
  { value: "Cima", label: "Cima" },
  { value: "Baixo", label: "Baixo" },
];

const POSITION = [
  { value: "Qualquer", label: "Qualquer" },
  { value: "Cruzou Acima", label: "Cruzou Acima" },
  { value: "Cruzou Abaixo", label: "Cruzou Abaixo" },
  { value: "Acima", label: "Acima" },
  { value: "Abaixo", label: "Abaixo" },
];

const Stochastic = () => {
  const { isLoggedIn } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["W1"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [minStochastic, setMinStochastic] = useState(0);
  const [maxStochastic, setMaxStochastic] = useState(100);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [timeframe, setTimeframe] = useState(PROTECTED_TIMEFRAME_OPTIONS[0]);
  const [averageDirection, setAverageDirection] = useState(DIRECTION[0]);
  const [stochasticDirection, setStochasticDirection] = useState(DIRECTION[0]);
  const [position, setPosition] = useState(POSITION[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const resetFilter = () => {
    setMinStochastic(0);
    setMaxStochastic(100);
    setAverageDirection(DIRECTION[0]);
    setStochasticDirection(DIRECTION[0]);
    setPosition(POSITION[0]);
    setTicker("");
  };

  const {
    allTicker: { nodes },
  } = useStaticQuery(graphql`
    query StochasticQuery {
      allTicker {
        nodes {
          ticker
          stochastic {
            D1 {
              k
              d
              k_is_up
              k_crossed_above
              k_crossed_below
            }
            W1 {
              k
              d
              k_is_up
              k_crossed_above
              k_crossed_below
            }
          }
          price
          mme80_is_up
          buildTime
        }
      }
    }
  `);

  const buildTime = nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(
        ({
          stochastic: {
            [timeframe.value]: { k },
          },
        }) => k <= maxStochastic && k >= minStochastic
      )
      .filter(({ mme80_is_up }) => {
        if (averageDirection.value === "Cima") return mme80_is_up;
        if (averageDirection.value === "Baixo") return !mme80_is_up;
        return true;
      })
      .filter(
        ({
          stochastic: {
            [timeframe.value]: { k_is_up },
          },
        }) => {
          if (stochasticDirection.value === "Cima") return k_is_up;
          if (stochasticDirection.value === "Baixo") return !k_is_up;
          return true;
        }
      )
      .filter(
        ({
          stochastic: {
            [timeframe.value]: { k_crossed_above, k_crossed_below, k, d },
          },
        }) => {
          if (position.value === "Cruzou Acima") return k_crossed_above;
          if (position.value === "Cruzou Abaixo") return k_crossed_below;
          if (position.value === "Acima") return k > d;
          if (position.value === "Abaixo") return k < d;
          return true;
        }
      )
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    `stochastic.${timeframe.value}.${fieldToSort}`,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "stochastic" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Estocástico Lento",
        description:
          "Screening do Estocástico Lento para os ativos do Ibovespa de forma rápida e objetiva.",
      }}
      title="Estocástico Lento"
      ctaMessage="Crie sua conta para desbloquear o screening no timeframe Semanal.">
      <div>
        <p>
          A lista abaixo contém os valores do Estocástico Lento (%K) de 8 dias
          para os ativos mais líquidos da B3. Os valores não são em tempo real e
          podem diferir de sua plataforma de trading.{" "}
          <Link to="/como-calcular-o-estocastico-lento-utilizando-python">
            Aprenda a calcular o Estocástico Lento utilizando Python.
          </Link>
        </p>
        <LastUpdated date={buildTime} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="stochastic-tf">Timeframe</label>
            <Select
              id="stochastic-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="k-order">Ordenar por</label>
            <Select
              id="k-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "145px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-k">{`Min %K`}</label>
            <input
              id="min-k"
              type="number"
              min={0}
              max={100}
              value={minStochastic}
              onChange={e => setMinStochastic(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-k">{`Max %K`}</label>
            <input
              id="max-k"
              type="number"
              min={0}
              max={100}
              value={maxStochastic}
              onChange={e => setMaxStochastic(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="k-mme80">Direção MME80</label>
            <Select
              id="k-mme80"
              options={DIRECTION}
              value={averageDirection}
              onChange={option => setAverageDirection(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "145px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="k-direction">Direção %K</label>
            <Select
              id="k-direction"
              options={DIRECTION}
              value={stochasticDirection}
              onChange={option => setStochasticDirection(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "145px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="k-position">Posição %K</label>
            <Select
              id="k-position"
              options={POSITION}
              value={position}
              onChange={option => setPosition(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "170px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="stochastic-ticker">Ativo</label>
            <input
              id="stochastic-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["resetContainer"]}>
            <button
              onClick={resetFilter}
              data-tooltip-id="el-tip"
              data-tooltip-content={`Limpar Filtro`}>
              <CloseIcon />
            </button>
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="el-tip"
                  data-tooltip-content="%K é o valor do Estocástico Lento de 8 dias.">
                  %K<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="el-tip"
                  data-tooltip-content="Preços tem um delay de 15 minutos a partir da última atualização.">
                  Preço<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="el-tip"
                  data-tooltip-content="Direção da média móvel exponencial de 80 dias.">
                  Direção MME80<sup>3</sup>
                </th>
                <th>Direção %K</th>
                <th
                  data-tooltip-id="el-tip"
                  data-tooltip-content="Posição de %K em relação à sua média móvel de 3 dias (%D).">
                  Posição %K<sup>4</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td style={getRSIStyle(node.stochastic[timeframe.value].k)}>
                    {node.ticker}
                  </td>
                  <td style={getRSIStyle(node.stochastic[timeframe.value].k)}>
                    {node.stochastic[timeframe.value].k}
                  </td>
                  <td>{node.price.toFixed(2)}</td>
                  <td>{node.mme80_is_up ? "Cima" : "Baixo"}</td>
                  <td>
                    {node.stochastic[timeframe.value].k_is_up
                      ? "Cima"
                      : "Baixo"}
                  </td>
                  <td>
                    {node.stochastic[timeframe.value].k_crossed_above
                      ? "Cruzou Acima"
                      : node.stochastic[timeframe.value].k_crossed_below
                      ? "Cruzou Abaixo"
                      : node.stochastic[timeframe.value].k >
                        node.stochastic[timeframe.value].d
                      ? "Acima"
                      : "Abaixo"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> %K é o valor do Estocástico Lento de 8 dias.
          </div>
          <div>
            <sup>2</sup> Preços tem um delay de 15 minutos a partir da última
            atualização.
          </div>
          <div>
            <sup>3</sup> Direção da média móvel exponencial de 80 dias.
          </div>
          <div>
            <sup>4</sup> Posição de %K em relação à sua média móvel de 3 dias
            (%D).
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="el-tip" />
      </div>
    </PublicLayout>
  );
};

export default Stochastic;
