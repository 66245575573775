import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import Spinner from "../components/common/Spinner";
import YouTubePlayer from "../components/common/YouTubePlayer";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./eden-dos-traders.module.css";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const PORTFOLIO_ID = process.env.GATSBY_TOP_200_PORTFOLIO_ID;

const SORT_OPTIONS = [
  { value: "dist8 desc", label: "Maior Distância MME8" },
  { value: "dist8 asc", label: "Menor Distância MME8" },
  { value: "dist80 desc", label: "Maior Distância MME80" },
  { value: "dist80 asc", label: "Menor Distância MME80" },
];

const TYPE_OPTIONS = [
  { value: "long", label: "Compra" },
  { value: "short", label: "Venda" },
];

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "H1", label: "60 Minutos" },
  { value: "H2", label: "120 Minutos" },
  { value: "W1", label: "Semanal" },
];

const Eden = () => {
  const { isLoggedIn } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["W1", "H1", "H2"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [type, setType] = useState(TYPE_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [timeframe, setTimeframe] = useState(
    PROTECTED_TIMEFRAME_OPTIONS.find(({ value }) => value === "D1")
  );

  const staticData = useStaticQuery(graphql`
    query {
      long: allEden(filter: { long: { eq: 1 } }) {
        nodes {
          mme8
          mme80
          long
          price
          short
          ticker
          buildTime
        }
      }
      short: allEden(filter: { short: { eq: 1 } }) {
        nodes {
          mme8
          mme80
          long
          price
          short
          ticker
          buildTime
        }
      }
    }
  `);

  const [data, setData] = useState(staticData);

  const callAPI = useAPI({ withCredentials: true });

  const { data: edenData, isLoading } = useQuery(
    ["edenTraders", timeframe],
    ({ queryKey }) => {
      const timeframeId = queryKey[1].value;
      if (timeframeId === "D1") {
        setData(staticData);
        return;
      }
      return callAPI(
        `/api/portfolio/${PORTFOLIO_ID}/eden?timeframe=${timeframeId}`
      );
    }
  );

  useEffect(() => {
    if (edenData) {
      const newData = { long: { nodes: [] }, short: { nodes: [] } };
      Object.keys(edenData).map(symbol => {
        const d = edenData[symbol];
        if (d.long)
          newData.long.nodes.push({
            ...d,
            ticker: symbol,
            buildTime: d.last_updated.replace("UTC", "Z"),
          });
        else if (d.short) newData.short.nodes.push({ ...d, ticker: symbol });
      });
      setData(newData);
    }
  }, [edenData]);

  const longLastUpdated = data.long.nodes[0]?.buildTime;
  const shortLastUpdated = data.short.nodes[0]?.buildTime;

  const lastUpdated = longLastUpdated || shortLastUpdated;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data[type.value].nodes
      .map(node => ({
        ...node,
        dist8: Math.abs((node.price - node.mme8) / node.mme8),
        dist80: Math.abs((node.price - node.mme80) / node.mme80),
      }))
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "tradersEden" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Éden dos Traders",
        description:
          "Acompanhe os ativos mais direcionais da bolsa brasileira.",
      }}
      title="Éden dos Traders"
      ctaMessage="Crie sua conta para desbloquear o Éden dos Traders em diferentes timeframes.">
      <div>
        <div className={css["youtubeContainer"]}>
          <p>
            O “Éden dos Traders” foi definido pelo trader{" "}
            <a href="https://www.youtube.com/channel/UCntXeFBe7WME5MEw4LE-tCg">
              Stormer
            </a>{" "}
            como uma região onde os ativos estão <b>direcionais</b>, ou seja,
            com suas médias curtas (MME8) e longas (MME80) apontando na mesma
            direção.{" "}
            <Link to="/criando-um-screening-dos-ativos-no-eden-dos-traders-utilizando-python">
              Criando um Screening dos Ativos no “Éden dos Traders” Utilizando
              Python
            </Link>
            .
          </p>
          <YouTubePlayer
            videoId="AcNnYZAoPEI"
            width={500}
            height={315}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <p>
          A lista abaixo contém os ativos mais líquidos da bolsa brasileira,
          categorizados em <em>Éden dos Traders de Compra</em> (médias apontando
          para cima) e <em>Éden dos Traders de Venda</em> (médias apontadas para
          baixo).{" "}
          <em>
            Nota: os valores podem diferir da sua plataforma de trading,
            especialmente a MME80.
          </em>
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="stochastic-tf">Timeframe</label>
            <Select
              id="stochastic-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => {
                if (option.value !== timeframe.value) {
                  logAmplitudeEvent(EVENT_MAP.TRADERS_EDEN_SELECTED, {
                    timeframe: option.value,
                  });
                }
                setTimeframe(option);
              }}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="eden-type">Éden dos Traders de</label>
            <Select
              id="eden-type"
              options={TYPE_OPTIONS}
              value={type}
              onChange={option => setType(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="beta-period">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="beta-order">Ordenar por</label>
            <Select
              id="eden-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "250px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        {isLoading && isLoggedIn ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Criando o éden..." />
          </div>
        ) : (
          <>
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th
                      data-tooltip-id="eden-tip"
                      data-tooltip-content={`Preço aferido na última atualização.`}>
                      Preço Atual<sup>1</sup>
                    </th>
                    <th>MME8</th>
                    <th
                      data-tooltip-id="eden-tip"
                      data-tooltip-content={`Médias mais longas irão diferir mais de sua plataforma de trading em virtude da diferença de valor inicial.`}>
                      MME80<sup>2</sup>
                    </th>
                    <th
                      data-tooltip-id="eden-tip"
                      data-tooltip-content={`Distância em valores absolutos.`}>
                      Dist. MME8 (%)<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="eden-tip"
                      data-tooltip-content={`Distância em valores absolutos.`}>
                      Dist. MME80 (%)<sup>4</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>{node.ticker}</td>
                      <td>{node.price.toFixed(2)}</td>
                      <td>{node.mme8.toFixed(2)}</td>
                      <td>{node.mme80.toFixed(2)}</td>
                      <td>{(node.dist8 * 100).toFixed(2)}%</td>
                      <td>{(node.dist80 * 100).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço aferido na última atualização.
              </div>
              <div>
                <sup>2</sup> Médias mais longas irão diferir mais de sua
                plataforma de trading em virtude da diferença de valor inicial.
              </div>
              <div>
                <sup>3</sup> Distância em valores absolutos.
              </div>
              <div>
                <sup>4</sup> Distância em Valores absolutos.
              </div>
            </div>
            <Tooltip id="eden-tip" />
          </>
        )}
        <InstagramLink />
      </div>
    </PublicLayout>
  );
};

export default Eden;
