import PropTypes from "prop-types";
import Select from "react-select";
import { useAssets } from "../../hooks/useAssets";
import { useIsClient } from "../../hooks/useIsClient";
import { PREMIUM_OPTION, SELECT_SMALL } from "../../utils/select";
import Input from "../common/Input";
import * as css from "./SupplyDemandFilter.module.css";
import { useAuth } from "../../hooks/useAuth";
import InfoIcon from "../../icons/info-question-circle.svg";
import GroupLabel from "../common/GroupLabel";
import { isEmpty } from "lodash";
import { Tooltip } from "react-tooltip";

export const getYears = (startRange, numberOfOptions) => {
  const options = [];
  for (let i = 0; i < numberOfOptions + 1; i++) {
    options.push({
      label: (startRange - i).toString(),
      value: startRange - i,
    });
  }
  return options;
};

export const getProjectionPeriods = () => [
  { value: "yearly", label: "Anual" },
  { value: "monthly", label: "Mensal" },
  { value: "weekly", label: "Semanal" },
];

const PROJECTION_PERIODS_OPTIONS = getProjectionPeriods();

const YEARS_OPTIONS = getYears(new Date().getFullYear(), 5);

const SupplyDemandFilter = ({
  onClick,
  isLoading,
  asset,
  setAsset,
  year,
  setYear,
  sdPeriod,
  setSdPeriod,
  projectionPeriod,
  setProjectionPeriod,
  isPremium,
}) => {
  const { grouped } = useAssets({ requirePremium: true, includeIBOV: true });
  const { isLoggedIn } = useAuth();

  const isClient = useIsClient();
  const key = isClient ? "client" : "server";

  const currentYear = new Date().getFullYear();

  const isValid =
    !isEmpty(asset) &&
    !isEmpty(year) &&
    year.value >= 2010 &&
    year.value <= currentYear &&
    sdPeriod >= 10 &&
    sdPeriod <= 100;

  const canSubmit = !isLoading && isValid;

  const PROTECTED_PROJECTION_PERIODS_OPTIONS = PROJECTION_PERIODS_OPTIONS.map(
    option => {
      if (!isPremium && option.value !== "yearly")
        return { ...option, isDisabled: true };
      return option;
    }
  );

  const PROTECTED_YEARS_OPTIONS = YEARS_OPTIONS.map(option => {
    if (!isPremium && option.value !== currentYear)
      return { ...option, isDisabled: true };
    return option;
  });

  return (
    <form
      className={css["filter"]}
      key={key}
      onSubmit={e => {
        e.preventDefault();
        onClick();
      }}>
      <div>
        <label>Ativo</label>
        <Select
          id="base-asset"
          options={grouped}
          value={asset}
          onChange={option => setAsset(option)}
          styles={{ ...SELECT_SMALL, ...PREMIUM_OPTION }}
          formatGroupLabel={data => <GroupLabel {...data} />}
          placeholder="Selecionar..."
        />
      </div>
      <div>
        <label htmlFor="sd-year">Ano de Projeção</label>
        <Select
          id="sd-year"
          options={PROTECTED_YEARS_OPTIONS}
          value={year}
          onChange={option => setYear(option)}
          styles={{
            ...SELECT_SMALL,
            ...PREMIUM_OPTION,
          }}
          isSearchable={false}
          placeholder="Selecionar ano de projeção"
        />
      </div>
      <div>
        <label htmlFor="sd-proj-period">Período de Projeção</label>
        <Select
          id="sd-proj-period"
          options={PROTECTED_PROJECTION_PERIODS_OPTIONS}
          value={projectionPeriod}
          onChange={option => setProjectionPeriod(option)}
          styles={{
            ...SELECT_SMALL,
            ...PREMIUM_OPTION,
          }}
          isSearchable={false}
          placeholder="Selecionar período"
        />
      </div>
      <div>
        <label htmlFor="window-filter">
          Período da Vol. (candles){" "}
          <InfoIcon
            className={css["infoIcon"]}
            data-tooltip-content="Número de candles utilizados para cálculo da volatilidade."
            data-tooltip-id="window-tip"
          />
          <Tooltip id="window-tip" delayHide={100} clickable />
        </label>
        <Input
          id="window-filter"
          value={sdPeriod}
          onValueChange={({ floatValue }) =>
            setSdPeriod(floatValue === undefined ? "" : floatValue)
          }
          fluid
          size="small"
          isNumber
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          decimalScale={0}
          placeholder="Ex: 20"
          hint={"Entre 10 e 100 candles"}
          isAllowed={({ floatValue }) =>
            floatValue === undefined || floatValue <= 100
          }
          disabled={!isLoggedIn}
          title={!isLoggedIn ? "Seja membro para customizar a janela" : ""}
        />
      </div>
      <div>
        <button disabled={!canSubmit} className={css["button"]} type="submit">
          Gerar
        </button>
      </div>
    </form>
  );
};

SupplyDemandFilter.propTypes = {
  isPremium: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  asset: PropTypes.object,
  setAsset: PropTypes.func.isRequired,
  year: PropTypes.object,
  setYear: PropTypes.func.isRequired,
  sdPeriod: PropTypes.number,
  setSdPeriod: PropTypes.func.isRequired,
  projectionPeriod: PropTypes.object,
  setProjectionPeriod: PropTypes.func.isRequired,
};

export default SupplyDemandFilter;
