import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import Spinner from "../components/common/Spinner";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { STRATEGY_OPTIONS } from "../utils/constants";
import { SELECT_SMALL } from "../utils/select";
import { showError } from "../utils/utils";
import * as css from "./trap.module.css";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const PORTFOLIO_ID = process.env.GATSBY_TOP_200_PORTFOLIO_ID;

const TYPE_OPTIONS = STRATEGY_OPTIONS.find(({ value }) => value === "long_trap")
  .rollingAvgType;

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "H2", label: "120 Minutos" },
  { value: "H1", label: "60 Minutos" },
];

const Trap = () => {
  const { isLoggedIn } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["H1", "H2"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const PROTECTED_TYPE_OPTIONS = TYPE_OPTIONS.map(option => {
    if (!isLoggedIn && ["simple"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [type, setType] = useState(
    PROTECTED_TYPE_OPTIONS.find(({ value }) => value === "ewm")
  );
  const [ticker, setTicker] = useState("");
  const [isHammer, setHammer] = useState(false);
  const [avgWindow, setWindow] = useState(8);
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [timeframe, setTimeframe] = useState(
    PROTECTED_TIMEFRAME_OPTIONS.find(({ value }) => value === "D1")
  );

  const [debouncedWindow] = useDebounce(avgWindow, 800);

  const staticData = useStaticQuery(graphql`
    query {
      allTrap {
        nodes {
          close
          low
          is_hammer
          moving_avg
          sequence_above
          ticker
          buildTime
        }
      }
      variations: allVariation(filter: { window: { eq: "DAYS_90" } }) {
        nodes {
          ticker
          variation
        }
      }
    }
  `);

  const [data, setData] = useState(staticData);

  const callAPI = useAPI({ withCredentials: true });

  const { data: trapData, isLoading } = useQuery(
    ["trap", timeframe, type, debouncedWindow],
    ({ queryKey }) => {
      const timeframeId = queryKey[1].value;
      const rollingAvgType = queryKey[2].value;
      if (
        queryKey[1].value === "D1" &&
        debouncedWindow === 8 &&
        rollingAvgType === "ewm"
      ) {
        setData(staticData);
        return;
      }
      if (!debouncedWindow || debouncedWindow < 2) return;
      return callAPI(
        `/api/portfolio/${PORTFOLIO_ID}/trap?timeframe=${timeframeId}&rolling_avg_type=${rollingAvgType}&window=${debouncedWindow}`
      );
    },
    {
      onError: showError,
      retry: (count, error) => {
        if (
          error.message === "Parâmetro inválido para usuários não cadastrados."
        )
          return false;
      },
    }
  );

  useEffect(() => {
    if (trapData) {
      const newData = { allTrap: { nodes: [] } };
      Object.keys(trapData).map(symbol => {
        const d = trapData[symbol];
        newData.allTrap.nodes.push({
          ...d,
          ticker: symbol,
          buildTime: d.last_updated.replace("UTC", "Z"),
        });
      });
      setData(newData);
    }
  }, [trapData]);

  const lastUpdated = data.allTrap.nodes[0]?.buildTime;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data.allTrap.nodes
      .map(node => ({
        ...node,
        order: staticData.variations.nodes.findIndex(
          n => n.ticker === node.ticker
        ),
      }))
      .filter(node => {
        if (isHammer) return node.is_hammer === 1;
        return true;
      })
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    "order",
    "asc"
  );

  useEffect(() => {
    trackScreeningViewed({ key: "trap" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Trap na Média",
        description:
          "Screening dos ativos que estão formando uma armadilha na média móvel, em tempo real e em diversos timeframes.",
      }}
      title="Trap na Média"
      ctaMessage="Crie sua conta para visualizar os ativos formando o Trap na Média em diferentes timeframes.">
      <div>
        <p>
          O <em>Trap de Compra na Média</em> é caracterizado por um candle que
          tem a mínima menor que a média móvel, mas cujo candle{" "}
          <em>seguinte</em> supera a sua <em>máxima</em>.
        </p>
        <p>
          A lista abaixo contém os ativos formando o <b>Trap na Média</b>, de{" "}
          <em>compra</em>, ordenados pela sua <em>força relativa</em> nos
          últimos 90 dias. É possível alterar o tipo e a janela da média móvel
          (máximo de 80 períodos), além de filtrar pelos sinais formando um{" "}
          <em>candle martelo</em>.
          {!isLoggedIn && (
            <span>
              {" "}
              <Link
                to="/cadastro"
                onClick={() =>
                  logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                    page: window.location.pathname,
                    ctaMessage:
                      "Crie sua conta para rodar o backtest do Trap na Média.",
                  })
                }>
                Crie sua conta para rodar o backtest do Trap na Média.
              </Link>{" "}
            </span>
          )}
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="trap-tf">Timeframe</label>
            <Select
              id="trap-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="trap-type">Tipo de Média</label>
            <Select
              id="trap-type"
              options={PROTECTED_TYPE_OPTIONS}
              value={type}
              onChange={option => setType(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="rolling-window">Período da Média</label>
            <input
              id="rolling-window"
              type="number"
              min={2}
              max={80}
              value={avgWindow}
              onChange={e =>
                setWindow(e.target.value ? parseInt(e.target.value) : "")
              }
              disabled={!isLoggedIn}
              title={
                isLoggedIn
                  ? undefined
                  : "Crie sua conta para desbloquear esse filtro"
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="trap-asset">Ativo</label>
            <input
              id="trap-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="is-hammer"
                type="checkbox"
                checked={isHammer}
                onChange={e => {
                  setHammer(e.target.checked);
                }}
              />
              <label htmlFor="is-hammer">Candle Martelo</label>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        {isLoading && isLoggedIn ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Robôs trabalhando..." />
          </div>
        ) : data.allTrap.nodes.length === 0 ? (
          <div className={css["empty"]}>
            Não há ativos apresentando o sinal nesse momento.
          </div>
        ) : (
          <>
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th
                      data-tooltip-id="trap-tip"
                      data-tooltip-content={`Preço aferido na última atualização.`}>
                      Preço Atual<sup>1</sup>
                    </th>
                    <th
                      data-tooltip-id="trap-tip"
                      data-tooltip-content={`Mínima do candle atual.`}>
                      Mínima<sup>2</sup>
                    </th>
                    <th>Média Móvel</th>
                    <th
                      data-tooltip-id="trap-tip"
                      data-tooltip-content="Candle cujo fechamento é maior que a abertura.">
                      Candle Martelo<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="trap-tip"
                      data-tooltip-content="Sequência de candles sem tocar a média móvel.">
                      Sequência<sup>4</sup>
                    </th>
                    <th
                      data-tooltip-id="trap-tip"
                      data-tooltip-content="Rode o backtest no simulador de estratégias.">
                      Estatísticas<sup>5</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>{node.ticker}</td>
                      <td>{node.close.toFixed(2)}</td>
                      <td>{node.low.toFixed(2)}</td>
                      <td>{node.moving_avg.toFixed(2)}</td>
                      <td>{node.is_hammer ? "Sim" : "Não"}</td>
                      <td>
                        {node.sequence_above >= 5 ? "5+" : node.sequence_above}
                      </td>
                      <td>
                        <Link
                          onClick={() =>
                            logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                              ticker: node.ticker,
                              page: window.location.pathname,
                            })
                          }
                          to={`/backtests?strategy=long_trap&ticker=${node.ticker}&timeframeId=${timeframe.value}&hammer=${node.is_hammer}&rollingAvgType=${type.value}&rollingAvgWindow=${debouncedWindow}`}>
                          Rodar Backtest
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço aferido na última atualização.
              </div>
              <div>
                <sup>2</sup> Mínima do candle atual.
              </div>
              <div>
                <sup>3</sup> Candle cujo fechamento é maior que a abertura.
              </div>
              <div>
                <sup>4</sup> Sequência de candles sem tocar a média móvel.
              </div>
              <div>
                <sup>5</sup> Rode o backtest no simulador de estratégias.
              </div>
            </div>
            <Tooltip id="trap-tip" />
          </>
        )}
        <InstagramLink />
      </div>
    </PublicLayout>
  );
};

export default Trap;
