import Modal from "../common/Modal";
import * as css from "./FeedModal.module.css";
import PropTypes from "prop-types";
import FeedBacktest from "./FeedBacktest";
import FeedCointegration from "./FeedCointegration";

const FeedModal = ({ data, isOpen, onClose }) => {
  const getContent = () => {
    if (data?.parameters?.backtest_id)
      return <FeedBacktest backtestId={data.parameters.backtest_id} />;

    if (data?.parameters?.cointegration_id)
      return (
        <FeedCointegration cointegrationId={data.parameters.cointegration_id} />
      );

    return <div>Conteúdo indisponível.</div>;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalClassName={css["modal"]}>
      {getContent()}
    </Modal>
  );
};

FeedModal.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FeedModal;
