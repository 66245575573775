import UpgradeButton from "./UpgradeButton";
import * as css from "./UpgradeSection.module.css";
import PropTypes from "prop-types";

const UpgradeSection = ({ copy }) => {
  return (
    <div className={css["container"]}>
      <div className={css["title"]}>Seja Premium</div>
      <div className={css["upgrade"]}>
        <div>{copy}</div>
        <UpgradeButton buttonText="Ver Planos" />
      </div>
    </div>
  );
};

UpgradeSection.propTypes = {
  copy: PropTypes.string.isRequired,
};

export default UpgradeSection;
