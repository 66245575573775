import { useEffect, useState } from "react";
import EntryLayout from "../../components/layouts/EntryLayout";
import SEO from "../../components/seo";
import PropTypes from "prop-types";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";
import Spinner from "../common/Spinner";
import SliderDiv from "../common/SliderDiv";
import * as css from "./LeadPageContainer.module.css";

const LeadPageContainer = ({ data, location }) => {
  const [hasSent, setHasSent] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Transform in a JSON object so we can send to Amplitude
    const urlParams = {};
    for (const [key, value] of params) {
      urlParams[key] = value;
    }

    logAmplitudeEvent(EVENT_MAP.LEAD_PAGE_VIEWED, {
      ...urlParams,
      ...data,
    });

    setTimeout(() => {
      setHasSent(true);
    }, 2000);
  }, [data, location]);

  return (
    <EntryLayout themeColor={data.theme}>
      <SEO title={data.title} meta={[{ name: "robots", content: "noindex" }]} />
      {hasSent && (
        <SliderDiv>
          <div className={css["container"]}>
            <h1>{data.successTitle}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: data.successText,
              }}
            />
          </div>
        </SliderDiv>
      )}
      {!hasSent && (
        <div>
          <Spinner text={data.loadingText} />
        </div>
      )}
    </EntryLayout>
  );
};

LeadPageContainer.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LeadPageContainer;
