import { useAPI } from "../../hooks/useAPI";
import { useMutation } from "react-query";
import { showError } from "../../utils/utils";
import * as css from "./ManageSubscriptions.module.css";
import VerifyIcon from "../../icons/verify.svg";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const ManageSubscriptionButton = ({ subscription }) => {
  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/payment/portal`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: data => (window.location.href = data.url),
      onError: showError,
    }
  );

  return (
    <div>
      {subscription && (
        <div>
          <div className={css["validUntil"]}>
            {subscription.cancel_at_period_end
              ? "Assinatura cancelada. Aproveite o plano Premium até"
              : "Assinatura ativa até"}{" "}
            <b>
              {DateTime.fromJSDate(new Date(subscription.current_period_end))
                .setLocale("pt-BR")
                .toLocaleString(DateTime.DATETIME_SHORT)}
              .
            </b>
          </div>
        </div>
      )}
      <button
        className={css["button"]}
        onClick={() => {
          mutation.mutate();
          logAmplitudeEvent(EVENT_MAP.MANAGE_SUBSCRIPTION_BUTTON_CLICKED);
        }}
        disabled={mutation.isLoading || mutation.isSuccess}>
        {mutation.isLoading
          ? "Atualizando informações..."
          : mutation.isSuccess
          ? "Redirecionando para página segura..."
          : subscription?.cancel_at_period_end
          ? "Reativar assinatura"
          : "Gerenciar assinatura"}
      </button>
      <div className={css["info"]}>
        <VerifyIcon />
        Você será redirecionado para o portal seguro do Stripe.
      </div>
    </div>
  );
};

ManageSubscriptionButton.propTypes = {
  subscription: PropTypes.object,
};

export default ManageSubscriptionButton;
