import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import { useIsClient } from "../../hooks/useIsClient";
import SliderDiv from "../common/SliderDiv";
import MyPortfoliosDropdown from "./MyPortfoliosDropdown";
import * as css from "./ShowPortfolios.module.css";
import StarIcon from "../../icons/star.svg";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  incrementProperty,
} from "../../utils/amplitude";

const ShowPortfolios = ({
  showPortfolios,
  setShowPortfolios,
  portfolioOptions,
  selectedPortfolio,
  setSelectedId,
  requirePremium = false,
}) => {
  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const { user } = useAuth();
  const isPremium = user?.isPremium;
  const isDisabled = requirePremium && !isPremium;

  return (
    <div key={key} className={css["portfolios"]}>
      <div>
        <input
          id="show-portfolios"
          type="checkbox"
          checked={showPortfolios}
          disabled={isDisabled}
          title={
            isDisabled
              ? "Seja Premium para desbloquear essa funcionalidade"
              : ""
          }
          onChange={e => {
            setShowPortfolios(e.target.checked);
            logAmplitudeEvent(EVENT_MAP.PORTFOLIO_APPLIED, {
              checked: e.target.checked,
              page: window.location.pathname,
            });
            if (e.target.checked) {
              incrementProperty("portfoliosApplied");
            }
          }}
        />
        <label htmlFor="show-portfolios">
          Aplicar nos meus portfólios
          {requirePremium && (
            <span className={css["premiumSpan"]}>
              <StarIcon />
            </span>
          )}
        </label>
      </div>
      {showPortfolios && (
        <SliderDiv offset={-50} property="marginTop">
          <MyPortfoliosDropdown
            options={portfolioOptions}
            selectedPortfolio={selectedPortfolio}
            onChange={option => setSelectedId(option ? option.value : null)}
          />
        </SliderDiv>
      )}
    </div>
  );
};

ShowPortfolios.propTypes = {
  showPortfolios: PropTypes.bool.isRequired,
  setShowPortfolios: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  selectedPortfolio: PropTypes.object,
  portfolioOptions: PropTypes.array.isRequired,
  requirePremium: PropTypes.bool,
};

export default ShowPortfolios;
