import { useMemo } from "react";
import ASSETS from "../data/assets.json";
import { useAuth } from "./useAuth";
import { isEmpty } from "lodash";

// Assets that are not premium only
const isAssetDisabled = (asset, showAll, isVisitor) => {
  if (showAll) return false;
  if (isVisitor) return asset.type !== "B3";
  return asset.type !== "B3" && asset.symbol !== "BTC-USD";
};

const getFutureOrder = asset => {
  if (asset.symbol.startsWith("WIN")) return 1;
  if (asset.symbol.startsWith("WDO")) return 2;
  if (asset.symbol.startsWith("WSP")) return 3;
  if (asset.symbol.startsWith("CCM")) return 4;
  if (asset.symbol.startsWith("BGI")) return 5;
  return 5;
};

export const getAssetLabel = symbol => LABEL_MAP[symbol] || symbol;

const mapToOption = (asset, showAll, isVisitor = false) => ({
  value: asset.symbol,
  label: getAssetLabel(asset.symbol),
  useContracts: asset.type === "Futuro",
  isDisabled: isAssetDisabled(asset, showAll, isVisitor),
  isCrypto: asset.type === "Crypto",
  type: asset.type,
  isIBOV: asset.is_ibov,
  ...(asset.type === "Futuro" ? { order: getFutureOrder(asset) } : {}),
});

let ASSETS_BLACKLIST = [
  "IBOV",
  "IND$",
  "DOL$",
  "WIN$",
  "WIN$N",
  "WDO$",
  "CCM$",
  "CCM$N",
  "WSP$D",
  "WSP$N",
  "BGI$N",
  "BGI$",
];

const LABEL_MAP = {
  WIN$D: "WINFUT",
  WDO$D: "WDOFUT",
  WSP$: "WSPFUT",
  CCM$D: "CCMFUT",
  BGI$D: "BGIFUT",
  "BTC-USD": "BTC/USD",
  "ETH-USD": "ETH/USD",
  "ADA-USD": "ADA/USD",
  "DOGE-USD": "DOGE/USD",
  "SOL-USD": "SOL/USD",
  "SHIB-USD": "SHIB/USD",
  "AVAX-USD": "AVAX/USD",
  "DOT-USD": "DOT/USD",
  "LINK-USD": "LINK/USD",
  "LTC-USD": "LTC/USD",
  "MATIC-USD": "MATIC/USD",
};

export const useAssets = ({ requirePremium = true, includeIBOV = false }) => {
  ASSETS_BLACKLIST = includeIBOV
    ? ASSETS_BLACKLIST.filter(symbol => symbol !== "IBOV")
    : ASSETS_BLACKLIST;

  const availableAssets = ASSETS.filter(
    ({ symbol }) => !ASSETS_BLACKLIST.includes(symbol)
  );

  const { user } = useAuth();

  const isVisitor = isEmpty(user);

  const showAll = requirePremium ? !!user?.isPremium : true;

  const asOptions = useMemo(
    () => availableAssets.map(asset => mapToOption(asset, showAll)),
    [availableAssets, showAll]
  );

  const grouped = useMemo(() => {
    const options = [
      {
        label: "Futuros",
        options: availableAssets
          .filter(({ type }) => type === "Futuro")
          .map(asset => mapToOption(asset, showAll))
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      },
      {
        label: "Ações",
        options: availableAssets
          .filter(({ type, symbol }) => type === "B3" && symbol !== "IBOV")
          .map(asset => mapToOption(asset, showAll)),
      },
      {
        label: "Criptomoedas",
        options: availableAssets
          .filter(({ type }) => type === "Crypto")
          .map(asset => ({
            ...mapToOption(asset, showAll, isVisitor),
            order:
              asset.symbol === "BTC-USD"
                ? 1
                : asset.symbol === "ETH-USD"
                ? 2
                : 3,
          }))
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
      },
      {
        label: "BDRs",
        options: availableAssets
          .filter(({ type }) => type === "BDR")
          .map(asset => mapToOption(asset, showAll)),
      },
      {
        label: "ETFs",
        options: availableAssets
          .filter(({ type }) => type === "ETF")
          .map(asset => mapToOption(asset, showAll)),
      },
    ];

    if (includeIBOV) {
      options.unshift({
        label: "Índices",
        options: availableAssets
          .filter(({ symbol }) => symbol === "IBOV")
          .map(asset => mapToOption(asset, showAll)),
      });
    }

    return options;
  }, [availableAssets, isVisitor, showAll, includeIBOV]);

  const getSector = symbol => {
    const asset = availableAssets.find(a => a.symbol === symbol);
    return asset?.sector;
  };

  const getAllFromSector = sector => {
    if (!sector) return [];
    return availableAssets.filter(a => a.sector === sector);
  };

  return {
    raw: availableAssets,
    asOptions,
    grouped,
    getSector,
    getAllFromSector,
  };
};
