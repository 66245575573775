import { Link } from "gatsby";
import PropTypes from "prop-types";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import * as css from "./RegisterButton.module.css";

const RegisterButton = ({ copy, buttonText = "Cadastrar" }) => {
  return (
    <div className={css["register"]}>
      <div>
        {copy} <b>É de graça!</b>
      </div>
      <div className={css["buttonContainer"]}>
        <Link
          onClick={() =>
            logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
              page: window.location.pathname,
              ctaText: buttonText,
              bannerText: copy,
            })
          }
          to="/cadastro">
          {buttonText}
        </Link>
        <div>Não leva nem 1 minuto!</div>
      </div>
    </div>
  );
};

RegisterButton.propTypes = {
  copy: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
};

export default RegisterButton;
