import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { memo, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { useAuth } from "../../hooks/useAuth";
import InfoIcon from "../../icons/info-question-circle.svg";
import { toPercent } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import UpgradeSection from "../common/UpgradeSection";
import * as css from "./BacktestResult.module.css";
import CalendarChart from "./CalendarChart";
import CapitalChart from "./CapitalChart";
import MonitorBanner from "./MonitorBanner";
import ReportBacktest from "./ReportBacktest";
import SaveBacktest from "./SaveBacktest";
import { renderToStaticMarkup } from "react-dom/server";
import scrollToElement from "scroll-to-element";
import BatchBanner from "./BatchBanner";
import ParametersBox from "./ParametersBox";
import ExportBacktest from "./ExportBacktest";
import ScoreIndicator from "./ScoreIndicator";
import SuspiciousBanner from "./SuspiciousBanner";

export const getColor = value => ({
  color: value > 0 ? "#49ce8b" : value < 0 ? "#d64242" : undefined,
});

const BacktestResult = ({
  result,
  isFavorite,
  onToggleFavorite,
  showParameters = false,
  showUpgrade = false,
  id = "backtest-result",
  scrollIntoView = false,
}) => {
  const { isLoggedIn } = useAuth();

  const showAlertBanner = isLoggedIn && result.can_monitor && result.ev > 0;

  const showScore = result.score !== null && result.score_percentiles;

  useEffect(() => {
    if (scrollIntoView) scrollToElement(`#${id}`, { offset: -16 });
  }, [id, scrollIntoView]);

  return (
    <div id={id}>
      {showParameters && !isEmpty(result?.parameters) && (
        <ParametersBox parameters={result.parameters} />
      )}
      <div className={css["statsLabel"]}>
        Estatísticas{" "}
        {isLoggedIn && (
          <div className={css["buttonsContainer"]}>
            <ExportBacktest result={result} allowPopup={!!onToggleFavorite} />
            <ReportBacktest result={result} />
            {!!onToggleFavorite && (
              <SaveBacktest
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                result={result}
              />
            )}
          </div>
        )}
      </div>
      {result.suspicious && <SuspiciousBanner />}
      <div className={css["stats"]}>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>Operações</div>
            <div>{result.num_operations}</div>
          </div>
          <div className={css["stat"]}>
            <div>Acertos</div>
            <div>
              {toPercent(result.pct_gains, 0)}{" "}
              {result.num_gains !== null && (
                <span className={css["complement"]}>({result.num_gains})</span>
              )}
            </div>
          </div>
          <div className={css["stat"]}>
            <div>Resultado</div>
            <div style={getColor(result.pct_profit)}>
              {toPercent(result.pct_profit, 2)}
            </div>
          </div>
        </div>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>Ganho Médio</div>
            <div>
              {result.avg_gains.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
          <div className={css["stat"]}>
            <div>Perda Média</div>
            <div>
              {result.avg_losses.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })}
            </div>
          </div>
          <div className={css["stat"]}>
            <div>
              EV <InfoIcon data-tooltip-content data-tooltip-id="ev-tip" />
            </div>
            <div style={getColor(result.ev)}>{toPercent(result.ev, 2)}</div>
          </div>
        </div>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>Melhor Sequência</div>
            <div>
              {result.best_win_streak}{" "}
              <span className={css["complement"]}>
                Gain{result.best_win_streak === 1 ? "" : "s"}
              </span>
            </div>
          </div>
          <div className={css["stat"]}>
            <div>Pior Sequência</div>
            <div>
              {result.worst_loss_streak}{" "}
              <span className={css["complement"]}>
                Loss{result.worst_loss_streak === 1 ? "" : "es"}
              </span>
            </div>
          </div>
          <div className={css["stat"]}>
            <div>
              Drawdown <InfoIcon data-tooltip-id="dd-tip" />
            </div>
            <div>{toPercent(result.drawdown, 2)}</div>
          </div>
        </div>
      </div>
      {isLoggedIn && (
        <BatchBanner
          setupId={result.setup_id}
          timeframeId={result.timeframe_id}
          ticker={result.ticker}
          parameters={result.parameters}
          strategyId={result.strategy_id}
        />
      )}
      {showScore && (
        <div className={css["chartContainer"]}>
          <div className={css["scoreTitle"]}>
            Score <InfoIcon data-tooltip-content data-tooltip-id="score-tip" />
          </div>
          <ScoreIndicator
            score={result.score}
            percentiles={result.score_percentiles}
          />
        </div>
      )}
      {!isLoggedIn && (
        <div className={css["registerContainer"]}>
          <RegisterButton copy="Crie sua conta para desbloquear o histórico de trades." />
        </div>
      )}
      <div className={css["chartContainer"]}>
        <div>Curva de Capital</div>
        <CapitalChart
          tradeHistory={result.trade_history.map(trade => ({
            ...trade,
            entry_date: trade.entry_date.slice(0, 10),
            exit_date: trade.exit_date.slice(0, 10),
          }))}
          initialCapital={result.initial_capital}
          startDate={result.start_date}
        />
      </div>
      {showAlertBanner && (
        <MonitorBanner
          backtestId={result.id}
          setupId={result.setup_id}
          timeframeId={result.timeframe_id}
          ticker={result.ticker}
          alertId={result.alert_id}
          alertStatus={result.alert_status}
        />
      )}
      <div className={css["chartContainer"]}>
        <div>Calendário de Trades</div>
        <CalendarChart
          tradeHistory={result.trade_history.map(trade => ({
            ...trade,
            entry_date: trade.entry_date.slice(0, 10),
            exit_date: trade.exit_date.slice(0, 10),
          }))}
          startDate={result.start_date}
          endDate={result.end_date}
        />
      </div>
      <div className={css["disclaimer"]}>
        Disclaimer: Não é recomendação de compra ou venda
      </div>
      {showUpgrade && (
        <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear backtests em criptos, ETFs, BDRs e mini-índice e mini-dólar sem restrição de período!" />
      )}
      <Tooltip
        id="ev-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o EV?{" "}
            <a
              href="/entenda-o-que-e-valor-esperado-ev-e-sua-utilidade-para-um-trader"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <Tooltip
        id="score-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o Score?{" "}
            <a
              href="/backtests/score"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <Tooltip
        id="dd-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o Drawdown?{" "}
            <a
              href="/entenda-o-drawdown-e-calcule-essa-medida-de-volatilidade-para-qualquer-ativo"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
    </div>
  );
};

BacktestResult.propTypes = {
  result: PropTypes.shape({
    num_operations: PropTypes.number.isRequired,
    num_gains: PropTypes.number.isRequired,
    pct_gains: PropTypes.number.isRequired,
    avg_gains: PropTypes.number.isRequired,
    avg_losses: PropTypes.number.isRequired,
    pct_profit: PropTypes.number.isRequired,
    ev: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    setup_id: PropTypes.number.isRequired,
    strategy_id: PropTypes.string.isRequired,
    timeframe_id: PropTypes.string.isRequired,
    ticker: PropTypes.string,
    drawdown: PropTypes.number.isRequired,
    best_win_streak: PropTypes.number.isRequired,
    worst_loss_streak: PropTypes.number.isRequired,
    trade_history: PropTypes.array.isRequired,
    initial_capital: PropTypes.number.isRequired,
    start_date: PropTypes.string.isRequired,
    end_date: PropTypes.string.isRequired,
    parameters: PropTypes.object,
    can_monitor: PropTypes.bool,
    alert_id: PropTypes.number,
    alert_status: PropTypes.oneOf(["active", "inactive"]),
    score: PropTypes.number,
    score_percentiles: PropTypes.shape({
      percentile_5: PropTypes.number,
      percentile_25: PropTypes.number,
      percentile_50: PropTypes.number,
      percentile_75: PropTypes.number,
      percentile_95: PropTypes.number,
    }),
    suspicious: PropTypes.bool,
  }).isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func,
  showParameters: PropTypes.bool,
  showUpgrade: PropTypes.bool,
  id: PropTypes.string,
  scrollIntoView: PropTypes.bool,
};

export default memo(BacktestResult);
