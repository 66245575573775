import { isEmpty } from "lodash";
import { useEffect, useReducer } from "react";
import { useSessionStorage } from "react-use";

const MAX_DATE_RANGE = 250;

const initialState = {
  baseAsset: null,
  comparisonAsset: null,
  runBatch: false,
  window: MAX_DATE_RANGE,
};

export const mapValues = state => {
  if (state.runBatch)
    return {
      tickers_to_combine: [state.baseAsset.value],
      window: state.window,
    };

  return {
    base_asset: state.baseAsset.value,
    comparison_asset: state.comparisonAsset.value,
    window: state.window,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, [action.key]: action.value };
    case "SET_STATE":
      return action.state;
    case "RESET":
      return initialState;
    default:
      throw new Error();
  }
};

export const useCointegrationFilter = () => {
  const [filter, saveFilter] = useSessionStorage(
    "qb.cointegrationFilter",
    initialState
  );
  const [state, dispatch] = useReducer(reducer, filter);

  const setValue = (key, value) =>
    dispatch({
      type: "SET_VALUE",
      key,
      value: value === "" ? null : value,
    });

  const reset = () => {
    saveFilter(initialState);
    dispatch({ type: "RESET" });
  };

  const setState = newState => {
    saveFilter(newState);
    dispatch({ type: "SET_STATE", state: newState });
  };

  const getValue = key => {
    return state[key] === undefined ? "" : state[key];
  };

  const checkForErrors = state => {
    const errors = {};
    Object.keys(state).forEach(key => {
      let errorMessage = "";
      switch (key) {
        case "window":
          errorMessage =
            typeof state.window !== "number" && isEmpty(state.window)
              ? "Janela é obrigatória"
              : state.window > MAX_DATE_RANGE
              ? `Limite máximo de ${MAX_DATE_RANGE} dias`
              : "";
          break;
        default:
          break;
      }
      errors[key] = errorMessage;
    });
    return errors;
  };

  const errors = checkForErrors(state);

  const isValid =
    !isEmpty(state.baseAsset) &&
    isEmpty(errors["window"]) &&
    (state.runBatch ? true : !isEmpty(state.comparisonAsset));

  useEffect(() => {
    saveFilter(state);
  }, [saveFilter, state]);

  return {
    state: filter,
    setValue,
    getValue,
    isValid,
    reset,
    errors,
    setState,
  };
};
