import { graphql } from "gatsby";
import PropTypes from "prop-types";
import ReleasesLayout from "../components/layouts/ReleasesLayout";

const ReleaseTemplate = ({ data: { ghostPost: data } }) => {
  return <ReleasesLayout data={[data]} isIndividual />;
};

ReleaseTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReleaseTemplate;

export const query = graphql`
  query ReleaseBySlug($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      title
      slug
      tags {
        slug
        name
      }
      excerpt
      published_at
      uuid
      html
      feature_image
    }
  }
`;
