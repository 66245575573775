// extracted by mini-css-extract-plugin
export var alreadyMember = "LandingPageContainer-module--alreadyMember--c653b";
export var anchors = "LandingPageContainer-module--anchors--b4441";
export var backTop = "LandingPageContainer-module--backTop--25cdd";
export var benefits = "LandingPageContainer-module--benefits--9fdbf";
export var button = "LandingPageContainer-module--button--1dfce";
export var buttonOutline = "LandingPageContainer-module--buttonOutline--ed08d";
export var content = "LandingPageContainer-module--content--8a36e";
export var coupon = "LandingPageContainer-module--coupon--931d0";
export var cta = "LandingPageContainer-module--cta--fb6ac";
export var description = "LandingPageContainer-module--description--67ede";
export var header = "LandingPageContainer-module--header--eb291";
export var logo = "LandingPageContainer-module--logo--98001";
export var main = "LandingPageContainer-module--main--33da3";
export var partnerLogo = "LandingPageContainer-module--partnerLogo--67285";
export var period = "LandingPageContainer-module--period--512fa";
export var plan = "LandingPageContainer-module--plan--865c9";
export var plans = "LandingPageContainer-module--plans--c5839";
export var price = "LandingPageContainer-module--price--fa832";
export var rest = "LandingPageContainer-module--rest--fd006";
export var savings = "LandingPageContainer-module--savings--14b22";
export var toggle = "LandingPageContainer-module--toggle--88aee";
export var waContainer = "LandingPageContainer-module--waContainer--e3203";
export var whatsContainer = "LandingPageContainer-module--whatsContainer--56963";