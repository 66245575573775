import { Link } from "gatsby";
import { useState } from "react";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import {
  isValidEmail,
  isValidName,
  isValidPassword,
  showError,
} from "../../utils/utils";
import SliderDiv from "../common/SliderDiv";
import * as css from "./Login.module.css";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import Spinner from "../common/Spinner";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import pt from "react-phone-input-2/lang/pt.json";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const invalidVerdicts = ["undeliverable", "unknown"];

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");

  const [hasRegistered, setRegistered] = useState(false);

  const { signUp } = useAuth();

  const callAPI = useAPI({ withCredentials: false });

  const { isFetching, data, refetch } = useQuery(
    "validateEmail",
    () =>
      callAPI(`/api/contact/validate_email?email=${encodeURIComponent(email)}`),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      onSuccess: data => {
        logAmplitudeEvent(EVENT_MAP.EMAIL_VALIDATED, {
          name,
          email,
          score: data?.score,
          verdict: data?.verdict,
          suggestion: data?.suggestion,
          reason: data?.reason,
          duration: data?.duration,
        });
      },
    }
  );

  const registerMutation = useMutation(
    () => signUp(name, email, password, phone),
    {
      onSuccess: () => {
        setRegistered(true);
      },
      onError: showError,
    }
  );

  const onClick = e => {
    e.preventDefault();
    if (!canSubmit) return;
    logAmplitudeEvent(EVENT_MAP.CREATE_ACCOUNT_BUTTON_CLICKED, {
      name,
      email,
      phone,
      score: data?.score,
      verdict: data?.verdict,
      suggestion: data?.suggestion,
      reason: data?.reason,
      duration: data?.duration,
      referrer: document?.referrer || "",
    });
    registerMutation.mutate();
  };

  const canSubmit =
    !registerMutation.isLoading &&
    isValidPassword(password, confirmPassword) &&
    isValidEmail(email) &&
    !invalidVerdicts.includes(data?.verdict) &&
    isValidName(name);

  const onEmailBlur = () => {
    // If new email, refetch
    if (email !== data?.email) refetch();
  };

  const getEmailHint = () => {
    if (isFetching)
      return (
        <div className={css["hint"]}>
          <span>Validando email...</span>
          <Spinner type="Rings" size={14} />
        </div>
      );

    if (!isEmpty(data)) {
      const suggestion = data.suggestion;
      const isSameEmail = email === data.email;

      // While the emails are different, display default message
      if (!isSameEmail)
        return (
          <div className={css["hint"]}>Enviaremos um email de confirmação.</div>
        );

      if (suggestion) {
        return (
          <div className={css["info"]}>
            Você quis dizer <strong>{suggestion}</strong>?{" "}
            <button
              className={css["fixButton"]}
              onClick={() => {
                setEmail(suggestion);
                setTimeout(() => {
                  refetch();
                  document?.getElementById("pwd")?.focus();
                }, 100);
              }}>
              Corrigir
            </button>
          </div>
        );
      }

      if (data.verdict === "risky") {
        return (
          <div className={css["info"]}>
            Caso não receba um email após o cadastro,{" "}
            <span>verifique sua caixa de spam.</span>
          </div>
        );
      }

      if (invalidVerdicts.includes(data.verdict)) {
        return (
          <div className={css["warning"]}>
            <span>Email inválido</span>.
          </div>
        );
      }

      return (
        <div className={css["success"]}>
          <span>Email válido!</span>
        </div>
      );
    }

    return (
      <div className={css["hint"]}>Enviaremos um email de confirmação.</div>
    );
  };

  if (hasRegistered) {
    return (
      <SliderDiv>
        <div className={css["confirmation"]}>
          <b>Feito</b>! Um email de confirmação foi enviado para{" "}
          <span>{email}</span>.
          <br /> Caso não tenha recebido, verifique sua <b>caixa de spam</b>.
        </div>
      </SliderDiv>
    );
  }

  return (
    <div className={css["box"]}>
      <h2>Criar Conta</h2>
      <form>
        <div className={css["input"]}>
          <label>Nome Completo</label>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={css["input"]}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            onBlur={onEmailBlur}
            required
            autoComplete="username"
          />
          {getEmailHint()}
        </div>
        <div className={css["input"]}>
          <label>WhatsApp</label>
          <PhoneInput
            containerClass={css["containerClass"]}
            inputClass={css["inputClass"]}
            dropdownClass={css["dropdownClass"]}
            country="br"
            localization={pt}
            placeholder="+55 11 987654321"
            preferredCountries={["br", "us", "ca"]}
            value={phone}
            onChange={phone => setPhone(phone)}
          />
          <div className={css["phoneHint"]}>
            Receba notificações e alertas sobre as estratégias que você
            escolher. <span className={css["optional"]}>Opcional.</span>
          </div>
        </div>
        <div className={css["input"]}>
          <label>Senha</label>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="new-password"
            id="pwd"
          />
        </div>
        <div className={css["input"]}>
          <label>Confirmar Senha</label>
          <input
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            autoComplete="new-password"
          />
        </div>
        <button
          disabled={!canSubmit}
          className={css["button"]}
          onClick={onClick}>
          {registerMutation.isLoading ? "Criando..." : "Criar Conta"}
        </button>
        <div className={css["createAccount"]}>
          Já possui conta? <Link to="/login">Entrar</Link>
        </div>
      </form>
    </div>
  );
};

export default Register;
