import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  incrementProperty,
} from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import AddAlertBanner from "./AddAlertBanner";
import AddPhoneBanner from "./AddPhoneBanner";
import Alerts, { FILTER_OPTIONS } from "./Alerts";

const REFETCH_INTERVAL = 1000 * 60; // Every minute

const AlertsContainer = () => {
  const [filter, setFilter] = useState(FILTER_OPTIONS[0]);
  const [assets, setAssets] = useState([]);
  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const hasPhone = !!user?.phone;

  const end = DateTime.now().toISODate();
  const start = DateTime.now().minus({ days: 15 }).toISODate();
  const { isLoading, isSuccess, data, dataUpdatedAt } = useQuery(
    "setupAlerts",
    () => callAPI(`/api/alert/?start=${start}&end=${end}`),
    { refetchInterval: REFETCH_INTERVAL, onError: showError }
  );

  const hasWhatsAppMessage = Boolean(
    data?.some(({ type }) => type === "WHATSAPP")
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ALERTS_VIEWED);
    incrementProperty("alertsPageViewed");
  }, []);

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear todos os alertas"
      seoProps={{
        title: "Alertas",
      }}>
      <div>
        <h1>Alertas</h1>
        <p style={{ marginBottom: "20px" }}>
          A lista abaixo contém todos os alertas enviados em tempo real pela
          plataforma nos últimos 15 dias. Clique no alerta para ver a mensagem
          enviada. Refine sua busca nos filtros abaixo.
        </p>
        {dataUpdatedAt > 0 && (
          <p style={{ fontSize: "16px", marginBottom: "20px" }}>
            <em>Última atualização</em>:{" "}
            {DateTime.fromMillis(dataUpdatedAt)
              .setLocale("pt-BR")
              .toFormat("HH:mm:ss")}
          </p>
        )}
        {!hasPhone && <AddPhoneBanner />}
        {hasPhone && isSuccess && !hasWhatsAppMessage && <AddAlertBanner />}
        {isLoading && <Spinner type="Rings" text="Carregando..." />}
        {isSuccess && (
          <Alerts
            alerts={data}
            filter={filter}
            setFilter={setFilter}
            assets={assets}
            setAssets={setAssets}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default AlertsContainer;
