import * as css from "../valueAtRisk/VaRFilter.module.css";
import Select from "react-select";
import { PREMIUM_OPTION, SELECT_MULTI } from "../../utils/select";
import PropTypes from "prop-types";

const BetaFilter = ({
  years,
  setYears,
  yearsOptions,
  assets,
  setAssets,
  assetsOptions,
  showPortfolios,
  setShowPortfolios,
}) => {
  return (
    <div className={css["filter"]}>
      <div>
        <label htmlFor="period-tf">Período</label>
        <Select
          id="period-tf"
          options={yearsOptions}
          value={years}
          onChange={option => setYears(option)}
          styles={{
            ...SELECT_MULTI,
            container: provided => ({
              ...provided,
              width: "205px",
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected
                ? "hsl(0, 0%, 100%)"
                : state.isDisabled
                ? "hsl(0, 0%, 80%)"
                : "rgb(51, 51, 51)",
              ...(state.isDisabled
                ? {
                    display: "flex",
                    alignItems: "center",
                    ":before": {
                      backgroundColor: "#49ce8b",
                      color: "#fff",
                      borderRadius: 10,
                      content: "'Premium'",
                      display: "block",
                      marginRight: 8,
                      fontSize: 10,
                      padding: 4,
                      fontWeight: 500,
                    },
                  }
                : {}),
            }),
          }}
          isSearchable={false}
        />
      </div>
      <div className={css["assets"]}>
        <label htmlFor="assets-select">Ativos</label>
        <Select
          id="assets-select"
          options={assetsOptions}
          value={assets}
          onChange={option => {
            setAssets(option);
            if (showPortfolios && option.length < assets.length) {
              setShowPortfolios(false);
            }
          }}
          styles={{ ...SELECT_MULTI, ...PREMIUM_OPTION }}
          isMulti
          placeholder="Selecione os ativos"
        />
      </div>
    </div>
  );
};

BetaFilter.propTypes = {
  years: PropTypes.object.isRequired,
  setYears: PropTypes.func.isRequired,
  yearsOptions: PropTypes.array.isRequired,
  assets: PropTypes.array.isRequired,
  setAssets: PropTypes.func.isRequired,
  assetsOptions: PropTypes.array.isRequired,
  setShowPortfolios: PropTypes.func.isRequired,
  showPortfolios: PropTypes.bool.isRequired,
};

export default BetaFilter;
