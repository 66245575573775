import PropTypes from "prop-types";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAPI } from "../../hooks/useAPI";
import CloseIcon from "../../icons/close.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import * as css from "./SelectedPortfolio.module.css";

const PortfolioAssets = ({ portfolio, fetchPortfolios }) => {
  const [selectedAssets, setSelected] = useState([]);

  const assetsInPortfolio = portfolio.symbols;

  const portfolioEmpty = assetsInPortfolio.length === 0;

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/portfolio/${portfolio.id}/remove`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          assets: selectedAssets,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        toast.success("Ativos removidos com sucesso!");
        logAmplitudeEvent(EVENT_MAP.PORTFOLIO_CHANGED, {
          action: "remove",
          assets: selectedAssets,
        });
        fetchPortfolios();
        setSelected([]);
      },
    }
  );

  return (
    <div className={css["assets"]}>
      <div>Meus Ativos:</div>
      {portfolioEmpty && <div>Portfólio vazio</div>}
      {!portfolioEmpty && (
        <div className={css["list"]}>
          {assetsInPortfolio.map(symbol => (
            <div
              className={css["asset"]}
              style={{
                boxShadow: selectedAssets.includes(symbol)
                  ? "0 0 0 2px #49ce8b"
                  : undefined,
              }}
              key={symbol}
              onClick={() => {
                if (selectedAssets.includes(symbol))
                  setSelected(selectedAssets.filter(s => s !== symbol));
                else setSelected(selectedAssets.concat([symbol]));
              }}>
              {symbol}
            </div>
          ))}
        </div>
      )}
      {selectedAssets.length > 0 && (
        <button
          disabled={mutation.isLoading}
          className={css["removeButton"]}
          onClick={() => mutation.mutate()}>
          {mutation.isLoading ? (
            <Loader
              type="TailSpin"
              color="rgb(118, 120, 140)"
              height={18}
              width={18}
              style={{ marginRight: "6px", paddingLeft: "4px" }}
            />
          ) : (
            <CloseIcon />
          )}
          {mutation.isLoading ? "Removendo" : "Remover"} {selectedAssets.length}{" "}
          ativo
          {selectedAssets.length === 1 ? "" : "s"}
        </button>
      )}
    </div>
  );
};

PortfolioAssets.propTypes = {
  portfolio: PropTypes.object.isRequired,
  fetchPortfolios: PropTypes.func.isRequired,
};

export default PortfolioAssets;
