import Modal from "../common/Modal";
import PropTypes from "prop-types";
import ViewIcon from "../../icons/view.svg";
import * as css from "./CointegrationTradeViewButton.module.css";
import { useState } from "react";
import FeedCointegration from "../feed/FeedCointegration";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const CointegrationTradeViewButton = ({ cointegrationId }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <button
        className={css["mainButton"]}
        data-tooltip-id="table-tooltip"
        data-tooltip-content="Visualizar cointegração"
        onClick={() => {
          logAmplitudeEvent(EVENT_MAP.COINTEGRATION_TRADE_VIEW_BUTTON_CLICKED, {
            cointegrationId,
          });
          setModalOpen(true);
        }}>
        <ViewIcon />
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        modalClassName={css["modal"]}>
        <FeedCointegration cointegrationId={cointegrationId} />
      </Modal>
    </>
  );
};

CointegrationTradeViewButton.propTypes = {
  cointegrationId: PropTypes.number.isRequired,
};

export default CointegrationTradeViewButton;
