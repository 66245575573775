import { Link, navigate } from "gatsby";
import { useState } from "react";
import { useMutation } from "react-query";
import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import * as css from "./Login.module.css";
import { useSessionStorage } from "react-use";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const Login = ({ isNewUser = false }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [redirectAfterLogin] = useSessionStorage("qb.redirect", "/app/feed");

  const { signIn } = useAuth();

  const loginMutation = useMutation(() => signIn(email, password), {
    onSuccess: () => {
      navigate(redirectAfterLogin);
    },
    onError: showError,
  });

  const onClick = e => {
    e.preventDefault();
    if (loginMutation.isLoading) return;
    loginMutation.mutate();
  };

  return (
    <div className={css["box"]}>
      <button onClick={() => history.go(-1)} className={css["back"]}>
        ← Voltar
      </button>
      <h2>Entrar</h2>
      {isNewUser && (
        <div className={css["success"]}>
          Bem-vindo ao QuantBrasil! Se você não era cadastrado, recebeu seus
          dados de acesso por <b>email</b> e <b>WhatsApp</b>. Se já possuía uma
          conta, faça o login normalmente para aproveitar o <b>plano Premium</b>
          .
        </div>
      )}
      <form>
        <div className={css["input"]}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className={css["input"]}>
          <label>Senha</label>
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>
        <button
          disabled={loginMutation.isLoading}
          className={css["button"]}
          onClick={onClick}>
          {loginMutation.isLoading ? "Entrando..." : "Entrar"}
        </button>
        <div className={css["forgot"]}>
          <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
        </div>
        <div className={css["createAccount"]}>
          Não é cadastrado?{" "}
          <Link
            onClick={() =>
              logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                page: window.location.pathname,
                ctaText: "Criar conta",
              })
            }
            to="/cadastro">
            Criar conta
          </Link>
        </div>
      </form>
    </div>
  );
};

Login.propTypes = {
  isNewUser: PropTypes.bool,
};

export default Login;
