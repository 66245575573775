import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./bdrs-mais-liquidas.module.css";

const formatVolume = value => (value / 1000000).toFixed(2) + "M";

const SORT_OPTIONS = [
  { value: "variation desc", label: "Melhor Retorno" },
  { value: "variation asc", label: "Pior Retorno" },
  { value: "mm21 desc", label: "Maior Volume" },
  { value: "mm21 asc", label: "Menor Volume" },
];

const FILTER_OPTIONS = [
  { value: "all", label: "Todos" },
  { value: "better", label: "Melhores" },
  { value: "worse", label: "Piores" },
];

const BDR = () => {
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [filter, setFilter] = useState(FILTER_OPTIONS[0]);

  const data = useStaticQuery(graphql`
    query {
      tickers: allBdr {
        nodes {
          buildTime
          initial_price
          current_price
          ticker
          variation
          mm21
        }
      }
    }
  `);

  const ivvb11 = data.tickers.nodes.find(({ ticker }) => ticker === "IVVB11");
  const usd = data.tickers.nodes.find(({ ticker }) => ticker === "DOL$");

  const lastUpdated = data.tickers.nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const filtered = orderBy(
    data.tickers.nodes
      .map(node => ({
        ...node,
        diff: node.variation - ivvb11.variation,
      }))
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      })
      .filter(node => {
        if (filter.value === "all") return true;

        if (filter.value === "better")
          return node.variation >= ivvb11.variation;
        if (filter.value === "worse") return node.variation <= ivvb11.variation;

        // Shouldn't happen
        return true;
      })
      .filter(node => node.ticker !== "DOL$"),
    fieldToSort,
    directionToSort
  );

  return (
    <PublicLayout
      seoProps={{
        title: "BDRs Mais Líquidas",
        description:
          "Acompanhe o desempenho das BDRs mais líquidas da bolsa brasileira atualizado diariamente.",
      }}
      title="BDRs Mais Líquidas"
      ctaMessage="Crie sua conta para aproveitar o máximo do QuantBrasil.">
      <div>
        <p>
          A lista abaixo contém as BDRs mais líquidas da bolsa brasileira. O
          retorno é considerado a partir do dia <b>01/09/2020</b>, quando as
          BDRs ficaram disponíveis para o público geral.{" "}
          <Link to="/determinando-as-bdrs-mais-liquidas-do-ibovespa-utilizando-python">
            Determinando as BDRs Mais Líquidas do Ibovespa Utilizando Python
          </Link>{" "}
          <em>Nota: os valores podem diferir da sua plataforma de trading.</em>
        </p>
        <p>
          Dúvidas ou sugestões em relação à lista?{" "}
          <a href="https://t.me/quantbrasil" target="_blank" rel="noreferrer">
            Discuta-as na nossa comunidade no Telegram
          </a>
          !
        </p>
        <LastUpdated date={lastUpdated} isISO />
        <p>
          <b>Variação USD/BRL desde 01/09/2020</b>:{" "}
          {(usd?.variation * 100).toFixed(2)}%
        </p>
        <div className={css["filter"]}>
          <div>
            <label htmlFor="ranking-filter">Comparação com IVVB11</label>
            <Select
              id="ranking-filter"
              options={FILTER_OPTIONS}
              value={filter}
              onChange={option => setFilter(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "200px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="ticker-bdr">Ativo</label>
            <input
              id="ticker-bdr"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: AAPL34"
            />
          </div>
          <div>
            <label htmlFor="bdr-order">Ordenar por</label>
            <Select
              id="bdr-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th>Retorno</th>
                <th
                  data-tooltip-id="bdrs-tip"
                  data-tooltip-content={`Preço de fechamento em 01/09/2020.`}>
                  Preço Inicial<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="bdrs-tip"
                  data-tooltip-content={`Preço aferido na última atualização.`}>
                  Preço Atual<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="bdrs-tip"
                  data-tooltip-content={`Média móvel de 21 períodos do volume financeiro.`}>
                  Volume Médio<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="bdrs-tip"
                  data-tooltip-content={`Variação em pontos percentuais.`}>
                  Retorno vs IVVB11<sup>4</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr
                  key={node.ticker}
                  style={{
                    boxShadow:
                      node.ticker === "IVVB11"
                        ? "0 0 0 2px #033660 inset"
                        : undefined,
                    fontWeight: node.ticker === "IVVB11" ? "600" : undefined,
                  }}>
                  <td>{node.ticker}</td>
                  <td
                    style={{
                      color: node.variation >= 0 ? "#49ce8b" : "#d64242",
                      fontWeight: "600",
                    }}>
                    {(node.variation * 100).toFixed(2)}%
                  </td>
                  <td>{node.initial_price.toFixed(2)}</td>
                  <td>{node.current_price.toFixed(2)}</td>
                  <td>{formatVolume(node.mm21)}</td>
                  <td>{(node.diff * 100).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Preço de fechamento em 01/09/2020.
          </div>
          <div>
            <sup>2</sup> Preço aferido na última atualização.
          </div>
          <div>
            <sup>3</sup> Média móvel de 21 períodos do volume financeiro.
          </div>
          <div>
            <sup>4</sup> Variação em pontos percentuais.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="bdrs-tip" />
      </div>
    </PublicLayout>
  );
};

export default BDR;
