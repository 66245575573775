// extracted by mini-css-extract-plugin
export var button = "PlansPopup-module--button--bae23";
export var buttonOutline = "PlansPopup-module--buttonOutline--f3278";
export var buttons = "PlansPopup-module--buttons--5da4d";
export var close = "PlansPopup-module--close--60c67";
export var container = "PlansPopup-module--container--2dcd6";
export var content = "PlansPopup-module--content--9cf77";
export var countdownContainer = "PlansPopup-module--countdownContainer--41f40";
export var modal = "PlansPopup-module--modal--6888c";
export var modalAfter = "PlansPopup-module--modalAfter--61492";
export var modalBefore = "PlansPopup-module--modalBefore--cb6c7";
export var notNow = "PlansPopup-module--notNow--9e169";
export var overlay = "PlansPopup-module--overlay--b74dc";
export var part = "PlansPopup-module--part--0824d";
export var title = "PlansPopup-module--title--036d5";