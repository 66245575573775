import InstagramIcon from "../../icons/instagram.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import * as css from "./InstagramLink.module.css";

const InstagramLink = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        logAmplitudeEvent(EVENT_MAP.INSTAGRAM_BANNER_CLICKED, {
          page: window.location.pathname,
        })
      }
      href="https://instagram.com/quant_brasil"
      className={css["container"]}>
      <div className={css["icon"]}>
        <InstagramIcon />
        <div>Siga-nos no Instagram @quant_brasil</div>
      </div>
      <div className={css["copy"]}>
        <div>Siga-nos no Instagram @quant_brasil</div>
        <div>
          Receba diariamente novidades sobre a plataforma, dicas de análise
          quantitativa, sinais, backtests, estatísticas e muito mais!
        </div>
      </div>
    </a>
  );
};

export default InstagramLink;
