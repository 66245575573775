import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import * as css from "./Strategy.module.css";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import Footer from "../components/Footer";
import YouTubePlayer from "../components/common/YouTubePlayer";
import RunBacktestBanner from "../components/common/RunBacktestBanner";
import PremiumBanner from "../components/common/PremiumBanner";
import StrategyTags from "../components/common/StrategyTags";
import Breadcrumb from "../components/common/Breadcrumb";
import { useEffect } from "react";
import {
  EVENT_MAP,
  incrementProperty,
  logAmplitudeEvent,
} from "../utils/amplitude";
import { useAuth } from "../hooks/useAuth";

const renderVideo = node => (
  <div key={node.videoId}>
    <div className={css["ytTitle"]}>{node.title}</div>
    <div className={css["ytPublished"]}>Publicado {node.publishedAt}</div>
    <YouTubePlayer
      videoId={node.videoId}
      width={node.thumbnail.width}
      height={250}
    />
  </div>
);

const Strategy = ({ data: { markdownRemark: data }, pageContext }) => {
  const isPremiumStrategy = data.frontmatter.tags.includes("Premium");

  const { user } = useAuth();

  const isPremium = user?.isPremium;

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.STRATEGY_VIEWED, {
      name: data.frontmatter.name,
    });
    incrementProperty("strategiesViewed");
  }, [data]);

  return (
    <div>
      <SEO title={data.frontmatter.name} description={data.excerpt} />
      <Navbar />
      {isPremiumStrategy && !isPremium && (
        <PremiumBanner ctaMessage="Seja Premium para simular essa estratégia" />
      )}
      <div className={css["content"]}>
        <div className={css["breadcrumbContainer"]}>
          <Breadcrumb
            name={data.frontmatter.name}
            parents={[
              { path: "/backtests", name: "Backtests" },
              { path: "/estrategias", name: "Estratégias" },
            ]}
          />
        </div>
        <h1>{data.frontmatter.name}</h1>
        <div className={css["tagsContainer"]}>
          <StrategyTags tags={data.frontmatter.tags} />
        </div>
        <div
          className={css["html"]}
          dangerouslySetInnerHTML={{ __html: data.html }}
        />
        <RunBacktestBanner />
        {data.frontmatter.screenings && (
          <>
            <h2 className={css["subheader"]}>Screenings relacionados:</h2>
            <ul>
              {data.frontmatter.screenings.map(screening => (
                <li key={screening.url}>
                  <Link to={screening.url}>{screening.name}</Link>
                </li>
              ))}
            </ul>
          </>
        )}
        {data.frontmatter.posts && (
          <>
            <h2 className={css["subheader"]}>Posts relacionados:</h2>
            <ul>
              {data.frontmatter.posts.map(post => (
                <li key={post.url}>
                  <Link to={post.url}>{post.title}</Link>
                </li>
              ))}
            </ul>
          </>
        )}
        {pageContext.videos?.length > 0 && (
          <>
            <h2 className={css["subheader"]}>Vídeos relacionados:</h2>
            <div className={css["videos"]}>
              {pageContext.videos.map(renderVideo)}
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

Strategy.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Strategy;

export const query = graphql`
  query StrategyBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        type
        slug
      }
      frontmatter {
        name
        strategyId
        posts {
          title
          url
        }
        videos
        tags
        screenings {
          name
          url
        }
      }
      html
      excerpt
    }
  }
`;
