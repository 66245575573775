import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation } from "react-query";
import Select from "react-select";
import { toast } from "react-toastify";
import { useAPI } from "../../hooks/useAPI";
import { useAssets } from "../../hooks/useAssets";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import DeletePortfolio from "./DeletePortfolio";
import PortfolioAssets from "./PortfolioAssets";
import PortfolioName from "./PortfolioName";
import * as css from "./SelectedPortfolio.module.css";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const getStyles = () => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: "#49ce8b",
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: "#fff",
    fontWeight: 500,
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: "#fff",
    ":hover": {
      backgroundColor: "#328a5e",
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "hsl(0, 0%, 100%)" : "rgb(51, 51, 51)",
  }),
});

const SelectedPortfolio = ({ portfolio, fetchPortfolios, setSelectedId }) => {
  const { asOptions: allAssets } = useAssets({ requirePremium: false });
  const [assets, setAssets] = useState([]);

  const assetsInPortfolio = portfolio.symbols;

  const callAPI = useAPI({ withCredentials: true });

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "ADD_ASSETS_TO_PORTFOLIO"
  );

  const mutation = useMutation(
    () =>
      callAPI(`/api/portfolio/${portfolio.id}/add`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ assets: assets.map(({ value }) => value) }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        toast.success("Ativos adicionados com sucesso!");
        logAmplitudeEvent(EVENT_MAP.PORTFOLIO_CHANGED, {
          action: "add",
          assets: assets.map(({ label }) => label),
        });
        fetchPortfolios();
        setAssets([]);
      },
    }
  );

  return (
    <div className={css["container"]}>
      <PortfolioName portfolio={portfolio} fetchPortfolios={fetchPortfolios} />
      <div className={css["content"]}>
        <Popover.Root open={showStep}>
          <div>
            <label htmlFor="assets-select">Adicionar Ativos</label>
            <div className={css["select"]}>
              <Select
                id="assets-select"
                options={allAssets.filter(
                  ({ label }) => !assetsInPortfolio.includes(label)
                )}
                value={assets}
                onChange={option => setAssets(option)}
                styles={getStyles()}
                isMulti
                placeholder="Selecione os ativos"
              />
              <Popover.Trigger asChild>
                <button
                  onClick={() => {
                    markStepAsCompleted();
                    mutation.mutate();
                  }}
                  disabled={mutation.isLoading || assets.length === 0}>
                  Adicionar
                </button>
              </Popover.Trigger>
              {showStep && (
                <OnboardingStep
                  step={step}
                  onConfirm={markStepAsCompleted}
                  onDismiss={markStepAsCompleted}
                />
              )}
            </div>
          </div>
        </Popover.Root>
        <PortfolioAssets
          portfolio={portfolio}
          fetchPortfolios={fetchPortfolios}
        />
      </div>
      <DeletePortfolio
        portfolio={portfolio}
        onDelete={() => {
          logAmplitudeEvent(EVENT_MAP.PORTFOLIO_DELETED, { ...portfolio });
          setSelectedId(null);
          fetchPortfolios();
          toast.success("Portfólio deletado com sucesso!");
        }}
      />
    </div>
  );
};

SelectedPortfolio.propTypes = {
  portfolio: PropTypes.object.isRequired,
  fetchPortfolios: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired,
};

export default SelectedPortfolio;
