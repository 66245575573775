import * as css from "./OnboardingStep.module.css";
import * as Popover from "@radix-ui/react-popover";
import PropTypes from "prop-types";
import CloseIcon from "../../icons/close.svg";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";
import { useMedia } from "react-use";

const OnboardingStep = ({
  step,
  onConfirm,
  onDismiss,
  side,
  align = "center",
  sideOffset = 4,
  alignOffset = 0,
}) => {
  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ONBOARDING_STEP_VIEWED, step);
  }, [step]);

  const isMobile = useMedia("(max-width: 700px)");

  return (
    <Popover.Portal>
      <Popover.Content
        className={css["content"]}
        sideOffset={sideOffset}
        side={isMobile ? "bottom" : side}
        collisionPadding={20}
        align={align}
        alignOffset={alignOffset}>
        <Popover.Close
          className={css["close"]}
          onClick={() => {
            logAmplitudeEvent(EVENT_MAP.ONBOARDING_STEP_DISMISSED, step);
            onDismiss();
          }}>
          <CloseIcon aria-hidden />
        </Popover.Close>
        <Popover.Arrow className={css["arrow"]} width={20} height={15} />
        <div className={css["popoverTitle"]}>{step.title}</div>
        <div
          className={css["popoverContent"]}
          dangerouslySetInnerHTML={{ __html: step.description }}
        />
        <div className={css["buttonsContainer"]}>
          <button
            onClick={() => {
              logAmplitudeEvent(EVENT_MAP.ONBOARDING_STEP_COMPLETED, step);
              onConfirm();
            }}
            className={css["primary"]}>
            {step.confirm_text}
          </button>
          {step.dismiss_text && (
            <button
              onClick={() => {
                logAmplitudeEvent(EVENT_MAP.ONBOARDING_STEP_DISMISSED, step);
                onDismiss();
              }}
              className={css["dismiss"]}>
              {step.dismiss_text}
            </button>
          )}
        </div>
      </Popover.Content>
    </Popover.Portal>
  );
};

OnboardingStep.propTypes = {
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    confirm_text: PropTypes.string.isRequired,
    dismiss_text: PropTypes.string,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  side: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  sideOffset: PropTypes.number,
  align: PropTypes.oneOf(["start", "center", "end"]),
  alignOffset: PropTypes.number,
};

export default OnboardingStep;
