import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import Spinner from "../components/common/Spinner";
import UpgradeSection from "../components/common/UpgradeSection";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./bandas-de-bollinger.module.css";
import InfoIcon from "../icons/info-question-circle.svg";
import StarIcon from "../icons/star.svg";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const PORTFOLIO_ID = process.env.GATSBY_TOP_200_PORTFOLIO_ID;

const EDEN_OPTIONS = [
  { value: "all_eden", label: "Todos" },
  { value: "long_eden", label: "Compra" },
  { value: "short_eden", label: "Venda" },
];

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "H1", label: "60 Minutos" },
  { value: "H2", label: "120 Minutos" },
  { value: "W1", label: "Semanal" },
];

const InsideBar = () => {
  const { isLoggedIn, user } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["W1", "H1", "H2"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [eden, setEden] = useState(EDEN_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [isShark, setIsShark] = useState(false);
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [timeframe, setTimeframe] = useState(
    PROTECTED_TIMEFRAME_OPTIONS.find(({ value }) => value === "D1")
  );

  const staticData = useStaticQuery(graphql`
    query {
      data: allInsideBar(filter: { inside_bar: { eq: true } }) {
        nodes {
          ticker
          shark
          price
          inside_bar
          long_eden
          short_eden
          buildTime
        }
      }
      variations: allVariation(filter: { window: { eq: "DAYS_90" } }) {
        nodes {
          ticker
          variation
        }
      }
    }
  `);

  const [data, setData] = useState(staticData);

  const callAPI = useAPI({ withCredentials: true });

  const { data: insideBarData, isLoading } = useQuery(
    ["insideBar", timeframe],
    ({ queryKey }) => {
      const timeframeId = queryKey[1].value;
      if (timeframeId === "D1") {
        setData(staticData);
        return;
      }
      return callAPI(
        `/api/portfolio/${PORTFOLIO_ID}/insidebar?timeframe=${timeframeId}`
      );
    }
  );

  useEffect(() => {
    if (insideBarData) {
      const newData = { data: { nodes: [] } };
      Object.keys(insideBarData).map(symbol => {
        const d = insideBarData[symbol];
        if (d.inside_bar)
          newData.data.nodes.push({
            ...d,
            ticker: symbol,
            buildTime: d.last_updated.replace("UTC", "Z"),
          });
      });
      setData(newData);
    }
  }, [insideBarData]);

  const lastUpdated = data.data.nodes[0]?.buildTime;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data.data.nodes
      .map(node => ({
        ...node,
        order: staticData.variations.nodes.findIndex(
          n => n.ticker === node.ticker
        ),
      }))
      .filter(node => {
        if (eden.value === "long_eden") return node.long_eden === 1;
        if (eden.value === "short_eden") return node.short_eden === 1;
        return true;
      })
      .filter(node => {
        if (isShark) return node.shark;
        return true;
      })
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    "order",
    "asc"
  );

  useEffect(() => {
    trackScreeningViewed({ key: "insideBar" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Inside Bar",
        description:
          "Screening dos ativos que estão formando um Inside Bar ou Shark, em tempo real e em diversos timeframes.",
      }}
      title="Inside Bar"
      ctaMessage="Crie sua conta para visualizar os ativos formando o Inside Bar em diferentes timeframes.">
      <div>
        <p>
          Um <b>Inside Bar</b> é um candle completamente contido no anterior, ou
          seja, tendo uma mínima maior e uma máxima menor.
        </p>
        <p>
          A lista abaixo contém os ativos formando o padrão <em>Inside Bar</em>,
          ordenados pela sua <em>força relativa</em> nos últimos 90 dias. É
          possível filtrar por ativos no{" "}
          <Link to="/eden-dos-traders">Éden dos Traders</Link> e os formando um{" "}
          <em>shark</em> (dois ou mais inside bars em sequência).
          {!isLoggedIn && (
            <span>
              {" "}
              <Link
                to="/cadastro"
                onClick={() =>
                  logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                    page: window.location.pathname,
                    ctaText:
                      "Crie sua conta para rodar o backtest do Inside Bar.",
                  })
                }>
                Crie sua conta para rodar o backtest do Inside Bar.
              </Link>{" "}
            </span>
          )}
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="ib-tf">Timeframe</label>
            <Select
              id="ib-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => {
                if (option.value !== timeframe.value) {
                  logAmplitudeEvent(EVENT_MAP.INSIDE_BAR_SELECTED, {
                    timeframe: option.value,
                  });
                }
                setTimeframe(option);
              }}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="eden-type">Éden dos Traders</label>
            <Select
              id="eden-type"
              options={EDEN_OPTIONS}
              value={eden}
              onChange={option => setEden(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="beta-period">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="shark"
                type="checkbox"
                checked={isShark}
                onChange={e => {
                  setIsShark(e.target.checked);
                }}
              />
              <label htmlFor="shark">
                Shark{" "}
                <InfoIcon
                  data-tooltip-id="ii-tip"
                  data-tooltip-content="Dois ou mais inside bars em sequência."
                />
              </label>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        {isLoading && isLoggedIn ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Robôs trabalhando..." />
          </div>
        ) : (
          <>
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th
                      data-tooltip-id="ii-tip"
                      data-tooltip-content={`Preço aferido na última atualização.`}>
                      Preço Atual<sup>1</sup>
                    </th>
                    <th
                      data-tooltip-id="ii-tip"
                      data-tooltip-content="O Éden dos Traders é quando a MME8 e MME80 estão na mesma direção.">
                      Éden dos Traders<sup>2</sup>
                    </th>
                    <th
                      data-tooltip-id="ii-tip"
                      data-tooltip-content="Dois ou mais inside bars em sequência.">
                      Shark<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="ii-tip"
                      data-tooltip-content="Rode o backtest no simulador de estratégias.">
                      Estatísticas<sup>4</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>{node.ticker}</td>
                      <td>{node.price.toFixed(2)}</td>
                      <td>
                        {node.long_eden
                          ? "Compra"
                          : node.short_eden
                          ? "Venda"
                          : "Não"}
                      </td>
                      <td>{node.shark ? "Sim" : "Não"}</td>
                      <td>
                        <div className={css["buttonContainer"]}>
                          <Link
                            onClick={() =>
                              logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                                ticker: node.ticker,
                                page: window.location.pathname,
                              })
                            }
                            to={`/backtests?strategy=${
                              node.short_eden
                                ? `short_${node.shark ? "shark" : "insidebar"}`
                                : `long_${node.shark ? "shark" : "insidebar"}`
                            }&ticker=${node.ticker}&timeframeId=${
                              timeframe.value
                            }&tradersEden=${
                              node.long_eden || node.short_eden
                            }`}>
                            Rodar Backtest
                          </Link>
                          {Boolean(node.short_eden) && (
                            <StarIcon className={css["star"]} aria-hidden />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço aferido na última atualização.
              </div>
              <div>
                <sup>2</sup> O Éden dos Traders é quando a MME8 e MME80 estão na
                mesma direção.
              </div>
              <div>
                <sup>3</sup> Dois ou mais inside bars em sequência.
              </div>
              <div>
                <sup>4</sup> Rode o backtest no simulador de estratégias.
              </div>
            </div>
            <Tooltip id="ii-tip" />
          </>
        )}
        <InstagramLink />
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear todas as funcionalidades do QuantBrasil!" />
        )}
      </div>
    </PublicLayout>
  );
};

export default InsideBar;
