import { Link } from "gatsby";
import PropTypes from "prop-types";
import * as css from "./UpgradeButton.module.css";
import StarIcon from "../../icons/star.svg";
import UnlockIcon from "../../icons/unlock.svg";
import { trackPlansButtonClicked } from "../../utils/amplitude";

const UpgradeButton = ({
  buttonText = "Upgrade",
  icon = "star",
  eventProps = {},
}) => {
  const IconComponent =
    icon === "star" ? StarIcon : icon === "unlock" ? UnlockIcon : null;
  return (
    <Link
      className={css["button"]}
      onClick={() =>
        trackPlansButtonClicked({ ...eventProps, ctaText: buttonText })
      }
      to="/planos">
      <IconComponent />
      {buttonText}
    </Link>
  );
};

UpgradeButton.propTypes = {
  icon: PropTypes.oneOf(["star", "unlock"]),
  buttonText: PropTypes.string,
  eventProps: PropTypes.object,
};

export default UpgradeButton;
