import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import { useAuth } from "../../hooks/useAuth";
import Select from "react-select";
import PropTypes from "prop-types";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import { parseStatus } from "../topBacktests/UserBatchesFilter";
import { groupBy } from "lodash";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";

const parseOptions = options => {
  const grouped = groupBy(options, "status");
  return Object.keys(grouped).map(status => ({
    label: parseStatus(status),
    options: grouped[status].map(o => {
      return {
        label: `Batch criado ${DateTime.fromISO(o.created_at)
          .setLocale("pt-BR")
          .toLocaleString(DateTime.DATETIME_MED)}`,
        value: o.id,
        isDisabled: status !== "completed",
      };
    }),
  }));
};

const CointegrationBatchesFilter = ({ selectedId, setSelectedId }) => {
  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const { data, isLoading } = useQuery(
    ["cointegrationBatches", user.userId],
    () => callAPI(`/api/cointegration/me`),
    {
      onError: error => showError(error),
    }
  );

  const getOptions = useCallback(() => {
    if (!data) {
      return [];
    }

    return parseOptions(data);
  }, [data]);

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "CHANGE_COINTEGRATION_BATCH"
  );

  const options = useMemo(() => getOptions(), [getOptions]);

  return (
    <Popover.Root open={showStep}>
      <Popover.Trigger asChild>
        <div style={{ width: "100%" }}>
          <label htmlFor="batch-select">Batch</label>
          <Select
            id="batch-select"
            isLoading={isLoading}
            options={options}
            placeholder="Selecione um batch"
            value={options.find(d => d.id === selectedId)}
            onChange={option => {
              setSelectedId(option.value);
            }}
            styles={{
              indicatorSeparator: () => ({
                display: "none",
              }),
            }}
          />
        </div>
      </Popover.Trigger>
      {showStep && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

CointegrationBatchesFilter.propTypes = {
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
};

export default CointegrationBatchesFilter;
