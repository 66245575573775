import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import * as css from "./MonitorBanner.module.css";
import { WhatsappIcon } from "react-share";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "gatsby";
import SliderDiv from "../common/SliderDiv";
import CloseIcon from "../../icons/close.svg";
import { useState } from "react";
import { QueryClient } from "react-query";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const queryClient = new QueryClient();

const MonitorBanner = ({
  backtestId,
  setupId,
  timeframeId,
  ticker,
  alertId,
  alertStatus,
}) => {
  const [isVisible, setVisible] = useState(true);

  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const mutation = useMutation(
    () =>
      callAPI(`/api/alert/backtest/${backtestId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("userAlerts");
        queryClient.invalidateQueries(["backtest", backtestId]);
      },
      onError: err => {
        showError(err);
        logAmplitudeEvent(EVENT_MAP.SAVE_ALERT_ERROR, { message: err.message });
      },
    }
  );

  const getHeader = () => {
    if (mutation.isSuccess) return "Alerta salvo com sucesso!";
    if (alertStatus === "active") return "Alerta ativo!";
    if (alertStatus === "inactive") return "Alerta inativo";
    return "Quer monitorar essa estratégia?";
  };

  const getContent = () => {
    if (mutation.isSuccess)
      return (
        <SliderDiv>
          Você receberá notificações em horários específicos quando o sinal for
          identificado. <Link to="/app/alertas/monitor">Ver alertas</Link>.
        </SliderDiv>
      );
    if (alertStatus === "active")
      return (
        <div>
          Você receberá notificações em horários específicos quando o sinal for
          identificado. <Link to="/app/alertas/monitor">Ver alertas</Link>.
        </div>
      );
    if (alertStatus === "inactive")
      return (
        <div>
          Ative o alerta para receber notificações.{" "}
          <Link to="/app/alertas/monitor">Gerenciar alertas</Link>.
        </div>
      );
    return (
      <div>
        Seja avisado no <span>WhatsApp</span> toda vez que esse sinal ocorrer.
      </div>
    );
  };

  const getCta = () => {
    if (!user.phone)
      return (
        <Link
          className={css["button"]}
          to="/app/settings"
          onClick={() =>
            logAmplitudeEvent(EVENT_MAP.ADD_PHONE_BUTTON_CLICKED, {
              ctaText: "Salvar WhatsApp",
            })
          }>
          Salvar WhatsApp
        </Link>
      );

    if (mutation.isSuccess || Boolean(alertId)) return null;

    return (
      <button
        disabled={mutation.isLoading}
        className={css["button"]}
        onClick={() => {
          mutation.mutate();
          logAmplitudeEvent(EVENT_MAP.ALERT_SAVED, {
            backtestId,
            setupId,
            timeframeId,
            ticker,
          });
        }}>
        Monitorar
      </button>
    );
  };

  if (!isVisible) return null;

  return (
    <div
      className={`${css["container"]}${
        alertStatus === "inactive" ? " " + css["inactive"] : ""
      }`}>
      <div>
        <WhatsappIcon size={48} round={true} />
      </div>
      <div>
        <div className={css["text"]}>
          <div>{getHeader()}</div>
          {getContent()}
        </div>
        {getCta()}
        {mutation.isSuccess && (
          <button className={css["close"]} onClick={() => setVisible(false)}>
            <CloseIcon />
          </button>
        )}
      </div>
    </div>
  );
};

MonitorBanner.propTypes = {
  backtestId: PropTypes.number.isRequired,
  setupId: PropTypes.number.isRequired,
  timeframeId: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  alertId: PropTypes.number,
  alertStatus: PropTypes.oneOf(["active", "inactive"]),
};

export default MonitorBanner;
