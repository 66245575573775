import { useQuery, useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import * as css from "./EmailNotifications.module.css";
import Toggle from "react-toggle";
import { useAuth } from "../../hooks/useAuth";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const NOTIFICATION_MAP = {
  ALERTS: {
    title: "Alertas",
    description: (
      <span>
        Receba alertas sobre o mercado.{" "}
        <em>Não substitui os alertas no WhatsApp.</em>
      </span>
    ),
  },
  APP_UPDATES: {
    title: "Atualizações",
    description: "Seja notificado sobre novidades, atualizações e melhorias.",
  },
  CONTENT: {
    title: "Conteúdo",
    description: "Receba artigos, posts e vídeos de análises quantitativas.",
  },
  PROMOTIONS: {
    title: "Promoções",
    description: "Seja notificado sobre promoções e descontos exclusivos.",
  },
};

const EmailNotifications = () => {
  const { user } = useAuth();

  const isPremium = user.isPremium === true;

  const callAPI = useAPI({ withCredentials: true });

  const { isLoading, data } = useQuery(["userNotifications"], () =>
    callAPI(`/api/notification/`)
  );

  const mutation = useMutation(({ notificationTypeId, enabled }) =>
    callAPI(`/api/notification/${notificationTypeId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        enabled,
      }),
    })
  );

  const renderToggles = () => {
    return (
      <div className={css["notificationsContainer"]}>
        <div>
          <label className={css["toggle"]}>
            <div className={css["notificationContent"]}>
              <div className={css["notificationTitle"]}>
                Premium Newsletter ⭐
              </div>
              <div className={css["notificationDescription"]}>
                Newsletter exclusiva para assinantes <b>Premium</b>.
              </div>
            </div>
            <Toggle defaultChecked={isPremium} icons={false} disabled />
          </label>
        </div>
        {data
          .sort((a, b) => a.notification_type_id - b.notification_type_id)
          .map(({ id, notification_type_id, enabled }) => (
            <div key={id}>
              <label className={css["toggle"]}>
                <div className={css["notificationContent"]}>
                  <div className={css["notificationTitle"]}>
                    {NOTIFICATION_MAP[notification_type_id].title}
                  </div>
                  <div className={css["notificationDescription"]}>
                    {NOTIFICATION_MAP[notification_type_id].description}
                  </div>
                </div>
                <Toggle
                  defaultChecked={enabled}
                  onChange={e => {
                    const payload = {
                      notificationTypeId: notification_type_id,
                      enabled: e.target.checked,
                    };
                    logAmplitudeEvent(EVENT_MAP.NOTIFICATION_UPDATED, payload);
                    mutation.mutate(payload);
                  }}
                  icons={false}
                />
              </label>
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className={css["container"]}>
      <div className={css["title"]}>Notificações via email</div>
      <div className={css["hint"]}>
        Configure que tipo de emails você deseja receber. Você continuará
        recebendo emails transacionais normalmente.
      </div>
      {isLoading ? (
        <div className={css["loading"]}>Carregando...</div>
      ) : (
        renderToggles()
      )}
    </div>
  );
};

export default EmailNotifications;
