import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import UpgradeSection from "../common/UpgradeSection";
import BetaChart from "./BetaChart";
import * as css from "./BetaResult.module.css";

const BetaResult = ({ data }) => {
  const { isLoggedIn, user } = useAuth();

  const showUpgrade = isLoggedIn && !user.isPremium;

  return (
    <div>
      <div className={css["betaContainer"]}>
        Beta da Carteira: <span>{data.beta.toFixed(2)}</span>
      </div>
      <p className={css["hint"]}>
        O portfólio tem uma correlação de <span>{data.corr.toFixed(2)}</span>{" "}
        com o Ibovespa. A vol. diária é{" "}
        <span>{(data.std * 100).toFixed(2)}%</span> e a vol. anual é{" "}
        <span>{(data.std * Math.sqrt(252) * 100).toFixed(2)}%</span>.
      </p>
      <BetaChart data={data.data} portfolioBeta={data.beta} />
      {showUpgrade && (
        <UpgradeSection copy="Faça o upgrade de sua conta para calcular o beta com maior confiança utilizando períodos mais longos." />
      )}
    </div>
  );
};

BetaResult.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BetaResult;
