import { useMutation } from "react-query";
import { useMedia } from "react-use";
import scrollToElement from "scroll-to-element";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./SupplyDemandScreeningContainer.module.css";
import { useState } from "react";
import { getProjectionPeriods } from "./SupplyDemandFilter";
import LogoIcon from "../../images/logo.svg";
import { Tooltip } from "react-tooltip";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";
import SupplyDemandScreeningFilter from "./SupplyDemandScreeningFilter";
import SupplyDemandScreeningResult from "./SupplyDemandScreeningResult";
import UpgradeSection from "../common/UpgradeSection";

const SupplyDemandScreeningContainer = () => {
  const [portfolio, setPortfolio] = useState(null);
  const [minLevel, setMinLevel] = useState(-2);
  const [maxLevel, setMaxLevel] = useState(2);
  const [frequency, setFrequency] = useState(getProjectionPeriods()[0]);

  const isMobile = useMedia("(max-width: 700px)");

  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const isPremium = Boolean(user?.isPremium);

  const { mutate: fetchData, isLoading, data, isSuccess, isIdle } = useMutation(
    () =>
      callAPI(`/api/portfolio/${portfolio.value}/supply_demand`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          min_level: minLevel,
          max_level: maxLevel,
          frequency: frequency.value,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        if (isMobile) {
          setTimeout(() => {
            scrollToElement("#supply-demand-result");
          }, 0);
        }
      },
    }
  );

  const onExecuted = () => {
    fetchData();
    logAmplitudeEvent(EVENT_MAP.SUPPLY_DEMAND_SCREENING_VIEWED, {
      minLevel,
      maxLevel,
      frequency: frequency.value,
    });
    incrementProperty("supplyDemandScreeningViewed");
  };

  const title = "Screening Suportes e Resistências";

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear o screening em todos os ativos e períodos."
      seoProps={{
        title,
        description:
          "Confira rapidamente os ativos mais próximos de cada nível de Suporte e Resistência. O cálculo é feito para ações, ETFs, criptos, BDRs e futuros baseado em suas volatilidades.",
      }}>
      <div>
        <h1 className={css["header"]}>{title}</h1>
        <p>
          Busque os ativos mais próximos das suas zonas de suporte e
          resistência. Um nível positivo é considerado uma{" "}
          <span
            className={css["hint"]}
            data-tooltip-content="Zonas de resistência são níveis de preços onde a pressão de venda é maior que a pressão de compra, fazendo com que o preço tenha dificuldade de subir."
            data-tooltip-id="supply-tip">
            resistência
          </span>{" "}
          , enquanto um nível negativo é considerado um{" "}
          <span
            className={css["hint"]}
            data-tooltip-content="Zonas de suporte são níveis de preços onde a pressão de compra é maior que a pressão de venda, fazendo com que o preço tenha dificuldade de cair."
            data-tooltip-id="demand-tip">
            suporte
          </span>{" "}
          . O screening retornará a <em>distância</em> atual do ativo para cada
          nível escolhido. Utiliza-se <em>20 candles</em> para o cálculo da
          volatilidade.
        </p>
        <div className={css["filter"]}>
          <SupplyDemandScreeningFilter
            onClick={onExecuted}
            portfolio={portfolio}
            setPortfolio={setPortfolio}
            minLevel={minLevel}
            setMinLevel={setMinLevel}
            maxLevel={maxLevel}
            setMaxLevel={setMaxLevel}
            frequency={frequency}
            setFrequency={setFrequency}
            isPremium={isPremium}
            isLoading={isLoading}
          />
        </div>
        <div className={css["panel"]}>
          <div className={css["results"]}>
            {isLoading && <Spinner type="Rings" text="Robôs trabalhando..." />}
            {data && isSuccess && (
              <>
                <SupplyDemandScreeningResult
                  data={data}
                  frequency={frequency}
                  minLevel={minLevel}
                  maxLevel={maxLevel}
                />
                {!isPremium && (
                  <div className={css["ctaContainer"]}>
                    <UpgradeSection copy="Seja Premium para desbloquear o screening em todos os ativos monitorados pelo QuantBrasil, além de refinar a análise alterando os níveis de interesse e o período de projeção." />
                  </div>
                )}
              </>
            )}
            {isIdle && (
              <div className={css["welcomeContainer"]}>
                <img className={css["logo"]} src={LogoIcon} />
                <div>
                  Selecione o portfólio, o período de projeção, e os níveis
                  máximo e mínimo de suporte e/ou resistência que deseja para
                  ordenar os ativos.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Tooltip id="vol-tip" clickable delayHide={100} />
      <Tooltip id="supply-tip" clickable delayHide={100} />
      <Tooltip id="demand-tip" clickable delayHide={100} />
    </AppLayout>
  );
};

export default SupplyDemandScreeningContainer;
