import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useMedia, useSessionStorage } from "react-use";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { mapValues, useBacktestFilter } from "../../hooks/useBacktestFilter";
import LogoIcon from "../../images/logo.svg";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
  addUnique,
} from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import BacktestFilter from "./BacktestFilter";
import BacktestPopup from "./BacktestPopup";
import BacktestResult from "./BacktestResult";
import * as css from "./BacktestsContainer.module.css";
import HelpButton from "./HelpModal";

export const SHOW_POPUP_AFTER_SEQUENCE = 3;

const BacktestsContainer = () => {
  const {
    state,
    getValue,
    setValue,
    reset: resetFilter,
    isValid,
    errors,
  } = useBacktestFilter();
  const isMobile = useMedia("(max-width: 700px)");

  const [sequence, setSequence] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [hasSeenPopup, setSeenPopup] = useSessionStorage(
    "seenBacktestPopup",
    false
  );

  const [isFavorite, setFavorite] = useState(false);

  const onToggleFavorite = () => setFavorite(!isFavorite);

  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn, user } = useAuth();

  const showUpgrade = isLoggedIn && !user.isPremium;

  const onBacktestExecuted = () => {
    mutation.mutate();

    // A new backtest can never start saved
    setFavorite(false);

    // Unnest parameters
    const mappedValues = mapValues(state);
    const strategy = getValue("strategy");
    const { parameters, ...rest } = mappedValues;
    const obj = { ...rest, ...parameters, strategy: strategy?.value };
    logAmplitudeEvent(EVENT_MAP.BACKTEST_EXECUTED, obj);
    incrementProperty("backtestsExecuted");
    addUnique("strategiesOfInterest", strategy?.value);
    addUnique("assetsOfInterest", obj.ticker);
  };

  const mutation = useMutation(
    () =>
      callAPI(`/api/backtest/${state.strategy.value}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mapValues(state)),
      }),
    {
      onError: showError,
      onSuccess: () => setSequence(sequence + 1),
    }
  );

  const canSeePopup =
    sequence === SHOW_POPUP_AFTER_SEQUENCE &&
    !hasSeenPopup &&
    !user.isPremium &&
    Boolean(user.userId);

  const { data } = useQuery(
    ["canTrial", user.userId],
    () => callAPI(`/api/payment/trial`),
    { enabled: canSeePopup, retry: false }
  );

  useEffect(() => {
    if (canSeePopup && Boolean(data?.is_allowed)) {
      setShowPopup(true);
      setSeenPopup(true);
      logAmplitudeEvent(EVENT_MAP.BACKTEST_PREMIUM_POPUP_VIEWED, { sequence });
      incrementProperty("backtestPopupViewed");
    }
  }, [canSeePopup, data, sequence, setSeenPopup]);

  const title = isLoggedIn ? "Simulador de Estratégias" : "Backtests";

  return (
    <AppLayout
      ctaMessage="Crie sua conta para desbloquear todas as estratégias"
      premiumMessage="Seja Premium para desbloquear todos os ativos, timeframes e estratégias"
      seoProps={{
        title,
        description:
          "Simule estratégias para diversos ativos, timeframes e parâmetros.",
      }}>
      <div>
        <h1 className={css["header"]}>
          {title} <HelpButton />
        </h1>
        <div className={css["panel"]}>
          <BacktestFilter
            onClick={onBacktestExecuted}
            isLoading={mutation.isLoading}
            state={state}
            setValue={setValue}
            getValue={getValue}
            resetFilter={resetFilter}
            isValid={isValid}
            errors={errors}
          />
          <div className={css["results"]}>
            {mutation.isLoading && (
              <Spinner type="Rings" text="Robôs trabalhando..." />
            )}
            {mutation.data && mutation.isSuccess && (
              <BacktestResult
                result={mutation.data}
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                showUpgrade={showUpgrade}
                scrollIntoView={isMobile}
              />
            )}
            {mutation.isIdle && (
              <div className={css["welcomeContainer"]}>
                <div>
                  Bem vindo ao <b>simulador de estratégias</b> do QuantBrasil!
                </div>
                <img className={css["logo"]} src={LogoIcon} />
                {isLoggedIn && (
                  <div>
                    Selecione a estratégia e seus parâmetros no filtro ao lado.
                    O resultado do backtest aparecerá aqui.
                  </div>
                )}
                {!isLoggedIn && (
                  <div className={css["ctaContainer"]}>
                    <RegisterButton copy="Crie sua conta para desbloquear todas as funcionalidades." />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <BacktestPopup
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        sequence={sequence}
      />
    </AppLayout>
  );
};

export default BacktestsContainer;
