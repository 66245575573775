import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { memo, useMemo, useState } from "react";
import { useMedia } from "react-use";
import { useDebounce } from "use-debounce";
import * as css from "./ZscoreChart.module.css";
import ZscoreTooltip from "./ZscoreTooltip";

const formatData = data => {
  let dataPoints = [];
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    dataPoints.push({ x: obj.date, y: obj.zscore });
  }
  return dataPoints;
};

const ZscoreChart = ({ data }) => {
  const isMobile = useMedia("(max-width: 700px)");

  const [zscore, setZscore] = useState(2);
  const [debouncedZscore] = useDebounce(zscore, 300);

  const points = useMemo(() => formatData(data), [data]);

  const maxValue = Math.max(
    Math.ceil(Math.max(...points.map(({ y }) => y))),
    Math.ceil(-Math.min(...points.map(({ y }) => y))),
    debouncedZscore
  );
  const minValue = -maxValue;

  const upperLimit = points.map(p => ({ ...p, y: debouncedZscore }));
  const lowerLimit = points.map(p => ({ ...p, y: -debouncedZscore }));

  const chartData = [
    {
      id: "Zscore",
      color: "#033660",
      data: points,
    },
    {
      id: "upperLimit",
      color: "darkmagenta",
      data: upperLimit,
    },
    {
      id: "lowerLimit",
      color: "#49ce8b",
      data: lowerLimit,
    },
  ];

  return (
    <div>
      <div className={css["input"]}>
        <label htmlFor="zscore-dp">Desvios-padrão</label>
        <input
          id="zscore-dp"
          type="number"
          min={1}
          max={5}
          value={zscore}
          onChange={e => setZscore(Math.min(e.target.value, 5))}
        />
      </div>
      <div
        style={{
          height: "500px",
          backgroundColor: "#fff",
        }}>
        <ResponsiveLine
          data={chartData}
          margin={{
            top: 20,
            right: isMobile ? 5 : 20,
            bottom: 50,
            left: 30,
          }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          tooltip={input => <ZscoreTooltip data={input.point.data} />}
          yScale={{
            type: "linear",
            min: minValue,
            max: maxValue,
          }}
          axisLeft={{
            tickPadding: 8,
          }}
          axisBottom={{
            format: "%b %y",
            tickRotation: isMobile ? 60 : 0,
          }}
          enableArea={true}
          areaOpacity={0.07}
          enableSlices={false}
          colors={{ datum: "color" }}
          pointSize={0}
          pointLabelYOffset={-12}
          useMesh={true}
          motionConfig="gentle"
        />
      </div>
    </div>
  );
};

ZscoreChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default memo(ZscoreChart);
