import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { mapTimeframe, showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./RobotsContainer.module.css";
import { useEffect } from "react";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { getAssetLabel } from "../../hooks/useAssets";
import TradeSquare from "./TradeSquare";
import StrategyDetails from "../common/StrategyDetails";
import { useState } from "react";
import Modal from "../common/Modal";
import FeedBacktest from "../feed/FeedBacktest";
import PerformanceIcon from "../../icons/performance.svg";
import ViewIcon from "../../icons/view.svg";
import { Tooltip } from "react-tooltip";
import { Link } from "gatsby";
import BetaTitle from "../common/BetaTitle";
import Breadcrumb from "../common/Breadcrumb";
import { getColor } from "../backtests/BacktestResult";
import HowButton from "./HowButton";
import RobotsTrades from "./RobotsTrades";

const mapStatus = robot => {
  const status = robot.status;
  let progress;
  switch (status) {
    case "AVAILABLE":
      return "Disponível";
    case "BUSY":
      progress = (robot.last_five_trades_profit[0].pct_profit * 100).toFixed(2);
      return (
        <span>
          Em execução{" "}
          <span
            style={{
              ...getColor(progress),
              fontSize: "12px",
              fontWeight: 500,
            }}>
            ({progress}%)
          </span>
        </span>
      );
    default:
      return status;
  }
};

const RobotsContainer = () => {
  const callAPI = useAPI({ withCredentials: true });

  const [selectedBacktestId, setSelectedBacktestId] = useState(null);

  const {
    data: robotsData,
    isLoading: robotsLoading,
    isSuccess: robotsSuccess,
  } = useQuery(["automatedRobots"], () => callAPI(`/api/automated/robot`), {
    onError: showError,
  });

  const startDate = "2023-11-30";
  // endDate should be today
  const endDate = new Date().toISOString().split("T")[0];

  const {
    data: returnsData,
    isSuccess: returnsSuccess,
    isLoading: returnsLoading,
  } = useQuery(
    ["robotsTrades", startDate],
    () =>
      callAPI(
        `/api/automated/robot/returns?start_date=${startDate}&end_date=${endDate}`
      ),
    { onError: showError }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ROBOTS_VIEWED);
  }, []);

  const isLoading = robotsLoading || returnsLoading;
  const isSuccess = robotsSuccess && returnsSuccess;

  return (
    <AppLayout
      premiumMessage="Receba atualizações em tempo real"
      seoProps={{
        title: "Robôs Quant",
      }}>
      <div className={css["content"]}>
        <Breadcrumb
          name="Lista de Robôs"
          parents={[{ path: "/app/feed", name: "Feed" }]}
        />
        <BetaTitle title="Robôs Quant" />
        <p>
          Estratégias automatizadas simuladas em <b>tempo real</b> pelo
          QuantBrasil. Os resultados demonstrados buscam ser os mais próximos
          possíveis da realidade. <HowButton />
        </p>
        {isLoading && <Spinner type="Rings" text="Buscando robôs..." />}
        {isSuccess && (
          <>
            <RobotsTrades {...returnsData} />
            <div className={css["wrapper"]}>
              <table>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Ativo</th>
                    <th>Timeframe</th>
                    <th>Estratégia</th>
                    <th>Status</th>
                    <th>Últimos 5 trades</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {robotsData.map(robot => (
                    <tr key={robot.robot_name}>
                      <td>{robot.robot_name}</td>
                      <td>{getAssetLabel(robot.ticker)}</td>
                      <td>{mapTimeframe(robot.timeframe_id)}</td>
                      <td>
                        <StrategyDetails
                          strategyId={robot.strategy_id}
                          parameters={robot.parameters}
                        />
                      </td>
                      <td>{mapStatus(robot)}</td>
                      <td>
                        <div className={css["squares"]}>
                          {robot.last_five_trades_profit.map((trade, index) => (
                            <TradeSquare key={index} trade={trade} />
                          ))}
                        </div>
                      </td>
                      <td className={css["buttonsCell"]}>
                        <Link
                          className={css["viewButton"]}
                          data-tooltip-id="button-tooltip"
                          data-tooltip-content="Visualizar robô"
                          to={`/app/robots/${robot.id}`}>
                          <ViewIcon />
                        </Link>
                        <button
                          data-tooltip-id="button-tooltip"
                          data-tooltip-content="Ver backtest"
                          className={css["backtestButton"]}
                          onClick={() =>
                            setSelectedBacktestId(robot.backtest_id)
                          }>
                          <PerformanceIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p className={css["disclaimer"]}>
                Disclaimer: não é recomendação de compra ou venda.
              </p>
            </div>
          </>
        )}
        <Tooltip
          className={css["tooltip"]}
          id="button-tooltip"
          delayHide={100}
        />
        <Modal
          isOpen={!!selectedBacktestId}
          modalClassName={css["modal"]}
          onClose={() => setSelectedBacktestId(null)}>
          <FeedBacktest backtestId={selectedBacktestId} />
        </Modal>
      </div>
    </AppLayout>
  );
};

export default RobotsContainer;
