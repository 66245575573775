import PropTypes from "prop-types";
import { useEffect,useState } from "react";
import { animated,useSpring } from "react-spring";

const SliderDiv = ({ children, offset = -500, property = "marginLeft" }) => {
  const [hasSlided, setSlide] = useState(false);

  const contentProps = useSpring({
    opacity: hasSlided ? 1 : 0,
    [property]: hasSlided ? 0 : offset,
  });

  useEffect(() => {
    if (!hasSlided) setSlide(true);
  }, [hasSlided]);

  return <animated.div style={contentProps}>{children}</animated.div>;
};

SliderDiv.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.number,
  property: PropTypes.string,
};

export default SliderDiv;
