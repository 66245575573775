// extracted by mini-css-extract-plugin
export var breadcrumbContainer = "estrategias-module--breadcrumbContainer--10bc7";
export var buy = "estrategias-module--buy--4f2a2";
export var content = "estrategias-module--content--0edc0";
export var excerpt = "estrategias-module--excerpt--89187";
export var filter = "estrategias-module--filter--9df8f";
export var sell = "estrategias-module--sell--bc0c6";
export var strategies = "estrategias-module--strategies--467fb";
export var strategy = "estrategias-module--strategy--e3342";
export var tag = "estrategias-module--tag--e8b60";
export var upgrade = "estrategias-module--upgrade--8c2d8";