import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import Input from "../components/common/Input";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import YouTubePlayer from "../components/common/YouTubePlayer";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./turnaround.module.css";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const SORT_OPTIONS = [
  { value: "negative_sequence desc", label: "Mais Dias de Quedas" },
  { value: "negative_sequence asc", label: "Menos Dias de Quedas" },
  { value: "current_variation asc", label: "Maior Queda Atual" },
  { value: "current_variation desc", label: "Menor Queda Atual" },
];

const Turnaround = () => {
  const { isLoggedIn } = useAuth();

  const [minDays, setMinDays] = useState(0);
  const [maxDays, setMaxDays] = useState(10);
  const [minFall, setMinFall] = useState(undefined);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allTicker: { nodes },
  } = useStaticQuery(graphql`
    {
      allTicker(
        filter: { negative_sequence: { gt: 0 } }
        sort: { negative_sequence: DESC }
      ) {
        nodes {
          ticker
          negative_sequence
          current_variation
          price
          buildTime
        }
      }
    }
  `);

  const buildTime = nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      })
      .filter(
        node =>
          node.negative_sequence <= maxDays && node.negative_sequence >= minDays
      )
      .filter(node =>
        minFall === undefined
          ? true
          : 100 * node.current_variation <= -1 * minFall
      ),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "turnaround" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Turnaround",
        description:
          "Screening dos ativos potencialmente podem iniciar um movimento de repique.",
      }}
      title="Turnaround"
      ctaMessage="Crie sua conta para desbloquear diversas opções de backtest.">
      <div>
        <div className={css["youtubeContainer"]}>
          <p>
            A estratégia <b>Turnaround</b> busca ativos que venham em uma
            sequência de queda, e portanto possuem uma maior probabilidade de{" "}
            <em>repique</em>. A entrada dá-se no fechamento, após <em>n</em>{" "}
            dias consecutivos de queda. O alvo é tipicamente o fechamento de 1
            ou 2 dias seguintes.
          </p>
          <YouTubePlayer
            videoId="ZD67SxuVR9E"
            width={500}
            height={315}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <p>
          {" "}
          <Link to="/backtests?strategy=long_turnaround&timeframeId=D1">
            Teste agora no nosso simulador!
          </Link>
        </p>
        <p>
          Sua motivação veio de uma{" "}
          <a
            href="https://twitter.com/jlbraga/status/1473668780240904194"
            target="_blank"
            rel="noreferrer">
            discussão
          </a>{" "}
          onde estudou-se sua eficácia no S&P e no IBOV.{" "}
          <a
            href="https://twitter.com/quant_brasil"
            target="_blank"
            rel="noreferrer">
            Siga o QuantBrasil no Twitter!
          </a>
        </p>
        <LastUpdated date={buildTime} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-days">{`Min Dias`}</label>
            <input
              id="min-days"
              type="number"
              min={0}
              max={20}
              value={minDays}
              onChange={e => setMinDays(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-days">{`Max Dias`}</label>
            <input
              id="max-days"
              type="number"
              min={0}
              max={20}
              value={maxDays}
              onChange={e => setMaxDays(e.target.value)}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "150px" }}>
            <label htmlFor="min-fall">{`Queda Mínima (%)`}</label>
            <Input
              id="min-fall"
              value={minFall}
              onValueChange={({ floatValue }) => setMinFall(floatValue)}
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={1}
              placeholder="Ex: 0,5"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 5
              }
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="stochastic-ticker">Ativo</label>
            <input
              id="stochastic-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="ticker-order">Ordenar por</label>
            <Select
              id="ticker-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "240px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="turnaround-tip"
                  data-tooltip-content="Preços tem um delay de 15 minutos a partir da última atualização.">
                  Preço Atual<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="turnaround-tip"
                  data-tooltip-content="Variação em relação ao pregão anterior.">
                  Queda Atual<sup>2</sup>
                </th>
                <th>Dias de Quedas</th>
                <th
                  data-tooltip-id="turnaround-tip"
                  data-tooltip-content="Rode o backtest de compra ou venda no simulador de estratégias.">
                  Backtest<sup>3</sup>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td>{node.ticker}</td>
                  <td>{node.price.toFixed(2)}</td>
                  <td>{(100 * node.current_variation).toFixed(2)}%</td>
                  <td>{node.negative_sequence}</td>
                  <td>
                    <Link
                      onClick={() =>
                        logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                          ticker: node.ticker,
                          page: window.location.pathname,
                        })
                      }
                      to={`/backtests?strategy=long_turnaround&ticker=${
                        node.ticker
                      }&timeframeId=D1&negativeSequence=${
                        node.negative_sequence
                      }&exhaustionPct=${Math.min(
                        node.current_variation * -100,
                        0.5
                      )}`}>
                      Rodar Backtest
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Preços tem um delay de 15 minutos a partir da última
            atualização.
          </div>
          <div>
            <sup>2</sup> Variação em relação ao pregão anterior.
          </div>
          <div>
            <sup>3</sup> Rode o backtest de compra ou venda no simulador de
            estratégias.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="turnaround-tip" />
      </div>
    </PublicLayout>
  );
};

export default Turnaround;
