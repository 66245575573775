import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty, isArray, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import InstagramLink from "../../components/common/InstagramLink";
import PublicLayout from "../../components/layouts/PublicLayout";
import { useAuth } from "../../hooks/useAuth";
import { SELECT_SMALL } from "../../utils/select";
import { useAPI } from "../../hooks/useAPI";
import { useQuery } from "react-query";
import * as css from "./momentum.module.css";
import Spinner from "../../components/common/Spinner";
import ArrowDownIcon from "../../icons/arrow-down.svg";
import PauseIcon from "../../icons/pause.svg";
import UpgradeSection from "../../components/common/UpgradeSection";
import LastUpdated from "../../components/common/LastUpdated";
import { trackScreeningViewed } from "../../utils/amplitude";
import {
  getMomentumStatus,
  hasPreviousMomentum,
  LAG_OPTIONS,
  parseMomentum,
  parseSlope,
  PERIOD_OPTIONS,
  REGRESSION_OPTIONS,
  SORT_OPTIONS,
} from "../../utils/momentum";

const PORTFOLIO_ID = Number(process.env.GATSBY_SP_500_PORTFOLIO_ID);

const SP500Momentum = () => {
  const { isLoggedIn, user } = useAuth();

  const isPremium = user?.isPremium;

  const PROTECTED_REGRESSION_OPTIONS = REGRESSION_OPTIONS.map(option => {
    if (!isPremium && ["EXPONENTIAL"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const PROTECTED_PERIOD_OPTIONS = PERIOD_OPTIONS.map(option => {
    if (!isPremium && ["DAYS_30", "DAYS_180"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const PROTECTED_LAG_OPTIONS = LAG_OPTIONS.map(option => {
    if (!isPremium) return { ...option, isDisabled: true };
    return option;
  });

  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [period, setPeriod] = useState(PROTECTED_PERIOD_OPTIONS[1]);
  const [regression, setRegression] = useState(PROTECTED_REGRESSION_OPTIONS[0]);
  const [lag, setLag] = useState(isPremium ? PROTECTED_LAG_OPTIONS[0] : null);
  const [ticker, setTicker] = useState("");

  const { allMomentum } = useStaticQuery(graphql`
    {
      allMomentum(sort: { momentum: DESC }) {
        nodes {
          ticker
          slope
          window
          regression
          intercept
          momentum
          momentum_lag1
          momentum_lag5
          momentum_lag20
          r_squared
          last_price
          last_updated
          ranking
          name
          portfolio_id
        }
      }
    }
  `);

  const staticData = useMemo(() => {
    const data = allMomentum.nodes.filter(
      node => node.portfolio_id === PORTFOLIO_ID
    );
    return data;
  }, [allMomentum]);

  const callAPI = useAPI({ withCredentials: true });

  const mapData = useCallback((data, windowId, regressionType) => {
    if (isArray(data)) return data;

    // Map object to array if not array
    return Object.keys(data).map(symbol => {
      const d = data[symbol];
      return {
        ...d,
        ticker: symbol,
        window: windowId,
        regression: regressionType,
        portfolio_id: PORTFOLIO_ID,
      };
    });
  }, []);

  const { data, isFetching, isPreviousData } = useQuery(
    ["momentum", PORTFOLIO_ID, period, regression],
    async ({ queryKey }) => {
      const portfolioId = queryKey[1];
      const windowId = queryKey[2].value;
      const regressionType = queryKey[3].value;
      const data = await callAPI(
        `/api/portfolio/${portfolioId}/momentum?window=${windowId}&regression=${regressionType}`
      );
      return mapData(data, windowId, regressionType);
    },
    {
      initialData: staticData,
      keepPreviousData: true,
      enabled: isPremium,
    }
  );

  // If we are fetching in background and keeping the previous data, this means the query key
  // changed and we can display the loader
  const isUpdating = isFetching && isPreviousData;

  const lastUpdated = data[0]?.last_updated.replace("UTC", "Z");

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const filtered = useMemo(() => {
    const byPrevious = isPremium ? orderBy(data, lag.value, "desc") : [];

    const ordered = orderBy(
      data
        .map(node =>
          isPremium
            ? {
                ...node,
                prevRanking:
                  byPrevious.findIndex(p => p.ticker === node.ticker) + 1,
              }
            : node
        )
        .filter(node => {
          if (isEmpty(ticker)) return true;
          return node.ticker.includes(ticker.toUpperCase());
        }),
      fieldToSort,
      directionToSort
    );

    return ordered;
  }, [data, directionToSort, fieldToSort, isPremium, lag, ticker]);

  const renderMomentumChange = node => {
    if (!hasPreviousMomentum(node, lag, isPremium)) return null;

    const status = getMomentumStatus(node, lag);

    if (status === "EQUAL") return null;

    const prevMomentum = parseMomentum(node[lag.value], node.regression);

    return (
      <ArrowDownIcon
        className={status === "DECREASING" ? css["arrowDown"] : css["arrowUp"]}
        data-tooltip-id="momentum-tip"
        data-tooltip-content={`Era ${prevMomentum}`}
      />
    );
  };

  const renderPositionChange = node => {
    if (!hasPreviousMomentum(node, lag, isPremium)) return null;

    const status =
      node.ranking < node.prevRanking
        ? "INCREASING"
        : node.ranking > node.prevRanking
        ? "DECREASING"
        : "EQUAL";

    const positionChangeText =
      node.ranking < node.prevRanking
        ? `Subiu de ${node.prevRanking}º para ${node.ranking}º`
        : node.ranking > node.prevRanking
        ? `Caiu de ${node.prevRanking}º para ${node.ranking}º`
        : `Manteve-se em ${node.ranking}º`;

    if (status === "EQUAL")
      return (
        <PauseIcon
          className={css["pause"]}
          data-tooltip-id="momentum-tip"
          data-tooltip-content={`${positionChangeText}`}
        />
      );

    return (
      <ArrowDownIcon
        className={status === "DECREASING" ? css["arrowDown"] : css["arrowUp"]}
        data-tooltip-id="momentum-tip"
        data-tooltip-content={`${positionChangeText}`}
      />
    );
  };

  useEffect(() => {
    trackScreeningViewed({ key: "momentum" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Momentum S&P 500",
        description: "Screening de momentum dos ativos que compõe o S&P500.",
      }}
      title="Momentum S&P 500"
      ctaMessage="Crie sua conta para desbloquear todas as opções do screening."
      premiumMessage="Seja Premium para desbloquear todas as funcionalidades do screening de momentum">
      <div>
        <p>
          A lista abaixo contém os ativos mais importantes da bolsa americana
          ranqueados por <em>momentum</em>. O <em>momentum</em> pode ser
          calculado utilizando-se uma regressão linear ou exponencial, em
          diferentes períodos.
        </p>
        <p>
          O <em>momentum</em> representa a variação anual da ação (em <em>$</em>{" "}
          na regressão linear ou em <em>%</em> na exponencial){" "}
          <b>caso mantivesse a mesma angulação no período</b> (coeficiente de
          regressão). É importante notar que esse número é apenas um{" "}
          <em>proxy</em> da força do ativo e não deve ser encarado como uma
          previsão.
        </p>
        <p>
          Ver também: <Link to="/momentum">Momentum das Ações Brasileiras</Link>
          .
        </p>
        <LastUpdated date={lastUpdated} isISO />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="momentum-period">Período</label>
            <Select
              id="momentum-period"
              options={PROTECTED_PERIOD_OPTIONS}
              value={period}
              onChange={option => setPeriod(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "145px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Premium'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="momentum-regression">Tipo de Regressão</label>
            <Select
              id="momentum-regression"
              options={PROTECTED_REGRESSION_OPTIONS}
              value={regression}
              onChange={option => setRegression(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Premium'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="momentum-regression">Variação em</label>
            <Select
              id="momentum-lag"
              placeholder="Nº de pregões"
              options={PROTECTED_LAG_OPTIONS}
              value={lag}
              onChange={option => setLag(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "175px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Premium'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="ranking-order">Ordenar por</label>
            <Select
              id="ranking-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "210px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="ranking-ticker">Ativo</label>
            <input
              id="ranking-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: AAPL"
              style={{
                minHeight: "32px",
                maxHeight: "32px",
              }}
            />
          </div>
        </div>
        {isUpdating ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Calculando momentum..." />
          </div>
        ) : (
          <>
            {!isPremium && isLoggedIn && (
              <UpgradeSection
                copy={`Calcule o momentum em diferentes períodos, utilizando diferentes tipos de regressão, e acompanhe a variação do momentum em vários pregões.`}
              />
            )}
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Posição</th>
                    <th>Ativo</th>
                    <th>Nome</th>
                    <th
                      data-tooltip-id="momentum-tip"
                      data-tooltip-content="Preço do ativo quando a regressão foi calculada.">
                      Preço<sup>1</sup>
                    </th>
                    <th
                      data-tooltip-id="momentum-tip"
                      data-tooltip-content={`Expectativa de retorno anualizada (${
                        regression.value === "LINEAR" ? "em reais" : "em %"
                      }).`}>
                      Momentum<sup>2</sup>
                    </th>
                    <th
                      data-tooltip-id="momentum-tip"
                      data-tooltip-content={`Coeficiente de Regressão, ou Inclinação da regressão, representa a variação diária esperada (${
                        regression.value === "LINEAR" ? "em reais" : "em %"
                      }).`}>
                      Variação<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="momentum-tip"
                      data-tooltip-content={`Coeficiente de Determinação, ou R², mede quão bem os preços se adequam à regressão.`}>
                      R²<sup>4</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>
                        <div className={css["momentumContainer"]}>
                          {node.ranking}
                          {renderPositionChange(node)}
                        </div>
                      </td>
                      <td>{node.ticker}</td>
                      <td>
                        <div className={css["sectorColumn"]}>{node.name}</div>
                      </td>
                      <td>{node.last_price.toFixed(2)}</td>
                      <td
                        style={{
                          color: node.momentum >= 0 ? "#49ce8b" : "#d64242",
                          fontWeight: "600",
                        }}>
                        <div className={css["momentumContainer"]}>
                          {parseMomentum(node.momentum, node.regression)}
                          {renderMomentumChange(node)}
                        </div>
                      </td>
                      <td>{parseSlope(node)}</td>
                      <td>{(node.r_squared * 100).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço do ativo quando a regressão foi calculada.
              </div>
              <div>
                <sup>2</sup>{" "}
                {`Expectativa de retorno anualizada (${
                  regression.value === "LINEAR" ? "em dólares" : "em %"
                }).`}
              </div>
              <div>
                <sup>3</sup>{" "}
                {`Coeficiente de Regressão, ou Inclinação da regressão, representa a variação diária esperada (${
                  regression.value === "LINEAR" ? "em dólares" : "em %"
                }).`}
              </div>
              <div>
                <sup>4</sup> Coeficiente de Determinação, ou R², mede quão bem
                os preços se adequam à regressão.
              </div>
            </div>
          </>
        )}
        <InstagramLink />
        <Tooltip id="momentum-tip" />
      </div>
    </PublicLayout>
  );
};

export default SP500Momentum;
