import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import Spinner from "../components/common/Spinner";
import UpgradeSection from "../components/common/UpgradeSection";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./setup-123.module.css";
import YouTubePlayer from "../components/common/YouTubePlayer";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const PORTFOLIO_ID = process.env.GATSBY_TOP_200_PORTFOLIO_ID;

const TYPE_OPTIONS = [
  { value: "long", label: "Compra" },
  { value: "short", label: "Venda" },
];

const EDEN_OPTIONS = [
  { value: "all_eden", label: "Todos" },
  { value: "long_eden", label: "Compra" },
  { value: "short_eden", label: "Venda" },
];

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "H1", label: "60 Minutos" },
  { value: "H2", label: "120 Minutos" },
  { value: "W1", label: "Semanal" },
];

const SetupPFR = () => {
  const { isLoggedIn, user } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["W1", "H1", "H2"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [type, setType] = useState(TYPE_OPTIONS[0]);
  const [eden, setEden] = useState(EDEN_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [isHammer, setIsHammer] = useState(false);
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [timeframe, setTimeframe] = useState(
    PROTECTED_TIMEFRAME_OPTIONS.find(({ value }) => value === "D1")
  );

  const staticData = useStaticQuery(graphql`
    query {
      long: allPfr(filter: { long_signal: { eq: 1 } }) {
        nodes {
          long_eden
          price
          is_long_hammer
          ticker
          buildTime
        }
      }
      short: allPfr(filter: { short_signal: { eq: 1 } }) {
        nodes {
          short_eden
          price
          is_short_hammer
          ticker
          buildTime
        }
      }
      variations: allVariation(filter: { window: { eq: "DAYS_90" } }) {
        nodes {
          ticker
          variation
        }
      }
    }
  `);

  const [data, setData] = useState(staticData);

  const callAPI = useAPI({ withCredentials: true });

  const { data: setupPFRData, isLoading } = useQuery(
    ["setupPFR", timeframe],
    ({ queryKey }) => {
      const timeframeId = queryKey[1].value;
      if (timeframeId === "D1") {
        setData(staticData);
        return;
      }
      return callAPI(
        `/api/portfolio/${PORTFOLIO_ID}/pfr?timeframe=${timeframeId}`
      );
    }
  );

  useEffect(() => {
    if (setupPFRData) {
      const newData = { long: { nodes: [] }, short: { nodes: [] } };
      Object.keys(setupPFRData).map(symbol => {
        const d = setupPFRData[symbol];
        if (d.long_signal)
          newData.long.nodes.push({
            ...d,
            ticker: symbol,
            buildTime: d.last_updated.replace("UTC", "Z"),
          });
        else if (d.short_signal)
          newData.short.nodes.push({ ...d, ticker: symbol });
      });
      setData(newData);
    }
  }, [setupPFRData]);

  const longLastUpdated = data.long.nodes[0]?.buildTime;
  const shortLastUpdated = data.short.nodes[0]?.buildTime;

  const lastUpdated = longLastUpdated || shortLastUpdated;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data[type.value].nodes
      .map(node => ({
        ...node,
        order: staticData.variations.nodes.findIndex(
          n => n.ticker === node.ticker
        ),
      }))
      .filter(node => {
        if (eden.value === "long_eden") return node.long_eden === 1;
        if (eden.value === "short_eden") return node.short_eden === 1;
        return true;
      })
      .filter(node => {
        if (isHammer)
          return type.value === "long"
            ? node.is_long_hammer === 1
            : node.is_short_hammer;
        return true;
      })
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    "order",
    "asc"
  );

  useEffect(() => {
    trackScreeningViewed({ key: "pfr" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "PFR — Preço de Fechamento de Reversão",
        description:
          "Screening dos ativos que estão formando um Preço de Fechamento de Reversão (PFR), em tempo real e em diversos timeframes.",
      }}
      title="PFR — Preço de Fechamento de Reversão"
      ctaMessage="Crie sua conta para visualizar os ativos formando o Setup PFR em diferentes timeframes.">
      <div>
        <div className={css["youtubeContainer"]}>
          <p>
            O <b>Preço de Fechamento de Reversão (PFR)</b> é caracterizado por
            um candle cuja mínima é menor que os dois anteriores, mas o
            fechamento é maior que o anterior (<em>compra</em>) ou um candle
            cuja máxima é maior que as duas anteriores, mas o fechamento é menor
            que o anterior (<em>venda</em>).
          </p>
          <YouTubePlayer
            videoId="SKZHJJgL8Es"
            width={500}
            height={315}
            style={{ marginBottom: "16px" }}
          />
        </div>
        <p>
          A lista abaixo contém os ativos formando o{" "}
          <b>Preço de Fechamento de Reversão</b>, de <em>compra</em> ou{" "}
          <em>venda</em>, ordenados pela sua <em>força relativa</em> nos últimos
          90 dias. É possível filtrar por ativos no{" "}
          <Link to="/eden-dos-traders">Éden dos Traders</Link> e os formando um{" "}
          <em>candle martelo</em>.
          {!isLoggedIn && (
            <span>
              {" "}
              <Link
                to="/cadastro"
                onClick={() =>
                  logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                    page: window.location.pathname,
                    ctaText: "Crie sua conta para rodar o backtest do PFR.",
                  })
                }>
                Crie sua conta para rodar o backtest do PFR.
              </Link>{" "}
            </span>
          )}
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="PFR-tf">Timeframe</label>
            <Select
              id="PFR-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => {
                if (option.value !== timeframe.value) {
                  logAmplitudeEvent(EVENT_MAP.PFR_SELECTED, {
                    timeframe: option.value,
                  });
                }
                setTimeframe(option);
              }}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="PFR-type">PFR de</label>
            <Select
              id="PFR-type"
              options={TYPE_OPTIONS}
              value={type}
              onChange={option => setType(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "120px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div>
            <label htmlFor="eden-type">Éden dos Traders</label>
            <Select
              id="eden-type"
              options={EDEN_OPTIONS}
              value={eden}
              onChange={option => setEden(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "180px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="beta-period">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="inside-bar"
                type="checkbox"
                checked={isHammer}
                onChange={e => {
                  setIsHammer(e.target.checked);
                }}
              />
              <label htmlFor="inside-bar">Candle Martelo</label>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        {isLoading && isLoggedIn ? (
          <div style={{ marginBottom: "40px" }}>
            <Spinner type="Rings" text="Robôs trabalhando..." />
          </div>
        ) : (
          <>
            <div className={css["tableContainer"]}>
              <table>
                <thead>
                  <tr>
                    <th>Ativo</th>
                    <th
                      data-tooltip-id="pfr-tip"
                      data-tooltip-content={`Preço aferido na última atualização.`}>
                      Preço Atual<sup>1</sup>
                    </th>
                    <th
                      data-tooltip-id="pfr-tip"
                      data-tooltip-content="O Éden dos Traders é quando a MME8 e MME80 estão na mesma direção.">
                      Éden dos Traders<sup>2</sup>
                    </th>
                    <th
                      data-tooltip-id="pfr-tip"
                      data-tooltip-content={
                        type.value === "long"
                          ? "Candle cujo fechamento é maior que a abertura."
                          : "Candle cujo fechamento é menor que a abertura."
                      }>
                      Candle Martelo<sup>3</sup>
                    </th>
                    <th
                      data-tooltip-id="pfr-tip"
                      data-tooltip-content="Rode o backtest no simulador de estratégias.">
                      Estatísticas<sup>4</sup>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.map(node => (
                    <tr key={node.ticker}>
                      <td>{node.ticker}</td>
                      <td>{node.price.toFixed(2)}</td>
                      <td>
                        {node.long_eden
                          ? "Compra"
                          : node.short_eden
                          ? "Venda"
                          : "Não"}
                      </td>
                      <td>
                        {(type.value === "long" && node.is_long_hammer) ||
                        (type.value === "short" && node.is_short_hammer)
                          ? "Sim"
                          : "Não"}
                      </td>
                      <td>
                        <Link
                          onClick={() =>
                            logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                              ticker: node.ticker,
                              page: window.location.pathname,
                            })
                          }
                          to={`/backtests?strategy=long_pfr&ticker=${node.ticker}&timeframeId=${timeframe.value}&tradersEden=${node.long_eden}&hammer=${node.is_long_hammer}`}>
                          Rodar Backtest
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={css["legend"]}>
              <div>
                <sup>1</sup> Preço aferido na última atualização.
              </div>
              <div>
                <sup>2</sup> O Éden dos Traders é quando a MME8 e MME80 estão na
                mesma direção.
              </div>
              <div>
                <sup>3</sup>{" "}
                {type.value === "long"
                  ? "Candle cujo fechamento é maior que a abertura."
                  : "Candle cujo fechamento é menor que a abertura."}
              </div>
              <div>
                <sup>4</sup> Rode o backtest no simulador de estratégias.
              </div>
            </div>
            <Tooltip id="pfr-tip" />
          </>
        )}
        <InstagramLink />
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear todas as funcionalidades do QuantBrasil!" />
        )}
      </div>
    </PublicLayout>
  );
};

export default SetupPFR;
