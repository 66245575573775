import React from "react";
import * as css from "./Breadcrumb.module.css";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import ArrowIcon from "../../icons/arrow-down.svg";

const Breadcrumb = ({ name, parents = [] }) => {
  return (
    <div className={css["header"]}>
      <div className={css["breadcrumb"]}>
        {parents.map((parent, i) => (
          <React.Fragment key={parent.path}>
            <span className={css["parent"]}>
              <Link to={parent.path}>{parent.name}</Link>
            </span>
            {i === parents.length - 1 && !name ? null : (
              <ArrowIcon className={css["arrow"]} />
            )}
          </React.Fragment>
        ))}
        {name && <span>{name}</span>}
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  parents: PropTypes.arrayOf(PropTypes.object),
  goBack: PropTypes.bool,
  hideBackButton: PropTypes.bool,
};

export default Breadcrumb;
