import PropTypes from "prop-types";
import { mapStrategy, parseParameters } from "../../utils/utils";
import { isEmpty } from "lodash";
import { renderToStaticMarkup } from "react-dom/server";
import { Tooltip } from "react-tooltip";
import { useId } from "react";
import InfoIcon from "../../icons/info-question-circle.svg";
import * as css from "./StrategyDetails.module.css";

const StrategyDetails = ({ strategyId, parameters }) => {
  const parsedParameters = parseParameters(parameters);
  const hasParameters =
    parsedParameters !== "Nenhum" && !isEmpty(parsedParameters);

  const id = useId();
  return (
    <div className={css["strategy"]}>
      {mapStrategy(strategyId)}{" "}
      {hasParameters && (
        <>
          <InfoIcon data-tooltip-id={id} />
          <Tooltip
            id={id}
            delayHide={100}
            html={renderToStaticMarkup(
              <span>
                {parsedParameters.map((p, i) => (
                  <div className={css["tooltip"]} key={i}>
                    {p.split(": ").map((chunk, j) => (
                      <span key={j}>
                        {chunk}
                        {j === 0 ? ": " : ""}
                      </span>
                    ))}
                  </div>
                ))}
              </span>
            )}
            clickable
          />
        </>
      )}
    </div>
  );
};

StrategyDetails.propTypes = {
  strategyId: PropTypes.number.isRequired,
  parameters: PropTypes.string.isRequired,
};

export default StrategyDetails;
