import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import PublicLayout from "../components/layouts/PublicLayout";
import LowRisk from "../components/lowRisk/LowRisk";
import { useEffect } from "react";
import { trackScreeningViewed } from "../utils/amplitude";

const TOP_ASSETS = 30;

const LowRiskPage = ({ data, pageContext: { isB3 } }) => {
  const renderBookLink = () => (
    <a
      target="_blank"
      href="https://www.amazon.com/gp/product/B01N984ORE/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01N984ORE&linkCode=as2&tag=rafaelquintan-20&linkId=a574e1b56e83ac83ad3e0eeede9b4252"
      rel="noreferrer">
      High Returns from Low Risk: A Remarkable Stock Market Paradox
    </a>
  );

  const getNodes = () => {
    const nodes = data.allLowRisk.nodes;
    return nodes.filter(
      n =>
        n.beta !== null &&
        n.beta !== undefined &&
        n.dividend_yield !== null &&
        n.dividend_yield !== undefined &&
        n.variation !== null &&
        n.variation !== undefined
    );
  };

  useEffect(() => {
    trackScreeningViewed({ key: "lowRisk" + isB3 ? "B3" : "S&P500" });
  }, [isB3]);

  return (
    <PublicLayout
      seoProps={{
        title: isB3 ? "Low Risk" : "Low Risk S&P 500",
        description: isB3
          ? "Screening the ativos da bolsa brasileira de baixo risco segundo o princípio de Factor Investing to Van Vliet."
          : "Screening the ativos da bolsa americana de baixo risco segundo o princípio de Factor Investing to Van Vliet.",
      }}
      title={
        isB3
          ? "Ativos de Baixo Risco da Bolsa Brasileira"
          : "Ativos de Baixo Risco do S&P 500"
      }
      ctaMessage="Crie sua conta para aplicar o screening de Low Risk no seu portfólio.">
      <LowRisk
        nodes={getNodes()}
        isB3={isB3}
        topAssets={TOP_ASSETS}
        descriptionParagraph={
          isB3 ? (
            <>
              <p>
                A lista abaixo utiliza uma técnica de <b>Factor Investing</b>{" "}
                chamada <em>Low Risk</em> e presente no livro {renderBookLink()}
                , de Van Vliet . A partir dela, geramos um screening dos ativos
                da bolsa brasileira baseados em 3 fatores: <em>dividendos</em>,{" "}
                <em>volatilidade</em> e <em>momentum</em>.
              </p>
              <p>
                O ranking final é a soma de suas posições em cada fator. A ideia
                é capturar ativos pagadores de dividendo, com baixa volatilidade
                (<Link to="/beta">beta</Link> dos últimos 2 anos), e que
                apresentem um bom <em>momentum</em> (maior variação nos últimos
                365 dias.) Os <b>{TOP_ASSETS}</b> melhores ativos são candidatos
                para um portfólio <em>Low Risk</em>.
              </p>
            </>
          ) : (
            <>
              <p>
                A lista abaixo utiliza uma técnica de <b>Factor Investing</b>{" "}
                chamada <em>Low Risk</em> e presente no livro {renderBookLink()}
                , de Van Vliet . A partir dela, geramos um screening dos ativos
                da bolsa americana baseados em 3 fatores: <em>dividendos</em>,{" "}
                <em>volatilidade</em> e <em>momentum</em>.
              </p>
              <p>
                O ranking final é a soma de suas posições em cada fator. A ideia
                é capturar ativos pagadores de dividendo, com baixa volatilidade
                (<Link to="/beta">beta</Link> dos últimos 5 anos), e que
                apresentem um bom <em>momentum</em> (maior variação nos últimos
                365 dias.) Os <b>{TOP_ASSETS}</b> melhores ativos são candidatos
                para um portfólio <em>Low Risk</em>.
              </p>
            </>
          )
        }
      />
    </PublicLayout>
  );
};

LowRiskPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default LowRiskPage;

export const query = graphql`
  query($portfolioId: Int!) {
    allLowRisk(filter: { portfolio_id: { eq: $portfolioId } }) {
      nodes {
        dividend_yield
        ticker
        variation
        beta
        buildTime
        portfolio_id
      }
    }
  }
`;
