import { Link } from "gatsby";
import { useState } from "react";
import { useMutation } from "react-query";

import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import SliderDiv from "../common/SliderDiv";
import * as css from "./Login.module.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [hasReset, setReset] = useState(false);

  const { forgotPassword } = useAuth();

  const mutation = useMutation(() => forgotPassword(email), {
    onSuccess: () => {
      setReset(true);
    },
    onError: showError,
  });

  const onClick = e => {
    e.preventDefault();
    if (mutation.isLoading) return;
    mutation.mutate();
  };

  if (hasReset) {
    return (
      <SliderDiv>
        <div className={css["confirmation"]}>
          <b>Pronto</b>! Um email para redefinição de senha foi enviado para{" "}
          <span>{email}</span>
        </div>
      </SliderDiv>
    );
  }

  return (
    <div className={css["box"]}>
      <h2>Esqueci minha senha</h2>
      <p>
        Preencha o seu email abaixo que enviaremos um link para redefinção de
        senha.
      </p>
      <form>
        <div className={css["input"]}>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <button
          disabled={mutation.isLoading}
          className={css["button"]}
          onClick={onClick}>
          Enviar
        </button>
        <div className={css["forgot"]}>
          <Link to="/login">Voltar</Link>
        </div>
        <div className={css["createAccount"]}>
          Não é cadastrado? <Link to="/cadastro">Criar conta</Link>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
