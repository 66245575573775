import RegisterIcon from "../../icons/register.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./RegisterCointegration.module.css";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import Modal from "../common/Modal";
import { useState } from "react";
import Input from "../common/Input";
import InfoIcon from "../../icons/info-question-circle.svg";
import ThumbsUpIcon from "../../icons/thumbs-up.svg";
import { useAPI } from "../../hooks/useAPI";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { showError } from "../../utils/utils";
import { Link } from "gatsby";

const SuccessMessage = () => (
  <div>
    Cointegração registrada com sucesso! Acompanhe o trade em{" "}
    <strong>
      <Link to="/app/cointegracao/trades/ongoing" style={{ color: "#fff" }}>
        Meus Trades
      </Link>
    </strong>
    .
  </div>
);

const RegisterCointegration = ({ result }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [baseAssetShares, setBaseAssetShares] = useState(null);
  const [baseAssetPrice, setBaseAssetPrice] = useState(
    result.base_asset_current_price
  );
  const [comparisonAssetShares, setComparisonAssetShares] = useState(null);
  const [comparisonAssetPrice, setComparisonAssetPrice] = useState(
    result.comparison_asset_current_price
  );

  const resetState = () => {
    setBaseAssetShares(null);
    setBaseAssetPrice(null);
    setComparisonAssetShares(null);
    setComparisonAssetPrice(null);
  };

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/cointegration/trade`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cointegration_id: result.id,
          base_asset_shares: baseAssetShares,
          base_asset_entry_price: baseAssetPrice,
          comparison_asset_shares: comparisonAssetShares,
          comparison_asset_entry_price: comparisonAssetPrice,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        toast.success(<SuccessMessage />);
        setIsOpen(false);
        resetState();
      },
    }
  );

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "REGISTER_COINTEGRATION"
  );

  const onClick = () => {
    markStepAsCompleted();
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_REGISTER_BUTTON_CLICKED, {
      cointegrationId: result.id,
    });
    setIsOpen(true);
  };

  const onSubmit = () => {
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_REGISTER_SAVED, {
      cointegrationId: result.id,
    });
  };

  const currentZscore = result.data[result.data.length - 1].zscore.toFixed(2);

  const invertDirection = result.beta < 0;

  let baseDirection = currentZscore > 0 ? "vender" : "comprar";
  let comparisonDirection = currentZscore > 0 ? "comprar" : "vender";

  if (invertDirection) {
    baseDirection = currentZscore > 0 ? "comprar" : "vender";
    comparisonDirection = currentZscore > 0 ? "vender" : "comprar";
  }

  const showBaseSharesWarning =
    baseAssetShares &&
    ((baseDirection === "comprar" && baseAssetShares < 0) ||
      (baseDirection === "vender" && baseAssetShares > 0));

  const showComparisonSharesWarning =
    comparisonAssetShares &&
    ((comparisonDirection === "comprar" && comparisonAssetShares < 0) ||
      (comparisonDirection === "vender" && comparisonAssetShares > 0));

  const effectiveBetaRatio =
    comparisonAssetShares && baseAssetShares
      ? Math.abs(comparisonAssetShares / baseAssetShares)
      : 0;

  const hasEffectiveBeta = effectiveBetaRatio !== 0;

  // See if the effective beta ratio is within 10% of the real beta
  const isEffectiveBetaRatioWithin10Percent =
    Math.abs(result.beta) * 1.1 >= effectiveBetaRatio &&
    Math.abs(result.beta) * 0.9 <= effectiveBetaRatio;

  const canSubmit =
    baseAssetPrice &&
    comparisonAssetPrice &&
    baseAssetShares &&
    comparisonAssetShares &&
    !mutation.isLoading;

  return (
    <>
      <Popover.Root open={showStep}>
        <Popover.Trigger asChild>
          <button
            data-tooltip-id="coint-register-tip"
            data-tooltip-content="Acompanhar cointegração"
            onClick={onClick}
            className={css["button"]}>
            <>
              <RegisterIcon />
              <span>Acompanhar</span>
              <Tooltip id="coint-register-tip" />
            </>
          </button>
        </Popover.Trigger>
        {showStep && (
          <OnboardingStep
            step={step}
            onConfirm={markStepAsCompleted}
            onDismiss={markStepAsCompleted}
          />
        )}
      </Popover.Root>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        modalClassName={css["modal"]}>
        <div className={css["header"]}>
          <h3>Acompanhe seu trade</h3>
          <p>
            Registre a quantidade e o preço de entrada de cada ativo e monitore
            o desempenho do seu <strong>Long & Short</strong>.
          </p>
        </div>
        <div className={css["info"]}>
          O z-score atual é {currentZscore}, portanto deve-se{" "}
          <strong>{baseDirection}</strong> {result.base_asset} e{" "}
          <strong>{comparisonDirection}</strong> {result.comparison_asset} na
          proporção de {result.beta.toFixed(2)}.
        </div>
        <div className={css["form"]}>
          <div>
            <div className={css["asset"]}>
              {result.base_asset} <span>(Ativo Base)</span>
            </div>
            <div className={css["inputContainer"]}>
              <div>
                <label htmlFor="baseAsset-shares">Quantidade</label>
                <Input
                  id="baseAsset-shares"
                  fluid
                  size="small"
                  value={baseAssetShares}
                  onValueChange={({ floatValue }) =>
                    setBaseAssetShares(floatValue)
                  }
                  isNumber
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative
                  decimalScale={0}
                  placeholder="Ex: 1000"
                  hint="Lote de compra ou venda"
                />
              </div>
              <div>
                <label htmlFor="baseAsset-price">Preço</label>
                <Input
                  id="baseAsset-price"
                  fluid
                  size="small"
                  value={baseAssetPrice}
                  onValueChange={({ floatValue }) =>
                    setBaseAssetPrice(floatValue)
                  }
                  isNumber
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  decimalScale={2}
                  placeholder="Ex: 10,00"
                  hint="Preço de entrada"
                />
              </div>
            </div>
          </div>
          <div>
            <div className={css["asset"]}>
              {result.comparison_asset} <span>(Ativo de Comparação)</span>
            </div>
            <div className={css["inputContainer"]}>
              <div>
                <label htmlFor="comparisonAsset-shares">Quantidade</label>
                <Input
                  id="comparisonAsset-shares"
                  fluid
                  size="small"
                  value={comparisonAssetShares}
                  onValueChange={({ floatValue }) =>
                    setComparisonAssetShares(floatValue)
                  }
                  isNumber
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative
                  decimalScale={0}
                  placeholder="Ex: 1000"
                  hint="Lote de compra ou venda"
                />
              </div>
              <div>
                <label htmlFor="comparisonAsset-price">Preço</label>
                <Input
                  id="comparisonAsset-price"
                  fluid
                  size="small"
                  value={comparisonAssetPrice}
                  onValueChange={({ floatValue }) =>
                    setComparisonAssetPrice(floatValue)
                  }
                  isNumber
                  thousandSeparator="."
                  decimalSeparator=","
                  allowNegative={false}
                  decimalScale={2}
                  placeholder="Ex: 10,00"
                  hint="Preço de entrada"
                />
              </div>
            </div>
          </div>
          <div className={css["tips"]}>
            {result.confidence < 0.95 && (
              <div className={css["warning"]}>
                <InfoIcon />{" "}
                <span>
                  A confiança atual de{" "}
                  <strong>{(100 * result.confidence).toFixed(2)}%</strong> é
                  menor que a recomendada (95%).
                </span>
              </div>
            )}
            {showBaseSharesWarning && (
              <div className={css["warning"]}>
                <InfoIcon />{" "}
                <span>
                  Você deve <strong>{baseDirection}</strong> o ativo base.
                </span>
              </div>
            )}
            {showComparisonSharesWarning && (
              <div className={css["warning"]}>
                <InfoIcon />{" "}
                <span>
                  Você deve <strong>{comparisonDirection}</strong> o ativo de
                  comparação.
                </span>
              </div>
            )}
            {hasEffectiveBeta && !isEffectiveBetaRatioWithin10Percent && (
              <div className={css["warning"]}>
                <InfoIcon />{" "}
                <span>
                  A proporção entre os ativos é de{" "}
                  <strong>{effectiveBetaRatio.toFixed(2)}</strong> (diferença
                  maior que ±10%).
                </span>
              </div>
            )}
            {hasEffectiveBeta && isEffectiveBetaRatioWithin10Percent && (
              <div className={css["success"]}>
                <ThumbsUpIcon />{" "}
                <span>
                  A proporção entre os ativos é de{" "}
                  <strong>{effectiveBetaRatio.toFixed(2)}</strong>.
                </span>
              </div>
            )}
          </div>
          <button
            className={css["registerButton"]}
            onClick={onSubmit}
            disabled={!canSubmit}>
            {mutation.isLoading ? "Cadastrando..." : "Cadastrar"}
          </button>
        </div>
      </Modal>
    </>
  );
};

RegisterCointegration.propTypes = {
  result: PropTypes.object.isRequired,
};

export default RegisterCointegration;
