import { useCallback } from "react";

import { checkStatus, getCookie } from "../utils/utils";
import { useAuth } from "./useAuth";
import { useConfig } from "./useConfig";

export const useAPI = ({ withCredentials = false, returnRaw = false }) => {
  const { BASE_URL, isDev } = useConfig();
  const { signOut } = useAuth();
  const callAPI = async (endpoint, options = {}) => {
    try {
      if (withCredentials === true) {
        options["credentials"] = "include";
        const headers = options.headers ? options.headers : {};
        const csrfToken = getCookie(`${isDev ? "dev_" : ""}csrf_access_token`);
        if (csrfToken) headers["X-CSRF-TOKEN"] = csrfToken;
        options["headers"] = headers;
      }

      const URL = `${BASE_URL}${endpoint}`;
      const response = await fetch(URL, options);

      if (returnRaw) return response;

      const data = await checkStatus(response);
      return data;
    } catch (e) {
      if (e.message === "INVALID_TOKEN") {
        signOut();
        throw new Error("Sessão expirada. Efetue o login novamente.");
      }
      throw new Error(e.message);
    }
  };

  return useCallback(callAPI, [
    BASE_URL,
    isDev,
    returnRaw,
    signOut,
    withCredentials,
  ]);
};
