import { Link } from "gatsby";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./UserCointegrationsContainer.module.css";
import CointegrationsList from "./CointegrationsList";
import { useAuth } from "../../hooks/useAuth";
import UpgradeSection from "../common/UpgradeSection";
import { DateTime } from "luxon";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const UserCointegrationsContainer = () => {
  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const callAPI = useAPI({ withCredentials: true });

  const { isLoading, isSuccess, data, dataUpdatedAt } = useQuery(
    ["userCointegrations"],
    () => callAPI(`/api/metrics/cointegration`),
    { onError: showError }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_FAVORITES_VIEWED);
  }, []);

  return (
    <AppLayout
      premiumMessage="Seja Premium e desbloqueie novos pares cointegrados todos os dias"
      seoProps={{
        title: "Radar de Cointegrações",
      }}>
      <div className={css["content"]}>
        <h1>Cointegrações Salvas</h1>
        <p>
          Monitore todos os pares{" "}
          <span
            className={css["hint"]}
            data-tooltip-content
            data-tooltip-id="cointegrations-radar-container">
            cointegrados
          </span>{" "}
          encontrados por você no{" "}
          <b>
            <Link to="/cointegracao">Teste de Cointegração</Link>
          </b>{" "}
          nos últimos 30 dias. Assinantes <b>Premium</b> podem buscar novos
          pares no{" "}
          <b>
            <Link to="/cointegracao/radar">Radar</Link>
          </b>
          .
        </p>
        <p>
          Pares cujo <em>Z-score</em> atual seja <b>≥ 2 ou ≤ -2</b> são
          candidatos a um trade de Long & Short.
        </p>
        {dataUpdatedAt > 0 && (
          <p className={css["lastUpdated"]}>
            <em>Última atualização</em>:{" "}
            {DateTime.fromMillis(dataUpdatedAt)
              .setLocale("pt-BR")
              .toFormat("HH:mm:ss")}
          </p>
        )}
        {isLoading && <Spinner type="Rings" text="Buscando..." />}
        {isSuccess && data.length === 0 && (
          <div className={css["none"]}>
            Nenhum par cointegrado encontrado nos últimos 30 dias.{" "}
            <Link to="/cointegracao">Buscar pares.</Link>
          </div>
        )}
        {isSuccess && data.length > 0 && (
          <CointegrationsList
            cointegrations={data}
            showFilter={isPremium}
            allowUnsave
          />
        )}
        {isSuccess && !isPremium && (
          <div className={css["extra"]}>
            <UpgradeSection copy="Assine o plano Premium para ter acesso ao Radar do QuantBrasil e desbloquear todos os ativos na ferramenta de Teste de Cointegração." />
          </div>
        )}
      </div>
      <Tooltip id="cointegrations-radar-container" clickable delayHide={100}>
        <span>{`São considerados cointegrados os pares com confiança ≥ 95% e beta > 0.`}</span>
      </Tooltip>
    </AppLayout>
  );
};

export default UserCointegrationsContainer;
