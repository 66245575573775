import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { toPercent } from "../../utils/utils";
import InfoIcon from "../../icons/info-question-circle.svg";
import ArrowDownIcon from "../../icons/arrow-down.svg";
import { Tooltip } from "react-tooltip";
import * as css from "./CointegrationsList.module.css";
import { useState } from "react";
import orderBy from "lodash.orderby";
import { isEmpty, uniqWith } from "lodash";
import { SELECT_SMALL } from "../../utils/select";
import Select from "react-select";
import UnsaveCointegration from "./UnsaveCointegration";
import RefreshCointegration from "./RefreshCointegration";

const SORT_OPTIONS = [
  { value: "confidence desc", label: "Maior Confiança" },
  { value: "remaining_days desc", label: "Maior Validade" },
  { value: "current_zscore desc", label: "Maior Z-score" },
  { value: "current_zscore asc", label: "Menor Z-score" },
];

const CointegrationsList = ({ cointegrations, showFilter, allowUnsave }) => {
  const [minZscore, setMinZscore] = useState("");
  const [maxZscore, setMaxZscore] = useState("");
  const [ticker, setTicker] = useState("");
  const [isSignal, setIsSignal] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const filtered = uniqWith(
    orderBy(
      cointegrations
        .map(c => ({
          ...c,
          remaining_days: Math.ceil(
            30 +
              DateTime.fromJSDate(new Date(c.created_at)).diffNow("days").days
          ),
        }))
        .filter(({ current_zscore }) => {
          if (isSignal) return Math.abs(parseFloat(current_zscore)) >= 2;
          return true;
        })
        .filter(({ current_zscore }) => {
          if (minZscore !== "" && maxZscore !== "")
            return (
              parseFloat(current_zscore) <= maxZscore &&
              parseFloat(current_zscore) >= minZscore
            );
          if (maxZscore !== "") return parseFloat(current_zscore) <= maxZscore;
          if (minZscore !== "") return parseFloat(current_zscore) >= minZscore;
          return true;
        })
        .filter(({ base_asset, comparison_asset }) => {
          if (isEmpty(ticker)) return true;
          return `${base_asset} ${comparison_asset}`.includes(
            ticker.toUpperCase()
          );
        }),
      fieldToSort,
      directionToSort
    ),
    (a, b) =>
      a.base_asset === b.base_asset &&
      a.comparison_asset === b.comparison_asset &&
      a.window === b.window
  );

  return (
    <div>
      {showFilter && (
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-zscore">{`Min Z-score`}</label>
            <input
              id="min-zscore"
              type="number"
              min={-5}
              max={5}
              value={minZscore}
              onChange={e => setMinZscore(e.target.value)}
              placeholder="Ex: -2"
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-zscore">{`Max Z-score`}</label>
            <input
              id="max-zscore"
              type="number"
              min={-5}
              max={5}
              value={maxZscore}
              onChange={e => setMaxZscore(e.target.value)}
              placeholder="Ex: 2"
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="radar-ticker">Ativo</label>
            <input
              id="radar-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div className={css["checkboxFilter"]}>
            <div className={css["checkbox"]}>
              <input
                id="isSignal"
                type="checkbox"
                checked={isSignal}
                onChange={e => {
                  setIsSignal(e.target.checked);
                }}
              />
              <label htmlFor="isSignal">
                Sinais Ativos{" "}
                <InfoIcon
                  data-tooltip-id="coint-list-tip"
                  data-tooltip-content="Mostrar apenas pares cujo Z-score atual seja ≥ 2 ou ≤ -2."
                />
              </label>
            </div>
          </div>
          <div>
            <label htmlFor="radar-order">Ordenar por</label>
            <Select
              id="radar-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "200px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
      )}
      <div className={css["tableContainer"]}>
        <table>
          <thead>
            <tr>
              <th>Base</th>
              <th>Comparação</th>
              <th>Z-Score</th>
              <th>Janela</th>
              <th>Criado em</th>
              <th className={css["headerWithIcon"]}>
                Validade{" "}
                <InfoIcon
                  data-tooltip-id="coint-list-tip"
                  data-tooltip-content="Dias restantes até ser necessário refazer o teste de cointegração."
                />
              </th>
              <th>Confiança</th>
              <th>Beta</th>
              <th>Meia-Vida</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((c, i) => (
              <tr key={c.id}>
                <td>{c.base_asset}</td>
                <td>{c.comparison_asset}</td>
                <td
                  className={css["zscore"]}
                  style={{
                    color:
                      Math.abs(parseFloat(c.current_zscore)) >= 2
                        ? "#49ce8b"
                        : undefined,
                    fontWeight:
                      Math.abs(parseFloat(c.current_zscore)) >= 2
                        ? 600
                        : undefined,
                  }}>
                  {parseFloat(c.current_zscore) <
                  parseFloat(c.previous_zscore) ? (
                    <ArrowDownIcon
                      className={css["arrowDown"]}
                      data-tooltip-id={`data-arrow-${c.id}`}
                    />
                  ) : (
                    <ArrowDownIcon
                      className={css["arrowUp"]}
                      data-tooltip-id={`data-arrow-${c.id}`}
                    />
                  )}
                  <Tooltip id={`data-arrow-${c.id}`} clickable>
                    {parseFloat(c.current_zscore) <
                    parseFloat(c.previous_zscore)
                      ? `Diminuiu de ${parseFloat(c.previous_zscore).toFixed(
                          2
                        )} para ${parseFloat(c.current_zscore).toFixed(2)}`
                      : `Aumentou de ${parseFloat(c.previous_zscore).toFixed(
                          2
                        )} para ${parseFloat(c.current_zscore).toFixed(2)}`}
                  </Tooltip>
                  {parseFloat(c.current_zscore).toFixed(2)}{" "}
                </td>
                <td>{c.window}</td>
                <td>
                  {DateTime.fromJSDate(new Date(c.created_at))
                    .setLocale("pt-BR")
                    .toFormat("dd/LL 'às' HH:mm")}
                </td>
                <td>{c.remaining_days} dias</td>
                <td>{toPercent(c.confidence, 2)}</td>
                <td>{parseFloat(c.beta).toFixed(2)}</td>
                <td>{Math.round(c.halflife)}</td>
                <td
                  className={css["actionsContainer"]}
                  style={{ height: i === 0 ? "52.5px" : undefined }}>
                  <RefreshCointegration
                    cointegration={c}
                    allowPopover={i === 0}
                  />
                  {allowUnsave && (
                    <UnsaveCointegration
                      id={c.id}
                      hideLabel
                      allowPopover={i === 0}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Tooltip id="coint-list-tip" clickable />
      <Tooltip id="refresh-coint-tip" clickable />
    </div>
  );
};

CointegrationsList.propTypes = {
  cointegrations: PropTypes.array.isRequired,
  showFilter: PropTypes.bool.isRequired,
  allowUnsave: PropTypes.bool.isRequired,
};

export default CointegrationsList;
