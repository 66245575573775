import { useMutation, useQuery } from "react-query";
import { useMedia, useSessionStorage } from "react-use";
import scrollToElement from "scroll-to-element";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./SupplyDemandContainer.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import SupplyDemandFilter, {
  getProjectionPeriods,
  getYears,
} from "./SupplyDemandFilter";
import SupplyDemandResult from "./SupplyDemandResult";
import LogoIcon from "../../images/logo.svg";
import SupplyDemandPopup from "./SupplyDemandPopup";
import { Tooltip } from "react-tooltip";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
  addUnique,
} from "../../utils/amplitude";
import { BooleanParam, useQueryParams } from "use-query-params";

const SHOW_POPUP_AFTER_SEQUENCE = 1;

const SupplyDemandContainer = () => {
  const [{ executeOnLoad }] = useQueryParams({
    executeOnLoad: BooleanParam,
  });

  const hasFastRun = useRef(false);

  const [asset, setAsset] = useSessionStorage("qb.sd.asset", null);
  const [year, setYear] = useSessionStorage(
    "qb.sd.year",
    getYears(new Date().getFullYear(), 5)[0]
  );
  const [sdPeriod, setSdPeriod] = useSessionStorage("qb.sd.period", 20);
  const [projectionPeriod, setProjectionPeriod] = useSessionStorage(
    "qb.sd.projectionPeriod",
    getProjectionPeriods()[0]
  );

  const [sequence, setSequence] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [hasSeenPopup, setSeenPopup] = useSessionStorage(
    "seenSupplyDemandPopup",
    false
  );

  const isMobile = useMedia("(max-width: 700px)");

  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn, user } = useAuth();

  const isPremium = Boolean(user?.isPremium);

  const showUpgrade = isLoggedIn && !isPremium;

  const mutation = useMutation(
    ({ asset, year }) =>
      callAPI(`/api/metrics/supply_demand`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ticker: asset.value,
          year: year.value.toString(),
          sd_period: sdPeriod,
          projection_period: projectionPeriod.value,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        setSequence(sequence + 1);
        if (isMobile) {
          setTimeout(() => {
            scrollToElement("#supply-demand-result");
          }, 0);
        }
      },
    }
  );

  const onExecuted = useCallback(() => {
    mutation.mutate({ asset, year });
    logAmplitudeEvent(EVENT_MAP.SUPPLY_DEMAND_EXECUTED, {
      ticker: asset.value,
      year: year.value,
      sdPeriod,
      projectionPeriod: projectionPeriod.value,
    });
    incrementProperty("supplyDemandExecuted");
    addUnique("assetsOfInterest", asset.value);
  }, [asset, mutation, projectionPeriod.value, sdPeriod, year]);

  const canSeePopup =
    sequence === SHOW_POPUP_AFTER_SEQUENCE &&
    !hasSeenPopup &&
    !user.isPremium &&
    Boolean(user.userId);

  const { data } = useQuery(
    ["canTrial", user.userId],
    () => callAPI(`/api/payment/trial`),
    { enabled: canSeePopup, retry: false }
  );

  useEffect(() => {
    if (canSeePopup && Boolean(data?.is_allowed)) {
      setShowPopup(true);
      setSeenPopup(true);
      logAmplitudeEvent(EVENT_MAP.SUPPLY_DEMAND_PREMIUM_POPUP_VIEWED, {
        sequence,
      });
    }
  }, [canSeePopup, data, sequence, setSeenPopup]);

  useEffect(() => {
    if (executeOnLoad && !hasFastRun.current) {
      onExecuted();
      hasFastRun.current = true;
    }
  }, [onExecuted, executeOnLoad]);

  const title = "Suportes e Resistências";

  return (
    <AppLayout
      ctaMessage="Crie sua conta e simule em outros anos e em diferentes períodos."
      premiumMessage="Seja Premium para desbloquear futuros, BDRs, ETFs e criptos"
      seoProps={{
        title,
        description:
          "Projete linhas de Suporte e Resistência para ações, ETFs, criptos, BDRs e futuros baseado em suas volatilidades.",
      }}>
      <div>
        <h1 className={css["header"]}>{title}</h1>
        <p>
          Projete zonas de suporte e resistência a partir da{" "}
          <span
            className={css["hint"]}
            data-tooltip-content={`Desvio-padrão calculado com ${
              sdPeriod || 20
            } períodos.`}
            data-tooltip-id="vol-tip">
            volatilidade
          </span>{" "}
          dos ativos. Esse cálculo é baseado no paper{" "}
          <b>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.outspokenmarket.com/uploads/8/8/2/3/88233040/supply_and_demand_levels_forecasting_based_on_returns_volatility.pdf">
              Supply and Demand Levels Forecasting Based on Returns Volatility
            </a>
          </b>
          , por Leandro Guerra.
        </p>
        <div className={css["panel"]}>
          <SupplyDemandFilter
            onClick={onExecuted}
            isLoading={mutation.isLoading}
            asset={asset}
            setAsset={setAsset}
            year={year}
            setYear={setYear}
            sdPeriod={sdPeriod}
            setSdPeriod={setSdPeriod}
            projectionPeriod={projectionPeriod}
            setProjectionPeriod={setProjectionPeriod}
            isPremium={isPremium}
          />
          <div className={css["results"]}>
            {mutation.isLoading && (
              <Spinner type="Rings" text="Robôs trabalhando..." />
            )}
            {mutation.data && mutation.isSuccess && (
              <SupplyDemandResult
                {...mutation.data}
                {...mutation.variables}
                showUpgrade={showUpgrade}
                isMobile={isMobile}
              />
            )}
            {mutation.isIdle && (
              <div className={css["welcomeContainer"]}>
                {isLoggedIn && (
                  <>
                    <img className={css["logo"]} src={LogoIcon} />
                    <div>
                      Selecione o ativo, o ano-base e o período utilizados para
                      cálculo da volatilidade.
                    </div>
                  </>
                )}
                {!isLoggedIn && (
                  <div className={css["ctaContainer"]}>
                    <RegisterButton copy="Crie sua conta para projetar suportes e resistências em outros anos, além de alterar o período da volatilidade." />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Tooltip id="vol-tip" clickable delayHide={100} />
      <SupplyDemandPopup
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        sequence={sequence}
      />
    </AppLayout>
  );
};

export default SupplyDemandContainer;
