import {
  init,
  setUserId,
  Identify,
  identify,
  reset,
  track,
} from "@amplitude/analytics-browser";
import { isEmpty } from "lodash";

export const EVENT_MAP = {
  // Signup
  SIGNUP_CLICKED: "create account started",
  CREATE_ACCOUNT_BUTTON_CLICKED: "create account button clicked",
  ACCOUNT_CREATED: "account created",
  ACCOUNT_VERIFIED: "account verified",
  EMAIL_WARNING_DISPLAYED: "email warning displayed",
  EMAIL_VALIDATED: "email validated",

  // General
  LOGIN_CLICKED: "login button clicked",
  LOGGED_IN: "logged in",
  LOGGED_OUT: "logged out",
  PAGE_NOT_FOUND: "page not found",

  // Engagement
  APP_OPENED: "app opened",
  BACKTEST_EXECUTED: "backtest executed",
  BACKTEST_CLICKED: "run backtest clicked",
  BACKTEST_SAVED: "backtest saved",
  BACKTEST_REMOVED: "backtest removed",
  BACKTEST_RETRIEVED: "backtest retrieved",
  BACKTEST_REPORTED: "backtest reported",
  BACKTEST_EXPORTED: "backtest exported",
  TOP_BACKTESTS_VIEWED: "backtests ranking viewed",
  BACKTEST_RANKING_SELECTED: "backtest ranking selected",
  BACKTEST_PREMIUM_POPUP_VIEWED: "backtest premium popup viewed",
  POST_VIEWED: "post viewed",
  POST_SHARED: "post shared",
  ABOUT_VIEWED: "about viewed",
  THEME_CHANGED: "theme changed",
  TRADERS_EDEN_SELECTED: "traders eden selected",
  SETUP_123_SELECTED: "setup 123 selected",
  PFR_SELECTED: "pfr selected",
  INSIDE_BAR_SELECTED: "inside bar selected",
  SCREENING_VIEWED: "screening viewed",
  RSI_CLICKED: "rsi clicked",
  SUBSCRIBE_BUTTON_CLICKED: "subscribed button clicked",
  WHATSAPP_BUTTON_CLICKED: "whatsapp button clicked",
  INSTAGRAM_BANNER_CLICKED: "instagram banner clicked",
  TELEGRAM_BANNER_CLICKED: "telegram banner clicked",
  YOUTUBE_BANNER_CLICKED: "youtube banner clicked",
  YOUTUBE_VIDEO_PLAYED: "youtube video played",
  HISTORICAL_RETURN_EXECUTED: "historical return executed",

  // Supply and Demand
  SUPPLY_DEMAND_EXECUTED: "supply and demand executed",
  SUPPLY_DEMAND_PREMIUM_POPUP_VIEWED: "supply and demand premium popup viewed",
  SUPPLY_DEMAND_SCREENING_VIEWED: "supply and demand screening viewed",
  STRATEGIES_LANDING_PAGE_VIEWED: "strategies landing page viewed",
  BACKTEST_SCORE_PAGE_VIEWED: "backtest score page viewed",
  STRATEGY_VIEWED: "strategy viewed",
  FOOTER_LINK_CLICKED: "footer link clicked",
  RELEASES_VIEWED: "releases viewed",

  COINTEGRATION_SAVED: "cointegration saved",
  COINTEGRATION_REMOVED: "cointegration removed",
  COINTEGRATION_REFRESHED: "cointegration refreshed",

  // Portfolio
  PORTFOLIO_APPLIED: "portfolio applied",
  PORTFOLIO_CREATED: "portfolio created",
  PORTFOLIO_RENAMED: "portfolio renamed",
  PORTFOLIO_CHANGED: "portfolio changed",
  PORTFOLIO_DELETED: "portfolio deleted",

  // Alerts
  ALERTS_VIEWED: "alerts viewed",
  ALERT_CLICKED: "alert clicked",
  ALERT_SAVED: "alert saved",
  PHONE_UPDATED: "phone updated",
  ADD_PHONE_BUTTON_CLICKED: "add phone button clicked",
  ALERT_MONITOR_VIEWED: "alert monitor viewed",
  ALERT_STATUS_CHANGED: "alert status changed",
  SAVE_ALERT_ERROR: "save alert error",
  NOTIFICATION_UPDATED: "notification updated",

  // Checkout
  VIEW_PLANS_BUTTON_CLICKED: "view plans button clicked",
  PLANS_VIEWED: "plans viewed",
  LANDING_PAGE_VIEWED: "landing page viewed",
  LEAD_PAGE_VIEWED: "lead page viewed",
  PLAN_SELECTED: "plan selected",
  CHECKOUT_SESSION_CREATED: "checkout session created",
  CHECKOUT_COMPLETED: "checkout completed",
  PLANS_POPUP_VIEWED: "plans popup viewed",
  PUBLIC_POPUP_VIEWED: "public popup viewed",
  MANAGE_SUBSCRIPTION_BUTTON_CLICKED: "manage subscription button clicked",

  // Cointegration
  COINTEGRATION_EXECUTED: "cointegration executed",
  COINTEGRATION_RADAR_VIEWED: "cointegration radar viewed",
  COINTEGRATION_FAVORITES_VIEWED: "favorites cointegrations viewed",
  COINTEGRATION_BATCH_CREATED: "cointegration batch created",
  COINTEGRATION_REGISTER_BUTTON_CLICKED:
    "cointegration register button clicked",
  COINTEGRATION_REGISTER_SAVED: "cointegration register saved",
  COINTEGRATION_TRADE_VIEW_BUTTON_CLICKED:
    "cointegration trade view button clicked",
  COINTEGRATION_TRADE_DELETE_BUTTON_CLICKED:
    "cointegration trade delete button clicked",
  COINTEGRATION_TRADE_COMPLETE_BUTTON_CLICKED:
    "cointegration trade complete button clicked",
  COINTEGRATION_TRADE_SCREEN_VIEWED: "cointegration trade screen viewed",

  // Risk
  VAR_EXECUTED: "var executed",
  BETA_EXECUTED: "beta executed",

  // Onboarding steps
  ONBOARDING_STEP_VIEWED: "onboarding step viewed",
  ONBOARDING_STEP_DISMISSED: "onboarding step dismissed",
  ONBOARDING_STEP_COMPLETED: "onboarding step completed",

  // User batches
  BATCH_BUTTON_CLICKED: "batch button clicked",
  BATCH_CREATED: "batch created",

  // Feed
  FEED_VIEWED: "feed viewed",
  FEED_WIDGET_BUTTON_CLICKED: "feed widget button clicked",

  // Robots
  ROBOTS_VIEWED: "robots viewed",
  ROBOT_VIEWED: "robot viewed",
};

const setUserData = user => {
  setUserId(user.userId);
  setProperty("isPremium", user.isPremium);
  setProperty("phone", user.phone);
};

export const initAmplitude = async () => {
  // Grab user from LS if existing, and set properties
  const storedUser = localStorage.getItem("qb.user");
  if (storedUser) {
    const user = JSON.parse(storedUser);
    if (!isEmpty(user)) setUserData(user);
  }

  await init(process.env.GATSBY_AMPLITUDE_API_KEY, undefined, {
    minIdLength: 1,
    defaultTracking: {
      sessions: true,
    },
    attribution: {
      disabled: true,
    },
    includeReferrer: true,
    saveParamsReferrerOncePerSession: false,
    includeUtm: true,
    includeGclid: true,
  }).promise;

  // Increment user visits if it's a valid user, after he's been identified
  if (storedUser) {
    logAmplitudeEvent(EVENT_MAP.APP_OPENED, {
      page: window.location.pathname,
      referrer: document.referrer,
    });
    incrementProperty("visits");
    addUnique("referrers", document.referrer);
  }
};

export const logAmplitudeEvent = (...args) => {
  return track(...args);
};

export const trackLogin = user => {
  setUserData(user);
  logAmplitudeEvent(EVENT_MAP.LOGGED_IN);
  incrementProperty("loginCount");
};

export const trackLogout = async () => {
  // Await before resetting so we can capture which user logged out
  await track(EVENT_MAP.LOGGED_OUT).promise;
  reset();
};

export const trackPlansButtonClicked = (eventProperties = {}) => {
  logAmplitudeEvent(EVENT_MAP.VIEW_PLANS_BUTTON_CLICKED, {
    ...eventProperties,
    page: window.location.pathname,
  });
  incrementProperty("viewPlansButtonClicked");
};

export const trackCheckoutSessionStarted = (eventProperties = {}) => {
  logAmplitudeEvent(EVENT_MAP.CHECKOUT_SESSION_CREATED, {
    ...eventProperties,
    page: window.location.pathname,
  });
  incrementProperty("checkoutStarted");
};

export const trackScreeningViewed = (eventProperties = {}) => {
  logAmplitudeEvent(EVENT_MAP.SCREENING_VIEWED, {
    ...eventProperties,
    page: window.location.pathname,
  });
  incrementProperty("screeningsViewed");
  if (eventProperties.key) {
    addUnique("screeningsOfInterest", eventProperties.key);
  }
};

export const setProperty = (propertyName, value) => {
  const identifyObj = new Identify();
  identifyObj.set(propertyName, value);
  identify(identifyObj);
};

export const incrementProperty = (propertyName, increment = 1) => {
  const identifyObj = new Identify();
  identifyObj.add(propertyName, increment);
  identify(identifyObj);
};

export const addUnique = (listName, value) => {
  const identifyObj = new Identify();
  identifyObj.postInsert(listName, value);
  identify(identifyObj);
};
