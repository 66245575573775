import { useAPI } from "../../hooks/useAPI";
import { useInfiniteQuery } from "react-query";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import SavedBacktests from "./SavedBacktests";
import { useEffect, useState } from "react";
import * as css from "./HistoryContainer.module.css";
import { useInView } from "react-intersection-observer";
import { useAuth } from "../../hooks/useAuth";
import UpgradeSection from "../common/UpgradeSection";
import { Link } from "gatsby";
import LogoIcon from "../../images/logo.svg";
import Select from "react-select";
import { STRATEGY_OPTIONS, TIMEFRAME_OPTIONS } from "../../utils/constants";
import { orderBy } from "lodash";
import { SELECT_SMALL } from "../../utils/select";
import GroupLabel from "../common/GroupLabel";
import { useAssets } from "../../hooks/useAssets";
import ArrowDownIcon from "../../icons/arrow-down.svg";
import { Tooltip } from "react-tooltip";

const ORDER_BY_OPTIONS = [
  { label: "Criação", value: "id" },
  { label: "EV", value: "ev" },
  { label: "Resultado", value: "pct_profit" },
  { label: "Drawdown", value: "drawdown" },
  { label: "Operações", value: "num_operations" },
];

const getStrategyOptions = () => {
  const long = [];
  const short = [];
  STRATEGY_OPTIONS.forEach(s => (s.isShort ? short.push(s) : long.push(s)));
  return [
    {
      label: "Compra",
      options: orderBy(long, ["order"], ["asc"]),
    },
    { label: "Venda", options: short },
  ];
};

const HistoryContainer = () => {
  const callAPI = useAPI({ withCredentials: true });
  const { user } = useAuth();

  const { grouped: assets } = useAssets({ requirePremium: false });

  const [selectedId, setSelectedId] = useState(null);
  const [strategy, setStrategy] = useState(null);
  const [asset, setAsset] = useState(null);
  const [timeframe, setTimeframe] = useState(null);
  const [orderByField, setOrderByField] = useState(ORDER_BY_OPTIONS[0]);
  const [isAsc, setIsAsc] = useState(false);

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      "allBacktests",
      user.userId,
      strategy,
      asset,
      timeframe,
      orderByField,
      isAsc,
    ],
    ({ pageParam = 1 }) => {
      const params = new URLSearchParams();
      params.append("page", pageParam);

      if (strategy) params.append("strategy", strategy.value);
      if (asset) params.append("ticker", asset.value);
      if (timeframe) params.append("timeframe_id", timeframe.value);
      if (orderByField) params.append("order_by", orderByField.value);

      params.append("ascending", isAsc);

      return callAPI(`/api/backtest/?${params.toString()}`);
    },
    {
      onError: showError,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length !== 10) return undefined; // Means it's the last page
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    if (inView) fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const canLoadMore = user.isPremium && hasNextPage;

  return (
    <div>
      {user.isPremium && (
        <div className={css["filter"]}>
          <Tooltip id="history-filter-tp" />
          <div>
            <label htmlFor="backtest-strategy">Estratégia</label>
            <Select
              id="backtest-strategy"
              options={getStrategyOptions()}
              value={strategy}
              onChange={option => setStrategy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "240px",
                }),
                indicatorContainer: provided => ({
                  ...provided,
                  padding: "8px 4px",
                }),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
              isClearable
            />
          </div>
          <div>
            <label htmlFor="backtest-asset">Ativo</label>
            <Select
              id="backtest-asset"
              options={assets}
              value={asset}
              onChange={option => setAsset(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "150px",
                }),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
              isClearable
            />
          </div>
          <div>
            <label htmlFor="backtest-timeframe">Timeframe</label>
            <Select
              id="backtest-timeframe"
              options={TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "150px",
                }),
              }}
              isClearable
              placeholder="Selecionar..."
            />
          </div>
          <div>
            <label htmlFor="backtest-order-by">Ordenar Por</label>
            <Select
              id="backtest-order-by"
              options={ORDER_BY_OPTIONS}
              value={orderByField}
              onChange={option => setOrderByField(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "135px",
                }),
              }}
              placeholder="Selecionar..."
            />
          </div>
          <div className={css["buttonContainer"]}>
            <button
              onClick={() => setIsAsc(!isAsc)}
              className={css["sortButton"]}
              data-tooltip-id="history-filter-tp"
              data-tooltip-content={`Ordenar resultados ${
                isAsc ? "do menor pro maior" : "do maior pro menor"
              }.`}>
              <ArrowDownIcon className={isAsc ? css["isAsc"] : undefined} />
            </button>
          </div>
        </div>
      )}
      {isLoading && <Spinner type="Rings" text="Carregando..." />}
      {isSuccess && (
        <div className={css["container"]}>
          {data?.pages[0].length > 0 && (
            <SavedBacktests
              data={data.pages.flat()}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
            />
          )}
          {canLoadMore && (
            <div className={css["fetchNext"]}>
              <button
                ref={ref}
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}>
                {isFetchingNextPage ? "Carregando..." : "Ver mais"}
              </button>
            </div>
          )}
          {data?.pages[0].length === 0 && (
            <div className={css["welcomeContainer"]}>
              <div>
                Visite o nosso{" "}
                <Link to="/backtests">Simulador de Estratégias</Link>.
              </div>
              <img className={css["logo"]} src={LogoIcon} />
              <div>O histórico de seus backtests aparecerá aqui.</div>
            </div>
          )}
          {!user.isPremium && (
            <UpgradeSection copy="Faça o upgrade de sua conta para liberar filtros de pesquisa no histórico de seus backtests. Assim é possível buscar os seus melhores backtests em segundos!" />
          )}
        </div>
      )}
    </div>
  );
};

export default HistoryContainer;
