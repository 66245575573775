import { navigate } from "gatsby";
import PropTypes from "prop-types";
import { useSessionStorage } from "react-use";
import { useAuth } from "../../hooks/useAuth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { isLoggedIn } = useAuth();

  const [redirectAfterLogin, setRedirect] = useSessionStorage(
    "qb.redirect",
    "/backtests"
  );

  // If we’re not logged in, redirect to the login page.
  if (!isLoggedIn && location.pathname !== `/login`) {
    // If the page the user is trying to go is different from the one saved in the session storage,
    // replace it so we can get the right URL when redirecting after login
    const currentLocation = location.pathname + location.search;
    if (currentLocation !== redirectAfterLogin) setRedirect(currentLocation);

    navigate(`/login`, {
      state: {
        // Check if there is query parameter in order to display message on login page
        isNewUser: location.search.includes("isPremium=1"),
      },
    });
    return null;
  }

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default PrivateRoute;
