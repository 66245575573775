import ReportFlagIcon from "../../icons/report-flag.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./ReportBacktest.module.css";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { toast } from "react-toastify";
import Modal from "../common/Modal";
import { useState } from "react";
import Loader from "react-loader-spinner";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";

const ReportBacktest = ({ result }) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [text, setText] = useState("");

  const resetModal = () => {
    setOpen(false);
    setSelectedOption(null);
    setText("");
  };

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/backtest/${result.id}/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ report_type: selectedOption, text }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        resetModal();
        toast.success(
          "Backtest reportado com sucesso. Obrigado por ajudar o QuantBrasil a ficar cada vez melhor!"
        );
      },
    }
  );

  const onClick = () => {
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.BACKTEST_REPORTED, {
      id: result.id,
      strategy: result.strategy_id,
      ticker: result.symbol,
      timeframeId: result.timeframe_id,
      selectedOption,
      text,
    });
    incrementProperty("backtestsReported");
  };

  return (
    <>
      <button
        data-tooltip-id="report-bt-tooltip"
        data-tooltip-content="Reportar backtest"
        disabled={mutation.isLoading}
        onClick={() => setOpen(true)}
        className={css["reportButton"]}>
        {mutation.isLoading ? (
          <div className={css["spinner"]}>
            <Spinner type="Rings" size={22} />
          </div>
        ) : (
          <>
            <ReportFlagIcon />
            <span>Reportar</span>
          </>
        )}
        <Tooltip id="report-bt-tooltip" />
      </button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["modalContainer"]}>
          <h3>Reportar backtest</h3>
          <p>
            Embora haja um rigoroso controle de qualidade nas estratégias e em
            nossa base de dados, é possível que eventualmente existam falhas no
            backtest. Com a sua ajuda podemos identificar e corrigir os erros,
            deixando a ferramenta cada vez mais robusta.
          </p>
          <div className={css["form"]}>
            <div className={css["radioContainer"]}>
              <label className={css["formLabel"]}>
                Que problema você gostaria de reportar?
              </label>
              <div>
                <input
                  type="radio"
                  id="badData"
                  name="reportType"
                  value="BAD_DATA"
                  checked={selectedOption === "BAD_DATA"}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <label htmlFor="badData">Dados incorretos</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="missingData"
                  name="reportType"
                  value="MISSING_DATA"
                  checked={selectedOption === "MISSING_DATA"}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <label htmlFor="missingData">Dados faltantes</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="tradeIncorrect"
                  name="reportType"
                  value="TRADE_INCORRECT"
                  checked={selectedOption === "TRADE_INCORRECT"}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <label htmlFor="tradeIncorrect">Trade incorreto</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="otherReason"
                  name="reportType"
                  value="OTHER"
                  checked={selectedOption === "OTHER"}
                  onChange={e => setSelectedOption(e.target.value)}
                />
                <label htmlFor="otherReason">Outro</label>
              </div>
            </div>
            {selectedOption && (
              <>
                {selectedOption === "BAD_DATA" && (
                  <div className={css["warning"]}>
                    <span>Atenção:</span> Preços podem variar entre provedores,
                    especialmente para dados mais antigos e no intraday. Para
                    fins de backtest, o importante é analisar a <b>diferença</b>{" "}
                    entre os preços de entrada e saída.
                  </div>
                )}
                <div className={css["textContainer"]}>
                  <label className={css["formLabel"]}>Descreva o erro</label>
                  <div className={css["hint"]}>
                    Por favor, inclua todos os detalhes relevantes. Exemplo: as
                    datas dos trades incorretos, por que você acredita estar
                    errado, em qual provedor você está conferindo os dados, etc.
                  </div>
                  <textarea
                    value={text}
                    onChange={e => setText(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
          {selectedOption && (
            <div className={css["modalButtonsContainer"]}>
              <button
                disabled={
                  mutation.isLoading || selectedOption === null || text === ""
                }
                onClick={onClick}
                className={css["send"]}>
                {mutation.isLoading ? (
                  <Loader type="TailSpin" color="#fff" height={18} width={18} />
                ) : (
                  "Enviar"
                )}
              </button>
              <button className={css["cancel"]} onClick={() => resetModal()}>
                Cancelar
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

ReportBacktest.propTypes = {
  result: PropTypes.object.isRequired,
};

export default ReportBacktest;
