import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useSessionStorage } from "react-use";

import { showError } from "../utils/utils";
import { useAPI } from "./useAPI";
import { useAuth } from "./useAuth";

export const usePortfolios = () => {
  const callAPI = useAPI({ withCredentials: true });
  const { isLoggedIn } = useAuth();

  const [portfolios, setPortfolios] = useSessionStorage("qb.portfolios", null);
  const [selectedId, setSelectedId] = useSessionStorage(
    "selectedPortfolioId",
    null
  );

  const options = portfolios
    ? portfolios.map(p => ({
        label: p.name,
        value: p.id,
      }))
    : [];

  const selectedPortfolio =
    isLoggedIn &&
    selectedId &&
    portfolios &&
    options.find(p => p.value === selectedId);

  const selectedAssets = !isEmpty(selectedPortfolio)
    ? portfolios.find(p => p.id === selectedPortfolio.value).symbols
    : [];

  const {
    data,
    error,
    isSuccess,
    refetch: fetchPortfolios,
    ...rest
  } = useQuery("myPortfolios", () => callAPI(`/api/portfolio/`), {
    enabled: false,
  });

  useEffect(() => {
    if (isLoggedIn) {
      fetchPortfolios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      setPortfolios(data);
    }
  }, [data, isSuccess, setPortfolios]);

  return {
    portfolios,
    fetchPortfolios,
    isSuccess,
    error,
    asOptions: options,
    selectedPortfolio,
    selectedAssets,
    setSelectedId,
    selectedId,
    ...rest,
  };
};
