import PropTypes from "prop-types";
import { useAuth } from "../../hooks/useAuth";
import { useIsClient } from "../../hooks/useIsClient";
import PremiumBanner from "../common/PremiumBanner";
import RegisterBanner from "../common/RegisterBanner";
import Footer from "../Footer";
import Navbar from "../Navbar";
import SEO from "../seo";
import * as css from "./AppLayout.module.css";

const AppLayout = ({
  children,
  seoProps,
  ctaMessage,
  premiumMessage,
  hideBanners = false,
}) => {
  const { isLoggedIn, user } = useAuth();
  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const showRegisterCta = !hideBanners && !isLoggedIn && isClient;
  const showPremiumCta =
    !hideBanners && premiumMessage && isLoggedIn && !user.isPremium;

  return (
    <div className={css["container"]}>
      <SEO {...seoProps} />
      <Navbar />
      {showPremiumCta && (
        <PremiumBanner key={key} ctaMessage={premiumMessage} />
      )}
      {showRegisterCta && <RegisterBanner key={key} ctaMessage={ctaMessage} />}
      <div className={css["content"]}>{children}</div>
      <Footer />
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoProps: PropTypes.object.isRequired,
  ctaMessage: PropTypes.string,
  premiumMessage: PropTypes.string,
  hideBanners: PropTypes.bool,
};

export default AppLayout;
