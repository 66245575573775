// extracted by mini-css-extract-plugin
export var arrowContainer = "bandas-de-bollinger-module--arrowContainer--4e8ff";
export var arrowDown = "bandas-de-bollinger-module--arrowDown--f2552";
export var arrowUp = "bandas-de-bollinger-module--arrowUp--a908e";
export var bandsCell = "bandas-de-bollinger-module--bandsCell--0a652";
export var buttonContainer = "bandas-de-bollinger-module--buttonContainer--50038";
export var checkbox = "bandas-de-bollinger-module--checkbox--a2291";
export var checkboxFilter = "bandas-de-bollinger-module--checkboxFilter--5da9d";
export var empty = "bandas-de-bollinger-module--empty--35b1d";
export var filter = "bandas-de-bollinger-module--filter--b148a";
export var input = "bandas-de-bollinger-module--input--8cd12";
export var legend = "bandas-de-bollinger-module--legend--874b7";
export var periodSelector = "bandas-de-bollinger-module--periodSelector--bfd50";
export var star = "bandas-de-bollinger-module--star--61fea";
export var tableContainer = "bandas-de-bollinger-module--tableContainer--6de73";
export var youtubeContainer = "bandas-de-bollinger-module--youtubeContainer--e8d6a";