import PropTypes from "prop-types";
import ZscoreChart from "./ZscoreChart";
import * as css from "./CointegrationResult.module.css";
import { toPercent } from "../../utils/utils";
import InfoIcon from "../../icons/info-question-circle.svg";
import { Tooltip } from "react-tooltip";
import UpgradeSection from "../common/UpgradeSection";
import BetaRotation from "./BetaRotation";
import { useAuth } from "../../hooks/useAuth";
import SaveCointegration from "./SaveCointegration";
import RegisterCointegration from "./RegisterCointegration";

const getColor = value => ({
  color: value >= 0.95 ? "#49ce8b" : "#d64242",
});

const CointegrationResult = ({
  result,
  showUpgrade,
  isFavorite,
  onToggleFavorite,
  allowToFollow = true,
}) => {
  const { isLoggedIn } = useAuth();

  const showBetaRotation =
    result.beta_data.length > 0 &&
    result.beta_mean &&
    result.beta_std &&
    result.beta_volatility;

  return (
    <div id="cointegration-result">
      <div className={css["statsLabel"]}>
        Resultado{" "}
        {isLoggedIn && (
          <div className={css["buttonsContainer"]}>
            {!!onToggleFavorite && (
              <SaveCointegration
                isFavorite={isFavorite}
                onToggleFavorite={onToggleFavorite}
                result={result}
              />
            )}
            {allowToFollow && <RegisterCointegration result={result} />}
          </div>
        )}
      </div>
      <div className={css["stats"]}>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>
              Meia-vida{" "}
              <InfoIcon
                data-tooltip-content="Tempo estimado de duração para que o resíduo retorne a média."
                data-tooltip-id="coint-result-tip"
              />
            </div>
            <div>
              {Math.round(result.halflife)}
              <span className={css["complement"]}>
                dia{Math.round(result.halflife) > 1 ? "s" : ""}
              </span>
            </div>
          </div>
          <div className={css["stat"]}>
            <div>
              Beta{" "}
              <InfoIcon
                data-tooltip-content="Quantidade do lote de compra ou venda do ativo de comparação dado 1
          lote do ativo base."
                data-tooltip-id="coint-result-tip"
              />
            </div>
            <div>{result.beta.toFixed(2)}</div>
          </div>
          <div className={css["stat"]}>
            <div>
              Confiança (Valor-p){" "}
              <InfoIcon
                data-tooltip-content="Probabilidade do resultado não ter sido encontrado por mero acaso."
                data-tooltip-id="coint-result-tip"
              />
            </div>
            <div style={getColor(result.confidence)}>
              {toPercent(result.confidence, 2)}
              <span className={css["complement"]}>
                ({result.p_value.toFixed(2)})
              </span>
            </div>
          </div>
        </div>
        {result.beta < 0 && (
          <div className={css["info"]}>
            O teste de cointegração apresentou <span>beta negativo</span>. A
            estratégia passa a ser <span>long & long</span> caso o Z-Score
            esteja na banda inferior, ou <span>short & short</span> caso esteja
            na banda superior. A proporção continua sendo dada pelo beta.
          </div>
        )}
      </div>
      <div className={css["chartContainer"]}>
        <div>
          Z-Score{" "}
          <InfoIcon
            data-tooltip-content="Medida estatística utilizada para descrever a diferença do resíduo do
          par com a sua média, normalizado pelo desvio padrão."
            data-tooltip-id="coint-result-tip"
          />
        </div>
        <ZscoreChart data={result.data} />
      </div>
      {showBetaRotation ? (
        <div className={css["chartContainer"]}>
          <div>
            Beta Rotation{" "}
            <InfoIcon
              data-tooltip-content="Evolução do beta nos últimos 50 dias. As bandas são geradas a partir
          de 2 desvios-padrão da média observada no período."
              data-tooltip-id="coint-result-tip"
            />
          </div>
          <div className={css["volatility"]}>
            Coeficiente de Variação:{" "}
            <span>{toPercent(result.beta_volatility, 2)}</span>{" "}
            <InfoIcon
              data-tooltip-html="Coeficiente de Variação = desvio-padrão (σ) ÷ média, é um indicador de <b>variabilidade</b>.<br />Quanto menor o Coeficiente, mais <b>estável</b> é o beta."
              data-tooltip-id="coint-result-tip"
            />
          </div>
          <BetaRotation
            data={result.beta_data}
            mean={result.beta_mean}
            std={result.beta_std}
          />
        </div>
      ) : null}
      {showUpgrade && (
        <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear a cointegração em todos os ativos do Ibovespa e em qualquer janela!" />
      )}
      <Tooltip id="coint-result-tip" delayHide={100} clickable />
    </div>
  );
};

CointegrationResult.propTypes = {
  result: PropTypes.object.isRequired,
  showUpgrade: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func,
  allowToFollow: PropTypes.bool,
};

export default CointegrationResult;
