import { Link } from "gatsby";
import { useState, useRef, useEffect, useMemo } from "react";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./VaRContainer.module.css";
import { useAssets } from "../../hooks/useAssets";
import { usePortfolios } from "../../hooks/usePortfolios";
import ShowPortfolios from "../portfolios/ShowPortfolios";
import { xor } from "lodash";
import VaRResult from "./VaRResult";
import VaRFilter from "./VarFilter";
import AssetsPanel from "./AssetsPanel";
import HelpButton from "./HelpModal";
import { useIsClient } from "../../hooks/useIsClient";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";

const YEARS_OPTIONS = [
  { value: 1, label: "1 ano" },
  { value: 2, label: "2 anos" },
  { value: 3, label: "3 anos" },
  { value: 4, label: "4 anos" },
  { value: 5, label: "5 anos" },
];

const VaRContainer = () => {
  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn, user } = useAuth();

  const isPremium = user?.isPremium;

  const PROTECTED_YEARS_OPTIONS = YEARS_OPTIONS.map(option => {
    if (!isPremium && option.value > 1) return { ...option, isDisabled: true };
    return option;
  });

  const [years, setYears] = useState(
    PROTECTED_YEARS_OPTIONS.find(({ value }) => value === 1)
  );
  const [confidence, setConfidence] = useState(95);
  const [assets, setAssets] = useState([]);
  const [showPortfolios, setShowPortfolios] = useState(false);

  const confidenceRef = useRef(null);

  const isClient = useIsClient();

  const onVaRExecuted = () => {
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.VAR_EXECUTED);
    incrementProperty("varExecuted");
  };

  const { grouped: allAssets } = useAssets({ requirePremium: true });

  const availableTickers = useMemo(() => {
    let allTickers = [];
    allAssets.forEach(({ options }) => {
      allTickers = [...allTickers, ...options];
    });
    return allTickers;
  }, [allAssets]);

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const mutation = useMutation(
    () =>
      callAPI(`/api/metrics/var`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          alpha: (100 - confidence) / 100,
          years: years.value,
          portfolio: assets.map(asset => ({
            ticker: asset.value,
            weight: asset.weight / 100,
          })),
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        confidenceRef.current = confidence;
      },
    }
  );

  useEffect(() => {
    if (showPortfolios && selectedAssets.length > 0) {
      const toBeAdded = availableTickers.filter(a =>
        selectedAssets.includes(a.value)
      );
      if (
        xor(
          assets.map(a => a.value),
          toBeAdded.map(a => a.value)
        ).length > 0
      ) {
        setAssets(toBeAdded);
      }
    }
  }, [availableTickers, assets, selectedAssets, showPortfolios]);

  const title = "VaR (Value-at-Risk)";

  return (
    <AppLayout
      ctaMessage="Crie sua conta para calcular o VaR de um portfólio"
      premiumMessage="Seja Premium para desbloquear todos os filtros e ativos"
      seoProps={{
        title,
        description:
          "Calcule o VaR (Value-at-Risk) de um portfólio utilizando a calculadora do QuantBrasil.",
      }}>
      <div>
        <h1 className={css["header"]}>
          {title} <HelpButton />
        </h1>
        <p style={{ marginBottom: "20px" }}>
          Utilize a ferramenta abaixo para calcular o VaR (Value-at-Risk) de um
          portfólio. Dúvidas?{" "}
          <Link to="/utilizando-o-var-value-at-risk-no-gerenciamento-de-risco-de-um-portfolio">
            Utilizando o VaR (Value-at-Risk) no Gerenciamento de Risco de um
            Portfólio
          </Link>
          .
        </p>
        {!isLoggedIn && isClient && (
          <div className={css["welcomeContainer"]}>
            <div className={css["ctaContainer"]}>
              <RegisterButton copy="Crie sua conta para calcular o VaR de um portfólio, simular backtests, testar estratégias de Long & Short, e muito mais." />
            </div>
          </div>
        )}
        {isLoggedIn && isClient && (
          <div>
            <VaRFilter
              years={years}
              yearsOptions={PROTECTED_YEARS_OPTIONS}
              setYears={setYears}
              confidence={confidence}
              setConfidence={setConfidence}
              assetsOptions={allAssets}
              assets={assets}
              setAssets={setAssets}
              showPortfolios={showPortfolios}
              setShowPortfolios={setShowPortfolios}
            />
            <ShowPortfolios
              showPortfolios={showPortfolios}
              setShowPortfolios={setShowPortfolios}
              portfolioOptions={portfolioOptions}
              setSelectedId={setSelectedId}
              selectedPortfolio={selectedPortfolio}
              requirePremium
            />
            <div className={css["panel"]}>
              <AssetsPanel
                assets={assets}
                setAssets={setAssets}
                isLoading={mutation.isLoading}
                onSubmit={onVaRExecuted}
                buttonText="Calcular VaR"
              />
              <div className={css["results"]}>
                {mutation.isLoading && (
                  <Spinner type="Rings" text="Robôs trabalhando..." />
                )}
                {mutation.data && mutation.isSuccess && (
                  <VaRResult
                    data={mutation.data.histogram_data}
                    valueAtRisk={mutation.data.var}
                    confidence={confidenceRef.current}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </AppLayout>
  );
};

export default VaRContainer;
