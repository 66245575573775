import PlansContainer from "../components/checkout/PlansContainer";
import PropTypes from "prop-types";

const PlanosIndex = ({ location }) => <PlansContainer location={location} />;

PlanosIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PlanosIndex;
