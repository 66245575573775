// extracted by mini-css-extract-plugin
export var bounce = "InfiniteFeed-module--bounce--8e250";
export var buttonContainer = "InfiniteFeed-module--buttonContainer--0293c";
export var col1 = "InfiniteFeed-module--col1--5cabd";
export var col2 = "InfiniteFeed-module--col2--14796";
export var colHeader = "InfiniteFeed-module--colHeader--29173";
export var container = "InfiniteFeed-module--container--6735a";
export var feedContainer = "InfiniteFeed-module--feedContainer--c051e";
export var fetchNext = "InfiniteFeed-module--fetchNext--10808";
export var filter = "InfiniteFeed-module--filter--e5eb2";
export var goBackTop = "InfiniteFeed-module--goBackTop--639e1";
export var page = "InfiniteFeed-module--page--a7a24";
export var premium = "InfiniteFeed-module--premium--e6bea";
export var upgradeContainer = "InfiniteFeed-module--upgradeContainer--bfde5";
export var widgetsContainer = "InfiniteFeed-module--widgetsContainer--c3e95";