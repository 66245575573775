// extracted by mini-css-extract-plugin
export var avatarButton = "Navbar-module--avatarButton--2e81c";
export var hamburguer = "Navbar-module--hamburguer--32b3a";
export var large = "Navbar-module--large--51002";
export var links = "Navbar-module--links--4e3aa";
export var logo = "Navbar-module--logo--9a862";
export var navbar = "Navbar-module--navbar--6e166";
export var separator = "Navbar-module--separator--99e66";
export var signin = "Navbar-module--signin--1bfe5";
export var slideDown = "Navbar-module--slideDown--5cfb6";
export var slideEffect = "Navbar-module--slide-effect--17180";
export var small = "Navbar-module--small--b8c8d";
export var upgrade = "Navbar-module--upgrade--c84a2";