import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAuth } from "./useAuth";
import { useAPI } from "./useAPI";

const useOnboardingStep = stepId => {
  const [hasDismissed, setHasDismissed] = useState(false);
  const queryClient = useQueryClient();

  const callAPI = useAPI({ withCredentials: true });
  const { user, isLoggedIn } = useAuth();

  const isPremium = Boolean(user?.isPremium);

  // Fetch all onboarding steps
  const { data, isLoading, isError, error } = useQuery(
    ["onboardingSteps", user?.userId],
    () => callAPI(`/api/onboarding/`),
    {
      enabled: isLoggedIn,
    }
  );

  // Find step by stepId
  const step = data?.find(step => step.step_id === stepId);

  // Mutation for updating onboarding step
  const mutation = useMutation(
    stepId =>
      callAPI(`/api/onboarding/${stepId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: true,
        }),
      }),
    {
      onSuccess: () => {
        // Invalidate and refetch onboarding steps query when the mutation is successful
        queryClient.invalidateQueries("onboardingSteps");
      },
    }
  );

  const showStep =
    !hasDismissed &&
    step &&
    !step.completed &&
    (step.isPremium ? isPremium : true);

  const markStepAsCompleted = () => {
    if (!isLoggedIn || !showStep) return;
    setHasDismissed(true);
    mutation.mutate(stepId);
  };

  return {
    step,
    isLoading,
    isError,
    error,
    markStepAsCompleted,
    showStep,
  };
};

export default useOnboardingStep;
