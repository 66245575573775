import { Link } from "gatsby";
import PropTypes from "prop-types";
import * as css from "./PremiumBanner.module.css";
import Icon from "../../icons/star.svg";
import { useConfig } from "../../hooks/useConfig";
import { trackPlansButtonClicked } from "../../utils/amplitude";

const DEFAULT_CTA = "Aproveite o QuantBrasil sem restrições. Seja Premium!";

// eslint-disable-next-line no-unused-vars
const PremiumBanner = ({ ctaMessage = DEFAULT_CTA }) => {
  const { IS_BLACKFRIDAY, IS_CHRISTMAS, PREMIUM_BANNER_MESSAGE } = useConfig();
  return (
    <div
      className={css["banner"]}
      style={{
        background: IS_BLACKFRIDAY
          ? "#100826"
          : IS_CHRISTMAS
          ? "#34a65f"
          : undefined,
      }}>
      <span>{PREMIUM_BANNER_MESSAGE || ctaMessage}</span>
      <Link
        onClick={() =>
          trackPlansButtonClicked({ ctaText: "Upgrade", fromBanner: true })
        }
        to="/planos">
        <Icon />
        Upgrade
      </Link>
    </div>
  );
};

PremiumBanner.propTypes = {
  ctaMessage: PropTypes.string,
};

export default PremiumBanner;
