import { Link } from "gatsby";
import noScroll from "no-scroll";
import PropTypes from "prop-types";
import { useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from "../icons/close.svg";
import Logo from "../images/logo-horizontal-white.svg";
import InstagramLink from "./common/InstagramLink";
import * as css from "./MobileMenu.module.css";

const MobileMenu = ({ onClose, isOpen, options }) => {
  // Prevent scrolling parent component when modal is open
  useEffect(() => {
    if (isOpen) noScroll.on();
    return () => noScroll.off();
  });

  return (
    <Modal
      closeTimeoutMS={300}
      ariaHideApp={false}
      isOpen={isOpen}
      className={{
        base: css["modal"],
        afterOpen: css["modalAfter"],
        beforeClose: css["modalBefore"],
      }}
      overlayClassName={css["overlay"]}
      onRequestClose={onClose}
      contentLabel="Menu">
      <div className={css["header"]}>
        <button onClick={onClose}>
          <CloseIcon aria-hidden />
        </button>
        <div className={css["logo"]}>
          <img src={Logo} alt="Logo QuantBrasil" />
        </div>
      </div>
      <ul className={css["options"]}>
        {options.map(option => (
          <li key={option.path}>
            <Link to={option.path} onClick={onClose}>
              {option.name}
            </Link>
          </li>
        ))}
      </ul>
      <div className={css["footer"]}>
        <InstagramLink />
      </div>
    </Modal>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default MobileMenu;
