exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-backtests-index-js": () => import("./../../../src/pages/backtests/index.js" /* webpackChunkName: "component---src-pages-backtests-index-js" */),
  "component---src-pages-backtests-score-js": () => import("./../../../src/pages/backtests/score.js" /* webpackChunkName: "component---src-pages-backtests-score-js" */),
  "component---src-pages-bandas-de-bollinger-js": () => import("./../../../src/pages/bandas-de-bollinger.js" /* webpackChunkName: "component---src-pages-bandas-de-bollinger-js" */),
  "component---src-pages-bdrs-mais-liquidas-js": () => import("./../../../src/pages/bdrs-mais-liquidas.js" /* webpackChunkName: "component---src-pages-bdrs-mais-liquidas-js" */),
  "component---src-pages-beta-da-carteira-js": () => import("./../../../src/pages/beta-da-carteira.js" /* webpackChunkName: "component---src-pages-beta-da-carteira-js" */),
  "component---src-pages-beta-js": () => import("./../../../src/pages/beta.js" /* webpackChunkName: "component---src-pages-beta-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cadastro-js": () => import("./../../../src/pages/cadastro.js" /* webpackChunkName: "component---src-pages-cadastro-js" */),
  "component---src-pages-candle-pavio-js": () => import("./../../../src/pages/candle-pavio.js" /* webpackChunkName: "component---src-pages-candle-pavio-js" */),
  "component---src-pages-cointegracao-js": () => import("./../../../src/pages/cointegracao.js" /* webpackChunkName: "component---src-pages-cointegracao-js" */),
  "component---src-pages-confirmar-email-js": () => import("./../../../src/pages/confirmar-email.js" /* webpackChunkName: "component---src-pages-confirmar-email-js" */),
  "component---src-pages-drawdown-js": () => import("./../../../src/pages/drawdown.js" /* webpackChunkName: "component---src-pages-drawdown-js" */),
  "component---src-pages-eden-dos-traders-js": () => import("./../../../src/pages/eden-dos-traders.js" /* webpackChunkName: "component---src-pages-eden-dos-traders-js" */),
  "component---src-pages-esqueci-minha-senha-js": () => import("./../../../src/pages/esqueci-minha-senha.js" /* webpackChunkName: "component---src-pages-esqueci-minha-senha-js" */),
  "component---src-pages-estocastico-lento-js": () => import("./../../../src/pages/estocastico-lento.js" /* webpackChunkName: "component---src-pages-estocastico-lento-js" */),
  "component---src-pages-estrategias-js": () => import("./../../../src/pages/estrategias.js" /* webpackChunkName: "component---src-pages-estrategias-js" */),
  "component---src-pages-full-factor-js": () => import("./../../../src/pages/full-factor.js" /* webpackChunkName: "component---src-pages-full-factor-js" */),
  "component---src-pages-hurst-js": () => import("./../../../src/pages/hurst.js" /* webpackChunkName: "component---src-pages-hurst-js" */),
  "component---src-pages-ifr-2-js": () => import("./../../../src/pages/ifr2.js" /* webpackChunkName: "component---src-pages-ifr-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inside-bar-js": () => import("./../../../src/pages/inside-bar.js" /* webpackChunkName: "component---src-pages-inside-bar-js" */),
  "component---src-pages-ipos-js": () => import("./../../../src/pages/ipos.js" /* webpackChunkName: "component---src-pages-ipos-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-magic-formula-js": () => import("./../../../src/pages/magic-formula.js" /* webpackChunkName: "component---src-pages-magic-formula-js" */),
  "component---src-pages-momentum-index-js": () => import("./../../../src/pages/momentum/index.js" /* webpackChunkName: "component---src-pages-momentum-index-js" */),
  "component---src-pages-momentum-sp-500-js": () => import("./../../../src/pages/momentum/sp500.js" /* webpackChunkName: "component---src-pages-momentum-sp-500-js" */),
  "component---src-pages-planos-js": () => import("./../../../src/pages/planos.js" /* webpackChunkName: "component---src-pages-planos-js" */),
  "component---src-pages-preco-de-fechamento-de-reversao-js": () => import("./../../../src/pages/preco-de-fechamento-de-reversao.js" /* webpackChunkName: "component---src-pages-preco-de-fechamento-de-reversao-js" */),
  "component---src-pages-redefinir-senha-js": () => import("./../../../src/pages/redefinir-senha.js" /* webpackChunkName: "component---src-pages-redefinir-senha-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-retorno-historico-js": () => import("./../../../src/pages/retorno-historico.js" /* webpackChunkName: "component---src-pages-retorno-historico-js" */),
  "component---src-pages-setup-123-js": () => import("./../../../src/pages/setup-123.js" /* webpackChunkName: "component---src-pages-setup-123-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-suportes-e-resistencias-js": () => import("./../../../src/pages/suportes-e-resistencias.js" /* webpackChunkName: "component---src-pages-suportes-e-resistencias-js" */),
  "component---src-pages-topo-historico-js": () => import("./../../../src/pages/topo-historico.js" /* webpackChunkName: "component---src-pages-topo-historico-js" */),
  "component---src-pages-trap-js": () => import("./../../../src/pages/trap.js" /* webpackChunkName: "component---src-pages-trap-js" */),
  "component---src-pages-turnaround-js": () => import("./../../../src/pages/turnaround.js" /* webpackChunkName: "component---src-pages-turnaround-js" */),
  "component---src-pages-value-at-risk-js": () => import("./../../../src/pages/value-at-risk.js" /* webpackChunkName: "component---src-pages-value-at-risk-js" */),
  "component---src-pages-youtube-js": () => import("./../../../src/pages/youtube.js" /* webpackChunkName: "component---src-pages-youtube-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/Author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-lead-page-js": () => import("./../../../src/templates/LeadPage.js" /* webpackChunkName: "component---src-templates-lead-page-js" */),
  "component---src-templates-low-risk-template-js": () => import("./../../../src/templates/LowRiskTemplate.js" /* webpackChunkName: "component---src-templates-low-risk-template-js" */),
  "component---src-templates-ranking-template-js": () => import("./../../../src/templates/RankingTemplate.js" /* webpackChunkName: "component---src-templates-ranking-template-js" */),
  "component---src-templates-release-template-js": () => import("./../../../src/templates/ReleaseTemplate.js" /* webpackChunkName: "component---src-templates-release-template-js" */),
  "component---src-templates-strategy-js": () => import("./../../../src/templates/Strategy.js" /* webpackChunkName: "component---src-templates-strategy-js" */)
}

