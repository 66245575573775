import { Link } from "gatsby";
import PropTypes from "prop-types";
import * as css from "./ReleasesLayout.module.css";
import Navbar from "../Navbar";
import SEO from "../seo";
import Footer from "../Footer";
import Breadcrumb from "../common/Breadcrumb";
import { DateTime } from "luxon";
import TelegramLink from "../TelegramLink";

const ReleasesLayout = ({ data, isIndividual = false }) => {
  return (
    <div>
      <SEO
        title={isIndividual ? data[0].title : "Releases"}
        description={
          isIndividual
            ? data[0].excerpt
            : "Fique por dentro de todos os lançamentos e novidades do QuantBrasil."
        }
      />
      <Navbar />
      <div className={css["content"]}>
        <div className={css["breadcrumbContainer"]}>
          {isIndividual ? (
            <Breadcrumb
              name={data[0].title}
              parents={[
                { path: "/", name: "Início" },
                { path: "/releases", name: "Releases" },
              ]}
            />
          ) : (
            <Breadcrumb
              name="Releases"
              parents={[{ path: "/", name: "Início" }]}
            />
          )}
        </div>
        <h1>Releases</h1>
        <p>Fique por dentro das novidades e atualizações do QuantBrasil.</p>
        <p className={css["follow"]}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/quantbrasil">
            Siga-nos no Twitter
          </a>
          &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/quantbrasil">
            Entre no grupo do Telegram
          </a>
          &nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/c/QuantBrasil">
            Inscreva-se no YouTube
          </a>
        </p>
        <div>
          {data.map(node => (
            <>
              <hr />
              <div key={node.uuid} className={css["entry"]}>
                <div>
                  {isIndividual && (
                    <Link to="/releases">
                      <span className={css["back"]}>← Voltar</span>
                    </Link>
                  )}
                  <Link to={`/releases/${node.slug}`}>
                    {DateTime.fromISO(node.published_at)
                      .setLocale("pt-BR")
                      .toFormat("dd LLLL, yyyy")}
                  </Link>
                </div>
                <div className={css["html"]}>
                  {node.feature_image && (
                    <Link
                      style={{
                        backgroundImage: `url(${node.feature_image})`,
                      }}
                      className={css["image"]}
                      to={`/releases/${node.slug}`}
                    />
                  )}
                  <h2>
                    <Link to={`/releases/${node.slug}`}>{node.title}</Link>
                  </h2>
                  <div dangerouslySetInnerHTML={{ __html: node.html }} />
                </div>
              </div>
            </>
          ))}
        </div>
        {!isIndividual && (
          <TelegramLink
            title="Parabéns, você chegou no final da lista 🎉"
            description="Quer ver os releases antigos? Todos os nossos anúncios, desde a nossa criação, estão no Telegram. Clique para entrar em nosso grupo."
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

ReleasesLayout.propTypes = {
  data: PropTypes.array.isRequired,
  isIndividual: PropTypes.bool,
};

export default ReleasesLayout;
