import { WhatsappIcon } from "react-share";
import * as css from "./WhatsAppFloatingButton.module.css";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

export const generateWADeepLink = () => {
  const WA_PHONE_NUMBER = "+552140420804";
  const WA_DEFAULT_TEXT =
    "Olá, gostaria de tirar uma dúvida sobre o plano Premium do QuantBrasil.";
  return `https://wa.me/${WA_PHONE_NUMBER}?text=${encodeURIComponent(
    WA_DEFAULT_TEXT
  )}`;
};

const WhatsAppFloatingButton = () => {
  return (
    <a
      onClick={() => logAmplitudeEvent(EVENT_MAP.WHATSAPP_BUTTON_CLICKED)}
      href={generateWADeepLink()}
      className={css["float"]}
      target="_blank"
      rel="noreferrer">
      <WhatsappIcon size={56} round={true} bgStyle={{ fill: "none" }} />
    </a>
  );
};

export default WhatsAppFloatingButton;
