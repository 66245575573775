import * as Tabs from "@radix-ui/react-tabs";
import AppLayout from "../layouts/AppLayout";
import FavoritesContainer from "./FavoritesContainer";
import * as css from "./BacktestTabs.module.css";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import HistoryContainer from "./HistoryContainer";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const BacktestTabs = ({ tab }) => {
  const {
    step: historyStep,
    markStepAsCompleted: completeHistoryStep,
    showStep: showHistoryStep,
  } = useOnboardingStep("CHECK_BACKTEST_HISTORY");

  const {
    step: favoriteStep,
    markStepAsCompleted: completeFavoriteStep,
    showStep: showFavoriteStep,
  } = useOnboardingStep("CHECK_SAVED_BACKTESTS");

  const isHistorySelected = tab === "historico";

  const showHistoryPopover =
    showHistoryStep &&
    (isHistorySelected || (!isHistorySelected && !showFavoriteStep));

  const showFavoritePopover =
    showFavoriteStep &&
    (!isHistorySelected || (isHistorySelected && !showHistoryStep));

  return (
    <AppLayout
      premiumMessage={
        isHistorySelected
          ? "Seja Premium para recuperar os seus melhores backtests"
          : "Seja Premium para salvar um número ilimitado de backtests"
      }
      seoProps={{
        title: isHistorySelected ? "Histórico" : "Favoritos",
      }}>
      <div className={css["container"]}>
        <Tabs.Root
          className="TabsRoot"
          defaultValue="historico"
          value={tab}
          onValueChange={newTab => navigate(`/app/backtests/${newTab}`)}>
          <Tabs.List
            className={css["tabsList"]}
            aria-label="Manage your account">
            <Popover.Root open={showHistoryPopover}>
              <Popover.Trigger asChild>
                <Tabs.Trigger className={css["tabsTrigger"]} value="historico">
                  Histórico
                </Tabs.Trigger>
              </Popover.Trigger>
              {showHistoryPopover && (
                <OnboardingStep
                  step={historyStep}
                  onConfirm={completeHistoryStep}
                  onDismiss={completeHistoryStep}
                />
              )}
            </Popover.Root>
            <Popover.Root open={showFavoritePopover}>
              <Popover.Trigger asChild>
                <Tabs.Trigger className={css["tabsTrigger"]} value="favoritos">
                  Favoritos
                </Tabs.Trigger>
              </Popover.Trigger>
              {showFavoritePopover && (
                <OnboardingStep
                  step={favoriteStep}
                  onConfirm={completeFavoriteStep}
                  onDismiss={completeFavoriteStep}
                />
              )}
            </Popover.Root>
          </Tabs.List>
          <Tabs.Content className="TabsContent" value="historico">
            <HistoryContainer />
          </Tabs.Content>
          <Tabs.Content className="TabsContent" value="favoritos">
            <FavoritesContainer />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </AppLayout>
  );
};

BacktestTabs.propTypes = {
  tab: PropTypes.string,
};

export default BacktestTabs;
