import PropTypes from "prop-types";
import { toPercent } from "../../utils/utils";
import * as css from "./PieTooltip.module.css";
import { DateTime } from "luxon";

const PieTooltip = ({ data, total }) => {
  return (
    <div className={css["container"]}>
      <ul>
        <li>
          <b>Beta:</b> {data.datum.data.props.beta.toFixed(2)}
        </li>
        <li>
          <b>Beta Ajustado:</b> {data.datum.data.props.weighted_beta} (
          {toPercent(data.datum.data.props.weighted_beta / total)})
        </li>
        <li>
          <b>Correlação:</b> {data.datum.data.props.corr.toFixed(2)}
        </li>
        <li>
          <b>Vol. Diária:</b>{" "}
          {(data.datum.data.props.std_asset * 100).toFixed(2)}%
        </li>
      </ul>
      <p className={css["lastUpdate"]}>
        Última atualização{" "}
        {DateTime.fromISO(data.datum.data.props.last_updated).toRelative({
          locale: "pt-br",
        })}
        .
      </p>
    </div>
  );
};

PieTooltip.propTypes = {
  data: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

export default PieTooltip;
