import { graphql, useStaticQuery } from "gatsby";
import orderBy from "lodash.orderby";
import { isEmpty } from "lodash";

export const getRankedNodes = (nodes, segmentsToExclude) => {
  const sortedNodes = orderBy(
    nodes.filter(
      node =>
        isEmpty(segmentsToExclude) ||
        !segmentsToExclude.map(({ label }) => label).includes(node.segment)
    ),
    "position",
    "asc"
  ).map((node, i) => ({
    ...node,
    overallRanking: i + 1,
  }));
  return sortedNodes;
};

const useSortedMagicFormula = ({ fieldToSort, directionToSort }) => {
  const {
    allMagicFormula: { nodes },
  } = useStaticQuery(graphql`
    query {
      allMagicFormula {
        nodes {
          ticker
          roic
          roic_ranking
          ev_ebit
          ev_ebit_ranking
          position
          segment
          buildTime
        }
      }
    }
  `);

  const filtered = orderBy(
    getRankedNodes(nodes),
    item => item[fieldToSort],
    directionToSort
  );

  return filtered;
};

export default useSortedMagicFormula;
