// extracted by mini-css-extract-plugin
export var anchors = "index-module--anchors--5b1a7";
export var author = "index-module--author--b4ac1";
export var blog = "index-module--blog--19716";
export var buttonContainer = "index-module--buttonContainer--77487";
export var canvas = "index-module--canvas--2b2d3";
export var content = "index-module--content--f97f8";
export var copy = "index-module--copy--364f0";
export var main = "index-module--main--1ba09";
export var posts = "index-module--posts--0e642";
export var secondary = "index-module--secondary--99671";
export var shareLink = "index-module--shareLink--64765";
export var signin = "index-module--signin--f6beb";
export var spoiler = "index-module--spoiler--06bc5";
export var star = "index-module--star--1c393";
export var title = "index-module--title--16c47";
export var tsparticles = "index-module--tsparticles--e1b5d";
export var videos = "index-module--videos--d47ec";
export var whatsappContainer = "index-module--whatsappContainer--dd737";
export var ytPublished = "index-module--ytPublished--58aa6";
export var ytTitle = "index-module--ytTitle--3a790";