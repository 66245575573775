import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useReducer } from "react";
import { useLocalStorage } from "react-use";
import { QueryClient } from "react-query";
import { objectKeysToCamelCase } from "../utils/utils";
import { useAPI } from "./useAPI";
import {
  logAmplitudeEvent,
  setProperty,
  trackLogin,
  trackLogout,
  EVENT_MAP,
} from "../utils/amplitude";

const queryClient = new QueryClient();

const initialState = {
  isLoggedIn: false,
  signIn: () => {},
  signUp: () => {},
  signOut: () => {},
  verifyEmail: () => {},
  forgotPassword: () => {},
  resetPassword: () => {},
  user: {},
};

const authContext = createContext(initialState);

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth()
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook for child components to get the auth object and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.user };
    case "LOGGED_OUT":
      return { ...state, user: {} };
    default:
      throw new Error();
  }
}

const toUser = data => {
  return objectKeysToCamelCase(data);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useLocalStorage("qb.user", {});
  const [state, dispatch] = useReducer(reducer, {
    user, // Load the user object from local storage
  });

  const callLoginAPI = useAPI({ withCredentials: true });
  const callLogoutAPI = useAPI({ withCredentials: true });
  const callRegisterAPI = useAPI({ withCredentials: true });
  const callVerifyAPI = useAPI({ withCredentials: true });
  const callForgotPasswordAPI = useAPI({ withCredentials: true });
  const callResetPasswordAPI = useAPI({ withCredentials: true });
  const callChangePasswordAPI = useAPI({ withCredentials: true });

  const signIn = async (email, password) => {
    try {
      const data = await callLoginAPI("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const user = toUser({ ...data, email });
      dispatch({ type: "SET_USER", user });
      setUser(user);
      trackLogin(user);
      return { success: true, message: "Successfully logged in", user };
    } catch (e) {
      dispatch({ type: "LOGGED_OUT" });
      setUser({});
      throw new Error(e.message);
    }
  };

  const signUp = async (name, email, password, phone) => {
    try {
      const data = await callRegisterAPI("/api/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          name,
          phone: isEmpty(phone) || phone.startsWith("+") ? phone : `+${phone}`,
        }),
      });
      logAmplitudeEvent(EVENT_MAP.ACCOUNT_CREATED);
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const signOut = async () => {
    try {
      await callLogoutAPI("/api/auth/logout", {
        method: "POST",
      });
      dispatch({ type: "LOGGED_OUT", user });
      setUser({});

      trackLogout();

      // Invalidate current queries
      queryClient.invalidateQueries();
      return { success: true, message: "Successfully logged out", user };
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const verifyEmail = async (code, email) => {
    try {
      const data = await callVerifyAPI("/api/auth/email-confirmation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, code }),
      });
      const user = toUser({ ...data, email });
      dispatch({ type: "SET_USER", user });
      setUser(user);
      return { success: true, message: "Successfully verified email", user };
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const forgotPassword = async email => {
    try {
      const data = await callForgotPasswordAPI("/api/auth/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const resetPassword = async (code, email, password) => {
    try {
      const data = await callResetPasswordAPI("/api/auth/reset-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, code, new_pwd: password }),
      });
      const user = toUser({ ...data, email });
      dispatch({ type: "SET_USER", user });
      setUser(user);
      return { success: true, message: "Successfully reset password!", user };
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const data = await callChangePasswordAPI("/api/auth/new-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ pwd: oldPassword, new_pwd: newPassword }),
      });
      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  };

  const setIsPremium = isPremium => {
    const user = { ...state.user, isPremium };
    dispatch({ type: "SET_USER", user });
    setUser(user);
    setProperty("isPremium", isPremium);
  };

  const setPhone = phone => {
    const user = { ...state.user, phone };
    dispatch({ type: "SET_USER", user });
    setUser(user);
    setProperty("phone", phone);
  };

  // Return the user object and auth methods
  return {
    ...state,
    isLoggedIn: !isEmpty(state.user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    signIn: useCallback(signIn, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    signUp: useCallback(signUp, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    signOut: useCallback(signOut, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    verifyEmail: useCallback(verifyEmail, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    forgotPassword: useCallback(forgotPassword, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    resetPassword: useCallback(resetPassword, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    changePassword: useCallback(changePassword, []),
    setIsPremium,
    setPhone,
  };
}
