import Modal from "../common/Modal";
import * as css from "./BatchModal.module.css";
import PropTypes from "prop-types";
import ParametersBox from "./ParametersBox";
import { mapStrategy, mapTimeframe, showError } from "../../utils/utils";
import { DateTime } from "luxon";
import Select from "react-select";
import { PREMIUM_DISABLED, SELECT_SMALL } from "../../utils/select";
import GroupLabel from "../common/GroupLabel";
import { useAssets } from "../../hooks/useAssets";
import { useState } from "react";
import CloseIcon from "../../icons/close.svg";
import { useMutation, QueryClient } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import SliderDiv from "../common/SliderDiv";
import { useSessionStorage } from "react-use";

const queryClient = new QueryClient();

// Parse date from DD/MM/YYYY to YYYY-MM-DD
const parseDate = date => {
  const [day, month, year] = date.split("/");
  return `${year}-${month}-${day}`;
};

const BatchModal = ({
  isOpen,
  onClose,
  parameters,
  strategyId,
  timeframeId,
  ticker,
  setupId,
  refetch,
}) => {
  // Yesterday as the end date and the start date should be two years before. Format in DD/MM/YYYY
  const endDate = DateTime.local().minus({ days: 1 }).toFormat("dd/MM/yyyy");
  const startDate = DateTime.fromFormat(endDate, "dd/MM/yyyy")
    .minus({ days: 365 })
    .toFormat("dd/MM/yyyy");

  const { grouped, getAllFromSector, getSector } = useAssets({
    requirePremium: true,
  });

  const [selectedAssets, setSelectedAssets] = useState([]);
  const [label, setLabel] = useState("");

  const addAsset = asset => {
    // Only add if it's not in the array already
    if (selectedAssets.includes(asset)) return;
    setSelectedAssets([...selectedAssets, asset]);
  };

  const removeAsset = asset => {
    setSelectedAssets(selectedAssets.filter(value => value !== asset));
  };

  const addManyAssets = assets => {
    // Only add if it's not in the array already
    assets = assets.filter(asset => !selectedAssets.includes(asset));
    setSelectedAssets([...selectedAssets, ...assets]);
  };

  const [, setHasDismissed] = useSessionStorage("dismissedBatchBanner");

  const getOptions = () => {
    // For each group, check if the asset is already selected. If it is, disable it.
    return grouped.map(group => ({
      ...group,
      options: group.options.map(option => ({
        ...option,
        isDisabled: selectedAssets.includes(option.value),
      })),
    }));
  };

  const tickerSector = getSector(ticker);

  const canSubmit =
    label.length > 0 &&
    selectedAssets.length > 0 &&
    selectedAssets.length <= 100;

  const callAPI = useAPI({ withCredentials: true });
  const mutation = useMutation(
    () =>
      callAPI(`/api/batch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          label,
          setup_id: setupId,
          timeframe_id: timeframeId,
          tickers: selectedAssets,
          start_date: parseDate(startDate),
          end_date: parseDate(endDate),
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["batchExpiration"],
        });
        refetch();
        setHasDismissed(false);
      },
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setSelectedAssets([]);
        setLabel("");
        onClose();
      }}
      modalClassName={css["modal"]}>
      {mutation.isSuccess ? (
        <SliderDiv>
          <div className={css["container"]}>
            <div>Simulações enviadas! 📨</div>
            <p>
              Você receberá um email quando estiver tudo pronto. Essa é uma boa
              hora para pegar um café.
            </p>
          </div>
        </SliderDiv>
      ) : (
        <div className={css["container"]}>
          <div>Acelerar Simulações 💨</div>
          <div>
            <p>
              As simulações tem um limite de <em>100 ativos</em> e são levados
              em conta os dados dos <em>últimos 365 dias</em>. Você será
              notificado por email quando o <em>batch</em> estiver completo.
            </p>
            <div className={css["info"]}>
              <div>
                <b>Estratégia:</b> {mapStrategy(strategyId)}
              </div>
              <div>
                <b>Timeframe:</b> {mapTimeframe(timeframeId)}
              </div>
              <div>
                <b>Início:</b> {startDate}
              </div>
              <div>
                <b>Fim:</b> {endDate}
              </div>
            </div>
            <ParametersBox parameters={parameters} hideLabel />
            <div className={css["inputContainers"]}>
              <div className={css["input"]}>
                <label>
                  Nome do <em>Batch</em>
                </label>
                <input
                  type="text"
                  value={label}
                  onChange={e => setLabel(e.target.value)}
                  placeholder={`Ex: ${mapStrategy(strategyId)} em ${endDate}`}
                />
              </div>
              <div className={css["assetSelect"]}>
                <label htmlFor="batch-asset">Adicionar Ativo</label>
                <Select
                  id="batch-asset"
                  options={getOptions()}
                  onChange={option => addAsset(option.value)}
                  value={null}
                  styles={{
                    ...SELECT_SMALL,
                    ...PREMIUM_DISABLED,
                  }}
                  maxMenuHeight={200}
                  formatGroupLabel={data => <GroupLabel {...data} />}
                  placeholder="Selecionar..."
                />
              </div>
            </div>
            <div className={css["selectedAssets"]}>
              {selectedAssets.map(asset => (
                <div key={asset} className={css["selectedAsset"]}>
                  <span>{asset}</span>
                  <button onClick={() => removeAsset(asset)}>
                    <CloseIcon />
                  </button>
                </div>
              ))}
              {selectedAssets.length === 0 && (
                <div className={css["noAssets"]}>
                  Nenhum ativo selecionado. Selecione um ou mais ativos para
                  iniciar o <em>batch</em>.
                </div>
              )}
            </div>
            <div className={css["shortcutButtons"]}>
              <button
                onClick={() =>
                  addManyAssets(
                    grouped
                      .map(group => group.options)
                      .flat()
                      .filter(({ isIBOV }) => isIBOV)
                      .map(option => option.value)
                  )
                }>
                + IBOV
              </button>
              <button
                onClick={() =>
                  addManyAssets(
                    getAllFromSector(tickerSector).map(({ symbol }) => symbol)
                  )
                }>
                + Setor {tickerSector}
              </button>
              <button
                onClick={() =>
                  addManyAssets(
                    grouped
                      .filter(({ label }) => label === "Criptomoedas")
                      .map(group => group.options)
                      .flat()
                      .map(option => option.value)
                  )
                }>
                + Criptomoedas
              </button>
              <button
                onClick={() =>
                  addManyAssets(
                    grouped
                      .filter(({ label }) => label === "BDRs")
                      .map(group => group.options)
                      .flat()
                      .map(option => option.value)
                  )
                }>
                + BDRs
              </button>
              <button
                onClick={() =>
                  addManyAssets(
                    grouped
                      .filter(({ label }) => label === "ETFs")
                      .map(group => group.options)
                      .flat()
                      .map(option => option.value)
                  )
                }>
                + ETFs
              </button>
              <button
                onClick={() =>
                  addManyAssets(
                    grouped
                      .filter(({ label }) => label === "Futuros")
                      .map(group => group.options)
                      .flat()
                      .map(option => option.value)
                  )
                }>
                + Futuros
              </button>
              <button onClick={() => setSelectedAssets([])}>Limpar</button>
            </div>
            <div className={css["submitButtonContainer"]}>
              <button
                className={css["button"]}
                disabled={!canSubmit || mutation.isLoading}
                onClick={() => {
                  logAmplitudeEvent(EVENT_MAP.BATCH_CREATED);
                  mutation.mutate();
                }}>
                {selectedAssets.length === 0
                  ? `Rodar backtest`
                  : selectedAssets.length > 100
                  ? `Máximo de 100 ativos (selecionados: ${selectedAssets.length})`
                  : `Rodar backtest em ${selectedAssets.length} ativos`}
              </button>
            </div>
            <p className={css["disclaimer"]}>
              Uma vez iniciado o <span>batch</span>, você poderá realizar outro
              em <span>1 hora</span>.
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

BatchModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  parameters: PropTypes.object.isRequired,
  strategyId: PropTypes.string.isRequired,
  timeframeId: PropTypes.string.isRequired,
  ticker: PropTypes.string.isRequired,
  setupId: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default BatchModal;
