import { graphql, useStaticQuery } from "gatsby";
import orderBy from "lodash.orderby";

const useSortedMomentum = ({ fieldToSort, directionToSort, portfolioId }) => {
  const { allMomentum: data } = useStaticQuery(graphql`
    {
      allMomentum(sort: { momentum: DESC }) {
        nodes {
          ticker
          slope
          window
          regression
          intercept
          momentum
          r_squared
          last_price
          last_updated
          buildTime
          ranking
          portfolio_id
        }
      }
    }
  `);

  const nodes = data.nodes.filter(node => node.portfolio_id === portfolioId);

  const filtered = orderBy(nodes, fieldToSort, directionToSort);

  return filtered;
};

export default useSortedMomentum;
