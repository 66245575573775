import { graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import InstagramLink from "../components/common/InstagramLink";
import UpgradeSection from "../components/common/UpgradeSection";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import * as css from "./topo-historico.module.css";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import { trackScreeningViewed } from "../utils/amplitude";
import Input from "../components/common/Input";
import Select from "react-select";
import { SELECT_SMALL } from "../utils/select";
import { DateTime } from "luxon";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const SORT_OPTIONS = [
  { value: "days_since_ath asc", label: "Menos tempo" },
  { value: "days_since_ath desc", label: "Mais tempo" },
  { value: "distance_from_ath asc", label: "Menor distância" },
  { value: "distance_from_ath desc", label: "Maior distância" },
];

const HIGHLIGHTED_ASSETS = ["VALE3", "PETR4"];

const ATHPage = () => {
  const { isLoggedIn, user } = useAuth();

  const [minDays, setMinDays] = useState(0);
  const [maxDays, setMaxDays] = useState(null);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const data = useStaticQuery(graphql`
    query {
      allAth {
        nodes {
          ticker
          ath_date
          buildTime
          ath
          current_close
          days_since_ath
          distance_from_ath
        }
      }
    }
  `);

  const lastUpdated = data.allAth.nodes[0]?.buildTime;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data.allAth.nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(
        ({ days_since_ath }) =>
          days_since_ath <= (maxDays || Infinity) && days_since_ath >= minDays
      )
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "ath" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Topo Histórico",
        description:
          "Acompanhe o Topo Histórico, ou ATH (all-time high), dos principais ativos da bolsa brasileira. O valor do topo histórico é atualizado diariamente no fechamento do mercado. Descubra os ativos mais próximos ou distantes do seu ATH.",
      }}
      title="Topo Histórico"
      ctaMessage="Verifique os ativos atingindo o topo histórico no nosso feed quantitativo">
      <div>
        <p>
          Acompanhe o <em>Topo Histórico</em>, ou <em>ATH (all-time high)</em>,
          dos principais ativos da bolsa brasileira. O valor do topo histórico é
          atualizado diariamente no <em>fechamento</em> do mercado. Descubra os
          ativos mais próximos ou distantes do seu ATH.
        </p>
        <p>
          <em>
            OBS: O Topo Histórico é calculado através do nosso banco de dados e
            pode divergir de outras fontes, especialmente para datas mais
            antigas, por conta de splits e dividendos.
          </em>
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-days">{`Min Dias`}</label>
            <Input
              id="min-days"
              value={minDays}
              onValueChange={({ floatValue }) =>
                setMinDays(floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 0"
              isAllowed={({ floatValue }) =>
                floatValue === undefined ||
                floatValue <= 10000 ||
                floatValue >= 0
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-days">{`Max Dias`}</label>
            <Input
              id="max-days"
              value={maxDays}
              onValueChange={({ floatValue }) =>
                setMaxDays(floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 365"
              isAllowed={({ floatValue }) =>
                floatValue === undefined ||
                floatValue <= 10000 ||
                floatValue >= 0
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="ath-asset">Ativo</label>
            <input
              id="ath-asset"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="ath-order">Ordenar por</label>
            <Select
              id="ath-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "200px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="ath-tip"
                  data-tooltip-content={`Considera o valor de fechamento.`}>
                  Topo Histórico<sup>1</sup>
                </th>
                <th>Preço Atual</th>
                <th>Data do ATH</th>
                <th>Distância do ATH</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td>{node.ticker}</td>
                  <td>
                    <span className={css["exponentContainer"]}>
                      {node.ath.toFixed(2)}
                    </span>
                  </td>
                  <td>{node.current_close.toFixed(2)}</td>
                  <td>
                    {DateTime.fromISO(node.ath_date).toFormat("dd/MM/yyyy")}{" "}
                    <em>
                      ({node.days_since_ath} dia
                      {node.days_since_ath === 1 ? "" : "s"})
                    </em>
                  </td>
                  <td>{(node.distance_from_ath * 100).toFixed(2)}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Considera o valor de fechamento.
          </div>
        </div>
        <Tooltip id="ath-tip" />
        <InstagramLink />
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear todas as funcionalidades do QuantBrasil!" />
        )}
        <div className={css["faq"]}>
          <h3>Dúvidas Frequentes</h3>
          <Accordion allowMultipleExpanded={false} allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  O que é o Topo Histórico ou ATH de um ativo?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  <p>
                    O <em>Topo Histórico</em> ou <em>ATH (all-time high)</em> de
                    um ativo é o valor máximo que ele já atingiu desde o seu
                    IPO/lançamento.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Como é calculado o Topo Histórico?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div>
                  <p>
                    O Topo Histórico é o maior valor de fechamento já registrado
                    para o ativo.
                  </p>
                  <p>
                    Note que o Topo Histórico é calculado através do nosso banco
                    de dados e pode divergir de outras fontes, especialmente
                    para datas mais antigas, por conta de splits e dividendos.
                  </p>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            {HIGHLIGHTED_ASSETS.map(ticker => (
              <AccordionItem key={ticker}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {`Qual o Topo Histórico de ${ticker}?`}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div>
                    <p>
                      {`O ativo ${ticker} atingiu o seu Topo Histórico no dia ${DateTime.fromISO(
                        data.allAth.nodes.find(node => node.ticker === ticker)
                          .ath_date
                      ).toFormat("dd/MM/yyyy")}.`}
                    </p>
                    <p>
                      {`O valor de fechamento do ativo naquele dia foi de R$${data.allAth.nodes
                        .find(node => node.ticker === ticker)
                        .ath.toFixed(2)}.`}
                    </p>
                    <p>
                      {`Desde então, o ativo ${ticker} já desvalorizou ${(
                        data.allAth.nodes.find(node => node.ticker === ticker)
                          .distance_from_ath * 100
                      ).toFixed(2)}%.`}
                    </p>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </PublicLayout>
  );
};

export default ATHPage;
