import { Link } from "gatsby";
import PropTypes from "prop-types";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import * as css from "./RegisterBanner.module.css";

const DEFAULT_CTA = "Crie a sua conta para aproveitar o QuantBrasil ao máximo";

const RegisterBanner = ({ ctaMessage = DEFAULT_CTA }) => {
  return (
    <div className={css["banner"]}>
      <span>{ctaMessage}</span>
      <Link
        onClick={() =>
          logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
            page: window.location.pathname,
            ctaText: "Cadastrar",
            bannerText: ctaMessage,
          })
        }
        to="/cadastro">
        Cadastrar
      </Link>
    </div>
  );
};

RegisterBanner.propTypes = {
  ctaMessage: PropTypes.string,
};

export default RegisterBanner;
