import Modal from "../common/Modal";
import PropTypes from "prop-types";
import CompleteIcon from "../../icons/flag.svg";
import * as css from "./CointegrationTradeCompleteButton.module.css";
import { useAPI } from "../../hooks/useAPI";
import { useMutation } from "react-query";
import { showError } from "../../utils/utils";
import { toast } from "react-toastify";
import { useState } from "react";
import Input from "../common/Input";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const CointegrationTradeCompleteButton = ({
  tradeId,
  baseAsset,
  baseAssetCurrentPrice,
  comparisonAsset,
  comparisonAssetCurrentPrice,
  refetch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [baseAssetPrice, setBaseAssetPrice] = useState(baseAssetCurrentPrice);
  const [comparisonAssetPrice, setComparisonAssetPrice] = useState(
    comparisonAssetCurrentPrice
  );
  const [endDate, setEndDate] = useState(DateTime.now().toJSDate());

  const canSubmit = baseAssetPrice && comparisonAssetPrice && endDate;

  const callAPI = useAPI({ withCredentials: true });

  const { mutate, isLoading } = useMutation(
    () =>
      callAPI(`/api/cointegration/trade/${tradeId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          base_asset_exit_price: baseAssetPrice,
          comparison_asset_exit_price: comparisonAssetPrice,
          end_date: endDate,
        }),
      }),
    {
      onSuccess: () => {
        refetch();
        toast.success("Trade finalizado com sucesso!");
        setIsModalOpen(false);
      },
      onError: showError,
    }
  );

  return (
    <>
      <button
        className={css["mainButton"]}
        data-tooltip-id="table-tooltip"
        data-tooltip-content="Finalizar trade"
        onClick={() => {
          setIsModalOpen(true);
          logAmplitudeEvent(
            EVENT_MAP.COINTEGRATION_TRADE_COMPLETE_BUTTON_CLICKED,
            {
              tradeId,
            }
          );
        }}>
        <CompleteIcon />
      </button>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        modalClassName={css["modal"]}>
        <div className={css["content"]}>
          <h3>Finalizar Trade</h3>
          <p>
            Preencha os preços de saída para finalizar o Long & Short{" "}
            <span>
              {baseAsset} x {comparisonAsset}
            </span>
            .
          </p>
          <div className={css["form"]}>
            <div className={css[["calendar"]]}>
              <label>Data de Fechamento</label>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={<input className={css["date"]} />}
                dateFormat="dd/MM/yyyy"
                selectsStart
                locale="pt"
                maxDate={new Date()}
              />
            </div>
            <div>
              <div className={css["asset"]}>
                {baseAsset} <span>(Ativo Base)</span>
              </div>
              <div className={css["inputContainer"]}>
                <div>
                  <label htmlFor="baseAsset-price">Preço</label>
                  <Input
                    id="baseAsset-price"
                    fluid
                    size="small"
                    value={baseAssetPrice}
                    onValueChange={({ floatValue }) =>
                      setBaseAssetPrice(floatValue)
                    }
                    isNumber
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    placeholder="Ex: 10,00"
                    hint="Preço de saída"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={css["asset"]}>
                {comparisonAsset} <span>(Ativo de Comparação)</span>
              </div>
              <div className={css["inputContainer"]}>
                <div>
                  <label htmlFor="comparisonAsset-price">Preço</label>
                  <Input
                    id="comparisonAsset-price"
                    fluid
                    size="small"
                    value={comparisonAssetPrice}
                    onValueChange={({ floatValue }) =>
                      setComparisonAssetPrice(floatValue)
                    }
                    isNumber
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    decimalScale={2}
                    placeholder="Ex: 10,00"
                    hint="Preço de saída"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={css["buttons"]}>
            <button
              className={css["cancel"]}
              onClick={() => setIsModalOpen(false)}>
              Cancelar
            </button>
            <button
              disabled={isLoading || !canSubmit}
              className={css["submitButton"]}
              onClick={() => mutate()}>
              {isLoading ? "Finalizando..." : "Finalizar"}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CointegrationTradeCompleteButton.propTypes = {
  tradeId: PropTypes.number.isRequired,
  baseAsset: PropTypes.string.isRequired,
  comparisonAsset: PropTypes.string.isRequired,
  baseAssetCurrentPrice: PropTypes.number.isRequired,
  comparisonAssetCurrentPrice: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CointegrationTradeCompleteButton;
