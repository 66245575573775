import * as css from "./RunBacktestBanner.module.css";
import PlayIcon from "../../icons/play.svg";
import { Link } from "gatsby";

const RunBacktestBanner = () => {
  return (
    <div className={css["container"]}>
      <div>
        <PlayIcon />
      </div>
      <div>
        <div className={css["text"]}>
          <div>
            <PlayIcon /> Faça o backtest dessa estratégia!
          </div>
          <div>
            No <span>QuantBrasil</span> você pode testar essa estratégia em
            diversos ativos, períodos e cenários. Acesse o nosso simulador e
            faça já o seu backtest!
          </div>
        </div>
        <Link className={css["button"]} to="/backtests">
          Testar Estratégia
        </Link>
      </div>
    </div>
  );
};

export default RunBacktestBanner;
