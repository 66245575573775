import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { mapStrategy, mapTimeframe, showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./RobotContainer.module.css";
import { useEffect, useState } from "react";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import PropTypes from "prop-types";
import ParametersBox from "../backtests/ParametersBox";
import Breadcrumb from "../common/Breadcrumb";
import Modal from "../common/Modal";
import FeedBacktest from "../feed/FeedBacktest";
import { Tooltip } from "react-tooltip";
import RobotTrades from "./RobotTrades";
import { getAssetLabel } from "../../hooks/useAssets";
import BetaTitle from "../common/BetaTitle";
import HowButton from "./HowButton";

const RobotContainer = ({ id }) => {
  const callAPI = useAPI({ withCredentials: true });

  const [selectedBacktestId, setSelectedBacktestId] = useState(null);

  const { data, isLoading, isSuccess } = useQuery(
    ["automatedRobots", id],
    () => callAPI(`/api/automated/robot/${id}`),
    { onError: showError, enabled: !!id }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.ROBOT_VIEWED);
  }, []);

  return (
    <AppLayout
      premiumMessage="Seja notificado sempre que o robô executar uma ordem"
      seoProps={{
        title: "Robô Quant",
      }}>
      <div className={css["content"]}>
        {isLoading && <Spinner type="Rings" text="Carregando robô..." />}
        {isSuccess && (
          <div>
            <Breadcrumb
              name={data.robot_name}
              parents={[
                { path: "/app/feed", name: "Feed" },
                { path: "/app/robots", name: "Lista de Robôs" },
              ]}
            />
            <BetaTitle title={`Robô ${data.robot_name}`} />
            <p className={css["desc"]}>
              Estratégia de <span>{mapStrategy(data.strategy_id)}</span> em{" "}
              <span>{getAssetLabel(data.ticker)}</span> no{" "}
              <span>{mapTimeframe(data.timeframe_id)}</span>.{" "}
              <button
                className={css["backtestButton"]}
                onClick={() => setSelectedBacktestId(data.backtest_id)}>
                Ver Backtest
              </button>
              <em>(últimos 365 dias).</em> <HowButton />
            </p>
            <ParametersBox parameters={data.parameters} />
            <RobotTrades id={id} />
            <p className={css["disclaimer"]}>
              Disclaimer: não é recomendação de compra ou venda.
            </p>
          </div>
        )}
      </div>
      <Tooltip className={css["tooltip"]} id="backtest-tooltip" delayHide={100}>
        Ver backtest
      </Tooltip>
      <Modal
        isOpen={!!selectedBacktestId}
        modalClassName={css["modal"]}
        onClose={() => setSelectedBacktestId(null)}>
        <FeedBacktest backtestId={selectedBacktestId} showParameters={false} />
      </Modal>
    </AppLayout>
  );
};

RobotContainer.propTypes = {
  id: PropTypes.number.isRequired,
};

export default RobotContainer;
