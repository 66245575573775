import { useAuth } from "../../hooks/useAuth";
import { useConfig } from "../../hooks/useConfig";
import { Link } from "gatsby";
import LandingPageLayout from "../layouts/LandingPageLayout";
import * as css from "./LandingPageContainer.module.css";
import SEO from "../seo";
import { useState } from "react";
import Toggle from "react-toggle";
import LogoIcon from "../../icons/logo-horizontal-white.svg";
import scrollToElement from "scroll-to-element";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { DISCOUNT, PRICES } from "../checkout/PlansContainer";
import WhatsAppLink from "../checkout/WhatsAppLink";
import WhatsAppFloatingButton from "../checkout/WhatsAppFloatingButton";
import PremiumFAQ from "../checkout/PremiumFAQ";
import Logo from "./um-ano.png";
import PremiumBenefits from "../checkout/PremiumBenefits";
import {
  logAmplitudeEvent,
  trackCheckoutSessionStarted,
  EVENT_MAP,
} from "../../utils/amplitude";

const DEV_YEARLY_PAYMENT_LINK =
  "https://buy.stripe.com/test_28o29DdmQ4s72gU7sw";

const DEV_MONTHLY_PAYMENT_LINK =
  "https://buy.stripe.com/test_bIYeWpdmQ5wb4p24gj";

export const formatPaymentLinkUrl = ({
  email,
  couponCode,
  isProd,
  isYearly,
  yearlyPaymentLink,
  monthlyPaymentLink,
}) => {
  const params =
    couponCode && email
      ? `?prefilled_promo_code=${couponCode}&prefilled_email=${encodeURIComponent(
          email
        )}`
      : email
      ? `?prefilled_email=${email}`
      : couponCode
      ? `?prefilled_promo_code=${couponCode}`
      : "";

  const url = isProd
    ? (isYearly ? yearlyPaymentLink : monthlyPaymentLink) + params
    : (isYearly ? DEV_YEARLY_PAYMENT_LINK : DEV_MONTHLY_PAYMENT_LINK) + params;

  return url;
};

const LandingPageContainer = ({ location, data }) => {
  const [isYearly, setIsYearly] = useState(true);

  const YEARLY_DISCOUNT = Math.round(
    100 * (1 - (1 - DISCOUNT / 100) * (1 - data.yearlyDiscount / 100))
  );

  const MONTHLY_DISCOUNT = Math.round(
    100 * (1 - (1 - data.monthlyDiscount / 100))
  );

  const description = data.description
    .replace(/{{trialDays}}/, data.trialDays)
    .replace(
      /{{discount}}/,
      isYearly ? data.yearlyDiscount : data.monthlyDiscount
    );

  const { isProd } = useConfig();

  const { isLoggedIn, user } = useAuth();

  const email = isLoggedIn && user?.email;

  const url = formatPaymentLinkUrl({
    email,
    couponCode: data.couponCode,
    isProd,
    isYearly,
    yearlyPaymentLink: data.yearlyPaymentLink,
    monthlyPaymentLink: data.monthlyPaymentLink,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Transform in a JSON object so we can send to Amplitude
    const urlParams = {};
    for (const [key, value] of params) {
      urlParams[key] = value;
    }

    logAmplitudeEvent(EVENT_MAP.LANDING_PAGE_VIEWED, {
      ...urlParams,
      ...data,
    });
  }, [data, location]);

  return (
    <LandingPageLayout footer={false} location={location} showFixedNav={false}>
      <SEO
        title={data.title}
        description={description}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <div className={css["content"]} id="plans-top">
        <div className={css["main"]}>
          <Link className={css["logo"]} to="/">
            <LogoIcon />
          </Link>
          <div className={css["header"]}>
            <div>
              <img className={css["partnerLogo"]} src={Logo} />
              <h1>{data.title}</h1>
              <p className={css["description"]}>{description}</p>
            </div>
          </div>
          <div>
            <label className={css["toggle"]}>
              <span>Mensal</span>
              <Toggle
                defaultChecked={isYearly}
                onChange={e => setIsYearly(e.target.checked)}
                icons={false}
              />
              <span>
                Anual — <span>{YEARLY_DISCOUNT}% off</span>
              </span>
            </label>
          </div>
          <div className={css["plans"]}>
            <div className={css["plan"]}>
              <h2>Plano Premium</h2>
              <div className={css["price"]}>
                R${" "}
                {(isYearly
                  ? (PRICES.yearly / 12) * ((100 - data.yearlyDiscount) / 100)
                  : PRICES.monthly * ((100 - data.monthlyDiscount) / 100)
                ).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                <span>/ mês</span>
              </div>
              {isYearly && (
                <div className={css["savings"]}>
                  <span>
                    R${" "}
                    {PRICES.monthly.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>{" "}
                  <span>{YEARLY_DISCOUNT}% off</span>
                </div>
              )}
              {!isYearly && MONTHLY_DISCOUNT > 0 && (
                <div className={css["savings"]}>
                  <span>
                    R${" "}
                    {PRICES.monthly.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>{" "}
                  <span>{MONTHLY_DISCOUNT}% off</span>
                </div>
              )}
              {data.couponCode && (
                <div className={css["coupon"]}>
                  Cupom: <span>{data.couponCode}</span>
                </div>
              )}
              <div className={css["cta"]}>
                <a
                  href={url}
                  className={css["button"]}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    trackCheckoutSessionStarted({
                      isYearly,
                      slug: data.slug,
                      ctaText: data.cta.replace(
                        /{{trialDays}}/,
                        data.trialDays
                      ),
                    })
                  }>
                  {data.cta.replace(/{{trialDays}}/, data.trialDays)}
                </a>
                <div className={css["period"]}>
                  Após o período de testes, será feita uma cobrança de{" "}
                  <span>
                    R${" "}
                    {(isYearly
                      ? PRICES.yearly * (1 - data.yearlyDiscount / 100)
                      : PRICES.monthly * ((100 - data.monthlyDiscount) / 100)
                    ).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
              <PremiumBenefits />
            </div>
          </div>
          <div className={css["anchors"]}>
            <button
              onClick={() => scrollToElement("#plans-faq", { offset: -150 })}>
              Perguntas Frequentes
            </button>
          </div>
        </div>
        <div className={css["waContainer"]}>
          <WhatsAppLink />
        </div>
        <div className={css["rest"]}>
          <h2 id="plans-faq">Perguntas Frequentes</h2>
          <PremiumFAQ />
          <button
            className={css["backTop"]}
            onClick={() => scrollToElement("#plans-top")}>
            Voltar ao Topo ↑
          </button>
        </div>
      </div>
      <WhatsAppFloatingButton />
    </LandingPageLayout>
  );
};

LandingPageContainer.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default LandingPageContainer;
