import EntryLayout from "../components/layouts/EntryLayout";
import Register from "../components/login/Register";
import SEO from "../components/seo";
import { useLocalStorage } from "react-use";
import PropTypes from "prop-types";
import { useEffect } from "react";

const RegisterPage = ({ location }) => {
  const [, setProductId, remove] = useLocalStorage("qb.selectedProduct");

  // If user has reached the register page with a selected product id, store in the
  // LS for posterior use. Make sure to clean the LS variable if none passed, once
  // this can be interpreted as an action taken in a different moment
  useEffect(() => {
    const selectedProductId = location?.state?.selectedProductId;
    if (selectedProductId) setProductId(selectedProductId);
    else remove();
  }, [location?.state?.selectedProductId, remove, setProductId]);

  return (
    <EntryLayout>
      <SEO title="Cadastro" />
      <Register />
    </EntryLayout>
  );
};

RegisterPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default RegisterPage;
