import PropTypes from "prop-types";
import { useState } from "react";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAPI } from "../../hooks/useAPI";
import PlusIcon from "../../icons/plus.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import Input from "../common/Input";
import * as css from "./AddPortfolio.module.css";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const AddPortfolio = ({ fetchPortfolios, hasPortfolios }) => {
  const callAPI = useAPI({ withCredentials: true });

  const [portfolioName, setPortfolioName] = useState("");

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "ADD_PORTFOLIO"
  );

  const mutation = useMutation(
    () =>
      callAPI("/api/portfolio/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: portfolioName }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        markStepAsCompleted();
        toast.success("Portfólio criado com sucesso!");
        logAmplitudeEvent(EVENT_MAP.PORTFOLIO_CREATED, { name: portfolioName });
        fetchPortfolios();
        setPortfolioName("");
      },
    }
  );

  return (
    <Popover.Root open={showStep && !hasPortfolios}>
      <div className={css["input"]}>
        <Input
          placeholder="Novo portfólio"
          fluid
          value={portfolioName}
          onChange={e => setPortfolioName(e.target.value)}
        />
        <Popover.Trigger asChild>
          <button
            disabled={!portfolioName || mutation.isLoading}
            onClick={() => mutation.mutate()}>
            {mutation.isLoading ? (
              <Loader type="TailSpin" color="#fff" height={18} width={18} />
            ) : (
              <PlusIcon />
            )}
          </button>
        </Popover.Trigger>
      </div>
      {showStep && !hasPortfolios && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

AddPortfolio.propTypes = {
  fetchPortfolios: PropTypes.func.isRequired,
  hasPortfolios: PropTypes.bool.isRequired,
};

export default AddPortfolio;
