import HeartIcon from "../../icons/heart.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./SaveBacktest.module.css";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { useAuth } from "../../hooks/useAuth";
import { chunk } from "lodash";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const SaveBacktest = ({ isFavorite, onToggleFavorite, result }) => {
  const callAPI = useAPI({ withCredentials: true });

  const { user } = useAuth();

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () =>
      callAPI(`/api/backtest/${result.id}/favorite`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_favorite: !isFavorite,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        const favoritesQueryKey = ["favorites", user.userId];
        const allBacktestsQueryKey = ["allBacktests", user.userId];
        if (isFavorite) {
          toast.success("Backtest removido dos favoritos");
          queryClient.setQueryData(favoritesQueryKey, oldData =>
            oldData?.filter(({ id }) => id !== result.id)
          );
          queryClient.setQueryData(allBacktestsQueryKey, oldData => {
            if (oldData === undefined) return;

            const data = oldData.pages.flat();
            const oldEntryIndex = data.findIndex(({ id }) => id === result.id);
            if (oldEntryIndex === -1 || oldEntryIndex === undefined) return;

            const oldEntry = { ...data[oldEntryIndex], is_favorite: false };
            const newData = data.concat([]);
            newData[oldEntryIndex] = oldEntry;

            // Update individual backtest entry
            queryClient.setQueryData(
              ["backtest", oldEntry.id],
              backtestData => ({ ...backtestData, is_favorite: false })
            );

            // eslint-disable-next-line no-unused-vars
            const { pages, ...rest } = oldData;
            return { ...rest, pages: chunk(newData) };
          });
        } else {
          toast.success("Backtest adicionado aos favoritos");
          queryClient.removeQueries(favoritesQueryKey, { exact: true });
          queryClient.setQueryData(allBacktestsQueryKey, oldData => {
            if (oldData === undefined) return;

            const data = oldData.pages.flat();
            const oldEntryIndex = data.findIndex(({ id }) => id === result.id);
            if (oldEntryIndex === -1 || oldEntryIndex === undefined) return;

            const oldEntry = { ...data[oldEntryIndex], is_favorite: true };
            const newData = data.concat([]);
            newData[oldEntryIndex] = oldEntry;

            // Update individual backtest entry
            queryClient.setQueryData(
              ["backtest", oldEntry.id],
              backtestData => ({ ...backtestData, is_favorite: true })
            );

            // eslint-disable-next-line no-unused-vars
            const { pages, ...rest } = oldData;
            return { ...rest, pages: chunk(newData) };
          });
        }
        onToggleFavorite();
      },
    }
  );

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "SAVE_BACKTEST"
  );

  const onClick = () => {
    markStepAsCompleted();
    mutation.mutate();
    logAmplitudeEvent(
      isFavorite ? EVENT_MAP.BACKTEST_REMOVED : EVENT_MAP.BACKTEST_SAVED,
      {
        id: result.id,
        strategy: result.strategy_id,
        ticker: result.symbol,
        timeframeId: result.timeframe_id,
      }
    );
  };

  return (
    <Popover.Root open={showStep}>
      <Popover.Anchor>
        <button
          data-tooltip-id="favorite-bt-tooltip"
          data-tooltip-content={
            isFavorite ? `Remover dos favoritos` : "Salvar backtest"
          }
          disabled={mutation.isLoading}
          onClick={onClick}
          className={`${css["heartButton"]}${
            isFavorite ? ` ${css["favorite"]}` : ""
          }`}>
          {mutation.isLoading ? (
            <div className={css["spinner"]}>
              <Spinner type="Rings" size={22} />
            </div>
          ) : (
            <>
              <HeartIcon />
              <span>{isFavorite ? "Salvo" : "Salvar"}</span>
              <Tooltip id="favorite-bt-tooltip" />
            </>
          )}
        </button>
      </Popover.Anchor>
      {showStep && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
          side="right"
        />
      )}
    </Popover.Root>
  );
};

SaveBacktest.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
  result: PropTypes.object.isRequired,
};

export default SaveBacktest;
