import { MAX_DATE_RANGE } from "../../hooks/useBacktestFilter";
import { STRATEGY_OPTIONS } from "../../utils/constants";
import { FREE_ALERTS } from "../alerts/AlertsMonitorContainer";
import * as css from "./FreeBenefits.module.css";

const FreeBenefits = () => {
  return (
    <ul className={css["benefits"]}>
      <li>
        Backtests de{" "}
        {STRATEGY_OPTIONS.filter(({ isPremium }) => !isPremium).length}{" "}
        estratégias em <em>todos ativos do Ibovespa.</em>
      </li>
      <li>
        Backtests em timeframes diários, intradiários e semanal nos últimos{" "}
        {MAX_DATE_RANGE} dias.
      </li>
      <li>Apenas um backtest por vez.</li>
      <li>
        Cálculo de cointegração em pares de ativos selecionados numa janela de
        250 dias.
      </li>
      <li>Notificações de estatísticas do mercado no Feed Quantitativo.</li>
      <li>
        Ranking com os ativos que melhor performaram em uma determinada
        estratégia <em>atualizados toda semana</em>.
      </li>
      <li>
        Alertas de sinais de <em>diversos setups</em>, tanto para{" "}
        <em>day trade</em> quanto para <em>swing trade</em>.
      </li>
      <li>
        Máximo de {FREE_ALERTS} alertas simultâneos de estratégias no WhatsApp.
      </li>
      <li>Zonas de Suportes e Resistências apenas na peridiocidade anual.</li>
      <li>
        Ferramentas de cálculo do <em>VaR e Beta de um portfólio</em> no período
        de 1 ano.
      </li>
      <li>
        Ferramenta de <em>desempenho histórico</em> de um portfólio no período
        de 1 ano.
      </li>
      <li>
        Novas funcionalidades <em>todos os meses</em>!
      </li>
    </ul>
  );
};

export default FreeBenefits;
