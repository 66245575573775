import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { useAPI } from "../../hooks/useAPI";
import EditIcon from "../../icons/edit.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import Input from "../common/Input";
import * as css from "./PortfolioName.module.css";

const PortfolioName = ({ portfolio, fetchPortfolios }) => {
  const [isEdit, setEdit] = useState(false);
  const [name, setName] = useState(portfolio.name);

  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/portfolio/${portfolio.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        logAmplitudeEvent(EVENT_MAP.PORTFOLIO_RENAMED, { name });
        toast.success("Portfólio renomeado com sucesso!");
        setEdit(false);
        fetchPortfolios();
      },
    }
  );

  useEffect(() => {
    setName(portfolio.name);
  }, [portfolio.name]);

  return (
    <div>
      <div className={css["title"]}>Portfólio Selecionado:</div>
      {isEdit ? (
        <div className={css["editing"]}>
          <Input
            value={name}
            onChange={e => setName(e.target.value)}
            size="small"
            fluid={true}
          />
          <div className={css["buttonsContainer"]}>
            <button
              disabled={mutation.isLoading || name === ""}
              onClick={() => mutation.mutate()}>
              Salvar
            </button>
            <button
              disabled={mutation.isLoading}
              onClick={() => {
                setEdit(false);
                setName(portfolio.name);
              }}>
              Cancelar
            </button>
          </div>
        </div>
      ) : (
        <div className={css["initial"]}>
          <div className={css["name"]}>{name}</div>{" "}
          <button
            onClick={() => setEdit(true)}
            className={css["edit"]}
            data-tooltip-id="portfolio-name-tp"
            data-tooltip-content="Editar nome">
            <EditIcon />
          </button>
        </div>
      )}
      <Tooltip id="portfolio-name-tp" place="right" />
    </div>
  );
};

PortfolioName.propTypes = {
  portfolio: PropTypes.object.isRequired,
  fetchPortfolios: PropTypes.func.isRequired,
};

export default PortfolioName;
