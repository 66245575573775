import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useMedia, useSessionStorage } from "react-use";

import LogoIcon from "../../images/logo.svg";
import AddPortfolio from "./AddPortfolio";
import * as css from "./Portfolios.module.css";
import SelectedPortfolio from "./SelectedPortfolio";
import PortfoliosList from "./PortfoliosList";

const Portfolios = ({ portfolios, fetchPortfolios }) => {
  const [selectedId, setSelectedId] = useSessionStorage(
    "selectedPortfolioId",
    null
  );

  const isMobile = useMedia("(max-width: 700px)");

  const showList = !isEmpty(portfolios);

  const selectedPortfolio = portfolios?.find(({ id }) => selectedId === id);

  const getContent = () => {
    if (!selectedPortfolio)
      return (
        <div className={css["welcomeContainer"]}>
          <div>
            Extraia o máximo do QuantBrasil com <b>portfólios</b>.
          </div>
          <img className={css["logo"]} src={LogoIcon} />
          {showList && (
            <div>
              Selecione um portfólio {isMobile ? "acima" : "ao lado"} e adicione
              ou remova ativos para melhorar sua experiência na plataforma.
            </div>
          )}
          {!showList && (
            <div>
              Crie um portfólio {isMobile ? "acima" : "ao lado"} para começar.
            </div>
          )}
        </div>
      );

    return (
      <SelectedPortfolio
        portfolio={selectedPortfolio}
        fetchPortfolios={fetchPortfolios}
        setSelectedId={setSelectedId}
      />
    );
  };

  return (
    <div className={css["container"]}>
      <div className={css["sidebar"]}>
        <AddPortfolio
          fetchPortfolios={fetchPortfolios}
          hasPortfolios={showList}
        />
        {showList && (
          <PortfoliosList
            portfolios={portfolios}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
          />
        )}
      </div>
      <div className={css["content"]}>{getContent()}</div>
    </div>
  );
};

Portfolios.propTypes = {
  portfolios: PropTypes.array,
  fetchPortfolios: PropTypes.func.isRequired,
};

export default Portfolios;
