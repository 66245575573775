import { Link } from "gatsby";
import * as css from "./BacktestWidget.module.css";
import PerformanceIcon from "../../icons/performance.svg";
import FeedWidget from "./FeedWidget";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const BacktestWidget = () => {
  return (
    <FeedWidget>
      <div className={css["icon"]}>
        <PerformanceIcon />
      </div>
      <p>
        Execute <b>backtests</b> de diversas estratégias quantitativas.
      </p>
      <Link
        className={css["button"]}
        to="/backtests"
        onClick={() =>
          logAmplitudeEvent(EVENT_MAP.FEED_WIDGET_BUTTON_CLICKED, {
            widget: "backtest",
          })
        }>
        Simular Backtests
      </Link>
    </FeedWidget>
  );
};

export default BacktestWidget;
