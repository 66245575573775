import * as css from "./BetaTitle.module.css";
import PropTypes from "prop-types";

const BetaTitle = ({ title }) => {
  return (
    <h1 className={css["title"]}>
      {title}
      <span className={css["beta"]}>Beta</span>
    </h1>
  );
};

BetaTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default BetaTitle;
