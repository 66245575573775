import PropTypes from "prop-types";
import { useState } from "react";
import { useMutation } from "react-query";
import { useAPI } from "../hooks/useAPI";
import { logAmplitudeEvent, EVENT_MAP } from "../utils/amplitude";
import { isValidEmail, showError } from "../utils/utils";
import * as css from "./Subscribe.module.css";

const Subscribe = ({ onSubscribeSuccess }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const callAPI = useAPI({ withCredentials: true });

  const mutate = useMutation(
    () =>
      callAPI(`/api/contact/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          name,
          path: window.location.href,
        }),
      }),
    {
      onSuccess: onSubscribeSuccess,
      onError: showError,
    }
  );

  const onClick = e => {
    e.preventDefault();
    logAmplitudeEvent(EVENT_MAP.SUBSCRIBE_BUTTON_CLICKED, {
      name,
      email,
      page: window.location.pathname,
    });
    mutate.mutate(email);
  };

  const isValid = isValidEmail(email) && name !== "";

  return (
    <div>
      <form className={css["form"]}>
        <div>
          <input
            type="text"
            placeholder="Seu nome"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div>
          <input
            type="email"
            placeholder="Adicione seu email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button disabled={!isValid || mutate.isLoading} onClick={onClick}>
            {mutate.isLoading ? "Inscrevendo..." : "Me avise!"}
          </button>
        </div>
      </form>
      <div className={css["help"]}>
        <span>Nunca enviaremos spam.</span>
      </div>
    </div>
  );
};

Subscribe.propTypes = {
  onSubscribeSuccess: PropTypes.func.isRequired,
};

export default Subscribe;
