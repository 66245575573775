import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as css from "../backtests/CapitalTooltip.module.css";

const ZscoreTooltip = ({ data }) => {
  return (
    <div className={css["container"]}>
      <div>
        <em>
          Em{" "}
          {DateTime.fromISO(data.xFormatted)
            .setLocale("pt-BR")
            .toFormat("dd LLL yy")}
        </em>
      </div>
      <div>
        <b>Z-Score:</b> {parseFloat(data.yFormatted).toFixed(2)}
      </div>
    </div>
  );
};

ZscoreTooltip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ZscoreTooltip;
