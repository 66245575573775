import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { FaGithub, FaLink, FaLinkedinIn, FaTwitter } from "react-icons/fa";

import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import * as css from "./Author.module.css";

const Author = ({
  data: {
    markdownRemark: {
      frontmatter: { avatar, name, web, linkedin, github, twitter },
      html,
    },
  },
  pageContext: { postsByAuthor },
}) => {
  const hasPosts = postsByAuthor && postsByAuthor.length > 0;
  return (
    <div>
      <SEO
        title={`${name}`}
        description={`Artigos sobre Data Science e análise quantitativa produzidos por ${name} em um só lugar.`}
      />
      <Navbar />
      <div className={css["content"]}>
        <div className={css["left"]}>
          <GatsbyImage
            image={avatar.childImageSharp.gatsbyImageData}
            alt={`Foto de ${name}`}
          />
          <div className={css["social"]}>
            {web && (
              <a href={web} target="_blank" rel="noreferrer">
                <FaLink />
              </a>
            )}
            {github && (
              <a href={github} target="_blank" rel="noreferrer">
                <FaGithub />
              </a>
            )}
            {twitter && (
              <a href={twitter} target="_blank" rel="noreferrer">
                <FaTwitter />
              </a>
            )}
            {linkedin && (
              <a href={linkedin} target="_blank" rel="noreferrer">
                <FaLinkedinIn />
              </a>
            )}
          </div>
        </div>
        <div className={css["right"]}>
          <h1>Sobre {name}</h1>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {hasPosts && (
            <>
              <h3>Do Autor</h3>
              <div className={css["posts"]}>
                {postsByAuthor.map(post => (
                  <Link key={post.slug} to={`/${post.slug}`}>
                    {post.title}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

Author.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Author;

export const query = graphql`
  query AuthorBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        type
        slug
      }
      frontmatter {
        name
        avatar {
          childImageSharp {
            gatsbyImageData(
              width: 256
              height: 256
              placeholder: BLURRED
              quality: 100
            )
          }
        }
        web
        linkedin
        github
        twitter
      }
      html
    }
  }
`;
