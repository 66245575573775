import { TelegramIcon } from "react-share";
import { logAmplitudeEvent, EVENT_MAP } from "../utils/amplitude";
import * as css from "./TelegramLink.module.css";
import PropTypes from "prop-types";

const TelegramLink = ({
  title = "Entre no nosso grupo do Telegram!",
  description = "Receba atualizações de funcionalidades, notificações de novos posts e discuta sobre análise quantitativa com a comunidade.",
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        logAmplitudeEvent(EVENT_MAP.TELEGRAM_BANNER_CLICKED, {
          page: window.location.pathname,
        })
      }
      href="https://t.me/quantbrasil"
      className={css["container"]}>
      <div className={css["icon"]}>
        <TelegramIcon size={48} round iconFillColor="#fff" />
        <div>{title}</div>
      </div>
      <div className={css["copy"]}>
        <div>{title}</div>
        <div>{description}</div>
      </div>
    </a>
  );
};

TelegramLink.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default TelegramLink;
