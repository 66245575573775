import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useMedia } from "react-use";
import BetaRotationTooltip from "./BetaRotationTooltip";

const formatData = data => {
  let dataPoints = [];
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    dataPoints.push({ x: obj.date, y: obj.slope });
  }
  return dataPoints;
};

const BetaRotation = ({ data, mean, std }) => {
  const isMobile = useMedia("(max-width: 700px)");

  const points = useMemo(() => formatData(data), [data]);

  const maxValue = Math.max(
    mean + 3 * std,
    Math.max(...points.map(({ y }) => y))
  );
  const minValue = Math.min(
    mean - 3 * std,
    Math.min(...points.map(({ y }) => y))
  );

  const chartData = [
    {
      id: "beta",
      color: "#033660",
      data: points,
    },
  ];

  return (
    <div>
      <div
        style={{
          height: "500px",
          backgroundColor: "#fff",
        }}>
        <ResponsiveLine
          data={chartData}
          margin={{
            top: 20,
            right: isMobile ? 5 : 20,
            bottom: 50,
            left: 35,
          }}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%Y-%m-%d"
          tooltip={input => <BetaRotationTooltip data={input.point.data} />}
          yScale={{
            type: "linear",
            min: minValue,
            max: maxValue,
          }}
          axisLeft={{
            tickPadding: 8,
            tickValues: [mean - 2 * std, mean, mean + 2 * std],
            format: value => value.toFixed(2),
          }}
          axisBottom={{
            format: "%b %y",
            tickRotation: isMobile ? 60 : 0,
          }}
          markers={[
            {
              axis: "y",
              value: mean,
              lineStyle: {
                stroke: "rgb(119, 119, 119)",
                strokeWidth: 1,
                strokeDasharray: "12, 6",
              },
              legend: "Média",
              legendOrientation: "horizontal",
              textStyle: {
                fontSize: "13px",
                fontWeight: 500,
              },
            },
            {
              axis: "y",
              value: mean + 2 * std,
              lineStyle: {
                stroke: "darkmagenta",
                strokeWidth: 1,
                strokeDasharray: "12, 6",
              },
              legend: "Banda Superior (2σ)",
              legendOrientation: "horizontal",
              textStyle: {
                fontSize: "13px",
                fontWeight: 500,
              },
            },
            {
              axis: "y",
              value: mean - 2 * std,
              lineStyle: {
                stroke: "#49ce8b",
                strokeWidth: 1,
                strokeDasharray: "12, 6",
              },
              legend: "Banda Inferior (2σ)",
              legendOrientation: "horizontal",
              textStyle: {
                fontSize: "13px",
                fontWeight: 500,
              },
            },
          ]}
          areaOpacity={0.07}
          enableSlices={false}
          colors={{ datum: "color" }}
          pointSize={0}
          pointLabelYOffset={-12}
          useMesh={true}
          motionConfig="gentle"
          enableGridY={false}
        />
      </div>
    </div>
  );
};

BetaRotation.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  mean: PropTypes.number.isRequired,
  std: PropTypes.number.isRequired,
};

export default BetaRotation;
