import { graphql } from "gatsby";
import PropTypes from "prop-types";
import LandingPageContainer from "../components/landingPages/LandingPageContainer";

const LandingPage = ({ location, data }) => {
  return (
    <LandingPageContainer location={location} data={data.landingPagesJson} />
  );
};

LandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default LandingPage;

export const query = graphql`
  query($slug: String!) {
    landingPagesJson(slug: { eq: $slug }) {
      slug
      title
      cta
      monthlyDiscount
      yearlyDiscount
      couponCode
      monthlyPaymentLink
      yearlyPaymentLink
      trialDays
      description
    }
  }
`;
