import * as Tabs from "@radix-ui/react-tabs";
import AppLayout from "../layouts/AppLayout";
import * as css from "./CointegrationTradeTabs.module.css";
import PropTypes from "prop-types";
import { Link, navigate } from "gatsby";
import { useAPI } from "../../hooks/useAPI";
import { useQuery } from "react-query";
import { toPercent } from "../../utils/utils";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../icons/info-question-circle.svg";
import { getColor } from "../backtests/CapitalTooltip";
import Spinner from "../common/Spinner";
import CointegrationTradeDeleteButton from "./CointegrationTradeDeleteButton";
import CointegrationTradeViewButton from "./CointegrationTradeViewButton";
import CointegrationTradeCompleteButton from "./CointegrationTradeCompleteButton";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const formatToBRL = price =>
  price.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

const formatToNumber = number =>
  number.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  });

const CointegrationTradeTabs = ({ tab }) => {
  const isOpenSelected = tab === "ongoing";

  const callAPI = useAPI({ withCredentials: true });

  const { data, isLoading, isSuccess, refetch } = useQuery(
    ["cointegrationTrades", tab],
    () => callAPI(`/api/cointegration/trade?status=${tab}`)
  );

  const renderPrices = (ticker, entryPrice, currentPrice, shares) => {
    return (
      <>
        <td>{ticker}</td>
        <td>{shares}</td>
        <td>
          <div
            className={css["flex"]}
            data-tooltip-id="table-tooltip"
            data-tooltip-html={`Entrada: ${formatToNumber(
              entryPrice
            )}<br />Preço Atual: ${formatToNumber(
              currentPrice
            )}<br />Resultado: <span style="font-weight:500; color: ${
              (currentPrice - entryPrice) * shares > 0 ? "#49ce8b" : "#d64242"
            }">${formatToBRL((currentPrice - entryPrice) * shares)}</span>`}>
            {toPercent(currentPrice / entryPrice - 1)}
            <InfoIcon />
          </div>
        </td>
      </>
    );
  };

  const showTable = isSuccess && data.length > 0;

  const showEmpty = isSuccess && data.length === 0;

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_TRADE_SCREEN_VIEWED, {
      tab,
    });
  }, [tab]);

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear a cointegração em todos os ativos"
      seoProps={{
        title: isOpenSelected ? "Trades em Andamento" : "Trades Finalizados",
      }}>
      <div className={css["container"]}>
        <Tabs.Root
          className="TabsRoot"
          value={tab}
          onValueChange={newTab =>
            navigate(`/app/cointegracao/trades/${newTab}`)
          }>
          <Tabs.List
            className={css["tabsList"]}
            aria-label="Manage your trades">
            <Tabs.Trigger className={css["tabsTrigger"]} value="ongoing">
              Em Andamento
            </Tabs.Trigger>
            <Tabs.Trigger className={css["tabsTrigger"]} value="finished">
              Finalizados
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content
            forceMount={false}
            className="TabsContent"
            hidden={tab !== "ongoing"}
            value="ongoing">
            {tab === "ongoing" && (
              <>
                <p>
                  Acompanhe seus trades de Long & Short em andamento. Cadastre
                  um par a partir da tela de{" "}
                  <strong>
                    <Link to="/cointegracao">Cointegração</Link>
                  </strong>
                  .
                </p>
                {isLoading && <Spinner text="Buscando trades..." />}
                {showTable && (
                  <div className={css["wrapper"]}>
                    <table className={css["table"]}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan="3">
                            Ativo Base
                          </th>
                          <th style={{ textAlign: "center" }} colSpan="3">
                            Ativo de Comparação
                          </th>
                          <th style={{ textAlign: "center" }} colSpan="5">
                            Resultado Atual
                          </th>
                        </tr>
                        <tr>
                          <th style={{ backgroundColor: "#f6f6f6" }}>Ticker</th>
                          <th style={{ backgroundColor: "#f6f6f6" }}>Lote</th>
                          <th style={{ backgroundColor: "#f6f6f6" }}>
                            Variação
                          </th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>Ticker</th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>Lote</th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>
                            Variação
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            Resultado
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            <div
                              className={css["flex"]}
                              data-tooltip-id="table-tooltip"
                              data-tooltip-content="Quantidade de candles iniciados após a entrada comparada com a meia-vida.">
                              Tempo <InfoIcon />
                            </div>
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            Z-Score
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            Confiança
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map(trade => (
                          <tr key={trade.id}>
                            {renderPrices(
                              trade.base_asset,
                              trade.base_asset_entry_price,
                              trade.base_asset_current_price,
                              trade.base_asset_shares
                            )}
                            {renderPrices(
                              trade.comparison_asset,
                              trade.comparison_asset_entry_price,
                              trade.comparison_asset_current_price,
                              trade.comparison_asset_shares
                            )}
                            <td style={getColor(trade.current_profit)}>
                              {formatToBRL(trade.current_profit)}
                            </td>
                            <td>
                              <span
                                style={{
                                  color:
                                    trade.ongoing_candles > trade.halflife
                                      ? "#d64242"
                                      : undefined,
                                }}>
                                {trade.ongoing_candles}
                              </span>
                              /{Math.round(trade.halflife)}
                            </td>
                            <td>{formatToNumber(trade.current_zscore)}</td>
                            <td
                              style={getColor(1 - trade.current_p_value, 0.95)}>
                              {toPercent(1 - trade.current_p_value)}
                            </td>
                            <td>
                              <div className={css["buttons"]}>
                                <CointegrationTradeCompleteButton
                                  tradeId={trade.id}
                                  baseAsset={trade.base_asset}
                                  comparisonAsset={trade.comparison_asset}
                                  baseAssetCurrentPrice={
                                    trade.base_asset_current_price
                                  }
                                  comparisonAssetCurrentPrice={
                                    trade.comparison_asset_current_price
                                  }
                                  refetch={refetch}
                                />
                                <CointegrationTradeViewButton
                                  cointegrationId={trade.cointegration_id}
                                />
                                <CointegrationTradeDeleteButton
                                  tradeId={trade.id}
                                  baseAsset={trade.base_asset}
                                  comparisonAsset={trade.comparison_asset}
                                  refetch={refetch}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Tooltip id="table-tooltip" clickable />
                  </div>
                )}
                {showEmpty && (
                  <div>
                    <p className={css["empty"]}>Nenhum trade em andamento.</p>
                  </div>
                )}
              </>
            )}
          </Tabs.Content>
          <Tabs.Content className="TabsContent" value="finished">
            {tab === "finished" && (
              <>
                <p>
                  Lista de todos os trades de Long & Short finalizados. Cadastre
                  um novo par a partir da tela de{" "}
                  <strong>
                    <Link to="/cointegracao">Cointegração</Link>
                  </strong>
                  .
                </p>
                {isLoading && <Spinner text="Buscando trades..." />}
                {showTable && (
                  <div className={css["wrapper"]}>
                    <table className={css["table"]}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} colSpan="3">
                            Ativo Base
                          </th>
                          <th style={{ textAlign: "center" }} colSpan="3">
                            Ativo de Comparação
                          </th>
                          <th style={{ textAlign: "center" }} colSpan="5">
                            Resultado Final
                          </th>
                        </tr>
                        <tr>
                          <th style={{ backgroundColor: "#f6f6f6" }}>Ticker</th>
                          <th style={{ backgroundColor: "#f6f6f6" }}>Lote</th>
                          <th style={{ backgroundColor: "#f6f6f6" }}>
                            Variação
                          </th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>Ticker</th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>Lote</th>
                          <th style={{ backgroundColor: "#e8f3fd" }}>
                            Variação
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            Resultado
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>
                            Criado em
                          </th>
                          <th style={{ backgroundColor: "#f4f4de" }}>Saída</th>
                          <th style={{ backgroundColor: "#f4f4de" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map(trade => (
                          <tr key={trade.id}>
                            {renderPrices(
                              trade.base_asset,
                              trade.base_asset_entry_price,
                              trade.base_asset_exit_price,
                              trade.base_asset_shares
                            )}
                            {renderPrices(
                              trade.comparison_asset,
                              trade.comparison_asset_entry_price,
                              trade.comparison_asset_exit_price,
                              trade.comparison_asset_shares
                            )}
                            <td style={getColor(trade.profit)}>
                              {formatToBRL(trade.profit)}
                            </td>
                            <td>
                              {DateTime.fromISO(trade.created_at).toFormat(
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td>
                              {DateTime.fromISO(trade.end_date).toFormat(
                                "dd/MM/yyyy"
                              )}
                            </td>
                            <td>
                              <div className={css["buttons"]}>
                                <CointegrationTradeViewButton
                                  cointegrationId={trade.cointegration_id}
                                />
                                <CointegrationTradeDeleteButton
                                  tradeId={trade.id}
                                  baseAsset={trade.base_asset}
                                  comparisonAsset={trade.comparison_asset}
                                  refetch={refetch}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Tooltip id="table-tooltip" clickable />
                  </div>
                )}
                {showEmpty && (
                  <div>
                    <p className={css["empty"]}>
                      Você ainda não finalizou nenhum trade.
                    </p>
                  </div>
                )}
              </>
            )}
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </AppLayout>
  );
};

CointegrationTradeTabs.propTypes = {
  tab: PropTypes.string,
};

export default CointegrationTradeTabs;
