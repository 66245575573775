/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql,useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function SEO({
  description = "",
  lang = "pt",
  meta = [],
  title,
  slug,
  type = "website",
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            image
            fullImage
          }
        }
      }
    `
  );

  // If there is a slug, append it to the end of the site url
  const metaURL = slug
    ? `${site.siteMetadata.siteUrl}/${slug}`
    : site.siteMetadata.siteUrl;

  const metaTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title;

  const metaDescription = description || site.siteMetadata.description;

  const metaImage = `${site.siteMetadata.siteUrl}${site.siteMetadata.image}`;

  const twitterImage = `${site.siteMetadata.siteUrl}${site.siteMetadata.fullImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: "og:image:width",
          content: `1200`,
        },
        {
          property: "og:image:height",
          content: `630`,
        },
        {
          property: "og:site_name",
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:locale`,
          content: "pt_BR",
        },
        {
          property: `og:url`,
          content: metaURL,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:site`,
          content: `@quant_brasil`,
        },
        {
          name: `twitter:creator`,
          content: `@quant_brasil`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
      ].concat(meta)}
    />
  );
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  slug: PropTypes.string,
  type: PropTypes.string,
};

export default SEO;
