// extracted by mini-css-extract-plugin
export var buttonsContainer = "BacktestResult-module--buttonsContainer--98efa";
export var chartContainer = "BacktestResult-module--chartContainer--f11ed";
export var complement = "BacktestResult-module--complement--d0e6a";
export var disclaimer = "BacktestResult-module--disclaimer--29290";
export var registerContainer = "BacktestResult-module--registerContainer--6228f";
export var row = "BacktestResult-module--row--4a137";
export var scoreTitle = "BacktestResult-module--scoreTitle--f0faa";
export var stat = "BacktestResult-module--stat--0372d";
export var stats = "BacktestResult-module--stats--9a006";
export var statsLabel = "BacktestResult-module--statsLabel--612a8";