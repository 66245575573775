// extracted by mini-css-extract-plugin
export var button = "SupplyDemandPopup-module--button--86dd5";
export var buttonOutline = "SupplyDemandPopup-module--buttonOutline--7ea26";
export var buttons = "SupplyDemandPopup-module--buttons--d0299";
export var close = "SupplyDemandPopup-module--close--b81af";
export var content = "SupplyDemandPopup-module--content--23842";
export var modal = "SupplyDemandPopup-module--modal--35dd7";
export var modalAfter = "SupplyDemandPopup-module--modalAfter--18b24";
export var modalBefore = "SupplyDemandPopup-module--modalBefore--fe37f";
export var notNow = "SupplyDemandPopup-module--notNow--dae3e";
export var overlay = "SupplyDemandPopup-module--overlay--efd25";
export var title = "SupplyDemandPopup-module--title--3e995";