import PropTypes from "prop-types";
import Plotly from "plotly.js-dist-min";
import locale from "plotly.js-locales/pt-br";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as css from "./SupplyDemandResult.module.css";
import UpgradeSection from "../common/UpgradeSection";
import RegisterButton from "../common/RegisterButton";
import { useAuth } from "../../hooks/useAuth";
import { getAssetLabel } from "../../hooks/useAssets";
import { DateTime } from "luxon";

const LEVELS = [
  { factor: 3, color: "#ed3419", width: 2.5, name: "3ª Resistência" },
  { factor: 2, color: "#ff4122", width: 2, name: "2ª Resistência" },
  { factor: 1, color: "#ff6242", width: 1.5, name: "1ª Resistência" },
  { factor: -1, color: "#a3c585", width: 1.5, name: "1º Suporte" },
  { factor: -2, color: "#87ab69", width: 2, name: "2º Suporte" },
  { factor: -3, color: "#87ab69", width: 2.5, name: "3º Suporte" },
];

const CHART_ID = "supply-demand-plotly";

const SupplyDemandResult = ({
  points,
  asset,
  year,
  showUpgrade,
  isMobile,
  zones,
  projection_period,
}) => {
  const [numberOfLevels, setNumberOfLevels] = useState(2);
  const { isLoggedIn } = useAuth();

  const maxPrice = useMemo(() => Math.max(...points.map(p => p.high)), [
    points,
  ]);
  const minPrice = useMemo(() => Math.min(...points.map(p => p.low)), [points]);

  const currentYear = String(new Date().getFullYear());

  const isProjectingCurrentYear = String(year.value) === currentYear;

  const currentYearPoints = isProjectingCurrentYear
    ? points.filter(({ datetime }) => datetime.slice(0, 4) === currentYear)
    : points;

  const initialDate = currentYearPoints[0].datetime;
  const yearFirstDay = `${year.value}-01-01`;
  const yearLastDay = `${year.value}-12-31`;

  // In order to get the last date, we compute 3 months from now and display what is smaller,
  // this projected date or the end of the year.
  const threeMonthsFromNow = DateTime.now().plus({ months: 3 }).toISODate();
  const lastDate =
    threeMonthsFromNow < yearLastDay ? threeMonthsFromNow : yearLastDay;

  const getYearlyZones = useCallback(() => {
    const lines = [];
    zones.forEach(({ vol, close }) => {
      LEVELS.filter(({ factor }) => Math.abs(factor) <= numberOfLevels)
        .map(({ factor, color, width }) => {
          const price = close * (1 + factor * vol);

          // For a level to be displayed, the price needs to be positive
          let show = price > 0;

          // If we are not projecting the current year, we can hide some levels that weren't
          // reached to improve visualization
          if (show && !isProjectingCurrentYear) {
            const previousLevel =
              close * (1 + (factor + (factor < 0 ? 1 : -1)) * vol);
            if (
              (factor === 3 && previousLevel > maxPrice) ||
              (factor === 2 && previousLevel > maxPrice) ||
              (factor === -2 && previousLevel < minPrice) ||
              (factor === -3 && previousLevel < minPrice)
            ) {
              show = false;
            }
          }

          return {
            type: "line",
            x0: initialDate,
            x1: yearLastDay,
            y0: price,
            y1: price,
            line: {
              color,
              width,
              dash: "dash",
            },
            show,
          };
        })
        .filter(({ show }) => show)
        .forEach(item => lines.push(item));
    });
    return lines;
  }, [
    initialDate,
    isProjectingCurrentYear,
    maxPrice,
    minPrice,
    numberOfLevels,
    yearLastDay,
    zones,
  ]);

  const getMonthlyZones = useCallback(() => {
    const lines = [];
    zones.forEach(({ vol, close, datetime }) => {
      LEVELS.filter(({ factor }) => Math.abs(factor) <= numberOfLevels).forEach(
        ({ factor, color, width }) => {
          const price = close * (1 + factor * vol);
          const nextMonth = DateTime.fromISO(datetime).plus({ month: 1 });
          const firstDay = nextMonth.startOf("month").toISODate();
          const lastDay = nextMonth.endOf("month").toISODate();

          lines.push({
            type: "line",
            x0: firstDay,
            x1: lastDay,
            y0: price,
            y1: price,
            line: {
              color,
              width,
              dash: "dash",
            },
            show: true,
          });
        }
      );
    });
    return lines;
  }, [numberOfLevels, zones]);

  const getWeeklyZones = useCallback(() => {
    const lines = [];
    zones.forEach(({ vol, close, datetime }) => {
      LEVELS.filter(({ factor }) => Math.abs(factor) <= numberOfLevels).forEach(
        ({ factor, color, width }) => {
          const price = close * (1 + factor * vol);
          const nextWeek = DateTime.fromISO(datetime).plus({ days: 7 });
          const firstDay = nextWeek.startOf("week").toISODate();
          const lastDay = nextWeek.endOf("week").toISODate();

          lines.push({
            type: "line",
            x0: firstDay,
            x1: lastDay,
            y0: price,
            y1: price,
            line: {
              color,
              width,
              dash: "dash",
            },
            show: true,
          });
        }
      );
    });
    return lines;
  }, [numberOfLevels, zones]);

  useEffect(() => {
    const trace1 = {
      x: points.map(p => p.datetime),
      open: points.map(p => p.open),
      high: points.map(p => p.high),
      low: points.map(p => p.low),
      close: points.map(p => p.close),

      increasing: { line: { color: "rgb(38, 166, 154)" } },
      decreasing: { line: { color: "rgb(239, 83, 80)" } },

      type: "candlestick",
      xaxis: "x",
      yaxis: "y",
    };

    const isYear = projection_period === "yearly";
    const isMonth = projection_period === "monthly";

    const shapes = isYear
      ? getYearlyZones()
      : isMonth
      ? getMonthlyZones()
      : getWeeklyZones();

    const xAxis = isYear
      ? {
          rangeslider: {
            visible: true,
          },
        }
      : isMonth
      ? {
          range: isProjectingCurrentYear
            ? [
                DateTime.now().minus({ month: 2 }).startOf("month").toISODate(),
                DateTime.now().endOf("month").toISODate(),
              ]
            : [`${year.value}-10-01`, `${year.value}-12-31`],
          type: "date",
          rangeslider: {
            visible: true,
          },
        }
      : {
          range: isProjectingCurrentYear
            ? [
                DateTime.now().minus({ days: 14 }).startOf("week").toISODate(),
                DateTime.now().endOf("week").toISODate(),
              ]
            : [`${year.value}-12-15`, `${year.value}-12-31`],
          type: "date",
          rangeslider: {
            visible: true,
          },
        };

    const annotations = shapes.map(s => ({
      x: isYear ? s.x0 : s.x1,
      y: s.y0,
      xref: "x",
      yref: "y",
      text: `<b>${s.y0.toFixed(2)}</b>`,
      showarrow: false,
      yshift: 10,
      xshift: isYear ? 14 : -14,
      font: {
        color: s.line.color,
      },
    }));

    const data = [trace1];

    const layout = {
      title: `Suportes e Resistências ${
        isYear
          ? "projetados <br />"
          : `<b>${isMonth ? "mensais" : "semanais"}</b><br /> projetados`
      } para <b>${getAssetLabel(asset.value)}</b> em <b>${year.value}</b>`,
      height: isMobile ? 500 : 800,
      dragmode: "pan",
      margin: {
        l: 30,
        r: 0,
      },
      xaxis: xAxis,
      shapes,
      annotations,
    };

    Plotly.register(locale);

    Plotly.newPlot(document.getElementById(CHART_ID), data, layout, {
      modeBarButtonsToRemove: ["lasso2d", "select2d", "resetScale2d"],
      scrollZoom: true,
      locale: "pt-BR",
      responsive: true,
      displaylogo: false,
      editable: false,
    });

    if (isYear) {
      // Create slider window to facilitate the view
      Plotly.relayout(document.getElementById(CHART_ID), "xaxis.range", [
        isProjectingCurrentYear
          ? DateTime.now().minus({ months: 3 }).toISODate()
          : initialDate,
        isProjectingCurrentYear ? lastDate : yearLastDay,
      ]);
    }
  }, [
    asset.value,
    isMobile,
    points,
    year,
    projection_period,
    getYearlyZones,
    getMonthlyZones,
    getWeeklyZones,
    yearFirstDay,
    threeMonthsFromNow,
    isProjectingCurrentYear,
    initialDate,
    lastDate,
    yearLastDay,
  ]);

  return (
    <div id="supply-demand-result">
      <div className={css["input"]}>
        <label htmlFor="levels-dp">Número de Níveis</label>
        <input
          id="levels-dp"
          type="number"
          min={1}
          max={3}
          value={numberOfLevels}
          onChange={e => setNumberOfLevels(Math.min(e.target.value, 3))}
        />
      </div>
      <div id={CHART_ID} />
      {showUpgrade && (
        <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear simulações em criptos, ETFs, BDRs e mini-índice e mini-dólar!" />
      )}
      {!isLoggedIn && (
        <div className={css["ctaContainer"]}>
          <RegisterButton copy="Crie sua conta para projetar suportes e resistências em outros anos, além de alterar o período da volatilidade." />
        </div>
      )}
    </div>
  );
};

SupplyDemandResult.propTypes = {
  points: PropTypes.array.isRequired,
  zones: PropTypes.array.isRequired,
  asset: PropTypes.object.isRequired,
  year: PropTypes.number.isRequired,
  showUpgrade: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  projection_period: PropTypes.oneOf(["yearly", "monthly", "weekly"])
    .isRequired,
};

export default SupplyDemandResult;
