import { useState, useRef } from "react";
import { useSessionStorage, usePageLeave } from "react-use";
import { useConfig } from "./useConfig";
import { logAmplitudeEvent, EVENT_MAP } from "../utils/amplitude";
import { useAuth } from "./useAuth";

export const usePremiumPopup = (defaultIsOpen = false) => {
  const [showModal, setShowModal] = useState(defaultIsOpen);

  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const [hasSeenPremiumPopup, setHasSeenPremiumPopup] = useSessionStorage(
    "qb.premiumPopup",
    defaultIsOpen
  );
  const hasSeenRef = useRef(hasSeenPremiumPopup);

  const { IS_PROMO, IS_BLACKFRIDAY } = useConfig();

  const enablePopup = IS_PROMO || IS_BLACKFRIDAY;

  // If the user is on desktop, we use the mouseOut event to trigger the modal
  usePageLeave(() => {
    if (enablePopup && !hasSeenRef.current && !isPremium) {
      setShowModal(true);
      setHasSeenPremiumPopup(true);
      hasSeenRef.current = true;
      logAmplitudeEvent(EVENT_MAP.PLANS_POPUP_VIEWED, {
        page: window.location.pathname,
      });
    }
  });

  return [showModal, setShowModal];
};
