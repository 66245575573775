import HeartIcon from "../../icons/heart.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./SaveCointegration.module.css";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const SaveCointegration = ({
  isFavorite,
  onToggleFavorite,
  result,
  hideLabel = false,
}) => {
  const callAPI = useAPI({ withCredentials: true });

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () =>
      callAPI(`/api/metrics/cointegration/${result.id}/favorite`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_favorite: !isFavorite,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        if (isFavorite) {
          toast.success("Cointegração removida dos favoritos");
          queryClient.setQueryData(["cointegrationFavorites"], oldData =>
            oldData?.filter(({ id }) => id !== result.id)
          );
        } else {
          toast.success("Cointegração adicionada aos favoritos");
          queryClient.removeQueries("cointegrationFavorites", { exact: true });
        }
        onToggleFavorite();
      },
    }
  );

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "SAVE_COINTEGRATION"
  );

  const onClick = () => {
    markStepAsCompleted();
    mutation.mutate();
    logAmplitudeEvent(
      isFavorite
        ? EVENT_MAP.COINTEGRATION_REMOVED
        : EVENT_MAP.COINTEGRATION_SAVED,
      {
        id: result.id,
      }
    );
  };

  return (
    <Popover.Root open={showStep}>
      <Popover.Trigger asChild>
        <button
          data-tooltip-id="coint-favorite-tip"
          data-tooltip-content={
            isFavorite ? `Remover dos favoritos` : "Salvar cointegração"
          }
          disabled={mutation.isLoading}
          onClick={onClick}
          className={`${css["heartButton"]}${
            isFavorite ? ` ${css["favorite"]}` : ""
          }`}>
          {mutation.isLoading ? (
            <div className={css["spinner"]}>
              <Spinner type="Rings" size={22} />
            </div>
          ) : (
            <>
              <HeartIcon />
              {!hideLabel && <span>{isFavorite ? "Salvo" : "Salvar"}</span>}
              {!hideLabel && <Tooltip id="coint-favorite-tip" />}
            </>
          )}
        </button>
      </Popover.Trigger>
      {showStep && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

SaveCointegration.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onToggleFavorite: PropTypes.func.isRequired,
  result: PropTypes.object.isRequired,
  hideLabel: PropTypes.bool,
};

export default SaveCointegration;
