import { ResponsiveBar } from "@nivo/bar";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { useMedia } from "react-use";
import { toPercent } from "../../utils/utils";
import HistogramTooltip from "./HistogramTooltip";

const formatData = data => {
  let dataPoints = [];
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    dataPoints.push({
      ...obj,
      interval: toPercent(obj.end_interval),
      order: i,
    });
  }
  return dataPoints;
};

const VaRHistogram = ({ data }) => {
  const isMobile = useMedia("(max-width: 700px)");

  const points = useMemo(() => formatData(data), [data]);

  return (
    <div>
      <div
        style={{
          height: "500px",
          backgroundColor: "#fff",
        }}>
        <ResponsiveBar
          data={points}
          keys={["frequency"]}
          indexBy="interval"
          margin={{
            top: 20,
            right: isMobile ? 5 : 20,
            bottom: 50,
            left: 30,
          }}
          tooltip={input => <HistogramTooltip data={input.data} />}
          axisLeft={{
            tickPadding: 8,
          }}
          axisBottom={{
            tickRotation: 60,
          }}
          colors="#B7B6FF"
          useMesh={true}
          motionConfig="gentle"
        />
      </div>
    </div>
  );
};

VaRHistogram.propTypes = {
  data: PropTypes.array.isRequired,
  valueAtRisk: PropTypes.number.isRequired,
};

export default memo(VaRHistogram);
