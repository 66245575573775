import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { isValidPassword, showError } from "../../utils/utils";
import AppLayout from "../layouts/AppLayout";
import PhoneNotifications from "./PhoneNotifications";
import * as css from "./Settings.module.css";
import UserSubscription from "./UserSubscription";
import EmailNotifications from "./EmailNotifications";

const Settings = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { changePassword, user } = useAuth();

  const mutation = useMutation(() => changePassword(oldPassword, newPassword), {
    onSuccess: () => {
      toast.success("Senha alterada com sucesso!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    },
    onError: showError,
  });

  const onClick = e => {
    e.preventDefault();
    if (!canSubmit) return;
    mutation.mutate();
  };

  const canSubmit =
    !mutation.isLoading &&
    oldPassword &&
    isValidPassword(newPassword, confirmPassword);

  return (
    <AppLayout
      seoProps={{
        title: "Minha Conta",
      }}>
      <div className={css["container"]}>
        <h1>Minha Conta</h1>
        <div>
          <div className={css["basic"]}>
            <div>
              <span>Nome:</span> {user.userName}
            </div>
            <div>
              <span>Email:</span> {user.email}
            </div>
            <div>
              <span>Plano:</span>
              {user.isPremium === true ? "Premium" : "Básico"}
            </div>
          </div>
          <UserSubscription />
          <PhoneNotifications />
          <EmailNotifications />
          <div className={css["preferences"]}>Preferências</div>
          <div className={css["box"]}>
            <h2>Trocar Senha</h2>
            <form>
              <div className={css["input"]}>
                <label>Senha Atual</label>
                <input
                  type="password"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                />
              </div>
              <div className={css["input"]}>
                <label>Nova Senha</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </div>
              <div className={css["input"]}>
                <label>Confirmar Senha</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
              <button
                disabled={!canSubmit}
                className={css["button"]}
                onClick={onClick}>
                {mutation.isLoading ? "Enviando..." : "Enviar"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Settings;
