import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

import * as css from "./Spinner.module.css";

const Spinner = ({
  size = 80,
  type = "Rings",
  color = "#49ce8b",
  text = "",
}) => {
  return (
    <div className={css["container"]}>
      <Loader type={type} color={color} height={size} width={size} />
      {` ${text}`}
    </div>
  );
};

Spinner.propTypes = {
  size: PropTypes.number,
  type: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
};

export default Spinner;
