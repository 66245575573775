import PropTypes from "prop-types";
import * as css from "./ParametersBox.module.css";
import { parseParameters } from "../../utils/utils";

const ParametersBox = ({
  parameters,
  hideLabel = false,
  label = "Parâmetros",
}) => {
  return (
    <div className={css["parametersContainer"]}>
      {!hideLabel && <div className={css["statsLabel"]}>{label}</div>}
      <div className={css["parameters"]}>
        {parseParameters(parameters).map((p, i) => (
          <div key={i}>
            {p.split(": ").map((chunk, j) => (
              <span key={j}>
                {chunk}
                {j === 0 ? ":" : ""}
              </span>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

ParametersBox.propTypes = {
  parameters: PropTypes.object.isRequired,
  hideLabel: PropTypes.bool,
  label: PropTypes.string,
};

export default ParametersBox;
