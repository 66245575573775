import { useAPI } from "../../hooks/useAPI";
import { useQuery } from "react-query";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import SavedBacktests from "./SavedBacktests";
import { useState } from "react";
import * as css from "./FavoritesContainer.module.css";
import { useAuth } from "../../hooks/useAuth";
import UpgradeSection from "../common/UpgradeSection";
import { Link } from "gatsby";
import LogoIcon from "../../images/logo.svg";

const FavoritesContainer = () => {
  const callAPI = useAPI({ withCredentials: true });
  const { user, isLoggedIn } = useAuth();

  const showUpgrade = isLoggedIn && !user.isPremium;

  const [selectedId, setSelectedId] = useState(null);

  const response = useQuery(
    ["favorites", user.userId],
    () => callAPI(`/api/backtest/?favorite=true`),
    {
      onError: showError,
    }
  );

  return (
    <div>
      {response.isLoading && <Spinner type="Rings" text="Carregando..." />}
      {response.isSuccess && (
        <div className={css["container"]}>
          {response.data?.length > 0 && (
            <SavedBacktests
              data={response.data}
              setSelectedId={setSelectedId}
              selectedId={selectedId}
              isFavorite
            />
          )}
          {response.data?.length === 0 && (
            <div className={css["welcomeContainer"]}>
              <div>
                Você pode salvar backtests no nosso{" "}
                <Link to="/backtests">Simulador de Estratégias</Link>.
              </div>
              <img className={css["logo"]} src={LogoIcon} />
              <div>Seus backtests favoritos aparecerão aqui.</div>
            </div>
          )}
          {showUpgrade && (
            <UpgradeSection copy="Faça o upgrade de sua conta para poder salvar quantos backtests quiser!" />
          )}
        </div>
      )}
    </div>
  );
};

export default FavoritesContainer;
