import { isEmpty, orderBy } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { useAssets } from "../../hooks/useAssets";
import { useAuth } from "../../hooks/useAuth";
import {
  MAX_DATE_RANGE,
  positionSizingVisibility,
} from "../../hooks/useBacktestFilter";
import { useIsClient } from "../../hooks/useIsClient";
import InfoIcon from "../../icons/info-question-circle.svg";
import ExternalIcon from "../../icons/external.svg";
import {
  INTRADAY_TIMEFRAMES,
  STRATEGY_OPTIONS,
  TIMEFRAME_OPTIONS,
} from "../../utils/constants";
import {
  PREMIUM_DISABLED,
  PREMIUM_OPTION,
  SELECT_SMALL,
  WITH_DETAILS,
  WITH_NEW_TAG,
} from "../../utils/select";
import {
  getMaxOpenTimeLimit,
  getMinCloseTimeLimit,
  parseStrategyDescription,
  mapTimeframeToMinutes,
} from "../../utils/utils";
import GroupLabel from "../common/GroupLabel";
import Input from "../common/Input";
import * as css from "./BacktestFilter.module.css";
import { DateTime } from "luxon";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import useStaticStrategies from "../../hooks/useStaticStrategies";

const parseToDatePicker = date => {
  if (date === null || date === undefined) return date;

  // Check if is ISO Date
  const parsed = DateTime.fromISO(date);
  if (parsed.isValid) return parsed.toJSDate();

  // If not ISO date then it's JS date and we can safely return
  return date;
};

const parseOffset = () => {
  const offset = DateTime.local().offset;
  if (offset === 0) return "mesmo horário";
  const hours = Math.abs(offset / 60);
  return `${offset > 0 ? "-" : "+"}${hours} hora${hours === 1 ? "" : "s"}`;
};

export const getYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date;
};

const BacktestFilter = ({
  onClick,
  isLoading,
  getValue,
  setValue,
  resetFilter,
  isValid,
  errors,
  state,
}) => {
  const { grouped: assets } = useAssets({ requirePremium: true });

  const { isLoggedIn, user } = useAuth();

  const isPremium = user?.isPremium;

  const isClient = useIsClient();
  const key = isClient ? "client" : "server";

  const isUser = isClient && isLoggedIn;

  const strategy = getValue("strategy");
  const timeframe = getValue("timeframeId");
  const asset = getValue("ticker");
  const entry = getValue("entry");
  const stop = getValue("stop");
  const target = getValue("target");

  const ignoreTimeframe = Boolean(strategy?.ignoreTimeframe);
  const isIntraDay =
    INTRADAY_TIMEFRAMES.includes(timeframe?.value) || ignoreTimeframe;

  const isDayTradeOnly = Boolean(strategy?.isDayTradeOnly);

  const canHaveTimeConstraints = isIntraDay && asset && !asset.isCrypto;

  const staticStrategies = useStaticStrategies();
  const selectedStatic = useMemo(
    () => staticStrategies.find(s => s.strategyId === strategy?.value),
    [staticStrategies, strategy]
  );

  const { showCapital, showContracts, showMaxRisk } = useMemo(
    () => positionSizingVisibility(state),
    [state]
  );

  const canSubmit = useMemo(
    () => !isLoading && isValid && (isLoggedIn || strategy?.isPublic),
    [isLoading, isLoggedIn, isValid, strategy?.isPublic]
  );

  const hasError = useCallback(key => errors[key] !== "", [errors]);

  const getAssetOptions = useCallback(() => {
    if (strategy?.blockedInCrypto) {
      const i = assets.findIndex(({ label }) => label === "Criptomoedas");
      const cryptoOptions = assets[i].options.map(o => ({
        ...o,
        isDisabled: true,
      }));
      const cryptoGroup = { ...assets[i], options: cryptoOptions };
      const newAssets = assets.concat([]);
      newAssets[i] = cryptoGroup;
      return newAssets;
    }
    return assets;
  }, [assets, strategy]);

  const isTimeframeDisabled = useCallback(
    timeframe =>
      !strategy.allowedTimeframes.includes(timeframe.value) ||
      (!isPremium &&
        INTRADAY_TIMEFRAMES.includes(timeframe.value) &&
        strategy.blockIntradayIfNotPremium) ||
      (timeframe.value === "M5" && asset && !asset.useContracts) ||
      (isUser
        ? !isPremium && asset?.value === "BTC-USD" && timeframe.value !== "D1"
        : timeframe.value !== "D1"),
    [asset, isPremium, isUser, strategy]
  );

  const getTimeframeOptions = useCallback(() => {
    if (isEmpty(strategy)) return TIMEFRAME_OPTIONS;
    return TIMEFRAME_OPTIONS.map(timeframe => ({
      ...timeframe,
      isDisabled: isTimeframeDisabled(timeframe),
    }));
  }, [isTimeframeDisabled, strategy]);

  const getStrategyOptions = useCallback(() => {
    const visibleOptions = STRATEGY_OPTIONS.map(option => {
      const isDisabled =
        (!isUser && !option.isPublic) ||
        (!isPremium && option.isPremium) ||
        (asset?.isCrypto && option.blockedInCrypto);
      return {
        ...option,
        isDisabled,
        order: option.order + (isDisabled ? 1000 : 0), // Add a penalty to the order of disabled strategies
      };
    });
    const long = [];
    const short = [];
    visibleOptions.forEach(s => (s.isShort ? short.push(s) : long.push(s)));
    return [
      {
        label: "Compra",
        options: orderBy(long, ["order"], ["asc"]),
      },
      { label: "Venda", options: short },
    ];
  }, [asset, isPremium, isUser]);

  // Reset timeframe if the selected strategy does not allow
  // the timeframe current selected
  useEffect(() => {
    if (
      !isEmpty(strategy) &&
      !isEmpty(timeframe) &&
      isTimeframeDisabled(timeframe)
    ) {
      setValue("timeframeId", null);
    }
  }, [isTimeframeDisabled, setValue, strategy, timeframe]);

  // Reset day trade mode if selected strategy or timeframe does not allow
  // or have intraday operations
  useEffect(() => {
    if (
      !isEmpty(strategy) &&
      !isEmpty(timeframe) &&
      !isIntraDay &&
      (getValue("isDayTrade") ||
        !isEmpty(getValue("closeTimeLimit")) ||
        !isEmpty(getValue("minOpenTime")))
    ) {
      setValue("isDayTrade", false);
      setValue("closeTimeLimit", null);
      setValue("minOpenTime", null);
    }
  }, [getValue, isIntraDay, setValue, strategy, timeframe]);

  // Reset certain values when strategy changes
  useEffect(() => {
    if (isEmpty(strategy)) return;

    // If strategy has target but a current value does not exist there, reset
    if (!isEmpty(strategy.target) && !isEmpty(target)) {
      const index = strategy.target.findIndex(
        ({ label }) => label === target.label
      );
      if (index === -1) setValue("target", null);
    }

    // If strategy has entry but a current value does not exist there, reset
    if (!isEmpty(strategy.entry) && !isEmpty(entry)) {
      const index = strategy.entry.findIndex(
        ({ label }) => label === entry.label
      );
      if (index === -1) setValue("entry", null);
    }

    // If strategy has stop but a current value does not exist there, reset
    if (!isEmpty(strategy.stop) && !isEmpty(stop)) {
      const index = strategy.stop.findIndex(
        ({ label }) => label === stop.label
      );
      if (index === -1) setValue("stop", null);
    }
  }, [entry, stop, strategy, setValue, target]);

  // Onboarding steps
  const {
    step: strategyStep,
    markStepAsCompleted: completeStrategyStep,
    showStep: showStrategyStep,
  } = useOnboardingStep("SELECT_BACKTEST_STRATEGY");

  const {
    step: timeframeStep,
    markStepAsCompleted: completeTimeframeStep,
    showStep: showTimeframeStep,
  } = useOnboardingStep("SELECT_BACKTEST_TIMEFRAME");

  const {
    step: assetStep,
    markStepAsCompleted: completeAssetStep,
    showStep: showAssetStep,
  } = useOnboardingStep("SELECT_BACKTEST_ASSET");

  const {
    step: dateStep,
    markStepAsCompleted: completeDateStep,
    showStep: showDateStep,
  } = useOnboardingStep("SELECT_BACKTEST_DATE");

  const {
    step: runBacktestStep,
    markStepAsCompleted: completeRunBacktestStep,
    showStep: showRunBacktestStep,
  } = useOnboardingStep("RUN_BACKTEST");

  return (
    <div className={css["filter"]} key={key}>
      <Tooltip id="bt-filter-tp" />
      <Popover.Root open={showStrategyStep}>
        <div>
          <label htmlFor="backtest-strategy">
            Estratégia{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={parseStrategyDescription(
                strategy?.value,
                state
              )}
            />
          </label>
          <Popover.Anchor>
            <Select
              id="backtest-strategy"
              options={getStrategyOptions()}
              value={strategy}
              onChange={option => {
                completeStrategyStep();
                setValue("strategy", option);
              }}
              styles={{
                ...SELECT_SMALL,
                ...(isUser && !isPremium
                  ? PREMIUM_OPTION
                  : isUser && isPremium
                  ? WITH_NEW_TAG
                  : {}),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
            />
          </Popover.Anchor>
          {selectedStatic ? (
            <a
              href={`/estrategias/${selectedStatic.slug}/`}
              target="_blank"
              rel="noopener noreferrer"
              className={css["strategyLink"]}>
              Ver detalhes da estratégia <ExternalIcon />
            </a>
          ) : (
            <a
              href="/estrategias/"
              target="_blank"
              rel="noopener noreferrer"
              className={css["strategyLink"]}>
              Ver todas estratégias <ExternalIcon />
            </a>
          )}
        </div>
        {showStrategyStep && (
          <OnboardingStep
            step={strategyStep}
            onConfirm={completeStrategyStep}
            onDismiss={completeStrategyStep}
            side="right"
            align="start"
            alignOffset={-40}
          />
        )}
      </Popover.Root>
      {!ignoreTimeframe && (
        <Popover.Root open={showTimeframeStep}>
          <div>
            <label htmlFor="backtest-timeframe">Timeframe</label>
            <Popover.Anchor>
              <Select
                id="backtest-timeframe"
                options={getTimeframeOptions()}
                value={timeframe}
                onChange={option => {
                  completeTimeframeStep();
                  setValue("timeframeId", option);
                }}
                styles={{
                  ...SELECT_SMALL,
                  ...(isUser &&
                  !isPremium &&
                  (asset?.value === "BTC-USD" ||
                    strategy?.blockIntradayIfNotPremium)
                    ? PREMIUM_OPTION
                    : WITH_DETAILS),
                }}
                isDisabled={isEmpty(getValue("strategy"))}
                placeholder="Selecionar..."
              />
            </Popover.Anchor>
          </div>
          {showTimeframeStep && !showStrategyStep && (
            <OnboardingStep
              step={timeframeStep}
              onConfirm={completeTimeframeStep}
              onDismiss={completeTimeframeStep}
              side="right"
            />
          )}
        </Popover.Root>
      )}
      <Popover.Root open={showAssetStep}>
        <div>
          <label htmlFor="backtest-asset">Ativo</label>
          <Popover.Anchor>
            <Select
              id="backtest-asset"
              options={getAssetOptions()}
              value={asset}
              onChange={option => {
                completeAssetStep();
                setValue("ticker", option);
              }}
              styles={{
                ...SELECT_SMALL,
                ...(isPremium ? PREMIUM_DISABLED : PREMIUM_OPTION),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
            />
          </Popover.Anchor>
        </div>
        {showAssetStep && !showStrategyStep && !showTimeframeStep && (
          <OnboardingStep
            step={assetStep}
            onConfirm={completeAssetStep}
            onDismiss={completeAssetStep}
            side="right"
          />
        )}
      </Popover.Root>
      <Popover.Root open={showDateStep}>
        <div>
          <div className={css["calendarContainer"]}>
            <div className={css[["calendar"]]}>
              <label
                style={{
                  color: hasError("startDate") ? "#d64242" : undefined,
                }}>
                Data Início
              </label>
              <DatePicker
                selected={getValue("startDate")}
                onChange={date => setValue("startDate", date)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={<input className={css["date"]} />}
                dateFormat="dd/MM/yyyy"
                endDate={getValue("endDate")}
                maxDate={getValue("endDate")}
                selectsStart
                locale="pt"
                title={
                  isUser
                    ? undefined
                    : "Crie sua conta para desbloquear esse filtro"
                }
                disabled={!isUser}
              />
            </div>
            <div className={css[["calendar"]]}>
              <label
                style={{ color: hasError("endDate") ? "#d64242" : undefined }}>
                Data Fim
              </label>
              <Popover.Anchor>
                <DatePicker
                  selected={getValue("endDate")}
                  onChange={date => setValue("endDate", date)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<input className={css["date"]} />}
                  dateFormat="dd/MM/yyyy"
                  minDate={getValue("startDate")}
                  maxDate={getYesterday()}
                  startDate={getValue("startDate")}
                  selectsEnd
                  locale="pt"
                  title={
                    isUser
                      ? undefined
                      : "Crie sua conta para desbloquear esse filtro"
                  }
                  disabled={!isUser}
                />
              </Popover.Anchor>
            </div>
          </div>
          {asset?.isCrypto && !hasError("startDate") ? (
            <div className={css["hint"]}>
              Trades em criptos estão em UTC ({parseOffset()})
            </div>
          ) : !isPremium ? (
            <div
              className={css["hint"]}
              style={{ color: hasError("startDate") ? "#d64242" : undefined }}>
              Período máximo de {MAX_DATE_RANGE} dias
            </div>
          ) : null}
        </div>
        {showDateStep &&
          !showStrategyStep &&
          !showTimeframeStep &&
          !showAssetStep && (
            <OnboardingStep
              step={dateStep}
              onConfirm={completeDateStep}
              onDismiss={completeDateStep}
              side="right"
            />
          )}
      </Popover.Root>
      {strategy?.parameters.includes("isDayTrade") && canHaveTimeConstraints && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-isDayTrade"
            type="checkbox"
            checked={isDayTradeOnly || getValue("isDayTrade")}
            onChange={e => setValue("isDayTrade", e.target.checked)}
            disabled={isDayTradeOnly}
            title={
              isDayTradeOnly
                ? "Estratégia disponível apenas no day-trade"
                : undefined
            }
          />
          <label htmlFor="backtest-isDayTrade">
            Modo Day-Trade{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Operação é necessariamente encerrada ao final do dia."
              }
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("minOpenTime") && canHaveTimeConstraints && (
        <div>
          <label htmlFor="backtest-minOpenTime">
            Horário Mínimo{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-html="<div>Para que a entrada seja autorizada, a <b>abertura</b> do candle deve ser igual ou posterior ao horário mínimo.</div>"
              data-tooltip-id="bt-filter-tp"
            />
          </label>
          <DatePicker
            id="backtest-minOpenTime"
            selected={parseToDatePicker(getValue("minOpenTime"))}
            onChange={date => setValue("minOpenTime", date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Hora"
            dateFormat="HH:mm"
            clearButtonClassName={css["timeClearButton"]}
            className={css["timePicker"]}
            wrapperClassName={css["timePickerWrapper"]}
            calendarClassName={css["timePickerCalendar"]}
            minTime={DateTime.now()
              .set({ hour: 9, minute: 0, second: 0 })
              .toJSDate()}
            maxTime={getMaxOpenTimeLimit(
              getValue("closeTimeLimit"),
              timeframe?.value
            )}
            placeholderText="Sem restrição de horário"
            isClearable
          />
        </div>
      )}
      {strategy?.parameters.includes("closeTimeLimit") &&
        canHaveTimeConstraints && (
          <div>
            <label htmlFor="backtest-closeTimeLimit">
              Horário Limite{" "}
              <InfoIcon
                className={css["infoIcon"]}
                data-tooltip-html="<div>Para que a entrada seja autorizada, o <b>fechamento</b> do candle deve ser igual ou anterior ao horário limite.</div>"
                data-tooltip-id="bt-filter-tp"
              />
            </label>
            <DatePicker
              id="backtest-closeTimeLimit"
              selected={parseToDatePicker(getValue("closeTimeLimit"))}
              onChange={date => setValue("closeTimeLimit", date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Hora"
              dateFormat="HH:mm"
              clearButtonClassName={css["timeClearButton"]}
              className={css["timePicker"]}
              wrapperClassName={css["timePickerWrapper"]}
              calendarClassName={css["timePickerCalendar"]}
              minTime={getMinCloseTimeLimit(
                getValue("minOpenTime"),
                timeframe?.value
              )}
              maxTime={DateTime.now()
                .set({ hour: 18, minute: 15, second: 0 })
                .toJSDate()}
              placeholderText="Sem restrição de horário"
              isClearable
            />
          </div>
        )}
      {strategy?.parameters.includes("entryTime") && canHaveTimeConstraints && (
        <div>
          <label htmlFor="backtest-entryTime">
            Horário de Entrada{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-html="<div>Entrada executada na <b>abertura</b> do candle desse horário.</div>"
              data-tooltip-id="bt-filter-tp"
            />
          </label>
          <DatePicker
            id="backtest-entryTime"
            selected={parseToDatePicker(getValue("entryTime"))}
            onChange={date => setValue("entryTime", date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={
              timeframe ? mapTimeframeToMinutes(timeframe.value) : 15
            }
            timeCaption="Hora"
            dateFormat="HH:mm"
            clearButtonClassName={css["timeClearButton"]}
            className={css["timePicker"]}
            calendarClassName={css["timePickerCalendar"]}
            minTime={DateTime.now()
              .set({ hour: 9, minute: 0, second: 0 })
              .toJSDate()}
            maxTime={getMaxOpenTimeLimit(
              getValue("exitTime"),
              timeframe?.value
            )}
            placeholderText="Selecione um horário"
            isClearable
          />
        </div>
      )}
      {strategy?.parameters.includes("exitTime") && canHaveTimeConstraints && (
        <div>
          <label htmlFor="backtest-exitTime">
            Horário de Saída{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-html="<div>Saída executada na <b>abertura</b> do candle desse horário.</div>"
              data-tooltip-id="bt-filter-tp"
            />
          </label>
          <DatePicker
            id="backtest-exitTime"
            selected={parseToDatePicker(getValue("exitTime"))}
            onChange={date => setValue("exitTime", date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={
              timeframe ? mapTimeframeToMinutes(timeframe.value) : 15
            }
            timeCaption="Hora"
            dateFormat="HH:mm"
            clearButtonClassName={css["timeClearButton"]}
            className={css["timePicker"]}
            calendarClassName={css["timePickerCalendar"]}
            minTime={getMinCloseTimeLimit(
              getValue("entryTime"),
              timeframe?.value
            )}
            maxTime={DateTime.now()
              .set({ hour: 18, minute: 15, second: 0 })
              .toJSDate()}
            placeholderText="Selecione um horário"
            isClearable
          />
        </div>
      )}
      {showCapital && (
        <div>
          <label
            style={{ color: hasError("capital") ? "#d64242" : undefined }}
            htmlFor="capital-filter">
            Capital Disponível
          </label>
          <Input
            id="capital-filter"
            value={getValue("capital")}
            onValueChange={({ floatValue }) => setValue("capital", floatValue)}
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix={asset?.isCrypto ? "$ " : "R$ "}
            decimalScale={0}
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 1000000
            }
            hint="Valor fixo utilizado em todos os trades"
            error={errors["capital"]}
          />
        </div>
      )}
      {showMaxRisk && (
        <div>
          <label htmlFor="max-risk-filter">Risco Máximo</label>
          <Input
            id="max-risk-filter"
            value={getValue("maxRisk")}
            onValueChange={({ floatValue }) => setValue("maxRisk", floatValue)}
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            prefix={asset?.isCrypto || asset?.value === "WSP$" ? "$ " : "R$ "}
            decimalScale={0}
            isAllowed={({ floatValue }) => floatValue <= 100000}
            hint="Risco máximo estimado por trade"
          />
        </div>
      )}
      {showContracts && (
        <div>
          <label
            style={{ color: hasError("contracts") ? "#d64242" : undefined }}
            htmlFor="contracts-filter">
            Número de Contratos
          </label>
          <Input
            id="contracts-filter"
            value={getValue("contracts")}
            onValueChange={({ floatValue }) =>
              setValue("contracts", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 100
            }
            hint="Entre 1 e 100"
            error={errors["contracts"]}
          />
        </div>
      )}
      {strategy?.parameters.includes("rsiWindow") &&
        strategy.value === "long_rsi" && (
          <div>
            <label htmlFor="rsiWindow-filter">Período IFR</label>
            <Input
              id="rsiWindow-filter"
              value={getValue("rsiWindow")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "rsiWindow",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 14"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 50
              }
              hint="Período de cálculo do IFR"
            />
          </div>
        )}
      {strategy?.parameters.includes("kWindow") && (
        <div>
          <label htmlFor="kWindow-filter">Período Estocástico</label>
          <Input
            id="kWindow-filter"
            value={getValue("kWindow")}
            onValueChange={({ floatValue }) =>
              setValue("kWindow", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder="Ex: 8"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 50
            }
            hint="Período de cálculo do Estocástico"
          />
        </div>
      )}
      {strategy?.parameters.includes("zscoreThreshold") && (
        <div>
          <label htmlFor="zscoreThreshold-filter">Z-Score</label>
          <Input
            id="zscoreThreshold-filter"
            value={getValue("zscoreThreshold")}
            onValueChange={({ floatValue }) =>
              setValue(
                "zscoreThreshold",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 2"
            hint={`Valor ${
              strategy.value.includes("long_") ? "mínimo" : "máximo"
            } para entrada`}
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue >= 1 && floatValue <= 5)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("numStd") && (
        <div>
          <label htmlFor="numStd-filter">Número de Desvios-Padrão</label>
          <Input
            id="numStd-filter"
            value={getValue("numStd")}
            onValueChange={({ floatValue }) =>
              setValue("numStd", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 2"
            hint="Utilizado para cálculo das Bandas de Bollinger."
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue >= 1 && floatValue <= 5)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("channelWindow") && (
        <div>
          <label htmlFor="channelWindow-filter">Janela do Canal</label>
          <Input
            id="channelWindow-filter"
            value={getValue("channelWindow")}
            onValueChange={({ floatValue }) =>
              setValue(
                "channelWindow",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder={3}
            hint="Período da média aritmética das máximas e mínimas."
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue >= 1 && floatValue <= 50)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("mmaWindow") && (
        <div>
          <label htmlFor="mmaWindow-filter">
            {[
              "long_open_bb",
              "short_open_bb",
              "long_reversal_bb",
              "short_reversal_bb",
            ].includes(strategy.value)
              ? "Período da Média Móvel"
              : ["long_three_bar", "short_three_bar"].includes(strategy.value)
              ? "Período da Tendência"
              : "Período de Suavização"}
          </label>
          <Input
            id="mmaWindow-filter"
            value={getValue("mmaWindow")}
            onValueChange={({ floatValue }) =>
              setValue("mmaWindow", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder={`Ex: ${
              [
                "long_open_bb",
                "short_open_bb",
                "long_reversal_bb",
                "short_reversal_bb",
              ].includes(strategy.value)
                ? "20"
                : ["long_three_bar", "short_three_bar"].includes(strategy.value)
                ? "30"
                : "3"
            }`}
            hint={
              ["long_three_bar", "short_three_bar"].includes(strategy.value)
                ? "Período da média aritmética que caracteriza a tendência."
                : "Período da média aritmética que caracteriza a banda central."
            }
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue >= 1 && floatValue <= 50)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("rollingWindow") && (
        <div>
          <label htmlFor="rollingWindow-filter">Período</label>
          <Input
            id="rollingWindow-filter"
            value={getValue("rollingWindow")}
            onValueChange={({ floatValue }) =>
              setValue(
                "rollingWindow",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder={`Ex: 20`}
            hint="Período utilizado para cálculo do Z-Score"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue >= 1 && floatValue <= 80)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("bodyPct") && (
        <div>
          <label htmlFor="bodyPct-filter">Tamanho do Corpo (%)</label>
          <Input
            id="bodyPct-filter"
            value={getValue("bodyPct")}
            onValueChange={({ floatValue }) =>
              setValue("bodyPct", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 20"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 25
            }
            hint="% do corpo em relação à amplitude"
          />
        </div>
      )}
      {strategy?.parameters.includes("gapPct") && (
        <div>
          <label htmlFor="gapPct-filter">Gap (%)</label>
          <Input
            id="gapPct-filter"
            value={getValue("gapPct")}
            onValueChange={({ floatValue }) =>
              setValue("gapPct", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 1"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 5
            }
            hint="Entre 0,5% e 5%"
          />
        </div>
      )}
      {strategy?.parameters.includes("negativeSequence") && (
        <div>
          <label htmlFor="negativeSequence-filter">
            Sequência de Queda (candles)
          </label>
          <Input
            id="negativeSequence-filter"
            value={getValue("negativeSequence")}
            onValueChange={({ floatValue }) =>
              setValue(
                "negativeSequence",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            title={
              isUser ? undefined : "Crie sua conta para desbloquear esse filtro"
            }
            disabled={strategy.value === "long_turnaround" && !isUser}
            hint="Candles consecutivos de queda (max: 10)"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue > 0 && floatValue <= 10)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("candlesAbove") && (
        <div>
          <label htmlFor="candlesAbove-filter">Sequência acima da média</label>
          <Input
            id="candlesAbove-filter"
            value={getValue("candlesAbove")}
            onValueChange={({ floatValue }) =>
              setValue(
                "candlesAbove",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            hint="Candles sem tocar a média (max: 20)"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || (floatValue > 0 && floatValue <= 20)
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("exhaustionPct") && (
        <div>
          <label htmlFor="exhaustionPct-filter">
            Barra de Exaustão (%){" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={"Tamanho mínimo da queda no último dia."}
            />
          </label>
          <Input
            id="exhaustionPct-filter"
            value={getValue("exhaustionPct")}
            onValueChange={({ floatValue }) =>
              setValue(
                "exhaustionPct",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 0.5"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 5
            }
            hint="Entre 0% e 5%"
          />
        </div>
      )}
      {strategy?.parameters.includes("entryVariation") && (
        <div>
          <label htmlFor="entryVariation-filter">
            Variação para Entrada (%){" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Variação percentual do ativo para que a entrada seja ativada."
              }
            />
          </label>
          <Input
            id="entryVariation-filter"
            value={getValue("entryVariation")}
            onValueChange={({ floatValue }) =>
              setValue(
                "entryVariation",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={true}
            decimalScale={1}
            placeholder="Ex: 0.5"
            isAllowed={({ floatValue }) =>
              floatValue === undefined ||
              floatValue <= 5 ||
              floatValue >= -5 ||
              floatValue === 0
            }
            hint="Entre -5% e 5%"
          />
        </div>
      )}
      {strategy?.parameters.includes("exitVariation") && (
        <div>
          <label htmlFor="exitVariation-filter">
            Alvo de Saída (%){" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Variação percentual buscada a partir da entrada."
              }
            />
          </label>
          <Input
            id="exitVariation-filter"
            value={getValue("exitVariation")}
            onValueChange={({ floatValue }) =>
              setValue(
                "exitVariation",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={strategy.value.includes("short_")}
            decimalScale={1}
            placeholder={
              strategy.value.includes("short_") ? "Ex: -0.5" : "Ex: 0.5"
            }
            isAllowed={({ floatValue }) =>
              strategy.value.includes("short_")
                ? floatValue === undefined ||
                  (floatValue <= 0 && floatValue >= -5)
                : floatValue === undefined ||
                  (floatValue <= 5 && floatValue >= 0)
            }
            hint={
              strategy.value.includes("short_")
                ? "Entre -5% e 0%"
                : "Entre 0% e 5%"
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("applyRsiFilter") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-applyRsiFilter"
            type="checkbox"
            checked={getValue("applyRsiFilter")}
            onChange={e => setValue("applyRsiFilter", e.target.checked)}
          />
          <label htmlFor="backtest-applyRsiFilter">
            Filtrar por IFR{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={`Condiciona a entrada a um valor ${
                strategy.value === "short_open_bb" ? "mínimo" : "máximo"
              } de IFR`}
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("rsi") &&
        (![
          "long_open_bb",
          "short_open_bb",
          "long_reversal_bb",
          "short_reversal_bb",
        ].includes(strategy.value) ||
          getValue("applyRsiFilter")) && (
          <div>
            <label htmlFor="rsi-filter">
              {strategy.value === "short_open_bb" ? "IFR Mínimo" : "IFR Máximo"}
            </label>
            <Input
              id="rsi-filter"
              value={getValue("rsi")}
              onValueChange={({ floatValue }) =>
                setValue("rsi", floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 30"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              hint={
                ["long_open_bb", "long_reversal_bb"].includes(strategy.value)
                  ? getValue("rsi") === null
                    ? "Valor máximo de IFR para entrada"
                    : `Entrada apenas com IFR ≤ ${getValue("rsi")}`
                  : ["short_open_bb", "short_reversal_bb"].includes(
                      strategy.value
                    )
                  ? getValue("rsi") === null
                    ? "Valor mínimo de IFR para entrada"
                    : `Entrada apenas com IFR ≥ ${getValue("rsi")}`
                  : "Entre 0 e 100"
              }
            />
          </div>
        )}
      {strategy?.parameters.includes("rsiWindow") &&
        [
          "long_open_bb",
          "short_open_bb",
          "long_reversal_bb",
          "short_reversal_bb",
        ].includes(strategy.value) &&
        getValue("applyRsiFilter") && (
          <div>
            <label htmlFor="rsiWindow-filter">Período IFR</label>
            <Input
              id="rsiWindow-filter"
              value={getValue("rsiWindow")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "rsiWindow",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 14"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 50
              }
              hint="Período do IFR a ser utilizado como filtro"
            />
          </div>
        )}
      {strategy?.parameters.includes("applyKeltnerFilter") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-applyKeltnerFilter"
            type="checkbox"
            checked={getValue("applyKeltnerFilter")}
            onChange={e => setValue("applyKeltnerFilter", e.target.checked)}
          />
          <label htmlFor="backtest-applyKeltnerFilter">
            Filtrar pela Keltner{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={`Condiciona a entrada a um fechamento ${
                strategy.value === "short_maxmin" ? "abaixo" : "acima"
              } das Keltners`}
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("keltnerWindow") &&
        getValue("applyKeltnerFilter") && (
          <div>
            <label htmlFor="keltnerWindow-filter">Período da Keltner</label>
            <Input
              id="keltnerWindow-filter"
              value={getValue("keltnerWindow")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "keltnerWindow",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 20"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 50
              }
              hint="Período da média móvel aritmética utilizada para cálculo das Keltners"
            />
          </div>
        )}
      {strategy?.parameters.includes("keltnerMultiplier") &&
        getValue("applyKeltnerFilter") && (
          <div>
            <label htmlFor="keltnerMultiplier-filter">
              Multiplicador da Keltner
            </label>
            <Input
              id="keltnerMultiplier-filter"
              value={getValue("keltnerMultiplier")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "keltnerMultiplier",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={2}
              placeholder="Ex: 1"
              hint="Multiplicador da amplitude dos canais de Keltner."
              isAllowed={({ floatValue }) =>
                floatValue === undefined || (floatValue >= 0 && floatValue <= 5)
              }
            />
          </div>
        )}
      {strategy?.parameters.includes("tradersEden") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-tradersEden"
            type="checkbox"
            checked={getValue("tradersEden")}
            onChange={e => setValue("tradersEden", e.target.checked)}
          />
          <label htmlFor="backtest-tradersEden">
            Éden dos Traders{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={"Candle sinal acima da MME8 e MME80."}
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("insideBar") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-insideBar"
            type="checkbox"
            checked={getValue("insideBar")}
            onChange={e => setValue("insideBar", e.target.checked)}
          />
          <label htmlFor="backtest-insideBar">
            Inside Bar{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Candle sinal tem sua máxima menor e a mínima maior que o candle anterior."
              }
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("hammer") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-hammer"
            type="checkbox"
            checked={getValue("hammer")}
            onChange={e => setValue("hammer", e.target.checked)}
          />
          <label htmlFor="backtest-hammer">
            Candle Martelo{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                strategy.value.startsWith("long_")
                  ? `Candle sinal possui o fechamento maior que a abertura.`
                  : `Candle sinal possui o fechamento menor que a abertura.`
              }
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("mm50IsUp") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-mm50IsUp"
            type="checkbox"
            checked={getValue("mm50IsUp")}
            onChange={e => setValue("mm50IsUp", e.target.checked)}
          />
          <label htmlFor="backtest-mm50IsUp">
            MM50 Subindo{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Entrada apenas se a média móvel aritmética de 50 períodos estiver subindo."
              }
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("mme80IsUp") && (
        <div className={css["checkbox"]}>
          <input
            id="backtest-mme80IsUp"
            type="checkbox"
            checked={getValue("mme80IsUp")}
            onChange={e => setValue("mme80IsUp", e.target.checked)}
          />
          <label htmlFor="backtest-mme80IsUp">
            MME80 {strategy.value.startsWith("short_") ? "Caindo" : "Subindo"}{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                "Entrada apenas se a média móvel exponencial de 80 períodos estiver subindo."
              }
            />
          </label>
        </div>
      )}
      {strategy?.parameters.includes("entry") && (
        <div>
          <label htmlFor="backtest-entry">Entrada</label>
          <Select
            id="backtest-entry"
            options={getValue("strategy").entry}
            value={entry}
            onChange={option => setValue("entry", option)}
            styles={SELECT_SMALL}
            isSearchable={false}
          />
        </div>
      )}
      {strategy?.parameters.includes("rollingAvgType") &&
        (!["long_rsi", "long_stochastic", "short_stochastic"].includes(
          strategy.value
        ) ||
          getValue("entry")?.value === "rolling_avg") && (
          <div>
            <label htmlFor="backtest-rollingAvgType">Tipo de Média</label>
            <Select
              id="backtest-rollingAvgType"
              options={getValue("strategy").rollingAvgType}
              value={getValue("rollingAvgType")}
              onChange={option => setValue("rollingAvgType", option)}
              styles={SELECT_SMALL}
              isSearchable={false}
            />
          </div>
        )}
      {strategy?.parameters.includes("rollingAvgWindow") &&
        (!["long_rsi", "long_stochastic", "short_stochastic"].includes(
          strategy.value
        ) ||
          getValue("entry")?.value === "rolling_avg") && (
          <div>
            <label htmlFor="rollingAvgWindow-filter">Período da Média</label>
            <Input
              id="rollingAvgWindow-filter"
              value={getValue("rollingAvgWindow")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "rollingAvgWindow",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 8"
              hint="Max: 80"
              isAllowed={({ floatValue }) =>
                floatValue === undefined ||
                (floatValue >= 1 && floatValue <= 80)
              }
            />
          </div>
        )}
      {strategy?.parameters.includes("entryRsi") && entry?.value === "rsi" && (
        <div>
          <label htmlFor="entryRsi-filter">IFR Entrada</label>
          <Input
            id="entryRsi-filter"
            value={getValue("entryRsi")}
            onValueChange={({ floatValue }) =>
              setValue("entryRsi", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder="Ex: 30"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 100
            }
            hint="Entre 0 e 100"
          />
        </div>
      )}
      {strategy?.parameters.includes("entryStochastic") &&
        entry?.value === "stochastic" && (
          <div>
            <label htmlFor="entryStochastic-filter">
              Estocástico de Entrada
            </label>
            <Input
              id="entryStochastic-filter"
              value={getValue("entryStochastic")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "entryStochastic",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder={`Ex: ${
                strategy.value.startsWith("short_") ? "80" : "20"
              }`}
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              hint="Entre 0 e 100"
            />
          </div>
        )}
      {strategy?.parameters.includes("target") && (
        <div>
          <label htmlFor="backtest-target">
            Alvo{" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={
                getValue("target")?.value === "amplitude"
                  ? "Alvo é a amplitude dos 3 candles a partir da máxima do 3º candle."
                  : getValue("target")?.value === "risk"
                  ? "Alvo é a diferença entre o ponto de entrada e o stop multiplicado pelo fator de risco."
                  : getValue("target")?.value === "window"
                  ? "Alvo é a máxima da janela."
                  : getValue("target")?.value === "rsi"
                  ? "Alvo é o fechamento quando o IFR fechar acima de um valor mínimo."
                  : getValue("target")?.value === "stochastic"
                  ? "Alvo é o fechamento quando o Estocástico fechar acima de um valor mínimo."
                  : getValue("target")?.value === "fibo"
                  ? "Alvo é uma % da amplitude do candle sinal."
                  : getValue("target")?.value === "percentage"
                  ? "Alvo é uma % fixa a partir da entrada."
                  : getValue("target")?.value === "std"
                  ? "Alvo é um múltiplo do desvio-padrão."
                  : ""
              }
            />
          </label>
          <Select
            id="backtest-target"
            options={getValue("strategy").target}
            value={getValue("target")}
            onChange={option => setValue("target", option)}
            styles={SELECT_SMALL}
            isSearchable={false}
            placeholder="Selecione o tipo de alvo"
          />
        </div>
      )}
      {strategy?.parameters.includes("exitWindow") &&
        getValue("target")?.value === "window" && (
          <div>
            <label htmlFor="exitWindow-filter">Janela (candles)</label>
            <Input
              id="exitWindow-filter"
              value={getValue("exitWindow")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "exitWindow",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 2"
              hint={
                [
                  "long_atypical_variation",
                  "short_atypical_variation",
                ].includes(strategy.value)
                  ? "Alvo em candles"
                  : "Alvo na máxima da janela"
              }
            />
          </div>
        )}
      {strategy?.parameters.includes("stdFactor") &&
        getValue("target")?.value === "std" && (
          <div>
            <label htmlFor="stdFactor-filter">Número de Desvios-Padrão</label>
            <Input
              id="stdFactor-filter"
              value={getValue("stdFactor")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "stdFactor",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={1}
              placeholder="Ex: 2"
              hint="Alvo será uma % dada pelo múltiplo do desvio-padrão"
              isAllowed={({ floatValue }) =>
                floatValue === undefined ||
                (floatValue >= 0.5 && floatValue <= 5)
              }
            />
          </div>
        )}
      {strategy?.parameters.includes("exitRsi") &&
        getValue("target")?.value === "rsi" && (
          <div>
            <label htmlFor="exitRsi-filter">IFR Saída</label>
            <Input
              id="exitRsi-filter"
              value={getValue("exitRsi")}
              onValueChange={({ floatValue }) =>
                setValue("exitRsi", floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder="Ex: 70"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              hint="Entre 0 e 100"
            />
          </div>
        )}
      {strategy?.parameters.includes("exitStochastic") &&
        getValue("target")?.value === "stochastic" && (
          <div>
            <label htmlFor="exitStochastic-filter">Estocástico de Saída</label>
            <Input
              id="exitStochastic-filter"
              value={getValue("exitStochastic")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "exitStochastic",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              placeholder={`Ex: ${
                strategy.value.startsWith("short_") ? "20" : "80"
              }`}
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              hint="Entre 0 e 100"
            />
          </div>
        )}
      {strategy?.parameters.includes("riskFactor") &&
        (!strategy?.parameters.includes("target") ||
          getValue("target")?.value === "risk") && (
          <div>
            <label htmlFor="riskFactor-filter">Fator de Risco</label>
            <Input
              id="riskFactor-filter"
              value={getValue("riskFactor")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "riskFactor",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={2}
              placeholder="Ex: 2"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 5
              }
              hint="Entre 1 e 5"
            />
          </div>
        )}
      {strategy?.parameters.includes("window") && (
        <div>
          <label htmlFor="window-filter">
            {[
              "long_open_bb",
              "short_open_bb",
              "long_reversal_bb",
              "short_reversal_bb",
            ].includes(strategy.value)
              ? "Alvo (candles)"
              : "Janela (candles)"}
          </label>
          <Input
            id="window-filter"
            value={getValue("window")}
            onValueChange={({ floatValue }) =>
              setValue("window", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder={`Ex: ${
              ["long_hilo_activator", "short_hilo_activator"].includes(
                strategy.value
              )
                ? "8"
                : ["long_pfr", "short_pfr"].includes(strategy.value)
                ? "3"
                : "2"
            }`}
            hint={
              strategy.value === "ifr2"
                ? "Alvo na máxima da janela"
                : strategy.value === "maxmin"
                ? "Entrada na mínima e alvo na máxima da janela"
                : strategy.value === "short_maxmin"
                ? "Entrada na máxima e alvo na mínima da janela"
                : strategy.value === "long_pfr"
                ? "Candle sinal terá a menor mínima da janela"
                : strategy.value === "short_pfr"
                ? "Candle sinal terá a maior máxima da janela"
                : strategy.value.includes("hilo_activator")
                ? "Período de cálculo do indicador"
                : "Alvo em candles"
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("targetBand") && (
        <div>
          <label htmlFor="backtest-target-band">Banda Alvo</label>
          <Select
            id="backtest-target-band"
            options={getValue("strategy").targetBands}
            value={getValue("targetBand")}
            onChange={option => setValue("targetBand", option)}
            styles={SELECT_SMALL}
            isSearchable={false}
          />
        </div>
      )}
      {strategy?.parameters.includes("targetInPct") &&
        (!strategy?.parameters.includes("target") ||
          getValue("target")?.value === "percentage") && (
          <div>
            <label htmlFor="targetInPct-filter">Alvo (%)</label>
            <Input
              id="targetInPct-filter"
              value={getValue("targetInPct")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "targetInPct",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={1}
              placeholder="Ex: 3"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              hint="% de lucro a partir da entrada"
            />
          </div>
        )}
      {strategy?.parameters.includes("fiboExtension") &&
        getValue("target")?.value === "fibo" && (
          <div>
            <label htmlFor="fiboExtension-filter">
              Extensão de Fibonacci (%){" "}
              <InfoIcon
                className={css["infoIcon"]}
                data-tooltip-id="bt-filter-tp"
                data-tooltip-content={`Alvo é uma % da amplitude do candle rompido projetado a partir da ${
                  strategy.value === "long_breakout"
                    ? "máxima"
                    : strategy.value === "short_breakout"
                    ? "mínima"
                    : "entrada"
                }.`}
              />
            </label>
            <Input
              id="fiboExtension-filter"
              value={getValue("fiboExtension")}
              onValueChange={({ floatValue }) =>
                setValue(
                  "fiboExtension",
                  floatValue === undefined ? "" : floatValue
                )
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={1}
              placeholder="Ex: 61.8%"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 261.8
              }
              hint="Entre 11,8% e 261,8%"
            />
          </div>
        )}
      {strategy?.parameters.includes("stop") && (
        <div>
          <label htmlFor="backtest-stop">Stop</label>
          <Select
            id="backtest-stop"
            options={getValue("strategy").stop}
            value={getValue("stop")}
            onChange={option => setValue("stop", option)}
            styles={SELECT_SMALL}
            isSearchable={false}
          />
        </div>
      )}
      {strategy?.parameters.includes("stopInPct") && (
        <div>
          <label htmlFor="stopInPct-filter">Stop (%)</label>
          <Input
            id="stopInPct-filter"
            value={getValue("stopInPct")}
            onValueChange={({ floatValue }) =>
              setValue("stopInPct", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 2"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 100
            }
            hint={
              getValue("stopInPct") && !showContracts
                ? `Risco máximo por trade de ${(
                    (getValue("capital") * getValue("stopInPct")) /
                    100
                  ).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}`
                : "% máxima de perda a partir da entrada"
            }
          />
        </div>
      )}
      {strategy?.parameters.includes("fiboRetracement") && (
        <div>
          <label htmlFor="fiboRetracement-filter">
            Retração de Fibonacci (%){" "}
            <InfoIcon
              className={css["infoIcon"]}
              data-tooltip-id="bt-filter-tp"
              data-tooltip-content={`Stop é uma % da amplitude do candle rompido projetado a partir da ${
                strategy.value === "long_breakout"
                  ? "máxima"
                  : strategy.value === "short_breakout"
                  ? "mínima"
                  : "entrada"
              }.`}
            />
          </label>
          <Input
            id="fiboRetracement-filter"
            value={getValue("fiboRetracement")}
            onValueChange={({ floatValue }) =>
              setValue(
                "fiboRetracement",
                floatValue === undefined ? "" : floatValue
              )
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 111,8%"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 261.8
            }
            hint="Entre 100% e 261,8%"
          />
        </div>
      )}
      {strategy?.parameters.includes("stopInDays") && (
        <div>
          <label htmlFor="stop-filter">Stop (candles)</label>
          <Input
            id="stop-filter"
            value={getValue("stopInDays")}
            onValueChange={({ floatValue }) =>
              setValue("stopInDays", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            placeholder="Sem stop"
            disabled={strategy.value === "ifr2" && !isUser}
            hint="Número máximo de candles até o alvo"
          />
        </div>
      )}
      {strategy?.parameters.includes("stopInStd") && (
        <div>
          <label htmlFor="stopInStd-filter">Stop (σ)</label>
          <Input
            id="stopInStd-filter"
            value={getValue("stopInStd")}
            onValueChange={({ floatValue }) =>
              setValue("stopInStd", floatValue === undefined ? "" : floatValue)
            }
            fluid
            size="small"
            isNumber
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={1}
            placeholder="Ex: 2"
            isAllowed={({ floatValue }) =>
              floatValue === undefined || floatValue <= 5
            }
            hint="Stop será uma % dada pelo múltiplo do desvio-padrão a partir da entrada"
          />
        </div>
      )}
      <div className={css["buttonsContainer"]}>
        <Popover.Root open={showRunBacktestStep}>
          <Popover.Anchor>
            <button
              disabled={!canSubmit}
              className={css["button"]}
              onClick={() => {
                completeRunBacktestStep();
                onClick();
              }}>
              Rodar Backtest
            </button>
          </Popover.Anchor>
          {showRunBacktestStep &&
            !showDateStep &&
            !showStrategyStep &&
            !showTimeframeStep &&
            !showAssetStep && (
              <OnboardingStep
                step={runBacktestStep}
                onConfirm={completeRunBacktestStep}
                onDismiss={completeRunBacktestStep}
                side="right"
              />
            )}
        </Popover.Root>
        <button className={css["reset"]} onClick={resetFilter}>
          Resetar
        </button>
      </div>
    </div>
  );
};

BacktestFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
};

export default BacktestFilter;
