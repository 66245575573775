import { WhatsappIcon } from "react-share";
import * as css from "./WhatsAppLink.module.css";
import { generateWADeepLink } from "./WhatsAppFloatingButton";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";

const WhatsAppLink = () => {
  return (
    <a
      onClick={() => logAmplitudeEvent(EVENT_MAP.WHATSAPP_BUTTON_CLICKED)}
      target="_blank"
      rel="noreferrer"
      href={generateWADeepLink()}
      className={css["container"]}>
      <div className={css["icon"]}>
        <WhatsappIcon size={60} round iconFillColor="#fff" />
        <div>Fale com a gente no WhatsApp!</div>
      </div>
      <div className={css["copy"]}>
        <div>Fale com a gente no WhatsApp!</div>
        <div>
          Tire suas dúvidas sobre o plano Premium e a plataforma em geral
          diretamente com a gente no WhatsApp do QuantBrasil.
        </div>
      </div>
    </a>
  );
};

export default WhatsAppLink;
