import ExportIcon from "../../icons/export.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./ExportBacktest.module.css";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const ExportBacktest = ({ result, allowPopup }) => {
  const callAPI = useAPI({ withCredentials: true, returnRaw: true });

  const { refetch: exportData, isFetching } = useQuery(
    ["exportBacktest", result.id],
    () => callAPI(`/api/backtest/${result.id}/export`),
    {
      enabled: false,
      onError: showError,
      onSuccess: async response => {
        if (response.status >= 200 && response.status < 300) {
          const data = await response.text();
          const blob = new Blob([data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = "trade_history.csv";
          anchor.click();
          window.URL.revokeObjectURL(url);
        } else {
          const data = await response.json();
          showError({
            message: data?.error || "Tente novamente mais tarde.",
          });
        }
      },
    }
  );

  const {
    step,
    markStepAsCompleted,
    showStep: showExportBacktest,
  } = useOnboardingStep("EXPORT_BACKTEST");

  const { showStep: showSaveBacktest } = useOnboardingStep("SAVE_BACKTEST");

  const showStep = allowPopup && showExportBacktest && !showSaveBacktest;

  const onClick = () => {
    markStepAsCompleted();
    exportData();
    logAmplitudeEvent(EVENT_MAP.BACKTEST_EXPORTED, {
      id: result.id,
    });
  };

  return (
    <Popover.Root open={showStep}>
      <Popover.Anchor>
        <button
          data-tooltip-id="export-bt-tooltip"
          data-tooltip-content="Exportar histórico de trades"
          disabled={isFetching}
          onClick={onClick}
          className={css["button"]}>
          {isFetching ? (
            <div className={css["spinner"]}>
              <Spinner type="Rings" size={22} />
            </div>
          ) : (
            <>
              <ExportIcon />
              <span>Exportar</span>
              <Tooltip id="export-bt-tooltip" />
            </>
          )}
        </button>
      </Popover.Anchor>
      {showStep && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

ExportBacktest.propTypes = {
  result: PropTypes.object.isRequired,
  allowPopup: PropTypes.bool,
};

export default ExportBacktest;
