// extracted by mini-css-extract-plugin
export var button = "BacktestPopup-module--button--c7ed8";
export var buttonOutline = "BacktestPopup-module--buttonOutline--9d628";
export var buttons = "BacktestPopup-module--buttons--930f0";
export var close = "BacktestPopup-module--close--a46e6";
export var content = "BacktestPopup-module--content--e34f9";
export var modal = "BacktestPopup-module--modal--ac91d";
export var modalAfter = "BacktestPopup-module--modalAfter--9b17b";
export var modalBefore = "BacktestPopup-module--modalBefore--c23a7";
export var notNow = "BacktestPopup-module--notNow--9caaf";
export var overlay = "BacktestPopup-module--overlay--2b1b6";
export var title = "BacktestPopup-module--title--8447d";