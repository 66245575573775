import { ResponsiveCalendar } from "@nivo/calendar";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useMedia } from "react-use";
import { groupBy } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { useMemo } from "react";
import CapitalTooltip from "./CapitalTooltip";

const colorScale = profit => (profit >= 0 ? "#61cdbb" : "#f47560");
colorScale.ticks = () => [];

export const groupTrades = tradeHistory => {
  const grouped = groupBy(tradeHistory, "exit_date");
  const result = [];
  Object.keys(grouped).forEach(key => {
    const trades = grouped[key];
    const numberTrades = trades.length;
    const totalProfit = trades
      .map(({ profit }) => profit)
      .reduce((a, b) => a + b, 0);
    result.push({
      day: key,
      numberTrades,
      value: totalProfit,
      profit: totalProfit,
      trades,
    });
  });
  return result;
};

const CalendarChart = ({ tradeHistory, startDate, endDate }) => {
  const isMobile = useMedia("(max-width: 1023px)");

  const { isLoggedIn } = useAuth();

  const numberOfCalendars =
    DateTime.fromISO(endDate).get("year") -
    DateTime.fromISO(startDate).get("year") +
    1;

  // Magic number for each calendar height
  const getHeight = () => `${(isMobile ? 90 : 140) * numberOfCalendars}px`;

  const data = useMemo(() => groupTrades(tradeHistory), [tradeHistory]);

  return (
    <div
      style={{
        height: getHeight(),
        backgroundColor: "#fff",
        filter: `blur(${isLoggedIn ? 0 : 4}px)`,
      }}>
      <ResponsiveCalendar
        data={data}
        margin={{
          top: 40,
          right: 0,
          bottom: 0,
          left: 20,
        }}
        from={DateTime.fromISO(startDate).toJSDate()}
        to={DateTime.fromISO(endDate).toJSDate()}
        emptyColor="#eeeeee"
        colorScale={colorScale}
        monthBorderWidth={0}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        tooltip={CapitalTooltip}
        align="top"
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            translateY: 36,
            itemCount: 4,
            itemWidth: 42,
            itemHeight: 36,
            itemsSpacing: 14,
            itemDirection: "right-to-left",
          },
        ]}
      />
    </div>
  );
};

CalendarChart.propTypes = {
  tradeHistory: PropTypes.array.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default CalendarChart;
