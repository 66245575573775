import PropTypes from "prop-types";
import { useState } from "react";
import Modal from "react-modal";
import { useMutation } from "react-query";

import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import * as css from "./DeletePortfolio.module.css";

const DeletePortfolio = ({ portfolio, onDelete }) => {
  const [isOpen, setOpen] = useState(false);
  const callAPI = useAPI({ withCredentials: true });

  const mutation = useMutation(
    () =>
      callAPI(`/api/portfolio/${portfolio.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onError: showError,
      onSuccess: () => {
        onDelete();
        setOpen(false);
      },
    }
  );

  return (
    <div className={css["container"]}>
      <button className={css["button"]} onClick={() => setOpen(true)}>
        Deletar Portfólio
      </button>
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        overlayClassName={css["overlay"]}
        className={css["modal"]}
        onRequestClose={() => setOpen(false)}
        contentLabel="Confirmação">
        <div className={css["content"]}>
          <p>
            Tem certeza que deseja deletar <br /> <span>{portfolio.name}</span>?
            Essa ação é <b>irreversível</b>.
          </p>
          <div className={css["buttonsContainer"]}>
            <button
              className={css["button"]}
              disabled={mutation.isLoading}
              onClick={() => mutation.mutate()}>
              Deletar
            </button>
            <button
              className={css["cancel"]}
              disabled={mutation.isLoading}
              onClick={() => setOpen(false)}>
              Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

DeletePortfolio.propTypes = {
  portfolio: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeletePortfolio;
