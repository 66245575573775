import { ErrorBoundary } from "@sentry/react";
import PropTypes from "prop-types";

import * as css from "./ErrorBoundary.module.css";

const Fallback = ({ error, resetError }) => (
  <div role="alert" className={css["container"]}>
    <div>
      <h2>Ops, tivemos um problema 😬</h2>
      <p>
        O QuantBrasil ainda está em beta. Pedimos sua paciência e cooperação
        para deixar a plataforma cada vez melhor.
      </p>
      <pre>{error.message}</pre>
      <p>
        Tente novamente em alguns instantes. Se precisar, reporte a{" "}
        <a href="mailto:rafael@quantbrasil.com.br">rafael@quantbrasil.com.br</a>{" "}
        ou entre em contato diretamente através do nosso{" "}
        <a href="https://t.me/quantbrasil">Telegram</a>.
      </p>
      <button
        onClick={() => {
          history.go(-1);
          setTimeout(() => {
            resetError();
          }, 100);
        }}
        className={css["back"]}>
        ← Voltar
      </button>
    </div>
  </div>
);

Fallback.propTypes = {
  error: PropTypes.object.isRequired,
  resetError: PropTypes.func,
};

const ErrorBoundaryContainer = ({ children }) => (
  <ErrorBoundary fallback={Fallback}>{children}</ErrorBoundary>
);

ErrorBoundaryContainer.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundaryContainer;
