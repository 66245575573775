import { camelCase, forEach, isArray, isEmpty, isPlainObject } from "lodash";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import { STRATEGY_OPTIONS, TIMEFRAME_OPTIONS } from "./constants";

export const getRSIStyle = rsi => ({
  color: rsi <= 30 ? "#49ce8b" : rsi >= 70 ? "#d64242" : undefined,
  fontWeight: rsi <= 30 || rsi >= 70 ? "600" : undefined,
});

const unexpectedError =
  "Houve um erro com sua requisição. Por favor tente novamente.";

export const checkStatus = response => {
  return new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 300) {
      // 204 No Content means that we don't need to parse into JSON
      if (response.status === 204) return resolve({ success: true });
      return response.json().then(resolve);
    }
    const error = new Error(response.statusText);
    return response
      .json()
      .then(json => {
        // Receive JSON information about the error from the server
        if (Array.isArray(json.error)) {
          error.message = json.error[0].msg;
        } else if (json.error && !Array.isArray(json.error)) {
          error.message = json.error;
        } else if (json.msg) {
          error.message = json.msg;
        } else {
          error.message = unexpectedError;
        }

        // Handle expired cookie differently
        if (
          [
            `Missing cookie "access_token_cookie"`,
            "Token has expired",
            "Usuário não autorizado.",
            "Erro ao decodificar o token de acesso.",
            "Cookie não encontrado.",
            "Usuário requerido.",
          ].includes(error.message)
        ) {
          error.message = "INVALID_TOKEN";
        }
      })
      .catch(() => {
        // Default error attributes, means server hasn't provided extra information
        error.message = unexpectedError;
      })
      .then(() => reject(error));
  });
};

export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const isValidEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPassword = (password, confirmation) =>
  password.length >= 6 && password === confirmation;

export const isValidName = name => !isEmpty(name);

export const showError = err => toast.error(err.message);

const PARAMETERS_MAP = [
  {
    key: "window",
    mapper: value => `Janela: ${value} candle${value === 1 ? "" : "s"}`,
  },
  {
    key: "exit_window",
    mapper: value => `Janela: ${value} candle${value === 1 ? "" : "s"}`,
  },
  {
    key: "rsi_window",
    mapper: value => `Período IFR: ${value}`,
  },
  {
    key: "k_window",
    mapper: value => `Período Estocástico: ${value}`,
  },
  {
    key: "mma_window",
    mapper: (value, { channel_window }) =>
      channel_window
        ? `Período de Tendência: MMA${value}`
        : `Suavização Estocástico: MMA${value}`,
  },
  {
    key: "channel_window",
    mapper: value => `Janela do Canal: ${value}`,
  },
  {
    key: "num_std",
    mapper: value => `Números de Desvios-Padrão: ${value}`,
  },
  {
    key: "rolling_window",
    mapper: value => `Período Média Móvel: ${value}`,
  },
  {
    key: "zscore_threshold",
    mapper: value => `Limite Z-Score: ${value}`,
  },
  {
    key: "stop_in_days",
    mapper: value =>
      `Stop: ${
        value === 0 ? "Sem stop" : `${value} candle${value === 1 ? "" : "s"}`
      }`,
  },
  { key: "rsi", mapper: value => `IFR: ${value}` },
  { key: "entry_rsi", mapper: value => `IFR Entrada: ${value}` },
  { key: "exit_rsi", mapper: value => `IFR Saída: ${value}` },
  {
    key: "entry_stochastic",
    mapper: (value, { entry }) =>
      `Estocástico de Entrada: ${entry === "stochastic" ? value : "Qualquer"}`,
  },
  {
    key: "exit_stochastic",
    mapper: (value, { target }) =>
      `Estocástico de Saída: ${target === "stochastic" ? value : "Qualquer"}`,
  },
  {
    key: "target_band",
    mapper: value =>
      `Alvo: ${value === "middle" ? "Banda do Meio" : "Banda Oposta"}`,
  },
  {
    key: "rolling_avg_type",
    mapper: value =>
      `Média Móvel: ${value === "ewm" ? "Exponencial" : "Aritmética"}`,
  },
  {
    key: "exhaustion_pct",
    mapper: value => `Barra de Exaustão: ${toPercent(value, 1)}`,
  },
  {
    key: "stop_in_pct",
    mapper: value => `Stop: ${value === 0 ? "Sem stop" : toPercent(value)}`,
  },
  {
    key: "stop_in_std",
    mapper: value => `Stop: ${value === 0 ? "Sem stop" : "Desvios-Padrão"}`,
  },
  {
    key: "target_in_pct",
    mapper: value => `Alvo: ${value === 0 ? "Sem alvo" : toPercent(value)}`,
  },
  {
    key: "entry_variation",
    mapper: value => `Variação para Entrada: ${toPercent(value, 1)}`,
  },
  {
    key: "exit_variation",
    mapper: value => `Alvo de Saída: ${toPercent(value, 1)}`,
  },
  {
    key: "fibo_retracement",
    mapper: value => `Stop: Retração de ${toPercent(value, 1)} de Fibonacci`,
  },
  {
    key: "gap_pct",
    mapper: value => `Gap: ${toPercent(value)}`,
  },
  {
    key: "body_pct",
    mapper: value => `Tamanho do Corpo: ${toPercent(value, 0)}`,
  },
  {
    key: "negative_sequence",
    mapper: value =>
      `Sequência de Queda: ${value} candle${value === 1 ? "" : "s"}`,
  },
  {
    key: "candles_above",
    mapper: value =>
      `Sequência acima da média: ${value} candle${value === 1 ? "" : "s"}`,
  },
  {
    key: "traders_eden",
    mapper: value => `Éden dos Traders: ${value ? "Sim" : "Indiferente"}`,
  },
  {
    key: "inside_bar",
    mapper: value => `Inside Bar: ${value ? "Sim" : "Indiferente"}`,
  },
  {
    key: "hammer",
    mapper: value => `Candle Martelo: ${value ? "Sim" : "Indiferente"}`,
  },
  {
    key: "mm50_is_up",
    mapper: value => `MM50 Subindo: ${value ? "Sim" : "Indiferente"}`,
  },
  {
    key: "is_day_trade",
    mapper: value => `Day-Trade: ${value ? "Sim" : "Indiferente"}`,
  },
  {
    key: "min_open_time",
    mapper: value => `Horário Mínimo: ${value}`,
  },
  {
    key: "close_time_limit",
    mapper: value => `Horário Limite: ${value}`,
  },
  {
    key: "entry_time",
    mapper: value => `Horário de Entrada: ${value}`,
  },
  {
    key: "exit_time",
    mapper: value => `Horário de Saída: ${value}`,
  },
  {
    key: "capital_exposure",
    mapper: value => `Exposição Máxima: ${toPercent(value, 0)}`,
  },
  {
    key: "target",
    mapper: (value, { risk_factor, target_in_pct, fibo_extension }) =>
      `Alvo: ${
        value === "amplitude"
          ? "Amplitude"
          : value === "risk"
          ? `${risk_factor}x Risco`
          : value === "window"
          ? "Janela"
          : value === "rsi"
          ? "IFR"
          : value === "percentage"
          ? `${toPercent(target_in_pct, 1)}`
          : value === "fibo"
          ? `Extensão de ${toPercent(fibo_extension, 1)} de Fibonacci`
          : "Indefinido"
      }`,
  },
  {
    key: "stop",
    mapper: value =>
      `Stop: ${
        value === "candle1"
          ? "Mínima do Candle 1"
          : value === "candle2"
          ? "Mínima do Candle 2"
          : value === "candle3"
          ? "Mínima do Candle 3"
          : "Indefinido"
      }`,
  },
  {
    key: "entry",
    mapper: value =>
      `Entrada: ${
        value === "candle1"
          ? "Máxima do Candle 1"
          : value === "candle2"
          ? "Máxima do Candle 2"
          : value === "candle3"
          ? "Máxima do Candle 3"
          : value === "stochastic"
          ? "Valor do Estocástico Lento"
          : value === "rsi"
          ? "Valor de IFR"
          : value === "rolling_avg"
          ? "Cruzamento de Média Móvel"
          : "Indefinido"
      }`,
  },
];

export const parseParameters = parameters =>
  isEmpty(parameters)
    ? "Nenhum"
    : Object.keys(parameters)
        .map(key =>
          PARAMETERS_MAP.find(obj => obj.key === key)?.mapper(
            parameters[key],
            parameters
          )
        )
        .filter(value => typeof value !== "undefined");

export const toPercent = (value, decimals = 2) =>
  (value * 100).toFixed(decimals) + "%";

export const objectKeysToCamelCase = snake_case_object => {
  var camelCaseObject = {};
  forEach(snake_case_object, function (value, key) {
    if (isPlainObject(value) || isArray(value)) {
      // checks that a value is a plain object or an array - for recursive key conversion
      value = objectKeysToCamelCase(value); // recursively update keys of any values that are also objects
    }
    camelCaseObject[camelCase(key)] = value;
  });
  return camelCaseObject;
};

export const parseStrategyDescription = (strategy, parameters) => {
  if (strategy === "ifr2")
    return `Entrada com IFR2 abaixo de ${
      parameters.rsi || "{IFR Máximo}"
    }, alvo na máxima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || `{Janela}`} dia${
      parameters.window === 1 ? "" : "s"
    }, ${parameters.stopInDays === null ? "sem stop" : "stop em 7 dias"}`;

  if (strategy === "maxmin")
    return `Entrada na mínima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || "{Janela}"} candle${
      parameters.window === 1 ? "" : "s"
    }, alvo na máxima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || "{Janela}"} dia${
      parameters.window === 1 ? "" : "s"
    }, ${parameters.stopInDays === null ? "sem stop" : "stop em 7 dias"}.`;

  if (strategy === "short_maxmin")
    return `Entrada na máxima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || "{Janela}"} candle{
      parameters.window === 1 ? "" : "s"
    }, alvo na mínima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || "{Janela}"} candle${
      parameters.window === 1 ? "" : "s"
    }, ${parameters.stopInDays === null ? "sem stop" : "stop em 7 dias"}.`;

  const timeframe = !parameters.timeframeId
    ? "{Timeframe}"
    : parameters.timeframeId.value === "M15"
    ? "15 minutos"
    : parameters.timeframeId.value === "M30"
    ? "30 minutos"
    : parameters.timeframeId.value === "H1"
    ? "60 minutos"
    : parameters.timeframeId.value;

  const band = !parameters.targetBand
    ? "{Banda Alvo}"
    : parameters.targetBand.value === "middle"
    ? "banda do meio"
    : "banda oposta";

  if (strategy === "long_gaptrap")
    return `Entrada na superação da máxima dos primeiros ${timeframe} caso abra abaixo da mínima do dia anterior e feche positivo, alvo no fechamento, sem stop.`;

  if (strategy === "short_gaptrap")
    return `Entrada na perda da mínima dos primeiros ${timeframe} caso abra acima da máxima do dia anterior e feche negativo, alvo no fechamento, sem stop.`;

  if (strategy === "long_sdc")
    return `Entrada na superação da máxima dos primeiros ${timeframe} caso abra abaixo das Bandas de Bollinger, alvo na ${band}, sem stop.`;

  if (strategy === "short_sdc")
    return `Entrada na perda da mínima dos primeiros ${timeframe} caso abra acima das Bandas de Bollinger, alvo na ${band}, sem stop.`;

  if (strategy === "long_123")
    return `Padrão com 3 candles, onde o 2º tem a menor mínima. Entrada na ruptura da máxima do 3º candle.`;

  if (strategy === "long_wick_candle")
    return `Entrada caso abertura seja um gap de alta ≥ ${
      parameters.gapPct
    }%, o corpo do candle anterior seja ≤ ${
      parameters.bodyPct
    }% de sua amplitude, alvo no fechamento, ${
      !parameters.stopInPct
        ? "sem stop"
        : `stop ${parameters.stopInPct}% abaixo da entrada`
    }.`;

  if (strategy === "short_wick_candle")
    return `Entrada caso abertura seja um gap de baixa ≥ ${
      parameters.gapPct
    }%, o corpo do candle anterior seja ≤ ${
      parameters.bodyPct
    }% de sua amplitude, alvo no fechamento, ${
      !parameters.stopInPct
        ? "sem stop"
        : `stop ${parameters.stopInPct}% acima da entrada`
    }.`;
  if (strategy === "long_turnaround")
    return `Após ${parameters.negativeSequence} candle${
      parameters.negativeSequence > 1 ? "s" : ""
    } de queda, entrada no fechamento caso a última seja uma queda ≥ ${
      parameters.exhaustionPct
    }%, alvo no fechamento ${parameters.window} candle${
      parameters.window > 1 ? "s" : ""
    } depois, sem stop.`;

  if (strategy === "long_trap")
    return `Entrada no rompimento da máxima do candle que teve a mínima menor que a média móvel ${
      parameters.rollingAvgType?.value === "ewm"
        ? "exponencial"
        : parameters.rollingAvgType?.value === "simple"
        ? "aritmética"
        : `{Tipo de Média}`
    } de ${
      parameters.rollingAvgWindow
    } períodos, stop na mínima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "long_pfr")
    return `Entrada no rompimento da máxima do candle que teve a menor mínima da janela de ${
      parameters.window || `{Janela}`
    } dias, stop na mínima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "short_pfr")
    return `Entrada no rompimento da mínima do candle que teve a maior máxima da janela de ${
      parameters.window || `{Janela}`
    } dias, stop na máxima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "long_candle_trap")
    return `Entrada no rompimento da máxima do candle que teve a mínima menor que o anterior, stop na mínima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "short_candle_trap")
    return `Entrada na perda da mínima do candle que teve a máxima maior que o anterior, stop na máxima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "short_pfr")
    return `Entrada no rompimento da mínima do candle que teve a máxima maior que o anteior, stop na máxima do candle sinal, alvo ${
      parameters.riskFactor || `{Fator de Risco}`
    }x o risco.`;

  if (strategy === "long_shark" || strategy === "short_shark")
    return `Padrão com 3 candles, onde o 2º e o 3º são um inside bar.`;

  if (strategy === "long_insidebar" || strategy === "short_insidebar")
    return `Padrão com 2 candles, onde o 2º está contido no 1º.`;

  if (strategy === "long_rsi")
    return `Entrada com IFR de ${
      parameters.rsiWindow || "{Período IFR}"
    } períodos abaixo de ${
      parameters.entryRsi || "{IFR Máximo}"
    }, alvo na máxima do${parameters.window === 1 ? "" : "s"} último${
      parameters.window === 1 ? "" : "s"
    } ${parameters.window || `{Janela}`} dia${
      parameters.window === 1 ? "" : "s"
    } ou com IFR acima de ${parameters.exitRsi || "{IFR Saída}"}, ${
      parameters.stopInDays === null
        ? "sem stop no tempo"
        : `stop em ${parameters.stopInDays} candles`
    } e ${
      parameters.stopInPct > 0
        ? `stop ${parameters.stopInPct}% abaixo da entrada`
        : "sem stop percentual"
    }.`;

  if (strategy === "long_breakout")
    return `Entrada no rompimento da máxima do candle anterior, stop na retração de ${
      parameters.fiboRetracement
        ? toPercent(parameters.fiboRetracement / 100, 1)
        : `{Porcentagem}`
    }, alvo ${
      parameters.target?.value === "percentage"
        ? `${
            toPercent(parameters.targetInPct / 100, 1) || `{Porcentagem}`
          } a partir da entrada`
        : parameters.target?.value === "fibo"
        ? `na expansão de ${
            toPercent(parameters.fiboExtension / 100, 1) ||
            `{Expansão de Fibonacci}`
          } a partir da entrada`
        : parameters.target?.value === "risk"
        ? `${parameters.riskFactor || `{Fator de Risco}`}x o risco`
        : "a definir"
    }.`;

  if (strategy === "short_breakout")
    return `Entrada na perda da mínima do candle anterior, stop na retração de ${toPercent(
      parameters.fiboRetracement
        ? parameters.fiboRetracement / 100
        : `{Porcentagem}`,
      1
    )}, alvo ${
      parameters.target?.value === "percentage"
        ? `${
            toPercent(parameters.targetInPct / 100, 1) || `{Porcentagem}`
          } a partir da entrada`
        : parameters.target?.value === "fibo"
        ? `na expansão de ${
            toPercent(parameters.fiboExtension / 100, 1) ||
            `{Expansão de Fibonacci}`
          } a partir da entrada`
        : parameters.target?.value === "risk"
        ? `${parameters.riskFactor || `{Fator de Risco}`}x o risco`
        : "a definir"
    }.`;

  if (strategy === "long_open_bb")
    return `Entrada com fechamento acima das Bandas de Bollinger, alvo no fechamento de ${
      parameters.window || `{Janela}`
    } candle${parameters.window === 1 ? "" : "s"} depois e ${
      parameters.stopInPct > 0
        ? `stop ${parameters.stopInPct}% abaixo da entrada`
        : "sem stop percentual"
    }.`;

  if (strategy === "short_open_bb")
    return `Entrada com fechamento abaixo das Bandas de Bollinger, alvo no fechamento de ${
      parameters.window || `{Janela}`
    } candle${parameters.window === 1 ? "" : "s"} depois e ${
      parameters.stopInPct > 0
        ? `stop ${parameters.stopInPct}% acima da entrada`
        : "sem stop percentual"
    }.`;

  if (strategy === "long_reversal_bb")
    return `Entrada com fechamento abaixo da Banda de Bollinger inferior, alvo no fechamento de ${
      parameters.window || `{Janela}`
    } candle${parameters.window === 1 ? "" : "s"} depois e ${
      parameters.stopInPct > 0
        ? `stop ${parameters.stopInPct}% abaixo da entrada`
        : "sem stop percentual"
    }.`;

  if (strategy === "short_reversal_bb")
    return `Entrada com fechamento acima da Banda de Bollinger superior, alvo no fechamento de ${
      parameters.window || `{Janela}`
    } candle${parameters.window === 1 ? "" : "s"} depois e ${
      parameters.stopInPct > 0
        ? `stop ${parameters.stopInPct}% acima da entrada`
        : "sem stop percentual"
    }.`;

  return "Selecione uma estratégia para rodar seu backtest.";
};

export const mapTimeframeToMinutes = timeframe => {
  if (timeframe === "M5") return 5;
  if (timeframe === "M15") return 15;
  if (timeframe === "M30") return 30;
  if (timeframe === "H1") return 60;
  if (timeframe === "H2") return 120;
  if (timeframe === "H4") return 240;

  // Shouldn't happen
  return 0;
};

export const getMinCloseTimeLimit = (minOpenTime, timeframe) => {
  if (!minOpenTime)
    return DateTime.now().set({ hour: 9, minute: 0, second: 0 }).toJSDate();

  return DateTime.fromJSDate(minOpenTime)
    .plus({ minutes: mapTimeframeToMinutes(timeframe) })
    .toJSDate();
};

export const getMaxOpenTimeLimit = (closeTimeLimit, timeframe) => {
  if (!closeTimeLimit)
    return DateTime.now().set({ hour: 18, minute: 15, second: 0 }).toJSDate();

  return DateTime.fromJSDate(closeTimeLimit)
    .minus({ minutes: mapTimeframeToMinutes(timeframe) })
    .toJSDate();
};

export const mapStrategy = strategyId => {
  const strategy = STRATEGY_OPTIONS.find(({ value }) => value === strategyId);
  return strategy ? strategy.label : "Indefinida";
};

export const mapTimeframe = timeframeId => {
  const timeframe = TIMEFRAME_OPTIONS.find(
    ({ value }) => value === timeframeId
  );
  return timeframe ? timeframe.label : "Indefinido";
};
