import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { useMedia } from "react-use";
import RobotsReturnsTooltip from "./RobotsReturnsTooltip";
import { getMinMax } from "../historicalReturns/ReturnsChart";

const getData = (robots, ibov) => {
  const data = [];

  data.push({
    id: "Robôs",
    color: "#033660",
    data: robots.map(d => ({
      x: d.date,
      y: d.value,
      variation: d.variation,
      color: "#033660",
      name: "Robôs",
    })),
  });

  data.push({
    id: "IBOV",
    color: "#FF6633",
    data: ibov.map(d => ({
      x: d.date,
      y: d.value,
      variation: d.variation,
      color: "#FF6633",
      name: "Ibovespa",
    })),
  });

  return data.reverse();
};

const RobotReturnsChart = ({ robots, ibov }) => {
  const isMobile = useMedia("(max-width: 700px)");

  const data = useMemo(() => getData(robots, ibov), [robots, ibov]);

  const delta = 0.1;

  const [minValue, maxValue] = getMinMax(data, delta);

  return (
    <div
      style={{
        height: isMobile ? "320px" : "420px",
        backgroundColor: "#fff",
      }}>
      <ResponsiveLine
        data={data}
        margin={{
          top: 40,
          right: isMobile ? 10 : 20,
          bottom: 50,
          left: 40,
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        tooltip={input => <RobotsReturnsTooltip data={input.point.data} />}
        yScale={{
          type: "linear",
          min: minValue,
          max: maxValue,
        }}
        axisLeft={{
          tickPadding: 8,
        }}
        axisBottom={{
          format: "%b %y",
          tickRotation: isMobile ? 60 : 0,
        }}
        enableArea={true}
        areaBaselineValue={1}
        areaOpacity={0.07}
        enableSlices={false}
        colors={{ datum: "color" }}
        pointSize={0}
        useMesh={true}
        motionConfig="gentle"
        legends={[
          {
            anchor: isMobile ? "top-left" : "top-right",
            direction: "row",
            justify: false,
            translateX: -30,
            translateY: -30,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 60,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
          },
        ]}
      />
    </div>
  );
};

RobotReturnsChart.propTypes = {
  robots: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      variation: PropTypes.number.isRequired,
    })
  ).isRequired,
  ibov: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      variation: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default memo(RobotReturnsChart);
