import { graphql, Link, useStaticQuery } from "gatsby";
import InstagramLink from "../components/common/InstagramLink";
import YouTubeLink from "../components/common/YouTubeLink";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SEO from "../components/seo";
import * as css from "./blog.module.css";

const POSTS_ON_TOP = 3;

const IndexPage = () => {
  const {
    allJupyterNotebook: { nodes },
  } = useStaticQuery(graphql`
    {
      allJupyterNotebook(
        sort: { fields: { date: DESC } }
        filter: { fields: { published: { eq: true } } }
      ) {
        nodes {
          fields {
            slug
            title
            author
            date(formatString: "DD/MM/YYYY")
            spoiler
          }
        }
      }
    }
  `);

  const top = nodes.slice(0, POSTS_ON_TOP);
  const middle = nodes.slice(POSTS_ON_TOP, POSTS_ON_TOP + 3);
  const rest = nodes.slice(POSTS_ON_TOP + 3);

  const renderPost = node => (
    <Link key={node.fields.slug} to={`/${node.fields.slug}`}>
      <h2>{node.fields.title}</h2>
      <div className={css["spoiler"]}>{node.fields.spoiler}</div>
      <div className={css["author"]}>
        Por {node.fields.author} — {node.fields.date}
      </div>
    </Link>
  );

  return (
    <div>
      <SEO title="Blog" />
      <Navbar />
      <div className={css["main"]}>
        <h1>Blog</h1>
        <div className={css["posts"]}>
          {top.map(renderPost)}
          <div className={css["shareLink"]}>
            <YouTubeLink />
          </div>
          {middle.map(renderPost)}
          <div className={css["shareLink"]}>
            <InstagramLink />
          </div>
          {rest.map(renderPost)}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default IndexPage;
