import PropTypes from "prop-types";
import Select from "react-select";
import { getAssetLabel, useAssets } from "../../hooks/useAssets";
import { useIsClient } from "../../hooks/useIsClient";
import { PREMIUM_OPTION, SELECT_SMALL } from "../../utils/select";
import Input from "../common/Input";
import * as css from "./CointegrationFilter.module.css";
import InvertIcon from "../../icons/invert.svg";
import { useAuth } from "../../hooks/useAuth";
import InfoIcon from "../../icons/info-question-circle.svg";
import GroupLabel from "../common/GroupLabel";
import { Tooltip } from "react-tooltip";
import { renderToStaticMarkup } from "react-dom/server";
import { useCallback } from "react";
import Toggle from "react-toggle";
import StarIcon from "../../icons/star.svg";
import Countdown from "react-countdown";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const ALLOWED_ASSETS = ["PETR4", "PETR3", "ELET3", "ELET6", "ITUB3", "ITUB4"];

function shouldDisable(category, selectedAsset) {
  // If no asset is selected, we shouldn't disable anything
  if (!selectedAsset) return false;

  // Only assets that belong from the same market can be selected
  if (["Futuro"].includes(selectedAsset.type))
    return !["Futuros"].includes(category);
  if (["B3", "BDR", "ETF"].includes(selectedAsset.type))
    return !["Ações", "BDRs", "ETFs"].includes(category);
  if (["Crypto"].includes(selectedAsset.type))
    return !["Criptomoedas"].includes(category);
}

const getUpdatedOptions = (isBase, categories, baseAsset, comparisonAsset) => {
  if (isBase && !comparisonAsset) return categories;
  return categories.filter(
    category =>
      !shouldDisable(category.label, baseAsset) &&
      !shouldDisable(category.label, comparisonAsset)
  );
};

const CointegrationFilter = ({
  onClick,
  isLoading,
  getValue,
  setValue,
  resetFilter,
  isValid,
  expiration,
}) => {
  const { asOptions: assets, grouped } = useAssets({ requirePremium: true });
  const { user, isLoggedIn } = useAuth();

  const showAll = !!user?.isPremium;

  const baseAsset = getValue("baseAsset");
  const comparisonAsset = getValue("comparisonAsset");
  const runBatch = getValue("runBatch");

  const getOptions = useCallback(
    isBase => {
      let options = assets.map(asset => ({
        ...asset,
        isDisabled: !showAll && !ALLOWED_ASSETS.includes(asset.value),
      }));

      return showAll
        ? getUpdatedOptions(isBase, grouped, baseAsset, comparisonAsset)
        : options.sort(a => (a.isDisabled ? 1 : -1));
    },
    [assets, baseAsset, comparisonAsset, grouped, showAll]
  );

  const isClient = useIsClient();
  const key = isClient ? "client" : "server";

  const canSubmit = isLoggedIn && !isLoading && isValid;

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "RUN_COINTEGRATION_BATCH"
  );

  const renderToggle = ({ formatted, completed, api }) => {
    if (!completed && api.isStopped()) {
      api.start();
    }

    if (!completed) {
      return (
        <div>
          <label className={css["toggle"]}>
            <span>Testar Múltiplos</span>
            <Toggle
              checked={runBatch}
              onChange={e => setValue("runBatch", e.target.checked)}
              icons={false}
              disabled={true}
            />
          </label>
          <div className={css["hint"]}>
            Disponível novamente em{" "}
            <span>
              {formatted.minutes}:{formatted.seconds}
            </span>
          </div>
        </div>
      );
    }

    return (
      <Popover.Root open={showStep}>
        <div>
          <Popover.Trigger asChild>
            <label className={css["toggle"]}>
              <span>Testar Múltiplos</span>
              <Toggle
                checked={runBatch}
                onChange={e => {
                  if (showStep) markStepAsCompleted();
                  setValue("runBatch", e.target.checked);
                }}
                icons={false}
                disabled={!showAll}
              />
              {!showAll && (
                <StarIcon data-tooltip-id="batch-tip" className={css["star"]} />
              )}
            </label>
          </Popover.Trigger>
          {showStep && (
            <OnboardingStep
              step={step}
              onConfirm={markStepAsCompleted}
              onDismiss={markStepAsCompleted}
              side="right"
            />
          )}
          <div className={css["hint"]}>
            Testar {baseAsset ? getAssetLabel(baseAsset.value) : "o ativo base"}{" "}
            com todos os outros do mesmo mercado.
          </div>
          <Tooltip
            id="batch-tip"
            delayHide={100}
            clickable
            html={renderToStaticMarkup(
              <span>
                Disponível para Premiums.{" "}
                <a
                  href="/planos"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ fontWeight: 500 }}>
                  Assine agora.
                </a>
              </span>
            )}
          />
        </div>
      </Popover.Root>
    );
  };

  return (
    <div className={css["filter"]} key={key}>
      <Tooltip
        id="window-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre a janela de cointegração?{" "}
            <a
              href="/definindo-a-janela-de-tempo-no-long-and-short-por-cointegracao"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <div>
        <label>Ativo Base</label>
        <Select
          id="base-asset"
          options={getOptions(true)}
          value={baseAsset}
          onChange={option => setValue("baseAsset", option)}
          styles={{ ...SELECT_SMALL, ...(showAll ? {} : PREMIUM_OPTION) }}
          formatGroupLabel={data => <GroupLabel {...data} />}
          placeholder="Selecionar..."
          isClearable
        />
      </div>
      <Countdown
        date={expiration * 1000}
        renderer={renderToggle}
        zeroPadTime={2}
      />
      <div>
        <label>Ativo de Comparação</label>
        <Select
          id="comparison-asset"
          options={getOptions(false)}
          value={comparisonAsset}
          onChange={option => setValue("comparisonAsset", option)}
          styles={{ ...SELECT_SMALL, ...(showAll ? {} : PREMIUM_OPTION) }}
          formatGroupLabel={data => <GroupLabel {...data} />}
          placeholder="Selecionar..."
          isClearable
          isDisabled={runBatch || !baseAsset}
        />
        <div className={css["hint"]}>
          Apenas ativos do mesmo mercado podem ser comparados.
        </div>
      </div>
      <button
        className={css["invertButton"]}
        onClick={() => {
          setValue("comparisonAsset", baseAsset);
          setValue("baseAsset", comparisonAsset);
        }}>
        <InvertIcon />
        Inverter
      </button>
      <div>
        <label htmlFor="window-filter">
          Janela (candles){" "}
          <InfoIcon className={css["infoIcon"]} data-tooltip-id="window-tip" />
        </label>
        <Input
          id="window-filter"
          value={getValue("window")}
          onValueChange={({ floatValue }) =>
            setValue("window", floatValue === undefined ? "" : floatValue)
          }
          fluid
          size="small"
          isNumber
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          decimalScale={0}
          placeholder="Ex: 250"
          hint={"Período máximo de 250 dias"}
          isAllowed={({ floatValue }) =>
            floatValue === undefined || floatValue <= 250
          }
          disabled={!user?.isPremium}
        />
      </div>
      <div className={css["buttonsContainer"]}>
        <button
          disabled={!canSubmit}
          className={css["button"]}
          onClick={onClick}>
          Testar Cointegração
        </button>
        <button className={css["reset"]} onClick={resetFilter}>
          Resetar
        </button>
      </div>
    </div>
  );
};

CointegrationFilter.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  expiration: PropTypes.number.isRequired,
};

export default CointegrationFilter;
