import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import { useConfig } from "../../hooks/useConfig";
import { showError } from "../../utils/utils";
import { Link, navigate } from "gatsby";
import LandingPageLayout from "../layouts/LandingPageLayout";
import * as css from "./PlansContainer.module.css";
import SEO from "../seo";
import { useState } from "react";
import Toggle from "react-toggle";
import LogoIcon from "../../icons/logo-horizontal-white.svg";
import scrollToElement from "scroll-to-element";
import PropTypes from "prop-types";
import PremiumFAQ from "./PremiumFAQ";
import { useEffect } from "react";
import WhatsAppFloatingButton from "./WhatsAppFloatingButton";
import WhatsAppLink from "./WhatsAppLink";
import FreeBenefits from "./FreeBenefits";
import PremiumBenefits from "./PremiumBenefits";
import PlansPopup from "./PlansPopup";
import { usePremiumPopup } from "../../hooks/usePremiumPopup";
import {
  incrementProperty,
  logAmplitudeEvent,
  trackCheckoutSessionStarted,
  EVENT_MAP,
} from "../../utils/amplitude";

export const PRICES = { monthly: 85.9, yearly: 718.8 };

export const DISCOUNT = Math.round(
  100 * (1 - PRICES.yearly / (PRICES.monthly * 12))
);

const PlansContainer = ({ location }) => {
  const [isYearly, setIsYearly] = useState(true);

  const {
    plans,
    TRIAL_DAYS,
    IS_BLACKFRIDAY,
    IS_PROMO,
    YEARLY_COUPON,
    MONTHLY_COUPON,
    COUPON_CODE,
    PROMOTION_CODE_ID,
  } = useConfig();

  const YEARLY_DISCOUNT = Math.round(
    100 * (1 - (1 - DISCOUNT / 100) * (1 - YEARLY_COUPON / 100))
  );

  const MONTHLY_DISCOUNT = Math.round(100 * (1 - (1 - MONTHLY_COUPON / 100)));

  const hasTrial = TRIAL_DAYS > 0;

  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn } = useAuth();

  const [showModal, setShowModal] = usePremiumPopup(false);

  const onPlanSelected = productId => {
    logAmplitudeEvent(EVENT_MAP.PLAN_SELECTED, {
      productId,
      isLoggedIn,
    });
    if (isLoggedIn) mutation.mutate(productId);
    else {
      navigate("/cadastro", {
        state: { selectedProductId: productId },
      });
    }
  };

  const mutation = useMutation(
    priceId => {
      const params = new URLSearchParams();
      params.append("price_id", priceId);
      if (PROMOTION_CODE_ID) params.append("promotion_code", PROMOTION_CODE_ID);
      return callAPI(`/api/payment/checkout?${params.toString()}`);
    },
    {
      onSuccess: data => {
        trackCheckoutSessionStarted({ trialDays: TRIAL_DAYS });
        window.location.href = data.url;
      },
      onError: showError,
    }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.PLANS_VIEWED);
    incrementProperty("plansPageViewed");
  }, []);

  const title = "Planos";

  const theme = IS_BLACKFRIDAY ? "#100826" : "#033660";

  return (
    <LandingPageLayout footer={false} location={location} theme={theme}>
      <SEO
        title={title}
        description="Assine o QuantBrasil Premium! Desbloqueie todas as funcionalidades da plataforma, incluindo backtests, alertas no WhatsApp, Long & Short por cointegração, ferramentas de controle de risco, e muito mais."
      />
      <div className={css["content"]} id="plans-top">
        <div
          className={css["main"]}
          style={{
            background: theme,
          }}>
          <Link className={css["logo"]} to="/">
            <LogoIcon />
          </Link>
          <div className={css["header"]}>
            <div>
              <h1>
                {IS_BLACKFRIDAY
                  ? "Black Friday QuantBrasil"
                  : IS_PROMO
                  ? "🎂 Aniversário QuantBrasil"
                  : "Planos"}
              </h1>
              <p>
                {IS_BLACKFRIDAY
                  ? "A maior promoção do ano. Assinatura anual com mais de 50% de desconto!"
                  : IS_PROMO
                  ? `Aproveite o aniversário do QuantBrasil para assinar o plano Premium com ${YEARLY_DISCOUNT}% de desconto!`
                  : "Use a estatística ao seu favor. Seja assinante do QuantBrasil."}
              </p>
            </div>
          </div>
          <div>
            <label className={css["toggle"]}>
              <span>Mensal</span>
              <Toggle
                defaultChecked={isYearly}
                onChange={e => setIsYearly(e.target.checked)}
                icons={false}
              />
              <span>
                Anual — <span>{YEARLY_DISCOUNT}% off</span>
              </span>
            </label>
          </div>
          <div className={css["plans"]}>
            <div className={css["plan"]}>
              <h2>Básico</h2>
              <div className={css["price"]}>
                R$ 0 <span>/ mês</span>
              </div>
              <div className={css["cta"]}>
                {!isLoggedIn ? (
                  <Link
                    onClick={() =>
                      logAmplitudeEvent(EVENT_MAP.SIGNUP_CLICKED, {
                        page: window.location.pathname,
                        ctaText: "Criar Conta de Graça",
                      })
                    }
                    to="/cadastro"
                    className={css["buttonOutline"]}>
                    Criar Conta de Graça
                  </Link>
                ) : (
                  <div className={css["alreadyMember"]}>
                    Você já está no plano Básico!{" "}
                    <b>
                      Assine o plano Premium para aproveitar o QuantBrasil ao
                      máximo.
                    </b>
                  </div>
                )}
              </div>
              <FreeBenefits />
            </div>
            <div className={css["plan"]}>
              <h2>Premium</h2>
              <div className={css["price"]}>
                R${" "}
                {(isYearly
                  ? (PRICES.yearly / 12) * ((100 - YEARLY_COUPON) / 100)
                  : PRICES.monthly * ((100 - MONTHLY_COUPON) / 100)
                ).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                <span>/ mês</span>
              </div>
              {isYearly && (
                <div className={css["savings"]}>
                  <span>
                    R${" "}
                    {PRICES.monthly.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>{" "}
                  <span>{YEARLY_DISCOUNT}% off</span>
                </div>
              )}
              {!isYearly && MONTHLY_DISCOUNT > 0 && (
                <div className={css["savings"]}>
                  <span>
                    R${" "}
                    {PRICES.monthly.toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>{" "}
                  <span>{MONTHLY_DISCOUNT}% off</span>
                </div>
              )}
              {COUPON_CODE && (
                <div className={css["coupon"]}>
                  Cupom: <span>{COUPON_CODE}</span>
                </div>
              )}
              <div className={css["cta"]}>
                <button
                  disabled={mutation.isLoading}
                  className={css["button"]}
                  onClick={e => {
                    e.preventDefault();
                    onPlanSelected(
                      plans.premium[isYearly ? "yearly" : "monthly"]
                    );
                  }}>
                  {hasTrial
                    ? `Testar ${TRIAL_DAYS} Dias Grátis${
                        isYearly ? ` (${YEARLY_DISCOUNT}% off)` : ""
                      }`
                    : `Assinar Plano ${isYearly ? "Anual" : "Mensal"}`}
                </button>
                {isYearly && (
                  <div className={css["period"]}>
                    {hasTrial
                      ? "Após o período de testes, será feita uma cobrança de"
                      : "Será feita uma cobrança de"}{" "}
                    <span>
                      R${" "}
                      {(
                        PRICES.yearly *
                        (1 - YEARLY_COUPON / 100)
                      ).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                )}
              </div>
              <PremiumBenefits />
            </div>
          </div>
          <div className={css["anchors"]}>
            <button
              onClick={() => scrollToElement("#plans-faq", { offset: -150 })}>
              Perguntas Frequentes
            </button>
          </div>
        </div>
        <div className={css["waContainer"]}>
          <WhatsAppLink />
        </div>
        <div className={css["rest"]}>
          <h2 id="plans-faq">Perguntas Frequentes</h2>
          <PremiumFAQ />
          <button
            className={css["backTop"]}
            onClick={() => scrollToElement("#plans-top")}>
            Voltar ao Topo ↑
          </button>
        </div>
      </div>
      <WhatsAppFloatingButton />
      <PlansPopup
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onClick={() => onPlanSelected(plans.premium["yearly"])}
        discount={YEARLY_DISCOUNT}
      />
    </LandingPageLayout>
  );
};

PlansContainer.propTypes = {
  location: PropTypes.object.isRequired,
};

export default PlansContainer;
