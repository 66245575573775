import YouTube from "react-youtube";
import PropTypes from "prop-types";
import * as css from "./YouTubePlayer.module.css";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";

const YouTubePlayer = props => {
  const { width, height, videoId, style, className, ...rest } = props;

  return (
    <YouTube
      videoId={videoId}
      style={style}
      iframeClassName={className ? className : css["iframeClassName"]}
      opts={{ width, height }}
      onPlay={() => {
        logAmplitudeEvent(EVENT_MAP.YOUTUBE_VIDEO_PLAYED, {
          videoId,
          page: window.location.pathname,
        });
        incrementProperty("youtubeVideoPlayed");
      }}
      {...rest}
    />
  );
};

YouTubePlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  style: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default YouTubePlayer;
