import PropTypes from "prop-types";
import * as css from "./FeedItem.module.css";
import { DateTime } from "luxon";
import StarIcon from "../../icons/star.svg";
import PerformanceIcon from "../../icons/performance.svg";
import RobotIcon from "../../icons/robot-head.svg";
import VolatilityIcon from "../../icons/volatility.svg";
import AnalyticsIcon from "../../icons/analytics.svg";
import VerifiedIcon from "../../icons/verified.svg";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "gatsby";
import { getAssetLabel } from "../../hooks/useAssets";

const mapFeedItemTypeIdToText = feedItemTypeId => {
  switch (feedItemTypeId) {
    case "backtest_suggestion":
      return "Sugestão de Backtest";
    case "start_automated_trade":
      return "Trade Iniciado";
    case "target_updated":
      return "Alvo Atualizado";
    case "end_automated_trade":
      return "Trade Finalizado";
    case "automated_trade_report":
      return "Relatório de Trades";
    case "cointegration_suggestion":
      return "Sugestão de Cointegração";
    case "spikes_and_drops":
      return "Variação Atípica";
    case "market_comments":
      return "Comentário de Mercado";
    case "short_period_variation":
      return "Variação de Curto Prazo";
    case "sector_breakdown":
      return "Desempenho Setorial";
    case "crossing_mm200":
      return "Cruzamento Média Móvel";
    case "ath":
      return "Topo Histórico";
    default:
      return "Feed Item";
  }
};

const FEED_CATEGORY = {
  automated_trade: {
    name: "Robôs",
    bgColor: "#e9e6f4",
    fgColor: "#150f51",
    className: css["automatedTrade"],
    icon: <RobotIcon />,
  },
  backtest: {
    name: "Backtests",
    bgColor: "#e6f4ea",
    fgColor: "#0f5132",
    className: css["backtest"],
    icon: <PerformanceIcon />,
  },
  cointegration: {
    name: "Cointegrações",
    bgColor: "#f4eae6",
    fgColor: "#51320f",
    className: css["cointegration"],
    icon: <VolatilityIcon className={css["volatility"]} />,
  },
  analytics: {
    name: "Análises",
    bgColor: "#BFFFF0",
    fgColor: "#333333",
    className: css["analytics"],
    icon: <AnalyticsIcon className={css["analyticsIcon"]} />,
  },
  social: {
    name: "Social",
  },
};

const FeedItem = ({ data, onClick, canClick }) => {
  const { user } = useAuth();
  const isPremium = !!user.isPremium;

  let categoryProps = FEED_CATEGORY[data.category_id];
  if (!categoryProps) {
    categoryProps = {
      name: data.category_id,
      bgColor: "#ccc",
      fgColor: "#000",
      className: undefined,
      icon: undefined,
    };
  }

  const isHiddenContent = !data.content && !isPremium;

  const renderTags = () => (
    <div className={css["tags"]}>
      {data.is_premium && (
        <div className={css["premium"]}>
          <StarIcon />
        </div>
      )}
      <div className={categoryProps?.className}>{categoryProps.name}</div>
      {data.symbols?.map(symbol => (
        <div key={symbol}>{getAssetLabel(symbol)}</div>
      ))}
    </div>
  );

  return (
    <div
      onClick={onClick}
      className={css["item"]}
      title={canClick ? "Clique para visualizar" : undefined}
      style={{
        cursor: canClick ? "pointer" : "default",
      }}>
      <div className={css["header"]}>
        {data.parameters?.avatar ? (
          <img
            className={css["avatar"]}
            src={data.parameters.avatar}
            alt="avatar"
          />
        ) : (
          <div
            className={css["iconContainer"]}
            style={{ backgroundColor: categoryProps?.fgColor || undefined }}>
            {categoryProps.icon}
          </div>
        )}
        <div>
          <div className={css["feedTitle"]}>
            {mapFeedItemTypeIdToText(data.feed_item_type_id)}
            <span
              className={css["time"]}
              data-tooltip-content={`Criado em ${DateTime.fromISO(
                data.created_at
              )
                .setLocale("pt-BR")
                .toLocaleString(DateTime.DATETIME_MED)}`}
              data-tooltip-id="feed-tooltip">
              {DateTime.fromISO(data.created_at).toRelative({
                locale: "pt-br",
              })}
            </span>
          </div>
          {data.parameters?.author && (
            <div className={css["author"]}>
              {data.parameters.author} <VerifiedIcon />
            </div>
          )}
          {renderTags()}
        </div>
      </div>
      <div className={css["tagsMobile"]}>{renderTags()}</div>
      {isHiddenContent ? (
        <div className={css["hiddenContent"]}>
          <span>Conteúdo disponível apenas para assinantes.</span>
          <br />
          <Link to="/planos">Ver Planos</Link>.
        </div>
      ) : (
        <div
          className={css["content"]}
          dangerouslySetInnerHTML={{ __html: data.content?.trim() }}></div>
      )}
    </div>
  );
};

FeedItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  canClick: PropTypes.bool.isRequired,
};

export default FeedItem;
