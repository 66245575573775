import "react-accessible-accordion/dist/fancy-example.css";
import { intersection, uniq } from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ReactMarkdown from "react-markdown";
import Select from "react-select";
import Modal from "../common/Modal";
import * as css from "./Alerts.module.css";
import { useAuth } from "../../hooks/useAuth";
import UpgradeButton from "../common/UpgradeButton";
import {
  mapTimeframeToMinutes,
  parseParameters,
  mapStrategy,
} from "../../utils/utils";
import { WhatsappIcon } from "react-share";
import { isEmpty } from "lodash";
import { renderToStaticMarkup } from "react-dom/server";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../icons/info-question-circle.svg";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";
import { getAssetLabel } from "../../hooks/useAssets";

export const FILTER_OPTIONS = [
  { value: "ALL", label: "Todos" },
  {
    value: "WHATSAPP",
    label: "WhatsApp",
    color: "#25d366",
    isPremium: false,
  },
  { value: "MISSING_HOME_M30", label: "Saudade de Casa", color: "#fc7375" },
  { value: "GAPTRAP_M15", label: "Gap Trap", color: "#4fa3f3" },
  { value: "STOCHASTIC_D1_W1", label: "Estocástico Lento", color: "#b096f8" },
  { value: "CLASSIC_IFR2", label: "IFR2", color: "#ff711f" },
  {
    value: "SNIPER_IFR2",
    label: "IFR2 Sniper",
    color: "#dbd753",
    isPremium: true,
  },
  { value: "LONG_123_D1", label: "123 (Diário)", color: "#aacc1f" },
  {
    value: "LONG_123_H2",
    label: "123 (120 Minutos)",
    color: "#aa711f",
    isPremium: true,
  },
  { value: "LONG_PFR_D1", label: "PFR (Diário)", color: "#eecc1f" },
  {
    value: "LONG_PFR_H2",
    label: "PFR (120 Minutos)",
    color: "#f17aff",
    isPremium: true,
  },
  { value: "LONG_TRAP_D1", label: "Trap (Diário)", color: "#123456" },
  {
    value: "LONG_TRAP_H2",
    label: "Trap (120 Minutos)",
    color: "#654321",
    isPremium: true,
  },
];

const getStyles = () => ({
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: provided => ({
    ...provided,
    backgroundColor: "#49ce8b",
  }),
  multiValueLabel: provided => ({
    ...provided,
    color: "#fff",
    fontWeight: 500,
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: "#fff",
    ":hover": {
      backgroundColor: "#328a5e",
      cursor: "pointer",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      display: "flex",
      color: state.isSelected
        ? "hsl(0, 0%, 100%)"
        : state.isDisabled
        ? "hsl(0, 0%, 80%)"
        : "rgb(51, 51, 51)",
      alignItems: "center",
      ":before": {
        backgroundColor: state.data.color,
        borderRadius: 10,
        content: '" "',
        display: "block",
        marginRight: 8,
        height: 10,
        width: 10,
      },
      ...(state.isDisabled
        ? {
            display: "flex",
            alignItems: "center",
            ":after": {
              backgroundColor: "#49ce8b",
              color: "#fff",
              borderRadius: 10,
              content: "'Premium'",
              display: "block",
              marginLeft: 8,
              fontSize: 10,
              padding: 4,
              fontWeight: 500,
            },
          }
        : {}),
    };
  },
});

const Alerts = ({ alerts, filter, setFilter, assets, setAssets }) => {
  const [selectedAlert, setSelectedAlert] = useState(null);

  const {
    user: { isPremium },
  } = useAuth();

  const PROTECTED_FILTER_OPTIONS = FILTER_OPTIONS.map(option => {
    if (!isPremium && option.isPremium) return { ...option, isDisabled: true };
    return option;
  });

  const allAssets = useMemo(
    () =>
      uniq(
        [].concat.apply(
          [],
          alerts.map(alert => alert.assets)
        )
      ).map(value => ({ value, label: value })),
    [alerts]
  );

  const selectedAssets = useMemo(() => assets.map(asset => asset.label), [
    assets,
  ]);

  const visibleAlerts = useMemo(
    () =>
      alerts
        .filter(alert => filter.value === "ALL" || alert.type === filter.value)
        .filter(
          alert =>
            assets.length === 0 ||
            intersection(selectedAssets, alert.assets).length > 0
        ),
    [alerts, assets, filter, selectedAssets]
  );

  const isOpen = selectedAlert !== null;
  const onClose = () => setSelectedAlert(null);

  const todayAlerts = useMemo(
    () =>
      visibleAlerts.filter(({ created_at }) =>
        DateTime.fromJSDate(new Date(created_at)).hasSame(
          DateTime.local(),
          "day"
        )
      ),
    [visibleAlerts]
  );

  const otherDaysAlerts = useMemo(
    () =>
      visibleAlerts.filter(
        ({ created_at }) =>
          !DateTime.fromJSDate(new Date(created_at)).hasSame(
            DateTime.local(),
            "day"
          )
      ),
    [visibleAlerts]
  );

  const renderAlert = alert => {
    const alertOption = FILTER_OPTIONS.find(
      ({ value }) => value === alert.type
    );
    const color = alertOption?.color || "#000";
    const label = alertOption?.label || alert.type;
    const isBlocked = alertOption ? alertOption.isPremium && !isPremium : false;

    const isWhatsApp = Boolean(alert.type === "WHATSAPP");

    const parameters = isWhatsApp ? parseParameters(alert.parameters) : [];
    const hasParameters = parameters !== "Nenhum" && !isEmpty(parameters);

    const mappedTimeframe = mapTimeframeToMinutes(alert.timeframe_id);
    const textualTimeframe =
      mappedTimeframe === 0 ? "Diário" : ` ${mappedTimeframe} Minutos`;

    return (
      <div
        key={`${alert.created_at}-${alert.type}-${alert.setup_id}`}
        className={css["alert"]}
        onClick={() => {
          if (!isBlocked) {
            logAmplitudeEvent(EVENT_MAP.ALERT_CLICKED, {
              type: alert.type,
            });
            incrementProperty("alertsExpanded");
            setSelectedAlert(alert.body);
          }
        }}
        style={{
          borderTopColor: color,
        }}>
        <div
          className={css["alertType"]}
          style={{
            filter: `blur(${isBlocked ? 3 : 0}px)`,
          }}>
          <div className={css["alertName"]}>
            {isWhatsApp ? (
              <WhatsappIcon round={true} className={css["wpIcon"]} />
            ) : (
              <div
                className={css["circle"]}
                style={{ backgroundColor: color }}
              />
            )}
            <div>
              <div>
                {isWhatsApp
                  ? `${mapStrategy(alert.strategy_id)} (${textualTimeframe})`
                  : label}
                {hasParameters && (
                  <span className={css["infoContainer"]}>
                    <InfoIcon
                      data-tooltip-id={`alert-${alert.created_at}-${alert.setup_id}`}
                      className={css["infoIcon"]}
                    />
                    <Tooltip
                      id={`alert-${alert.created_at}-${alert.setup_id}`}
                      delayHide={100}
                      html={renderToStaticMarkup(
                        <span>
                          {parameters.map((p, i) => (
                            <div className={css["tooltip"]} key={i}>
                              {p.split(":").map((chunk, j) => (
                                <span key={j}>
                                  {chunk}
                                  {j === 0 ? ":" : ""}
                                </span>
                              ))}
                            </div>
                          ))}
                        </span>
                      )}
                      clickable
                    />
                  </span>
                )}
              </div>

              <div className={css["time"]}>
                {DateTime.fromJSDate(new Date(alert.created_at))
                  .setLocale("pt-BR")
                  .toFormat("dd/LL 'às' HH:mm")}
              </div>
            </div>
          </div>
        </div>
        <div className={css["assets"]}>
          {alert.assets.map(asset => (
            <div
              className={css["asset"]}
              key={asset}
              style={
                selectedAssets.includes(asset)
                  ? { color: "#fff", background: "#49ce8b" }
                  : undefined
              }>
              {getAssetLabel(asset)}
            </div>
          ))}
          {isBlocked && (
            <div className={css["upgradeContainer"]}>
              <UpgradeButton
                buttonText={`Desbloquear ${label}`}
                icon="unlock"
                eventProps={{
                  type: alert.type,
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const getContent = () => {
    if (visibleAlerts.length === 0)
      return (
        <div>Não houve nenhum alerta enviado para o filtro selecionado.</div>
      );

    return (
      <Accordion
        allowMultipleExpanded={true}
        allowZeroExpanded={true}
        preExpanded={["today"]}
        className={css["accordion"]}>
        <AccordionItem uuid="today" className={css["accordionItem"]}>
          <AccordionItemHeading className={css["heading"]}>
            <AccordionItemButton>Hoje</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={css["panel"]}>
            {todayAlerts.length === 0 ? (
              <div className={css["emptyAlerts"]}>
                Sem alertas até o momento.
              </div>
            ) : (
              todayAlerts.map(renderAlert)
            )}
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem className={css["accordionItem"]} uuid="others">
          <AccordionItemHeading className={css["heading"]}>
            <AccordionItemButton>Últimos 15 dias</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={css["panel"]}>
            {otherDaysAlerts.map(renderAlert)}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <div>
      <div className={css["filters"]}>
        <div>
          <label htmlFor="alert-type">Alerta</label>
          <Select
            id="alert-type"
            options={PROTECTED_FILTER_OPTIONS}
            value={filter}
            onChange={option => setFilter(option)}
            styles={getStyles()}
          />
        </div>
        <div>
          <label htmlFor="assets-select">Ativos</label>
          <Select
            id="assets-select"
            options={allAssets}
            value={assets}
            onChange={option => setAssets(option)}
            styles={getStyles()}
            isMulti
            placeholder="Selecione os ativos"
          />
        </div>
      </div>
      {getContent()}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ReactMarkdown>{selectedAlert}</ReactMarkdown>
      </Modal>
    </div>
  );
};

Alerts.propTypes = {
  alerts: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired,
  setAssets: PropTypes.func.isRequired,
};

export default Alerts;
