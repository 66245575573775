import PropTypes from "prop-types";
import { STRATEGY_OPTIONS, TIMEFRAME_OPTIONS } from "../../utils/constants";
import { DateTime } from "luxon";
import * as css from "./SavedBacktests.module.css";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty } from "lodash";
import { useAPI } from "../../hooks/useAPI";
import { useQuery } from "react-query";
import { showError } from "../../utils/utils";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import BacktestResult, { getColor } from "../backtests/BacktestResult";
import Spinner from "../common/Spinner";
import { toPercent } from "../../utils/utils";
import HeartIcon from "../../icons/heart.svg";
import { getAssetLabel } from "../../hooks/useAssets";

const MAX_BACKTESTS = 10;

const SavedBacktests = ({
  data,
  setSelectedId,
  selectedId,
  isFavorite = false,
}) => {
  const {
    user: { isPremium },
  } = useAuth();

  const callAPI = useAPI({ withCredentials: true });

  const backtestResponse = useQuery(
    ["backtest", selectedId],
    () => callAPI(`/api/backtest/${selectedId}`),
    {
      onError: showError,
      enabled: selectedId !== null,
      onSuccess: data => {
        logAmplitudeEvent(EVENT_MAP.BACKTEST_RETRIEVED, {
          id: data.id,
          type: isFavorite ? "favoritos" : "historico",
        });
      },
    }
  );

  const getFavoriteWarnings = () => (
    <>
      {!isPremium && data.length < MAX_BACKTESTS && (
        <div className={css["info"]}>
          Você pode salvar mais{" "}
          <span>
            {MAX_BACKTESTS - data.length} backtest
            {MAX_BACKTESTS - data.length === 1 ? "" : "s"}
          </span>
          .
        </div>
      )}
      {!isPremium && data.length >= MAX_BACKTESTS && (
        <div className={css["warning"]}>
          Você pode salvar até <span>{MAX_BACKTESTS}</span> backtests. Se
          necessário, remova-os de sua lista para abrir espaço.
        </div>
      )}
    </>
  );

  const getHistoryWarnings = () => (
    <>
      {!isPremium && data.length >= MAX_BACKTESTS && (
        <div className={css["warning"]}>
          Você pode visualizar apenas os últimos <span>{MAX_BACKTESTS}</span>{" "}
          backtests. Seja <span>Premium</span> para ter acesso a todo o
          histórico.
        </div>
      )}
    </>
  );

  return (
    <div className={css["container"]}>
      {isFavorite ? getFavoriteWarnings() : getHistoryWarnings()}
      {data.map(d => (
        <div key={d.id}>
          <button
            className={css["backtest"]}
            onClick={() => {
              setSelectedId(d.id === selectedId ? null : d.id);
            }}
            style={{
              color: selectedId === d.id ? "#49ce8b" : undefined,
              fontWeight: selectedId === d.id ? "500" : undefined,
              borderLeftColor: selectedId === d.id ? "#49ce8b" : undefined,
            }}>
            <div className={css["row"]}>
              <span>
                {getAssetLabel(d.symbol)}
                {d.is_favorite && <HeartIcon />}
              </span>
              <span className={css["score"]}>
                Score: <span>{d.score}</span>
              </span>
            </div>
            <div className={css["details"]}>
              <div className={css["resultRow"]}>
                {
                  STRATEGY_OPTIONS.find(({ value }) => value === d.strategy_id)
                    .label
                }{" "}
                no{" "}
                {
                  TIMEFRAME_OPTIONS.find(
                    ({ value }) => value === d.timeframe_id
                  ).label
                }
                <span
                  style={getColor(parseFloat(d.pct_profit))}
                  className={css["result"]}>
                  {parseFloat(d.pct_profit) > 0 ? "+" : ""}
                  {toPercent(d.pct_profit, 2)}
                </span>
              </div>
              <div className={css["resultRow"]}>
                De{" "}
                {DateTime.fromJSDate(new Date(d.start_date))
                  .setLocale("pt-BR")
                  .toLocaleString(DateTime.DATE_SHORT)}{" "}
                até{" "}
                {DateTime.fromJSDate(new Date(d.end_date))
                  .setLocale("pt-BR")
                  .toLocaleString(DateTime.DATE_SHORT)}
                <span className={css["relativeDate"]}>
                  {DateTime.fromJSDate(new Date(d.created_at))
                    .setLocale("pt-BR")
                    .toRelative({
                      locale: "pt-br",
                    })}
                </span>
              </div>
              <div>
                Capital:{" "}
                {parseInt(d.initial_capital).toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
                {!isEmpty(d.max_risk) && d.initial_capital !== d.max_risk && (
                  <span className={css["maxRisk"]}>
                    (Risco Máximo: R${" "}
                    {parseInt(d.max_risk).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    )
                  </span>
                )}
              </div>
            </div>
          </button>
          {d.id === selectedId && (
            <div className={css["results"]}>
              {backtestResponse.isSuccess && (
                <BacktestResult
                  id={`backtest-${backtestResponse.data.id}`}
                  showParameters
                  isFavorite={backtestResponse.data.is_favorite}
                  onToggleFavorite={() => setSelectedId(null)}
                  scrollIntoView
                  result={{
                    ...backtestResponse.data,
                    start_date: DateTime.fromJSDate(
                      new Date(backtestResponse.data.start_date)
                    ).toISODate(),
                    end_date: DateTime.fromJSDate(
                      new Date(backtestResponse.data.end_date)
                    ).toISODate(),
                  }}
                />
              )}
              {backtestResponse.isLoading && (
                <Spinner type="Rings" text="Carregando backtest..." />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

SavedBacktests.propTypes = {
  data: PropTypes.array.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  selectedId: PropTypes.number,
  isFavorite: PropTypes.bool,
};

export default SavedBacktests;
