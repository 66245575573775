import "@reach/menu-button/styles.css";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList,
} from "@reach/menu-button";
import { Link } from "gatsby";
import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useIsClient } from "../hooks/useIsClient";
import ArrowDown from "../icons/arrow-down.svg";
import LogoutIcon from "../icons/log-out.svg";
import MenuIcon from "../icons/menu.svg";
import UserIcon from "../icons/user.svg";
import StarIcon from "../icons/star.svg";
import PortfolioIcon from "../icons/portfolio.svg";
import LogoSmall from "../images/logo.svg";
import Logo from "../images/logo-horizontal-white.svg";
import MobileMenu from "./MobileMenu";
import * as css from "./Navbar.module.css";
import {
  logAmplitudeEvent,
  trackPlansButtonClicked,
  EVENT_MAP,
} from "../utils/amplitude";
import NewTag from "./common/NewTag";
import PropTypes from "prop-types";

const OPTIONS = [
  {
    name: "Home",
    path: "/",
  },
  {
    name: "Simulador de Backtests",
    path: "/backtests",
  },
  {
    name: "Retorno Histórico",
    path: "/retorno-historico",
  },
  {
    name: "Cointegração",
    path: "/cointegracao",
  },
  {
    name: "Planos",
    path: "/planos",
  },
  {
    name: "Blog",
    path: "/blog",
  },
  {
    name: "Vídeos do YouTube",
    path: "/youtube",
  },
  {
    name: "IFR2",
    path: "/ifr2",
  },
  {
    name: "Setup 123",
    path: "/setup-123",
  },
  {
    name: "Estocástico Lento",
    path: "/estocastico-lento",
  },
  {
    name: "Beta",
    path: "/beta",
  },
  {
    name: "Ranking B3",
    path: "/ranking",
  },
  {
    name: "Ranking S&P500",
    path: "/ranking/sp500",
  },
  {
    name: "Éden dos Traders",
    path: "/eden-dos-traders",
  },
  {
    name: "Momentum B3",
    path: "/momentum",
  },
  {
    name: "Momentum S&P 500",
    path: "/momentum/sp500",
  },
  {
    name: "Low Risk",
    path: "/low-risk",
  },
  {
    name: "Low Risk S&P 500",
    path: "/low-risk/sp500",
  },
  {
    name: "Magic Formula",
    path: "/magic-formula",
  },
  {
    name: "Full Factor",
    path: "/full-factor",
  },
  {
    name: "Candle Pavio",
    path: "/candle-pavio",
  },
  {
    name: "Turnaround",
    path: "/turnaround",
  },
  {
    name: "Trap na Média",
    path: "/trap",
  },
  {
    name: "PFR",
    path: "/preco-de-fechamento-de-reversao",
  },
  {
    name: "Bandas de Bollinger",
    path: "/bandas-de-bollinger",
  },
  {
    name: "Inside Bar",
    path: "/inside-bar",
  },
  {
    name: "IPOs",
    path: "/ipos",
  },
  {
    name: "BDRs Mais Líquidas",
    path: "/bdrs-mais-liquidas",
  },
  {
    name: "Drawdown",
    path: "/drawdown",
  },
  {
    name: "Topo Histórico (ATH)",
    path: "/topo-historico",
  },
  {
    name: "Estratégias",
    path: "/estrategias",
  },
  {
    name: "VaR — Value-at-Risk",
    path: "/value-at-risk",
  },
  {
    name: "Beta da Carteira",
    path: "/beta-da-carteira",
  },
  {
    name: "Suportes e Resistências",
    path: "/suportes-e-resistencias",
  },
  {
    name: "Releases",
    path: "/releases",
  },
  {
    name: "Sobre",
    path: "/sobre",
  },
  // {
  //   name: "Ranking por Score",
  //   path: "/backtests/ranking/score",
  // },
  {
    name: "Backtest Score",
    path: "/backtests/score",
  },
];

const Navbar = ({ theme = "#033660" }) => {
  const [showMenu, setShowMenu] = useState(false);

  const {
    signOut,
    isLoggedIn,
    user: { email, isPremium },
  } = useAuth();

  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;

  const privateRoutes = [
    {
      name: "Feed",
      path: "/app/feed",
    },
    {
      name: "Robôs Quantitativos",
      path: "/app/robots",
    },
    {
      name: "Rankings de Backtests",
      path: "/app/melhores-backtests",
    },
    {
      name: "Histórico de Backtests",
      path: "/app/backtests/historico",
    },
    {
      name: "Backtests Favoritos",
      path: "/app/backtests/favoritos",
    },
    {
      name: "Portfólios",
      path: "/app/portfolios",
    },
    {
      name: "Alertas",
      path: "/app/alertas",
    },
    {
      name: "Monitor de Alertas",
      path: "/app/alertas/monitor",
    },
    {
      name: "Radar de Cointegrações",
      path: "/app/cointegracao/radar",
    },
    {
      name: "Cointegrações Salvas",
      path: "/app/cointegracao/favoritos",
    },
    {
      name: "Meus Trades",
      path: "/app/cointegracao/trades/ongoing",
    },
    {
      name: "Screening Suportes e Resistências",
      path: "/app/screening/supply-and-demand",
    },
  ];

  // If logged in, insert privateRoutes into position of index 2 (first is Home, second is Backtests)
  const mobileOptions = OPTIONS.concat([]);
  if (isLoggedIn) mobileOptions.splice(2, 0, ...privateRoutes);

  return (
    <nav
      key={key}
      className={css["navbar"]}
      style={{
        backgroundColor: theme,
      }}>
      <div>
        <button className={css["hamburguer"]} onClick={() => setShowMenu(true)}>
          <MenuIcon name="menu" data-mobile="true" />
        </button>
        <Link to="/" className={css["logo"]}>
          <img src={Logo} alt="Logo QuantBrasil" className={css["large"]} />
          <img
            src={LogoSmall}
            alt="Logo QuantBrasil"
            className={css["small"]}
          />
        </Link>
        <div className={css["links"]}>
          {isLoggedIn && <Link to="/app/feed">Feed</Link>}
          {isLoggedIn && (
            <Menu>
              <MenuButton>
                Backtests <ArrowDown aria-hidden />
              </MenuButton>
              <MenuList>
                <MenuLink as={Link} to="/backtests">
                  Simulador de Estratégias
                </MenuLink>
                <MenuLink as={Link} to="/app/melhores-backtests">
                  Rankings de Backtests
                </MenuLink>
                {/* <MenuLink as={Link} to="/app/backtests/ranking/score">
                  Ranking por Score&nbsp;
                  <NewTag />
                </MenuLink> */}
                <MenuLink as={Link} to="/backtests/score">
                  Backtest Score&nbsp;
                  <NewTag />
                </MenuLink>
                <MenuLink as={Link} to="/app/backtests/historico">
                  Histórico
                </MenuLink>
                <MenuLink as={Link} to="/app/backtests/favoritos">
                  Favoritos
                </MenuLink>
                <MenuLink as={Link} to="/app/robots">
                  Robôs Quantitativos&nbsp;
                  <NewTag />
                </MenuLink>
              </MenuList>
            </Menu>
          )}
          {!isLoggedIn && <Link to="/backtests">Backtests</Link>}
          {isLoggedIn && (
            <Menu>
              <MenuButton>
                Alertas <ArrowDown aria-hidden />
              </MenuButton>
              <MenuList>
                <MenuLink as={Link} to="/app/alertas">
                  Todos os Alertas
                </MenuLink>
                <MenuLink as={Link} to="/app/alertas/monitor">
                  Monitor de Alertas
                </MenuLink>
              </MenuList>
            </Menu>
          )}
          {isLoggedIn ? (
            <Menu>
              <MenuButton>
                Cointegração <ArrowDown aria-hidden />
              </MenuButton>
              <MenuList>
                <MenuLink as={Link} to="/cointegracao">
                  Teste de Cointegração
                </MenuLink>
                <MenuLink as={Link} to="/app/cointegracao/radar">
                  Radar
                </MenuLink>
                <MenuLink as={Link} to="/app/cointegracao/favoritos">
                  Cointegrações Salvas
                </MenuLink>
                <MenuLink as={Link} to="/app/cointegracao/trades/ongoing">
                  Meus Trades&nbsp;
                  <NewTag />
                </MenuLink>
              </MenuList>
            </Menu>
          ) : (
            <Link to="/cointegracao">Cointegração</Link>
          )}
          <Menu>
            <MenuButton>
              Ferramentas <ArrowDown aria-hidden />
            </MenuButton>
            <MenuList>
              <MenuLink as={Link} to="/value-at-risk">
                Value-at-Risk
              </MenuLink>
              <MenuLink as={Link} to="/beta-da-carteira">
                Beta da Carteira
              </MenuLink>
              <MenuLink as={Link} to="/retorno-historico">
                Retorno Histórico
              </MenuLink>
              <MenuLink as={Link} to="/suportes-e-resistencias">
                Suportes e Resistências
              </MenuLink>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton>
              Screenings <ArrowDown aria-hidden />
            </MenuButton>
            <MenuList>
              <div className={css["separator"]}>Estratégias</div>
              <MenuLink as={Link} to="/ifr2">
                IFR2
              </MenuLink>
              <MenuLink as={Link} to="/setup-123">
                Setup 123
              </MenuLink>
              <MenuLink as={Link} to="/estocastico-lento">
                Estocástico Lento
              </MenuLink>
              <MenuLink as={Link} to="/candle-pavio">
                Candle Pavio
              </MenuLink>
              <MenuLink as={Link} to="/turnaround">
                Turnaround
              </MenuLink>
              <MenuLink as={Link} to="/trap">
                Trap na Média
              </MenuLink>
              <MenuLink as={Link} to="/preco-de-fechamento-de-reversao">
                PFR
              </MenuLink>
              <MenuLink as={Link} to="/bandas-de-bollinger">
                Bandas de Bollinger
              </MenuLink>
              <MenuLink as={Link} to="/inside-bar">
                Inside Bar
              </MenuLink>
              {isLoggedIn && (
                <MenuLink as={Link} to="/app/screening/supply-and-demand">
                  Suportes e Resistências&nbsp;
                  <NewTag />
                </MenuLink>
              )}
              <MenuLink as={Link} to="/hurst">
                Expoente de Hurst&nbsp;
                <NewTag />
              </MenuLink>
              <div className={css["separator"]}>Factor Investing</div>
              <MenuLink as={Link} to="/magic-formula">
                Magic Formula
              </MenuLink>
              <MenuLink as={Link} to="/momentum">
                Momentum B3
              </MenuLink>
              <MenuLink as={Link} to="/momentum/sp500">
                Momentum S&P 500
              </MenuLink>
              <MenuLink as={Link} to="/low-risk">
                Low Risk
              </MenuLink>
              <MenuLink as={Link} to="/low-risk/sp500">
                Low Risk S&P 500
              </MenuLink>
              <MenuLink as={Link} to="/full-factor">
                Full Factor
              </MenuLink>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton>
              Listas <ArrowDown aria-hidden />
            </MenuButton>
            <MenuList>
              <MenuLink as={Link} to="/eden-dos-traders">
                Éden dos Traders
              </MenuLink>
              <MenuLink as={Link} to="/beta">
                Beta
              </MenuLink>
              <MenuLink as={Link} to="/ranking">
                Ranking B3
              </MenuLink>
              <MenuLink as={Link} to="/ranking/sp500">
                Ranking S&P 500
              </MenuLink>
              <MenuLink as={Link} to="/ipos">
                IPOs
              </MenuLink>
              <MenuLink as={Link} to="/bdrs-mais-liquidas">
                BDRs Mais Líquidas
              </MenuLink>
              <MenuLink as={Link} to="/drawdown">
                Drawdown
              </MenuLink>
              <MenuLink as={Link} to="/topo-historico">
                Topo Histórico (ATH)
              </MenuLink>
              <MenuLink as={Link} to="/estrategias">
                Estratégias
              </MenuLink>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton>
              QuantBrasil <ArrowDown aria-hidden />
            </MenuButton>
            <MenuList>
              {!isPremium && (
                <MenuLink as={Link} to="/planos">
                  Planos
                </MenuLink>
              )}
              <MenuLink as={Link} to="/blog">
                Blog
              </MenuLink>
              <MenuLink as={Link} to="/releases">
                Releases
              </MenuLink>
              <MenuLink as={Link} to="/youtube">
                Vídeos
              </MenuLink>
              <MenuLink as={Link} to="/sobre">
                Sobre
              </MenuLink>
            </MenuList>
          </Menu>
          {isLoggedIn && (
            <Menu>
              <MenuButton className={css["avatarButton"]}>
                <div>{email[0]}</div>
                <ArrowDown aria-hidden />
              </MenuButton>
              <MenuList className={css["slideDown"]}>
                <MenuLink as={Link} to="/app/settings">
                  <UserIcon aria-hidden /> Minha Conta
                </MenuLink>
                {isLoggedIn && (
                  <MenuLink as={Link} to="/app/portfolios">
                    <PortfolioIcon className={css["portfolio"]} aria-hidden />{" "}
                    Portfólios
                  </MenuLink>
                )}
                {!isPremium && (
                  <MenuLink
                    as={Link}
                    to="/planos"
                    className={css["upgrade"]}
                    onClick={() =>
                      trackPlansButtonClicked({
                        ctaText: "Upgrade",
                        fromNav: true,
                      })
                    }>
                    <StarIcon aria-hidden /> Upgrade
                  </MenuLink>
                )}
                <MenuItem onSelect={() => signOut()}>
                  <LogoutIcon aria-hidden /> Sair
                </MenuItem>
              </MenuList>
            </Menu>
          )}
          {!isLoggedIn && isClient && (
            <Link
              onClick={() =>
                logAmplitudeEvent(EVENT_MAP.LOGIN_CLICKED, {
                  path: window.location.pathname,
                })
              }
              className={css["signin"]}
              to="/login">
              Entrar
            </Link>
          )}
        </div>
      </div>
      <MobileMenu
        isOpen={showMenu}
        onClose={() => setShowMenu(false)}
        options={mobileOptions}
      />
    </nav>
  );
};

Navbar.propTypes = {
  theme: PropTypes.string,
};

export default Navbar;
