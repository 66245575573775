import DatePicker from "react-datepicker";
import { useAuth } from "../../hooks/useAuth";
import { getYesterday } from "../backtests/BacktestFilter";
import * as css from "./ReturnsDateFilter.module.css";
import PropTypes from "prop-types";

const ReturnsDateFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  restrictToYesterday = true,
}) => {
  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const maxDate = restrictToYesterday ? getYesterday() : new Date();

  return (
    <div className={css["calendarContainer"]}>
      <div className={css[["calendar"]]}>
        <label>Data Início</label>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<input className={css["date"]} />}
          dateFormat="dd/MM/yyyy"
          endDate={endDate || null}
          maxDate={endDate || null}
          selectsStart
          locale="pt"
          title={
            isPremium
              ? undefined
              : "Crie sua conta para desbloquear esse filtro"
          }
          disabled={!isPremium}
        />
      </div>
      <div className={css[["calendar"]]}>
        <label>Data Fim</label>
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<input className={css["date"]} />}
          dateFormat="dd/MM/yyyy"
          minDate={startDate || null}
          maxDate={maxDate}
          startDate={startDate || null}
          selectsEnd
          locale="pt"
          title={
            isPremium
              ? undefined
              : "Crie sua conta para desbloquear esse filtro"
          }
          disabled={!isPremium}
        />
      </div>
    </div>
  );
};

ReturnsDateFilter.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  restrictToYesterday: PropTypes.bool,
};

export default ReturnsDateFilter;
