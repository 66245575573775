import * as css from "./SuspiciousBanner.module.css";
import InfoIcon from "../../icons/info-question-circle.svg";
import { useState } from "react";
import Modal from "../common/Modal";

const SuspiciousBanner = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className={css["warning"]}>
        <InfoIcon />{" "}
        <span>
          Este backtest apresenta resultados suspeitos.{" "}
          <button onClick={() => setOpen(true)} className={css["seeMore"]}>
            O que isso significa?
          </button>
        </span>
      </div>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["help"]}>
          <div>O que são backtests suspeitos?</div>
          <div>
            <p>
              Um backtest é classificado como <strong>suspeito</strong> quando
              seu resultado apresenta alguma característica extremamente
              incomum, como por exemplo:
            </p>
            <ul>
              <li>
                <b>Retornos irreais</b>. Tipicamente, retornos acima de{" "}
                <em>1000%</em> são considerados suspeitos.
              </li>
              <li>
                <b>Sequências muito longas de acerto</b>. Uma sequência de{" "}
                <em>mais de 40</em> operações com o mesmo resultado é
                considerada suspeita.
                <ul>
                  <li>
                    Isso é comum em estratégias aplicadas em ativos com
                    amplitude muito pequena (ex: Máximas e Mínimas em ativos
                    muito baratos, onde a variação de 1 tick pode representar um{" "}
                    <em>gain</em>).
                  </li>
                </ul>
              </li>
              <li>
                <b>Trades com retornos discrepantes</b>. Se{" "}
                <em>qualquer operação</em> de um backtest possuir um resultado
                de mais de 100%, o backtest é considerado suspeito.
              </li>
            </ul>
            <p>
              <strong>Atenção:</strong> um backtest suspeito não significa que
              ele é inválido. Ele apenas indica que seu resultado é incomum e
              pode ser fruto de um erro na estratégia ou nos dados utilizados.
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SuspiciousBanner;
