import { navigate } from "gatsby";
import { useEffect } from "react";
import Loader from "react-loader-spinner";
import { useMutation } from "react-query";
import { StringParam, useQueryParams } from "use-query-params";
import { useAuth } from "../../hooks/useAuth";
import { showError } from "../../utils/utils";
import SliderDiv from "../common/SliderDiv";
import * as css from "./ConfirmEmail.module.css";
import RedirectMessage from "./RedirectMessage";

const ConfirmEmail = () => {
  const { verifyEmail } = useAuth();
  const [{ token, to }] = useQueryParams({
    token: StringParam,
    to: StringParam,
  });

  const mutation = useMutation(() => verifyEmail(token, to), {
    onError: err => {
      showError(err);
      navigate("/login");
    },
  });

  useEffect(() => {
    mutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mutation.isLoading)
    return (
      <div className={css["container"]}>
        <Loader type="Rings" color="#49ce8b" height={80} width={80} />{" "}
        Verificando seu email...
      </div>
    );

  if (mutation.isSuccess)
    return (
      <SliderDiv>
        <div className={css["container"]}>
          <RedirectMessage isNewUser />
        </div>
      </SliderDiv>
    );

  return null;
};

export default ConfirmEmail;
