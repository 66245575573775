import * as css from "./SupplyDemandScreeningResult.module.css";
import PropTypes from "prop-types";
import CandlesIcon from "../../icons/candles.svg";
import RobotIcon from "../../icons/robot.svg";
import InfoIcon from "../../icons/info-question-circle.svg";
import { Tooltip } from "react-tooltip";
import { useSessionStorage } from "react-use";
import { navigate } from "gatsby";
import { useMemo, useRef, useState } from "react";
import { SELECT_SMALL } from "../../utils/select";
import Select from "react-select";
import orderBy from "lodash.orderby";

const SupplyDemandScreeningResult = ({
  data,
  frequency,
  minLevel,
  maxLevel,
}) => {
  const computedFrequency = useRef(frequency);
  const computedMinLevel = useRef(minLevel);
  const computedMaxLevel = useRef(maxLevel);

  const currentYear = new Date().getFullYear();

  const [, setAsset] = useSessionStorage("qb.sd.asset");
  const [, setYear] = useSessionStorage("qb.sd.year");
  const [, setSdPeriod] = useSessionStorage("qb.sd.period");
  const [, setProjectionPeriod] = useSessionStorage("qb.sd.projectionPeriod");

  const onClick = ({ symbol }) => {
    setAsset({ label: symbol, value: symbol });
    setYear({
      label: currentYear,
      value: currentYear,
    });
    setSdPeriod(20);
    setProjectionPeriod(computedFrequency.current);

    navigate("/suportes-e-resistencias/?executeOnLoad=1");
  };

  const maxHeader =
    computedMaxLevel.current === 0
      ? `Fechamento ${computedFrequency.current.label}`
      : `${Math.abs(computedMaxLevel.current)}${
          computedMaxLevel.current > 0 ? "ª Resistência" : "º Suporte"
        }`;

  const minHeader =
    computedMinLevel.current === 0
      ? `Fechamento ${computedFrequency.current.label}`
      : `${Math.abs(computedMinLevel.current)}${
          computedMinLevel.current > 0 ? "ª Resistência" : "º Suporte"
        }`;

  const maxHeaderArticle = maxHeader.includes("Resistência") ? "a" : "o";
  const minHeaderArticle = minHeader.includes("Resistência") ? "a" : "o";

  const tooltip1 = `Em parêntesis quanto o ativo precisa subir ou cair para atingir ${maxHeaderArticle} ${maxHeader}`;
  const tooltip2 = `Em parêntesis quanto o ativo precisa subir ou cair para atingir ${minHeaderArticle} ${minHeader}.`;
  const tooltip3 = `Volatilidade auferida ${
    computedFrequency.current.value === "yearly"
      ? `em ${currentYear - 1}`
      : computedFrequency.current.value === "monthly"
      ? "no último mês"
      : "na última semana"
  }.`;

  const SORT_OPTIONS = [
    {
      label: `Menor distância para ${maxHeaderArticle} ${maxHeader}`,
      value: "distance_from_max_level asc",
    },
    {
      label: `Maior distância para ${maxHeaderArticle} ${maxHeader}`,
      value: "distance_from_max_level desc",
    },
    {
      label: `Menor distância para ${minHeaderArticle} ${minHeader}`,
      value: "distance_from_min_level asc",
    },
    {
      label: `Maior distância para ${minHeaderArticle} ${minHeader}`,
      value: "distance_from_min_level desc",
    },
  ];

  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const [fieldToSort, directionToSort] = sortOption.value.split(" ");

  const sortedData = useMemo(
    () => orderBy(data, [fieldToSort], [directionToSort]),
    [data, directionToSort, fieldToSort]
  );

  return (
    <>
      <div className={css["banner"]}>
        <div>
          <RobotIcon />
          <div>Como interpretar esse screening?</div>
        </div>
        <div>
          <div className={css["text"]}>
            <div>Como interpretar esse screening?</div>
            <div>
              A tabela abaixo mostra os ativos selecionados e sua distância para{" "}
              {maxHeaderArticle} <b>{maxHeader}</b> e {minHeaderArticle}{" "}
              <b>{minHeader}</b>, projetados para{" "}
              {computedFrequency.current.value === "yearly"
                ? "o ano"
                : computedFrequency.current.value === "monthly"
                ? "o mês"
                : "a semana"}{" "}
              atual. Os níveis de interesse são calculados utilizando a
              volatilidade do período.
            </div>
          </div>
        </div>
      </div>
      <div className={css["filter"]}>
        <div>
          <label htmlFor="table-order">Ordenar por</label>
          <Select
            id="table-order"
            options={SORT_OPTIONS}
            value={sortOption}
            onChange={option => setSortOption(option)}
            styles={{
              ...SELECT_SMALL,
              container: provided => ({
                ...provided,
                width: "375px",
              }),
            }}
            isSearchable={false}
          />
        </div>
      </div>
      <div className={css["tableContainer"]}>
        <table>
          <thead>
            <tr>
              <th>Ativo</th>
              <th>Preço</th>
              <th data-tooltip-content={tooltip1} data-tooltip-id="level-tip">
                {maxHeader}
                <sup>1</sup>
              </th>
              <th data-tooltip-content={tooltip2} data-tooltip-id="level-tip">
                {minHeader}
                <sup>2</sup>
              </th>
              <th data-tooltip-content={tooltip3} data-tooltip-id="vol-tip">
                Vol. {computedFrequency.current.label}
                <sup>3</sup>
              </th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map(node => {
              return (
                <tr key={node.symbol}>
                  <td>{node.symbol.replace("$", "FUT")}</td>
                  <td>{node.current_price.toFixed(2)}</td>
                  <td>
                    {Math.max(node.max_zone, 0).toFixed(2)}{" "}
                    <span className={css["detail"]}>
                      ({node.max_zone > node.current_price ? "+" : "-"}
                      {(
                        Math.min(
                          node.distance_from_max_level,
                          node.max_zone > 0 ? Infinity : 1
                        ) * 100
                      ).toFixed(2)}
                      %){" "}
                      {Math.max(node.max_zone, 0) === 0 && (
                        <InfoIcon
                          data-tooltip-content="O suporte calculado para esse nível de volatilidade é negativo, e portanto consideramos como 0."
                          data-tooltip-id="zero-tip"
                        />
                      )}
                    </span>
                  </td>
                  <td>
                    {Math.max(node.min_zone, 0).toFixed(2)}{" "}
                    <span className={css["detail"]}>
                      ({node.min_zone < node.current_price ? "-" : "+"}
                      {(
                        Math.min(
                          node.distance_from_min_level,
                          node.min_zone > 0 ? Infinity : 1
                        ) * 100
                      ).toFixed(2)}
                      %){" "}
                      {Math.max(node.min_zone, 0) === 0 && (
                        <InfoIcon
                          data-tooltip-content="O suporte calculado para esse nível de volatilidade é negativo, e portanto consideramos como 0."
                          data-tooltip-id="zero-tip"
                        />
                      )}
                    </span>
                  </td>
                  <td>{(node.volatility * 100).toFixed(2)}%</td>
                  <td>
                    <button
                      data-tooltip-id="view-sd-tip"
                      data-tooltip-content={`Ver Gráfico`}
                      className={css["candleButton"]}
                      onClick={() => onClick(node)}>
                      <CandlesIcon />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> {tooltip1}
          </div>
          <div>
            <sup>2</sup> {tooltip2}
          </div>
          <div>
            <sup>3</sup> {tooltip3}
          </div>
        </div>
        <Tooltip id="view-sd-tip" clickable />
        <Tooltip id="level-tip" delayHide={100} clickable />
        <Tooltip id="zero-tip" delayHide={100} clickable />
      </div>
    </>
  );
};

SupplyDemandScreeningResult.propTypes = {
  data: PropTypes.array.isRequired,
  frequency: PropTypes.object.isRequired,
  minLevel: PropTypes.number.isRequired,
  maxLevel: PropTypes.number.isRequired,
};

export default SupplyDemandScreeningResult;
