// extracted by mini-css-extract-plugin
export var arrowDown = "momentum-module--arrowDown--e754a";
export var arrowUp = "momentum-module--arrowUp--ec084";
export var filter = "momentum-module--filter--2f0c2";
export var filtersWrapper = "momentum-module--filtersWrapper--3bfac";
export var input = "momentum-module--input--0c4ad";
export var legend = "momentum-module--legend--15e7a";
export var momentumContainer = "momentum-module--momentumContainer--3f6bf";
export var pause = "momentum-module--pause--cd18b";
export var periodSelector = "momentum-module--periodSelector--0e441";
export var sectorColumn = "momentum-module--sectorColumn--31035";
export var tableContainer = "momentum-module--tableContainer--36d26";