import * as css from "./StreamWidget.module.css";
import { useEffect, useMemo, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useConfig } from "../../hooks/useConfig";
import { getAssetLabel } from "../../hooks/useAssets";
import FeedWidget from "./FeedWidget";
import { DateTime } from "luxon";

const StreamWidget = () => {
  const { BASE_URL, isProd } = useConfig();
  const [prices, setPrices] = useState([]);

  // Remove http:// or https:// from the URL
  const wsURL = BASE_URL.replace(/^https?:\/\//, "");

  const protocol = isProd ? "wss" : "ws";

  const { lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    `${protocol}://${wsURL}/api/feed/data`
  );

  useEffect(() => {
    return () => {
      const websocket = getWebSocket();
      if (websocket) websocket.close();
    };
  }, [getWebSocket]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      // For each ticker, update the price
      setPrices(prevPrices => {
        const newPrices = [...prevPrices];
        lastJsonMessage.forEach(price => {
          // Check if price is more than one hour old
          if (new Date().getTime() - price.timestamp > 3600000) {
            price["oldPrice"] = true;
          } else {
            price["oldPrice"] = false;
          }

          price["fromAnotherDay"] =
            DateTime.fromMillis(price.timestamp).get("day") !==
            DateTime.now().get("day");

          const index = newPrices.findIndex(p => p.ticker === price.ticker);
          if (index !== -1) {
            newPrices[index] = price;
          } else {
            newPrices.push(price);
          }
        });

        return newPrices;
      });
    }
  }, [lastJsonMessage]);

  // Sort prices accordingly to the following rule: WIN$D first, then WDO$D, then the rest alphabetically
  const sorted = useMemo(() => {
    return prices.sort((a, b) => {
      if (a.ticker === "WIN$D") return -1;
      if (b.ticker === "WIN$D") return 1;
      if (a.ticker === "WDO$D") return -1;
      if (b.ticker === "WDO$D") return 1;
      if (a.ticker < b.ticker) return -1;
      if (a.ticker > b.ticker) return 1;
      return 0;
    });
  }, [prices]);

  const isConnecting = readyState === ReadyState.CONNECTING;

  const isConnected = readyState === ReadyState.OPEN;

  // Should return a pulsing dot if connecting, a green dot if connected, a red dot if disconnected
  const getStatus = () => {
    if (isConnecting) {
      return (
        <div className={css["status"]}>
          <div className={css["dot"]} />
          <span>Conectando...</span>
        </div>
      );
    }
    if (readyState === ReadyState.OPEN) {
      return (
        <div className={css["status"] + " " + css["connected"]}>
          <div className={css["dot"]} />
          <span>Conectado</span>
        </div>
      );
    }

    return (
      <div className={css["status"] + " " + css["disconnected"]}>
        <div className={css["dot"]} />
        <span>Desconectado</span>
      </div>
    );
  };

  const getVariation = price =>
    ((price.price - price.last_close) / price.last_close) * 100;

  const getPrices = () => {
    if (sorted.length === 0 && isConnected)
      return <div className={css["disclaimer"]}>Carregando preços...</div>;

    const isMarketClosed = sorted.every(price => price.oldPrice);

    const isFromAnotherDay = sorted.every(price => price.fromAnotherDay);

    // Show date and time of last update using luxon
    const lastUpdate = sorted.reduce((latestTimestamp, price) => {
      const timestamp = price.timestamp;
      if (timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
      }
      return latestTimestamp;
    }, 0);

    const formattedLastUpdate = DateTime.fromMillis(lastUpdate)
      .setLocale("pt-BR")
      .toFormat("dd/LL 'às' HH:mm");

    if (isConnected && sorted.length > 0)
      return (
        <>
          <div className={css["container"]}>
            {sorted.map((price, index) => (
              <div className={css["prices"]} key={index}>
                <div>{getAssetLabel(price.ticker)}</div>
                <div>
                  {price.price.toFixed(2)}{" "}
                  {!isFromAnotherDay && (
                    <span
                      style={{
                        color:
                          getVariation(price) > 0
                            ? "#49ce8b"
                            : getVariation(price) < 0
                            ? "#d64242"
                            : undefined,
                      }}>
                      {getVariation(price) > 0 && "+"}
                      {getVariation(price).toFixed(2)}%
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          {isMarketClosed && (
            <div className={css["warning"]}>
              <span>Mercado fechado.</span> Última atualização:{" "}
              {formattedLastUpdate}.
            </div>
          )}
          {!isFromAnotherDay && (
            <div className={css["disclaimer"]}>
              Variação calculada em relação ao fechamento do dia anterior.
            </div>
          )}
        </>
      );
  };

  return (
    <FeedWidget>
      {getStatus()}
      {getPrices()}
    </FeedWidget>
  );
};

export default StreamWidget;
