import { ResponsiveLine } from "@nivo/line";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import { useMedia } from "react-use";
import ReturnsTooltip from "./ReturnsTooltip";

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

const formatData = (data, key, color, priceLabel, name) => {
  const initialValue = data[0][key];
  return data.map(d => ({
    x: d.date,
    y: d[key],
    initialValue,
    name,
    color,
    price: d.price || d.wallet_value,
    priceLabel,
  }));
};

export const getMinMax = (data, delta) => {
  const minArray = [];
  const maxArray = [];

  data.forEach(d => {
    const points = d.data;
    const minValue = Math.min(...points.map(({ y }) => y)) - delta;
    const maxValue = Math.max(...points.map(({ y }) => y)) + delta;
    minArray.push(minValue);
    maxArray.push(maxValue);
  });

  return [Math.min(...minArray), Math.max(...maxArray)];
};

const getData = (visibleTickers, weightedData, groupedData) => {
  const data = [];

  if (visibleTickers.includes("Portfólio")) {
    data.push({
      id: "Portfólio",
      color: "#033660",
      data: formatData(
        weightedData,
        "wallet_value",
        "#033660",
        "Cota",
        "Portfólio"
      ),
    });
  }

  visibleTickers
    .filter(ticker => ticker !== "Portfólio")
    .forEach((ticker, i) => {
      const id = ticker.includes(" ")
        ? capitalizeFirstLetter(ticker.split(" ")[0])
        : ticker;
      data.push({
        id,
        color: colorArray[i],
        data: formatData(
          groupedData[ticker],
          "value",
          colorArray[i],
          ticker.includes(" ") ? "Cota" : "Preço",
          id
        ),
      });
    });

  return data.reverse();
};

const ReturnsChart = ({ weightedData, groupedData, visibleTickers }) => {
  const isMobile = useMedia("(max-width: 700px)");

  const data = useMemo(
    () => getData(visibleTickers, weightedData, groupedData),
    [groupedData, visibleTickers, weightedData]
  );

  const delta = 0.1;

  const [minValue, maxValue] = getMinMax(data, delta);

  return (
    <div
      style={{
        height: "500px",
        backgroundColor: "#fff",
      }}>
      <ResponsiveLine
        data={data.reverse()}
        margin={{
          top: 20,
          right: isMobile ? 10 : 110,
          bottom: 50,
          left: 40,
        }}
        xScale={{
          type: "time",
          format: "%Y-%m-%d",
          useUTC: false,
          precision: "day",
        }}
        xFormat="time:%Y-%m-%d"
        tooltip={input => <ReturnsTooltip data={input.point.data} />}
        yScale={{
          type: "linear",
          min: minValue,
          max: maxValue,
        }}
        axisLeft={{
          tickPadding: 8,
        }}
        axisBottom={{
          format: "%b %y",
          tickRotation: isMobile ? 60 : 0,
        }}
        enableArea={true}
        areaBaselineValue={1}
        areaOpacity={0.07}
        enableSlices={false}
        colors={{ datum: "color" }}
        pointSize={0}
        useMesh={true}
        motionConfig="gentle"
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

ReturnsChart.propTypes = {
  weightedData: PropTypes.array.isRequired,
  groupedData: PropTypes.object.isRequired,
  visibleTickers: PropTypes.array.isRequired,
};

export default memo(ReturnsChart);
