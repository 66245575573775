import { Link } from "gatsby";
import { useCallback, useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import { useMedia } from "react-use";
import scrollToElement from "scroll-to-element";
import { useAPI } from "../../hooks/useAPI";
import { useAuth } from "../../hooks/useAuth";
import {
  mapValues,
  useCointegrationFilter,
} from "../../hooks/useCointegrationFilter";
import LogoIcon from "../../images/logo.svg";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
  addUnique,
} from "../../utils/amplitude";
import { showError } from "../../utils/utils";
import RegisterButton from "../common/RegisterButton";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./CointegrationContainer.module.css";
import CointegrationFilter from "./CointegrationFilter";
import CointegrationResult from "./CointegrationResult";
import HelpButton from "./HelpModal";
import { BooleanParam, useQueryParams } from "use-query-params";
import SliderDiv from "../common/SliderDiv";

const CointegrationContainer = () => {
  const {
    state,
    getValue,
    setValue,
    reset: resetFilter,
    isValid,
    errors,
  } = useCointegrationFilter();

  const [{ executeOnLoad }] = useQueryParams({
    executeOnLoad: BooleanParam,
  });

  const [isFavorite, setFavorite] = useState(false);

  const onToggleFavorite = () => setFavorite(!isFavorite);

  const isMobile = useMedia("(max-width: 700px)");

  const callAPI = useAPI({ withCredentials: true });

  const { isLoggedIn, user } = useAuth();

  const showUpgrade = isLoggedIn && !user.isPremium;

  const { data, refetch: refetchExpiration } = useQuery(
    ["cointegrationBatchExpiration", user.userId],
    () => callAPI(`/api/cointegration/expiration`),
    {
      enabled: Boolean(user?.isPremium),
    }
  );

  const mutation = useMutation(
    () =>
      callAPI(
        state.runBatch
          ? `/api/cointegration/batch`
          : `/api/metrics/cointegration`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(mapValues(state)),
        }
      ),
    {
      onError: showError,
      onSuccess: data => {
        if (data.batchSuccess) {
          refetchExpiration();
          resetFilter();
        } else {
          if (isMobile) {
            setTimeout(() => {
              scrollToElement("#cointegration-result");
            }, 0);
          }
        }
      },
    }
  );

  const runBatch = getValue("runBatch");

  const onCointegrationExecuted = useCallback(() => {
    mutation.mutate();
    setFavorite(false);
    const params = mapValues(state);
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_EXECUTED, params);
    incrementProperty("cointegrationsExecuted");
    addUnique("assetsOfInterest", params.base_asset);
    addUnique("assetsOfInterest", params.comparison_asset);
  }, [mutation, state]);

  const hasFastRun = useRef(false);

  useEffect(() => {
    if (executeOnLoad && !hasFastRun.current) {
      onCointegrationExecuted();
      hasFastRun.current = true;
    }
  }, [onCointegrationExecuted, executeOnLoad]);

  const onBatchExecuted = useCallback(() => {
    mutation.mutate();
    setFavorite(false);
    const params = mapValues(state);
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_BATCH_CREATED, params);
    addUnique("assetsOfInterest", params.base_asset);
  }, [mutation, state]);

  const title = isLoggedIn ? "Teste de Cointegração" : "Cointegração";

  return (
    <AppLayout
      ctaMessage="Crie sua conta para executar estratégias de Long & Short"
      premiumMessage="Seja Premium para desbloquear todos os ativos e períodos"
      seoProps={{
        title,
        description:
          "Experimente uma análise precisa de Long & Short utilizando nossa intuitiva ferramenta de Teste de Cointegração. Ideal para investidores pessoa-física e profissionais do mercado financeiro que querem ampliar seu portfólio de estratégias quantitativas. O Long & Short por cointegração é ideal tanto no bull quanto no bear market. Teste agora de graça!",
      }}>
      <div>
        <h1 className={css["header"]}>
          {title} <HelpButton />
        </h1>
        <div className={css["panel"]}>
          <CointegrationFilter
            onClick={runBatch ? onBatchExecuted : onCointegrationExecuted}
            isLoading={mutation.isLoading}
            state={state}
            setValue={setValue}
            getValue={getValue}
            resetFilter={resetFilter}
            isValid={isValid}
            errors={errors}
            expiration={data?.expiration || -1}
          />
          <div className={css["results"]}>
            {mutation.isLoading && (
              <Spinner type="Rings" text="Robôs trabalhando..." />
            )}
            {mutation.data &&
              mutation.isSuccess &&
              (mutation.data.batchSuccess ? (
                <SliderDiv>
                  <div className={css["successBatch"]}>
                    <div>Cointegrações enviadas! 📨</div>
                    <p>
                      Você receberá um email quando estiver tudo pronto.
                      Enquanto isso, fique à vontade para testar cointegrações
                      individualmente.
                    </p>
                  </div>
                </SliderDiv>
              ) : (
                <CointegrationResult
                  result={mutation.data}
                  showUpgrade={showUpgrade}
                  isFavorite={isFavorite}
                  onToggleFavorite={onToggleFavorite}
                />
              ))}
            {mutation.isIdle && (
              <div className={css["welcomeContainer"]}>
                <div>
                  Bem vindo à <b>área de cointegração</b> do QuantBrasil!
                </div>
                <img className={css["logo"]} src={LogoIcon} />
                {isLoggedIn && (
                  <div>
                    Selecione os ativos e a janela de cointegração no filtro ao
                    lado.
                  </div>
                )}
                {!isLoggedIn && (
                  <div className={css["ctaContainer"]}>
                    <RegisterButton copy="Crie sua conta para desbloquear mais funcionalidades." />
                  </div>
                )}
                <div className={css["questions"]}>
                  <span>Dúvidas?</span>
                  <div>
                    <div>
                      <Link to="/aprenda-a-estrategia-de-long-and-short-por-cointegracao">
                        Aprenda a Estratégia de Long & Short por Cointegração
                      </Link>
                    </div>
                    <div>
                      <Link to="/definindo-a-janela-de-tempo-no-long-and-short-por-cointegracao">
                        Definindo a Janela de Tempo no Long & Short por
                        Cointegração
                      </Link>
                    </div>
                    <div>
                      <Link to="/como-treinar-e-testar-o-modelo-long-and-short-por-cointegracao-na-pratica">
                        Como Treinar e Testar o Modelo Long & Short por
                        Cointegração na Prática
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CointegrationContainer;
