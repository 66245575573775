import { toPercent } from "../../utils/utils";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import * as css from "./TradeSquare.module.css";
import { useId } from "react";
import { Tooltip } from "react-tooltip";

const TradeSquare = ({ trade }) => {
  const { start_date, end_date, pct_profit } = trade;
  const squareColor = pct_profit > 0 ? "#49ce8b" : "#d64242";

  // Format date using luxon to DD/MM/YYYY
  const start = DateTime.fromISO(start_date).toFormat("dd/MM/yyyy");
  const end = end_date
    ? DateTime.fromISO(end_date).toFormat("dd/MM/yyyy")
    : "Em execução";

  const id = useId();

  return (
    <>
      <div
        data-tooltip-id={id}
        className={`${css["square"]} ${!end_date ? css["pulsating"] : ""}`}
        style={{
          backgroundColor: squareColor,
        }}></div>
      <Tooltip id={id} delayHide={100} clickable>
        <div>Início: {start}</div>
        <div>Fim: {end}</div>
        <div>Resultado: {toPercent(pct_profit)}</div>
      </Tooltip>
    </>
  );
};

TradeSquare.propTypes = {
  trade: PropTypes.object.isRequired,
};

export default TradeSquare;
