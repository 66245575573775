import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useState } from "react";
import Select from "react-select";
import { Tooltip } from "react-tooltip";
import { usePortfolios } from "../../hooks/usePortfolios";
import InfoIcon from "../../icons/info-question-circle.svg";
import { SELECT_SMALL } from "../../utils/select";
import { toPercent } from "../../utils/utils";
import ShowPortfolios from "../portfolios/ShowPortfolios";
import * as css from "./BacktestsList.module.css";
import { renderToStaticMarkup } from "react-dom/server";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import ParametersBox from "../backtests/ParametersBox";
import { getAssetLabel } from "../../hooks/useAssets";

const SORT_OPTIONS = [
  { value: "pct_profit desc", label: "Maior Lucro" },
  { value: "ev desc", label: "Maior EV" },
  { value: "drawdown asc", label: "Menor Drawdown" },
  { value: "pct_gains desc", label: "Maior Acerto" },
  { value: "num_operations desc", label: "Mais Operações" },
  { value: "score desc", label: "Melhor Score" },
];

const formatPeriod = (backtests, info) => {
  if (info.start_date && info.end_date) {
    // Convert YYYY-MM-DD to DD/MM/YYYY
    const startDate = info.start_date.split("-").reverse().join("/");
    const endDate = info.end_date.split("-").reverse().join("/");
    return `de ${startDate} a ${endDate}`;
  }

  if (isEmpty(backtests)) return "";
  const { start_date, end_date } = backtests[0];
  return `de ${DateTime.fromJSDate(new Date(start_date))
    .setLocale("pt-BR")
    .toLocaleString(DateTime.DATE_SHORT)} a ${DateTime.fromJSDate(
    new Date(end_date)
  )
    .setLocale("pt-BR")
    .toLocaleString(DateTime.DATE_SHORT)}`;
};

const BacktestsList = ({ backtests, info }) => {
  const [ticker, setTicker] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [minOperations, setMinOperations] = useState("");
  const [maxDrawdown, setMaxDrawdown] = useState("");
  const [minGains, setMinGains] = useState("");
  const [minProfit, setMinProfit] = useState("");
  const [minEV, setMinEV] = useState("");
  const [minScore, setMinScore] = useState("");
  const [fieldToSort, directionToSort] = sortBy.value.split(" ");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const ordered = orderBy(
    backtests
      .filter(backtest =>
        minOperations
          ? backtest.num_operations >= parseInt(minOperations)
          : true
      )
      .filter(backtest =>
        maxDrawdown ? backtest.drawdown <= parseFloat(maxDrawdown / 100) : true
      )
      .filter(backtest =>
        minGains ? backtest.pct_gains >= parseFloat(minGains / 100) : true
      )
      .filter(backtest =>
        minProfit ? backtest.pct_profit >= parseFloat(minProfit / 100) : true
      )
      .filter(backtest =>
        minEV ? backtest.ev >= parseFloat(minEV / 100) : true
      )
      .filter(backtest =>
        minScore ? (backtest.score || 0) >= parseFloat(minScore) : true
      )
      .map(backtest => ({ ...backtest, score: backtest.score || 0 })),
    fieldToSort,
    directionToSort
  );

  const allowedSymbols = backtests
    .filter(backtest => {
      const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
      if (!filterByAssets) return true;
      return selectedAssets.includes(backtest.ticker);
    })
    .filter(backtest => {
      if (isEmpty(ticker)) return true;
      return backtest.ticker.includes(ticker.toUpperCase());
    })
    .map(backtest => backtest.ticker);

  const getContent = () => {
    return (
      <div className={css["tableContainer"]}>
        <table>
          <thead>
            <tr>
              <th>Posição</th>
              <th>Ativo</th>
              <th
                style={{
                  color:
                    sortBy.value === "num_operations desc"
                      ? "#49ce8b"
                      : undefined,
                }}>
                Operações
              </th>
              <th
                style={{
                  color:
                    sortBy.value === "pct_gains desc" ? "#49ce8b" : undefined,
                }}>
                Acerto (%)
              </th>
              <th
                style={{
                  color:
                    sortBy.value === "drawdown asc" ? "#49ce8b" : undefined,
                }}>
                Drawdown (%)
              </th>
              <th
                style={{
                  color:
                    sortBy.value === "pct_profit desc" ? "#49ce8b" : undefined,
                }}>
                Lucro (%)
              </th>
              <th
                style={{
                  color: sortBy.value === "ev desc" ? "#49ce8b" : undefined,
                }}>
                EV (%)
              </th>
              <th
                style={{
                  color: sortBy.value === "score desc" ? "#49ce8b" : undefined,
                }}>
                Score
              </th>
            </tr>
          </thead>
          <tbody>
            {ordered.map((backtest, i) => {
              if (!allowedSymbols.includes(backtest.ticker)) return null;
              return (
                <tr key={backtest.id}>
                  <td>{i + 1}</td>
                  <td>{getAssetLabel(backtest.ticker)}</td>
                  <td>{backtest.num_operations}</td>
                  <td>{toPercent(backtest.pct_gains)}</td>
                  <td>{toPercent(backtest.drawdown)}</td>
                  <td>{toPercent(backtest.pct_profit)}</td>
                  <td>{toPercent(backtest.ev)}</td>
                  <td>{backtest.score ? backtest.score.toFixed(2) : "-"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const {
    step: changeRakingOrderStep,
    markStepAsCompleted: markChangeRankingOrderAsCompleted,
    showStep: showChangeRankingOrderStep,
  } = useOnboardingStep("CHANGE_RANKING_ORDER");

  const { showStep: showChangeRankingStep } = useOnboardingStep(
    "CHANGE_SELECTED_SETUP"
  );

  return (
    <div className={css["container"]}>
      <div className={css["filter"]}>
        <div className={css["input"]} style={{ maxWidth: "105px" }}>
          <label htmlFor="asset-top">Ativo</label>
          <input
            value={ticker}
            onChange={e => setTicker(e.target.value)}
            placeholder="Ex: EQTL3"
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "123px" }}>
          <label htmlFor="min-operations">{`Min Operações`}</label>
          <input
            id="min-operations"
            type="number"
            min={0}
            value={minOperations}
            placeholder="Ex: 40"
            onChange={e => setMinOperations(e.target.value)}
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "118px" }}>
          <label htmlFor="min-gains">{`Min Acerto (%)`}</label>
          <input
            id="min-gains"
            type="number"
            min={0}
            value={minGains}
            placeholder="Ex: 70"
            onChange={e => setMinGains(e.target.value)}
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "176px" }}>
          <label htmlFor="max-drawdown">
            {`Max Drawdown (%)`} <InfoIcon data-tooltip-id="dd-tip" />
          </label>
          <input
            id="max-drawdown"
            type="number"
            min={0}
            value={maxDrawdown}
            placeholder="Ex: 10"
            onChange={e => setMaxDrawdown(e.target.value)}
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "108px" }}>
          <label htmlFor="min-profit">{`Min Lucro (%)`}</label>
          <input
            id="min-profit"
            type="number"
            min={0}
            value={minProfit}
            placeholder="Ex: 50"
            onChange={e => setMinProfit(e.target.value)}
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "106px" }}>
          <label htmlFor="min-ev">
            {`Min EV (%)`} <InfoIcon data-tooltip-id="ev-tip" />
          </label>
          <input
            id="min-ev"
            type="number"
            min={0}
            value={minEV}
            placeholder="Ex: 1"
            onChange={e => setMinEV(e.target.value)}
          />
        </div>
        <div className={css["input"]} style={{ maxWidth: "102px" }}>
          <label htmlFor="min-score">
            {`Min Score`} <InfoIcon data-tooltip-id="score-tip" />
          </label>
          <input
            id="min-score"
            type="number"
            min={0}
            value={minScore}
            placeholder="Ex: 70"
            onChange={e => setMinScore(e.target.value)}
          />
        </div>
        <Popover.Root open={showChangeRankingOrderStep}>
          <Popover.Trigger asChild>
            <div>
              <label htmlFor="asset-order">Ordenar por</label>
              <Select
                id="asset-order"
                options={SORT_OPTIONS}
                value={sortBy}
                onChange={option => {
                  markChangeRankingOrderAsCompleted();
                  setSortBy(option);
                }}
                styles={{
                  ...SELECT_SMALL,
                  container: provided => ({
                    ...provided,
                    width: "175px",
                  }),
                }}
                isSearchable={false}
              />
            </div>
          </Popover.Trigger>
          {showChangeRankingOrderStep && !showChangeRankingStep && (
            <OnboardingStep
              step={changeRakingOrderStep}
              onConfirm={markChangeRankingOrderAsCompleted}
              onDismiss={markChangeRankingOrderAsCompleted}
            />
          )}
        </Popover.Root>
      </div>
      <div className={css["batchInfo"]}>
        <div className={css["period"]}>
          <div>
            <strong>Período:</strong> {formatPeriod(backtests, info)}
          </div>{" "}
          <InfoIcon
            data-tooltip-id="bt-list-tip"
            data-tooltip-content={`Atualizado
          ${DateTime.fromJSDate(new Date(info.created_at))
            .setLocale("pt-BR")
            .toFormat("dd/LL 'às' HH:mm")}`}
          />
        </div>
        <ParametersBox
          parameters={info.parameters}
          label="Parâmetros da Estratégia:"
        />
      </div>
      <ShowPortfolios
        showPortfolios={showPortfolios}
        setShowPortfolios={setShowPortfolios}
        portfolioOptions={portfolioOptions}
        setSelectedId={setSelectedId}
        selectedPortfolio={selectedPortfolio}
      />
      {getContent()}
      <Tooltip id="bt-list-tip" clickable />
      <Tooltip
        id="ev-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o EV?{" "}
            <a
              href="/entenda-o-que-e-valor-esperado-ev-e-sua-utilidade-para-um-trader"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <Tooltip
        id="score-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o Score?{" "}
            <a
              href="/backtests/score"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <Tooltip
        id="dd-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Dúvidas sobre o Drawdown?{" "}
            <a
              href="/entenda-o-drawdown-e-calcule-essa-medida-de-volatilidade-para-qualquer-ativo"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
    </div>
  );
};

BacktestsList.propTypes = {
  backtests: PropTypes.array.isRequired,
  info: PropTypes.object.isRequired,
};

export default BacktestsList;
