import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";

import { useIsClient } from "../../hooks/useIsClient";
import Footer from "../Footer";
import Navbar from "../Navbar";
import * as css from "./LandingPageLayout.module.css";

const NAVBAR_THRESHOLD = 120;

const Fixed = ({ position, top, fixedNav }) => (
  <div className={css["fixed"]} style={{ position, top }}>
    {fixedNav}
  </div>
);

Fixed.propTypes = {
  position: PropTypes.string.isRequired,
  top: PropTypes.string.isRequired,
  fixedNav: PropTypes.node.isRequired,
};

const MemoizedFixed = memo(Fixed);

const NavBarContainer = ({ ...rest }) => {
  const [y, setY] = useState(0);

  useEffect(() => {
    const handler = () => setY(window.pageYOffset);
    window.addEventListener("scroll", handler);

    return () => window.removeEventListener("scroll", handler);
  }, []);

  return (
    <MemoizedFixed
      {...rest}
      position={y > NAVBAR_THRESHOLD ? "fixed" : "absolute"}
      top={y > NAVBAR_THRESHOLD ? "0px" : "-100px"}
    />
  );
};

const LandingPageLayout = ({
  children,
  footer = true,
  location,
  showFixedNav = true,
  theme = "#033660",
}) => {
  const isClient = useIsClient();
  const key = isClient ? `client` : `server`;
  return (
    <div className={css["container"]} key={key}>
      {showFixedNav && (
        <NavBarContainer
          location={location}
          fixedNav={<Navbar theme={theme} />}
        />
      )}
      {children}
      {footer && <Footer />}
    </div>
  );
};

LandingPageLayout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool,
  showFixedNav: PropTypes.bool,
  customFixedNav: PropTypes.node,
  theme: PropTypes.string,
};

export default LandingPageLayout;
