import PropTypes from "prop-types";
import * as css from "./RefreshCointegration.module.css";
import RefreshIcon from "../../icons/refresh2.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import { useCointegrationFilter } from "../../hooks/useCointegrationFilter";
import { navigate } from "gatsby";

const RefreshCointegration = ({ cointegration, allowPopover = false }) => {
  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "REFRESH_COINTEGRATION"
  );

  const { setState } = useCointegrationFilter();

  const onClick = () => {
    markStepAsCompleted();
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_REFRESHED);

    setState({
      baseAsset: {
        value: cointegration.base_asset,
        label: cointegration.base_asset,
      },
      comparisonAsset: {
        value: cointegration.comparison_asset,
        label: cointegration.comparison_asset,
      },
      window: cointegration.window,
    });

    navigate("/cointegracao/?executeOnLoad=1");
  };

  const canShow = allowPopover && showStep;

  return (
    <Popover.Root open={canShow}>
      <Popover.Trigger asChild>
        <button
          className={css["refreshButton"]}
          data-tooltip-id="refresh-coint-tip"
          data-tooltip-content={`Atualizar cointegração`}
          onClick={() => onClick()}>
          <RefreshIcon />
        </button>
      </Popover.Trigger>
      {canShow && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

RefreshCointegration.propTypes = {
  cointegration: PropTypes.object.isRequired,
  allowPopover: PropTypes.bool,
};

export default RefreshCointegration;
