import { usePortfolios } from "../../hooks/usePortfolios";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import Portfolios from "./Portfolios";

const PortfoliosContainer = () => {
  const { isSuccess, isLoading, fetchPortfolios, portfolios } = usePortfolios();

  return (
    <AppLayout
      seoProps={{
        title: "Portfólios",
      }}>
      <div>
        <h1>Portfólios</h1>
        {isLoading && <Spinner type="Rings" text="Carregando..." />}
        {isSuccess && (
          <Portfolios
            portfolios={portfolios}
            fetchPortfolios={fetchPortfolios}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default PortfoliosContainer;
