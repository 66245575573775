// extracted by mini-css-extract-plugin
export var analytics = "FeedItem-module--analytics--b31fb";
export var analyticsIcon = "FeedItem-module--analyticsIcon--412db";
export var author = "FeedItem-module--author--0bf94";
export var automatedTrade = "FeedItem-module--automatedTrade--1d93f";
export var avatar = "FeedItem-module--avatar--ef631";
export var backtest = "FeedItem-module--backtest--69320";
export var cointegration = "FeedItem-module--cointegration--fe86c";
export var content = "FeedItem-module--content--51f83";
export var feedTitle = "FeedItem-module--feedTitle--d03d1";
export var header = "FeedItem-module--header--3aa4a";
export var hiddenContent = "FeedItem-module--hiddenContent--7f157";
export var iconContainer = "FeedItem-module--iconContainer--05f54";
export var item = "FeedItem-module--item--92be8";
export var premium = "FeedItem-module--premium--720a2";
export var tags = "FeedItem-module--tags--0e312";
export var tagsMobile = "FeedItem-module--tagsMobile--b6324";
export var time = "FeedItem-module--time--7fed8";
export var volatility = "FeedItem-module--volatility--78a19";