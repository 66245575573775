const NewTag = () => {
  return (
    <div
      style={{
        backgroundColor: "#eab838",
        color: "#fff",
        borderRadius: 10,
        marginLeft: "auto",
        fontSize: 10,
        padding: 4,
        fontWeight: 600,
      }}>
      Novo
    </div>
  );
};

export default NewTag;
