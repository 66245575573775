import YoutubeIcon from "../../icons/youtube-outline.svg";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import * as css from "./YouTubeLink.module.css";

const YouTubeLink = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        logAmplitudeEvent(EVENT_MAP.YOUTUBE_BANNER_CLICKED, {
          page: window.location.pathname,
        })
      }
      href="https://www.youtube.com/c/QuantBrasil?sub_confirmation=1"
      className={css["container"]}>
      <div className={css["icon"]}>
        <YoutubeIcon />
        <div>Inscreva-se no canal do QuantBrasil!</div>
      </div>
      <div className={css["copy"]}>
        <div>Inscreva-se no canal do QuantBrasil!</div>
        <div>
          Acompanhe novidades sobre a plataforma, dicas de análise quantitativa,
          sinais, backtests, estatísticas e muito mais!
        </div>
      </div>
    </a>
  );
};

export default YouTubeLink;
