import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as css from "./LastUpdated.module.css";

const LastUpdated = ({ date, isISO = false }) => {
  if (!date) return null;
  const formatted = isISO
    ? DateTime.fromISO(date)
    : DateTime.fromJSDate(new Date(date));
  return (
    <p className={css["lastUpdate"]}>
      Última atualização{" "}
      {formatted.toRelative({
        locale: "pt-br",
      })}
      .
    </p>
  );
};

LastUpdated.propTypes = {
  date: PropTypes.string,
  isISO: PropTypes.bool,
};

export default LastUpdated;
