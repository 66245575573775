import * as Popover from "@radix-ui/react-popover";
import PropTypes from "prop-types";
import * as css from "./Portfolios.module.css";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import OnboardingStep from "../common/OnboardingStep";

const PortfoliosList = ({ portfolios, selectedId, setSelectedId }) => {
  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "SELECT_PORTFOLIO"
  );

  return (
    <Popover.Root open={showStep}>
      <div className={css["separator"]} />
      <div className={css["list"]}>
        {portfolios.map((p, i) => {
          const renderItem = (
            <div
              onClick={() => {
                markStepAsCompleted();
                setSelectedId(p.id);
              }}
              key={p.id}
              style={{
                color: selectedId === p.id ? "#49ce8b" : undefined,
                fontWeight: selectedId === p.id ? "500" : undefined,
                borderLeftColor: selectedId === p.id ? "#49ce8b" : undefined,
              }}>
              {p.name}
            </div>
          );

          if (i === 0) {
            return (
              <>
                <Popover.Trigger key={p.id} asChild>
                  {renderItem}
                </Popover.Trigger>
                {showStep && (
                  <OnboardingStep
                    step={step}
                    onConfirm={markStepAsCompleted}
                    onDismiss={markStepAsCompleted}
                  />
                )}
              </>
            );
          }
          return renderItem;
        })}
      </div>
    </Popover.Root>
  );
};

PortfoliosList.propTypes = {
  portfolios: PropTypes.array,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func.isRequired,
};

export default PortfoliosList;
