// extracted by mini-css-extract-plugin
export var accordion = "Alerts-module--accordion--183d7";
export var accordionItem = "Alerts-module--accordionItem--e134f";
export var alert = "Alerts-module--alert--06b63";
export var alertName = "Alerts-module--alertName--33c63";
export var alertType = "Alerts-module--alertType--28208";
export var asset = "Alerts-module--asset--97bee";
export var assets = "Alerts-module--assets--da551";
export var circle = "Alerts-module--circle--fe4fb";
export var emptyAlerts = "Alerts-module--emptyAlerts--8a3fe";
export var fadein = "Alerts-module--fadein--8df3a";
export var filters = "Alerts-module--filters--f21b8";
export var heading = "Alerts-module--heading--a727c";
export var infoContainer = "Alerts-module--infoContainer--62826";
export var infoIcon = "Alerts-module--infoIcon--59e72";
export var panel = "Alerts-module--panel--9224f";
export var time = "Alerts-module--time--d2d0c";
export var upgradeContainer = "Alerts-module--upgradeContainer--90982";
export var wpIcon = "Alerts-module--wpIcon--65d49";