import { Link } from "gatsby";
import SEO from "../components/seo";
import { useIsClient } from "../hooks/useIsClient";
import { useEffect } from "react";
import { EVENT_MAP, logAmplitudeEvent } from "../utils/amplitude";

const NotFoundPage = () => {
  const isClient = useIsClient();

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.PAGE_NOT_FOUND, {
      page: window.location.pathname,
    });
  }, []);

  // We don't render anything till we are sure we are in the client side. Otherwise,
  // the page gets loaded *before* JavaScript actually loads, which causes a quick
  // flicker of a 404 when we hard refresh
  if (!isClient) return null;

  return (
    <div>
      <SEO
        title="404 - Página Não Encontrada"
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <h1>Página Não Encontrada</h1>
      <p>A página que você tentou acessar não existe.</p>
      <p>
        Entre em contato com nosso suporte{" "}
        <a href="mailto:rafael@quantbrasil.com.br">rafael@quantbrasil.com.br</a>{" "}
        para maiores informações.
      </p>
      <Link to="/">Início</Link>
    </div>
  );
};

export default NotFoundPage;
