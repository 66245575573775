import { Link } from "gatsby";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import AppLayout from "../layouts/AppLayout";
import * as css from "./CointegrationRadarContainer.module.css";
import CointegrationsList from "./CointegrationsList";
import { useAuth } from "../../hooks/useAuth";
import UpgradeSection from "../common/UpgradeSection";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useAssets } from "../../hooks/useAssets";
import { isEmpty } from "lodash";
import { Tooltip } from "react-tooltip";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";
import CointegrationBatchesFilter from "./CointegrationBatchesFilter";
import { PREMIUM_OPTION } from "../../utils/select";
import { StringParam, useQueryParams } from "use-query-params";
import CointegrationBatchBanner from "./CointegrationBatchBanner";

const getAvailableAssets = grouped => {
  const allAssets = grouped.filter(({ label }) =>
    ["Ações", "ETFs"].includes(label)
  );

  const etfAssets = {
    ...allAssets[1],
    options: allAssets[1].options.filter(({ label }) =>
      ["BOVA11", "SMAL11"].includes(label)
    ),
  };

  return [allAssets[0], etfAssets];
};

const TYPE_OPTIONS = [
  { value: "ticker", label: "Por ativo" },
  { value: "sector", label: "Por setor" },
  { value: "user", label: "Por batch", isPremium: true },
];

export const SECTORS_OPTIONS = [
  { value: "bens-industriais", label: "Bens Industriais" },
  { value: "consumo-nao-ciclico", label: "Consumo Não-Cíclico" },
  { value: "consumo-ciclico", label: "Consumo Cíclico" },
  { value: "comunicacoes", label: "Comunicações" },
  { value: "financeiro", label: "Financeiro" },
  {
    value: "petroleo-gas-e-biocombustiveis",
    label: "Petróleo, Gás e Biocombustíveis",
  },
  { value: "materiais-basicos", label: "Materiais Básicos" },
  { value: "saude", label: "Saúde" },
  { value: "utilidade-publica", label: "Utilidade Pública" },
  { value: "tecnologia-da-informacao", label: "Tecnologia da Informação" },
];

const CointegrationRadarContainer = () => {
  const [{ filter: initialFilter }] = useQueryParams({ filter: StringParam });

  const [selectedFilter, setSelectedFilter] = useState(
    initialFilter
      ? TYPE_OPTIONS.find(option => option.value === initialFilter) ||
          TYPE_OPTIONS[0]
      : TYPE_OPTIONS[0]
  );
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedSector, setSelectedSector] = useState(null);
  const [selectedBatchId, setSelectedBatchId] = useState(null);

  const isValid =
    (selectedFilter?.value === "ticker" && !isEmpty(selectedAsset)) ||
    (selectedFilter?.value === "sector" && !isEmpty(selectedSector)) ||
    (selectedFilter?.value === "user" && selectedBatchId !== null);

  const { user } = useAuth();

  const isPremium = user?.isPremium;

  const { grouped } = useAssets({ requirePremium: false });

  const allAssets = getAvailableAssets(grouped);

  const callAPI = useAPI({ withCredentials: true });

  const {
    isLoading,
    isSuccess,
    data,
    dataUpdatedAt,
    refetch: getCointegrations,
  } = useQuery(
    [
      "cointegrationRadar",
      selectedFilter,
      selectedAsset,
      selectedSector,
      selectedBatchId,
    ],
    () =>
      selectedFilter?.value === "user"
        ? callAPI(`/api/cointegration/batch/${selectedBatchId}`)
        : callAPI(
            `/api/metrics/cointegration/${selectedFilter.value}/${
              selectedFilter.value === "ticker"
                ? selectedAsset.value
                : selectedSector.value
            }`
          ),
    { onError: showError, enabled: false, retry: false }
  );

  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_RADAR_VIEWED);
    incrementProperty("cointegrationRadarViewed");
  }, []);

  return (
    <AppLayout
      premiumMessage="Seja Premium e tenha acesso ao Radar de Cointegração"
      seoProps={{
        title: "Radar de Cointegrações",
      }}>
      <div className={css["content"]}>
        <h1>Radar de Cointegrações</h1>
        <p>
          Monitore todos os pares{" "}
          <span
            className={css["hint"]}
            data-tooltip-content="São considerados cointegrados os pares com confiança ≥ 95% e beta > 0."
            data-tooltip-id="cointegrations-radar-container">
            cointegrados
          </span>{" "}
          identificados semanalmente pelo <b>Radar do QuantBrasil</b>, com
          validade de 30 dias. Busque pares contendo um determinado <b>ativo</b>{" "}
          ou dentro de um <b>setor</b>.{" "}
          <strong>A lista não é exaustiva.</strong> Busque novos pares no{" "}
          <b>
            <Link to="/cointegracao">Teste de Cointegração</Link>
          </b>
          .
        </p>
        <p>
          Pares cujo <em>Z-score</em> atual seja <b>≥ 2 ou ≤ -2</b> são
          candidatos a um trade de Long & Short.
        </p>
        {dataUpdatedAt > 0 && (
          <p className={css["lastUpdated"]}>
            <em>Última atualização</em>:{" "}
            {DateTime.fromMillis(dataUpdatedAt)
              .setLocale("pt-BR")
              .toFormat("HH:mm:ss")}
          </p>
        )}
        <div className={css["filter"]}>
          <div>
            <label htmlFor="123-tf">Filtro</label>
            <Select
              id="radar-type"
              options={TYPE_OPTIONS.map(option => ({
                ...option,
                isDisabled: !isPremium && option.isPremium,
              }))}
              value={selectedFilter}
              onChange={option => setSelectedFilter(option)}
              styles={{
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                ...PREMIUM_OPTION,
              }}
              isSearchable={false}
            />
          </div>
          {selectedFilter?.value === "ticker" && (
            <div className={css["assets"]}>
              <label htmlFor="assets-select">Ativos</label>
              <Select
                id="assets-select"
                options={allAssets}
                value={selectedAsset}
                onChange={option => setSelectedAsset(option)}
                styles={{
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                }}
                placeholder="Selecione o ativo"
              />
            </div>
          )}
          {selectedFilter?.value === "sector" && (
            <div className={css["assets"]}>
              <label htmlFor="assets-select">Setores</label>
              <Select
                id="sectors-select"
                options={SECTORS_OPTIONS}
                value={selectedSector}
                onChange={option => setSelectedSector(option)}
                styles={{
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                }}
                placeholder="Selecione o setor"
              />
            </div>
          )}
          {selectedFilter.value === "user" && (
            <CointegrationBatchesFilter
              selectedId={selectedBatchId}
              setSelectedId={setSelectedBatchId}
            />
          )}
          <div className={css["buttonWrapper"]}>
            <button
              title={isPremium ? undefined : "Apenas assinantes Premium"}
              disabled={!isPremium || !isValid || isLoading}
              className={css["button"]}
              onClick={() => getCointegrations()}>
              Procurar
            </button>
          </div>
        </div>
        {isLoading && <Spinner type="Rings" text="Buscando..." />}
        {isSuccess && data.length === 0 && (
          <div className={css["none"]}>
            Nenhum par cointegrado encontrado nos últimos 30 dias.{" "}
            <Link to="/cointegracao">Buscar pares.</Link>
          </div>
        )}
        {isSuccess && data.length > 0 && (
          <CointegrationsList
            cointegrations={data}
            showFilter={isPremium}
            allowUnsave={false}
          />
        )}
        <div className={css["displayContainer"]}>
          <CointegrationBatchBanner />
        </div>
        {!isPremium && (
          <div className={css["extra"]}>
            <UpgradeSection copy="Assine o plano Premium para visualizar pares cointegrados encontrados pelo Radar do QuantBrasil, atualizados semanalmente, dentro de um mesmo setor ou que contenham um ativo específico." />
          </div>
        )}
      </div>
      <Tooltip id="cointegrations-radar-container" clickable delayHide={100} />
    </AppLayout>
  );
};

export default CointegrationRadarContainer;
