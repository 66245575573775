import { groupBy } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import { STRATEGY_OPTIONS } from "./TopBacktestsContainer";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";
import Select from "react-select";
import { SELECT_MEDIUM } from "../../utils/select";
import PropTypes from "prop-types";

const parseOptions = (options, isPremium) => {
  const grouped = groupBy(options, "group");
  return Object.keys(grouped).map(selectedSetup => ({
    label: selectedSetup,
    options: grouped[selectedSetup].map(o =>
      isPremium || Boolean(!o.isPremium) ? o : { ...o, isDisabled: true }
    ),
  }));
};

const SelectedSetupFilter = ({ setup, setSetup }) => {
  const {
    user: { isPremium },
  } = useAuth();

  const PROTECTED_FILTER_OPTIONS = parseOptions(STRATEGY_OPTIONS, isPremium);

  const {
    step: changeRakingStep,
    markStepAsCompleted: markChangeRankingAsCompleted,
    showStep: showChangeRankingStep,
  } = useOnboardingStep("CHANGE_SELECTED_SETUP");

  return (
    <Popover.Root open={showChangeRankingStep}>
      <Popover.Trigger asChild>
        <div style={{ width: "100%" }}>
          <label htmlFor="setup">Setup</label>
          <Select
            id="setup"
            options={PROTECTED_FILTER_OPTIONS}
            value={setup}
            onChange={option => {
              if (option.value !== setup?.value) {
                markChangeRankingAsCompleted();
                logAmplitudeEvent(EVENT_MAP.BACKTEST_RANKING_SELECTED, {
                  ranking: option.value,
                });
                incrementProperty("backtestRankingSelected");
              }
              setSetup(option);
            }}
            placeholder="Selecione um setup"
            styles={{
              ...SELECT_MEDIUM,
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected
                  ? "hsl(0, 0%, 100%)"
                  : state.isDisabled
                  ? "hsl(0, 0%, 80%)"
                  : "rgb(51, 51, 51)",
                ...(state.isDisabled
                  ? {
                      display: "flex",
                      alignItems: "center",
                      ":after": {
                        backgroundColor: "#49ce8b",
                        color: "#fff",
                        borderRadius: 10,
                        content: "'Premium'",
                        display: "block",
                        marginLeft: "auto",
                        fontSize: 10,
                        padding: 4,
                        fontWeight: 500,
                      },
                    }
                  : {}),
              }),
            }}
          />
        </div>
      </Popover.Trigger>
      {showChangeRankingStep && (
        <OnboardingStep
          step={changeRakingStep}
          onConfirm={markChangeRankingAsCompleted}
          onDismiss={markChangeRankingAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

SelectedSetupFilter.propTypes = {
  setup: PropTypes.object,
  setSetup: PropTypes.func.isRequired,
};

export default SelectedSetupFilter;
