import { Link } from "gatsby";
import * as css from "./SupplyDemandWidget.module.css";
import Icon from "../../icons/candles.svg";
import FeedWidget from "./FeedWidget";
import { EVENT_MAP, logAmplitudeEvent } from "../../utils/amplitude";

const SupplyDemandWidget = () => {
  return (
    <FeedWidget>
      <div className={css["icon"]}>
        <Icon />
      </div>
      <p>
        Descubra pontos de <b>suporte e resistência</b> dos seus ativos
        favoritos.
      </p>
      <Link
        className={css["button"]}
        to="/suportes-e-resistencias"
        onClick={() =>
          logAmplitudeEvent(EVENT_MAP.FEED_WIDGET_BUTTON_CLICKED, {
            widget: "supply-demand",
          })
        }>
        Suportes e Resistências
      </Link>
    </FeedWidget>
  );
};

export default SupplyDemandWidget;
