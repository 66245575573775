import { DateTime } from "luxon";
import PropTypes from "prop-types";
import * as css from "../backtests/CapitalTooltip.module.css";

const BetaRotationTooltip = ({ data }) => {
  return (
    <div className={css["container"]}>
      {data.hideDate ? null : (
        <div>
          <em>
            Em{" "}
            {DateTime.fromISO(data.xFormatted)
              .setLocale("pt-BR")
              .toFormat("dd LLL yy")}
          </em>
        </div>
      )}
      <div>
        <b>{data.title || "Beta"}:</b> {parseFloat(data.yFormatted).toFixed(3)}
      </div>
      {data.hint && <div>{data.hint}</div>}
    </div>
  );
};

BetaRotationTooltip.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BetaRotationTooltip;
