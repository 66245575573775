import { graphql, Link, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import Select from "react-select";
import InstagramLink from "../components/common/InstagramLink";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import { SELECT_SMALL } from "../utils/select";
import * as css from "./candle-pavio.module.css";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  trackScreeningViewed,
} from "../utils/amplitude";

const TIMEFRAME_OPTIONS = [
  { value: "D1", label: "Diário" },
  { value: "W1", label: "Semanal" },
];

const SORT_OPTIONS = [
  { value: "body asc", label: "Menor Corpo" },
  { value: "body desc", label: "Maior Corpo" },
];

const WickCandle = () => {
  const { isLoggedIn } = useAuth();

  const PROTECTED_TIMEFRAME_OPTIONS = TIMEFRAME_OPTIONS.map(option => {
    if (!isLoggedIn && ["W1"].includes(option.value))
      return { ...option, isDisabled: true };
    return option;
  });

  const [minBody, setMinBody] = useState(0);
  const [maxBody, setMaxBody] = useState(20);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [timeframe, setTimeframe] = useState(PROTECTED_TIMEFRAME_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const {
    allTicker: { nodes },
  } = useStaticQuery(graphql`
    query {
      allTicker {
        nodes {
          ticker
          ohlc {
            D1 {
              open
              high
              low
              close
              is_wick
            }
            W1 {
              open
              high
              low
              close
              is_wick
            }
          }
          buildTime
        }
      }
    }
  `);

  const buildTime = nodes[0]?.buildTime;

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    nodes
      .filter(node => node.ohlc[timeframe.value]?.is_wick)
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      })
      .map(node => ({
        ...node,
        body:
          Math.abs(
            node.ohlc[timeframe.value].open - node.ohlc[timeframe.value].close
          ) /
          (node.ohlc[timeframe.value].high - node.ohlc[timeframe.value].low),
      }))
      .filter(node => 100 * node.body <= maxBody && 100 * node.body >= minBody),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "wickCandle" });
  }, []);

  return (
    <PublicLayout
      seoProps={{
        title: "Candle Pavio",
        description: "Screening dos ativos que contraíram sua volatilidade.",
      }}
      title="Candle Pavio"
      ctaMessage="Crie sua conta para desbloquear o screening no timeframe Semanal.">
      <div>
        <p>
          A lista abaixo contém a lista dos ativos que apresentam o padrão{" "}
          <b>Candle Pavio</b>. Um Candle Pavio é aquele cujo corpo, ou seja, a
          diferença entre a abertura e o fechamento, é até <b>20%</b> da
          amplitude do candle (diferença entre máxima e mínima).
        </p>
        <LastUpdated date={buildTime} />
        <div className={css["filter"]}>
          <div>
            <label htmlFor="wick-tf">Timeframe</label>
            <Select
              id="wick-tf"
              options={PROTECTED_TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "205px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  color: state.isSelected
                    ? "hsl(0, 0%, 100%)"
                    : state.isDisabled
                    ? "hsl(0, 0%, 80%)"
                    : "rgb(51, 51, 51)",
                  ...(state.isDisabled
                    ? {
                        display: "flex",
                        alignItems: "center",
                        ":before": {
                          backgroundColor: "#49ce8b",
                          color: "#fff",
                          borderRadius: 10,
                          content: "'Membros'",
                          display: "block",
                          marginRight: 8,
                          fontSize: 10,
                          padding: 4,
                          fontWeight: 500,
                        },
                      }
                    : {}),
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="min-body">{`Min Corpo (%)`}</label>
            <input
              id="min-body"
              type="number"
              min={0}
              max={20}
              value={minBody}
              onChange={e => setMinBody(e.target.value)}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-body">{`Max Corpo (%)`}</label>
            <input
              id="max-body"
              type="number"
              min={0}
              max={20}
              value={maxBody}
              onChange={e => setMaxBody(e.target.value)}
            />
          </div>
          <div className={css["input"]} style={{ maxWidth: "110px" }}>
            <label htmlFor="stochastic-ticker">Ativo</label>
            <input
              id="stochastic-ticker"
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="k-order">Ordenar por</label>
            <Select
              id="k-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "165px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltio-id="cp-tip"
                  data-tooltip-content="Tamanho do corpo do candle em relação a sua amplitude.">
                  Corpo (%)<sup>1</sup>
                </th>
                <th
                  data-tooltio-id="cp-tip"
                  data-tooltip-content="Rode o backtest de compra ou venda no simulador de estratégias.">
                  Backtest<sup>2</sup>
                </th>
                <th>Abertura</th>
                <th>Máxima</th>
                <th>Mínima</th>
                <th>Fechamento</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td>{node.ticker}</td>
                  <td>{(node.body * 100).toFixed(2)}%</td>
                  <td>
                    <Link
                      onClick={() =>
                        logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                          ticker: node.ticker,
                          page: window.location.pathname,
                        })
                      }
                      to={`/backtests?strategy=long_wick_candle&ticker=${node.ticker}&timeframeId=${timeframe.value}`}>
                      Compra
                    </Link>
                    {` - `}
                    <Link
                      onClick={() =>
                        logAmplitudeEvent(EVENT_MAP.BACKTEST_CLICKED, {
                          ticker: node.ticker,
                          page: window.location.pathname,
                        })
                      }
                      to={`/backtests?strategy=short_wick_candle&ticker=${node.ticker}&timeframeId=${timeframe.value}`}>
                      Venda
                    </Link>
                  </td>
                  <td>{node.ohlc[timeframe.value].open.toFixed(2)}</td>
                  <td>{node.ohlc[timeframe.value].high.toFixed(2)}</td>
                  <td>{node.ohlc[timeframe.value].low.toFixed(2)}</td>
                  <td>{node.ohlc[timeframe.value].close.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Tamanho do corpo do candle em relação a sua amplitude.
          </div>
          <div>
            <sup>2</sup> Rode o backtest de compra ou venda no simulador de
            estratégias.
          </div>
        </div>
        <InstagramLink />
        <Tooltip id="cp-tip" />
      </div>
    </PublicLayout>
  );
};

export default WickCandle;
