import PropTypes from "prop-types";
import * as css from "./PublicPopup.module.css";
import Modal from "react-modal";
import CloseIcon from "../../icons/close.svg";
import { useAuth } from "../../hooks/useAuth";
import { formatPaymentLinkUrl } from "../landingPages/LandingPageContainer";
import { useConfig } from "../../hooks/useConfig";
import Countdown from "react-countdown";
import landingPagesData from "../../data/landingPages.json";
import { PROMO_DEADLINE, renderer } from "../checkout/PlansPopup";
import { trackCheckoutSessionStarted } from "../../utils/amplitude";
import { DISCOUNT } from "../checkout/PlansContainer";

const PublicPopup = ({ isOpen, onClose }) => {
  const { user } = useAuth();

  const { COUPON_CODE, YEARLY_COUPON } = useConfig();

  const YEARLY_DISCOUNT = Math.round(
    100 * (1 - (1 - DISCOUNT / 100) * (1 - YEARLY_COUPON / 100))
  );

  const { isProd } = useConfig();

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      className={{
        base: css["modal"],
        afterOpen: css["modalAfter"],
        beforeClose: css["modalBefore"],
      }}
      overlayClassName={css["overlay"]}
      onRequestClose={onClose}
      contentLabel="Modal">
      <div>
        <button className={css["close"]} onClick={onClose}>
          <CloseIcon aria-hidden />
        </button>
      </div>
      <div className={css["content"]}>
        <div className={css["title"]}>🎂 Aniversário QuantBrasil</div>
        <p>
          No aniversário do QuantBrasil você pode assinar o Plano Premium com{" "}
          <b>{YEARLY_DISCOUNT}% de desconto no plano anual</b>!
        </p>
        <p>A promoção terminará em:</p>
        <div className={css["countdownContainer"]}>
          <Countdown
            date={PROMO_DEADLINE}
            renderer={renderer}
            zeroPadDays={2}
          />
        </div>
        <p>
          Desbloqueie estratégias exclusivas, alertas no WhatsApp, ranking das
          melhores estratégias, ferramentas de controle de risco, Long & Short
          por cointegração, além de <em>novidades todos os meses</em>!
        </p>
        <div className={css["buttons"]}>
          <a
            href={formatPaymentLinkUrl({
              email: user?.email,
              couponCode: COUPON_CODE,
              isProd,
              isYearly: true,
              yearlyPaymentLink: landingPagesData[0].yearlyPaymentLink,
              monthlyPaymentLink: null,
            })}
            className={css["button"]}
            target="_blank"
            rel="noreferrer"
            onClick={() =>
              trackCheckoutSessionStarted({
                popup: true,
                ctaText: "Testar 7 Dias Grátis",
              })
            }>
            Testar 7 Dias Grátis
          </a>
          <button className={css["notNow"]} onClick={onClose}>
            Agora não
          </button>
        </div>
      </div>
    </Modal>
  );
};

PublicPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PublicPopup;
