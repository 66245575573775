import { Link } from "gatsby";
import { useState } from "react";
import Modal from "../common/Modal";
import * as css from "./HelpModal.module.css";

const HelpButton = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <button className={css["button"]} onClick={() => setOpen(true)}>
        Ajuda
      </button>
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div className={css["help"]}>
          <div>Ajuda</div>
          <div>
            <p>
              O VaR (Value-at-Risk) é uma métrica de risco para uma carteira.
              Dado um <b>período</b> pode-se calcular quanto deve ser a perda
              máxima dentro de um <b>intervalo de confiança</b> para uma
              determinada carteira.
            </p>
            <ul>
              <li>
                Por exemplo, para o período de <b>1 ano</b> e intervalo de
                confiança de <b>95%</b>, um VaR de <b>-2.50%</b> significa que
                existe{" "}
                <em>
                  95% de chance de que o portfólio não cairá mais que 2.50%.
                </em>
              </li>
            </ul>
            <p>
              Na calculadora, é possível adicionar pesos <em>negativos</em> a um
              determinado ativo. Isso pode ser interpretado como uma proteção,
              ou <b>hedge</b> da carteira. Da mesma forma, é possível simular
              uma carteira <b>alavancada</b> sempre que o <em>total</em> seja
              maior que 100%.
            </p>
            <ul>
              <li>
                Por exemplo, uma carteira composta por{" "}
                <em>50% PETR4, 50% VALE3, 30% JBSS3 e -30% WINFUT</em> está{" "}
                <b>130% long</b> e <b>30% short</b>, resultando em um{" "}
                <b>total de 100%</b>.
              </li>
            </ul>
            <p>
              Por fim, caso o total não seja 100%, o valor faltante é
              considerado <em>caixa</em> e funciona como um diluidor do VaR.
            </p>
            <ul>
              <li>
                Por exemplo, se o VaR de uma carteira{" "}
                <em>100% comprada em PETR4 é -3%</em>, o VaR de uma carteira{" "}
                <em>50% comprada em PETR4</em> e cujo resto é caixa (
                <em>total de 50%</em>) será de -1.5%.
              </li>
            </ul>
            <div>
              <p>
                Dúvidas?{" "}
                <Link to="/utilizando-o-var-value-at-risk-no-gerenciamento-de-risco-de-um-portfolio">
                  Utilizando o VaR (Value-at-Risk) no Gerenciamento de Risco de
                  um Portfólio
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HelpButton;
