import BrokenHeartIcon from "../../icons/broken-heart.svg";
import { Tooltip } from "react-tooltip";
import PropTypes from "prop-types";
import * as css from "./UnsaveCointegration.module.css";
import { useMutation } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const UnsaveCointegration = ({
  id,
  allowPopover = false,
  hideLabel = false,
}) => {
  const callAPI = useAPI({ withCredentials: true });

  const queryClient = useQueryClient();

  const mutation = useMutation(
    () =>
      callAPI(`/api/metrics/cointegration/${id}/favorite`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          is_favorite: false,
        }),
      }),
    {
      onError: showError,
      onSuccess: () => {
        toast.success("Cointegração removida dos favoritos");
        queryClient.setQueryData(["userCointegrations"], oldData => {
          return oldData?.filter(c => c.id !== id);
        });
      },
    }
  );

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "UNSAVE_COINTEGRATION"
  );

  const { showStep: showRefreshCointegration } = useOnboardingStep(
    "REFRESH_COINTEGRATION"
  );

  const onClick = () => {
    markStepAsCompleted();
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.COINTEGRATION_REMOVED, { id: id });
  };

  const canShow = allowPopover && showStep && !showRefreshCointegration;

  return (
    <Popover.Root open={canShow}>
      <Popover.Trigger asChild>
        <button
          data-tooltip-id="remove-coint-favorite-tip"
          data-tooltip-content={`Remover dos favoritos`}
          disabled={mutation.isLoading}
          onClick={onClick}
          className={css["heartButton"]}>
          {mutation.isLoading ? (
            <div className={css["spinner"]}>
              <Spinner type="Rings" size={22} />
            </div>
          ) : (
            <>
              <BrokenHeartIcon />
              {!hideLabel && <span>Remover</span>}
              <Tooltip id="remove-coint-favorite-tip" />
            </>
          )}
        </button>
      </Popover.Trigger>
      {canShow && (
        <OnboardingStep
          step={step}
          onConfirm={markStepAsCompleted}
          onDismiss={markStepAsCompleted}
        />
      )}
    </Popover.Root>
  );
};

UnsaveCointegration.propTypes = {
  id: PropTypes.number.isRequired,
  allowPopover: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

export default UnsaveCointegration;
