import { Link } from "gatsby";
import PropTypes from "prop-types";
import Select from "react-select";

import { SELECT_SMALL } from "../../utils/select";
import * as css from "./MyPortfoliosDropdown.module.css";

const MyPortfoliosDropdown = ({ options, selectedPortfolio, onChange }) => {
  return (
    <div className={css["container"]}>
      <div>
        <label htmlFor="portfolios">
          Meus Portfólios{" "}
          <Link to="/app/portfolios/">
            {options.length === 0 ? "Criar" : "Editar"}
          </Link>
        </label>
        <Select
          id="portfolios"
          options={options}
          value={selectedPortfolio}
          onChange={onChange}
          styles={SELECT_SMALL}
          isSearchable={false}
          isClearable={true}
          placeholder="Selecionar portfólio"
          noOptionsMessage={() => "Nenhum portfólio encontrado"}
        />
      </div>
    </div>
  );
};

MyPortfoliosDropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedPortfolio: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default MyPortfoliosDropdown;
