import { useEffect } from "react";
import {
  logAmplitudeEvent,
  EVENT_MAP,
  incrementProperty,
} from "../../utils/amplitude";
import AppLayout from "../layouts/AppLayout";
import InfiniteFeed from "./InfiniteFeed";
import BetaTitle from "../common/BetaTitle";

const FeedContainer = () => {
  useEffect(() => {
    logAmplitudeEvent(EVENT_MAP.FEED_VIEWED);
    incrementProperty("feedViewed");
  }, []);

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear todo o feed"
      seoProps={{
        title: "Feed",
      }}>
      <div>
        <BetaTitle title="Feed Quantitativo" />
        <InfiniteFeed />
      </div>
    </AppLayout>
  );
};

export default FeedContainer;
