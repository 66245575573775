import PropTypes from "prop-types";
import * as css from "./FeedCointegration.module.css";
import { useQuery } from "react-query";
import { useAPI } from "../../hooks/useAPI";
import Spinner from "../common/Spinner";
import CointegrationResult from "../cointegration/CointegrationResult";

const FeedCointegration = ({ cointegrationId }) => {
  const callAPI = useAPI({ withCredentials: true });

  const cointegrationResponse = useQuery(
    ["cointegration", cointegrationId],
    () => callAPI(`/api/cointegration/${cointegrationId}`)
  );

  return (
    <div className={css["container"]}>
      {cointegrationResponse.isSuccess && (
        <CointegrationResult
          result={cointegrationResponse.data}
          showUpgrade={false}
          isFavorite={false}
          allowToFollow={false}
        />
      )}
      {cointegrationResponse.isLoading && (
        <Spinner type="Rings" text="Carregando cointegração..." />
      )}
    </div>
  );
};

FeedCointegration.propTypes = {
  cointegrationId: PropTypes.number.isRequired,
};

export default FeedCointegration;
