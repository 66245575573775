import { Link, graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import orderBy from "lodash.orderby";
import { useEffect, useState } from "react";
import InstagramLink from "../components/common/InstagramLink";
import UpgradeSection from "../components/common/UpgradeSection";
import PublicLayout from "../components/layouts/PublicLayout";
import ShowPortfolios from "../components/portfolios/ShowPortfolios";
import { useAuth } from "../hooks/useAuth";
import { usePortfolios } from "../hooks/usePortfolios";
import * as css from "./hurst.module.css";
import LastUpdated from "../components/common/LastUpdated";
import { Tooltip } from "react-tooltip";
import { trackScreeningViewed } from "../utils/amplitude";
import Input from "../components/common/Input";
import Select from "react-select";
import { SELECT_SMALL } from "../utils/select";
import TrendingIcon from "../icons/trending.svg";
import VolatilityIcon from "../icons/volatility.svg";
import RandomIcon from "../icons/dice.svg";
import ArrowDownIcon from "../icons/arrow-down.svg";
import ExternalIcon from "../icons/external.svg";

const SORT_OPTIONS = [
  { value: "hurst_exponent desc", label: "Maior Hurst" },
  { value: "hurst_exponent asc", label: "Menor Hurst" },
];

const MARKET_OPTIONS = [
  { value: "B3", label: "B3" },
  { value: "S&P500", label: "S&P500" },
];

const Hurst = () => {
  const { isLoggedIn, user } = useAuth();

  const [minHurst, setMinHurst] = useState(0);
  const [maxHurst, setMaxHurst] = useState(1);
  const [market, setMarket] = useState(MARKET_OPTIONS[0]);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [ticker, setTicker] = useState("");
  const [showPortfolios, setShowPortfolios] = useState(false);

  const [fieldToSort, directionToSort] = sortBy.value.split(" ");

  const data = useStaticQuery(graphql`
    query {
      allHurst {
        nodes {
          ticker
          type
          buildTime
          years
          max_lag
          hurst_exponent
          last_week_hurst_exponent
          last_updated
        }
      }
      monthVariation: allVariation(filter: { window: { eq: "DAYS_30" } }) {
        nodes {
          window
          ticker
          variation
          ranking
        }
      }
      yearVariation: allVariation(filter: { window: { eq: "DAYS_365" } }) {
        nodes {
          window
          ticker
          variation
          ranking
        }
      }
    }
  `);

  const lastUpdated = data.allHurst.nodes[0]?.buildTime;

  const {
    selectedPortfolio,
    selectedAssets,
    asOptions: portfolioOptions,
    setSelectedId,
  } = usePortfolios();

  const filtered = orderBy(
    data.allHurst.nodes
      .filter(node => {
        const filterByAssets = showPortfolios && !isEmpty(selectedPortfolio);
        if (!filterByAssets) return true;
        return selectedAssets.includes(node.ticker);
      })
      .filter(
        ({ hurst_exponent }) =>
          hurst_exponent <= maxHurst && hurst_exponent >= minHurst
      )
      .filter(node => {
        if (isEmpty(market)) return true;
        return node.type === market.value;
      })
      .filter(node => {
        if (isEmpty(ticker)) return true;
        return node.ticker.includes(ticker.toUpperCase());
      }),
    fieldToSort,
    directionToSort
  );

  useEffect(() => {
    trackScreeningViewed({ key: "hurst" });
  }, []);

  const getVariation = (ticker, isMonth) => {
    const sourceData = isMonth ? data.monthVariation : data.yearVariation;
    const variation = sourceData.nodes.find(node => node.ticker === ticker)
      ?.variation;
    if (variation === undefined) return "-";
    return `${(variation * 100).toFixed(2)}%`;
  };

  const getHurstState = (hurst, ticker) => {
    if (hurst >= 0.48 && hurst <= 0.52)
      return (
        <>
          <span>Indefinido</span>
          <RandomIcon
            data-tooltip-id="hurst-tip"
            data-tooltip-content="Comportamento aleatório no curto prazo"
          />
        </>
      );
    if (hurst < 0.48)
      return (
        <>
          <span>Reversão</span>
          <VolatilityIcon
            data-tooltip-id="hurst-tip"
            data-tooltip-content="Revertendo à média"
          />
        </>
      );
    const variation =
      data.monthVariation.nodes.find(node => node.ticker === ticker)
        ?.variation || 0;

    const trendingDown = variation < 0;

    let colorClass = "";
    if (trendingDown) {
      if (hurst > 0.52 && hurst < 0.55) {
        colorClass = "lightRed";
      } else if (hurst >= 0.55 && hurst < 0.6) {
        colorClass = "mediumRed";
      } else if (hurst >= 0.6) {
        colorClass = "strongRed";
      }
    } else {
      if (hurst > 0.52 && hurst < 0.55) {
        colorClass = "lightGreen";
      } else if (hurst >= 0.55 && hurst < 0.6) {
        colorClass = "mediumGreen";
      } else if (hurst >= 0.6) {
        colorClass = "strongGreen";
      }
    }
    return (
      <>
        <span>Tendência</span>
        <TrendingIcon
          className={css[colorClass]}
          style={{ transform: variation < 0 ? "scaleY(-1)" : undefined }}
          data-tooltip-id="hurst-tip"
          data-tooltip-content={`${
            hurst >= 0.6 ? "Forte" : hurst >= 0.55 ? "Média" : "Leve"
          } tendência de ${trendingDown ? "baixa" : "alta"}`}
        />
      </>
    );
  };

  const getURL = (hurst, ticker) => {
    if (hurst >= 0.48 && hurst <= 0.52) return "/estrategias";

    if (hurst < 0.48) return "/estrategias?type=Volatilidade";

    const variation =
      data.monthVariation.nodes.find(node => node.ticker === ticker)
        ?.variation || 0;

    const direction = variation < 0 ? "Venda" : "Compra";

    return `/estrategias?direction=${direction}&type=Tendência`;
  };

  const getHurstVariation = (hurst, prevHurst) => {
    if (!isLoggedIn || prevHurst === null || prevHurst === undefined)
      return null;
    return (
      <ArrowDownIcon
        className={hurst < prevHurst ? css["arrowDown"] : css["arrowUp"]}
        data-tooltip-id="hurst-tip"
        data-tooltip-content={`Era ${prevHurst.toFixed(4)} há 7 dias`}
      />
    );
  };

  return (
    <PublicLayout
      seoProps={{
        title: "Expoente de Hurst",
        description:
          "O Expoente de Hurst é um indicador que classifica uma série de preços em tendência, reversão ou comportamento aleatório.",
      }}
      title="Expoente de Hurst"
      ctaMessage="Crie sua conta para visualizar a variação do expoente de Hurst nos últimos 7 dias.">
      <div>
        <p>
          O <strong>Expoente de Hurst</strong> é um indicador que visa
          identificar se um ativo está em <em>tendência</em> (Hurst {`>`} 0.5),{" "}
          <em>reversão</em> (Hurst {`<`} 0.5) ou{" "}
          <em>comportamento aleatório</em> (Hurst {`=`} 0.5). Quanto mais
          próximo de 1, mais forte a tendência. Quanto mais próximo de 0, mais
          forte a reversão à média.
        </p>
        <p>
          Os valores abaixo foram calculados para os ativos{" "}
          {market.value === "B3" ? "mais líquidos da B3" : "do S&P500"}
          em um período de 1 ano e utilizando um <em>max lag</em> de 50
          períodos. O retorno nas janelas de 30 e dias e 1 ano é utilizado para
          determinar a <em>direção</em> da tendência. Dúvidas?{" "}
          <Link to="/descobrindo-as-tendencias-do-ibovespa-utilizando-o-expoente-de-hurst/">
            Descobrindo as Tendências do Ibovespa Utilizando o Expoente de Hurst
          </Link>
          .
        </p>
        <LastUpdated date={lastUpdated} />
        <div className={css["filter"]}>
          <div className={css["input"]}>
            <label htmlFor="min-hurst">{`Min Hurst`}</label>
            <Input
              id="min-hurst"
              value={minHurst}
              onValueChange={({ floatValue }) =>
                setMinHurst(floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={4}
              placeholder="Ex: 0"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 1 || floatValue >= 0
              }
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="max-hurst">{`Max Hurst`}</label>
            <Input
              id="max-hurst"
              value={maxHurst}
              onValueChange={({ floatValue }) =>
                setMaxHurst(floatValue === undefined ? "" : floatValue)
              }
              fluid
              size="small"
              isNumber
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={4}
              placeholder="Ex: 0"
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 1 || floatValue >= 0
              }
            />
          </div>
          <div>
            <label htmlFor="hurst-mkt">Mercado</label>
            <Select
              id="hurst-mkt"
              options={MARKET_OPTIONS}
              value={market}
              onChange={option => setMarket(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "125px",
                }),
              }}
              isSearchable={false}
            />
          </div>
          <div className={css["input"]}>
            <label htmlFor="beta-period">Ativo</label>
            <input
              value={ticker}
              onChange={e => setTicker(e.target.value)}
              placeholder="Ex: PETR4"
            />
          </div>
          <div>
            <label htmlFor="hurst-order">Ordenar por</label>
            <Select
              id="hurst-order"
              options={SORT_OPTIONS}
              value={sortBy}
              onChange={option => setSortBy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "170px",
                }),
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {isLoggedIn && (
          <ShowPortfolios
            showPortfolios={showPortfolios}
            setShowPortfolios={setShowPortfolios}
            portfolioOptions={portfolioOptions}
            setSelectedId={setSelectedId}
            selectedPortfolio={selectedPortfolio}
          />
        )}
        <div className={css["tableContainer"]}>
          <table>
            <thead>
              <tr>
                <th>Ativo</th>
                <th
                  data-tooltip-id="hurst-tip"
                  data-tooltip-content={`Expoente de Hurst calculado utilizando-se uma janela de 1 ano e um max lag de 50 períodos.`}>
                  Hurst<sup>1</sup>
                </th>
                <th
                  data-tooltip-id="hurst-tip"
                  data-tooltip-content={`Variação do preço do ativo nos últimos 30 dias.`}>
                  Retorno (30 dias)<sup>2</sup>
                </th>
                <th
                  data-tooltip-id="hurst-tip"
                  data-tooltip-content={`Variação do preço do ativo nos últimos 365 dias.`}>
                  Retorno (1 ano)<sup>3</sup>
                </th>
                <th
                  data-tooltip-id="hurst-tip"
                  data-tooltip-content="Classificamos 0.48 <= H <= 0.52 como indefinido, H < 0.48 como reversão à média, H > 0.52 como tendência.">
                  Classificação<sup>4</sup>
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {filtered.map(node => (
                <tr key={node.ticker}>
                  <td>{node.ticker}</td>
                  <td>
                    <span className={css["exponentContainer"]}>
                      {node.hurst_exponent.toFixed(4)}
                      {getHurstVariation(
                        node.hurst_exponent,
                        node.last_week_hurst_exponent
                      )}
                    </span>
                  </td>
                  <td>{getVariation(node.ticker, true)}</td>
                  <td>{getVariation(node.ticker, false)}</td>
                  <td>
                    <span className={css["state"]}>
                      {getHurstState(node.hurst_exponent, node.ticker)}
                    </span>
                  </td>
                  <td>
                    <a
                      href={getURL(node.hurst_exponent, node.ticker)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={css["strategyLink"]}>
                      Ver estratégias <ExternalIcon />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={css["legend"]}>
          <div>
            <sup>1</sup> Expoente de Hurst calculado utilizando-se uma janela de
            1 ano e um max lag de 50 períodos.
          </div>
          <div>
            <sup>2</sup> Variação do preço do ativo nos últimos 30 dias.
          </div>
          <div>
            <sup>3</sup> Variação do preço do ativo nos últimos 365 dias.
          </div>
          <div>
            <sup>4</sup>{" "}
            {`Classificamos 0.48 <= H <= 0.52 como indefinido, H < 0.48 como reversão à média, H > 0.52 como tendência.`}
          </div>
        </div>
        <Tooltip id="hurst-tip" />
        <InstagramLink />
        {isLoggedIn && !user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para desbloquear todas as funcionalidades do QuantBrasil!" />
        )}
      </div>
    </PublicLayout>
  );
};

export default Hurst;
