import ReturnsChart from "./ReturnsChart";
import PropTypes from "prop-types";
import { uniq, groupBy } from "lodash";
import { useMemo, useState } from "react";
import * as css from "./ReturnsResult.module.css";
import ChartControls from "./ChartControls";
import { toPercent } from "../../utils/utils";
import { Tooltip } from "react-tooltip";
import InfoIcon from "../../icons/info-question-circle.svg";
import { renderToStaticMarkup } from "react-dom/server";

export const BENCHMARKS = ["IBOV", "CDI", "IPCA"];

const renderReturnsTable = (
  groupedData,
  visibleTickers,
  title,
  showWeighted,
  walletReturn
) => (
  <div className={css["securitiesContainer"]}>
    <div className={css["returnTitle"]}>{title}</div>
    <table>
      <thead>
        <tr>
          <th>Ativo</th>
          <th>Retorno</th>
          <th>{showWeighted ? "Retorno Ponderado" : "Retorno VS Portfólio"}</th>
        </tr>
      </thead>
      <tbody>
        {visibleTickers.map(ticker => {
          const tickerReturn = computeReturn(groupedData[ticker], "value");
          return (
            <tr key={ticker}>
              <td>{ticker}</td>
              <td>{toPercent(tickerReturn)}</td>
              {showWeighted ? (
                <td>
                  {toPercent(
                    computeReturn(groupedData[ticker], "value") *
                      (groupedData[ticker][0]?.weighted_value || 1)
                  )}
                </td>
              ) : (
                <td>
                  <span
                    style={{
                      fontWeight: 500,
                      color:
                        walletReturn < tickerReturn
                          ? "#49ce8b"
                          : walletReturn > tickerReturn
                          ? "#d64242"
                          : undefined,
                    }}>
                    {toPercent(tickerReturn - walletReturn)}
                  </span>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

const computeReturn = (data, key = "wallet_value") => {
  if (!data || data.length === 0) return 0;

  const initialValue = data[0][key];
  const adjust = initialValue === 0 ? 1 : 0;
  const finalValue = data[data.length - 1][key];
  const sign = initialValue >= 0 ? 1 : -1;
  return sign * ((finalValue + adjust) / (initialValue + adjust) - 1);
};

const ReturnsResult = ({
  weightedData,
  dailyReturns,
  walletDrawdown,
  walletStd,
  sharpeRatio,
  ibovData,
  cdiData,
  ipcaData,
}) => {
  const tickers = uniq(dailyReturns.map(({ name }) => name));

  const groupedData = useMemo(() => {
    const group = groupBy(dailyReturns, "name");
    const benchmarks = { IBOV: ibovData, CDI: cdiData, IPCA: ipcaData };
    return { ...group, ...benchmarks };
  }, [cdiData, dailyReturns, ibovData, ipcaData]);

  const walletReturn = useMemo(() => computeReturn(weightedData), [
    weightedData,
  ]);

  const defaultState = useMemo(() => {
    const defaultState = [{ ticker: "Portfólio", enabled: true }];
    tickers.forEach(ticker => {
      defaultState.push({ ticker, enabled: false, isBenchmark: false });
    });

    BENCHMARKS.forEach(ticker => {
      defaultState.push({ ticker, enabled: false, isBenchmark: true });
    });
    return defaultState;
  }, [tickers]);

  const [state, setState] = useState(defaultState);

  const visibleTickers = state
    .filter(({ enabled }) => enabled)
    .map(({ ticker }) => ticker);

  return (
    <div>
      <div className={css["stats"]}>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>Resultado</div>
            <div
              style={{
                color:
                  walletReturn > 0
                    ? "#49ce8b"
                    : walletReturn < 0
                    ? "#d64242"
                    : undefined,
              }}>
              {toPercent(walletReturn)}
            </div>
          </div>
          <div className={css["stat"]}>
            <div>Drawdown</div>
            <div>{toPercent(walletDrawdown)}</div>
          </div>
          <div className={css["stat"]}>
            <div>Vol. Diária</div>
            <div>{toPercent(walletStd)}</div>
          </div>
        </div>
        <div className={css["row"]}>
          <div className={css["stat"]}>
            <div>
              Sharpe <InfoIcon data-tooltip-id="sharpe-tip" />
            </div>
            <div>{sharpeRatio.toFixed(2)}</div>
          </div>
          {BENCHMARKS.map(ticker => {
            const tickerReturn = computeReturn(groupedData[ticker], "value");
            return (
              <div key={ticker} className={css["stat"]}>
                <div>
                  VS {ticker}{" "}
                  {ticker === "IPCA" && <InfoIcon data-tooltip-id="ipca-tip" />}
                </div>
                <div
                  style={{
                    color:
                      walletReturn > tickerReturn
                        ? "#49ce8b"
                        : walletReturn < tickerReturn
                        ? "#d64242"
                        : undefined,
                  }}>
                  {toPercent(walletReturn - tickerReturn)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ReturnsChart
        weightedData={weightedData}
        groupedData={groupedData}
        visibleTickers={visibleTickers}
      />
      <ChartControls state={state} setState={setState} />
      {renderReturnsTable(
        groupedData,
        Object.keys(groupedData).filter(ticker => !BENCHMARKS.includes(ticker)),
        "Retorno Individual do Portfólio",
        true,
        walletReturn
      )}
      {renderReturnsTable(
        groupedData,
        Object.keys(groupedData).filter(ticker => BENCHMARKS.includes(ticker)),
        "Benchmarks",
        false,
        walletReturn
      )}
      <Tooltip
        id="sharpe-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            O <b>Índice de Sharpe</b> é definido como a diferença entre os
            retornos do portfólio e do CDI, dividida pelo desvio-padrão no
            período.
            <br />
            Tipicamente, valores acima de 1 são considerados bons.&nbsp;
            <a
              href="https://pt.wikipedia.org/wiki/%C3%8Dndice_de_Sharpe"
              rel="noopener noreferrer"
              target="_blank">
              Leia mais.
            </a>
          </span>
        )}
      />
      <Tooltip
        id="ipca-tip"
        delayHide={100}
        clickable
        html={renderToStaticMarkup(
          <span>
            Considera-se o{" "}
            <strong>
              <a
                href="https://www.anbima.com.br/pt_br/informar/precos-e-indices/indices/ima.htm#:~:text=IMA%2DB%3A%20formado%20por%20t%C3%ADtulos,Tesouro%20IPCA%2B%20com%20Juros%20Semestrais)."
                rel="noopener noreferrer"
                target="_blank">
                IMA-B
              </a>
            </strong>{" "}
            como referência.
          </span>
        )}
      />
    </div>
  );
};

ReturnsResult.propTypes = {
  weightedData: PropTypes.array.isRequired,
  dailyReturns: PropTypes.array.isRequired,
  walletDrawdown: PropTypes.number.isRequired,
  walletStd: PropTypes.number.isRequired,
  sharpeRatio: PropTypes.number.isRequired,
  ibovData: PropTypes.array.isRequired,
  cdiData: PropTypes.array.isRequired,
  ipcaData: PropTypes.array.isRequired,
};

export default ReturnsResult;
