import PropTypes from "prop-types";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import {
  incrementProperty,
  logAmplitudeEvent,
  EVENT_MAP,
} from "../utils/amplitude";
import * as css from "./Share.module.css";

const Share = ({ title, slug }) => {
  const URL =
    typeof window !== "undefined"
      ? window.location.href
      : `https://quantbrasil.com/${slug}`;

  const onShare = medium => {
    logAmplitudeEvent(EVENT_MAP.POST_SHARED, {
      medium,
      page: window.location.pathname,
    });
    incrementProperty("shareCount");
  };

  return (
    <div className={css["share"]}>
      <div className={css["shareHeader"]}>Compartilhar:</div>
      <div className={css["buttons"]}>
        <TwitterShareButton
          title={title}
          via="quant_brasil"
          url={URL}
          beforeOnClick={() => {
            onShare("twitter");
          }}>
          <TwitterIcon
            size={36}
            bgStyle={{ fill: "none" }}
            iconFillColor="#49ce8b"
          />
        </TwitterShareButton>
        <FacebookShareButton
          quote={title}
          hashtag="#quantbrasil"
          beforeOnClick={() => {
            onShare("facebook");
          }}
          url={`https://quantbrasil.com/${slug}`}>
          <FacebookIcon
            size={36}
            bgStyle={{ fill: "none" }}
            iconFillColor="#49ce8b"
          />
        </FacebookShareButton>
        <WhatsappShareButton
          title={title}
          url={URL}
          beforeOnClick={() => {
            onShare("whatsapp");
          }}>
          <WhatsappIcon
            size={36}
            bgStyle={{ fill: "none" }}
            iconFillColor="#49ce8b"
          />
        </WhatsappShareButton>
        <TelegramShareButton
          title={title}
          url={URL}
          beforeOnClick={() => {
            onShare("telegram");
          }}>
          <TelegramIcon
            size={36}
            bgStyle={{ fill: "none" }}
            iconFillColor="#49ce8b"
          />
        </TelegramShareButton>
      </div>
    </div>
  );
};

Share.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Share;
