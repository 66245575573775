import { useState } from "react";
import { LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";
import { generateWADeepLink } from "./checkout/WhatsAppFloatingButton";
import InstagramIcon from "../icons/instagram-outline.svg";
import YoutubeIcon from "../icons/youtube-outline.svg";
import SliderDiv from "./common/SliderDiv";
import * as css from "./Footer.module.css";
import Subscribe from "./Subscribe";
import TelegramLink from "./TelegramLink";
import { Link } from "gatsby";
import Logo from "../images/logo-horizontal-white.svg";
import { EVENT_MAP, logAmplitudeEvent } from "../utils/amplitude";
import { useConfig } from "../hooks/useConfig";

const navLinks = [
  {
    title: "Ferramentas Quantitativas",
    links: [
      { path: "/backtests", label: "Backtests" },
      { path: "/cointegracao", label: "Cointegração" },
      { path: "/suportes-e-resistencias", label: "Suportes e Resistências" },
      { path: "/retorno-historico", label: "Retorno Histórico" },
      { path: "/value-at-risk", label: "Value-at-Risk" },
      { path: "/beta-da-carteira", label: "Beta da Carteira" },
    ],
  },
  {
    title: "QuantBrasil",
    links: [
      { path: "/planos", label: "Planos" },
      { path: "/sobre", label: "Sobre" },
      { path: "/blog", label: "Blog" },
      { path: "/youtube", label: "Vídeos" },
      { path: "/releases", label: "Releases" },
    ],
  },
  {
    title: "Screenings",
    links: [
      { path: "/ifr2", label: "IFR2" },
      { path: "/setup-123", label: "Setup 123" },
      { path: "/estocastico-lento", label: "Estocástico Lento" },
      { path: "/candle-pavio", label: "Candle Pavio" },
      { path: "/turnaround", label: "Turnaround" },
      { path: "/trap", label: "Trap na Média" },
      { path: "/preco-de-fechamento-de-reversao", label: "PFR" },
      { path: "/bandas-de-bollinger", label: "Bandas de Bollinger" },
      { path: "/inside-bar", label: "Inside Bar" },
      { path: "/hurst", label: "Expoente de Hurst" },
    ],
  },
  {
    title: "Factor Investing",
    links: [
      { path: "/magic-formula", label: "Magic Formula" },
      { path: "/momentum", label: "Momentum B3" },
      { path: "/momentum/sp500", label: "Momentum S&P 500" },
      { path: "/low-risk", label: "Low Risk" },
      { path: "/low-risk/sp500", label: "Low Risk S&P 500" },
      { path: "/full-factor", label: "Full Factor" },
    ],
  },
  {
    title: "Listas",
    links: [
      { path: "/eden-dos-traders", label: "Éden dos Traders" },
      { path: "/beta", label: "Beta" },
      { path: "/ranking", label: "Ranking B3" },
      { path: "/ranking/sp500", label: "Ranking S&P 500" },
      { path: "/ipos", label: "IPOs" },
      { path: "/bdrs-mais-liquidas", label: "BDRs Mais Líquidas" },
      { path: "/drawdown", label: "Drawdown" },
      { path: "/topo-historico", label: "Topo Histórico (ATH)" },
      { path: "/estrategias", label: "Estratégias" },
    ],
  },
];

const renderSection = section => (
  <div className={css["linksContainer"]}>
    <div>{section.title}</div>
    <ul>
      {section.links.map(link => (
        <li key={link.path}>
          <Link
            onClick={() =>
              logAmplitudeEvent(EVENT_MAP.FOOTER_LINK_CLICKED, {
                link: link.path,
                label: link.label,
              })
            }
            to={link.path}>
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const [hasSubscribed, setSubscribed] = useState(false);

  const { IS_BLACKFRIDAY } = useConfig();
  const theme = IS_BLACKFRIDAY ? "#100826" : "#033660";

  return (
    <footer
      className={css["footer"]}
      style={{
        backgroundColor: theme,
      }}>
      <div className={css["row"]}>
        <div className={css["subscribeContainer"]}>
          <img src={Logo} alt="Logo QuantBrasil" className={css["large"]} />
          <h3>
            Gostou do conteúdo<span>?</span>
          </h3>
          <p>
            Se inscreva para ser avisado sobre os próximos posts e novidades do
            QuantBrasil!
          </p>
          {!hasSubscribed && (
            <div>
              <Subscribe onSubscribeSuccess={() => setSubscribed(true)} />
            </div>
          )}
          {hasSubscribed && (
            <SliderDiv>
              <b>Feito!</b> Fique ligado para os próximos conteúdos 🚀
            </SliderDiv>
          )}
        </div>
        <div className={css["allLinks"]}>
          <div>
            {renderSection(navLinks[0])}
            {renderSection(navLinks[1])}
          </div>
          <div>{renderSection(navLinks[2])}</div>
          <div>
            {renderSection(navLinks[3])}
            {renderSection(navLinks[4])}
          </div>
        </div>
      </div>
      <div className={css["telegram"]}>
        <TelegramLink />
      </div>
      <div className={css["copyright"]}>
        <div>
          <span>RAFAEL G S QUINTANILHA TECNOLOGIA</span>
          {` © `}
          {new Date().getFullYear()}
        </div>
        <div className={css["buttonsFooter"]}>
          <a
            href="https://www.youtube.com/c/QuantBrasil"
            target="_blank"
            rel="noreferrer"
            className={css["instagram"]}>
            <YoutubeIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/quantbrasil"
            target="_blank"
            rel="noreferrer">
            <LinkedinIcon size={32} round={true} bgStyle={{ fill: "none" }} />
          </a>
          <a
            href="https://instagram.com/quant_brasil"
            target="_blank"
            rel="noreferrer"
            className={css["instagram"]}>
            <InstagramIcon />
          </a>
          <a
            href="https://twitter.com/quant_brasil"
            target="_blank"
            rel="noreferrer">
            <TwitterIcon size={32} round={true} bgStyle={{ fill: "none" }} />
          </a>
          <a href={generateWADeepLink()} target="_blank" rel="noreferrer">
            <WhatsappIcon size={32} round={true} bgStyle={{ fill: "none" }} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
