import { useAPI } from "../../hooks/useAPI";
import { useInfiniteQuery } from "react-query";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useEffect, useState } from "react";
import * as css from "./InfiniteFeed.module.css";
import { useInView } from "react-intersection-observer";
import { useAuth } from "../../hooks/useAuth";
import FeedItem from "./FeedItem";
import ArrowDown from "../../icons/arrow-down.svg";
import FeedModal from "./FeedModal";
import BacktestWidget from "./BacktestWidget";
import CointegrationWidget from "./CointegrationWidget";
import Logo from "../../icons/logo.svg";
import SupplyDemandWidget from "./SupplyDemandWidget";
import StreamWidget from "./StreamWidget";
import { navigate } from "gatsby";
import { Tooltip } from "react-tooltip";
import PremiumWidget from "./PremiumWidget";

const MAX_PAGE = 10;

const InfiniteFeed = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const callAPI = useAPI({ withCredentials: true });
  const { user } = useAuth();

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ["userFeed", user.userId],
    ({ pageParam = 1 }) => {
      const params = new URLSearchParams();
      params.append("page", pageParam);

      return callAPI(`/api/feed/?${params.toString()}`);
    },
    {
      onError: showError,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length !== 10) return undefined; // Means it's the last page
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    if (inView) fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const currentPage = data?.pages?.length || 1;

  const hasReachedEnd = currentPage >= MAX_PAGE || !hasNextPage;

  const canLoadMore = hasNextPage && !hasReachedEnd;

  return (
    <div className={css["page"]}>
      <div className={css["col1"]}>
        <div className={css["colHeader"]}>
          <div>
            <Logo /> Destaques
          </div>
          <div>O essencial do QuantBrasil.</div>
        </div>
        <div className={css["widgetsContainer"]}>
          <BacktestWidget />
          <CointegrationWidget />
          <SupplyDemandWidget />
        </div>
      </div>
      <div className={css["container"]}>
        {isLoading && <Spinner type="Rings" text="Carregando..." />}
        {isSuccess && (
          <>
            {data?.pages[0].length > 0 && (
              <>
                <Tooltip id="feed-tooltip" />
                <div className={css["feedContainer"]}>
                  {data.pages.flat().map((item, index) => {
                    const isLink = item.category_id === "automated_trade";

                    const isButton =
                      !!item.parameters?.backtest_id ||
                      !!item.parameters?.cointegration_id;

                    const canClick = isLink || isButton;
                    return (
                      <FeedItem
                        key={index}
                        data={item}
                        onClick={() => {
                          if (isButton) {
                            setSelectedItem(item);
                          } else if (isLink) {
                            const link =
                              item.category_id === "automated_trade"
                                ? "/app/robots"
                                : "#";
                            navigate(link);
                          }
                        }}
                        canClick={canClick}
                      />
                    );
                  })}
                </div>
              </>
            )}
            {canLoadMore &&
              (isFetchingNextPage ? (
                <Spinner type="Rings" text="Carregando..." />
              ) : (
                <div className={css["fetchNext"]}>
                  <button
                    ref={ref}
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}>
                    {isFetchingNextPage ? "Carregando..." : "Ver mais"}
                  </button>
                </div>
              ))}
            {hasReachedEnd && (
              <button
                className={css["goBackTop"]}
                onClick={() => {
                  refetch();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}>
                <ArrowDown aria-hidden /> Voltar ao topo
              </button>
            )}
          </>
        )}
      </div>
      <div className={css["col2"]}>
        <div className={css["widgetsContainer"]}>
          <StreamWidget />
          {!user.isPremium && (
            <div className={css["premium"]}>
              <PremiumWidget />
            </div>
          )}
        </div>
      </div>
      <FeedModal
        isOpen={!!selectedItem}
        onClose={() => setSelectedItem(null)}
        data={selectedItem}
      />
    </div>
  );
};

export default InfiniteFeed;
