import { useAPI } from "../../hooks/useAPI";
import { useInfiniteQuery } from "react-query";
import { showError } from "../../utils/utils";
import Spinner from "../common/Spinner";
import { useEffect, useState } from "react";
import * as css from "./ScoreRankingContainer.module.css";
import { useInView } from "react-intersection-observer";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "gatsby";
import LogoIcon from "../../images/logo.svg";
import Select from "react-select";
import { STRATEGY_OPTIONS, TIMEFRAME_OPTIONS } from "../../utils/constants";
import { orderBy } from "lodash";
import { SELECT_SMALL } from "../../utils/select";
import GroupLabel from "../common/GroupLabel";
import { useAssets } from "../../hooks/useAssets";
import { Tooltip } from "react-tooltip";
import AppLayout from "../layouts/AppLayout";
import SavedBacktests from "../favorites/SavedBacktests";
import UpgradeSection from "../common/UpgradeSection";

const getStrategyOptions = () => {
  const long = [];
  const short = [];
  STRATEGY_OPTIONS.forEach(s => (s.isShort ? short.push(s) : long.push(s)));
  return [
    {
      label: "Compra",
      options: orderBy(long, ["order"], ["asc"]),
    },
    { label: "Venda", options: short },
  ];
};

const ScoreRankingContainer = () => {
  const callAPI = useAPI({ withCredentials: true });
  const { user } = useAuth();

  const { grouped: allAssets } = useAssets({ requirePremium: false });

  const [selectedId, setSelectedId] = useState(null);
  const [strategy, setStrategy] = useState(null);
  const [asset, setAsset] = useState(null);
  const [timeframe, setTimeframe] = useState(null);

  const { ref, inView } = useInView({ threshold: 0 });

  const {
    isLoading,
    isSuccess,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: fetchRanking,
  } = useInfiniteQuery(
    ["scoreRanking", user.userId, strategy, asset, timeframe],
    ({ pageParam = 1 }) => {
      const params = new URLSearchParams();
      params.append("page", pageParam);

      if (strategy) params.append("strategy_id", strategy.value);
      if (asset) params.append("tickers", asset.value);
      if (timeframe) params.append("timeframe_id", timeframe.value);

      return callAPI(`/api/backtest/ranking?${params.toString()}`);
    },
    {
      enabled: false,
      onError: showError,
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length !== 10) return undefined; // Means it's the last page
        return pages.length + 1;
      },
    }
  );

  useEffect(() => {
    if (inView) fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const canLoadMore = user.isPremium && hasNextPage;

  return (
    <AppLayout
      premiumMessage="Seja Premium para desbloquear o ranking por Score."
      seoProps={{
        title: "Ranking por Score",
      }}>
      <div className={css["outerContainer"]}>
        <h1>Ranking por Score</h1>
        <p>
          Busque as estratégias com as maiores notas de acordo com o{" "}
          <b>
            <Link to="/backtests/score">Score do QuantBrasil</Link>
          </b>
          .{" "}
          {user?.isPremium
            ? "Selecione um timeframe para visualizar o ranking."
            : "Filtre por qualquer combinação de ativo, estratégia e timeframe."}
        </p>
        <div className={css["filter"]}>
          <Tooltip id="history-filter-tp" />
          <div>
            <label htmlFor="backtest-asset">Ativo</label>
            <Select
              id="backtest-asset"
              options={allAssets}
              value={asset}
              onChange={option => setAsset(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "100%",
                  minWidth: "170px",
                }),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
              isClearable
            />
          </div>
          <div style={{ flex: 1 }}>
            <label htmlFor="backtest-strategy">Estratégia</label>
            <Select
              id="backtest-strategy"
              options={getStrategyOptions()}
              value={strategy}
              onChange={option => setStrategy(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "100%",
                }),
              }}
              formatGroupLabel={data => <GroupLabel {...data} />}
              placeholder="Selecionar..."
              isClearable
            />
          </div>
          <div>
            <label htmlFor="backtest-timeframe">Timeframe</label>
            <Select
              id="backtest-timeframe"
              options={TIMEFRAME_OPTIONS}
              value={timeframe}
              onChange={option => setTimeframe(option)}
              styles={{
                ...SELECT_SMALL,
                container: provided => ({
                  ...provided,
                  width: "190px",
                }),
              }}
              isClearable
              placeholder="Selecionar..."
            />
          </div>
          <div className={css["buttonWrapper"]}>
            <button
              title={
                !timeframe ? "Selecione um timeframe para continuar." : "Buscar"
              }
              disabled={isLoading || !user?.isPremium || !timeframe}
              className={css["button"]}
              onClick={() => {
                fetchRanking();
              }}>
              Buscar
            </button>
          </div>
        </div>
        {isLoading && <Spinner type="Rings" text="Carregando..." />}
        {isSuccess && (
          <div className={css["container"]}>
            {data?.pages[0].length > 0 && (
              <SavedBacktests
                data={data.pages.flat()}
                setSelectedId={setSelectedId}
                selectedId={selectedId}
              />
            )}
            {canLoadMore &&
              (isFetchingNextPage ? (
                <Spinner type="Rings" text="Carregando..." />
              ) : (
                <div className={css["fetchNext"]}>
                  <button
                    ref={ref}
                    onClick={() => fetchNextPage()}
                    disabled={isFetchingNextPage}>
                    {isFetchingNextPage ? "Carregando..." : "Ver mais"}
                  </button>
                </div>
              ))}
            {data?.pages[0].length === 0 && (
              <div className={css["welcomeContainer"]}>
                <div>Nenhum backtest encontrado!</div>
                <img className={css["logo"]} src={LogoIcon} />
                <div>
                  Visite o nosso{" "}
                  <Link to="/backtests">Simulador de Estratégias</Link> para
                  realizar esse backtest.
                </div>
              </div>
            )}
          </div>
        )}
        {!user.isPremium && (
          <UpgradeSection copy="Faça o upgrade de sua conta para visualizar o ranking por Score! Assim é possível descobrir os melhores backtests para cada combinação de ativo, estratégia e timeframe, de acordo com o algoritmo do QuantBrasil." />
        )}
      </div>
    </AppLayout>
  );
};

export default ScoreRankingContainer;
