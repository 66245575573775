import { graphql, useStaticQuery } from "gatsby";
import orderBy from "lodash.orderby";

export const getRankedNodes = nodes => {
  const sortedByDividend = orderBy(
    nodes,
    node => node.dividend_yield,
    "desc"
  ).map((node, i) => ({ ...node, dividendRanking: i + 1 }));
  const sortedByBeta = orderBy(
    sortedByDividend,
    node => node.beta,
    "asc"
  ).map((node, i) => ({ ...node, betaRanking: i + 1 }));
  const sortedByVariation = orderBy(
    sortedByBeta,
    node => node.variation,
    "desc"
  ).map((node, i) => ({ ...node, variationRanking: i + 1 }));
  const sortedNodes = orderBy(
    sortedByVariation.map(node => ({
      ...node,
      overallPoints:
        node.dividendRanking + node.betaRanking + node.variationRanking,
    })),
    "overallPoints",
    "asc"
  ).map((node, i) => ({ ...node, overallRanking: i + 1 }));
  return sortedNodes;
};

const useSortedLowRisk = ({ fieldToSort, directionToSort, portfolioId }) => {
  const { allLowRisk: data } = useStaticQuery(graphql`
    query {
      allLowRisk {
        nodes {
          dividend_yield
          ticker
          variation
          beta
          buildTime
          portfolio_id
        }
      }
    }
  `);

  const nodes = data.nodes.filter(node => node.portfolio_id === portfolioId);

  const filtered = orderBy(
    getRankedNodes(nodes),
    item => item[fieldToSort],
    directionToSort
  );

  return filtered;
};

export default useSortedLowRisk;
