import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import ErrorBoundaryContainer from "./src/components/common/ErrorBoundary";
import FeedbackContainer from "./src/components/feedback/FeedbackContainer";
import * as css from "./src/components/Toast.module.css";
import { ProvideAuth } from "./src/hooks/useAuth";
import { ProvideConfig } from "./src/hooks/useConfig";

const queryClient = new QueryClient();

// Pattern inspired by https://github.com/gatsbyjs/gatsby/tree/master/examples/using-i18n
const wrapRootElement = ({ element }) => (
  <ErrorBoundaryContainer>
    <QueryClientProvider client={queryClient}>
      <ProvideConfig>
        <ToastContainer
          pauseOnHover
          position="top-center"
          closeButton={false}
          hideProgressBar
          autoClose={3000}
          toastClassName={css["toast"]}
          theme="colored"
          icon={false}
        />
        <ProvideAuth>
          <FeedbackContainer>{element}</FeedbackContainer>
        </ProvideAuth>
      </ProvideConfig>
    </QueryClientProvider>
  </ErrorBoundaryContainer>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default wrapRootElement;
