import { toPercent } from "../../utils/utils";
import RobotsReturnsChart from "./RobotsReturnsChart";
import * as css from "./RobotsTrades.module.css";
import PropTypes from "prop-types";

const computeReturn = data => {
  const initialValue = 1;
  const finalValue = data[data.length - 1].value;
  const adjust = initialValue === 0 ? 1 : 0;
  const sign = initialValue >= 0 ? 1 : -1;
  return sign * ((finalValue + adjust) / (initialValue + adjust) - 1);
};

const RobotsTrades = ({ robots, ibov }) => {
  const statsData = [
    {
      metric: "Retorno Total",
      robot: toPercent(computeReturn(robots.data)),
      ibov: toPercent(computeReturn(ibov.data)),
      winner:
        computeReturn(robots.data) > computeReturn(ibov.data)
          ? "Robôs"
          : "IBOV",
    },
    {
      metric: "Dias Positivos",
      robot: robots.data.filter(d => d.variation > 0).length,
      ibov: ibov.data.filter(d => d.variation > 0).length,
      winner:
        robots.data.filter(d => d.variation > 0).length >
        ibov.data.filter(d => d.variation > 0).length
          ? "Robôs"
          : "IBOV",
    },
    {
      metric: "Dias Negativos",
      robot: robots.data.filter(d => d.variation < 0).length,
      ibov: ibov.data.filter(d => d.variation < 0).length,
      winner:
        robots.data.filter(d => d.variation < 0).length <
        ibov.data.filter(d => d.variation < 0).length
          ? "Robôs"
          : "IBOV",
    },
    {
      metric: "Vol. Diária",
      robot: toPercent(robots.std),
      ibov: toPercent(ibov.std),
      winner: robots.std < ibov.std ? "Robôs" : "IBOV",
    },
    {
      metric: "Drawdown",
      robot: toPercent(robots.drawdown),
      ibov: toPercent(ibov.drawdown),
      winner: robots.drawdown < ibov.drawdown ? "Robôs" : "IBOV",
    },
  ];

  let ibovWinCount = 0;
  let robotWinCount = 0;

  ibov.data.forEach(d => {
    const date = d.date;
    const ibovVariation = d.variation;

    const robot = robots.data.find(r => r.date === date);
    const robotVariation = robot ? robot.variation : 0;

    const ibovWin = ibovVariation > robotVariation;
    const robotWin = robotVariation > ibovVariation;

    if (ibovWin) ibovWinCount++;
    else if (robotWin) robotWinCount++;
  });

  statsData.splice(3, 0, {
    metric: "Dias Vencedores",
    robot: robotWinCount,
    ibov: ibovWinCount,
    winner: robotWinCount > ibovWinCount ? "Robôs" : "IBOV",
  });

  const alpha = computeReturn(robots.data) - computeReturn(ibov.data);

  return (
    <div className={css["container"]}>
      <div>
        <RobotsReturnsChart robots={robots.data} ibov={ibov.data} />
      </div>
      <div className={css["statsContainer"]}>
        <div className={css["statsLabel"]}>
          Estatísticas{" "}
          <div className={css["alpha"]}>
            <div className={css["alphaLabel"]}>Alfa:</div>
            <div
              className={css["alphaValue"]}
              style={{
                color: alpha > 0 ? "#49ce8b" : "#f44336",
              }}>
              {toPercent(alpha)}
            </div>
          </div>
        </div>
        <div className={css["statsTable"]}>
          <table>
            <thead>
              <tr>
                <th>Métrica</th>
                <th>Robôs</th>
                <th>IBOV</th>
              </tr>
            </thead>
            <tbody>
              {statsData.map((d, i) => {
                const isLast = i === statsData.length - 1;
                return (
                  <tr key={i}>
                    <td
                      style={{
                        borderBottom: isLast ? "none" : undefined,
                      }}>
                      {d.metric}
                    </td>
                    <td
                      style={{
                        borderBottom: isLast ? "none" : undefined,
                        ...(d.winner === "Robôs"
                          ? { fontWeight: 600, color: "#49ce8b" }
                          : {}),
                      }}>
                      {d.robot}
                    </td>
                    <td
                      style={{
                        borderBottom: isLast ? "none" : undefined,
                        ...(d.winner === "IBOV"
                          ? { fontWeight: 600, color: "#49ce8b" }
                          : {}),
                      }}>
                      {d.ibov}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

RobotsTrades.propTypes = {
  robots: PropTypes.shape({
    data: PropTypes.array.isRequired,
    std: PropTypes.number.isRequired,
    drawdown: PropTypes.number.isRequired,
  }).isRequired,
  ibov: PropTypes.shape({
    data: PropTypes.array.isRequired,
    std: PropTypes.number.isRequired,
    drawdown: PropTypes.number.isRequired,
  }).isRequired,
};

export default RobotsTrades;
