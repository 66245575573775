import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/plain.css";
import pt from "react-phone-input-2/lang/pt.json";
import * as css from "./PhoneNotifications.module.css";
import { useAuth } from "../../hooks/useAuth";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { showError } from "../../utils/utils";
import { useAPI } from "../../hooks/useAPI";
import { isEmpty } from "lodash";
import { logAmplitudeEvent, EVENT_MAP } from "../../utils/amplitude";
import useOnboardingStep from "../../hooks/useOnboardingStep";
import * as Popover from "@radix-ui/react-popover";
import OnboardingStep from "../common/OnboardingStep";

const PhoneNotifications = () => {
  const { user, setPhone: updateUserPhone } = useAuth();
  const [phone, setPhone] = useState(user.phone);
  const callAPI = useAPI({ withCredentials: true });

  const { step, markStepAsCompleted, showStep } = useOnboardingStep(
    "ADD_PHONE"
  );

  const mutation = useMutation(
    () =>
      callAPI(`/api/auth/phone`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phone: isEmpty(phone) || phone.startsWith("+") ? phone : `+${phone}`,
        }),
      }),
    {
      onSuccess: () => {
        toast.success(
          phone ? "Número salvo com sucesso!" : "Número removido com sucesso!"
        );
        updateUserPhone(phone);
      },
      onError: showError,
    }
  );

  const onClick = e => {
    e.preventDefault();
    markStepAsCompleted();
    mutation.mutate();
    logAmplitudeEvent(EVENT_MAP.PHONE_UPDATED, { phone });
  };

  return (
    <Popover.Root open={showStep}>
      <div className={css["container"]}>
        <div className={css["title"]}>Notificações via telefone</div>
        <div className={css["phoneContainer"]}>
          <label>WhatsApp</label>
          <div>
            <PhoneInput
              containerClass={css["containerClass"]}
              inputClass={css["inputClass"]}
              dropdownClass={css["dropdownClass"]}
              country="br"
              localization={pt}
              placeholder="+55 11 987654321"
              preferredCountries={["br", "us", "ca"]}
              value={phone}
              onChange={phone => setPhone(phone)}
            />
            <Popover.Trigger asChild>
              <button
                onClick={onClick}
                disabled={mutation.isLoading}
                className={css["saveButton"]}>
                {mutation.isLoading ? "Salvando..." : "Salvar"}
              </button>
            </Popover.Trigger>
            {showStep && !user.phone && (
              <OnboardingStep
                step={step}
                onConfirm={markStepAsCompleted}
                onDismiss={markStepAsCompleted}
                side="right"
              />
            )}
          </div>
        </div>
        <div className={css["phoneHint"]}>
          Adicione seu WhatsApp para receber notificações e alertas das suas
          estratégias favoritas. Você pode remover seu número a qualquer
          momento.
        </div>
      </div>
    </Popover.Root>
  );
};

export default PhoneNotifications;
